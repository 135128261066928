import React, { useState } from 'react';
import '../styles/PartnerBanner.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged } from '../functions';
import { Parser } from 'html-to-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyChainBlue, TickBlue } from '../assets';

function PartnerBanner(props) {
    const partnerData = props.partnerData;
    const phoneNumber = props.phoneNumber;
    const profilePicLoading = props.profilePicLoading;
    const profilePicError = props.profilePicError;
    const textInput = props.textInput;
    const openEditProfile = props.openEditProfile;
    const onChangeFile = props.onChangeFile;
    const editMode = props.editMode;
    const property = props.property;
    const [linkCopied, setLinkCopied] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state === null ? {partnerId: partnerData.id} : location.state;
    const host = window.location.host;
    const profileLink = `${host}/partner/${partnerData.id}?utm_source=partner&utm_medium=profile&utm_campaign=${partnerData.id}`;

    const openPhone = () => {
        window.open(`tel:${phoneNumber}`, '_self');
    };

    const openEmail = () => {
        window.open(`mailto:${partnerData.email}`, '_self');
    };

    const goToProfile = async() => {
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const userId = user.userId;
            if ( userId === partnerData.userId ) {
                navigate("/partner/dashboard", {
                    state: state
                });
            }
            else {
                navigate(`/partner/${partnerData.id}`, {
                    state: state
                });
            }
        }
        else {
            navigate(`/partner/${partnerData.id}`, {
                state: state
            });
        }
    };

    const copyToClipboard = () => {
        setLinkCopied(true);
    };

    return (
        <div className="partner-banner-container margin-x-large">
            <div className="partner-inner-banner-container">
                <div className="partner-image-container">
                    {
                        profilePicLoading !== "" ?
                        <div className="partner-image-loading-container">
                            <span className="heading-large-semibold colour-primary">
                                {profilePicLoading}
                            </span>
                        </div>
                        :
                        partnerData.profileImage === "" ?
                        null
                        :
                        <img
                            src={partnerData.profileImage}
                            className="partner-profile-image margin-x-small"
                            alt="Profile Pic"
                        />
                    }
                    {
                        editMode === true ?
                        <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                        :
                        null
                    }
                    {
                        editMode === true ?
                        <div className="partner-edit-image-container">
                            <span onClick={() => textInput.current.click()} className="body-regular-underline colour-link">
                                Change photo
                            </span>
                            {
                                profilePicError !== "" ?
                                <span className="body-regular colour-error block-text margin-top-small">
                                    {profilePicError}
                                </span>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>
                <div className="partner-info-container">
                    <h3 className="heading-small-semibold text-link margin-x-small">
                        {partnerData.firstName} {partnerData.lastName}
                    </h3>
                    <div 
                        className={"partner-company-info-container margin-x-small " + (editMode === false ? "partner-company-info-link cursor-pointer" : "")}
                        onClick={() => editMode === false ? goToProfile() : null}
                    >
                        {
                            partnerData.companyLogo === "" ?
                            null
                            :
                            <img
                                src={partnerData.companyLogo}
                                className="partner-company-logo"
                                alt="Company Logo"
                            />
                        }
                        <span className="body-regular colour-secondary">
                            {partnerData.companyName}
                        </span>
                    </div>
                    <div className="partner-company-info-contact-container margin-x-small">
                        <div className="partner-company-phone-number-container">
                            <span 
                                className="body-regular colour-link"
                                onClick={() => openPhone()}
                            >
                                {phoneNumber}
                            </span>
                        </div>
                        <span className="body-regular colour-secondary block-text margin-sides">
                            •
                        </span>
                        <div className="partner-company-email-container">
                            <span 
                                className="body-regular colour-link"
                                onClick={() => openEmail()}
                            >
                                {partnerData.email}
                            </span>
                        </div>
                    </div>
                    <div className="partner-banner-info-row">
                        {
                            editMode === true ?
                            <div className="partner-edit-profile-container">
                                <span 
                                    className="body-regular-underline colour-link"
                                    onClick={() => openEditProfile()}
                                >
                                    Edit profile
                                </span>
                            </div>
                            :
                            null
                        }
                        {
                            editMode === true ?
                            <CopyToClipboard 
                                text={profileLink}
                                onCopy={() => copyToClipboard()}
                            >
                                <div className={"partner-banner-copy-link-container " + (linkCopied === false ? "cursor-pointer text-button" : "")}>
                                    <img
                                        src={linkCopied === true ? TickBlue : CopyChainBlue}
                                        className="new-property-card-partner-copy-link-icon subtext-margin-right"
                                        alt="Copy to Clipboard"
                                    />
                                    <span className="body-regular text-link">
                                        {
                                            linkCopied === false ?
                                            "Copy profile link"
                                            :
                                            "Link copied"
                                        }
                                    </span>
                                </div>
                            </CopyToClipboard>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            {
                property !== null ?
                <div className="partner-banner-extra-info-container">
                    {/* <h3 className="heading-small-semibold colour-primary margin-medium">
                        {property.title}
                    </h3> */}
                    <div className="parnter-banner-message-container">
                        {Parser().parse(property.message)}
                    </div>
                </div>
                :
                null
            }
        </div>
    )
};

export default PartnerBanner;