import React from 'react';
import { FinancialsInput, formatterLong } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';

function HoldingCosts(props) {
    const onChangeHoldingCosts = props.onChangeHoldingCosts;
    const onChangeHoldingPeriod = props.onChangeHoldingPeriod;
    const monthlyHoldingCosts = props.monthlyHoldingCosts;
    const holdingPeriod = props.holdingPeriod;
    const totalHoldingCosts = props.totalHoldingCosts;

    return (
        <div className="monthly-costs-fix-flip-container">
            <h3 className="body-semibold colour-primary margin-medium">
                Estimate holding cost
            </h3>
            <div className="monthly-costs-fix-flip-row margin-x-large">
                <div className="monthly-costs-holding-cost-input subtext-margin-right-x-large">
                    <FinancialsInput
                        value={monthlyHoldingCosts}
                        type="number"
                        label="Monthly holding cost"
                        id={`holding-costs-input`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        onChange={(text) => onChangeHoldingCosts(text.target.value)}
                    />
                </div>
                <div className="monthly-costs-holding-period-input">
                    <FinancialsInput
                        value={holdingPeriod}
                        type="text"
                        label="Holding period"
                        id={`holding-period-input`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            endAdornment: <InputAdornment className="holding-period-adornment" position="end">months</InputAdornment>
                        }}
                        onChange={(text) => onChangeHoldingPeriod(text.target.value)}
                    />
                </div>
            </div>
            <div className="monthly-costs-fix-flip-total-container">
                <span className="body-regular colour-secondary block-text subtext-margin-right-large">
                    Total holding cost =
                </span>
                <span className="body-semibold colour-primary">
                    {formatterLong.format(totalHoldingCosts).replace(".00", '')}
                </span>
            </div>
        </div>
    );
};

export default HoldingCosts;