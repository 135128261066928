import React from 'react';

function NoAvailableProperties(props) {
    const resetFilters = props.resetFilters;
    const disabled = props.disabled;
    const userDetails = props.userDetails;
    const setDisabled = props.setDisabled;
    const cityId = props.cityId;
    const sorting = props.sorting;
    const offMarket = props.offMarket;
    const setLoading = props.setLoading;

    return (
        <div className="new-property-list-no-matching-properties-container">
            <h2 className="new-property-list-no-matching-properties-title">
                No matching properties
            </h2>
            <h3 className="new-property-list-no-matching-properties-subtitle">
                Try changing your filters or resetting the map.
            </h3>
            {
                userDetails !== null ?
                <span 
                    className="new-property-list-clear-filters-text"
                    onClick={() => disabled === true ? null : resetFilters(userDetails, setDisabled, cityId, sorting, offMarket, setLoading)}
                >
                    Clear all filters
                </span>
                :
                null
            }
        </div>
    )
}

export default NoAvailableProperties;