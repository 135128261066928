import React from 'react';
import '../styles/ShareButtons.css';
import Download from '../assets/download.svg';
// import Share from '../assets/share.svg';
import Heart from '../assets/heart.svg';
import HeartActive from '../assets/heart-active.svg';
import { downloadExcel, recordEvent } from '../functions'

function ShareButtons(props) {

	const property = props.property;
	const favourites = props.favourites;
	const toggleFavourite = props.toggleFavourite;
	const favouriteDisable = props.favouriteDisable;
	const setFavourites = props.setFavourites;
	const setFavouriteDisable = props.setFavouriteDisable;
	const navigate = props.navigate;
	const cityId = props.cityId;
	const downPayment = props.downPayment;
	const freeTrial = props.freeTrial;
	const spreadsheetProperty = props.spreadsheetProperty;
	const propertySearch = props.propertySearch;
	const partner = props.partner;
	// const downloadPDF = props.downloadPDF;
	const strategyPanel = props.strategyPanel;

	const zpidIndex = favourites.findIndex(e => e.zpid === property.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const cityObject = {
        cityId: cityId,
        city: property.address.city,
        state: property.address.state
    };
	const buttons = [
		// {
		// 	label: "Download PDF",
		// 	icon: Download,
		// 	available: true
		// },
		{
			label: "Download CSV",
			icon: Download,
			available: true
		},
		// {
		// 	label: "Share listing",
		// 	icon: Share
		// },
		{
			label: "Add to favorites",
			icon: inFavourites === false ? Heart : HeartActive,
			available: propertySearch === true || partner === true ? false : true
		}
	];

	const cta = (index) => {
		if ( index === 1 && favouriteDisable === false ) {
			toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null);
		}
		else if ( index === 0 && (freeTrial === false || partner === true) ) {
			downloadExcel([spreadsheetProperty], `${property.address.streetAddress}`, cityId, Number(downPayment), propertySearch, strategyPanel);
			recordEvent("Download CSV", {
				city: cityId,
				cityTitle: "",
				all: false,
				zpid: property.zpid
			})
		}
		// else if ( index === 0 && (freeTrial === false || partner === true) ) {
		// 	downloadPDF();
		// 	recordEvent("Download PDF", {
		// 		city: cityId,
		// 		cityTitle: "",
		// 		all: false,
		// 		zpid: property.zpid
		// 	})
		// }
	}

	return (
		<div className="share-buttons-outer-container">
			<div className="share-buttons-inner-container">
				{
					buttons.map((item, index) =>
						item.available === true ?
						<div 
							className={"share-buttons-element-container " + (index === 0 || index === 1 ? " share-buttons-element-container-download " : "") + (freeTrial === true && (index === 0 || index === 1) && partner === false ? "share-buttons-element-container-free-trial" : "text-button")}
							key={index}
							onClick={() => cta(index)}
						>
							<img
								src={item.icon}
								className="share-buttons-element-image"
								alt={item.label}
							/>
							<span className="body-regular colour-link">
								{item.label}
							</span>
						</div>
						:
						null
					)
				}
			</div>
		</div>
	)
}

export default ShareButtons;