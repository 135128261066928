import React from 'react';
import '../styles/RecommendedTab.css';

function RecommendedTab(props) {
    const index = props.index;
    const strategyClass = `recommended-strategy-${index}`;
    return (
        <div className={"recommended-tab-container bg-colour-sold-status " + (strategyClass)}>
            <div className="recommended-tab-inner-container">
                <span className="label-semibold-caps colour-primary">
                    RECOMMENDED
                </span>
            </div>
        </div>
    )
};

export default RecommendedTab;