import React, { useEffect, useState } from 'react';
import '../styles/VerifyEmail.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged } from '../functions';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { PrimaryButton } from '../buttons';

function VerifyEmail() {

    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchUserData = async() => {
            document.title = "Verify Email | Coffee Clozers";

            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setEmail(user.email);
                if ( user.emailVerified === true ) {
                    navigate("/my-cities");
                }
                else {
                    sendVerificationEmail();
                }
            }
            else {
                navigate("/login");
            }
        };

        const sendVerificationEmail = async() => {
            const auth = getAuth();
            await sendEmailVerification(auth.currentUser)
            .then(() => {
                setEmailSent(true);
                setEmailError(false);
            })
            .catch((error) => {
                setEmailSent(false);
            });
        };

        fetchUserData();
    }, [navigate]);

    const resendEmail = async() => {
        const auth = getAuth();
        await sendEmailVerification(auth.currentUser)
        .then(() => {
            setEmailSent(true);
            setEmailError(false);
        })
        .catch((error) => {
            setEmailSent(false);
            setEmailError(true);
        });
    };

    return (
        <div className="verify-email-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
            />
            <div className="verify-email-inner-container">
                <div className="verify-email-title-container margin-medium">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                        Let's verify your email
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        We've sent you an email with a link to verify your account.
                    </span>
                </div>
                <div className="verify-email-text-container margin-x-large">
                    <span className="body-semibold colour-primary">
                        {email}
                    </span>
                </div>
                <div className="verify-email-body-container">
                    {
                        emailError === true ?
                        <div className="verify-email-confirmation-container margin-medium">
                            <span className="body-regular colour-error block-text text-align-center">
                                There was an error sending you an email. Please wait a couple of minutes and try again.
                            </span>
                        </div>
                        :
                        emailSent === true ?
                        <div className="verify-email-confirmation-container margin-medium">
                            <span className="body-regular colour-primary block-text text-align-center">
                                We just sent you a confirmation email - please check the spam folder if you can't see it!
                            </span>
                        </div>
                        :
                        null
                    }
                    <div className="verify-email-button-container">
                        <PrimaryButton
                            text="Resend verification email"
                            cta={resendEmail}
                            disabled={false}
                            size="medium"
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default VerifyEmail;