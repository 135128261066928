import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '../styles/GlobalStyles';

function RentComparison(props) {
    const rentAccordion = props.rentAccordion;
    const rentalEstimates = props.rentalEstimates;
    const formatter = props.formatter;
    const blurred = props.blurred;
    const partnerData = props.partnerData;
    return (
        <Accordion 
            expanded={rentAccordion}
            sx={{ 
                    boxShadow: 'none',
                    '&:before': {
                    display: 'none',
                }}
            }
        >
            <AccordionSummary
                aria-controls={`panel-1-bh-content`}
                id={`panel-1-bh-header`}
                sx={{ 
                    display: 'none'
                }}
            >
            </AccordionSummary>
            <AccordionDetails>
                <div className="financials-panel-rent-accordion">
                    {
                        rentalEstimates.map((item, index) =>
                        partnerData !== null && rentalEstimates.length !== 0 && item.label === "Partner's Rent Estimate" && partnerData.profileImage !== "" ?
                        <div 
                            className={"financials-panel-rent-accordion-element-row " + (rentalEstimates.length > 1 ? "financials-panel-rent-accordion-element-spacing" : "")}
                            key={index}
                        >
                            <div className="financials-panel-rent-accordion-element-left-container">
                                <img
                                    src={partnerData.profileImage}
                                    className="financials-panel-rent-accordion-element-image"
                                    alt="Partner's Rent Estimate"
                                />
                            </div>
                            <div className="financials-panel-rent-accordion-element-right-container">
                                <span className="label-regular colour-primary">
                                    {item.label}
                                </span>
                                <span className="heading-small-semibold">
                                    {
                                        blurred === true ?
                                        "$999"
                                        :
                                        formatter.format(item.value).replace(".00", "")
                                    }
                                </span>
                            </div>
                        </div>
                        :
                        item.value === 0 || item.value === "0" || item.value === null ? null :
                        <div 
                            className="financials-panel-rent-accordion-element"
                            key={index}
                        >
                            <span className="label-regular colour-primary">
                                {item.label}
                            </span>
                            <span className="heading-small-semibold">
                                {
                                    blurred === true ?
                                    "$999"
                                    :
                                    formatter.format(item.value).replace(".00", "")
                                }
                            </span>
                        </div>
                        )
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default RentComparison;