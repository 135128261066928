import React from 'react';
import { citiesForPurchase } from '../styles/GlobalStyles';

function NonDisclosureState(props) {
    const property = props.property;
    const state = property.address.state;
    const stateIndex = citiesForPurchase.findIndex(e => e.state === state);
    const fullState = citiesForPurchase[stateIndex].stateFull;

    return (
        <div className="non-disclosure-state-container margin-top-x-large">
            <span className="body-regular colour-error">
                Unfortunately {fullState} is a non-disclosure state, which means
                we're unable to calculate the ARV of this property.
            </span>
        </div>
    );
};

export default NonDisclosureState;