import React from 'react';

function MadeUpData() {
    return (
        <div className="property-made-up-data-container">
            <h2 className="heading-semibold colour-error text-align-center">
                ✨ DATA IS ALL MADE UP BCOZ YOU'RE NOT LOGGED IN ✨
            </h2>
        </div>
    )
};

export default MadeUpData;