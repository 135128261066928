import React from 'react';
import '../styles/PricingCardBadge.css';

function PricingCardBadge(props) {
    const discount = props.discount;
    const green = props.green;
    return (
        <div className={"pricing-card-badge-outer-container " + (green === true ? "bg-colour-green-2" : "bg-colour-medium")}>
            <div className="pricing-card-badge-inner-container">
                <span className={"body-regular block-text text-align-center " + (green === true ? "colour-white" : "colour-primary")}>
                    {discount}
                </span>
            </div>
        </div>
    )
};

export default PricingCardBadge;