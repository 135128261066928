import { useEffect } from 'react';
import { setData, setDefaultEditedProperty, getAllSubDocuments, getSubDocument, recalculateProfits, getAPIGatewayDetailAddress, getAPIGatewayDetail, checkSavedRehabNumber, checkOnAuthStateChanged, getDocument, getWhereDoc, calculateMortgagePayment, getUserDocuments, saveNewFavourites, setMergeData, getAPIGatewayListingsSimple, cloudFunctionV2, getCitySearch } from '../functions';
import { defaultUserData } from '../styles/GlobalStyles';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function PropertyEffect(props) {
    const cityId = props.cityId;
	const setCityId = props.setCityId;
    const setProperty = props.setProperty;
    const setMobileBar = props.setMobileBar;
    const setFreeTrial = props.setFreeTrial;
    const setUserDetails = props.setUserDetails;
    const setFavourites = props.setFavourites;
    const navigate = props.navigate;
    const setBlurred = props.setBlurred;
    const id = props.id;
    const setUsePropertySearch = props.setUsePropertySearch;
    const setAddBedroom = props.setAddBedroom;
    const editedProperty = props.editedProperty;
	const queryMade = props.queryMade;
    const setQueryMade = props.setQueryMade;
    const setLoading = props.setLoading;
    const setEditedProperty = props.setEditedProperty;
    const setDownPayment = props.setDownPayment;
    const setInterestRate = props.setInterestRate;
	const setClosingPercentage = props.setClosingPercentage;
    const setTotalRehabCost = props.setTotalRehabCost;
    const setCostPerSqFoot = props.setCostPerSqFoot;
    const setDefaultCostPerSqFoot = props.setDefaultCostPerSqFoot;
    const setHighLevelProfits = props.setHighLevelProfits;
    const setRecurringCosts = props.setRecurringCosts;
    const setSpreadsheetProperty = props.setSpreadsheetProperty;
    const setZipData = props.setZipData;
    const setGentrificationData = props.setGentrificationData;
    const setGentrificationBounds = props.setGentrificationBounds;
    const setZipNames = props.setZipNames;
    const setBounds = props.setBounds;
	const propertySearch = props.propertySearch;
	const setNoProperty = props.setNoProperty;
	const partner = props.partner;
	const partnerId = props.partnerId;
	const setCityState = props.setCityState;
	const setUserSettings = props.setUserSettings;
	const setSubscriptions = props.setSubscriptions;
	const setUserId = props.setUserId;
	const testMode = props.testMode;
	const location = useLocation();
	const state = location.state;
	const origin = window.location.origin;
	const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;

    useEffect(() => {

		const handleWindowResize = () => {
			if ( window.innerWidth > 1000 ) {
				document.body.removeAttribute("style");
				setMobileBar(false);
			}
		};
	  
		window.addEventListener('resize', handleWindowResize);

		async function queryUser() {
			// 1. Get user data
			// 2. Check if user is allowed to view this property
			// 3. If they are allowed, get the property
			// 4. 

			document.title = "Coffee Clozers | Property";

			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const collections = [
					"Users",
                    "Financial Preferences",
					"Subscriptions",
					"Favourites"
				];
				const userId = user.userId;
				if ( setUserId !== null )  {
					setUserId(userId);
				}

				const queryUser = await getUserDocuments(collections, userId);
				if ( queryUser[0].status === 200 ) {
					const userData = queryUser[0].data;
					let userSettings;
					if ( queryUser[1].status === 200 ) {
						userSettings = queryUser[1].data;
						setUserSettings(userSettings);
					}
					else {
						userSettings = defaultUserData.settings;
					}
					
					if ( queryUser[2].status === 200 ) {
						const subscriptions = queryUser[2].data;
						const subscriptionIndex = subscriptions.findIndex(e => e.cityId === cityId && e.endDate.seconds > new Date().getTime() / 1000);
						if ( subscriptionIndex === -1 ) {
							setFreeTrial(true);
						}
						else {
							setFreeTrial(false);
						}
						setSubscriptions(subscriptions);
					}

					let newFavourites = [];
					if ( queryUser[3].status === 200 ) {
						newFavourites = queryUser[3].data;
					}
					else {
						newFavourites = userData.favourites;
						saveNewFavourites(userData.favourites, userId);
					}
					
					if ( propertySearch === true && userData.credits === 0 && state === null && partner === false ) {
						navigate("/pick-city", {
							state: state
						});
					}
					else if ( partner === false ) {
						// Rewrite code for checking who is paid vs free user, and for
						// which city they paid for

						setUserDetails(userData);
						setFavourites(newFavourites);
						queryGatewayProperties(userData, userId, "Property Searches", null, null, null, userSettings);
					}
					else if ( partner === true ) {
						setUserDetails(userData);
						checkPartnerPropertyAmendments(userData, userId, "Users");
					}
				}
			}
			else if ( ( propertySearch === true && partner === false ) && ( id === null || state === null || state.redirect === false || state.ipUser !== true ) ) {
				getOutOfHere();
			}
			else if ( propertySearch === true ) {
				const colRef = "IP Addresses";
				const docRef = state.noUserId;
				const queryUser = await getDocument(colRef, docRef);
				if ( queryUser.status === 200 ) {
					const data = queryUser.data.data;
					if ( state !== null ) {
						queryGatewayProperties(data, "", colRef, null, null, null, defaultUserData.settings);
						setUserDetails(defaultUserData);
					}
				}
				else {
					getOutOfHere();
				}
			}
			else if ( partner === true ) {
				checkPartnerPropertyAmendments(defaultUserData, "", "");
			}
			else {
				queryGatewayProperties(defaultUserData, "", "", null, null, null, defaultUserData.settings);
				setBlurred(true);
				setUserDetails(defaultUserData);
			}
		}

		const checkPartnerPropertyAmendments = async(userData, userId, colRef) => {
			const partnerRef = "Partners";
            const field = "data.id";
            const operator = "==";
            const queryDocument = await getWhereDoc(partnerRef, field, operator, partnerId);
            if ( queryDocument.status === 200 ) {
                const partnerData = queryDocument.data[0];
				const partnerListings = partnerData.listings;
				const listingIndex = partnerListings.findIndex(e => e.zpid === id);
				if ( listingIndex !== -1 ) {
					const listing = partnerListings[listingIndex];
					queryGatewayProperties(userData, userId, colRef, listing.amendments, listing.address, listing, defaultUserData.settings);
					setUserDetails(userData);
				}
			}
		};

		async function queryGatewayProperties(
			userData, 
			userId, 
			colRef, 
			partnerAmendments, 
			partnerAddress, 
			listing,
			userSettings
		) {
			
			const down = userSettings.initialCosts[0].value === "" ? 20 : Number(userSettings.initialCosts[0].value);
			const mortgage = down === 0 ? false : true;
			const address = propertySearch === false && partner === false ? "" : partner === true ? partnerAddress : decodeURIComponent(id);


			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const getGatewayQueryString = propertySearch === true || partner === true ? await getAPIGatewayDetailAddress(userSettings, address) : await getAPIGatewayDetail(cityId, id, userSettings);
			const resourceId = "detail";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
			if ( propertySearch === true && getGateway.status === 200 && getGateway.body.inProd === true && testMode !== true ) {
				const getGatewayBody = getGateway.body;
				const propertyObject = getGatewayBody.prop;
				const zpid = propertyObject.zpid;
				const cityId = propertyObject.cityId;

				setNoProperty(false);
				navigate(`/properties/${cityId}/${zpid}`, {
					state: state
				});
			}
			else if ( getGateway.status === 200 && getGateway.body.prop !== null ) {
				if ( propertySearch === false && partner === false ) {
					setUsePropertySearch(false);
				}
				const getGatewayBody = getGateway.body;
				const propertyObject = getGatewayBody.prop;
				propertyObject.propertySearch = propertySearch;

				if ( partner === true ) {
					if ( partnerAmendments.arvCompsStats !== null ) {
						propertyObject.arvCompsStats = partnerAmendments.arvCompsStats;
						propertyObject.arvComps = true;
						propertyObject.arvCompsSimilarProp = partnerAmendments.arvCompsSimilarProp;
					}

					if ( listing !== null ) {
						propertyObject.status = listing.status;
					}

					if ( partnerAmendments.price !== null ) {
						propertyObject.price = partnerAmendments.price;
					}

					if ( partnerAmendments.customImages !== undefined && partnerAmendments.customImages !== null ) {
						const newImageArray = [];
						const customImages = partnerAmendments.customImages;
						for (let index = 0; index < customImages.length; index++) {
							const element = customImages[index];
							newImageArray.push(element.url)
						}
						propertyObject.images = newImageArray;
						if ( newImageArray.length !== 0 ) {
							propertyObject.imgSrc = newImageArray[0];
						}
					}

					if ( partnerAmendments.rent !== null ) {
						propertyObject.financials.oldRent = propertyObject.financials.rent;
						propertyObject.financials.rent = partnerAmendments.rent;
					}

					if ( partnerAmendments.arv !== null ) {
						propertyObject.arvCompsStats.arvPrice = partnerAmendments.arv;
					}

					if ( partnerAmendments.description !== null ) {
						propertyObject.description = partnerAmendments.description;
					}

					if ( partnerAmendments.bedrooms !== null ) {
						propertyObject.bedrooms = partnerAmendments.bedrooms;
					}

					if ( partnerAmendments.bathrooms !== null ) {
						propertyObject.bathrooms = partnerAmendments.bathrooms;
					}

					if ( partnerAmendments.livingArea !== null ) {
						propertyObject.livingArea = partnerAmendments.livingArea;
					}

					if ( partnerAmendments.propertyTypeDimension !== null ) {
						propertyObject.propertyTypeDimension = partnerAmendments.propertyTypeDimension;
					}

					if ( partnerAmendments.title !== undefined && partnerAmendments.title !== null ) {
						propertyObject.title = partnerAmendments.title;
					}

					if ( partnerAmendments.message !== undefined && partnerAmendments.message !== null ) {
						propertyObject.message = partnerAmendments.message;
					}

					if ( partnerAmendments.rentalComps !== undefined && partnerAmendments.rentalComps !== null ) {
						propertyObject.rentComps = partnerAmendments.rentalComps;
					}

					if ( partnerAmendments.downPayment !== undefined && partnerAmendments.downPayment !== null && userId === "" ) {
						setDownPayment(partnerAmendments.downPayment);
					}

					if ( partnerAmendments.interestRate !== undefined && partnerAmendments.interestRate !== null && userId === "" ) {
						setInterestRate(partnerAmendments.interestRate);
					}

					if ( partnerAmendments.closingCosts !== undefined && partnerAmendments.closingCosts !== null && userId === "" ) {
						setClosingPercentage(partnerAmendments.closingCosts);
					}

					if ( partnerAmendments.totalRehabCost !== undefined && partnerAmendments.totalRehabCost !== null ) {
						setTotalRehabCost(partnerAmendments.totalRehabCost);
						if ( propertyObject.livingArea !== null ) {
							setCostPerSqFoot(partnerAmendments.totalRehabCost / propertyObject.livingArea);
							setDefaultCostPerSqFoot(partnerAmendments.totalRehabCost / propertyObject.livingArea);
						}
						propertyObject.financials.totalRehabCost = partnerAmendments.totalRehabCost;
					}
				}

				let searches = [];
				let credits = 5;
				const getPropertySearches = userId === "" ? {status: 400} : await getDocument(colRef, userId);
				if ( getPropertySearches.status === 200 ) {
					const getPropertySearchesData = getPropertySearches.data.data;
					if ( getPropertySearchesData.propertySearches !== undefined )  {
						searches = getPropertySearchesData.propertySearches;
					}
					else {
						searches = getPropertySearchesData.searches;
					}
					credits = getPropertySearchesData.credits;
				}
				else {
					credits = userData.credits;
					searches = userData.searches;
				}

				if ( propertySearch === true && state !== null && state.preSaved === false ) {
					await writeZPID(colRef, state.ipUser === true ? state.noUserId : userId, credits, propertyObject, searches);
				}
				else if ( propertySearch === true && state === null && searches.length !== 0 ) {
					await writeZPID(colRef, userId, credits, propertyObject, searches);
				}

				propertyObject.datePostedTS = new Date(propertyObject.datePosted);
				propertyObject.offMarket = propertyObject.motivatedSeller === undefined && propertySearch === false ? false : true;

				const checkAddBed = propertyObject.addBedOpportunity;
				setAddBedroom(checkAddBed);

				const a = moment(new Date());
				const b = moment(propertyObject.datePostedTS);
				const dateDifference = a.diff(b, 'days');
				propertyObject.dateDifference = dateDifference;

				const int = propertyObject.mortgage30us === undefined ? 7 : propertyObject.mortgage30us;
				const loan = 0;

				if ( propertyObject.uniqueUnitPropertyDetails !== undefined && propertyObject.uniqueUnitPropertyDetails !== null && propertyObject.uniqueUnitPropertyDetails.length > 1 ) {
					for (let index = 0; index < propertyObject.uniqueUnitPropertyDetails.length; index++) {
						propertyObject.uniqueUnitPropertyDetails[index].value = propertyObject.uniqueUnitPropertyDetails[index].rentcast_rent;
					}
				}

				if ( editedProperty === "" && userId !== "" ) {
					await checkEditedProperty(userId, propertyObject, down, int, loan, mortgage, userData, partnerAmendments, userSettings);
				}
				else if ( partner === true && partnerAmendments !== null ) {
					await savePartnerChanges(propertyObject, down, int, loan, mortgage, partnerAmendments);
				}
				else if ( editedProperty === "" ) {
					writeDefaultEditedProperty(userId, propertyObject, down, int, loan, mortgage, userData, partnerAmendments, userSettings);
				}

				if ( propertyObject.rentComps !== null && propertyObject.rentComps !== undefined ) {
					propertyObject.rentComps.sort((a,b) => (a.prediction_label < b.prediction_label) ? 1 : ((b.prediction_label < a.prediction_label) ? -1 : 0));
				}

				if ( propertyObject.unitPropertyComps !== null && propertyObject.unitPropertyComps !== undefined ) {
					if ( propertyObject.unitPropertyComps.length === 1 ) {
						propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
					}
					else if ( propertyObject.unitPropertyComps.length > 1 ) {
						propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						propertyObject.unitPropertyComps[1].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
					}
				}

				if ( propertyObject.imgSrc === null || propertyObject.imgSrc === undefined || propertyObject.imgSrc.includes("maps") ) {
					propertyObject.offMarketImage = true;
				}
				else {
					propertyObject.offMarketImage = false;
				}

				if ( propertyObject.arvComps === true && partner === false && propertyObject.arvCompsSubjProp !== null ) {
					propertyObject.arvCompsSubjProp = propertyObject.arvCompsSubjProp[0];
				}

				document.title = `Coffee Clozers | ${propertyObject.address.streetAddress}, ${propertyObject.address.city}, ${propertyObject.address.state} ${propertyObject.address.zipcode}`;
				setProperty(propertyObject);
				setQueryMade(true);
				setLoading(false);

				if ( (propertyObject.cityId !== undefined && propertySearch === true) || cityId !== "" ) {
					setCityId(propertyObject.cityId);
					setCityState(`${propertyObject.address.city}, ${propertyObject.address.state}`)
                    getZIPData(propertySearch === false ? cityId : propertyObject.cityId);
                    fetchZIPBounds(propertyObject);
                    getGentrificationData(propertySearch === false ? cityId : propertyObject.cityId);
                }
				else if ( propertySearch === true && propertyObject.cityId === undefined ) {
					const getCityIdParams = {
						city: propertyObject.city,
						state: propertyObject.state,
						returnCityId: "True"
					};

					const getCityParams = await getCitySearch(getCityIdParams, false);
					const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
					const gatewayData = {
						type: "gateway",
						resourceId: "citySearchLive",
						queryString: getCityParams
					};
					const queryCityId = await cloudFunctionV2(gatewayURL, gatewayData);
					if ( queryCityId.status === 200 && queryCityId.body !== null ) {
						const cityIdBody = queryCityId.body;
						if ( cityIdBody.cityId !== undefined && cityIdBody.cityId !== null ) {
							setCityId(cityIdBody.cityId);
						}
					}
				}
			}
			else if ( getGateway.status > 399 && propertySearch === true ) {
				if ( devMode === true ) {
					console.log("get gateway bad status = ", getGateway);
				}
				if ( state !== null && state.creditRemoved === true && state.preSaved === false ) {
					userData.credits = userData.credits + 1;
					await setData(colRef, state.ipUser === true ? state.noUserId : userId, userData);	
				}
				console.log("get gateway bad status = ", getGateway);
				setNoProperty(true);
				setLoading(false);
			}
			else if ( propertySearch === true && getGateway.status === 200 && getGateway.body.prop === null ) {
				if ( devMode === true ) {
					console.log("get gateway bad status = ", getGateway);
				}
				if ( state !== null && state.creditRemoved === true && state.preSaved === false ) {
					userData.credits = userData.credits + 1;
					await setData(colRef, state.ipUser === true ? state.noUserId : userId, userData);	
				}
				setNoProperty(true);
				setLoading(false);
			}
			else if ( getGateway.status === 405 && propertySearch === false ) {
				setLoading(false);
				setUsePropertySearch(true);
			}
			else {
				navigate("/404", {
					state: state
				});
			}
		}

		async function checkEditedProperty(userId, propertyObject, down, int, loan, mortgage, userData, partnerAmendments, userSettings) {
			const colRef = "Users";
			const docRef = userId;
			const subColRef = "Properties";
			const subDocRef = `${propertyObject.zpid}`;
			const queryProperty = await getSubDocument(colRef, docRef, subColRef, subDocRef);
			if ( queryProperty.status === 200 ) {
				const amendedPropertyData = queryProperty.data.data;
				setEditedProperty(amendedPropertyData)
				setDownPayment(mortgage === true ? amendedPropertyData.downPayment : 100);
				setInterestRate(mortgage === true ? amendedPropertyData.interestRate : 0);
				setClosingPercentage(amendedPropertyData.closingCosts);

				const savedRehabNumbers = await checkSavedRehabNumber(userSettings, propertyObject, cityId, propertySearch);
				if ( partnerAmendments !== null && partnerAmendments.totalRehabCost !== undefined && partnerAmendments.totalRehabCost !== null )  {
					setTotalRehabCost(amendedPropertyData.totalRehabCost);
					if ( propertyObject.livingArea !== null ) {
						savedRehabNumbers.default = partnerAmendments.totalRehabCost / propertyObject.livingArea;
						setCostPerSqFoot(amendedPropertyData.totalRehabCost / propertyObject.livingArea);
						setDefaultCostPerSqFoot(savedRehabNumbers.default);
					}
				}
				else if ( amendedPropertyData.totalRehabCost !== undefined ) {
					setTotalRehabCost(amendedPropertyData.totalRehabCost);
					if ( propertyObject.livingArea !== null ) {
						setCostPerSqFoot(amendedPropertyData.totalRehabCost / propertyObject.livingArea);
					}
					setDefaultCostPerSqFoot(savedRehabNumbers.default);
				}
				else {
					setTotalRehabCost(savedRehabNumbers.totalCost);
					setCostPerSqFoot(savedRehabNumbers.costPerSqFoot);
					setDefaultCostPerSqFoot(savedRehabNumbers.default);
				}

				let checkedPrice = amendedPropertyData.price;
				if ( propertyObject.priceChange === true )  {
					const previouslySavedPrice = amendedPropertyData.price;
					const priceChangeHistory = propertyObject.priceChangeHistory;
					const oldPriceIndex = priceChangeHistory.findIndex(e => e.price === previouslySavedPrice);
					if ( oldPriceIndex !== -1 ) {
						checkedPrice = propertyObject.price;
					}
				}
				recalculateProfits(amendedPropertyData.financials, amendedPropertyData, checkedPrice, amendedPropertyData.totalRehabCost !== undefined ? amendedPropertyData.totalRehabCost : savedRehabNumbers.totalCost, setHighLevelProfits, amendedPropertyData.downPayment, propertyObject, setRecurringCosts, false, setSpreadsheetProperty);
			}
			else {
				writeDefaultEditedProperty(userId, propertyObject, down, int, loan, mortgage, userData, partnerAmendments, userSettings);
			}
		}

		async function savePartnerChanges(propertyObject, down, int, loan, mortgage, partnerAmendments) {
			// Partner programme is true so we're checking for amendments on each value.
			// If there are NO amendments, we resort back to defaults

			if ( partnerAmendments.downPayment !== undefined && partnerAmendments.downPayment !== null ) {
				down = partnerAmendments.downPayment

				if ( down === 100 ) {
					mortgage = false;
				}
				else {
					mortgage = true;
				}
			}

			if ( partnerAmendments.interestRate !== undefined && partnerAmendments.interestRate !== null ) {
				int = partnerAmendments.interestRate
			}

			const savedRehabNumbers = {
				totalCost: 0,
				costPerSqFoot: 0,
				default: 0
			};

			if ( partnerAmendments.totalRehabCost !== undefined && partnerAmendments.totalRehabCost !== null ) {
				savedRehabNumbers.totalCost = partnerAmendments.totalRehabCost;
				if ( propertyObject.livingArea !== null ) {
					savedRehabNumbers.costPerSqFoot = partnerAmendments.totalRehabCost / propertyObject.livingArea;
					savedRehabNumbers.default = partnerAmendments.totalRehabCost / propertyObject.livingArea;
				}
			}

			propertyObject.financials.totalRehabCost = savedRehabNumbers.totalCost;
			const newMortgage = await calculateMortgagePayment(propertyObject.price, down, int, 30);
			propertyObject.financials.mortgagePayment = newMortgage

			const writeDefault = await setDefaultEditedProperty("", propertyObject, down, int, loan, mortgage, savedRehabNumbers);
			if ( partnerAmendments.closingCosts !== undefined && partnerAmendments.closingCosts !== null ) {
				writeDefault.data.closingCosts = partnerAmendments.closingCosts
			}

			setSpreadsheetProperty(propertyObject);
			setEditedProperty(writeDefault.data);
			await recalculateProfits(writeDefault.data.financials, writeDefault.data, propertyObject.price, savedRehabNumbers.totalCost, setHighLevelProfits, down, propertyObject, setRecurringCosts, true, setSpreadsheetProperty);
			return true;
		}

		async function writeDefaultEditedProperty(userId, propertyObject, down, int, loan, mortgage, userData, partnerAmendments, userSettings) {
			let savedRehabNumbers = await checkSavedRehabNumber(userSettings, propertyObject, cityId, propertySearch);
			if ( partnerAmendments !== null && partnerAmendments.totalRehabCost !== undefined && partnerAmendments.totalRehabCost !== null ) {
				savedRehabNumbers = {
					totalCost: partnerAmendments.totalRehabCost,
					costPerSqFoot: 0,
					default: 0
				};

				if ( propertyObject.livingArea !== null && propertyObject.livingArea !== 0 ) {
					savedRehabNumbers.costPerSqFoot = partnerAmendments.totalRehabCost / propertyObject.livingArea;
					savedRehabNumbers.default = partnerAmendments.totalRehabCost / propertyObject.livingArea;
				}
			}

			const writeDefault = await setDefaultEditedProperty(userId, propertyObject, down, int, loan, mortgage, savedRehabNumbers);
			if ( writeDefault.status === 200 ) {
				setEditedProperty(writeDefault.data);
				setDownPayment(mortgage === true ? down : 100);
				setInterestRate(mortgage === true ? int : 0);
				setClosingPercentage(writeDefault.data.closingCosts);


				setTotalRehabCost(savedRehabNumbers.totalCost);
				setCostPerSqFoot(savedRehabNumbers.costPerSqFoot);
				setDefaultCostPerSqFoot(savedRehabNumbers.default);
				propertyObject.financials.totalRehabCost = savedRehabNumbers.totalCost;
				
				setSpreadsheetProperty(propertyObject);
				recalculateProfits(writeDefault.data.financials, writeDefault.data, propertyObject.price, savedRehabNumbers.totalCost, setHighLevelProfits, down, propertyObject, setRecurringCosts, true, setSpreadsheetProperty);
			}
		}

		async function getZIPData() {
			const data = {
				s3Data: {
					key: `output/city_zipcode_market_data_all_cities/${cityId}.json`,
					bucketName: "residentialpropertydata",
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, data);
			if ( queryData.status === 200 ) {
				const zipBody = queryData.body;
				zipBody.sort((a,b) => (a.medianPricePerSqft > b.medianPricePerSqft) ? 1 : ((b.medianPricePerSqft > a.medianPricePerSqft) ? -1 : 0));
				setZipData(zipBody);
			}
		}

		async function getGentrificationData() {
			const params = {
				s3Data: {
					key: `output/gentrification_map_all_cities/${cityId}.json`,
					bucketName: "residentialpropertydata",
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			if ( queryData.status === 200 ) {
				const gentrificationBody = queryData.body;
				setGentrificationData(gentrificationBody);
				const newArray = [];
				for (let index = 0; index < gentrificationBody.length; index++) {
					const element = gentrificationBody[index];
					const tract = element.tract_polygon;
					const zip = element.zip;
					const zipCode = zip.slice(4);
					gentrificationBody[index].zipcode = zipCode;

					const subArray = [];
					for (let i = 0; i < tract.length; i++) {
						const tractElement = tract[i];
						const tractObject = {
							lat: tractElement[0].lat,
							lng: tractElement[0].lng
						}
						subArray.push(tractObject);
					}
					newArray.push(subArray);
				}

				setGentrificationBounds(newArray);
			}
		}

		async function fetchZIPBounds(propertyObject) {
			const getGatewayParams = await getAPIGatewayListingsSimple(cityId);
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayParams
			};
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			let getGateway = await cloudFunctionV2(gatewayURL, gatewayData);

			if ( getGateway.status === 404 ) {
				const customS3Location = getGateway.body.s3_location;
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					getGateway = getS3Query;
				}
			}

			if ( getGateway.status === 200 ) {
				const propData = getGateway.body.props;
				if ( propData.length !== 0 ) {
					const propertyCity = propertyObject.address.city;
					const state = propertyObject.address.state;
					let city = "";
					if ( 
						( propertyCity === "Brooklyn" && state === "NY" ) ||
						( propertyCity === "Bronx" && state === "NY" ) ||
						( propertyCity === "Manhattan" && state === "NY" ) ||
						( propertyCity === "Staten Island" && state === "NY" ) ||
						( propertyCity === "Queens" && state === "NY" ) 
					) {
						city = "New York";
					}
					else {
						city = propertyCity
					}

					const colRef = "ZIP Codes";
					const docRef = `${city}, ${state}`;
					const subColRef = "ZIP Codes";
					const queryDoc = await getAllSubDocuments(colRef, docRef, subColRef);
					if ( queryDoc.status === 200 ) {
						const data = queryDoc.data;
						const docNames = queryDoc.docNames;

						const newZIPArray = [];
						for (let index = 0; index < docNames.length; index++) {
							const element = docNames[index];
							const zipIndex = propData.findIndex(e => e.address.zipcode === element);

							if ( zipIndex !== -1 ) {
								const bounds = data[index];
								const newObject = {
									zipCode: element,
									bounds: bounds
								}
								newZIPArray.push(newObject);
							}
						}

						const zipNameArray = [];
						const zipBoundArray = [];
						for (let index = 0; index < newZIPArray.length; index++) {
							const element = newZIPArray[index];
							const zipName = element.zipCode;
							zipNameArray.push(zipName);

							const bounds = element.bounds;
							zipBoundArray.push(bounds);
						}

						setZipNames(zipNameArray);
						setBounds(zipBoundArray);
					}
				}
			}
		};

		async function writeZPID(colRef, userId, credits, propertyObject, searches) {
			if ( searches.length === 0 ) {
				getOutOfHere();
			}
			else {
				const searchIndex = searches.findIndex(e => e.address === id);
				if ( searchIndex === -1 ) {
					getOutOfHere();
				}
				else {
					const searchObject = searches[searchIndex];
					if ( searchObject.zpid !== propertyObject.zpid ) {
						searchObject.zpid = propertyObject.zpid;
						const data = {
							searches: searches,
							credits: credits
						}
						await setMergeData(colRef, userId, data);
					}
				}
			}
		};

		const getOutOfHere = () => {
			navigate("/quick-report", {
				state: state
			});
		};

		if ( queryMade === false ) {
			queryUser();
		}
	}, [
		cityId, 
		id, 
		navigate, 
		editedProperty, 
		setAddBedroom, 
		setBlurred, 
		setBounds, 
		setCostPerSqFoot, 
		setDefaultCostPerSqFoot,
		setDownPayment,
		setEditedProperty,
		setFreeTrial,
		setFavourites,
		setGentrificationBounds,
		setGentrificationData,
		setHighLevelProfits,
		setInterestRate,
		setLoading,
		setMobileBar,
		setProperty,
		queryMade,
		setQueryMade,
		setRecurringCosts,
		setSpreadsheetProperty,
		setTotalRehabCost,
		setUsePropertySearch,
		setUserDetails,
		setZipData,
		setZipNames,
		propertySearch,
		state,
		devMode,
		setCityId,
		setNoProperty,
		partner,
		partnerId,
		setClosingPercentage,
		setCityState,
		setUserSettings,
		setSubscriptions,
		setUserId,
		testMode
	]);
}

export default PropertyEffect;