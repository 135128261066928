import React from 'react';
import '../styles/MobileCityCard.css'

function MobileCityCard(props) {
    const index = props.index;
    const item = props.item;
    const matchUpIcon = props.matchUpIcon;
    const selectCity = props.selectCity;
    const matchUpDescription = props.matchUpDescription;
    return (
        <div className="mobile-city-card-outer-container margin-medium">
            <div className="mobile-city-card-inner-container">
                <div className="mobile-city-card-number-container">
                    <span className="body-regular colour-secondary">
                        {index + 1}
                    </span>
                </div>
                <div className="mobile-city-card-text-container">
                    <div 
                        className="mobile-city-card-city-name-container margin-x-small"
                        onClick={() => selectCity(item)}
                    >        
                        <img
                            src={matchUpIcon(item)}
                            className="explore-markets-city-icon"
                            alt={`${item.city}, ${item.state}`}
                        />
                        <span className="body-semibold colour-link">
                            {item.city}, {item.state}
                        </span>
                    </div>
                    <div className="mobile-city-card-description-container">
                        <span className="body-regular colour-secondary">
                            {matchUpDescription(item)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MobileCityCard;