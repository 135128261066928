import React, { useState, useRef } from 'react';
import '../styles/NewFiltersModal.css';
import Exit from '../assets/exit.svg';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import { checkUserId, recordEvent, resetFilterType, saveSearchParams } from '../functions';
import { useNavigate } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import { CustomSelect, priceReductionList, ActionButton, colour, CustomRadio } from '../styles/GlobalStyles';
import { ChoosePropertyType, ChooseBudget, ChoosePropertySpecifics, ChooseValueAddOpportunity, EditNeighbourhoodGrade } from './';

function NewFiltersModal(props) {

	const setFiltersModalOpen = props.setFiltersModalOpen;
	const offMarket = props.offMarket;
	// const setLoading = props.setLoading;
	const searchParams = props.searchParams;
	const setSearchParams = props.setSearchParams;
	const filtersRef = useRef(null);
	const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
	const onlyADU = props.onlyADU;
	const setOnlyADU = props.setOnlyADU;
	const onlyExtraBedroom = props.onlyExtraBedroom;
	const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
	const onlySellerFinancing = props.onlySellerFinancing;
	const setOnlySellerFinancing = props.setOnlySellerFinancing;
	const priceReduction = props.priceReduction;
	const setPriceReduction = props.setPriceReduction;
	const priceReductionIndex = props.priceReductionIndex;
	const setPriceReductionIndex = props.setPriceReductionIndex;
	const hideFixerUppers = props.hideFixerUppers;
	const setHideFixerUppers = props.setHideFixerUppers;
	const onlyFixerUppers = props.onlyFixerUppers;
	const setOnlyFixerUppers = props.setOnlyFixerUppers;
	const setChangeMade = props.setChangeMade;
	const setPage = props.setPage;
	const fixerUpperOption = props.fixerUpperOption;
	const setFixerUpperOption = props.setFixerUpperOption;
	const fsbo = props.fsbo;
	const setFsbo = props.setFsbo;
	const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
	const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
	const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
	const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;
	// const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
	
	const [disabled, setDisabled] = useState(false);
	const navigate = useNavigate();

	const listingTypeRadios = [
        {
            label: "Listed by agent",
            value: 0
        },
        {
            label: "For sale by owner",
            value: 1
        }
    ];

	const changePriceReduction = (val) => {
		setPriceReductionIndex(val);
	};

	const changeFixerUpperRadio = (val) => {
		setFixerUpperOption(val);
		if ( val === "fu" ) {
			setHideFixerUppers(false);
			setOnlyFixerUppers(true);
		}
		else if ( val === "tk" ) {
			setHideFixerUppers(true);
			setOnlyFixerUppers(false);
		}
		else if ( val === "both" ) {
			setHideFixerUppers(false);
			setOnlyFixerUppers(false);
		}
	};

	const changeFSBOFilter = (val) => {
		setFsbo(val);
	};

	const saveChanges = async() => {
		const propertyTypeArray = [];
		for (let index = 0; index < propertyTypes.length; index++) {
			const element = propertyTypes[index];
			if ( element.included === true ) {
				propertyTypeArray.push(true);
			}
		}

		const getUserId = await checkUserId();
		if ( getUserId.userId === "" ) {
			navigate('/sign-up');
		}
		else {
			setDisabled(true);
			const newConstructionYears = [Number(constructionYears[0]), Number(constructionYears[1])];
			const labels = [
				"propertyType",
				"budgetRange",
				"propertySpecifics",
				"valueAdd",
				"more",
				"page"
			];

			const propertyTypesArray = propertyTypes.map(e => e.included ? e.abbreviation : null);
            const filteredPropertyTypesArray = propertyTypesArray.filter(value => value !== null);
            const commaSeparatedPropertyTypesString = filteredPropertyTypesArray.join(',');
            
			const propertyTypesObject = {
                propertyTypes: commaSeparatedPropertyTypesString
            };
            if ( commaSeparatedPropertyTypesString.includes("MFH") ) {
                propertyTypesObject.units = units;
            }
            else if ( commaSeparatedPropertyTypesString === "" ) {
                propertyTypesObject.propertyTypes = "MFH,SFH";
                propertyTypesObject.units = 1;
            }

			const propertySpecificsObject = {
                bedrooms: bedrooms,
                bathrooms: bathrooms,
                squareFootage: squareFootage
            };

			const lowConstructionYearLength = constructionYears[0].length;
            const highConstructionYearLength = constructionYears[1].length;
			if ( 
                constructionYears[0] !== "" && constructionYears[1] !== "" &&
                newConstructionYears[0] <= newConstructionYears[1] &&
                lowConstructionYearLength === 4 && highConstructionYearLength === 4
            ) {
				propertySpecificsObject.constructionYears = newConstructionYears;
			}
			else if ( constructionYears[0].length === 4 && constructionYears[1].length < 4 ) {
                propertySpecificsObject.constructionYears = [newConstructionYears[0], 2024];
                setConstructionYears([newConstructionYears[0], 2024]);
            }

			const valueAddObject = {
                onlyADU: onlyADU,
                onlyExtraBedroom: onlyExtraBedroom,
                hideFixerUppers: hideFixerUppers,
                onlyFixerUppers: onlyFixerUppers
            }

			const moreObject = {
				priceReduction: priceReduction,
                priceReductionIndex: priceReductionIndex,
                onlySellerFinancing: onlySellerFinancing,
				fsbo: fsbo,
				neighborhoodGradeMin: neighbourhoodGradeMin,
                neighborhoodGradeMax: neighbourhoodGradeMax
			};

			if ( priceReduction === false ) {
                delete moreObject.priceReductionIndex;
            }

			const values = [
				JSON.stringify(propertyTypesObject),
				JSON.stringify(budgetRange),
				JSON.stringify(propertySpecificsObject),
				JSON.stringify(valueAddObject),
				JSON.stringify(moreObject),
				1
			];
			setPage(1);
			await saveSearchParams(labels, values, searchParams, setSearchParams);
			recordEvent("Save Filters", {});
			setChangeMade("filters");
			setDisabled(false);
			closeFilters();
		}
	};

	const closeFilters = () => {
		setFiltersModalOpen(false);
		recordEvent("Close Filters", {});
	};

	const resetAllFilters = async() => {
		searchParams.delete("propertyType");
		searchParams.delete("budgetRange");
		searchParams.delete("propertySpecifics");
		searchParams.delete("valueAdd");
		searchParams.delete("more");
		await resetFilterType(
			"all",
			setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
            setOnlyADU,
            setOnlyExtraBedroom,
            setHideFixerUppers,
            setOnlyFixerUppers,
            setPriceReduction,
            setPriceReductionIndex,
            setOnlySellerFinancing,
			setFixerUpperOption,
            setFsbo
		);
		setSearchParams(searchParams);
		closeFilters();
		setChangeMade("filters");
	};

	const changeNeighbourhoodGrade = (val, type) => {
        if ( type === 0 ) {
            setNeighbourhoodGradeMin(val);    
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

	const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
		menuItemZIPs: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
			padding: '8px 36px'
		}
	};

	return (
		<div className="new-filters-modal-outer-container">
			<div className="new-filters-modal-sticky-title margin-medium">
				<div className="new-filters-modal-inner-sticky-title">
					<div className="new-filters-modal-title-container">
						<h2 className="body-regular colour-primary">
							Filters
						</h2>
					</div>
					<div 
						onClick={() => closeFilters()}
						className="new-filters-modal-exit-container"
					>
						<img
							src={Exit}
							className="filters-exit"
							alt="Close Modal"
						/>
					</div>
				</div>
			</div>
			<div ref={filtersRef} className="new-filters-modal-inner-container">
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Property Type
						</h3>
					</div>
					<ChoosePropertyType
						propertyTypes={propertyTypes}
						setPropertyTypes={setPropertyTypes}
						units={units}
						setUnits={setUnits}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Budget
						</h3>
					</div>
					<ChooseBudget
						budgetRange={budgetRange}
						setBudgetRange={setBudgetRange}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Property Specifics
						</h3>
					</div>
					<ChoosePropertySpecifics
						bedrooms={bedrooms}
						setBedrooms={setBedrooms}
						bathrooms={bathrooms}
						setBathrooms={setBathrooms}
						squareFootage={squareFootage}
						setSquareFootage={setSquareFootage}
						constructionYears={constructionYears}
						setConstructionYears={setConstructionYears}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Value-add
						</h3>
					</div>
					<ChooseValueAddOpportunity
						onlyADU={onlyADU}
						setOnlyADU={setOnlyADU}
						onlyExtraBedroom={onlyExtraBedroom}
						setOnlyExtraBedroom={setOnlyExtraBedroom}
						noFixerUpper={null}
						setNoFixerUpper={null}
						filtersModal={true}
						fixerUpperOption={fixerUpperOption}
						changeFixerUpperRadio={changeFixerUpperRadio}
						fixerUpperError={false}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Filters
						</h3>
					</div>
					<div className="new-filters-modal-section-container margin-x-large">
						<h4 className="body-semibold colour-primary margin-x-small">
							Price reduction
						</h4>
						<div className={"new-filters-modal-price-reduction-container " + (offMarket === true ? "filter-type-disabled" : "")}>
							<div className="new-filters-modal-price-reduction-inner-container">
								<div 
									className="price-reduction-checkbox-container"
									onClick={() => offMarket === true ? null : setPriceReduction(!priceReduction)}
								>
									{
										priceReduction === true ?
										<div className="price-reduction-checkbox">
										</div>
										:
										null
									}
								</div>
								<div className="filters-modal-option-text-outer-container">
									<span className="body-semibold colour-primary">
										Filter by price reduction
									</span>
								</div>
							</div>
							<div className="new-filters-modal-price-reduction-select-container">
								{
									offMarket === true ?
									<span className="body-regular colour-error">
										Price reductions don't apply to off-market properties
									</span>
									:
									null
								}
								{
									priceReduction === true && offMarket === false ?
									<Box>
										<FormControl fullWidth>
											<Select
												labelId="max-select-label"
												id="max-budget-select"
												value={priceReductionIndex}
												label=""
												onChange={(event) => changePriceReduction(event.target.value)}
												input={<CustomSelect />}
											>
												{
													priceReductionList.map((item, index) =>
														<MenuItem
															key={index}
															value={index}
															style={styles.menuItem}
															disabled={false}
														>
															{item.label}
														</MenuItem>	
													)
												}
											</Select>
										</FormControl>
									</Box>
									:
									null
								}
							</div>
						</div>
					</div>
					<div className="new-filters-modal-section-column margin-medium">
                        <div className="choose-value-add-opportunity-title-container margin-x-small">
                            <span className="body-semibold colour-primary">
                                Listing type
                            </span>
                        </div>
                        <div className="">
                            <FormControl className="new-filters-modal-radio-outer-container">
                                <RadioGroup
                                    aria-labelledby="fixer-upper-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={fsbo}
                                    className="choose-value-add-opportunity-property-condition-radios"
                                    onChange={(val) => changeFSBOFilter(val.target.value)}
                                >
                                    {
                                        listingTypeRadios.map((item, index) =>
                                            <FormControlLabel 
                                                value={item.value} 
                                                control={<CustomRadio />}
                                                className=""
                                                label={<span className="body-regular colour-primary">{item.label}</span>}
                                                key={index}
                                            />
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
					<div className="new-filters-modal-section-column">
						<EditNeighbourhoodGrade
                            neighbourhoodGradeMin={neighbourhoodGradeMin}
                            neighbourhoodGradeMax={neighbourhoodGradeMax}
                            changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                            filtersModal={true}
                        />
					</div>
				</div>
			</div>
			<div className="new-filters-modal-button-outer-container">
				<div className="new-filters-modal-reset-container">
					<span 
						className="new-filters-modal-reset-text"
						onClick={() => disabled === true ? null : resetAllFilters()}
					>
						Reset all
					</span>
				</div>
				<div className="new-filters-modal-button-inner-container">
					<div className="new-filters-modal-button-element-container">
						<ActionButton
							disabled={disabled}
							onClick={() => saveChanges()}
						>
							Save
						</ActionButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewFiltersModal;