import React, { useState, useRef } from 'react';
import '../styles/UploadImage.css';
import { uploadImage, checkUserId, makeId } from '../functions';
import { PlusLight } from '../assets';
import { useNavigate } from 'react-router-dom';

function UploadMultipleImages(props) {
    const textInput = useRef(null);
	const [loading, setLoading] = useState("");
    const [error, setError] = useState("");
    const saveFile = props.saveFile;
    const route = props.route;
    const subText = props.subText;
    const setImageLoading = props.setImageLoading;
    const navigate = useNavigate();

	const onChangeFile = async(event) => {
        event.stopPropagation();
        event.preventDefault();
        if ( event.target.files.length === 0 ) return;

        const files = event.target.files;
        const user = await checkUserId();
        const userId = user.userId;
        const uploadedFiles = [];

        for (let index = 0; index < files.length; index++) {
            setImageLoading(`Loading ${index + 1} of ${files.length}`);
            const element = files[index];
            const file = URL.createObjectURL(element);
            const type = event.target.files[0].type;
            
            if ( userId === "" ) {
                navigate("/sign-up");
            }
            else if ( type.includes("image") ) {
                setError("");
                const id = await makeId(10);
                const savePath = `Users/${userId}/${route}/${id}`;
                const uploading = await uploadImage(file, savePath, setLoading);
                const uploadedFile = {
                    title: element.name,
                    url: uploading,
                    id: id
                };
                uploadedFiles.push(uploadedFile);
            }
            else {
                setError("Please select an image!");
            }
        }
        setImageLoading("");
        saveFile(uploadedFiles, userId);
	}

    return (
        <div className="upload-image-outer-container">
            <div 
                onClick={() => textInput.current.click()}
                className="upload-image-container"
            >
                <input type="file" id="file" multiple="multiple" ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                {
                    loading === "" ?
                    <div className="upload-image-border-container">
                        <img
                            src={PlusLight}
                            className="upload-image-icon"
                            alt="Upload Document"
                        />
                    </div>
                    :
                    <div className="upload-image-border-container">
                        <h2 className="heading-large-semibold colour-primary">
                            {loading}
                        </h2>
                    </div>
                }
                {
                    subText === "" ?
                    null
                    :
                    <span className="body-regular-underline colour-link block-text margin-top-small">
                        {subText}
                    </span>
                }
            </div>
            {
                error === "" ?
                null
                :
                <span className="body-regular colour-error">
                    {error}
                </span>
            }
        </div>
    )
};

export default UploadMultipleImages;