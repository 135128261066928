import React from 'react';
import '../styles/ContactAgent.css';
import { PartnerBanner } from '../components';

function ContactAgent(props) {
	const property = props.property;
	const partnerData = props.partnerData;
	const state = props.state;
	return (
		<div className="contact-agent-outer-container">
			<div className="contact-agent-inner-container">
				<h2 className="heading-small-semibold colour-primary margin-x-small">
					Contact
				</h2>
				{
					partnerData !== null ?
					<PartnerBanner
						partnerData={partnerData}
						phoneNumber={partnerData.phoneNumber}
						profilePicLoading={""}
						profilePicError={""}
						textInput={null}
						openEditProfile={null}
						onChangeFile={null}
						editMode={false}
						property={property}
						state={state}
					/>
					:
					property.listedBy.displayName === null && 
					property.attributionInfo.agentName === null &&
					property.listedBy.businessName === null &&
					property.attributionInfo.brokerName === null &&
					property.attributionInfo.agentEmail === null &&
					property.attributionInfo.agentPhoneNumber === null
					?
					<div className="contact-agent-body-container">
						<span className="body-regular colour-secondary">
							Oh no! It appears this <span className="text-italic">very busy </span>
							real estate agent forgot to include their contact
							info in the listing. Not much we can do here...
						</span>
					</div>
					:
					<div className="contact-agent-body-container">
						{/* <div className="contact-agent-image-container">
						</div> */}
						<div className="contact-agent-text-container">
							<h3 className="body-regular margin-x-small">
								{
									property.listedBy.displayName !== null ?
										property.listedBy.displayName
										:
										property.attributionInfo.agentName
								}
							</h3>
							<h4 className="label-regular colour-secondary margin-x-small">
								{
									property.listedBy.businessName !== null ?
										property.listedBy.businessName
										:
										property.attributionInfo.brokerName
								}
							</h4>
							<div className="contact-agent-contact-details-container">
								{
									property.attributionInfo.agentPhoneNumber !== undefined &&
									property.attributionInfo.agentPhoneNumber !== null ?
									<a 
										href={`tel:${property.attributionInfo.agentPhoneNumber}`}
										className="body-semibold colour-primary no-underline"
									>
										{
											property.attributionInfo.agentPhoneNumber !== null ?
												property.attributionInfo.agentPhoneNumber
												:
												property.attributionInfo.brokerPhoneNumber
										}
									</a>
									:
									property.fsbo === true && property.fsboOwnerPhone !== undefined
									&& property.fsboOwnerPhone !== null ?
										<a 
											href={`tel:${property.fsboOwnerPhone}`}
											className="body-semibold colour-primary no-underline"
										>
											{property.fsboOwnerPhone}
										</a>
										:
										null
								}
								{
									property.attributionInfo.agentEmail !== undefined &&
									property.attributionInfo.agentEmail !== null
									?
									<span className="body-semibold colour-primary dot-spacing">
										•
									</span>
									:
									null
								}
								<a 
									href={`mailto:${property.attributionInfo.agentEmail}`}
									className="body-semibold colour-primary no-underline"
								>
									{
										property.attributionInfo.agentEmail
									}
								</a>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default ContactAgent;