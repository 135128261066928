import React, { useState, useEffect } from 'react';
import '../styles/StrategiesPanel.css';
import { ReportFinancialsBug, Chip, FinancialPreferencesBanner, StrategiesPanelFinancialOptions, RentTab, PurchaseTab, RehabTab, MonthlyCostsTab, StrategiesPanelFooter, StrategiesPanelTopLevel, MobileStrategiesPanel, HoldingCosts, StrategyTooltip } from './';
import { calculateARVSpread, calculateCashOnCash, calculateMortgagePayment, formatHighLevelProfits, savePropertyChanges, saveSpreadsheetProperty, checkUserSettingsOnCosts, recordEvent } from '../functions';
import { BedroomBlack, ADU } from '../assets';

function StrategiesPanel(props) {

    const property = props.property;
	const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
	const editedProperty = props.editedProperty;
	const downPayment = props.downPayment;
	const setDownPayment = props.setDownPayment;
	const loanYears = props.loanYears;
	const setHighLevelProfits = props.setHighLevelProfits;
	const interestRate = props.interestRate;
	const setInterestRate = props.setInterestRate;
	const closingPercentage = props.closingPercentage;
	const setClosingPercentage = props.setClosingPercentage;
	const userDetails = props.userDetails;
	const bugModal = props.bugModal;
	const setBugModal = props.setBugModal;
    const highLevelProfits = props.highLevelProfits;
	const blurred = props.blurred;
	const recurringCosts = props.recurringCosts;
	const spreadsheetProperty = props.spreadsheetProperty;
	const setSpreadsheetProperty = props.setSpreadsheetProperty;
	const costPerSqFoot = props.costPerSqFoot;
	const setCostPerSqFoot = props.setCostPerSqFoot;
	const totalRehabCost = props.totalRehabCost;
	const setTotalRehabCost = props.setTotalRehabCost;
	const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
	const strategyPanel = props.strategyPanel;
	const setStrategyPanel = props.setStrategyPanel;
	const userSettings = props.userSettings;
	const cityId = props.cityId;
	const defaultTotalRehabCost = props.defaultTotalRehabCost;
	const defaultDownPayment = props.defaultDownPayment;
	const defaultInterestRate = props.defaultInterestRate;
	const viewArvComps = props.viewArvComps;
	const viewRentalComps = props.viewRentalComps;
	const recommendedStrategy = props.recommendedStrategy;
	const getStrategy = props.getStrategy;
	const mobileBar = props.mobileBar;
	const favouriteDisable = props.favouriteDisable;
	const setFavouriteDisable = props.setFavouriteDisable;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const navigate = props.navigate;
	const toggleMobileBottomBar = props.toggleMobileBottomBar;
	const setActiveToast = props.setActiveToast;
	const propertyTaxSetting = props.propertyTaxSetting;
	const setToastText = props.setToastText;
	const setEditedProperty = props.setEditedProperty;

	const [firstRender, setFirstRender] = useState(false);
	const [brrrrPropertyTaxRate] = useState(propertyTaxSetting === null ? property.apiFinancialParams.propertyTaxRate : propertyTaxSetting);
	const [monthlyCosts, setMonthlyCosts] = useState([]);
	const [arv, setArv] = useState(property.arvComps === true ? property.arvCompsStats.arvPrice : property.price);
	const [defaultArv] = useState(property.arvComps === true ? property.arvCompsStats.arvPrice : property.price);
	const [arvFinancingPercentage, setArvFinancingPercentage] = useState(75);
	const arvFinancing = (arv * Number(arvFinancingPercentage)) / 100;
	const [customArv, setCustomArv] = useState(0);
    const [unitsRent, setUnitsRent] = useState([]);
	const [houseHackUnitsRent, setHouseHackUnitsRent] = useState([]);
	const [houseHackBedroomsRent, setHouseHackBedroomsRent] = useState([]);
	const [multiUnitRent, setMultiUnitRent] = useState([]);
	const [houseHackMultiUnitsRent, setHouseHackMultiUnitsRent] = useState([]);
    const [financialOptions, setFinancialOptions] = useState([
        {
            title: "Rent",
            active: true
        },
        {
            title: "Purchase",
            active: false
        },
        {
            title: strategyPanel < 2 ? "Minor repairs" : "Rehab & ARV",
            active: false
        },
        {
            title: "Monthly costs",
            active: false
        },
		{
            title: "Holding costs",
            active: false
        }
    ]);

	// const [rentAccordion, setRentAccordion] = useState(true);
    const strategies = [
		{
            text: "Turnkey (Buy & hold)",
			title: "Buy-and-Hold Rentals",
			description: "Re-calculate properties for traditional buy and hold investing. This is a long-term investment strategy of purchasing properties to hold and rent them for steady cash flow and potential appreciation.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#9328a712c81b4f01900bc6168f702b00"
        },
		{
            text: "House Hack",
			title: "Househacking",
			description: "Re-calculate properties for house-hacking. Househacking means buying an investment property with the intention of living in one of the units or rooms (for at least one year) and renting out the others to cover expenses and generate income. This strategy allows the investor to make a much smaller down payment. We calculate this with a 5% down payment.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#7b629a671d234f23ab66f6c4a32887ac"
        },
		{
            text: "BRRRR",
			title: "BRRRR (Buy, Rehab, Rent, Refinance, Repeat)",
			description: "Re-calculate properties for BRRRR method. BRRRR is a real estate strategy involving buying undervalued properties, renovating for added value, renting out for income, refinancing to recover capital, and repeating the cycle.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#7309b252bfd44db087c07807b8cf15ce"
        },
		{
            text: "Fix & Flip",
			title: "Fix & Flip",
			description: "Re-calculate properties for househack strategy. Fix & flip is the short-term strategy of buying distressed properties, renovating them, and selling at a higher price to make a profit.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#c7ddef70eef54360b6558e0dca6d80ba"
        },
    ];

    const [rent, setRent] = useState(property.financials.rent);
	const [houseHackRent, setHouseHackRent] = useState(0);
	const [multiUnitRentTotal, setMultiUnitRentTotal] = useState(0);
	const [multiUnitHouseHackRentTotal, setMultiUnitHouseHackRentTotal] = useState(0);
	const [unitLiving, setUnitLiving] = useState(0);
	const [multiUnitTypeUnitLiving, setMultiUnitTypeUnitLiving] = useState([0, 0]);
	const [defaultHouseHackRent, setDefaultHouseHackRent] = useState(0);
	const [defaultMultiUnitRent, setDefaultMultiUnitRent] = useState(0);
	const [defaultMultiUnitHouseHackRentTotal, setDefaultMultiUnitHouseHackRentTotal] = useState(0);
	const [multiUnitRentRatio, setMultiUnitRentRatio] = useState([1, 1]);
	const [customDiscount, setCustomDiscount] = useState(Math.round(((property.price - property.price) / property.price) * 100));
	const [customPrice, setCustomPrice] = useState(property.price);
	const [monthlyHoldingCosts, setMonthlyHoldingCosts] = useState(0);
	const [holdingPeriod, setHoldingPeriod] = useState(4);
	const [totalHoldingCosts, setTotalHoldingCosts] = useState(monthlyHoldingCosts * holdingPeriod);
	const [defaultValuesPanel, setDefaultValuesPanel] = useState([false, false, false, false]);
	const [valueAddOptions, setValueAddOptions] = useState(
        property.addBedOpportunity === true && property.zonedForAdu === true ?
        [
            {
                label: "Add a bedroom",
                active: false,
                icon: BedroomBlack,
                inputLabel: "Additional rent income",
				value: 0,
				original: 0
            },
            {
                label: "ADU",
                active: false,
                icon: ADU,
                inputLabel: "ADU rent income",
				value: 0,
				original: 0
            }
        ]
        :
        property.addBedOpportunity === true ?
        [
            {
                label: "Add a bedroom",
                active: false,
                icon: BedroomBlack,
                inputLabel: "Additional rent income",
				value: 0,
				original: 0
            }
        ]
        :
        property.zonedForAdu === true ?
        [
            {
                label: "ADU",
                active: false,
                icon: ADU,
                inputLabel: "ADU rent income",
				value: 0,
				original: 0
            }
        ]
        :
        []
    );
	const [doneSettings, setDoneSettings] = useState(true);
	const [profitChange, setProfitChange] = useState(false);
	const [strategyHover, setStrategyHover] = useState(null);
	const strategiesPanelHoverIndex = `strategies-panel-hover-${strategyHover}`;
	const maxPercentage = -100;
	const closingCost = (customPrice * Number(closingPercentage)) / 100;
	const downPaymentPercentage = downPayment / 100;
	const downPaymentCost = (customPrice * downPaymentPercentage);
	const cashInDeal = (Number(downPaymentCost) + Number(totalRehabCost) + Number(closingCost) + Number(totalHoldingCosts)) - Number(arvFinancing);

    useEffect(() => {

		// Multi family home units for BOTH 1 unit layout AND house hack 1 unit layout
        const checkUnits = () => {
            const propertyUnits = property.units;
            const propertyRent = property.financials.rent;
            const newUnits = [];
			const newHouseHackUnits = [];
            for (let index = 0; index < propertyUnits; index++) {
                const newUnit = {
                    value: Math.round(propertyRent / propertyUnits),
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);

				const houseHackRentableUnits = propertyUnits - 1;
				const newHouseHackUnit = {
                    value: Math.round(houseHackRentableUnits === 0 ? 0 : index === unitLiving ? 0 : propertyRent / propertyUnits),
                    label: `Unit ${index + 1}`
                };
                newHouseHackUnits.push(newHouseHackUnit);
            };
            setUnitsRent(newUnits);
			setHouseHackUnitsRent(newHouseHackUnits);
			setHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
			setDefaultHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
        };

		// Multi family home units for multi unit layout
		// AND house hack multi unit layout
		const checkUniqueUnits = () => {
			const uniqueUnits = property.uniqueUnitPropertyDetails;
			const letters = ["A", "B", "C", "D"];
			
			const newUnits = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				const unitFrequency = Number(element.frequency);
				const newFrequency = {
					units: [],
					label: `Layout ${letters[index]}`
				};

				for (let ii = 0; ii < unitFrequency; ii++) {
					const newObject = {
						value: Number(element.rentcast_rent),
						original: Number(element.rentcast_rent),
						label: `Unit ${ii + 1}${letters[index]}`
					};
					newFrequency.units.push(newObject);
				};
				newUnits.push(newFrequency);
			};
			setMultiUnitRent(newUnits);
			const newTotal = newUnits.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);
			setDefaultMultiUnitRent(newTotal);

			const uniqueRentArray = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				uniqueRentArray.push(Number(element.rentcast_rent));
			};
			const minimumRentValue = Math.min(...uniqueRentArray);
			const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);
			setMultiUnitTypeUnitLiving([minimumValueIndex, 0]);

			const newHouseHackUnits = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				const unitFrequency = Number(element.frequency);
				const newFrequency = {
					units: [],
					label: `Layout ${letters[index]}`
				};

				for (let ii = 0; ii < unitFrequency; ii++) {
					const newObject = {
						value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent),
						original: Number(element.rentcast_rent),
						label: `Unit ${ii + 1}${letters[index]}`
					};
					newFrequency.units.push(newObject);
				};
				newHouseHackUnits.push(newFrequency);
			};
			setHouseHackMultiUnitsRent(newHouseHackUnits);
			const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);
			setDefaultMultiUnitHouseHackRentTotal(newHouseHackTotal);

			if ( uniqueRentArray.length > 1 ) {
				const firstRentRate = uniqueRentArray[0];
				const secondRentRate = uniqueRentArray[1];
				const ratio = secondRentRate / firstRentRate;
				setMultiUnitRentRatio([1, ratio]);
			}
		};

		// Single family home bedrooms for house hack
		const checkBedrooms = () => {
			const bedrooms = property.bedrooms !== null && property.bedrooms !== undefined ? property.bedrooms : 1;
			const newHouseHackBedrooms = [];
			const totalBedroomsRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
			const individualBedroomRent = totalBedroomsRent / bedrooms;
			for (let index = 0; index < bedrooms; index++) {
				const newBedroom = {
					value: Math.round(index === unitLiving ? 0 : individualBedroomRent),
					label: `Bedroom ${index + 1}`
				};
				newHouseHackBedrooms.push(newBedroom);
			};
			setHouseHackBedroomsRent(newHouseHackBedrooms);
			setHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
			setDefaultHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
		};

		// Fucntion that checks the property type and the selected
		// strategy panel, and returns the appropriate rent estimate
		const getAppropriateRentEstimateEffect = () => {
			const defaultRent = property.financials.rent;
			if ( strategyPanel === 1 ) {
				// This is for house hack mode

				if ( property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" ) {
					// Get house hack bedroom rent
					const houseHackTotalRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
					const bedrooms = property.bedrooms !== undefined && property.bedrooms !== null ? property.bedrooms : 1;
					const individualBedroomRent = houseHackTotalRent / bedrooms;
					const totalBedroomsRent = (individualBedroomRent * (bedrooms - 1));
					return Number(totalBedroomsRent);
				}
				else if ( multipleUniqueUnits === true ) {
					const uniqueUnits = property.uniqueUnitPropertyDetails;
					const uniqueRentArray = [];
					for (let index = 0; index < uniqueUnits.length; index++) {
						const element = uniqueUnits[index];
						uniqueRentArray.push(Number(element.rentcast_rent));
					};
					const minimumRentValue = Math.min(...uniqueRentArray);
					const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);

					const newHouseHackUnits = [];
					for (let index = 0; index < uniqueUnits.length; index++) {
						const element = uniqueUnits[index];
						const unitFrequency = Number(element.frequency);
						const newFrequency = {
							units: []
						};

						for (let ii = 0; ii < unitFrequency; ii++) {
							const newObject = {
								value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent)
							};
							newFrequency.units.push(newObject);
						};
						newHouseHackUnits.push(newFrequency);
					};
					const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
						// Add the 'value' property to the accumulator
						accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);

					return Number(newHouseHackTotal);
				}
				else {
					const units = property.units !== null && property.units !== undefined ? property.units : 1;
					if ( units === 1 ) {
						return Number(defaultRent);
					}
					else {
						const perUnitRent = Number(defaultRent) / units;
						const totalHouseHackUnitRent = perUnitRent * (units - 1);
						return totalHouseHackUnitRent;
					}
				}
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					return Number(defaultRent);
				}
				else {
					return Number(defaultRent);
				}
			}
		};

		// Function that gets you the total value add rent
		const getTotalValueAddRentEffect = () => {
			const totalValueAddRent = valueAddOptions.reduce((accumulator, item) => {
				if ( item.active === true ) {
					accumulator += Number(item.value);
				}
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			return totalValueAddRent;
		};

		const formatMonthlyCosts = async() => {
			// This is where we format monthly costs to be based off of user settings
			const newArray = [
				{
					label: "Property costs",
					inputs: recurringCosts.slice(0, 4)
				},
				{
					label: "Operating expenses",
					inputs: recurringCosts.slice(4, 6)
				},
				{
					label: "Upkeep",
					inputs: recurringCosts.slice(6, 8)
				}
			];
			const defaultRentVal = await getAppropriateRentEstimateEffect();
			const defaultValueAddRent = await getTotalValueAddRentEffect();
			const relevantRent = defaultRentVal + defaultValueAddRent;

			for (let index = 0; index < newArray.length; index++) {
				const element = newArray[index];
				for (let ii = 0; ii < element.inputs.length; ii++) {
					const input = element.inputs[ii];
					const userSettingOriginal = await checkUserSettingsOnCosts(input, userSettings, cityId, index, ii, customPrice, relevantRent, arv);

					if ( userSettingOriginal.change === true ) {
						// Values based on user settings
						newArray[index].inputs[ii].percentage = true;
						newArray[index].inputs[ii].original = Number(userSettingOriginal.original.toFixed(2));
						newArray[index].inputs[ii].value = Number(userSettingOriginal.original.toFixed(2));
						newArray[index].inputs[ii].percentageVal = userSettingOriginal.percentageVal;
					}
					else {
						// Values based on our defaults
						newArray[index].inputs[ii].percentage = false;
						if ( index === 0 ) {
							const value = Number(newArray[index].inputs[ii].value) * 12;
							const percentageVal = (value / customPrice) * 100;
							newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
						}
						else {
							const value = Number(newArray[index].inputs[ii].value);
							const percentageVal = (value / relevantRent) * 100;
							newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
						}
						if ( input.original === undefined ) {
							newArray[index].inputs[ii].original = input.value;
						}
					}

					if ( index === 0 && ii === 3 ) {
						const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * arv) / 12).toFixed(2);
						newArray[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
						newArray[0].inputs[3].brrrrOriginal = Number(brrrrPropertyTax);
						newArray[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
					}
				}
			}
			saveHoldingCostTotal(newArray);
			setMonthlyCosts(newArray);
			saveDefaultHighLevelProfits(newArray);
		};

		const checkUserSettings = () => {
			if ( userSettings !== null ) {
				const userRecurringCosts = userSettings.recurringCosts;
				let nonDefault = false;

				for (let index = 0; index < userRecurringCosts.length; index++) {
					const element = userRecurringCosts[index];
					if ( element.default === false ) {
						nonDefault = true;
					}
				}

				if ( userSettings.rehabCosts.default === false ) {
					nonDefault = true;
				}

				if ( nonDefault === false ) {
					setDoneSettings(false);
				}
			}
			else {
				setDoneSettings(false);
			}
		};

		const saveDefaultHighLevelProfits = async(newArray) => {
			if ( highLevelProfits === "" ) {
				const relevantRent = await getAppropriateRentEstimateEffect();
				const defaultValueAddRent = await getTotalValueAddRentEffect();
				const getNewMonthlyHoldingCosts = await getNewHoldingCostsEffect(newArray);
				const newTotalHoldingCosts = getNewMonthlyHoldingCosts * holdingPeriod;

				const finalRent = Number(relevantRent) + Number(defaultValueAddRent);
				const totalMonthlyCosts = newArray.reduce((accumulator, currentValue) => {
					currentValue.inputs.forEach(item => {
						if ( item.label === "Management fee" && strategyPanel === 1 ) {
		
						}
						else {
							accumulator += Number(item.value);
						}
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);

				const newVals = {
					rent: finalRent,
					downPayment: downPaymentCost,
					interestRate: Number(interestRate),
					closingCost: Number(closingCost),
					rehab: Number(totalRehabCost),
					monthlyCosts: totalMonthlyCosts,
					price: Number(customPrice),
					arv: Number(arv),
					holdingCosts: Number(newTotalHoldingCosts),
					arvFinancing: (arv * Number(arvFinancingPercentage)) / 100
				};
				formatHighLevelProfits(
					newVals,
					strategyPanel,
					setHighLevelProfits
				);
				setProfitChange(true);
			}
		}

		const saveEditedProfits = () => {
			let relevantRent;
			if ( editedProperty.strategy === 1 ) {
				// This is for house hack mode

				if ( property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" ) {
					// Get house hack bedroom rent
					relevantRent = editedProperty.houseHackRent;
				}
				else if ( multipleUniqueUnits === true ) {
					relevantRent = editedProperty.multiUnitHouseHackRentTotal;
				}
				else {
					relevantRent = editedProperty.houseHackRent;
				}
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					relevantRent = editedProperty.multiUnitRentTotal;
				}
				else {
					relevantRent = editedProperty.rent;
				}
			}

			const defaultValueAddRent = editedProperty.valueAddOptions.reduce((accumulator, item) => {
				if ( item.active === true ) {
					accumulator += Number(item.value);
				}
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			const finalRent = Number(relevantRent) + Number(defaultValueAddRent);
			const totalMonthlyCosts = editedProperty.monthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label === "Management fee" && editedProperty.strategy === 1 ) {
	
					}
					else if ( item.label === "Property taxes" && editedProperty.strategy === 2 ) {
						accumulator += Number(item.brrrrValue);
					}
					else {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			const checkArvFinancingPercentage = editedProperty.arvFinancingPercentage;
			const newVals = {
				rent: finalRent,
				downPayment: (editedProperty.downPayment / 100) * editedProperty.price,
				interestRate: editedProperty.interestRate,
				closingCost: (editedProperty.closingCost / 100) * editedProperty.price,
				rehab: editedProperty.rehab,
				monthlyCosts: totalMonthlyCosts,
				price: editedProperty.price,
				arv: editedProperty.arv,
				holdingCosts: editedProperty.totalHoldingCosts,
				arvFinancing: (editedProperty.arv * Number(checkArvFinancingPercentage)) / 100
			};
			formatHighLevelProfits(
				newVals,
				editedProperty.strategy,
				setHighLevelProfits
			);
			
			setProfitChange(true);
		};

		const saveEditedValues = () => {
			setValueAddOptions(editedProperty.valueAddOptions);
			setMonthlyCosts(editedProperty.monthlyCosts);
			setRent(editedProperty.rent);
			setHouseHackRent(editedProperty.houseHackRent);
			setUnitsRent(editedProperty.unitsRent);
			setHouseHackUnitsRent(editedProperty.houseHackUnitsRent);
			setHouseHackBedroomsRent(editedProperty.houseHackBedroomsRent);
			setMultiUnitRent(editedProperty.multiUnitRent);
			setMultiUnitRentTotal(editedProperty.multiUnitRentTotal);
			setMultiUnitRentRatio(editedProperty.multiUnitRentRatio);
			setHouseHackMultiUnitsRent(editedProperty.houseHackMultiUnitsRent);
			setMultiUnitHouseHackRentTotal(editedProperty.multiUnitHouseHackRentTotal);
			setMultiUnitTypeUnitLiving(editedProperty.multiUnitTypeUnitLiving);
			setUnitLiving(editedProperty.unitLiving);
			setDownPayment(editedProperty.downPayment);
			setInterestRate(editedProperty.interestRate);
			setClosingPercentage(editedProperty.closingCost);
			setTotalRehabCost(editedProperty.rehab);
			setCustomPrice(editedProperty.price);
			setArv(editedProperty.arv);
			setTotalHoldingCosts(editedProperty.totalHoldingCosts);
			setHoldingPeriod(editedProperty.holdingPeriod);
			setMonthlyHoldingCosts(editedProperty.monthlyHoldingCosts);
			setCostPerSqFoot(editedProperty.costPerSqFoot);
			setArvFinancingPercentage(editedProperty.arvFinancingPercentage);
			saveEditedProfits();

			if ( getStrategy === null ) {
				setStrategyPanel(editedProperty.strategy);
			}

			if ( property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === false ) {
				const propertyUnits = property.units;
				const propertyRent = property.financials.rent;
				const newHouseHackUnits = [];
				for (let index = 0; index < propertyUnits; index++) {
					const houseHackRentableUnits = propertyUnits - 1;
					const newHouseHackUnit = {
						value: Math.round(houseHackRentableUnits === 0 ? 0 : index === unitLiving ? 0 : propertyRent / propertyUnits),
						label: `Unit ${index + 1}`
					};
					newHouseHackUnits.push(newHouseHackUnit);
				};
				setDefaultHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
			}
			else if ( property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === true ) {
				const uniqueUnits = property.uniqueUnitPropertyDetails;
				
				const newUnits = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					const unitFrequency = Number(element.frequency);
					const newFrequency = {
						units: [],
					};

					for (let ii = 0; ii < unitFrequency; ii++) {
						const newObject = {
							value: Number(element.rentcast_rent)
						};
						newFrequency.units.push(newObject);
					};
					newUnits.push(newFrequency);
				};

				const newTotal = newUnits.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					// Add the 'value' property to the accumulator
					accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setDefaultMultiUnitRent(newTotal);

				const uniqueRentArray = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					uniqueRentArray.push(Number(element.rentcast_rent));
				};
				const minimumRentValue = Math.min(...uniqueRentArray);
				const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);

				const newHouseHackUnits = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					const unitFrequency = Number(element.frequency);
					const newFrequency = {
						units: []
					};

					for (let ii = 0; ii < unitFrequency; ii++) {
						const newObject = {
							value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent)
						};
						newFrequency.units.push(newObject);
					};
					newHouseHackUnits.push(newFrequency);
				};
				const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					// Add the 'value' property to the accumulator
					accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setDefaultMultiUnitHouseHackRentTotal(newHouseHackTotal);
			}
			else if ( houseHackBedroomsRent.length === 0 && property.propertyTypeDimension === "Single Family" && multipleUniqueUnits === false ) {
				const bedrooms = property.bedrooms !== null && property.bedrooms !== undefined ? property.bedrooms : 1;
				const newHouseHackBedrooms = [];
				for (let index = 0; index < bedrooms; index++) {
					const totalBedroomsRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
					const individualBedroomRent = totalBedroomsRent / bedrooms;
					const newBedroom = {
						value: Math.round(index === unitLiving ? 0 : individualBedroomRent)
					};
					newHouseHackBedrooms.push(newBedroom);
				};
				setDefaultHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
			}
		};

		const changeToRehab = () => {
			const newFinancialOptions = [...financialOptions];
			for (let index = 0; index < newFinancialOptions.length; index++) {
				newFinancialOptions[index].active = false;
			}
			newFinancialOptions[2].active = true;
			setFinancialOptions(newFinancialOptions);
		};

		// Function that checks whether default values have been changed
		// so that we can add a change dot where necessary on the strategies panel
		const checkDefaultValues = async() => {
			const defaultValuesPanelClone = [...defaultValuesPanel];
			let getPropertyRent;

			if ( strategyPanel === 1 ) {
				// This is for house hack mode

				if ( multipleUniqueUnits === true ) {
					const finalRent = Number(multiUnitHouseHackRentTotal);
					const defaultValue = Number(defaultMultiUnitHouseHackRentTotal);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
				else {
					const finalRent = Number(houseHackRent);
					const defaultValue = Number(defaultHouseHackRent);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					const finalRent = Number(multiUnitRentTotal);
					const defaultValue = Number(defaultMultiUnitRent);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
				else {
					const finalRent = Number(rent);
					const defaultValue = property.financials.rent;
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
			}

			const getValueAddRentTotal = valueAddOptions.reduce((accumulator, item) => {
				if ( item.active === true ) {
					accumulator += Number(item.value);
				}
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			for (let index = 0; index < defaultValuesPanelClone.length; index++) {
				if ( index === 0 ) {
					if ( getPropertyRent === true || getValueAddRentTotal > 0 ) {
						defaultValuesPanelClone[index] = true;
					}
					else {
						defaultValuesPanelClone[index] = false;
					}
				}
				else if ( index === 1 ) {
					if ( 
							Number(customPrice) === property.price &&
							Number(defaultDownPayment) === Number(downPayment) &&
							Number(defaultInterestRate) === Number(interestRate)
						) {
						defaultValuesPanelClone[index] = false;
					}
					else {
						defaultValuesPanelClone[index] = true;
					}
				}
				else if ( index === 2 ) {
					if ( Number(totalRehabCost) === Number(defaultTotalRehabCost) && Number(defaultArv) === Number(arv) ) {
						defaultValuesPanelClone[index] = false;
					}
					else {
						defaultValuesPanelClone[index] = true;
					}
				}
				else if ( index === 3 ) {
					let monthlyCostCheck = false;
					for (let ii = 0; ii < monthlyCosts.length; ii++) {
						const element = monthlyCosts[ii];
						for (let iii = 0; iii < element.inputs.length; iii++) {
							if ( ii === 0 && iii === 0 ) {
								// Skip mortgage
								continue;
							}
							const input = element.inputs[iii];
							if ( strategyPanel === 2 && ii === 0 && iii === 3 && Number(input.brrrrOriginal) !== Number(input.brrrrValue) ) {
								monthlyCostCheck = true;
							}
							else if ( strategyPanel === 2 && ii === 0 && iii === 3 && Number(input.brrrrOriginal) === Number(input.brrrrValue) ) {

							}
							else if ( Number(input.original) !== Number(input.value) ) {
								monthlyCostCheck = true;
							}
						}
					}
					defaultValuesPanelClone[index] = monthlyCostCheck;
				}	
			}
			setDefaultValuesPanel(defaultValuesPanelClone);
		};

		// Get holding cost total
		const getNewHoldingCostsEffect = (costs) => {
			const totalMonthlyCosts = costs.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label.includes("HOA") || item.label.includes("Insurance") || item.label.includes("Property tax") ) {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			return totalMonthlyCosts;
		}

		// Save holding cost total
		const saveHoldingCostTotal = (costs) => {
			const totalMonthlyCosts = costs.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label.includes("HOA") || item.label.includes("Insurance") || item.label.includes("Property tax") ) {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMonthlyHoldingCosts(Number(totalMonthlyCosts.toFixed(2)));
			setTotalHoldingCosts(Number((totalMonthlyCosts * holdingPeriod).toFixed(2)));
		};

		if ( profitChange === true ) {
			setProfitChange(false);
			const saveObject = {
				valueAddOptions: valueAddOptions,
				monthlyCosts: monthlyCosts,
				rent: Number(rent),
				houseHackRent: Number(houseHackRent),
				unitsRent: unitsRent,
				houseHackUnitsRent: houseHackUnitsRent,
				houseHackBedroomsRent: houseHackBedroomsRent,
				multiUnitRent: multiUnitRent,
				multiUnitRentTotal: Number(multiUnitRentTotal),
				multiUnitRentRatio: multiUnitRentRatio,
				houseHackMultiUnitsRent: houseHackMultiUnitsRent,
				multiUnitHouseHackRentTotal: Number(multiUnitHouseHackRentTotal),
				multiUnitTypeUnitLiving: multiUnitTypeUnitLiving,
				unitLiving: Number(unitLiving),
				downPayment: Number(downPayment),
				interestRate: Number(interestRate),
				closingCost: Number(closingPercentage),
				rehab: Number(totalRehabCost),
				costPerSqFoot: Number(costPerSqFoot),
				price: Number(customPrice),
				arv: Number(arv),
				totalHoldingCosts: Number(totalHoldingCosts),
				holdingPeriod: Number(holdingPeriod),
				monthlyHoldingCosts: Number(monthlyHoldingCosts),
				strategy: strategyPanel,
				arvFinancingPercentage: Number(arvFinancingPercentage),
			};
			savePropertyChanges(saveObject, property.zpid);
			checkDefaultValues();
			setEditedProperty(saveObject);
		}

		if ( firstRender === false ) {
			setFirstRender(true);
			checkUserSettings();

			if ( strategyPanel === 3 ) {
				changeToRehab()
			}

			if ( editedProperty === null ) {
				// If no edited values are present, resort to default
				formatMonthlyCosts();
				if ( unitsRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === false ) {
					checkUnits();
				}
				else if ( multiUnitRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === true ) {
					checkUniqueUnits();
				}
				else if ( houseHackBedroomsRent.length === 0 && (property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse") ) {
					checkBedrooms();
				}
			}
			else {
				saveEditedValues();
			}
		}
    }, [
		property.financials.rent, 
		property.units, 
		unitsRent.length, 
		property.propertyTypeDimension, 
		unitLiving, 
		property.bedrooms, 
		rent,
		houseHackUnitsRent.length,
		houseHackBedroomsRent.length,
		multipleUniqueUnits,
		property.uniqueUnitPropertyDetails,
		multiUnitRent.length,
		monthlyCosts.length,
		recurringCosts,
		customPrice,
		houseHackRent,
		multiUnitRentTotal,
		multiUnitHouseHackRentTotal,
		strategyPanel,
		arv,
		closingCost,
		downPaymentCost,
		highLevelProfits,
		interestRate,
		setHighLevelProfits,
		totalHoldingCosts,
		totalRehabCost,
		userSettings,
		valueAddOptions,
		profitChange,
		closingPercentage,
		downPayment,
		houseHackBedroomsRent,
		houseHackMultiUnitsRent,
		houseHackUnitsRent,
		monthlyCosts,
		multiUnitRent,
		multiUnitRentRatio,
		multiUnitTypeUnitLiving,
		unitsRent,
		holdingPeriod,
		monthlyHoldingCosts,
		property.zpid,
		costPerSqFoot,
		editedProperty,
		setClosingPercentage,
		setCostPerSqFoot,
		setDownPayment,
		setInterestRate,
		setStrategyPanel,
		setTotalRehabCost,
		property.houseHackingFeatures.totalRentByBedroom,
		property.houseHackingFlag,
		cityId,
		defaultArv,
		defaultDownPayment,
		defaultInterestRate,
		defaultTotalRehabCost,
		defaultValuesPanel,
		property.price,
		defaultHouseHackRent,
		defaultMultiUnitHouseHackRentTotal,
		defaultMultiUnitRent,
		financialOptions,
		getStrategy,
		arvFinancingPercentage,
		brrrrPropertyTaxRate,
		setEditedProperty,
		firstRender
	]);

	// Function for changing strategy
    const changeStrategyPanel = async(index) => {
        setStrategyPanel(index);

		recordEvent("Change Strategy", {
			strategy: strategies[index].text
		});

        const newFinancialOptions = [...financialOptions];
        if ( index < 2 ) {
            newFinancialOptions[2].title = "Minor repairs";
			if ( newFinancialOptions[4].active === true ) {
				newFinancialOptions[4].active = false;
				newFinancialOptions[0].active = true;
			}
        }
        else {
            newFinancialOptions[2].title = "Rehab & ARV";
		}
		
		if ( index === 3 ) {
			for (let index = 0; index < newFinancialOptions.length; index++) {
				newFinancialOptions[index].active = false;
			}
			newFinancialOptions[2].active = true;
		}

        setFinancialOptions(newFinancialOptions);

		if ( index === 2 && downPayment !== 100 ) {
			setDownPayment(100);
			setActiveToast(true);
			setToastText("Down payment changed to 100% because you picked BRRRR strategy");
		}
		else if ( strategyPanel === 2 && index !== 2 && (Number(downPayment) !== Number(defaultDownPayment)) ) {
			setDownPayment(defaultDownPayment);
			setActiveToast(true);
			setToastText("Down payment changed back to default");
		}

		if ( index === 1 ) {
			// If they're switching to house hack mode

			const newMonthlyCosts = [...monthlyCosts];
			if ( strategyPanel === 2 && index !== 2 && (Number(downPayment) !== Number(defaultDownPayment)) ) {
				const newMortgage = await calculateMortgagePayment(customPrice, defaultDownPayment, Number(interestRate), loanYears);
				newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
			}

			const newTotalCosts = newMonthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label === "Management fee" ) {
	
					}
					else {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			if ( multipleUniqueUnits === true ) {
				const updatedRent = Number(multiUnitHouseHackRentTotal);

				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
			else {
				const updatedRent = Number(houseHackRent);
				
				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
		}
		else if ( index === 2 ) {
			// If they're switching to BRRR mode

			const newMonthlyCosts = [...monthlyCosts];
			const amountBorrowedInverse = 100 - arvFinancingPercentage;
			const newMortgage = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(interestRate), loanYears);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * arv) / 12).toFixed(2);
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;

			setMonthlyCosts(newMonthlyCosts);

			const newTotalCosts = newMonthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label === "Property taxes" ) {
						accumulator += Number(item.brrrrValue);
					}
					else {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			if ( multipleUniqueUnits === true ) {
				const updatedRent = Number(multiUnitRentTotal);
				
				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
			else {
				const updatedRent = Number(rent);
				
				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
		}
		else {
			// If they're switching to any other mode

			const newMonthlyCosts = [...monthlyCosts];
			if ( strategyPanel === 2 && index !== 2 && (Number(downPayment) !== Number(defaultDownPayment)) ) {
				const newMortgage = await calculateMortgagePayment(customPrice, defaultDownPayment, Number(interestRate), loanYears);
				newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
			}

			const newTotalCosts = newMonthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			if ( multipleUniqueUnits === true ) {
				const updatedRent = Number(multiUnitRentTotal);
				
				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
			else {
				const updatedRent = Number(rent);
				
				// Register strategy change
				collectStrategyChangeData(index, updatedRent, newTotalCosts);
			}
		}
    };

	// Function for changing which financial panel you'd like
	// to look at
    const selectFinancialOption = (index) => {
        const newFinancialOptions = [...financialOptions];
        for (let index = 0; index < newFinancialOptions.length; index++) {
            newFinancialOptions[index].active = false;
            if ( strategyPanel < 2 && index === 2 ) {
                newFinancialOptions[index].title = "Minor repairs";
            }
            else if ( index === 2 ) {
                newFinancialOptions[index].title = "Rehab & ARV";
            }
        }

        newFinancialOptions[index].active = true;
        setFinancialOptions(newFinancialOptions)
    };
	
	// Function for changing the TOTAL rent value
	// across all variations
    const onChangeRent = (val) => {

		// This is for recording total rents a SFH + MFH with 1 unit type
		if ( strategyPanel === 1 ) {
			// If we're on house hack mode
			const availableUnits = property.propertyTypeDimension === "Single Family" ? property.bedrooms : property.units;
			if ( availableUnits !== undefined && availableUnits !== null && availableUnits > 1 ) {
				const currentlyAccountedUnits = availableUnits - 1;
				const singleUnitRent = Number(val) / currentlyAccountedUnits;
				const fullRent = Math.round(singleUnitRent * availableUnits);
				setRent(fullRent);
			}
			else {
				setRent(val);

				// Register total rent
				collectTotalRent(Number(val));
			}
		}
		else {
			// If we're NOT on house hack mode
			setRent(val);

			// Register total rent
			collectTotalRent(Number(val));
		}

		// For 1 unit layout MFH homes, this is where we segment the rent
		// between each unit
        if ( unitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < unitsRent.length; index++) {
				const newUnit = {
					value: strategyPanel === 1 ? Math.round(val) : Math.round(val / unitsRent.length),
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setUnitsRent(newUnits);
        }

		// For 1 unit layout MFH homes on a House Hack, this is where we segment the rent
		// between each unit
		if ( houseHackUnitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < houseHackUnitsRent.length; index++) {
				const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
				const divisionDenominator = strategyPanel === 1 ? rentableHouseHackUnits : houseHackUnitsRent.length;
				const newUnit = {
					value: index === unitLiving ? 0 : rentableHouseHackUnits === 0 ? 0 : Number(val) / divisionDenominator,
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };

			const newHouseHackUnitsTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
            setHouseHackUnitsRent(newUnits);
			if ( strategyPanel === 1 ) {
				// If we're on the house hack panel, the house hack total should be what is typed
				setHouseHackRent(val);

				// Register total rent
				collectTotalRent(Number(val));
			}
			else {
				// If we're on another panel, the house hack total should be the sum of the rented units
				setHouseHackRent(newHouseHackUnitsTotal);
			}
        }
    };

	// Function for changing the rent on INDIVIDUAL units
	// on a Multi-family home with 1 unit type, and on a
	// Multi-family house hack with 1 unit type
    const onChangeUnitRent = (val, unitIndex) => {
		
		// Setting up rent for 1 unit layout MFH homes
        const newUnits = [];
        for (let index = 0; index < unitsRent.length; index++) {
            if ( index === unitIndex ) {
                const newUnit = {
                    value: val,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: unitsRent[index].value,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
        setUnitsRent(newUnits);
        const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
        setRent(newTotal);

		// Setting up rent for house hack units
		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			if ( index === unitIndex ) {
				const newUnit = {
					value: index === unitLiving ? 0 : val,
					label: `Unit ${index + 1}`
				};
				newHouseHackUnits.push(newUnit);
			}
			else {
				const newUnit = {
					value: index === unitLiving ? 0 : houseHackUnitsRent[index].value,
					label: `Unit ${index + 1}`
				};
				newHouseHackUnits.push(newUnit);
			}
        };
		setHouseHackUnitsRent(newHouseHackUnits);

		const newHouseHackTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newHouseHackTotal);

		if ( strategyPanel === 1 ) {
			// Register total rent
			collectTotalRent(newHouseHackTotal);
		}
		else {
			// Register total rent
			collectTotalRent(newTotal);
		}
    };

	// Function for changing the total rent for SFH House Hack mode
	const onChangeSFHHouseHackTotal = (val) => {
		// For SFH homes on a House Hack, this is where we segment the rent
		// between each bedroom
		if ( houseHackBedroomsRent.length !== 0 ) {
			const newUnits = [];
            for (let index = 0; index < houseHackBedroomsRent.length; index++) {
				const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
				const divisionDenominator = strategyPanel === 1 ? rentableHouseHackBedrooms : houseHackBedroomsRent.length;
				const newUnit = {
					value: index === unitLiving ? 0 : rentableHouseHackBedrooms === 0 ? 0 : Math.round(val / divisionDenominator),
					label: `Bedroom ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setHouseHackBedroomsRent(newUnits);
			setHouseHackRent(val);

			// Register total rent
			collectTotalRent(Number(val));
		}
	};

	// Function for changing the rent on INDIVIDUAL bedrooms
	// on a SFH for a House hack
	const onChangeBedroomRent = (val, bedroomIndex) => {
		const newUnits = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
            if ( index === bedroomIndex ) {
                const newUnit = {
                    value: index === unitLiving ? 0 : val,
                    label: `Bedroom ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: index === unitLiving ? 0 : houseHackBedroomsRent[index].value,
                    label: `Bedroom ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
		setHouseHackBedroomsRent(newUnits);
		const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing rent for a value add input
	const onChangeValueAddInput = async(val, item) => {
		const newOptions = [...valueAddOptions];
		for (let index = 0; index < newOptions.length; index++) {
			const element = newOptions[index];
			if ( element.label === item.label ) {
				newOptions[index].value = val;
			}
		};
		setValueAddOptions(newOptions);
		const getTotal = await getTotalValueAddRent(newOptions);
		collectValueAddRent(getTotal);
    };

	// Function for changing whether a value add option is active
	const onChangeValueAddOptions = async(index) => {
        let tempValueAddOptions = [...valueAddOptions];
        tempValueAddOptions[index].active = !tempValueAddOptions[index].active;
        setValueAddOptions(tempValueAddOptions);
		const getTotal = await getTotalValueAddRent(tempValueAddOptions);
		collectValueAddRent(getTotal);
    };

	// Function for changing which unit you want to live in on a MFH
	// house hack with 1 unit type
	const onChangeUnitLiving = (val) => {
		setUnitLiving(val);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			// const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
			// const perUnitTotal = rentableHouseHackUnits === 0 ? 0 : houseHackRent / rentableHouseHackUnits;
			const existingUnitVal = unitsRent[index].value;

			const newUnit = {
				value: index === val ? 0 : existingUnitVal,
				label: `Unit ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
        };
		setHouseHackUnitsRent(newHouseHackUnits);
		
		const newTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing which unit you want to live in on a MFH
	// house hack with multiple unit types
	const onChangeMultiUnitLiving = (val, subVal) => {
		setMultiUnitTypeUnitLiving([val, subVal]);

		const newHouseHackMultiUnits = [...houseHackMultiUnitsRent];
		for (let index = 0; index < newHouseHackMultiUnits.length; index++) {
			const element = newHouseHackMultiUnits[index];
			for (let ii = 0; ii < element.units.length; ii++) {
				if ( index === val && ii === subVal ) {
					newHouseHackMultiUnits[index].units[ii].value = 0;
				}
				else {
					newHouseHackMultiUnits[index].units[ii].value = multiUnitRent[index].units[ii].value;
				}
			}
		};

		setHouseHackMultiUnitsRent(newHouseHackMultiUnits);

		const newTotal = newHouseHackMultiUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitHouseHackRentTotal(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing which bedroom you want to live in on a SFH
	// house hack
	const onChangeBedroomLiving = (val) => {
		setUnitLiving(val);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
			const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
			const perBedroomTotal = rentableHouseHackBedrooms === 0 ? 0 : houseHackRent / rentableHouseHackBedrooms;
			const newUnit = {
				value: index === val ? 0 : perBedroomTotal,
				label: `Bedroom ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
        };
		setHouseHackBedroomsRent(newHouseHackUnits);
		
		const newTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing the TOTAL rent for MFHs with multiple
	// unique units
	const onChangeMultiUnitTotalRent = (val) => {
		if ( strategyPanel === 1 ) {
			// This is if they were in house hack mode
			setMultiUnitHouseHackRentTotal(val);
			const totalNumber = Number(val);
			
			// Register total rent
			collectTotalRent(totalNumber);

			if ( multiUnitRent.length > 1 ) {
				if ( multiUnitRent[0].units.length < 1 ) {
					return;
				}
				
				const livedInUnitIndex = multiUnitTypeUnitLiving[0];
				const livedInUnitLength = houseHackMultiUnitsRent[livedInUnitIndex].units.length;

				// If the unit they're living in is a single unit type, we just divide the total
				// by the number of units being rented
				if ( livedInUnitLength === 1 ) {
					const rentedUnits = houseHackMultiUnitsRent[livedInUnitIndex === 0 ? 1 : 0].units.length;
					const newSegmentedRent = totalNumber / rentedUnits;

					const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
					for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
						const element = houseHackMultiUnitsRentClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = 0;
							}
							else {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newSegmentedRent);
							}
						}
					};
					setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);

					const ratio = multiUnitRentRatio[1];
					const nonHouseHackUnit = livedInUnitIndex === 0 ? newSegmentedRent / ratio : newSegmentedRent * ratio;
					const nonHouseHackArrayClone = [...multiUnitRent];

					for (let index = 0; index < nonHouseHackArrayClone.length; index++) {
						const element = nonHouseHackArrayClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								nonHouseHackArrayClone[index].units[ii].value = Math.round(nonHouseHackUnit);
							}
							else {
								nonHouseHackArrayClone[index].units[ii].value = Math.round(newSegmentedRent);
							}
						}
					};
					setMultiUnitRent(nonHouseHackArrayClone);

					const nonHouseHackTotal = nonHouseHackArrayClone.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
							// Add the 'value' property to the accumulator
							accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);
					setMultiUnitRentTotal(nonHouseHackTotal);
				}
				else {
					// If the unit they're living in is a double unit type, we need to determine
					// the ratio of the rent between the two unit types

					// Reduce the firt unit type count by 
					const firstUnitTypeCount = multiUnitRent[0].units.length > 1 ? multiUnitRent[0].units.length - 1 : multiUnitRent[0].units.length;
					const ratio = multiUnitRentRatio[1];
					
					// Equation to find new rent rates
					const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
					const newSecondUnitRent = newFirstUnitRent * ratio;

					const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
					for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
						const element = houseHackMultiUnitsRentClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = 0;
							}
							else if ( index === 0 ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newFirstUnitRent);
							}
							else {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newSecondUnitRent);
							}
						}
					};
					setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);


					const nonHouseHackArrayClone = [...multiUnitRent];
					for (let index = 0; index < nonHouseHackArrayClone.length; index++) {
						const element = nonHouseHackArrayClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							nonHouseHackArrayClone[index].units[ii].value = Math.round(index === 0 ? newFirstUnitRent : newSecondUnitRent);
						}
					};
					setMultiUnitRent(nonHouseHackArrayClone);

					const nonHouseHackTotal = nonHouseHackArrayClone.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
							// Add the 'value' property to the accumulator
							accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);
					setMultiUnitRentTotal(nonHouseHackTotal);
				}
			}
		}
		else {
			// This is if they were NOT in house hack mode
			setMultiUnitRentTotal(val);
			const totalNumber = Number(val);

			// Register total rent
			collectTotalRent(totalNumber);
			
			if ( multiUnitRent.length > 1 ) {
				if ( multiUnitRent[0].units.length < 1 ) {
					return;
				}
				const firstUnitTypeCount = multiUnitRent[0].units.length;
				const ratio = multiUnitRentRatio[1];
				
				// Equation to find new rent rates
				const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
				const newSecondUnitRent = newFirstUnitRent * ratio;
				
				const multiUnitRentClone = [...multiUnitRent];
				for (let index = 0; index < multiUnitRentClone.length; index++) {
					const element = multiUnitRentClone[index];
					for (let ii = 0; ii < element.units.length; ii++) {
						multiUnitRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
					}
				}
				setMultiUnitRent(multiUnitRentClone);

				const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
				for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
					const element = houseHackMultiUnitsRentClone[index];
					for (let ii = 0; ii < element.units.length; ii++) {
						if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
							houseHackMultiUnitsRentClone[multiUnitTypeUnitLiving[0]].units[multiUnitTypeUnitLiving[1]].value = 0;
						}
						else {
							houseHackMultiUnitsRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
						}
					}
				}

				setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);
				
				const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					  // Add the 'value' property to the accumulator
					  accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setMultiUnitHouseHackRentTotal(newHouseHackTotal);
			}
		}
	};


	// Function to change indiviudal text inputs for multiple unique
	// units MFH properties
	const onChangeMultiUnitRent = (val, inputIndex, inputSubIndex) => {
		if ( strategyPanel === 1 ) {
			// On house hack mode

			const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
			for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
				const element = houseHackMultiUnitsRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						houseHackMultiUnitsRentClone[index].units[ii].value = val;
					}
				}
			};
			const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);

			// Register total rent
			collectTotalRent(newHouseHackTotal);

			const multiUnitRentClone = [...multiUnitRent];
			for (let index = 0; index < multiUnitRentClone.length; index++) {
				const element = multiUnitRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						multiUnitRentClone[index].units[ii].value = val;
					}
				}
			};
			setMultiUnitRent(multiUnitRentClone);
			const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);
		}
		else {
			// NOT on house hack mode
			const multiUnitRentClone = [...multiUnitRent];
			for (let index = 0; index < multiUnitRentClone.length; index++) {
				const element = multiUnitRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						multiUnitRentClone[index].units[ii].value = val;
					}
				}
			};
			setMultiUnitRent(multiUnitRentClone);
			const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);

			// Register total rent
			collectTotalRent(newTotal);

			const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
			for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
				const element = houseHackMultiUnitsRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex && (index !== multiUnitTypeUnitLiving[0] || ii !== multiUnitTypeUnitLiving[1]) ) {
						houseHackMultiUnitsRentClone[index].units[ii].value = val;
					}
				}
			};
			const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);
		}
	};

	// Function for resetting rent rates
	const resetRentEstimates = () => {
		setRent(property.financials.rent);
		setHouseHackRent(defaultHouseHackRent);
		const newUnits = [];
		for (let index = 0; index < unitsRent.length; index++) {
			const newUnit = {
				value: Math.round(property.financials.rent / unitsRent.length),
				label: `Unit ${index + 1}`
			};
			newUnits.push(newUnit);
		};
		setUnitsRent(newUnits);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
			const newUnit = {
				value: rentableHouseHackUnits === 0 ? 0 : Math.round(defaultHouseHackRent / rentableHouseHackUnits),
				label: `Unit ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
		};
		setHouseHackUnitsRent(newHouseHackUnits);

		const newHouseHackBedrooms = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
			const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
			const newUnit = {
				value: rentableHouseHackBedrooms === 0 ? 0 : Math.round(defaultHouseHackRent / rentableHouseHackBedrooms),
				label: `Bedroom ${index + 1}`
			};
			newHouseHackBedrooms.push(newUnit);
		};
		setHouseHackBedroomsRent(newHouseHackBedrooms);

		if ( strategyPanel === 1 ) {
			// Register total rent
			collectTotalRent(defaultHouseHackRent);
		}
		else {
			// Register total rent
			collectTotalRent(property.financials.rent);
		}
	};

	// Function for resetting rent rates for MFHs with multiple
	// unique units
	const resetMFHMultiUnitRentEstimates = () => {
		const newUnits = [];
		const multiUnitRentClone = [...multiUnitRent];
		for (let index = 0; index < multiUnitRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				multiUnitRentClone[index].units[ii].value = multiUnitRentClone[index].units[ii].original;
			}
			newUnits.push(multiUnitRentClone[index]);
		};
		setMultiUnitRent(newUnits);
		const newUnitsTotal = newUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitRentTotal(newUnitsTotal);

		const newHouseHackUnits = [];
		const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
		for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
					houseHackMultiUnitsRentClone[index].units[ii].value = 0;
				}
				else {
					houseHackMultiUnitsRentClone[index].units[ii].value = houseHackMultiUnitsRentClone[index].units[ii].original;
				}
			}
			newHouseHackUnits.push(houseHackMultiUnitsRentClone[index]);
		};
		setHouseHackMultiUnitsRent(newHouseHackUnits);
		const newHouseHackUnitsTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitHouseHackRentTotal(newHouseHackUnitsTotal);

		if ( strategyPanel === 1 ) {
			// Register total rent
			collectTotalRent(newHouseHackUnitsTotal);
		}
		else {
			// Register total rent
			collectTotalRent(newUnitsTotal);
		}
	};

	// Function to reset a specific monthly cost
	const resetMonthlyCost = async(index, ii) => {
		const newMonthlyCosts = [...monthlyCosts];
		if ( index === 0 && ii === 3 && strategyPanel === 2 ) {
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(newMonthlyCosts[0].inputs[3].brrrrOriginal);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
		}
		else {
			newMonthlyCosts[index].inputs[ii].value = newMonthlyCosts[index].inputs[ii].original;

			if ( index === 0 ) {
				const originalValue = Number(newMonthlyCosts[index].inputs[ii].original) * 12;
				const percentageOfTotal = (originalValue / property.price) * 100;
				newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
			}
			else {
				const originalValue = Number(newMonthlyCosts[index].inputs[ii].original);
				const percentageOfTotal = (originalValue / property.financials.rent) * 100;
				newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
			}	
		}

		setMonthlyCosts(newMonthlyCosts);
		const newTotal = await getTotalMonthlyCosts(newMonthlyCosts);

		collectMonthlyCosts(newTotal);
	};

	// Function for changing purchase price
	const onChangePrice = async(val) => {
		const newPrice = Number(val);
		setCustomPrice(newPrice);

		// Register initial costs
		collectInitialCosts(Number(downPayment), Number(interestRate), Number(closingPercentage), newPrice);

		const percentageChange = Math.round(((newPrice - property.price) / property.price) * 100);
		if ( percentageChange < maxPercentage ) {
			setCustomDiscount(maxPercentage);
		}
		else {
			setCustomDiscount(percentageChange);
		}

		const updatedRent = await getNonHouseHackRent();
		updateMonthlyCosts(monthlyCosts, updatedRent, newPrice);
	};

	// Function for changing slider price
	const changePriceSlider = async(event, newValue) => {
		setCustomDiscount(newValue);
		const newPrice = property.price * ((100 + newValue) / 100);
		const roundedPrice = Math.round(newPrice);
		setCustomPrice(roundedPrice);

		// Register initial costs
		collectInitialCosts(Number(downPayment), Number(interestRate), Number(closingPercentage), roundedPrice);
		const updatedRent = await getNonHouseHackRent();
		updateMonthlyCosts(monthlyCosts, updatedRent, roundedPrice);
	};

	// Function for changing down payment
	const onChangeDownPayment = async(text) => {
		if ( Number(text) <= 100 ) {
			setDownPayment(text);

			// Register initial costs
			collectInitialCosts(Number(text), Number(interestRate), Number(closingPercentage), Number(customPrice));
		}
	};

	// Function for changing interest rate
	const onChangeInterest = async(text) => {
		if ( Number(text) <= 100 ) {
			setInterestRate(text);

			// Register initial costs
			collectInitialCosts(Number(downPayment), Number(text), Number(closingPercentage), Number(customPrice));
		}
	};

	// Closing cost is a percentage of the
	// total MORTGAGE, not of the purchase price
	const onChangeClosing = async(text) => {
		if ( Number(text) <= 100 ) {
			setClosingPercentage(text);

			// Register initial costs
			collectInitialCosts(Number(downPayment), Number(interestRate), Number(text), Number(customPrice));
		}
	};

	// Function for changing rehab cost input
	const changeRehabCost = (event, val) => {
        setCostPerSqFoot(val);
        const newTotalCost = val * property.livingArea;
        setTotalRehabCost(newTotalCost);

		// Register rehab costs
		collectRehabCosts(Number(newTotalCost), Number(arv));
    }

	// Change cost per sq. foot
	const onChangeCostPerSqFoot = (val) => {
        setCostPerSqFoot(val);
        const newTotalCost = Number(val) * property.livingArea;
        setTotalRehabCost(newTotalCost);

		// Register rehab costs
		collectRehabCosts(Number(newTotalCost), Number(arv));
    };

	// Change total rehab cost
    const onChangeTotalCost = (val) => {
        setTotalRehabCost(val);
        const newCostPerSqFoot = Math.round(Number(val) / property.livingArea);
        setCostPerSqFoot(newCostPerSqFoot);

		// Register rehab costs
		collectRehabCosts(Number(val), Number(arv));
    };

	// Function for changing ARV
	const onChangeARV = (val) => {
		const newArv = Number(val);
		setArv(newArv);

		// Register rehab costs
		collectRehabCosts(Number(totalRehabCost), Number(val));

		const percentageChange = Math.round(((newArv - defaultArv) / defaultArv) * 100);
		if ( percentageChange < maxPercentage ) {
			setCustomArv(maxPercentage);
		}
		else {
			setCustomArv(percentageChange);
		}
	};

	// Funciton to change ARV slider
	const onChangeARVSlider = (event, newValue) => {
		setCustomArv(newValue);
		const newArv = defaultArv * ((100 + newValue) / 100);
		const roundedArv = Math.round(newArv);
		setArv(roundedArv);

		// Register rehab costs
		collectRehabCosts(Number(totalRehabCost), Number(roundedArv));
	};

	// Function to change monthly holding costs
	const onChangeHoldingCosts = (val) => {
		setMonthlyHoldingCosts(val);
		const newTotal = Number(val) * Number(holdingPeriod);
		setTotalHoldingCosts(newTotal);

		// Register holding costs
		collectHoldingCosts(newTotal);
	};

	// Function to change monthly holding period
	const onChangeHoldingPeriod = (val) => {
		setHoldingPeriod(val);
		const newTotal = Number(monthlyHoldingCosts) * Number(val);
		setTotalHoldingCosts(newTotal);

		// Register holding costs
		collectHoldingCosts(newTotal);
	};

	// Function to change monthly costs
	const onChangeMonthlyCosts = async(val, index, ii) => {
		const newArray = [...monthlyCosts];

		// If we're on BRRRR strategy and they're changing property taxes, save the value
		// to BRRRR values
		if ( strategyPanel === 2 && index === 0 && ii === 3 ) {
			newArray[index].inputs[ii].brrrrValue = val;
			const numberVal = Number(val);
			const yearlyValue = numberVal * 12;
			const percentageOfTotal = (yearlyValue / Number(arv)) * 100;
			newArray[index].inputs[ii].brrrrPercentageVal = percentageOfTotal.toFixed(1);
		}
		else {
			newArray[index].inputs[ii].value = val;
			const numberVal = Number(val);
			if ( index === 0 ) {
				const yearlyValue = numberVal * 12;
				const percentageOfTotal = (yearlyValue / property.price) * 100;
				newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
			}
			else {
				const percentageOfTotal = (numberVal / property.financials.rent) * 100;
				newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
			}

		}
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

	const onChangeMonthlyCostsPercentage = async(val, index, ii) => {
		const newArray = [...monthlyCosts];

		if ( index === 0 && ii === 3 && strategyPanel === 2 ) {
			// If we're on BRRRR strategy and they're changing property taxes, save the value to
			// the BRRRR fields
			newArray[index].inputs[ii].brrrrPercentageVal = val;
			const relativeValue = Number(arv) / 12;
			const dollarValue = (relativeValue * (Number(val) / 100));
			newArray[index].inputs[ii].brrrrValue = Number(dollarValue.toFixed(2));
		}
		else {
			newArray[index].inputs[ii].percentageVal = val;

			// Choose the appropriate relative value - either property price or monthly rent.
			// If the relative value is property price, divide the price by 12, so as to get the
			// monthly cost, rather than the yearly cost.
			const relativeValue = index === 0 ? customPrice / 12 : await getAppropriateRentEstimate();
			const dollarValue = (relativeValue * (val / 100));
			newArray[index].inputs[ii].value = Number(dollarValue.toFixed(2));
		}
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

	// Function to change monthly costs setting from percentage to dollar value
	const togglePercentage = (val, index, ii) => {
		const newArray = [...monthlyCosts];
		newArray[index].inputs[ii].percentage = val;
		setMonthlyCosts(newArray);
	};

	// Function to change ARV financing percentage
	const onChangeARVFinancing = async(text) => {
		if ( Number(text) <= 100 ) {
			setArvFinancingPercentage(text);

			const getRent = await getAppropriateRentEstimate();
			const amountBorrowedInverse = 100 - Number(text);
			const newMortgage = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(interestRate), loanYears);
			const newMonthlyCosts = [...monthlyCosts];
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			const newArvFinancing = (Number(arv) * Number(text)) / 100;
			const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
			const newValsObject = {
				rent: getRent,
				downPayment: downPaymentCost,
				interestRate: Number(interestRate),
				closingCost: closingCost,
				rehab: Number(totalRehabCost),
				monthlyCosts: totalMonthlyCosts,
				price: Number(customPrice),
				arv: Number(arv),
				holdingCosts: Number(totalHoldingCosts),
				strategy: strategyPanel,
				arvFinancing: newArvFinancing
			};
			updateBRRRRCashFlow(newValsObject);
		}
	};

	// This is where we send all new total rents
	// to then send to a cash flow update function
	const collectTotalRent = async(val) => {
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const totalValueAddRent = await getTotalValueAddRent(valueAddOptions);
		const finalRent = Number(val) + Number(totalValueAddRent);
		
		const newValsObject = {
			rent: finalRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: Number(closingCost),
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: strategyPanel,
			arvFinancing: Number(arvFinancing)
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}


		if ( strategyPanel !== 1 ) {
			updateMonthlyCosts(monthlyCosts, finalRent, customPrice);
		}
	};

	// This is where we send all new value add rents
	// to then send to a cash flow update function
	const collectValueAddRent = async(val) => {
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const getRent = await getAppropriateRentEstimateWithoutValueAdd();
		const finalRent = Number(val) + Number(getRent);

		const newValsObject = {
			rent: finalRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: Number(closingCost),
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: strategyPanel,
			arvFinancing: Number(arvFinancing)
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}

		if ( strategyPanel !== 1 ) {
			updateMonthlyCosts(monthlyCosts, finalRent, customPrice);
		}
	};

	// This is where we send all new initial costs
	// to then send to a cash flow update function
	const collectInitialCosts = async(down, int, close, price) => {
		let mortgagePayment;
		if ( strategyPanel === 2 ) {
			const amountBorrowedInverse = 100 - Number(arvFinancingPercentage);
			mortgagePayment = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(int), loanYears);
		}
		else {
			mortgagePayment = Number(down) === 100 ? 0 : await calculateMortgagePayment(Number(price), Number(down), Number(int), loanYears);
		}

		const newMonthlyCosts = [...monthlyCosts];
		newMonthlyCosts[0].inputs[0].value = Number(mortgagePayment.toFixed(2));
		setMonthlyCosts(newMonthlyCosts);

		const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
		const getClosingCost = await getAppropriateClosingCost(Number(close), Number(price));
		const getRent = await getAppropriateRentEstimate();
		const newDownPaymentCost = (Number(price) * (Number(down) / 100));
		const newValsObject = {
			rent: getRent,
			downPayment: newDownPaymentCost,
			interestRate: Number(int),
			closingCost: getClosingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(price),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: strategyPanel,
			arvFinancing: Number(arvFinancing)
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}
	};

	// This is where we send all new rehab costs
	// to then send to a cash flow update function
	const collectRehabCosts = async(rehab, newArv) => {

		const newMonthlyCosts = [...monthlyCosts];
		if ( strategyPanel === 2 ) {
			// New mortgage payment for BRRRR
			const amountBorrowedInverse = 100 - Number(arvFinancingPercentage);
			const newMortgage = await calculateMortgagePayment(Number(newArv), amountBorrowedInverse, Number(interestRate), loanYears);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			// New BRRRR property taxes
			const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * newArv) / 12).toFixed(2);
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
		}

		const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
		const getRent = await getAppropriateRentEstimate();
		const newArvFinancing = (Number(newArv) * arvFinancingPercentage) / 100;
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(rehab),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(newArv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: strategyPanel,
			arvFinancing: newArvFinancing
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}
	};

	// This is where we send all new monthly costs
	// to then send to a cash flow update function
	const collectMonthlyCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate();
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: costs,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: strategyPanel,
			arvFinancing: Number(arvFinancing)
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}
	};

	// Function that collcetions holding cost values for the
	// Fix & Flip strategy
	const collectHoldingCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate();
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(costs),
			strategy: strategyPanel,
			arvFinancing: Number(arvFinancing)
		};
		if ( strategyPanel < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( strategyPanel === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( strategyPanel === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}
	};

	// Function that collects property data after a strategy change
	const collectStrategyChangeData = async(newStrategy, newRent, newMonthlyCosts) => {
		const totalValueAddRent = await getTotalValueAddRent(valueAddOptions);
		const finalRent = Number(newRent) + Number(totalValueAddRent);
		const checkedDownPaymentCost = strategyPanel === 2 ? ((defaultDownPayment / 100) * Number(customPrice)) : downPaymentCost;

		const newDownPaymentCost = newStrategy !== 2 ? checkedDownPaymentCost : customPrice;

		const newValsObject = {
			rent: finalRent,
			downPayment: newDownPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: newMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: totalHoldingCosts,
			strategy: newStrategy,
			arvFinancing: Number(arvFinancing)
		};
		if ( newStrategy < 2 ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( newStrategy === 2 ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( newStrategy === 3 ) {
			updateFixAndFlipROI(newValsObject);
		}
	};

	// Fucntion that checks the property type and the selected
	// strategy panel, and returns the appropriate rent estimate
	const getAppropriateRentEstimate = async() => {
		const totalValueAddRent = await getTotalValueAddRent(valueAddOptions);
		if ( strategyPanel === 1 ) {
			// This is for house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitHouseHackRentTotal) + Number(totalValueAddRent);
				return finalRent;
			}
			else {
				const finalRent = Number(houseHackRent) + Number(totalValueAddRent);
				return finalRent;
			}
		}
		else {
			// This is for non-house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitRentTotal) + Number(totalValueAddRent);
				return finalRent;
			}
			else {
				const finalRent = Number(rent) + Number(totalValueAddRent);
				return finalRent;
			}
		}
	};

	// Function that checks the property type and the selected
	// strategy panel, and returns the appropriate rent estimate WITHOUT value add
	const getAppropriateRentEstimateWithoutValueAdd = async() => {
		if ( strategyPanel === 1 ) {
			// This is for house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitHouseHackRentTotal);
				return finalRent;
			}
			else {
				const finalRent = Number(houseHackRent);
				return finalRent;
			}
		}
		else {
			// This is for non-house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitRentTotal);
				return finalRent;
			}
			else {
				const finalRent = Number(rent);
				return finalRent;
			}
		}
	};

	// Function that gets the rent estimate in NON-house hack mode
	// but WITHOUT value add
	const getNonHouseHackRent = async() => {
		if ( multipleUniqueUnits === true ) {
			const finalRent = Number(multiUnitRentTotal);
			return finalRent;
		}
		else {
			const finalRent = Number(rent);
			return finalRent;
		}
	};

	// Function that gets the total monthly costs
	const getTotalMonthlyCosts = (vals) => {
		const totalMonthlyCosts = vals.reduce((accumulator, currentValue) => {
			currentValue.inputs.forEach(item => {
				if ( item.label === "Management fee" && strategyPanel === 1 ) {

				}
				else if ( item.label === "Property taxes" && strategyPanel === 2 ) {
					accumulator += Number(item.brrrrValue);
				}
				else {
					accumulator += Number(item.value);
				}
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		return totalMonthlyCosts;
	};

	// Function that gets you the total value add rent
	const getTotalValueAddRent = (vals) => {
		const totalValueAddRent = vals.reduce((accumulator, item) => {
			if ( item.active === true ) {
				accumulator += Number(item.value);
			}
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		return totalValueAddRent;
	};

	// Function that calculates the dollar amount of closing costs
	const getAppropriateClosingCost = (val, price) => {
		return Number((price * (val / 100)).toFixed(2));
	};

	// Function that updates monthly costs after a rent value change or
	// a price change
	const updateMonthlyCosts = async(vals, newRent, newPrice) => {
		for (let index = 0; index < vals.length; index++) {
			const element = vals[index];
			for (let ii = 0; ii < element.inputs.length; ii++) {
				const input = element.inputs[ii];
				if ( index === 0 ) {
					// These monthly costs are relative to the property price
					if ( input.percentage === true ) {
						// Change the raw dollar amount to reflect the percentage of the new price
						const dollarAmount = (newPrice * (input.percentageVal / 100)) / 12;
						vals[index].inputs[ii].value = dollarAmount.toFixed(2);
					}
					else {
						// Change the percentage to reflect the dollar amount in relation to the new price
						const percentageAmount = ((input.value * 12) / newPrice) * 100;
						vals[index].inputs[ii].percentageVal = Number(percentageAmount.toFixed(1));
					}
				}
				else {
					// These monthly costs are relative to the monthly rent
					if ( input.percentage === true ) {
						// Change the raw dollar amount to reflect the percentage of the new rent estimate
						const dollarAmount = (newRent * (input.percentageVal / 100));
						vals[index].inputs[ii].value = dollarAmount.toFixed(2);
					}
					else {
						// Change the percentage to reflect the dollar amount in relation to the new rent estimate
						const percentageAmount = (input.value / newRent) * 100;
						vals[index].inputs[ii].percentageVal = Number(percentageAmount.toFixed(1));
					}
				}
			}
		};
		setMonthlyCosts(vals);
	};

	// Function for updating ROI for the Turnkey or House Hack strategies
	// based up on user input changes
	const updateBuyAndHoldCashFlow = async(vals) => {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);
		const newArvSpread = await calculateARVSpread(vals.price, vals.rehab, vals.arv, 0);
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: null
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
		saveSpreadsheetProperty(
			spreadsheetProperty,
			setSpreadsheetProperty,
			vals.price,
			monthlyProfit,
			newCashOnCash,
			vals.rent,
			monthlyCosts,
			vals.rehab,
			property.uniqueUnitPropertyDetails,
			vals.interestRate,
			closingPercentage
		);
	};

	// Function for updating ROI for the BRRRR strategy
	// based up on user input changes
	const updateBRRRRCashFlow = async(vals) => {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab + vals.holdingCosts;
		const newCashInDeal = newInitialCosts - vals.arvFinancing;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newCashInDeal);
		const newArvSpread = await calculateARVSpread(vals.price, vals.rehab, vals.arv, vals.holdingCosts);
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: newCashInDeal
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
		saveSpreadsheetProperty(
			spreadsheetProperty,
			setSpreadsheetProperty,
			vals.price,
			monthlyProfit,
			newCashOnCash,
			vals.rent,
			monthlyCosts,
			vals.rehab,
			property.uniqueUnitPropertyDetails,
			vals.interestRate,
			closingPercentage
		);
	};

	// Function for updating ROI for the BRRRR strategy
	// based up on user input changes
	const updateFixAndFlipROI = async(vals) => {
		const newArvSpread = await calculateARVSpread(vals.price, vals.rehab, vals.arv, vals.holdingCosts);

		// We assume a 100% down payment on fix & flip
		const newInitialCosts = vals.price + vals.closingCost + vals.rehab;
		const arvProfit = vals.arv - vals.price - vals.rehab - vals.holdingCosts;

		const profitObject = {
			monthlyProfit: null,
			cashOnCash: null,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: null,
			cashInDeal: null
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
		saveSpreadsheetProperty(
			spreadsheetProperty,
			setSpreadsheetProperty,
			vals.price,
			highLevelProfits.monthlyProfit,
			highLevelProfits.cashOnCash * 100,
			vals.rent,
			monthlyCosts,
			vals.rehab,
			property.uniqueUnitPropertyDetails,
			vals.interestRate,
			closingPercentage
		);
	};

	let hoverTimeout;
	const hoverStrategy = (index) => {
		hoverTimeout = setTimeout(() => {
			setStrategyHover(index);
		}, 300);
	};

	const hoverLeaveStrategy = () => {
		setStrategyHover(null);
		clearTimeout(hoverTimeout);
	};

    return (
		<div className="strategies-panel-outer-wrapper">
			{
				doneSettings === false ?
				<FinancialPreferencesBanner />
				:
				null
			}
			<MobileStrategiesPanel
				mobileBar={mobileBar}
				property={property}
				strategyPanel={strategyPanel}
				changeStrategyPanel={changeStrategyPanel}
				strategies={strategies}
				rent={strategyPanel === 1 && property.uniqueUnitPropertyDetails !== undefined && property.uniqueUnitPropertyDetails !== null ? multiUnitHouseHackRentTotal : strategyPanel === 1 ? houseHackRent : property.uniqueUnitPropertyDetails !== undefined && property.uniqueUnitPropertyDetails !== null && property.uniqueUnitPropertyDetails.length > 1 ? multiUnitRentTotal : rent}
				arv={arv}
				downPayment={downPayment}
				downPaymentCost={downPaymentCost}
				closingCostPercentage={closingPercentage}
				closingCost={closingCost}
				interestRate={interestRate}
				highLevelProfits={highLevelProfits}
				blurred={blurred}
				favouriteDisable={favouriteDisable}
				setFavouriteDisable={setFavouriteDisable}
				favourites={favourites}
				setFavourites={setFavourites}
				navigate={navigate}
				cityId={cityId}
				toggleMobileBottomBar={toggleMobileBottomBar}
			/>
			<div className="strategies-panel-outer-container">
				<div className="strategies-panel-inner-container">
                    <ul className="strategies-panel-options-row">
                        {
                            strategies.map((item, index) =>
								<div 
									className={"strategies-panel-option-element relative-container " + (strategyHover !== null ? strategiesPanelHoverIndex : "")}
									key={index}
									onMouseEnter={() => hoverStrategy(index)}
									onMouseLeave={() => hoverLeaveStrategy()}
								>
									<Chip
										label={item.text}
										func={changeStrategyPanel}
										index={index}
										selected={strategyPanel}
										changeIcon={null}
										recommended={recommendedStrategy === index ? true : false}
										hover={null}
										leave={null}
										disabled={false}
									/>
									{
										strategyHover === index ?
										<StrategyTooltip
											title={strategies[strategyHover].title}
											description={strategies[strategyHover].description}
											link={strategies[strategyHover].link}
											index={index}
										/>
										:
										null
									}
								</div>
                            )
                        }
					</ul>
					<StrategiesPanelTopLevel
						strategyPanel={strategyPanel}
						defaultArv={defaultArv}
						defaultRent={property.financials.rent}
						property={property}
					/>
                    <div className="strategies-panel-divider-line margin-tops">
                    </div>
					<div className="strategies-panel-body-container">
						<StrategiesPanelFinancialOptions
                            options={financialOptions}
                            selectOption={selectFinancialOption}
							defaultValuesPanel={defaultValuesPanel}
							strategyPanel={strategyPanel}
                        />
                        <div className="strategies-panel-section-container">
                            {
                                financialOptions[0].active === true ?
                                <RentTab
                                    rent={rent}
                                    onChangeRent={onChangeRent}
                                    blurred={blurred}
                                    defaultRent={property.financials.rent}
                                    strategyPanel={strategyPanel}
                                    property={property}
                                    unitsRent={unitsRent}
									houseHackUnitsRent={houseHackUnitsRent}
                                    onChangeUnitRent={onChangeUnitRent}
									houseHackRent={houseHackRent}
									unitLiving={unitLiving}
									setUnitLiving={setUnitLiving}
									defaultHouseHackRent={defaultHouseHackRent}
									onChangeUnitLiving={onChangeUnitLiving}
									houseHackBedroomsRent={houseHackBedroomsRent}
									onChangeBedroomRent={onChangeBedroomRent}
									onChangeBedroomLiving={onChangeBedroomLiving}
									resetRentEstimates={resetRentEstimates}
									multipleUniqueUnits={multipleUniqueUnits}
									multiUnitRent={multiUnitRent}
									multiUnitRentTotal={multiUnitRentTotal}
									defaultMultiUnitRent={defaultMultiUnitRent}
									houseHackMultiUnitsRent={houseHackMultiUnitsRent}
									multiUnitTypeUnitLiving={multiUnitTypeUnitLiving}
									multiUnitHouseHackRentTotal={multiUnitHouseHackRentTotal}
									defaultMultiUnitHouseHackRentTotal={defaultMultiUnitHouseHackRentTotal}
									multiUnitRentRatio={multiUnitRentRatio}
									onChangeMultiUnitTotalRent={onChangeMultiUnitTotalRent}
									onChangeMultiUnitLiving={onChangeMultiUnitLiving}
									resetMFHMultiUnitRentEstimates={resetMFHMultiUnitRentEstimates}
									onChangeMultiUnitRent={onChangeMultiUnitRent}
									onChangeSFHHouseHackTotal={onChangeSFHHouseHackTotal}
									onChangeValueAddInput={onChangeValueAddInput}
									valueAddOptions={valueAddOptions}
									onChangeValueAddOptions={onChangeValueAddOptions}
									viewRentalComps={viewRentalComps}
                                />
                                :
                                financialOptions[1].active === true ?
								<PurchaseTab
									customPrice={customPrice}
									onChangePrice={onChangePrice}
									strategyPanel={strategyPanel}
									customDiscount={customDiscount}
									changeSlider={changePriceSlider}
									maxPercentage={maxPercentage}
									downPayment={downPayment}
									onChangeDownPayment={onChangeDownPayment}
									interestRate={interestRate}
									onChangeInterest={onChangeInterest}
									closingPercentage={closingPercentage}
									onChangeClosing={onChangeClosing}
									property={property}
									closingCost={closingCost}
									defaultDownPayment={defaultDownPayment}
									defaultInterestRate={defaultInterestRate}
									onChangeARVFinancing={onChangeARVFinancing}
									arvFinancing={arvFinancing}
									arvFinancingPercentage={arvFinancingPercentage}
									cashInDeal={cashInDeal}
									totalRehabCost={totalRehabCost}
								/>
								:
								financialOptions[2].active === true ?
								<RehabTab
									property={property}
									strategyPanel={strategyPanel}
									costPerSqFoot={costPerSqFoot}
									setCostPerSqFoot={setCostPerSqFoot}
									totalRehabCost={totalRehabCost}
									setTotalRehabCost={setTotalRehabCost}
									changeRehabCost={changeRehabCost}
									onChangeCostPerSqFoot={onChangeCostPerSqFoot}
									onChangeTotalCost={onChangeTotalCost}
									defaultCostPerSqFoot={defaultCostPerSqFoot}
									arv={arv}
									onChangeARV={onChangeARV}
									onChangeARVSlider={onChangeARVSlider}
									customArv={customArv}
									maxPercentage={maxPercentage}
									defaultTotalCost={defaultTotalRehabCost}
									defaultArv={defaultArv}
									viewArvComps={viewArvComps}
								/>
								:
								financialOptions[3].active === true ?
								<MonthlyCostsTab
									strategyPanel={strategyPanel}
									monthlyCosts={monthlyCosts}
									onChangeMonthlyCosts={onChangeMonthlyCosts}
									togglePercentage={togglePercentage}
									onChangeMonthlyCostsPercentage={onChangeMonthlyCostsPercentage}
									resetMonthlyCost={resetMonthlyCost}
								/>
								:
								financialOptions[4].active === true ?
								<HoldingCosts
									monthlyHoldingCosts={monthlyHoldingCosts}
									holdingPeriod={holdingPeriod}
									totalHoldingCosts={totalHoldingCosts}
									onChangeHoldingCosts={onChangeHoldingCosts}
									onChangeHoldingPeriod={onChangeHoldingPeriod}
								/>
								:
								null
                            }
                        </div>
					</div>
					{
						highLevelProfits !== "" ?
						<StrategiesPanelFooter
							highLevelProfits={highLevelProfits}
							strategyPanel={strategyPanel}
							blurred={blurred}
							cashInDeal={cashInDeal}
						/>
						:
						null
					}
				</div>
			</div>
			<ReportFinancialsBug
				userDetails={userDetails}
				bugModal={bugModal}
				setBugModal={setBugModal}
				blurred={blurred}
			/>
		</div>
    )
}

export default StrategiesPanel;