import React, { useState, useEffect } from 'react';
import '../styles/NeighbourhoodScores.css';
import { checkZIPPercentile, neighbourhoodTitles, percentage } from '../styles/GlobalStyles';
import { Arrow, Info, Beak } from '../assets';
import { neighbourhoodTooltipIndexes } from '../functions';
import { DefaultTooltip, IDTooltip, PropertyNeighbourhoodBadge } from './';

function NeighbourhoodScores(props) {

    const property = props.property;
    const zipData = props.zipData;
    const formatter = props.formatter;
    const toggleTooltip = props.toggleTooltip;
    const neighbourhoodTooltipShow = props.neighbourhoodTooltipShow;
    const neighbourhoodTooltipIndex = props.neighbourhoodTooltipIndex;
    const tooltipType = props.tooltipType;

    const averageArrayIndex = zipData.findIndex(e => e.zipcode === property.address.zipcode);
    property.cityId = property.city_id;

	const [zipType, setZipType] = useState("");
    const [zipIndex, setZipIndex] = useState(0);

	useEffect(() => {
		const percentile = checkZIPPercentile(zipData, averageArrayIndex);
		if ( percentile > 0.8 ) {
			setZipType("map-zone-a");
            setZipIndex(0);
		}
		else if ( percentile > 0.6 ) {
			setZipType("map-zone-b");
            setZipIndex(1);
		}
		else if ( percentile > 0.4 ) {
			setZipType("map-zone-bc");
            setZipIndex(2);
		}
		else if ( percentile > 0.2 ) {
			setZipType("map-zone-c");
            setZipIndex(3);
		}
		else {
			setZipType("map-zone-d");
            setZipIndex(4);
		}

	}, [averageArrayIndex, zipData]);

	const medianCashOnCashReturn = averageArrayIndex === -1 ? null : zipData[averageArrayIndex].medianPriceToRentRatio === null ? 'N/A' : Number(zipData[averageArrayIndex].medianPriceToRentRatio);
	const medianYoyAppreciation = averageArrayIndex === -1 ? null : zipData[averageArrayIndex].medianYoyAppreciation === null ? "N/A" : (percentage.format(Number(zipData[averageArrayIndex].medianYoyAppreciation)));
	const medianDaysOnMarket = averageArrayIndex === -1 ? null : zipData[averageArrayIndex].medianDaysOnMarket === null ? "N/A" : Number(zipData[averageArrayIndex].medianDaysOnMarket);

	const areaStatsLeft = [
		{
			label: "Average price per square foot",
			value: averageArrayIndex !== -1 ? `${formatter.format(zipData[averageArrayIndex].medianPricePerSqft)}` : ""
		},
        {
			label: "Average price to rent ratio",
			value: averageArrayIndex !== -1 ? `${medianCashOnCashReturn >= 0 ? "+" : ""}${medianCashOnCashReturn}` : ""
		}
	];

    const areaStatsRight = [
		{
			label: "Year over year appreciation",
			value: averageArrayIndex !== -1 ? `${zipData[averageArrayIndex].medianYoyAppreciation >= 0 ? "+" : ""}${medianYoyAppreciation}` : ""
		},
		{
			label: "Year over year days on market",
			value: averageArrayIndex !== -1 ? `${zipData[averageArrayIndex].medianDaysOnMarket >= 0 ? "" : ""}${medianDaysOnMarket}` : ""
		}
	];

    return (
        averageArrayIndex !== -1
        ?
        <div className="neighbourhood-scores-outer-container">
            <div className="neighbourhood-scores-inner-container">
                <h2 className="heading-small-semibold colour-primary margin-x-small">
                    Neighborhood
                </h2>
                <PropertyNeighbourhoodBadge
                    item={property}
                    zipType={zipType}
                    badgeText={neighbourhoodTitles[zipIndex].title}
                    type={"neighbourhood-rating"}
                />
                <div className="neighbourhood-scores-body-container">
                    <h4 className="body-semibold colour-primary">
                        ZIP Code Insights
                    </h4>
                    <div className="neighbourhood-scores-grid-container">
                        <div className="neighbourhood-scores-left-grid-container">
                            {
                                areaStatsLeft.map((item, index) =>
                                    <div 
                                        className="neighbourhood-scores-element-container"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small inline-block-text relative-container">
                                            {item.label}
                                            <img
                                                src={Info}
                                                className="neighbourhood-scores-tooltip-icon"
                                                alt="Info"
                                                onMouseEnter={() => toggleTooltip(true, index, "neighbourhood")}
                                                onMouseLeave={() => toggleTooltip(false, index, "neighbourhood")}
                                            />
                                            {
                                                neighbourhoodTooltipShow === true && neighbourhoodTooltipIndex === index && tooltipType === "neighbourhood" ?
                                                <div className="financials-panel-tooltip-container">
                                                    <img
                                                        src={Beak}
                                                        className="financials-panel-beak"
                                                        alt="Beak"
                                                    />
                                                    <h5 className="body-semibold margin-x-small colour-primary">
                                                        {neighbourhoodTooltipIndexes[index].title}
                                                    </h5>
                                                    <span className="tooltip-regular colour-primary">
                                                        {neighbourhoodTooltipIndexes[index].description}
                                                    </span>
                                                </div>
                                                :
                                                null
                                            }
                                        </span>
                                        <h5 className="body-semibold colour-primary">
                                            {item.value}
                                        </h5>
                                    </div>
                                )
                            }
                        </div>
                        <div className="neighbourhood-scores-right-grid-container">
                            {
                                areaStatsRight.map((item, index) =>
                                    <div 
                                        className="neighbourhood-scores-element-container"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small inline-block-text relative-container">
                                            {item.label}
                                            <img
                                                src={Info}
                                                className="neighbourhood-scores-tooltip-icon"
                                                alt="Info"
                                                onMouseEnter={() => toggleTooltip(true, index + 2, "neighbourhood")}
                                                onMouseLeave={() => toggleTooltip(false, index + 2, "neighbourhood")}
                                            />
                                            {
                                                neighbourhoodTooltipShow === true && neighbourhoodTooltipIndex === 3 && index === 1 && tooltipType === "neighbourhood" ?
                                                <IDTooltip />
                                                :
                                                neighbourhoodTooltipShow === true && neighbourhoodTooltipIndex === 2 && index === 0 && tooltipType === "neighbourhood" ?
                                                <DefaultTooltip
                                                    title={neighbourhoodTooltipIndexes[index + 2].title}
                                                    description={neighbourhoodTooltipIndexes[index + 2].description}
                                                />
                                                :
                                                null
                                            }
                                        </span>
                                        <div className="neighbourhood-scores-element-inner-row">
                                            {
                                                index === 1 ?
                                                <img 
                                                    src={Arrow} 
                                                    className={"neighbourhood-scores-arrow " + (item.value > 0 ? "neighbourhood-scores-arrow-reverse" : "")}
                                                    alt="Arrow"
                                                />
                                                :
                                                null
                                            }
                                            <h5 className="body-semibold colour-primary">
                                                {item.value}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        null
    )
}

export default NeighbourhoodScores;