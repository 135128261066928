import React from 'react';
import { percentageCompact } from '../styles/GlobalStyles';

function CashOnCashBadge(props) {
    const item = props.item;
    const strategy = props.strategy;
    const relevantCashOnCash = strategy === 3 && item.cashOnCashReturnBrrrrDefault !== undefined ? item.cashOnCashReturnBrrrrDefault : item.financials.cashOnCash;
    return (
        <div className={"new-property-card-cash-on-cash-badge-container " + (relevantCashOnCash > 0 ? "new-property-card-cash-on-cash-positive" : "new-property-card-cash-on-cash-negative")}>
            <div className="new-property-card-cash-on-cash-badge-inner-container">
                <span className="label-semibold colour-primary block-text subtext-margin-right">
                    Cash on Cash
                </span>
                <span className="label-regular colour-primary">
                    {
                        strategy === 3 && item.cashOnCashReturnBrrrrDefaultStr !== undefined && item.cashOnCashReturnBrrrrDefaultStr === "infinity" ?
                        `∞`
                        :
                        strategy === 3 && item.cashOnCashReturnBrrrrDefault !== undefined && item.cashOnCashReturnBrrrrDefault !== null ?
                        percentageCompact.format(relevantCashOnCash / 100)
                        :
                        percentageCompact.format(relevantCashOnCash / 100)
                    }
                </span>
            </div>
        </div>
    )
}

export default CashOnCashBadge;