import React from 'react';
import { ChangeDot } from '../assets';

function StrategiesPanelFinancialOptions(props) {
    const options = props.options;
    const selectOption = props.selectOption;
    const defaultValuesPanel = props.defaultValuesPanel;
    const strategyPanel = props.strategyPanel;
   
    return (
        <ul className="strategies-panel-options-container">
            {
                options.map((item, index) =>
                    strategyPanel < 2 && index === 4 ?
                    null
                    :
                    strategyPanel === 3 && index === 3 ?
                    null
                    :
                    <li
                        className="side-navigation-element-container"
                        key={index}
                        onClick={() => selectOption(index)}
                    >
                        <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (item.active === true ? "side-navigation-option-active" : "")}>
                            <span className={item.active === true ? "body-semibold colour-primary" : "body-regular text-link"}>
                                {item.title}
                            </span>
                            {
                                defaultValuesPanel[index] === true ?
                                <img
                                    src={ChangeDot}
                                    className="strategies-panel-change-dot"
                                    alt="Changes made"
                                />
                                :
                                null
                            }
                        </div>
                    </li>
                )
            }
        </ul>
    );
};

export default StrategiesPanelFinancialOptions;