import React from 'react';
import '../styles/Tabs.css';

function Tabs(props) {
    const tabs = props.tabs;
    const value = props.value;
    const selectTab = props.selectTab;

    const chooseTab = (value) => {
        selectTab(value);
    };

    return (
        <div className="tabs-outer-container">
            <div className="tabs-inner-container">
                {
                    tabs.map((item, index) =>
                        <div 
                            className={"tabs-element-container cursor-pointer " + (item.value === value ? "tabs-element-selected-container" : "")}
                            onClick={() => chooseTab(item.value)}
                            key={index}
                        >
                            <span className={item.value === value ? "body-semibold colour-primary" : "body-regular text-link"}>
                                {item.label}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default Tabs;