import React from 'react';
import { RedirectArrowBlue, OffMarketHouse } from '../assets';
import { checkPropertySearchCredits, recordEvent } from '../functions';

function PropertySearchCard(props) {
    const item = props.item;
    const setCardClick = props.setCardClick;

    const openGoogle = () => {
        setCardClick(true);
        const googleLink = `https://www.google.com/maps/place/${item.description.replace(" ", "+")}`;
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
        setTimeout(() => {
            setCardClick(false);
        }, 500);
    };

    const viewProperty = async(e) => {
        const target = e.target;
		const targetClass = target.classList.value;
		if ( targetClass.includes("google-text-click") ) {
            return;
        }

        await checkPropertySearchCredits(item, null, setCardClick);
    }

    return (
        <div className="new-property-card-outer-container">
            <div className="new-property-card-inner-container">
                <div 
                    className="off-market-card-image-container property-search-card-image-container"
                    onClick={(e) => viewProperty(e)}
                >
                    <img
                        src={OffMarketHouse}
                        className="off-market-card-icon"
                        alt="Off Market"
                    />
                    <div 
                        className="off-market-image-inner-container google-text-click"
                        onClick={() => openGoogle()}
                    >
                        <span className="label-regular-caps colour-secondary block-text margin-x-x-small google-text-click">
                            No photos available
                        </span>
                        <div className="off-market-image-inner-row google-text-click">
                            <span className="body-regular-underline colour-link google-text-click">
                                Go to street view
                            </span>
                            <img
                                src={RedirectArrowBlue}
                                className="off-market-image-container-arrow google-text-click"
                                alt="Redirect"
                            />
                        </div>
                    </div>
                </div>
                <div 
                    className="off-market-card-body-container"
                    onClick={(e) => viewProperty(e)}
                >
                    <div className="new-property-characteristics-outer-container">
                        <div className="new-property-characteristics-address-container">
                            <span className="body-regular colour-secondary">
                                {item.description}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertySearchCard;