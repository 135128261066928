import React from 'react';
import '../styles/TopPickElement.css';
import Close from '../assets/close-new.svg';

function TopPickElement({ property, toggleElement, topPickPopUp }) {

	return (
		<div className={"top-pick-element-outer-container " + (topPickPopUp === true ? "top-pick-element-animate-in" : " top-pick-element-animate-out")}>
			<div className="top-pick-element-inner-container">
				<img
					src={Close}
					className="top-pick-element-close"
					alt="Close"
					onClick={() => toggleElement()}
				/>
				<div className="top-pick-element-title-container">
					<h2 className="top-pick-element-title-text">
						🔥 Heads up: You’ve found a deal
					</h2>
				</div>
				<div className="top-pick-element-body-container">
					<p className="top-pick-element-body-text">
						This is one of the best properties in {property.address.city}. 
						It'll likely get sold fast!
					</p>
					<p className="top-pick-element-body-reminder">
						If it's to your liking, contact your agent now.
					</p>
				</div>
			</div>
		</div>
	)
}

export default TopPickElement;