import React from 'react';
import { HandShake } from '../assets';

function SellerFinancingBadge() {
    return (
        <div className="fixer-upper-badge-outer-container">
			<img
				src={HandShake}
				className="fixer-upper-badge-icon"
				alt="Fixer Upper"
			/>
			<span className="top-pick-badge-text">
				Seller Financing
			</span>
		</div>
    )
}

export default SellerFinancingBadge;