import React, { forwardRef } from 'react';
import '../styles/ExploreMarkets.css';
import { MobileCityCard } from './';
import { citiesForPurchase } from '../styles/GlobalStyles';

const ExploreMarkets = forwardRef((props, ref) => {
    const selectCity = props.selectCity;
    const cashOnCashRankings = props.cashOnCashRankings;
    const appreciationRankings = props.appreciationRankings;
    const valueAddRankings = props.valueAddRankings;
    const landlordRankings = props.landlordRankings;
    const hotnessRankings = props.hotnessRankings;
    const listingPriceRankings = props.listingPriceRankings;
    const selectedCategory = props.selectedCategory;
    const savedUserId = props.savedUserId;

    const matchUpIcon = (item) => {
        const index = citiesForPurchase.findIndex(e => e.city_id === item.city_id);
        if ( index !== -1 ) {
            return citiesForPurchase[index].icon;
        }
        else {
            return "";
        }
    };

    const matchUpDescription = (item) => {
        const index = citiesForPurchase.findIndex(e => e.city_id === item.city_id);
        if ( index !== -1 ) {
            return citiesForPurchase[index].description;
        }
        else {
            return "";
        }
    };

    return (
        <div className="explore-markets-outer-container margin-x-x-x-large">
            <div className="explore-markets-inner-container">
                <div 
                    ref={ref}
                    className={"explore-markets-table-outer-container " + (selectedCategory === 99 ? "explore-markets-table-hidden" : "explore-markets-table-show")}
                >
                    <div className="explore-markets-overflow-container mobile-none">
                        <div className="explore-markets-grid-container explore-markets-labels-container">
                            <div className="explore-markets-grid-element">
                                <span className="label-regular-caps colour-secondary">
                                    RANK
                                </span>
                            </div>
                            <div className="explore-markets-grid-element">
                                <span className="label-regular-caps colour-secondary">
                                    CITY
                                </span>
                            </div>
                            <div className="explore-markets-grid-element">
                                <span className="label-regular-caps colour-secondary">
                                    DESCRIPTION
                                </span>
                            </div>
                        </div>
                        {
                            selectedCategory === 0 &&
                            cashOnCashRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedCategory === 1 &&
                            valueAddRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedCategory === 2 &&
                            landlordRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedCategory === 3 &&
                            appreciationRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedCategory === 4 &&
                            hotnessRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedCategory === 5 &&
                            listingPriceRankings.map((item, index) => 
                                <div 
                                    className="explore-markets-grid-container explore-markets-values-container" 
                                    key={index}
                                >
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-rank">
                                            <span className="body-regular colour-secondary">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div 
                                            className="explore-markets-grid-inner-element explore-markets-value-city"
                                            onClick={() => savedUserId === "" ? null : selectCity(item)}
                                        >
                                            <img
                                                src={matchUpIcon(item)}
                                                className="explore-markets-city-icon"
                                                alt={`${item.city}, ${item.state}`}
                                            />
                                            <span className="body-semibold colour-link">
                                                {item.city}, {item.state}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="explore-markets-grid-element">
                                        <div className="explore-markets-grid-inner-element explore-markets-value-description">
                                            <span className="body-regular colour-secondary">
                                                {matchUpDescription(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="explore-markets-mobile-cards-container desktop-none margin-x-x-large">
                        {
                            selectedCategory === 0 ?
                            cashOnCashRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            selectedCategory === 1 ?
                            valueAddRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            selectedCategory === 2 ?
                            landlordRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            selectedCategory === 3 ?
                            appreciationRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            selectedCategory === 4 ?
                            hotnessRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            selectedCategory === 5 ?
                            listingPriceRankings.map((item, index) => 
                                <MobileCityCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    matchUpIcon={matchUpIcon}
                                    selectCity={selectCity}
                                    matchUpDescription={matchUpDescription}
                                />
                            )
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
});

export default ExploreMarkets;