import React, { useEffect, useState } from 'react';
import '../styles/SideNavigation.css';
import { CoffeeIcon, CogBlack, HeartBlack, PinBlack } from '../assets';
import { DefaultUserImage, SelectedLine } from './';
import { checkOnAuthStateChanged, recordEvent } from '../functions';

function SideNavigation(props) {
    const pathname = window.location.pathname;
    const navigate = props.navigate;
    const state = props.state;
    const userData = props.userData;
    const partner = userData === null ? false : userData.partner !== undefined ? true : false;
    const describeYou = userData === null ? null : userData.describeYou !== undefined ? userData.describeYou.type : null;
    const wholesaler = describeYou === null ? false : describeYou.findIndex(e => e.label === "Wholesaler" && e.value === true) === -1 ? false : true;
    const [options, setOptions] = useState([
        {
            title: "My markets",
            icon: PinBlack,
            link: "/my-cities",
            active: pathname === "/my-cities" || pathname === "/" ? true : false
        },
        {
            title: "Financial preferences",
            icon: CogBlack,
            link: "/preferences",
            active: pathname === "/preferences" ? true : false
        },
        {
            title: "Favorites",
            icon: HeartBlack,
            link: "/favourites",
            active: pathname === "/favourites" ? true : false
        }
    ]);

    useEffect(() => {

        const checkUser = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const admins = JSON.parse(process.env.REACT_APP_ADMINIDS);
                if ( admins.indexOf(userId) !== -1 ) {
                    const optionsClone = [...options];
                    const godModeIndex = optionsClone.findIndex(option => option.link === "/god-mode");
                    if ( godModeIndex === -1 ) {
                        optionsClone.push(
                            {
                                title: "God mode",
                                icon: CoffeeIcon,
                                link: "/god-mode",
                                active: pathname === "/god-mode" ? true : false
                            }
                        );
                        setOptions(optionsClone);
                    }
                }
            }
        };

        checkUser();
    }, [options, pathname]);

    const selectOption = (item) => {
        navigate(item.link, {
            state: state
        });
    };

    const openPartnerProgramme = () => {
        recordEvent("Open Partner Programme Page", {});
        window.open("https://www.coffeeclozers.com/partner-program", "_blank");
    };

    return (
        <div className="side-navigation-outer-container bg-colour-white">
            <div className="side-navigation-inner-container relative-container">
                <div className="side-navigation-title-container">
                    <span className="label-regular-caps colour-secondary">
                        WELCOME BACK
                    </span>
                    <span className="heading-small-semibold colour-primary">
                        {
                            userData === null ?
                            null
                            :
                            userData.firstName
                        }
                    </span>
                </div>
                <div className="side-navigation-divider-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="1" viewBox="0 0 200 1" fill="none">
                        <rect width="200" height="0.5" rx="0.25" fill="#989793"/>
                    </svg>
                </div>
                <ul className="side-navigation-options-container">
                    {
                        options.map((item, index) =>
                            <li
                                className="side-navigation-element-container"
                                key={index}
                                onClick={() => selectOption(item)}
                            >
                                <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (item.active === true ? "side-navigation-option-active" : "")}>
                                    <img
                                        src={item.icon}
                                        className={"side-navigation-option-icon " + (item.active === false ? "side-navigation-option-grey-icon" : "")}
                                        alt={item.title}
                                    />
                                    <span className={item.active === true ? "body-semibold colour-primary" : "body-regular text-link"}>
                                        {item.title}
                                    </span>
                                </div>
                                <SelectedLine
                                    visible={item.active}
                                />
                            </li>
                        )
                    }
                </ul>
                <div className="side-navigation-account-container">
                    {
                        partner === true ?
                        <div className="side-navigation-element-container">
                            <div 
                                className={"button-row side-navigation-partner-container side-navigation-element-inner-container cursor-pointer " + (pathname === "/partner/dashboard" ? "side-navigation-option-active" : "")}
                                onClick={() => selectOption({link: "/partner/dashboard"})}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path 
                                        d="M15.1519 7.28845L8.74795 0.884462C8.35497 0.491482 7.71782 0.491482 7.32484 0.884462C6.93186 1.27744 6.93186 1.91459 7.32484 2.30757L12.011 6.99371L1.55988 6.99371C1.00412 6.99371 0.553589 7.44424 0.553589 8C0.553589 8.55576 1.00412 9.00629 1.55988 9.00629L12.011 9.00629L7.32484 13.6924C6.93186 14.0854 6.93186 14.7226 7.32484 15.1155C7.71782 15.5085 8.35497 15.5085 8.74795 15.1155L15.1519 8.71156C15.5449 8.31858 15.5449 7.68143 15.1519 7.28845Z"
                                        fill="#2F4858"
                                    />
                                </svg>
                                <span className="body-regular text-link">
                                    Partner Profile
                                </span>
                            </div>
                        </div>
                        :
                        wholesaler === true ?
                        <div className="side-navigation-element-container">
                            <div 
                                className={"button-row side-navigation-partner-container side-navigation-element-inner-container cursor-pointer " + (pathname === "/partner/dashboard" ? "side-navigation-option-active" : "")}
                                onClick={() => openPartnerProgramme()}
                            >
                                <span className="body-semibold text-link">
                                    👋 Are you a wholesaler?
                                </span>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="side-navigation-divider-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="1" viewBox="0 0 200 1" fill="none">
                            <rect width="200" height="0.5" rx="0.25" fill="#989793"/>
                        </svg>
                    </div>
                    <div className="side-navigation-element-container">
                        <div 
                            className={"button-row side-navigation-element-inner-container cursor-pointer " + (pathname === "/profile" ? "side-navigation-option-active" : "")}
                            onClick={() => selectOption({link: "/profile"})}
                        >
                            {
                                userData === null || userData.profileImage === undefined ?
                                <DefaultUserImage
                                    size="small"
                                />
                                :
                                <img
                                    src={userData.profileImage.url}
                                    className="side-navigation-account-pic"
                                    alt="My account"
                                />
                            }
                            <span className="body-regular text-link">
                                Account
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SideNavigation;