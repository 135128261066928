import React, { useEffect, useState, useRef } from 'react';
import '../styles/PullRecordsPay.css';
import '../styles/Pay.css';
import { ChevronLeft } from '../assets';
import { checkOnAuthStateChanged, createStripeSetupIntent, deleteStripePaymentMethod, recordEvent, getUserDocuments, setData, submitPayment, updateStripeCustomer, checkStripeError, getSetupIntent, getStripeCustomer, getDocument, queryOffMarketData } from '../functions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { CreditCard, DeleteCardModal, Loading, Header, Footer, LoadingStatic, SetupForm, RecordsPricingCard, RecordsMobilePricingOverlay } from '../components';

const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
const stripePromise = loadStripe(mode === "test" ? JSON.parse(process.env.REACT_APP_STRIPE).test.publishable : JSON.parse(process.env.REACT_APP_STRIPE).live.publishable);

function PullRecordsPay() {

    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [paymentError, setPaymentError] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [userId, setUserId] = useState("");
    const [mobileAccordion, setMobileAccordion] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const stateSearchParams = searchParams.get('stateId');
    const location = useLocation();
    const state = location.state;
    const creditCardButton = useRef(null);
    const navigate = useNavigate();
    const [records, setRecords] = useState(state === null ? null : state.records);

    const options = {
        clientSecret: clientSecret,
        fonts: [
            {
                cssSrc: "https://fonts.googleapis.com/css?family=Rubik"
            }
        ],
        appearance: {
            theme: "stripe",
            variables: {
                fontFamily: "Rubik",
                colorBackground: colour.grayScaleWhite,
                colorText: colour.textPrimary,
                colorDanger: colour.redRed01,
                borderRadius: '3px',
                fontSizeBase: '14px',
                colorPrimary: colour.brownBrown01
            }
        }
    };

    useEffect(() => {
        document.title = "Checkout Records | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const collections = [
					"Users",
					"Subscriptions"
				];
                const docRef = user.userId;
                setUserId(docRef);
				const queryUserData = await getUserDocuments(collections, docRef);
                if ( queryUserData[0].status === 200 ) {
                    const userDetails = queryUserData[0].data;
                    setUserData(userDetails);

                    if ( userDetails.creditCard === undefined ) {
                        setupIntent(userDetails, docRef);
                    }
                }
                else {
                    navigate("/pull-records");
                }

                if ( queryUserData[1].status === 200 ) {
                    setSubscriptions(queryUserData[1].data);
                }
            }
            else {
                navigate("/pull-records");
            }
        }

        const setupIntent = async(userDetails, userId) => {
            const getSetupIntentParams = searchParams.get('setup_intent');
            if ( clientSecret !== "" ) {
                return;
            }
            else if ( getSetupIntentParams !== null && userDetails.creditCard === undefined ) {
                const card = await getSetupIntent(getSetupIntentParams, mode);
                if ( card !== "" ) {
                    const amendedCard = {
                        brand: card.brand,
                        last4: card.last4,
                        exp_month: card.exp_month,
                        exp_year: card.exp_year,
                        mode: card.mode,
                        paymentMethod: card.paymentMethod
                    };
                    const colRef = "Users";
                    const newUserDetails = structuredClone(userDetails);
                    newUserDetails.creditCard = amendedCard;
                    await setData(colRef, userId, newUserDetails);
                    setUserData(newUserDetails);
                }
            }
            else {
                let customerId = userDetails.customerId;
                if ( customerId === undefined ) {
                    const customer = {
                        email: userDetails.email,
                        name: `${userDetails.firstName} ${userDetails.lastName}`,
                        phone: userDetails.phoneNumber !== undefined ? userDetails.phoneNumber : "",
                        mode: mode
                    };
                    const getCustomer = await getStripeCustomer(customer);
                    customerId = getCustomer.customerId;
                    const colRef = "Users";
                    const docRef = userId;
                    const data = userDetails;
                    data.customerId = customerId;
                    setUserData(data);
                    await setData(colRef, docRef, data);
                }
                const params = {
                    customerId: customerId,
                    mode: mode
                };
                const getIntent = await createStripeSetupIntent(params);
                if ( getIntent.status === 200 ) {
                    const id = getIntent.setupIntent.client_secret;
                    setClientSecret(id);
                }
            }
        };

        const querySavedState = async() => {
            const getStateColRef = "Page State";
            const queryState = await getDocument(getStateColRef, stateSearchParams);
            if ( queryState.status === 200 ) {
                const stateData = queryState.data.data;
                setRecords(stateData);
                setSearchParams({});
                fetchUserData();
            }
            else if ( state === null ) {
                navigate("/pick-city");
            }
        };

        if ( state === null && stateSearchParams !== null ) {
            querySavedState();
        }
        else if ( state === null && stateSearchParams === null && records === null ) {
            navigate("/pull-records");
        }
        else {
            fetchUserData();
        }
    }, [navigate, state, clientSecret, searchParams, setSearchParams, stateSearchParams, records]);

    const goBack = () => {
        navigate("/pull-records");
    };

    const showDeleteModal = () => {
        recordEvent("Show Delete Card Modal");
        setDeleteModal(true);
    };

    const deleteCard = async(item) => {
        setDisabled(true);
        setDeleteModal(false);
        let clone = JSON.parse(JSON.stringify(userData))
        delete clone.creditCard;
        setClientSecret("");
        setUserData(clone);
        const params = {
            mode: item.mode,
            paymentMethod: item.paymentMethod
        };
        const deletion = await deleteStripePaymentMethod(params);
        if ( deletion.status === 200 ) {
            const colRef = "Users";
            const data = clone;
            await setData(colRef, userId, data);
            setUserData(clone);

            const setupIntentParams = {
				customerId: data.customerId,
				mode: item.mode
			};
			const getIntent = await createStripeSetupIntent(setupIntentParams);
			if ( getIntent.status === 200 ) {
				const id = getIntent.setupIntent.client_secret;
				setClientSecret(id);
				setDisabled(false);
			}
        }
        setDisabled(false);
	};

    const toggleAccordion = () => {
        setMobileAccordion(!mobileAccordion);
    };

    const saveCreditCard = () => {
        if ( creditCardButton.current !== null) {
            creditCardButton.current.click();
        }
    };

    const complete = async(newUserDetails) => {
        setLoading(true);

        if ( newUserDetails === undefined ) {
            newUserDetails = userData;
        }

        const newTotal = Number((records.total).toFixed(2));
        const metadata = {
            price: newTotal,
            allCities: false,
            discountApplied: null,
            discountedTotal: 0
        };
        const sendPayment = await submitPayment(newUserDetails, metadata);
        if ( sendPayment.status === 200 ) {
            const colRef = "Records Download";
            const docRef = userId;
            const queryRecords = await getDocument(colRef, docRef);
            let oldRecords = [];
            if ( queryRecords.status === 200 ) {
                oldRecords = queryRecords.data.data;
            }

            const paymentId = sendPayment.paymentMethod.id;
            const date = new Date();
            const newRecord = {
                date: date,
                total: newTotal,
                comps: records.comps,
                reAPIParams: records.reAPIParams,
                skipTrace: records.skipTrace,
                totalRecords: records.totalRecords,
                id: paymentId,
                county: records.county
            };
            newRecord.date = new Date();
            oldRecords.push(newRecord);
            await setData(colRef, docRef, oldRecords);

            const recordParams = {
                reAPIParams: records.reAPIParams,
                reAPIEndpoint: "PropertySearch",
                skipTrace: records.skipTrace,
                comps: records.comps,
                compCount: 10,
                id: paymentId,
                date: date,
                userId: userId,
                email: newUserDetails.email,
                county: records.county,
                total: newTotal,
                totalRecords: records.totalRecords,
                reApiQuerySize: records.reAPIParams.size
            };

            if ( mode === "test" ) {
                recordParams.reAPIParams.size = 1;
                recordParams.totalRecords = 1;
            }
            queryOffMarketData(recordParams);
            navigate("/pull-records-success", {
                state: {
                    email: newUserDetails.email
                }
            });
            setLoading(false);
        }
        else if ( sendPayment.status === 404 ) {
            const newCustomerData = {
                paymentMethod: newUserDetails.creditCard.paymentMethod,
                customerId: newUserDetails.customerId,
                mode: mode
            }
            const setDefaultPaymentMethod = await updateStripeCustomer(newCustomerData);
            if ( setDefaultPaymentMethod.status === 200 ) {
                complete(userData);
            }
            else {
                setPaymentError("There was an error processing your subscription. Please delete your credit card and re-add it.");
                setLoading(false);
            }
        }
        else {
            const error = sendPayment.paymentMethod;
            const checkedError = await checkStripeError(error);
            setPaymentError(checkedError);
            setLoading(false);
        }
    };

    return (
        <div className="pay-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            {
                state === null && records === null ?
                null
                :
                <div className="pay-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    {
                        userData !== null && userData.creditCard !== undefined && deleteModal === true ?
                        <DeleteCardModal
                            creditCard={userData.creditCard}
                            deleteFunction={deleteCard}
                            setDeleteModal={setDeleteModal}
                        />
                        :
                        null
                    }
                    <div className="pay-row-container">
                        <div className="pay-left-container margin-x-small">
                            <div className="city-selection-mobile-back-container desktop-none">
                                <div 
                                    className="button-row text-button"
                                    onClick={() => goBack()}
                                >
                                    <img
                                        src={ChevronLeft}
                                        className="city-selection-back-icon"
                                        alt="Back icon"
                                    />
                                    <span className="body-regular colour-link">
                                        Back
                                    </span>
                                </div>
                            </div>
                            <div className="city-selection-mobile-progress-bar margin-x-large desktop-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                                    <rect 
                                        width="100%" 
                                        height="4" 
                                        rx="2" 
                                        fill="#F2E1D0"
                                    />
                                    <rect
                                        width={`100%`}
                                        height="4" 
                                        rx="2" 
                                        fill={colour.blueBlue03}
                                    />
                                </svg>
                            </div>
                            <h1 className="heading-small-semibold colour-primary margin-large">
                                Payment method
                            </h1>
                            {
                                userData !== null && userData.creditCard !== undefined ?
                                <span className="body-regular colour-secondary block-text margin-large">
                                    Found card on file
                                </span>
                                :
                                null
                            }
                            {
                                userData === null ?
                                <LoadingStatic />
                                :
                                userData.creditCard !== undefined ?
                                <CreditCard
                                    creditCard={userData.creditCard}
                                    deleteCard={showDeleteModal}
                                    checkout={true}
                                />
                                :
                                clientSecret !== "" ?
                                    <Elements
                                        stripe={stripePromise} 
                                        options={options}
                                    >
                                        <SetupForm
                                            clientSecret={clientSecret}
                                            domain="pull-records-pay"
                                            bundle={false}
                                            userData={userData}
                                            setUserData={setUserData}
                                            setPaymentMethodModal={null}
                                            autoBilling={false}
                                            setAutoBilling={null}
                                            product={null}
                                            city={null}
                                            loading={loading}
                                            setLoading={setLoading}
                                            setPaymentError={setPaymentError}
                                            payFunc={complete}
                                            creditCardButton={creditCardButton}
                                            setDisabled={setDisabled}
                                            pageState={records}
                                        />
                                    </Elements>
                                :
                                <LoadingStatic />
                            }
                            {
                                paymentError !== "" ?
                                <div className="pay-payment-error-container margin-top-small">
                                    <span className="body-regular colour-error">
                                        {paymentError}
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                        {
                            records === null ?
                            null
                            :
                            <div className="pay-right-container">
                                <RecordsPricingCard
                                    total={records.total}
                                    plan={records.plan}
                                />
                            </div>
                        }
                    </div>
                    <div className="new-checkout-buttons-row margin-top-x-x-large mobile-none">
                        <div 
                            className="button-row text-button"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeft}
                                className="city-selection-back-icon"
                                alt="Back icon"
                            />
                            <span className="body-regular colour-link">
                                Back
                            </span>
                        </div>
                        <div className="city-selection-next-button">
                            <ActionButton
                                disabled={userData === null || loading === true || disabled === true ? true : false}
                                onClick={() => userData !== null && userData.creditCard !== undefined ? complete() : saveCreditCard()}
                            >
                                Submit payment
                            </ActionButton>
                        </div>
                    </div>
                    {
                        deleteModal === true ?
                        null
                        :
                        <RecordsMobilePricingOverlay
                            toggleAccordion={toggleAccordion}
                            mobileAccordion={mobileAccordion}
                            deleteCity={null}
                            complete={complete}
                            checkout={true}
                            total={records.total}
                            plan={records.plan}
                            cta="Submit payment"
                            payment={true}
                            disabled={userData === null || loading === true || disabled === true ? true : false}
                            userData={userData}
                            saveCreditCard={saveCreditCard}
                            allCities={false}
                            discountApplied={null}
                            title={records.plan.title}
                        />
                    }
                </div>
                }
            <Footer />
        </div>
    );
};

export default PullRecordsPay;