import React from 'react';

function OversizedQuery(props) {
    const cityStateName = props.cityStateName;

    return (
        <div className="new-property-list-no-matching-properties-container">
            <h2 className="new-property-list-no-matching-properties-title">
                Your filters are too broad!
            </h2>
            <h3 className="new-property-list-no-matching-properties-subtitle">
                Since {cityStateName} has so many properties, we're unable to load them all at once.
                Please select some more filters to narrow down the search to ideally below 5,000 properties!
            </h3>
        </div>
    )
}

export default OversizedQuery;