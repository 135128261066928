import React, { useEffect, useState } from 'react';
import '../styles/GodMode.css';
import { checkOnAuthStateChanged, cloudFunctionV2, getCitySearch, getDocument, getMetroSearch, makeId, sendCityIDErrorEmail, setData, getCountySearch, queryAllSubscriptions } from '../functions';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Chip, Footer, Header, Loading, LoadingStatic, PlaceInput, SideNavigation } from '../components';
import { ActionButton, FilterCityInput, allStates, stateMetros } from '../styles/GlobalStyles';
import moment from 'moment';
import { Exit, Search } from '../assets';
import { counties } from '../counties';

function GodMode() {

    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [localLocation, setLocalLocation] = useState("");
    const [cities, setCities] = useState([]);
    const [error, setError] = useState("");
    const [selectedOption, setSelectedOption] = useState(0);
    const [waitingRoom, setWaitingRoom] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [metro, setMetro] = useState("");
    const [showList, setShowList] = useState(false);
    const [metroList, setMetroList] = useState([]);
    const [county, setCounty] = useState("");
    const [countyOptions, setCountyOptions] = useState([]);
    const [subsLoading, setSubsLoading] = useState(false);
    const [lastSubs, setLastSubs] = useState([]);
    const getTab = searchParams.get('tab');
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const options = [
        "Add a city",
        "Add a metro",
        "Add a county",
        "Waiting room"
    ];

    useEffect(() => {
        document.title = "God Mode";
        const fetchUser = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                if ( JSON.parse(process.env.REACT_APP_ADMINIDS).indexOf(userId) !== -1 ) {
                    if ( getTab !== null ) {
                        setSelectedOption(Number(getTab));
                    }

                    const colRef = "Users";
                    const queryData = await getDocument(colRef, userId);
                    if ( queryData.status === 200 ) {
                        setUserDetails(queryData.data.data);
                        setLoading(false);

                        const waitingRoomColRef = "Waiting Room";
                        const waitingRoomDocRef = "Users";
                        const queryWaitingRoomData = await getDocument(waitingRoomColRef, waitingRoomDocRef);
                        if ( queryWaitingRoomData.status === 200 ) {
                            setWaitingRoom(queryWaitingRoomData.data.data);
                        }
                    }
                }
                else {
                    navigate("/");
                }
            }
            else {
                navigate("/");
            }
        };

        fetchUser();
    }, [navigate, getTab]);

    const getLast5Subs = async() => {
        setSubsLoading(true);
        const queryData = await queryAllSubscriptions();
        if ( queryData.status === 200 ) {
            const subData = queryData.data;
            const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
            const filteredData = [];
            for (let index = 0; index < subData.length; index++) {
                const element = subData[index];
                if ( adminIds.indexOf(element.userId) === -1 ) {
                    filteredData.push(element);
                }
            }
            
            filteredData.sort((a,b) => (a.startDate._seconds < b.startDate._seconds) ? 1 : ((b.startDate._seconds < a.startDate._seconds) ? -1 : 0));
            const lastData = filteredData.slice(0, 5);
            setLastSubs(lastData);
            setSubsLoading(false);
        }
        else {
            alert("ERROR: Reload page and try again");
        }
    };

    const changeLocation = (val) => {
        setLocalLocation(val);
    };

    const selectedLocation = async(place) => {
        setDisabled(true);
        if ( place !== null ) {
            const terms = place.value.terms;
            if ( terms.length >= 2 ) {
                const city = terms[0].value;
                
                let cityState = terms[1].value;
                if ( cityState.length > 2 ) {
                    const stateIndex = allStates.findIndex(e => e.name === cityState);
                    if ( stateIndex !== -1 ) {
                        cityState = allStates[stateIndex].abbreviation;
                    }
                }
                const findCity = cities.findIndex(item => item.city === city && item.state === cityState);
                if ( findCity === -1 ) {
                    const checkTier = await checkCityTier(city, cityState);
                    if ( checkTier.status === 200 ) {
                        setCities([...cities, checkTier.data]);
                    }
                }
            }
        }
        setDisabled(false);
    };

    const checkCityTier = async(city, cityState) => {
        setDisabled(true);

        const params = {
            city: city,
            state: cityState,
            returnCityId: "True"
        }
        
        const getCityParams = await getCitySearch(params, false);
        const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
        const gatewayData = {
            type: "gateway",
            resourceId: "citySearchLive",
            queryString: getCityParams
        };
        const queryData = await cloudFunctionV2(gatewayURL, gatewayData);
        if ( queryData.status === 200 ) {
            const body = queryData.body;

            if ( body.cityId === undefined || body.cityId === null ) {
                const cityIdErrorPage = "God Mode";
			    const apiResponse = `City ID null or undefined: ${body.cityId}`;
                await sendCityIDErrorEmail(city, cityState, cityIdErrorPage, apiResponse);
                setError(`There was an error with ${city}, ${cityState}: cityId = ${body.cityId}`);

                const res = {
                    status: 400,
                    data: null
                };
                return res;
            }
            else {
                const startDate = new Date();
                const endDate = moment(startDate).add(1, "years");
                const id = await makeId(10);
                const newObject = {
                    allCities: false,
                    autoBilling: false,
                    city: city,
                    cityId: body.cityId,
                    metroArea: false,
                    state: cityState,
                    price: 0,
                    id: id,
                    startDate: startDate,
                    endDate: endDate._d
                }
                setError("");

                const res = {
                    status: 200,
                    data: newObject
                };
                return res;
            }
        }
        else {
            setDisabled(false);
            setError("There was an error with this city");
            const res = {
                status: 400,
                data: null
            };
            return res;
        }
    };

    const changeMetro = (val) => {
        setMetro(val);
        setShowList(true);
        const valLowerCase = val.toLowerCase();

        if ( val === "" ) {
            setMetroList(stateMetros);
        }
        else {
            const newArray = [];
            const cloneArray = [...stateMetros];
            for (let index = 0; index < cloneArray.length; index++) {
                const element = cloneArray[index];
                const stateFull = element.stateFull.toLowerCase();
                if ( stateFull.includes(valLowerCase) ) {
                    newArray.push(stateMetros[index]);
                }

                const metrosInState = element.metros;
                for (let ii = 0; ii < metrosInState.length; ii++) {
                    const msaTitle = cloneArray[index].metros[ii].msaTitle.toLowerCase();
                    if ( msaTitle.includes(valLowerCase) ) {
                        const stateIndex = newArray.findIndex(e => e.stateFull === element.stateFull);
                        if ( stateIndex === -1 ) {
                            const newObject = {
                                stateFull: element.stateFull,
                                state: element.state,
                                metros: [cloneArray[index].metros[ii]]
                            }
                            newArray.push(newObject);
                        }
                        else {
                            const msaIndex = newArray[stateIndex].metros.findIndex(e => e.msaTitle === cloneArray[index].metros[ii].msaTitle);
                            if ( msaIndex === -1 ) {
                                newArray[stateIndex].metros.push(cloneArray[index].metros[ii]);
                            }
                        }
                    }
                }

            }
            setMetroList(newArray);
        }
    };

    const selectMetro = async(item) => {
        setShowList(false);
        setMetro("");
        setDisabled(true);

        const params = {
            msaTitle: `${item.msaTitle}, ${item.state}`,
            county: false,
            returnCityId: "True"
        };
		const getCityParams = await getMetroSearch(params);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "msa",
			queryString: getCityParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            const msaCityList = [];
            const msaCityIDs = [];
            for (let index = 0; index < body.msaCityList.length; index++) {
                const element = body.msaCityList[index];
                const newObject = {
                    city: element.city,
                    state: element.state,
                    cityId: element.cityId
                };
                msaCityList.push(newObject);
                msaCityIDs.push(element.cityId);
            }

            const today = new Date();
            const endDate = moment(today).add(1, "years");
            const id = await makeId(10);

            const newObject = {
                allCities: false,
                autoBilling: false,
                endDate: endDate._d,
                metroArea: true,
                msaTitle: body.msaTitle,
                msaCode: body.msaCode,
                metroCities: msaCityList,
                msaCityIds: msaCityIDs,
                price: 0,
                id: id,
                startDate: today
            };
            setCities([...cities, newObject]);

            setError("");
            setDisabled(false);
        }
        else {
            setDisabled(true);
            console.log("Error: ", getCityDetails);
        }
    };

    const changeCounty = (val) => {
        setCounty(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
        };
        setCountyOptions(newArray);
    };

    const selectCounty = async(item) => {
        setCounty("");
        setDisabled(true);
        const countyParams = {
            countyId: `CTY${item.code}`
        }
		const getCountyParams = await getCountySearch(countyParams);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "county",
			queryString: getCountyParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            body.price = item.price;
            const msaCityList = [];
            const msaCityIDs = [];
            for (let index = 0; index < body.msaCityList.length; index++) {
                const element = body.msaCityList[index];
                const newObject = {
                    city: element.city,
                    state: element.state,
                    cityId: element.cityId
                };
                msaCityList.push(newObject);
                msaCityIDs.push(element.cityId);
            }

            const today = new Date();
            const endDate = moment(today).add(1, "years");
            const id = await makeId(10);

            const newObject = {
                allCities: false,
                autoBilling: false,
                endDate: endDate._d,
                metroArea: true,
                msaTitle: body.countyName,
                msaCode: body.countyId,
                metroCities: msaCityList,
                msaCityIds: msaCityIDs,
                price: 0,
                id: id,
                startDate: today
            };
            setCities([...cities, newObject]);
        }
        setDisabled(false);
    };

    const deleteListing = (index) => {
        const citiesClone = [...cities];
        citiesClone.splice(index, 1);
        setCities(citiesClone);
    };

    const complete = async() => {
        setDisabled(true);
        const colRef = "Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            let buyBoxes = [];
            const getBuyBoxes = await getDocument(colRef, docRef);
            if ( getBuyBoxes.status === 200 ) {
                buyBoxes = getBuyBoxes.data.data;
            }

            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const cityIdIndex = buyBoxes.findIndex(e => element.metroArea === true ? e.msaCode === element.msaCode : e.cityId === element.cityId);
                if ( cityIdIndex === -1 ) {
                    // SETTING LOADED TO TRUE ONLY FOR GOD MODE
                    const newObject = {
                        buyBoxes: [],
                        metroArea: element.metroArea,
                        loaded: true
                    }

                    if ( element.metroArea === true ) {
                        newObject.msaTitle = element.msaTitle;
                        newObject.msaCode = element.msaCode;
                        newObject.metroCities = element.metroCities;
                        newObject.msaCityIds = element.msaCityIds;
                    }
                    else {
                        newObject.city = element.city;
                        newObject.state = element.state;
                        newObject.cityId = element.cityId;
                    }
                    buyBoxes.push(newObject);
                }
            }

            await setData(colRef, docRef, buyBoxes);

            const newSubscriptions = [];
            let subscriptionArray = [];
            const subscriptionColRef = "Subscriptions";
            const querySubscriptionData = await getDocument(subscriptionColRef, docRef);
            if ( querySubscriptionData.status === 200 ) {
                subscriptionArray = querySubscriptionData.data.data;
            }

            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const newSubscription = {
                    allCities: false,
                    autoBilling: false,
                    endDate: element.endDate,
                    id: element.id,
                    metroArea: element.metroArea,
                    price: element.price,
                    startDate: element.startDate
                };
                if ( element.metroArea === true ) {
                    newSubscription.msaTitle = element.msaTitle;
                    newSubscription.msaCode = element.msaCode;
                    newSubscription.metroCities = element.metroCities;
                    newSubscription.msaCityIds = element.msaCityIds;
                }
                else {
                    newSubscription.city = element.city;
                    newSubscription.state = element.state;
                    newSubscription.cityId = element.cityId;
                }
                subscriptionArray.push(newSubscription);
                newSubscriptions.push(newSubscription);
            }
            await setData(subscriptionColRef, docRef, subscriptionArray);
            navigate("/my-cities");
        }
        setDisabled(false);
    };

    const changeSelectedPanel = (index) => {
        setSelectedOption(index);
        setSearchParams({tab: index});
    };

    return (
        <div className="god-mode-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={userDetails}
                queries={[false, true]}
                mobileNav={true}
            />
            {
                loading === true ?
                <div className="god-mode-inner-container">
                    <Loading />
                </div>
                :
                <div className="god-mode-outer-row-container">
                    <SideNavigation
                        navigate={navigate}
                        state={state}
                        userData={userDetails}
                    />
                    <div className="god-mode-inner-container">
                        <div className="god-mode-title-container margin-large">
                            <h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
                                Welcome to God mode
                            </h1>
                            <span className="body-regular colour-secondary block-text text-align-center">
                                (blasphemy intended)
                            </span>
                        </div>
                        {
                            disabled === true ?
                            <Loading />
                            :
                            null
                        }
                        <div className="god-mode-options-row margin-large">
                            {
                                options.map((item, index) =>
                                    <Chip
                                        label={item}
                                        func={changeSelectedPanel}
                                        index={index}
                                        selected={selectedOption}
                                        key={index}
                                        changeIcon={null}
                                        recommended={false}
                                        hover={null}
                                        leave={null}
                                        disabled={false}
                                    />
                                )
                            }
                        </div>
                        {
                            selectedOption === 0 ?
                            <div className="god-mode-body-container margin-large">
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    Pick the cities you'd like to add to your account
                                </span>
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    This will <span className="body-semibold">NOT</span> cause a data run.
                                    It will only add the cities to your account so you're allowed to view them.
                                </span>
                                <PlaceInput
                                    placeholder="Search to add a city"
                                    changeLocation={changeLocation}
                                    localLocation={localLocation}
                                    selectedLocation={selectedLocation}
                                    types={['locality']}
                                    disabled={disabled}
                                />
                                {
                                    error === "" ?
                                    null
                                    :
                                    <div className="margin-top-small">
                                        <span className="body-regular colour-error">
                                            {error}
                                        </span>
                                    </div>
                                }
                            </div>
                            :
                            selectedOption === 1 ?
                            <div className="god-mode-body-container margin-large">
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    Pick the metros you'd like to add to your account
                                </span>
                                <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                                    <FilterCityInput
                                        value={metro}
                                        type="text"
                                        id="metro-input"
                                        placeholder="Search for any major metro..."
                                        onChange={(text) => changeMetro(text.target.value)}
                                        disabled={false}
                                    />
                                    <img
                                        src={metro === "" ? Search : Exit}
                                        className={metro === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                                        alt="Search"
                                    />
                                    {
                                        showList === false ?
                                        null
                                        :
                                        <div className="city-filtering-outer-container">
                                            {
                                                metroList.map((item, index) =>
                                                    <div
                                                        className="city-filtering-state-container"
                                                        key={index}
                                                    >
                                                        <div className="city-filtering-state-text-container">
                                                            <span className="label-semibold-caps colour-primary">
                                                                {item.stateFull}
                                                            </span>
                                                        </div>
                                                        {
                                                            item.metros.map((subItem, subIndex) =>
                                                                <div 
                                                                    className="metro-filtering-element"
                                                                    key={subIndex}
                                                                    onClick={() => selectMetro(subItem)}
                                                                >
                                                                    <span className="body-regular colour-primary">
                                                                        {subItem.msaTitle}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="metro-filtering-divider-padding">
                                                            <div className="metro-filtering-divider margin-tops">
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                    {
                                        error === "" ?
                                        null
                                        :
                                        <div className="margin-top-small">
                                            <span className="body-regular colour-error">
                                                {error}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            selectedOption === 2 ?
                            <div className="god-mode-body-container margin-large">
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    Pick the counties you'd like to add to your account
                                </span>
                                <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                                    <FilterCityInput
                                        value={county}
                                        type="text"
                                        id="upgrade-city-input"
                                        placeholder="Search for any county in the U.S..."
                                        onChange={(text) => changeCounty(text.target.value)}
                                        disabled={false}
                                    />
                                    <img
                                        src={county === "" ? Search : Exit}
                                        className={county === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                                        alt="Search"
                                    />
                                    {
                                        countyOptions.length > 0 && county !== "" ?
                                            <div className="city-filtering-outer-container">
                                                {
                                                    countyOptions.map((item, index) =>
                                                        <div 
                                                            className="city-filtering-element"
                                                            key={index}
                                                            onClick={() => selectCounty(item)}
                                                        >
                                                            <span className="body-regular colour-primary">
                                                                {item.county}{item.state === "LA" ? "" : " County"}, {item.state} (${item.price})
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            <div className="god-mode-waiting-room-body-container margin-large">
                                {
                                    waitingRoom.map((item, index) =>
                                        <div 
                                            className="god-mode-waiting-room-element margin-medium"
                                            key={index}
                                        >
                                            <span className="body-regular colour-primary block-text margin-x-small">
                                                {index + 1}{")"} {item.city}, {item.state}
                                            </span>
                                            <span className="body-regular colour-primary block-text margin-x-small">
                                                City ID: {item.cityId}
                                            </span>
                                            {
                                                item.users.map((user, userIndex) =>
                                                    <div 
                                                        className="god-mode-waiting-room-user margin-x-small"
                                                        key={userIndex}
                                                    >
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {user.email}
                                                        </span>
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {user.userId}
                                                        </span>
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {moment.unix(user.date.seconds).format("HH:mm on DD MMMM YYYY")}
                                                        </span>
                                                    </div>)
                                            }
                                        </div>
                                    )
                                }
                                <div className="god-mode-last-five-subs-container">
                                    {
                                        subsLoading === true ?
                                        <LoadingStatic />
                                        :
                                        lastSubs.length === 0 ?
                                        <div className="">
                                            <ActionButton
                                                onClick={() => getLast5Subs()}
                                            >
                                                Get last 5 subscriptions
                                            </ActionButton>
                                        </div>
                                        :
                                        <div className="god-mode-last-five-list">
                                            <h3 className="body-semibold colour-primary margin-medium">
                                                Last 5 subscriptions 
                                            </h3>
                                            {
                                                lastSubs.map((item, index) =>
                                                    <div 
                                                        className="margin-large"
                                                        key={index}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            {index + 1}) {item.metroArea === true ? `${item.msaTitle} (${item.msaCode})` : `${item.city}, ${item.state} (${item.cityId})`}
                                                            {"  "}-{"  "}
                                                            {moment.unix(item.startDate._seconds).format("HH:mm on DD MMMM YYYY")}
                                                        </span>
                                                        <br/>
                                                        <span className="body-regular colour-primary">
                                                            USER ID - {item.userId}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            selectedOption < 3 ?
                            <div className="god-mode-results-container">
                                <div className="god-mode-city-list-container margin-large margin-top-medium">
                                    {
                                        cities.map((item, index) =>
                                            <div 
                                                className="god-mode-city-list-item margin-medium"
                                                key={index}
                                            >
                                                {
                                                    item.metroArea === true ?
                                                    <span className="body-regular colour-primary block-text margin-x-small">
                                                        {item.msaTitle} - {item.msaCode}
                                                    </span>
                                                    :
                                                    <span className="body-regular colour-primary block-text margin-x-small">
                                                        {item.city}, {item.state} - {item.cityId}
                                                    </span>
                                                }
                                                <span 
                                                    className="body-regular colour-error cursor-pointer"
                                                    onClick={() => deleteListing(index)}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="god-mode-button-container">
                                    <ActionButton
                                        onClick={() => complete()}
                                        disabled={disabled === true ? true : loading === true ? true : cities.length === 0 ? true : false}
                                    >
                                        Complete
                                    </ActionButton>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default GodMode;