import React, { useEffect, useState } from 'react';
import '../styles/Location.css';
import { MultiLocationMap } from './';
import { getMiddle, recordEvent } from '../functions';

function CompareLocations(props) {

    const properties = props.properties;
	const tracts = props.tracts;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const neighbourhoodData = props.neighbourhoodData;
	const [centreCoordinates, setCentreCoordinates] = useState([]);

	useEffect(() => {
		async function findCentre(markers) {
            const latMiddle = getMiddle('latitude', markers);
            const lngMiddle = getMiddle('longitude', markers);
            const centreObject = {
                lat: latMiddle,
                lng: lngMiddle
            };
            setCentreCoordinates(centreObject);
        }
        findCentre(properties);
	}, [properties])

	const openCrimeMap = () => {
		const cityState = properties.length === 0 ? "" : `${properties[0].address.city}, ${properties[0].address.state}`;
		const lowerCaseCity = cityState.toLowerCase();
		const formattedCity = lowerCaseCity.replace(" ", "-");
		const crimeGradeURL = `https://crimegrade.org/safest-places-in-${formattedCity}/`;
		window.open(crimeGradeURL, "_blank");
		recordEvent("Open Crime Map", {
			city: cityState
		});
	};

	return (
		<div className="location-outer-container">
			<div className="location-inner-container">
				<h2 className="heading-small-semibold colour-primary margin-medium">
                   Location
                </h2>
				{
					centreCoordinates.length !== 0 ?
					<MultiLocationMap
						properties={properties}
						controls={false}
						text={true}
						neighbourhoodBounds={neighbourhoodBounds}
						tracts={tracts}
						centreCoordinates={centreCoordinates}
						neighbourhoodData={neighbourhoodData}
					/>
					:
					null
				}
				<div className="location-crime-map-container">
					<span 
						className="body-regular-underline colour-link"
						onClick={() => openCrimeMap()}
					>
						View crime map
					</span>
				</div>
			</div>
		</div>
	)
}

export default CompareLocations;