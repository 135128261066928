import React, { useState, useEffect } from 'react';
import '../styles/PropertySearchLandingPage.css';
import { Footer, Header, Loading, LoadingStatic, NewPropertyCard, PlaceInput, PropertySearchPagination } from '../components';
import { containsNumbers, checkOnAuthStateChanged, getDocument, setData, getIp, getWhereDoc, writeSearch, makeId, getAPIGatewaySavedProperties, recordEvent, getUserDocuments, cloudFunctionV2 } from '../functions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { ActionButton, defaultUserData, formatterLong } from '../styles/GlobalStyles';
import { LandingImage } from '../assets/images';

function PropertySearchLandingPage() {
    const [searchParams] = useSearchParams({});
    const getAddress = searchParams.get("address");
    const [searchLocation, setSearchLocation] = useState(getAddress === null ? "" : getAddress);
    const [error, setError] = useState("");
    const [userData, setUserData] = useState(null);
    const [savedUserId, setSavedUserId] = useState("");
    const [credits, setCredits] = useState(0);
    const [maxCredits, setMaxCredits] = useState(null);
    const [ipError, setIpError] = useState(false);
    const [googlePlacesDisabled, setGooglePlacesDisabled] = useState(false);
    const [ipUser, setIpUser] = useState(null);
    const [noUserId, setNoUserId] = useState("");
    const [searchHistory, setSearchHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [queriedUser, setQueriedUser] = useState(false);
    const percentageFilled = maxCredits === null ? 100 : (credits / maxCredits) * 100 > 100 ? 100 : (credits / maxCredits) * 100;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        document.title = "Quick Report";

        const fetchUserData = async() => {
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const userId = getUserId.userId;
                setSavedUserId(userId);
                const collections = [
					"Users",
                    "Property Searches",
                    "Financial Preferences",
                    "Subscriptions"
				];

				const queryData = await getUserDocuments(collections, userId);
                if ( queryData[3].status === 200 ) {
                    const subscriptionData = queryData[3].data;
                    setSubscriptions(subscriptionData);
                    setUserData(queryData[0].data);
                    const propertySearchData = queryData[1].status === 200 ? queryData[1].data : {
                        searches: [],
                        credits: 5
                    };

                    const today = new Date();
                    const todaySeconds = today.getTime() / 1000;
                    let paidPlan = false;
                    for (let index = 0; index < subscriptionData.length; index++) {
                        const element = subscriptionData[index];
                        const endDate = element.endDate.seconds;
                        if ( endDate > todaySeconds ) {
                            paidPlan = true;
                        }
                    };

                    if ( paidPlan === false && propertySearchData.credits > 10 ) {
                        const remainingCredits = 5 - propertySearchData.searches.length;

                        setCredits(remainingCredits < 0 ? 0 : remainingCredits);
                        const newObject = {
                            credits: remainingCredits,
                            searches: propertySearchData.searches
                        };
                        await setData("Property Searches", userId, newObject);
                    }
                    else if ( paidPlan === false && propertySearchData.credits > 5 ) {
                        setMaxCredits(10);
                    }
                    else if ( paidPlan === true ) {
                        setMaxCredits(50);
                    }
                }
                else {
                    const propertySearchData = queryData[1].status === 200 ? queryData[1].data : {
                        searches: [],
                        credits: 5
                    };
                    if ( propertySearchData.credits > 10 ) {
                        setCredits(5);
                        const newObject = {
                            credits: 5,
                            searches: propertySearchData.searches
                        };
                        await setData("Property Searches", userId, newObject);
                    }
                    else if ( propertySearchData.credits > 5 ) {
                        setMaxCredits(10);
                    }
                    else {
                        setMaxCredits(5);
                    }
                    setUserData(queryData[0].data);
                }


                if ( queryData[0].status === 200 ) {
                    setIpError(false);
                    setIpUser(false);
                    const propertySearchData = queryData[1].status === 200 ? queryData[1].data : {
                        searches: [],
                        credits: 5
                    };
                    setQueriedUser(true);
                    setCredits(propertySearchData.credits);
                    if ( propertySearchData.searches !== undefined ) {
                        const userSettings = queryData[2].status === 200 ? queryData[2].data : defaultUserData.settings;
                        await getOldPropertySearches(propertySearchData.searches, userSettings, propertySearchData.credits);
                    }
                }
                setQueriedUser(true);
            }
            else {
                setQueriedUser(true);
                setMaxCredits(3);
                const getIpAddress = await getIp();
                if ( getIpAddress.error === true ) {
                    setIpError(true);
                    setGooglePlacesDisabled(true);
                    setSearchLocation("");
                }
                else {
                    setIpError(false);
                    const ipAddress = getIpAddress.ip;
                    const userCity = getIpAddress.city;
                    const userRegion = getIpAddress.region;
                    const userCountry = getIpAddress.country;
                    const userLocation = {
                        city: userCity,
                        region: userRegion,
                        country: userCountry
                    };

                    const col = "IP Addresses"
                    const field = "data.ipAddress";
                    const operator = "==";
                    const val = ipAddress;
                    const getUserDocument = await getWhereDoc(col, field, operator, val);
                    if ( getUserDocument.status === 200 ) {
                        const ipUserData = getUserDocument.data[0];
                        if ( ipUserData.searches === undefined ) {
                            ipUserData.searches = ipUserData.propertySearches;
                            delete ipUserData.propertySearches;
                        }

                        await getOldPropertySearches(ipUserData.searches, defaultUserData.settings, ipUserData.credits);
                        setCredits(ipUserData.credits);
                        setIpUser(true);
                        setNoUserId(ipUserData.id);
                    }
                    else {
                        defaultIPCredits();
                        writeIPAddressData(col, ipAddress, userLocation);
                        setIpUser(true);
                    }
                }
            }
        };

        const writeIPAddressData = async(col, ipAddress, userLocation) => {
            const randomId = await makeId(15);
            const defaultData = {
                ipAddress: ipAddress,
                userLocation: userLocation,
                credits: 3,
                searches: [],
                id: randomId
            };
            const queryRandomId = await getDocument(col, randomId);
            if ( queryRandomId.status !== 200 ) {
                await setData(col, randomId, defaultData);
                defaultIPCredits();
                setIpUser(true);
                setNoUserId(randomId);
            }
            else {
                writeIPAddressData(col, ipAddress, userLocation);   
            }
        }

        fetchUserData();
    }, []);

    const getOldPropertySearches = async(searches, settings, currentCredits) => {
        setLoading(true);
        const zpidArray = [];
        let removedProperties = 0;
        for (let index = 0; index < searches.length; index++) {
            const element = searches[index];
            if ( element.zpid !== undefined ) {
                zpidArray.push(element.zpid);
            }
            else {
                removedProperties++;
            }
        }
        const zpids = zpidArray.toString();
        if ( zpidArray.length !== 0 ) {
            const getGatewayQueryString = await getAPIGatewaySavedProperties(zpids, settings);
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "saved",
				queryString: getGatewayQueryString
			};
            const queryData = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( queryData.status === 200 ) {
                const pastSearches = queryData.body;
                const newPastSearches = [];
                
                for (let index = 0; index < pastSearches.length; index++) {
                    const element = pastSearches[index];
                    if ( element.address === undefined || element.address === null || element.price === undefined || element.price === null ) {
                        continue;
                    }

                    let propertyIdAvailable = false;
                    if ( (element.zpid !== undefined && element.zpid !== null) ) {
                        propertyIdAvailable = true;
                    }

                    if ( (element.propertyId !== undefined && element.propertyId !== null) ) {
                        propertyIdAvailable = true;
                    }

                    if ( propertyIdAvailable === false ) {
                        continue;
                    }

                    if ( element.propertyId !== undefined ) {
                        pastSearches[index].zpid = element.propertyId;
                    }
                    
                    if ( element.images !== null && element.images.length > 0 ) {
                        pastSearches[index].imgSrc = element.images[0];
                    }

                    if ( element.zpid !== undefined ) {
                        const zpidIndex = searches.findIndex(e => e.zpid === element.propertyId);
                        if ( zpidIndex !== -1 ) {
                            element.encodedAddress = searches[zpidIndex].encodedAddress;
                            newPastSearches.push(element);
                        }
                    }
                }
                newPastSearches.reverse();
                setSearchHistory(newPastSearches);

                if ( removedProperties > 0 ) {
                    setCredits(currentCredits + removedProperties);
                }
            }
        }
        setLoading(false);
    };

    const changeLocation = (text) => {
        setSearchLocation(text);
    };

    const selectedLocation = async(place) => {
        if ( place !== null ) {
            const addressElements = place.value.terms;
            const firstElement = addressElements.length !== 0 ? addressElements[0].value : "";
            const address = place.label;
            const firstWord = firstElement.replace(/ .*/,'');
            const checkNumber = await containsNumbers(firstWord);
            if ( checkNumber === true ) {
                setError("");
                const encodedAddress = encodeURIComponent(address);
                const save = await saveNewSearch(place, encodedAddress);
                if ( save.status === 200 ) {
                    recordEvent("Property Searched", {
                        "Address": address
                    })

                    navigate(`/quick-report/${encodedAddress}`, {
                        state: {
                            redirect: true,
                            preSaved: save.preSaved,
                            ipUser: ipUser,
                            noUserId: noUserId,
                            creditRemoved: save.creditRemoved
                        }
                    });
                }
                else {
                    setError("There was an error saving your search");
                }
            }
            else {
                setError("It doesn't look like you entered an address.. 🤔");
            }
            setSearchLocation("");
        }
    };

    const saveNewSearch = async(place, encodedAddress) => {
        if ( savedUserId !== "" ) {
            const colRef = "Property Searches";
            const writeDetails = await writeSearch(colRef, savedUserId, place, encodedAddress, credits);
            return writeDetails;
        }
        else if ( noUserId !== "" )  {
            const colRef = "IP Addresses";
            const writeDetails = await writeSearch(colRef, noUserId, place, encodedAddress, credits);
            return writeDetails;
        }
    };

    const defaultIPCredits = () => {
        setCredits(3);
    };

    const createAccount = () => {
        navigate("/sign-up");
        recordEvent("Property Search - Signup Button Clicked", {});
    };

    const buyCredits = () => {
        if ( userData === null ) {
            createAccount();
        }
        else {
            navigate("/pricing", {
                state: state
            });
        }
    };

    const checkPage = (index) => {
        const lowerBound = ((page - 1) * 9);
        const upperBound = lowerBound + 9;
        const pageRanges = [lowerBound, upperBound];
        if ( index >= pageRanges[0] && index < pageRanges[1] ) {
            return true;
        }
        else {
            return false;
        }
    };

    const styles = {
        progress: {
            width: `${percentageFilled}%`
        }
    };
    
    return (
        <div className="property-search-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            <div className="property-search-inner-container">
                <div className="property-search-gradient-container">
                    <div className="property-search-landing-component-container">
                        <img
                            src={LandingImage}
                            className="property-search-landing-image"
                            alt="Where to invest"
                        />
                        <h1 className="display-xl-semibold colour-primary text-align-center margin-medium">
                            Run the numbers on any property
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            Get a Coffee Clozers report on any residential property
                        </span>
                        <div className="property-search-input-container margin-x-x-x-large">
                            <PlaceInput
                                placeholder="Search any residential address"
                                localLocation={searchLocation}
                                changeLocation={changeLocation}
                                selectedLocation={selectedLocation}
                                types={['address']}
                                disabled={credits === 0 || queriedUser === false ? true : googlePlacesDisabled}
                            />
                            {
                                ipError === true ?
                                <span className="body-regular colour-error block-text margin-top-small">
                                    We couldn't verify your IP address to figure out 
                                    how many credits you have left..<span onClick={() => createAccount()} className="underline cursor-pointer">create a free account here</span>
                                    {' '}to get some free credits
                                </span>
                                :
                                error === "" ?
                                null
                                :
                                <span className="body-regular colour-error block-text margin-top-small">
                                    {error}
                                </span>
                            }
                        </div>
                    </div>
                </div>
                {
                    queriedUser === false ?
                    <div className="property-search-landing-page-loading-container">
                        <Loading />
                    </div>
                    :
                    credits > 100 ?
                    <div className="property-search-credits-container margin-x-large">
                        <span className="body-semibold colour-beige block-text text-align-center margin-x-x-small">
                            ∞
                        </span>
                        <span className="body-regular colour-primary block-text">
                            Unlimited credits 🎉
                        </span>
                    </div>
                    :
                    <div className="property-search-credits-container margin-x-large">
                        <span className={"body-regular block-text text-align-center margin-medium " + (credits > 0 ? "colour-primary" : "colour-error")}>
                            <span className="body-semibold">{credits}</span> of {maxCredits === null ? ".." : maxCredits} Free reports remaining
                        </span>
                        <div className="property-search-progress-bar-outer-container margin-medium">
                            <div 
                                className="property-search-progress-bar-inner-container"
                                style={styles.progress}
                            >
                            </div>
                        </div>
                        <span className="body-regular colour-secondary block-text text-align-center margin-x-small">
                            Need more credits?
                        </span>
                        {
                            queriedUser === false ?
                            null
                            :
                            userData === null ?
                            <div className="property-search-credits-button-container-long">
                                <ActionButton
                                    onClick={() => createAccount()}
                                >
                                    Signup for 5 FREE credits
                                </ActionButton>
                            </div>
                            :
                            <div className="property-search-credits-button-container">
                                <ActionButton
                                    onClick={() => buyCredits()}
                                >
                                    Get unlimited credits
                                </ActionButton>
                            </div>
                        }
                    </div>
                }
                <div className="property-search-body-container">
                    <h2 className="heading-small-semibold colour-primary margin-medium">
                        Search History
                    </h2>
                    <div className="property-search-history-grid-container">
                        {
                            loading === true ?
                            <LoadingStatic />
                            :
                            searchHistory.map((item, index) =>
                                checkPage(index) === true ?
                                <NewPropertyCard
                                    item={item}
                                    index={index}
                                    key={index}
                                    userDetails={userData}
                                    formatter={formatterLong}
                                    abstract={true}
                                    viewedProperties={[]}
                                    setViewedProperties={null}
                                    cityId={""}
                                    setHoverCard={null}
                                    propertyRefs={null}
                                    onboarding={null}
                                    onboardingStep={null}
                                    setOnboardingStep={null}
                                    setCardClick={null}
                                    temporaryId={null}
                                    setSignUpModal={null}
                                    overlay={null}
                                    setOverlay={null}
                                    propertySearch={true}
                                    ipUser={ipUser}
                                    noUserId={noUserId}
                                    partnerView={false}
                                    partnerEditProperty={null}
                                	partnerSoldProperty={null}
                                    partnerData={null}
                                    changeStatus={null}
                                    setShowExitScreen={null}
                                    selectOption={null}
                                    openStatus={null}
                                    chooseStatus={null}
                                    state={null}
                                    favourites={[]}
                                    setFavorites={null}
                                    arrowClicked={null}
                                    imageLimit={10}
                                />
                                :
                                null
                            )
                        }
                    </div>
                    {
                        searchHistory.length > 9 ?
                        <PropertySearchPagination
                            searchHistory={searchHistory}
                            page={page}
                            setPage={setPage}
                        />
                        :
                        null
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PropertySearchLandingPage;