import React from 'react';
import '../styles/OffMarketBadgesTooltip.css';
import { Beak } from '../assets';

function OffMarketBadgesTooltip(props) {
    const motivatedSeller = props.motivatedSeller;
    return (
        <div className="off-market-badges-tooltip-container">
            <img
                src={Beak}
                className="off-market-badges-tooltip-beak"
                alt="Beak"
            />
            <h5 className="body-semibold margin-x-small colour-primary">
                Distress signals
            </h5>
            <ul className="off-market-badges-tooltip-list-container">
                {
                    motivatedSeller.map((item, index) =>
                        item.flag === true ?
                        <li
                            className="off-market-badges-tooltip-list-item"
                            key={index}
                        >
                            <div className="off-market-tooltip-badge-emoji-container">
                                <span className="off-market-badge-emoji">
                                    {item.icon}
                                </span>
                            </div>
                            <span className="body-regular colour-primary">
                                {item.label}
                            </span>
                        </li>
                        :
                        null
                    )
                }
            </ul>
        </div>
    )
}

export default OffMarketBadgesTooltip;