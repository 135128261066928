import React, { useEffect, useState } from 'react';
import '../styles/ChooseProduct.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, getDocument } from '../functions';
import { Sparkle, CoffeeIcon } from '../assets';
import { useNavigate } from 'react-router-dom';

function ChooseProduct() {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchUserData = async() => {
            document.title = "Choose Product | Coffee Clozers";
            const colRef = "Users";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryData = await getDocument(colRef, userId);
                if ( queryData.status === 200 ) {
                    setUserData(queryData.data.data);
                }
            }
        };
        fetchUserData();

    }, []);

    const options = [
        {
            image: CoffeeIcon,
            title: "Regular Coffee Clozers",
            description: "For buying with a mortgage.",
            link: "/my-cities",
            redirect: false
        },
        {
            image: Sparkle,
            title: "Creative Finance Tool",
            description: "For doing creative finance.",
            link: "https://creative.coffeeclozers.com/login",
            redirect: true
        }
    ];

    const selectProduct = (item) => {
        if ( item.redirect === false ) {
            navigate(item.link);
        }
        else {
            window.open(item.link, "_self");
        }
    };

    return (
        <div className="choose-product-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={false}
            />
            <div className="choose-product-inner-container">
                <div className="choose-product-title-container margin-x-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
                        Where you headed?
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        We’ve been making some changes at Coffee Clozers. 
                        Are you looking for the original app or creative finance tool?
                    </span>
                </div>
                <div className="choose-product-body-container">
                    {
                        options.map((item, index) =>
                            <div 
                                className="choose-product-element-container cursor-pointer"
                                key={index}
                                onClick={() => selectProduct(item)}
                            >
                                <div className="choose-product-element-inner-container">
                                    <img
                                        src={item.image}
                                        className="choose-product-element-image margin-medium"
                                        alt={item.title}
                                    />
                                    <h2 className="heading-small-semibold colour-primary margin-x-x-small text-align-center">
                                        {item.title}
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        {item.description}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default ChooseProduct;