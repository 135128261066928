import React, { useEffect, useState } from 'react';
import '../styles/CompareProperties.css';
import { Header, Footer, ComparePropertyCard, ComparePropertyDetails, ComparePropertyFinancials, ComparePropertyNeighbourhood, CompareLocations, SideNavigation, Loading } from '../components';
import { checkOnAuthStateChanged, getSubDocument, calculateCashOnCash, recordEvent, getAPIGatewayDetail, getUserDocuments, cloudFunctionV2 } from '../functions';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Bathrooms, Bedrooms, ChevronRight, MultiFamily, SingleFamily, SQFootage, Construction, LotSize, PriceSqFoot, Parking, Cooling, Heating } from '../assets';
import { defaultUserData, formatterLong, numberFormatter } from '../styles/GlobalStyles';

function CompareProperties() {

    const [properties, setProperties] = useState([]);
    const [searchParams] = useSearchParams({});
    const getPropertyParams = searchParams.get('properties');
    const parsedParams = JSON.parse(getPropertyParams);
    const [propertyDetails, setPropertyDetails] = useState([]);
    const [propertyFinancials, setPropertyFinancials] = useState([]);
    const [propertyNeighbourhoods, setPropertyNeighbourhoods] = useState([]);
    const [tracts, setTracts] = useState([]);
    const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
    const [sameCity, setSameCity] = useState(false);
    const [positiveArray, setPositiveArray] = useState([]);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [neighbourhoodData, setNeighbourhoodData] = useState([]);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Property Comparison";

        async function fetchUserData() {
            const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const colRef = [
                    "Users",
                    "Financial Preferences"
                ];
                const docRef = getUserId.userId;
                const query = await getUserDocuments(colRef, docRef);
                if ( query[0].status === 200 ) {
                    const userDetails = query[0].data;
                    setUserData(userDetails);
                    const settings = query[1].status === 200 ? query[1].data : defaultUserData.settings;
                    fetchProperties(docRef, settings);
                }
            }
            else {
                navigate('/sign-up', {
                    state: state
                });
            }
        };

        async function fetchProperties(userId, userSettings) {
            const propertyArray = [];
            for (let index = 0; index < parsedParams.length; index++) {
                const element = parsedParams[index];
                const cityId = element.cityId;
                const zpid = element.zpid;
                const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                const getGatewayQueryString = await getAPIGatewayDetail(cityId, zpid, userSettings);
                const getGatewayParams = {
                    type: "gateway",
                    resourceId: "detail",
                    queryString: getGatewayQueryString
                };
                const query = await cloudFunctionV2(gatewayURL, getGatewayParams);
                if ( query.status === 200 ) {
                    const propertyObject = query.body.prop;
                    propertyObject.offMarket = propertyObject.stackedList !== undefined ? true : false;
                    if ( propertyObject.offMarket === true ) {
                        const motivatedSellerArray = [
                            propertyObject.motivatedSeller.absentee_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.auction_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.death_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.free_clear_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.high_equity_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.inherited_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.lien_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
                            propertyObject.motivatedSeller.owner_occupied_flag === 0 ? true : false,						
                            propertyObject.motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.spousal_death_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.tax_lien_flag === 0 ? false : true,
                            propertyObject.motivatedSeller.vacant_flag === 0 ? false : true
                        ];
                        
                        const emptyArray = [];
                        const totalTrueArray = [];
                        let motivatedSellerFourthIndex = 99;
                        for (let i = 0; i < motivatedSellerArray.length; i++) {
                            const subElement = motivatedSellerArray[i];
                            if ( subElement === true && emptyArray.length < 4 ) {
                                emptyArray.push(subElement);
                            }
                    
                            if ( subElement === true ) {
                                totalTrueArray.push(true);
                            }
                    
                            if ( emptyArray.length === 4 && motivatedSellerFourthIndex === 99 ) {
                                motivatedSellerFourthIndex = i;
                            }
                        }
                        propertyObject.motivatedSellerFourthIndex = motivatedSellerFourthIndex;
                    }
                    const checkSavedDetails = await checkSavedProperty(userId, propertyObject);
                    propertyArray.push(checkSavedDetails);
                }
            }
            setProperties(propertyArray);
            setLoading(false);

            if ( propertyArray.length === 2 ) {
                if ( propertyArray[0].address.city === propertyArray[1].address.city ) {
                    setSameCity(true);
                }
            }

            const newDetailsArray = [];
            const newFinancialsArray = [];
            const newNeighbourhoodArray = [];
            const newPositiveArray = [];
            const cashOnCashArray = [];

            const propertyType = {
                label: "Property type",
                properties: []
            }

            const beds = {
                label: "Beds",
                properties: []
            }

            const baths = {
                label: "Baths",
                properties: []
            }

            const sqFeet = {
                label: "Square footage",
                properties: []
            }

            const yearBuilt = {
                label: "Year built",
                properties: []
            }

            const lotSize = {
                label: "Lot size",
                properties: []
            }

            const pricePerSqFoot = {
                label: "Price per square foot",
                properties: []
            }

            const parking = {
                label: "Parking",
                properties: []
            }

            const cooling = {
                label: "Cooling",
                properties: []
            }

            const heating = {
                label: "Heating",
                properties: []
            }

            const description = {
                label: "Description",
                properties: []
            }

            const financialsSummary = {
                label: "",
                properties: [
                    {
                        label: "Summary",
                        value: []
                    },
                    {
                        label: "Price",
                        value: []
                    }
                ]
            }

            const financialsIncome = {
                label: "Income",
                properties: [
                    {
                        label: "Rent",
                        value: []
                    },
                    {
                        label: "Other income",
                        value: []
                    }
                ]
            }

            const financialsInitialCosts = {
                label: "Initial costs",
                properties: [
                    {
                        label: "Down payment",
                        value: []
                    },
                    {
                        label: "Interest rate",
                        value: []
                    },
                    {
                        label: "Closing costs",
                        value: []
                    },
                    {
                        label: "Rehab",
                        value: []
                    }
                ]
            }

            const monthlyCosts = {
                label: "Monthly costs",
                properties: [
                    {
                        label: "Mortgage payment",
                        value: []
                    },
                    {
                        label: "HOA",
                        value: []
                    },
                    {
                        label: "Insurance",
                        value: []
                    },
                    {
                        label: "Property taxes",
                        value: []
                    },
                    {
                        label: "Vacancy",
                        value: []
                    },
                    {
                        label: "Management fee",
                        value: []
                    },
                    {
                        label: "Maintenance",
                        value: []
                    },
                    {
                        label: "Other costs",
                        value: []
                    }
                ]
            };

            const neighbourhoodScores = {
                label: "",
                properties: [
                    {
                        label: "Neighborhood grade",
                        value: []
                    }
                ]
            };

            const schools = {
                label: "Schools",
                properties: [
                    {
                        label: "School district",
                        value: []
                    },
                    {
                        label: "Schools",
                        value: []
                    }
                ]
            };

            for (let index = 0; index < propertyArray.length; index++) {
                const element = propertyArray[index];
                const propertyTypeObject = {
                    value: element.propertyTypeDimension === "Single Family" ? "Single family" : "Multi family",
                    icon: element.propertyTypeDimension === "Single Family" ? SingleFamily : MultiFamily 
                }
                propertyType.properties.push(propertyTypeObject);

                const bedObject = {
                    value: element.bedrooms,
                    icon: Bedrooms
                }
                beds.properties.push(bedObject);

                const bathObject = {
                    value: element.bathrooms,
                    icon: Bathrooms
                }
                baths.properties.push(bathObject);

                const sqFootageObject = {
                    value: `${numberFormatter.format(element.livingArea)} sqft`,
                    icon: SQFootage
                }
                sqFeet.properties.push(sqFootageObject);

                const yearBuiltObject = {
                    value: element.yearBuilt,
                    icon: Construction
                }
                yearBuilt.properties.push(yearBuiltObject);

                const lotSizeObject = {
                    value: element.resoFacts.lotSize,
                    icon: LotSize
                }
                lotSize.properties.push(lotSizeObject);

                const pricePerSqFootObject = {
                    value: `${element.resoFacts.pricePerSqFoot !== null ? formatterLong.format(element.resoFacts.pricePerSqFoot).replace(".00", "") : "$0"}/sqft`,
                    icon: PriceSqFoot
                };
                pricePerSqFoot.properties.push(pricePerSqFootObject);

                const parkingObject = {
                    value: `${element.resoFacts.garageSpaces === null ? 0 : element.resoFacts.garageSpaces} garage spaces`,
                    icon: Parking
                };
                parking.properties.push(parkingObject);

                const coolingObject = {
                    value: `${element.resoFacts.cooling === null ? "None" : Object.keys(element.resoFacts.cooling).length === 0 ? "None" : element.resoFacts.cooling === null ? "None" : element.resoFacts.cooling.join(', ')}`,
                    icon: Cooling
                };
                cooling.properties.push(coolingObject);

                const heatingObject = {
                    value: `${element.resoFacts.heating === null ? "None" : Object.keys(element.resoFacts.heating).length === 0 ? "None" : element.resoFacts.heating !== null ? element.resoFacts.heating.join(', ') : "None"}`,
                    icon: Heating
                };
                heating.properties.push(heatingObject);

                const descriptionObject = {
                    value: element.description,
                    icon: ""
                };
                description.properties.push(descriptionObject);

                const financialsSummaryObject = {
                    label: ["INITIAL COSTS", "MONTHLY PROFIT", "CASH ON CASH"],
                    value: [
                        typeof(element.purchaseCosts.totalPurchaseCosts) === "number" ? formatterLong.format(element.purchaseCosts.totalPurchaseCosts).replace(".00", "") : element.purchaseCosts.totalPurchaseCosts,
                        formatterLong.format(element.financials.monthlyProfit),
                        typeof(element.financials.cashOnCash) === "number" ? `${element.financials.cashOnCash.toFixed(2)}%` : element.financials.cashOnCash
                    ]
                }
                financialsSummary.properties[0].value.push(financialsSummaryObject);

                cashOnCashArray.push(
                    {
                        lowerCoCR: element.financials.lowerCoCR,
                        upperCoCR: element.financials.upperCoCR
                    }
                );

                const financialsAskingPriceObject = {
                    label: "",
                    value: formatterLong.format(element.price).replace(".00", "")
                };
                financialsSummary.properties[1].value.push(financialsAskingPriceObject);

                const financialsRentObject = {
                    label: "",
                    value: formatterLong.format(element.financials.rent).replace(".00", "")
                }
                financialsIncome.properties[0].value.push(financialsRentObject);

                const financialsOtherIncomeObject = {
                    label: "",
                    value: formatterLong.format(element.financials.otherIncome).replace(".00", "")
                }
                financialsIncome.properties[1].value.push(financialsOtherIncomeObject);

                const financialsDownPaymentObject = {
                    label: "",
                    value: formatterLong.format(element.purchaseCosts.downPayment).replace(".00", "")
                }
                financialsInitialCosts.properties[0].value.push(financialsDownPaymentObject);

                const financialsInterestRateObject = {
                    label: "",
                    value: `${element.financials.interestRate}%`
                }
                financialsInitialCosts.properties[1].value.push(financialsInterestRateObject);
                
                const financialsClosingCostsObject = {
                    label: "",
                    value: formatterLong.format(element.purchaseCosts.closingCosts).replace(".00", "")
                }
                financialsInitialCosts.properties[2].value.push(financialsClosingCostsObject);

                const financialsRenovationsObject = {
                    label: "",
                    value: formatterLong.format(element.financials.totalRehabCost).replace(".00", "")
                }
                financialsInitialCosts.properties[3].value.push(financialsRenovationsObject);

                const mortgagePaymentObject = {
                    label: "",
                    value: formatterLong.format(element.financials.mortgagePayment).replace(".00", "")
                }
                monthlyCosts.properties[0].value.push(mortgagePaymentObject);

                const hoaObject = {
                    label: "",
                    value: formatterLong.format(element.financials.hoaFees).replace(".00", "")
                }
                monthlyCosts.properties[1].value.push(hoaObject);

                const insuranceObject = {
                    label: "",
                    value: formatterLong.format(element.financials.insurance).replace(".00", "")
                }
                monthlyCosts.properties[2].value.push(insuranceObject);

                const propertyTaxesObject = {
                    label: "",
                    value: formatterLong.format(element.financials.propertyTaxes).replace(".00", "")
                }
                monthlyCosts.properties[3].value.push(propertyTaxesObject);

                const vacancyObject = {
                    label: "",
                    value: formatterLong.format(element.financials.vacancyRateAllocation).replace(".00", "")
                }
                monthlyCosts.properties[4].value.push(vacancyObject);

                const managementFeeObject = {
                    label: "",
                    value: formatterLong.format(element.financials.managementFee).replace(".00", "")
                }
                monthlyCosts.properties[5].value.push(managementFeeObject);

                const maintenanceObject = {
                    label: "",
                    value: formatterLong.format(element.financials.maintenanceFee).replace(".00", "")
                }
                monthlyCosts.properties[6].value.push(maintenanceObject);

                const otherObject = {
                    label: "",
                    value: formatterLong.format(element.financials.otherCosts).replace(".00", "")
                }
                monthlyCosts.properties[7].value.push(otherObject);

                const neighbourhoodRatingObject = {
                    label: "",
                    value: ""
                }
                neighbourhoodScores.properties[0].value.push(neighbourhoodRatingObject);

                const schoolDistrictObject = {
                    label: "",
                    value: `${element.address.city} School District`
                };
                schools.properties[0].value.push(schoolDistrictObject);

                const schoolsObject = {
                    label: "",
                    value: element.schools === undefined || Object.keys(element.schools).length === 0 ? [] : element.schools
                }
                schools.properties[1].value.push(schoolsObject);

                const positiveCheck = typeof(element.financials.cashOnCash) === "number" && element.financials.cashOnCash > 0 ? true : typeof(element.financials.cashOnCash) === "string" && element.financials.lowerCoCR > 0 ? true : false;
                newPositiveArray.push(positiveCheck);
            }

            newDetailsArray.push(propertyType);
            newDetailsArray.push(beds);
            newDetailsArray.push(baths);
            newDetailsArray.push(sqFeet);
            newDetailsArray.push(yearBuilt);
            newDetailsArray.push(lotSize);
            newDetailsArray.push(pricePerSqFoot);
            newDetailsArray.push(parking);
            newDetailsArray.push(cooling);
            newDetailsArray.push(heating);
            newDetailsArray.push(description);
            setPropertyDetails(newDetailsArray);

            newFinancialsArray.push(financialsSummary);
            newFinancialsArray.push(financialsIncome);
            newFinancialsArray.push(financialsInitialCosts);
            newFinancialsArray.push(monthlyCosts);
            setPropertyFinancials(newFinancialsArray);
            setPositiveArray(newPositiveArray);

            newNeighbourhoodArray.push(neighbourhoodScores);
            newNeighbourhoodArray.push(schools);
            setPropertyNeighbourhoods(newNeighbourhoodArray);
            getNeighbourhoodData(propertyArray);
        }

        async function checkSavedProperty(userId, property) {
            const colRef = "Users";
			const docRef = userId;
			const subColRef = "Properties";
			const subDocRef = `${property.zpid}`;
			const queryProperty = await getSubDocument(colRef, docRef, subColRef, subDocRef);
            if ( queryProperty.status === 200 ) {
                const savedProperty = queryProperty.data.data;
                const closingCosts = ((savedProperty.closingCosts / 100) * savedProperty.price);
                const downPaymentCost = ((savedProperty.downPayment / 100) * savedProperty.price);
                const initialCosts = downPaymentCost + closingCosts;
                const newCosts = [];
                const newProfits = [];
                for (let index = 0; index < savedProperty.financials.length; index++) {
                    const element = savedProperty.financials[index];
                    if ( element.loss === true ) {
                        newCosts.push(element);
                    }
                    else {
                        newProfits.push(element);
                    }
                }

                const profitObject = await updateCashFlow(newCosts, newProfits, savedProperty.totalRehabCost !== undefined ? savedProperty.totalRehabCost : 0, initialCosts);
                const amendedObject = {
                    rent: Number(savedProperty.financials[0].value),
                    otherIncome: Number(savedProperty.financials[1].value),
                    mortgagePayment: Number(savedProperty.financials[2].value),
                    hoaFees: Number(savedProperty.financials[3].value),
                    insurance: Number(savedProperty.financials[4].value),
                    propertyTaxes: Number(savedProperty.financials[5].value),
                    vacancyRateAllocation: Number(savedProperty.financials[6].value),
                    managementFee: Number(savedProperty.financials[7].value),
                    maintenanceFee: Number(savedProperty.financials[8].value),
                    otherCosts: Number(savedProperty.financials[9].value),
                    price: savedProperty.price,
                    closingCost: closingCosts,
                    initialCosts: initialCosts + (savedProperty.totalRehabCost !== undefined ? savedProperty.totalRehabCost : 0),
                    monthlyProfit: profitObject.monthlyProfit,
                    cashOnCash: profitObject.cashOnCash,
                    interestRate: savedProperty.interestRate,
                    totalRehabCost: savedProperty.totalRehabCost !== undefined ? Number(savedProperty.totalRehabCost) : 0
                }
                property.price = savedProperty.price;
                property.financials = amendedObject;
                property.purchaseCosts.downPayment = downPaymentCost;
                property.purchaseCosts.closingCosts = closingCosts;
                property.purchaseCosts.totalPurchaseCosts = amendedObject.initialCosts;
                property.resoFacts.pricePerSqFoot = property.livingArea !== null ? (savedProperty.price / property.livingArea).toFixed(0) : 0;
                return property;
            }
            else {
                property.financials.interestRate = property.mortgage30us;
                property.financials.totalRehabCost = 0;
                return property;
            }
        };

        async function updateCashFlow(newCosts, newProfits, totalRehabCost, customInitialCosts){
            let monthlyProfit = 0;
            for (let index = 0; index < newCosts.length; index++) {
                monthlyProfit = monthlyProfit - Number(newCosts[index].value);
            }
    
            for (let index = 0; index < newProfits.length; index++) {
                monthlyProfit = monthlyProfit + Number(newProfits[index].value);
            }
            
            const newInitialCosts = customInitialCosts + totalRehabCost;
            const newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);
    
            const profitObject = {
                monthlyProfit: monthlyProfit,
                cashOnCash: newCashOnCash,
                totalCosts: newInitialCosts
            };
            return profitObject;
        }

        async function getNeighbourhoodData(propertyArray) {
            const allNeighbourhoodData = [];
            for (let index = 0; index < propertyArray.length; index++) {
                const element = propertyArray[index];
                const cityId = element.cityId;
                const neighbourhoodPath = cityId.includes("CTY") ? `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json` : `output/map_neighborhood/${cityId}.json`
                const params = {
                    s3Data: {
                        key: neighbourhoodPath,
                        bucketName: "residentialpropertydata"
                    },
                    type: "s3"
                }
                const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
                const queryData = await cloudFunctionV2(awsURL, params);
                if ( queryData.status === 200 ) {
                    const body = queryData.body;
                    for (let ii = 0; ii < body.length; ii++) {
                        allNeighbourhoodData.push(body[ii]);
                    }

                    const newBounds = [];
                    const getTracts = [];
                    for (let ii = 0; ii < body.length; ii++) {
                        const element = body[ii];
                        getTracts.push(element.region.tractId);
                        const tractPolygon = element.tractPolygon.flat(1);
                        newBounds.push(tractPolygon);
                    };
                    setTracts(getTracts);
                    setNeighbourhoodBounds(newBounds);
                }
            }
            setNeighbourhoodData(allNeighbourhoodData);
        }

        if ( properties.length !== 0 ) {
            return;
        }
        else if ( getPropertyParams !== null && parsedParams.length > 1 ) {
            fetchUserData();
        }
        else {
            navigate('/favourites', {
                state: state
            });
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		  };
	  
		window.addEventListener('resize', handleWindowResize);

    }, [parsedParams, getPropertyParams, navigate, properties.length, state]);

    const viewProperty = (item) => {
        window.open(`/properties/${item.cityId}/${item.zpid}`, '_blank');
        
        const path = window.location.pathname;
        
        recordEvent("Navigation", {
            oldRoute: path,
            newRoute: `/properties/${item.cityId}/${item.zpid}`
        });
        recordEvent("Property Click", {
            zpid: item.zpid,
            address: item.cityId
        });
    };

    return (
        <div className="compare-properties-outer-container">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
            />
            <div className="compare-property-outer-row">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="compare-properties-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    <div className="compare-properties-top-container">
                        <div className="compare-properties-top-left-container margin-x-small">
                            <div 
                                className="compare-properties-row-container text-button"
                                onClick={() => navigate("/favourites", {state: state})}
                            >
                                <img
                                    src={ChevronRight}
                                    className="compare-properties-left-chevron"
                                    alt="Back"
                                />
                                <h2 className="body-regular text-link">
                                    Back to Favorites
                                </h2>
                            </div>
                            <div className="compare-properties-divider margin-tops">
                            </div>
                        </div>
                        <div className="compare-properties-top-right-container margin-top-x-large">
                            
                            {
                                properties.map((item, index) =>
                                    <ComparePropertyCard
                                        property={item}
                                        key={index}
                                        viewProperty={viewProperty}
                                        innerWidth={innerWidth}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className="compare-properties-body-container">
                        <ComparePropertyDetails
                            properties={properties}
                            propertyDetails={propertyDetails}
                        />
                        <ComparePropertyFinancials
                            properties={properties}
                            propertyFinancials={propertyFinancials}
                            positiveArray={positiveArray}
                        />
                        <ComparePropertyNeighbourhood
                            properties={properties}
                            propertyNeighbourhoods={propertyNeighbourhoods}
                            neighbourhoodData={neighbourhoodData}
                        />
                        {
                            properties.length > 0 && neighbourhoodData.length !== 0 && sameCity === true ?
                            <CompareLocations
                                properties={properties}
                                neighbourhoodBounds={neighbourhoodBounds}
                                tracts={tracts}
                                neighbourhoodData={neighbourhoodData}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CompareProperties;