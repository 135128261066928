import React, { useEffect, useState } from 'react';
import '../styles/SignUpModal.css';
import { ActionButton } from '../styles/GlobalStyles';
import { checkUserId, setData } from "../functions";

function OffMarketModal(props) {
    const setOffMarketModal = props.setOffMarketModal;
    const userDetails = props.userDetails
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setDisabled(false);
        }, 2000);
    }, []);

    const cta = async() => {
        setOffMarketModal(false);

        const colRef = "Users";
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            userDetails.offMarketModal = true;
            await setData(colRef, docRef, userDetails);
        }
    }

    return (
        <div className="sign-up-modal-outer-container">
            <div className="sign-up-modal-inner-container">
                <div className="sign-up-modal-skull-crossbones-container margin-large">
                    <span className="sign-up-modal-skull-icon">
                        ☠️
                    </span>
                </div>
                <div className="sign-up-modal-text-container">
                    <h2 className="heading-large-semibold colour-primary text-align-center margin-large">
                        Beware: Cash flow is not as it seems
                    </h2>
                    <span className="body-regular colour-secondary block-text margin-x-large text-align-center">
                        Due to the nature of off-market properties, the cash on cash estimate can be very inaccurate. 
                        Off-market properties may have hidden expenses and require a lot of renovation. 
                        <br/> <br/>
                        Please factor this in to your calculations and proceed with caution.
                    </span>
                    <div className="sign-up-modal-button-container">
                        <ActionButton
                            disabled={disabled}
                            onClick={() => cta()}
                        >
                            I understand
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OffMarketModal;