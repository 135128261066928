import React from 'react';
import '../styles/MapSearchAddress.css';
import { DarkSearch, Search, Exit } from '../assets';
import { FilterCityInput } from '../styles/GlobalStyles';
import { LoadingStatic } from './';

function MapSearchAddress(props) {
    const searchActive = props.searchActive;
    const closeMapSearch = props.closeMapSearch;
    const searchAddress = props.searchAddress;
    const setSearchAddress = props.setSearchAddress
    const onChangeSearchAddress = props.onChangeSearchAddress;
    const searchAddressResults = props.searchAddressResults;
    const selectAddress = props.selectAddress;
    const activateMapSearch = props.activateMapSearch;
    const mapSearchAddressAutocomplete = props.mapSearchAddressAutocomplete;
    const googlePlacesLoading = props.googlePlacesLoading;

    return (
        <div 
            className="map-search-address-outer-container"
            onClick={() => searchActive === true ? null : activateMapSearch()}
        >
            <div className="map-search-address-inner-container">
                {
                    searchActive === false ?
                    <div className="map-search-address-search-container">
                        <img
                            src={DarkSearch}
                            className="map-search-address-icon"
                            alt="Search"
                        />
                    </div>
                    :
                    null
                }
                <div className={"map-search-address-input-container " + (searchActive === true ? "map-search-address-active-input-container" : "map-search-address-inactive-input-container")}>
                    <FilterCityInput
                        value={searchAddress}
                        type="text"
                        id="search-address-input"
                        placeholder="Find an address"
                        onChange={(text) => onChangeSearchAddress(text.target.value)}
                    />
                    <img
                        src={searchAddress === "" ? Search : Exit}
                        className={searchAddress === "" ? "pick-city-search-icon" : "pick-city-exit-icon"}
                        alt="Search"
                        onClick={() => searchAddress === "" ? closeMapSearch() : setSearchAddress("")}
                    />
                    {
                        (searchAddressResults.length === 0 || searchAddress === "") && mapSearchAddressAutocomplete === null && googlePlacesLoading === false ?
                        null
                        :
                        <div className="map-search-address-results-container">
                            {
                                searchAddressResults.map((item, index) =>
                                    <div 
                                        className="map-search-address-results-element"
                                        key={index}
                                        onClick={() => selectAddress(item, false)}
                                    >
                                        <span className="body-regular colour-primary">
                                            {item.address.streetAddress}, {item.address.city}, {item.address.state}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                googlePlacesLoading === true ?
                                <div className="map-search-address-results-element">
                                    <LoadingStatic />
                                </div>
                                :
                                null
                            }
                            {
                                mapSearchAddressAutocomplete !== null && searchAddressResults.length === 0 ?
                                <div 
                                    className="map-search-address-results-element"
                                    onClick={() => selectAddress(mapSearchAddressAutocomplete, true)}
                                >
                                    <span className="body-regular colour-primary">
                                        {mapSearchAddressAutocomplete.description}
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MapSearchAddress;