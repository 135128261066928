import React from 'react';
import '../styles/PropertyStatus.css';
import { CustomSelector } from '../components';
import { propertyStatuses } from '../styles/GlobalStyles';

function PropertyStatus(props) {
    const text = props.text;
    const bgColour = props.bgColour;
    const chooseStatus = props.chooseStatus;
    const openStatus = props.openStatus;
    const selectOption = props.selectOption;
    const property = props.property;
    
    return (
        <div 
            className={"property-status-container cursor-pointer relative-container " + (bgColour)}
            onClick={() => openStatus(property.zpid)}
        >
            <span className="body-regular colour-primary property-status-text">
                {text}
            </span>
            {
                chooseStatus === true ?
                <CustomSelector
                    array={propertyStatuses}
                    selectOption={selectOption}
                    property={property}
                />
                :
                null
            }
        </div>
    );
};

export default PropertyStatus;