import React, { useState, useEffect } from 'react';
import '../styles/FinancialsPanel.css';
import { IncomeNew, InitialCosts, MobileFinancials, RecurringCosts, ReportFinancialsBug, Chip, RentComparison, RehabCalculator, FinancialPreferencesBanner } from './';
import { calculateCashOnCash, calculateMortgagePayment, saveAmendedFinancials, recalculateProfits } from '../functions';
import { ChevronUp } from '../assets';

function FinancialsPanel(props) {

    const property = props.property;
	const formatter = props.formatter;
	const editedProperty = props.editedProperty;
	const setEditedProperty = props.setEditedProperty;
	const downPayment = props.downPayment;
	const setDownPayment = props.setDownPayment;
	const setHighLevelProfits = props.setHighLevelProfits;
	const interestRate = props.interestRate;
	const setInterestRate = props.setInterestRate;
	const closingPercentage = props.closingPercentage;
	const setClosingPercentage = props.setClosingPercentage;
	const addBedroom = props.addBedroom;
	const setAddBedroom = props.setAddBedroom;
	const userDetails = props.userDetails;
	const bugModal = props.bugModal;
	const setBugModal = props.setBugModal;
    const highLevelProfits = props.highLevelProfits;
	const mobileBar = props.mobileBar;
	const blurred = props.blurred;
	const recurringCosts = props.recurringCosts;
	const setRecurringCosts = props.setRecurringCosts;
	const renovationRadio = props.renovationRadio;
	const setRenovationRadio = props.setRenovationRadio;
	const spreadsheetProperty = props.spreadsheetProperty;
	const setSpreadsheetProperty = props.setSpreadsheetProperty;
	const costPerSqFoot = props.costPerSqFoot;
	const setCostPerSqFoot = props.setCostPerSqFoot;
	const totalRehabCost = props.totalRehabCost;
	const setTotalRehabCost = props.setTotalRehabCost;
	const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
	const partnerData = props.partnerData;
	const partnerListings = partnerData === null ? null : partnerData.listings;
	const partnerListingIndex = partnerData === null ? null : partnerListings.findIndex(e => e.zpid === property.zpid);
	const partnerAmendments = partnerListingIndex === null ? null : partnerListings[partnerListingIndex].amendments;
	const userSettings = props.userSettings;

	const [rentAccordion, setRentAccordion] = useState(true);
    const [selectedPanel, setSelectedPanel] = useState(0);
	const [profitChanges, setProfitsChanges] = useState(false);
	const [monthlyCostsChanges, setMonthlyCostsChanges] = useState(false);
    const sidePanelElements = [
        {
            text: "Income"
        },
		{
            text: "Rehab"
        },
        {
            text: "Initial costs"
        },
        {
            text: "Monthly costs"
        }
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    }

	const [recurringProfits, setRecurringProfits] = useState([]);
	const [recurringUnits, setRecurringUnits] = useState([]);
	const [costsTooltipIndex, setCostsTooltipIndex] = useState(0);
	const [revenueTooltipIndex, setRevenueTooltipIndex] = useState(0);
	const [costsTooltipShow, setCostsTooltipShow] = useState(false);
	const [revenueTooltipShow, setRevenueTooltipShow] = useState(false);
	const [customDiscount, setCustomDiscount] = useState(Math.round(((editedProperty.price - property.price) / property.price) * 100));
	const [customPrice, setCustomPrice] = useState(editedProperty.price);
	// const [netProfit, setNetProfit] = useState("");
	const [firstRender, setFirstRender] = useState(false);
	const [doneSettings, setDoneSettings] = useState(true);
	const maxPercentage = -100;
	const editedFinancials = editedProperty.financials;
	const closingCost = (customPrice * Number(closingPercentage)) / 100;
	// const downPaymentPercentage = downPayment / 100;
	// const downPaymentCost = (customPrice * downPaymentPercentage);
	// const initialCosts = downPaymentCost + closingCost;
	// const costWithRenovation = renovationRadio === 0 ? initialCosts : renovationRadio === 3 ? `${formatterCompact.format(initialCosts + renovationRanges[renovationRadio].lower)}+` : renovationRadio !== null ? `${formatterCompact.format(initialCosts + renovationRanges[renovationRadio].lower)} - ${formatterCompact.format(initialCosts + renovationRanges[renovationRadio].upper)}` : initialCosts;
	const traditionalRentEstimate = property.rental_income === undefined ? null : property.rental_income.traditional;

	const partnerRentEstimate = [
		{
			label: "Partner's Rent Estimate",
			value: partnerData === null ? null : partnerAmendments.rent === null ? null : Math.round(partnerAmendments.rent)
		},
		{
			label: "Zillow Rent Zestimate",
			value: Math.round(property.financials.oldRent)
		}
	];

	const rentalEstimates = partnerData !== null && partnerAmendments.rent !== null ? partnerRentEstimate : traditionalRentEstimate === null ? [] : [
		{
			label: "Rental Comps Median",
			value: Math.round(traditionalRentEstimate.ccRentEstimate)
		},
		{
			label: "Median ZIP Code Rent",
			value: Math.round(traditionalRentEstimate.medianZipRent)
		},
		{
			label: "Zillow Rent Zestimate",
			value: traditionalRentEstimate.rentZestimate === undefined ? null : Math.round(traditionalRentEstimate.rentZestimate)
		},
		{
			label: "Rentcast Estimate",
			value: traditionalRentEstimate.rentCastEstimate === undefined || property.propertyTypeDimension === "Single Family" ? null : Math.round(traditionalRentEstimate.rentCastEstimate)
		}
	];

	useEffect(() => {
		if ( editedProperty !== "" && downPayment !== "" ) {
			const newCosts = [];
			const newProfits = [];
			const monthlyCostAmendments = [];
			const profitsAmendments = [];

			let monthlyProfit = 0;
			for (let index = 0; index < editedFinancials.length; index++) {
				const element = editedFinancials[index];
				if ( element.loss === true ) {
					if ( element.original === undefined ) {
						element.original = firstRender === true ? element.original : element.value;
					}
					if ( element.percentage === undefined ) {
						element.percentage = false;
					}
					if ( index === 3 ) {
						element.original = property.financials.hoaFees.toFixed(2);
					}
					else if ( index === 4 ) {
						element.original = property.financials.insurance.toFixed(2);
					}
					else if ( index === 5 ) {
						element.original = property.financials.propertyTaxes.toFixed(2);
					}
					else if ( index === 6 ) {
						element.original = property.financials.vacancyRateAllocation.toFixed(2);
					}
					else if ( index === 7 ) {
						element.original = property.financials.managementFee.toFixed(2);
					}
					else if ( index === 8 ) {
						element.original = property.financials.maintenanceFee.toFixed(2);
					}
					else if ( index === 9 ) {
						element.original = property.financials.otherCosts.toFixed(2);
					}

					if ( Number(element.original) !== Number(element.value) && index !== 2 ) {
						monthlyCostAmendments.push(true);
					}

					newCosts.push(element);
					monthlyProfit = monthlyProfit - Number(editedFinancials[index].value);
				}
				else {
					if ( element.original === undefined ) {
						element.original = firstRender === true ? element.original : element.value;
					}

					if ( index === 0 ) {
						element.original = `${property.financials.rent}`;
					}

					if ( Number(element.original) !== Number(element.value) ) {
						profitsAmendments.push(true);
					}

					newProfits.push(element);
					monthlyProfit = monthlyProfit + Number(editedFinancials[index].value);
				}
			}

			setRecurringCosts(newCosts);
			setRecurringProfits(newProfits);
			if ( property.uniqueUnitPropertyDetails !== null && property.uniqueUnitPropertyDetails !== undefined && property.uniqueUnitPropertyDetails.length > 1 ) {
				if ( editedFinancials[0].original === editedFinancials[0].value ) {
					setRecurringUnits(property.uniqueUnitPropertyDetails);
				}
				else if ( editedProperty.uniqueUnitPropertyDetails !== undefined ) {
					setRecurringUnits(editedProperty.uniqueUnitPropertyDetails);
				}
				else if ( property.uniqueUnitPropertyDetails !== undefined ) {
					setRecurringUnits(property.uniqueUnitPropertyDetails);
				}
			}

			setFirstRender(true);
			if ( monthlyCostAmendments.length !== 0 ) {
				setMonthlyCostsChanges(true);
			}
			else {
				setMonthlyCostsChanges(false);
			}

			if ( profitsAmendments.length !== 0 ) {
				setProfitsChanges(true);
			}
			else {
				setProfitsChanges(false);
			}

			if ( userSettings !== null ) {
				const userRecurringCosts = userSettings.recurringCosts;
				let nonDefault = false;

				for (let index = 0; index < userRecurringCosts.length; index++) {
					const element = userRecurringCosts[index];
					if ( element.default === false ) {
						nonDefault = true;
					}
				}

				if ( userSettings.rehabCosts.default === false ) {
					nonDefault = true;
				}

				// if ( userSettings.customCities.length !== 0 ) {
				// 	nonDefault = true;
				// }

				if ( nonDefault === false ) {
					setDoneSettings(false);
				}
			}
			else {
				setDoneSettings(false);
			}
		}

	}, [downPayment, editedFinancials, editedProperty, firstRender, setRecurringCosts, property.financials.hoaFees, property.financials.insurance, property.financials.maintenanceFee, property.financials.managementFee, property.financials.propertyTaxes, property.financials.rent, property.financials.vacancyRateAllocation, property.uniqueUnitPropertyDetails, property.financials.otherCosts, userSettings])

	const onChangeDownPayment = async(text) => {
		setDownPayment(text);
		const mortgagePayment = await calculateMortgagePayment(customPrice, Number(text), editedProperty.interestRate, 30);
		const cloneEditedProperty = structuredClone(editedProperty);
		cloneEditedProperty.financials[2].value = mortgagePayment.toFixed(2);
		cloneEditedProperty.downPayment = Number(text);
		
		const newArray = recurringCosts.slice(0);
		newArray[0].value = Number(mortgagePayment.toFixed(2));

		updateCashFlow(newArray, recurringProfits, totalRehabCost, customPrice, closingCost, closingPercentage, cloneEditedProperty.downPayment, interestRate);
		setEditedProperty(cloneEditedProperty);
	};

	const onChangeInterest = async(text) => {
		setInterestRate(text);

		if ( text !== "" && Number(text) !== 0 ) {
			const mortgagePayment = await calculateMortgagePayment(customPrice, downPayment, Number(text), 30);
			const cloneEditedProperty = structuredClone(editedProperty);
			cloneEditedProperty.financials[2].value = mortgagePayment.toFixed(2);
			cloneEditedProperty.interestRate = Number(text);

			const newArray = recurringCosts.slice(0);
			newArray[0].value = Number(mortgagePayment.toFixed(2));

			updateCashFlow(newArray, recurringProfits, totalRehabCost, customPrice, closingCost, closingPercentage, downPayment, Number(text));
			setEditedProperty(cloneEditedProperty);
		}
		else {
			const mortgagePayment = 0;
			const cloneEditedProperty = structuredClone(editedProperty);
			cloneEditedProperty.financials[2].value = mortgagePayment;
			cloneEditedProperty.interestRate = 0;
			const newArray = recurringCosts.slice(0);
			newArray[0].value = 0;

			updateCashFlow(newArray, recurringProfits, totalRehabCost, customPrice, closingCost, closingPercentage, downPayment, Number(text));
			setEditedProperty(cloneEditedProperty);
		}
	};

	// Closing cost is a percentage of the
	// total MORTGAGE, not of the purchase price
	
	const onChangeClosing = async(text) => {
		setClosingPercentage(text);

		const cloneEditedProperty = structuredClone(editedProperty);
		cloneEditedProperty.closingCosts = Number(text).toFixed(2);
		setEditedProperty(cloneEditedProperty);
		const newClosingCost = (customPrice * Number(text)) / 100;
		updateCashFlow(recurringCosts, recurringProfits, totalRehabCost, customPrice, newClosingCost, Number(text), downPayment, interestRate);
	};

	const onChangeRecurringCosts = (text, index, resetting) => {
		const newArray = recurringCosts.slice(0);
		if ( resetting === true ) {
			newArray[index].value = recurringCosts[index].original;
			setRecurringCosts(newArray);
		}
		else if ( text === "" ) {
			newArray[index].value = text;
			setRecurringCosts(newArray);
		}
		else if ( newArray[index].percentage === true && (newArray[index].label === "Property taxes" || newArray[index].label === "Insurance") ) {
			const priceVal = property.price;
			const newCost = (priceVal * Number(text)) / 1200;
			newArray[index].value = newCost.toFixed(2);
			setRecurringCosts(newArray);
		}
		else if ( newArray[index].percentage === true ) {
			const rentVal = recurringProfits[0].value;
			const newCost = (rentVal * Number(text)) / 100;
			newArray[index].value = newCost.toFixed(2);
			setRecurringCosts(newArray);
		}
		else {
			newArray[index].value = text;
			setRecurringCosts(newArray);
		}

		const monthlyCostAmendments = [];
		for (let index = 0; index < newArray.length; index++) {
			const element = newArray[index];
			if ( Number(element.original) !== Number(element.value) && index !== 0 ) {
				monthlyCostAmendments.push(true);
			}
		}

		if ( monthlyCostAmendments.length !== 0 ) {
			setMonthlyCostsChanges(true);
		}
		else {
			setMonthlyCostsChanges(false);
		}
		
		updateCashFlow(newArray, recurringProfits, totalRehabCost, customPrice, closingCost, closingPercentage, downPayment, interestRate);
	};

	const onChangeRevenue = async(text, index, uniqueUnitPropertyDetails) => {
		const newArray = recurringProfits.slice(0);
		newArray[index].value = text;
		setRecurringProfits(newArray);
		updateCashFlow(recurringCosts, newArray, totalRehabCost, customPrice, closingCost, closingPercentage, downPayment, interestRate, uniqueUnitPropertyDetails);

		const profitsAmendments = [];
		for (let index = 0; index < newArray.length; index++) {
			const element = newArray[index];
			if ( Number(element.original) !== Number(element.value) ) {
				profitsAmendments.push(true);
			}
		}

		if ( profitsAmendments.length !== 0 || property.price !== customPrice ) {
			setProfitsChanges(true);
		}
		else {
			setProfitsChanges(false);
		}
	}

	const onChangeMFHRents = async(text, index) => {
		const recurringUnitsClone = recurringUnits.slice(0);
		recurringUnitsClone[index].value = text;

		const newArray = [];
		for (let i = 0; i < recurringUnitsClone.length; i++) {
			const element = recurringUnitsClone[i];
			newArray.push(Number(element.value));
		}

		const sum = newArray.reduce((partialSum, a) => partialSum + a, 0);
		onChangeRevenue(`${sum}`, 0, recurringUnitsClone);
		setRecurringUnits(recurringUnitsClone);
	}

	const updateCashFlow = async(newCosts, newProfits, newRehab, newPrice, newClosingCost, newClosingPercentage, newDownPaymentPercentage, newInterestRate, uniqueUnitPropertyDetails) => {
		const newDownPaymentCost = (newPrice * (Number(newDownPaymentPercentage) / 100));
		const customInitialCosts = newDownPaymentCost + newClosingCost + newRehab;

		let monthlyProfit = 0;
		for (let index = 0; index < newCosts.length; index++) {
			monthlyProfit = monthlyProfit - Number(newCosts[index].value);
		}

		for (let index = 0; index < newProfits.length; index++) {
			monthlyProfit = monthlyProfit + Number(newProfits[index].value);
		}
		
		const finalCosts = customInitialCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, customInitialCosts);

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash,
			totalCosts: finalCosts,
			cocrRange: typeof(newCashOnCash) === "string" ? true : false
		};

		setHighLevelProfits(profitObject);
		const cloneProperty = structuredClone(spreadsheetProperty);
		cloneProperty.price = newPrice;
		cloneProperty.financials.monthlyProfit = monthlyProfit;
		cloneProperty.financials.cashOnCash = newCashOnCash;
		cloneProperty.financials.rent = Number(newProfits[0].value);
		cloneProperty.financials.otherIncome = Number(newProfits[1].value);
		cloneProperty.financials.mortgagePayment = Number(newCosts[0].value);
		cloneProperty.financials.hoaFees = Number(newCosts[1].value);
		cloneProperty.financials.insurance = Number(newCosts[2].value);
		cloneProperty.financials.propertyTaxes = Number(newCosts[3].value);
		cloneProperty.financials.vacancyRateAllocation = Number(newCosts[4].value);
		cloneProperty.financials.managementFee = Number(newCosts[5].value);
		cloneProperty.financials.maintenanceFee = Number(newCosts[6].value);
		cloneProperty.financials.otherCosts = Number(newCosts[7].value);
		cloneProperty.financials.totalRehabCost = Number(newRehab);
		if ( uniqueUnitPropertyDetails !== undefined ) {
			cloneProperty.uniqueUnitPropertyDetails = uniqueUnitPropertyDetails;
		}

		cloneProperty.mortgage30us = newInterestRate;
		cloneProperty.purchaseCosts.closingCostRate = newClosingPercentage / 100;

		setSpreadsheetProperty(cloneProperty);
		
		if ( blurred === false ) {
			const mortgage = downPayment === 100 ? false : true;
			await saveAmendedFinancials(newProfits, newRehab, newCosts, property.zpid, newPrice, mortgage, Number(newDownPaymentPercentage), Number(newClosingPercentage), Number(newInterestRate), uniqueUnitPropertyDetails);
		}
	}

	const toggleTooltip = (open, index, type) => {

		if ( type === "costs" ) {
			setCostsTooltipIndex(index);
			setCostsTooltipShow(open);
		}
		else if ( type === "revenue" ) {
			setRevenueTooltipIndex(index)
			setRevenueTooltipShow(open);
		}
	};

	const changeRenovationRadio = (val) => {
		setRenovationRadio(Number(val));
		updateCashFlow(recurringCosts, recurringProfits, Number(val), customPrice, closingCost, closingPercentage, downPayment, interestRate);
	}

	const toggleAddBedroom = async(val) => {
		setAddBedroom(val);
	};

	const changeSlider = async(event, newValue) => {
		setCustomDiscount(newValue);
		const newPrice = property.price * ((100 + newValue) / 100);
		setCustomPrice(Math.round(newPrice));
		const cloneProperty = structuredClone(spreadsheetProperty);
		cloneProperty.price = newPrice;
		setSpreadsheetProperty(cloneProperty);

		recalculateProfits(editedFinancials, editedProperty, newPrice, costPerSqFoot, setHighLevelProfits, Number(downPayment), property, setRecurringCosts, false, setSpreadsheetProperty);
		const mortgage = downPayment === 100 ? false : true;
		await saveAmendedFinancials(recurringProfits, totalRehabCost, recurringCosts, property.zpid, Math.round(newPrice), mortgage, Number(downPayment), Number(closingPercentage), interestRate);
	};

	const onChangeLowball = async(val) => {
		const newPrice = Number(val);
		setCustomPrice(newPrice);
		const cloneProperty = structuredClone(spreadsheetProperty);
		cloneProperty.price = newPrice;
		setSpreadsheetProperty(cloneProperty);

		const percentageChange = Math.round(((newPrice - property.price) / property.price) * 100);

		if ( percentageChange < maxPercentage ) {
			setCustomDiscount(maxPercentage);
		}
		else {
			setCustomDiscount(percentageChange);
		}

		const editedFinancials = editedProperty.financials;
		recalculateProfits(editedFinancials, editedProperty, newPrice, costPerSqFoot, setHighLevelProfits, Number(downPayment), property, setRecurringCosts, false, setSpreadsheetProperty);
		const mortgage = downPayment === 100 ? false : true;
		await saveAmendedFinancials(recurringProfits, totalRehabCost, recurringCosts, property.zpid, Math.round(newPrice), mortgage, Number(downPayment), Number(closingPercentage), interestRate);
	}

	const resetRecurringCosts = (index, value, percentage) => {
		if ( percentage === false ) {
			onChangeRecurringCosts(value, index, true);
		}
		else {
			const rentVal = recurringProfits[0].value;
			const percentageValue = ((Number(value) / Number(rentVal)) * 100).toFixed(2);
			onChangeRecurringCosts(percentageValue, index, true);
		}
	};

	const resetRecurringProfits = (index, value) => {
		const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
		if ( index === 0 && multipleUniqueUnits === true ) {
			const recurringUnitsClone = recurringUnits.slice(0);
			for (let i = 0; i < recurringUnitsClone.length; i++) {
				recurringUnitsClone[i].value = recurringUnitsClone[i].rentcast_rent;
			}
			setRecurringUnits(recurringUnitsClone);
		}
		onChangeRevenue(value, index);
	};

	const resetLowballOffer = (price) => {
		onChangeLowball(price);
	};

	const toggleRentAccordion = () => {
		if ( rentAccordion !== true ) {
			setRentAccordion(true);
		}
		else {
			setRentAccordion(false);
		}
	};

	const changeRehabCost = (event, val) => {
        setCostPerSqFoot(val);
        const newTotalCost = val * property.livingArea;
        setTotalRehabCost(newTotalCost);

		updateCashFlow(recurringCosts, recurringProfits, newTotalCost, customPrice, closingCost, closingPercentage, downPayment, interestRate);
    }

	const onChangeCostPerSqFoot = (val) => {
        setCostPerSqFoot(val);
        const newTotalCost = Number(val) * property.livingArea;
        setTotalRehabCost(newTotalCost);

		updateCashFlow(recurringCosts, recurringProfits, newTotalCost, customPrice, closingCost, closingPercentage, downPayment, interestRate);
    };

    const onChangeTotalCost = (val) => {
        setTotalRehabCost(val);
        const newCostPerSqFoot = Math.round(val / property.livingArea);
        setCostPerSqFoot(newCostPerSqFoot);

		updateCashFlow(recurringCosts, recurringProfits, Number(val), customPrice, closingCost, closingPercentage, downPayment, interestRate);
    };

	const togglePercentage = (index, change) => {
		const recurringCostsClone = recurringCosts.slice(0);
		recurringCostsClone[index].percentage = change;
		setRecurringCosts(recurringCostsClone);

		updateCashFlow(recurringCosts, recurringProfits, totalRehabCost, customPrice, closingCost, closingPercentage, downPayment, interestRate);
    };

    return (
		<div className="financials-panel-outer-wrapper">
			{
				doneSettings === false ?
				<FinancialPreferencesBanner />
				:
				null
			}
			<MobileFinancials
				mobileBar={mobileBar}
				recurringProfits={recurringProfits}
				recurringCosts={recurringCosts}
				toggleTooltip={toggleTooltip}
				revenueTooltipShow={false}
				revenueTooltipIndex={0}
				property={property}
				onChangeRevenue={onChangeRevenue}
				changeSlider={changeSlider}
				customDiscount={customDiscount}
				customPrice={customPrice}
				onChangeLowball={onChangeLowball}
				toggleAddBedroom={toggleAddBedroom}
				addBedroom={addBedroom}
				maxPercentage={maxPercentage}
				resetRecurringCosts={resetRecurringCosts}
				resetLowballOffer={resetLowballOffer}
				downPayment={downPayment}
				onChangeDownPayment={onChangeDownPayment}
				interestRate={interestRate}
				onChangeInterest={onChangeInterest}
				closingPercentage={closingPercentage}
				onChangeClosing={onChangeClosing}
				renovationRadio={renovationRadio}
				changeRenovationRadio={changeRenovationRadio}
				formatter={formatter}
				closingCost={closingCost}
				onChangeRecurringCosts={onChangeRecurringCosts}
				costsTooltipShow={costsTooltipShow}
				costsTooltipIndex={costsTooltipIndex}
				resetRecurringProfits={resetRecurringProfits}
				blurred={blurred}
				rentAccordion={rentAccordion}
				rentalEstimates={rentalEstimates}
				toggleRentAccordion={toggleRentAccordion}
				togglePercentage={togglePercentage}
				onChangeMFHRents={onChangeMFHRents}
				recurringUnits={recurringUnits}
				profitChanges={profitChanges}
				monthlyCostsChanges={monthlyCostsChanges}
				costPerSqFoot={costPerSqFoot}
				setCostPerSqFoot={setCostPerSqFoot}
				totalRehabCost={totalRehabCost}
				setTotalRehabCost={setTotalRehabCost}
				changeRehabCost={changeRehabCost}
				onChangeCostPerSqFoot={onChangeCostPerSqFoot}
				onChangeTotalCost={onChangeTotalCost}
				defaultCostPerSqFoot={defaultCostPerSqFoot}
				partnerData={partnerData}
			/>
			<div className="financials-panel-outer-container">
				<div className="financials-panel-inner-container">
					<div className="financials-panel-title-container">
						<h2 className="heading-small-semibold colour-primary margin-x-small">
							Run the numbers
						</h2>
						<div className="financials-panel-rent-estimate-row">
							{
								recurringProfits.length !== 0 && partnerData === null ?
								<h3 className="body-regular colour-primary">
									Coffee Clozers rent estimate{' '}
									<span className="body-semibold colour-primary">
										{
											blurred === true ? 
											"$9,999"
											:
											formatter.format(Number(recurringProfits[0].original)).replace(".00", "")
										}
									</span>
								</h3>
								:
								null
							}
							<div 
								className="financials-panel-rent-estimate-compare-container"
								onClick={() => toggleRentAccordion()}
							>
								<span className="label-semibold colour-link underline">
									{rentAccordion === true ? "Hide comparison" : "Compare rent"}
								</span>
								<img
									src={ChevronUp}
									className={"financials-panel-rent-estimate-compare-chevron " + (rentAccordion === true ? "" : "reverse")}
									alt="Comparison chevron"
								/>
							</div>
						</div>
						<RentComparison
							rentAccordion={rentAccordion}
							rentalEstimates={rentalEstimates}
							formatter={formatter}
							blurred={blurred}
							partnerData={partnerData}
						/>
					</div>
					<div className="financials-panel-body-container">
						<ul className="financials-panel-sidepanel-container">
							{
								sidePanelElements.map((item, index) =>
									<Chip
										label={item.text}
										func={changeSelectedPanel}
										index={index}
										selected={selectedPanel}
										key={index}
										changeIcon={profitChanges === true && index === 0 ? true : Number(costPerSqFoot) !== defaultCostPerSqFoot && index === 1 ? true : customPrice !== property.price && index === 0 ? true : monthlyCostsChanges === true && index === 3 ? true : interestRate !== property.mortgage30us && index === 2 ? true : false}
										recommended={false}
										hover={null}
                                    	leave={null}
										disabled={false}
									/>
									// <li 
									// 	className={"financials-panel-sidepanel-element " + (selectedPanel === index ? "body-semibold colour-link" : "body-regular colour-secondary")}
									// 	key={index}
									// 	onClick={() => changeSelectedPanel(index)}
									// >
									// 	{item.text}
									// </li>
								)
							}
						</ul>
						<div className="financials-panel-right-container">
							{
								selectedPanel === 0 ?
								<IncomeNew
									recurringProfits={recurringProfits}
									toggleTooltip={toggleTooltip}
									revenueTooltipShow={revenueTooltipShow}
									revenueTooltipIndex={revenueTooltipIndex}
									property={property}
									onChangeRevenue={onChangeRevenue}
									customDiscount={customDiscount}
									changeSlider={changeSlider}
									customPrice={customPrice}
									onChangeLowball={onChangeLowball}
									toggleAddBedroom={toggleAddBedroom}
									addBedroom={addBedroom}
									maxPercentage={maxPercentage}
									resetRecurringProfits={resetRecurringProfits}
									resetLowballOffer={resetLowballOffer}
									blurred={blurred}
									onChangeMFHRents={onChangeMFHRents}
									recurringUnits={recurringUnits}
								/>
								:
								selectedPanel === 1 ?
								<RehabCalculator
									property={property}
									costPerSqFoot={costPerSqFoot}
									setCostPerSqFoot={setCostPerSqFoot}
									totalRehabCost={totalRehabCost}
									setTotalRehabCost={setTotalRehabCost}
									changeRehabCost={changeRehabCost}
									onChangeCostPerSqFoot={onChangeCostPerSqFoot}
									onChangeTotalCost={onChangeTotalCost}
									defaultCostPerSqFoot={defaultCostPerSqFoot}
								/>
								:
								selectedPanel === 2 ?
								<InitialCosts
									downPayment={downPayment}
									onChangeDownPayment={onChangeDownPayment}
									interestRate={interestRate}
									onChangeInterest={onChangeInterest}
									closingPercentage={closingPercentage}
									onChangeClosing={onChangeClosing}
									renovationRadio={renovationRadio}
									changeRenovationRadio={changeRenovationRadio}
									toggleTooltip={toggleTooltip}
									property={property}
									formatter={formatter}
									closingCost={closingCost}
									blurred={blurred}
									customPrice={customPrice}
								/>
								:
								<RecurringCosts
									recurringCosts={recurringCosts}
									onChangeRecurringCosts={onChangeRecurringCosts}
									resetRecurringCosts={resetRecurringCosts}
									toggleTooltip={toggleTooltip}
									costsTooltipShow={costsTooltipShow}
									costsTooltipIndex={costsTooltipIndex}
									blurred={blurred}
									togglePercentage={togglePercentage}
									recurringProfits={recurringProfits}
									property={property}
								/>
							}
						</div>
					</div>
					{
						highLevelProfits !== "" ?
						<div className="financials-panel-footer-container">
							<div className="financials-panel-footer-body-container">
								<div className="sticky-roi-coc-element">
									<h3 className="label-semibold-caps colour-primary margin-x-x-small">
										INITIAL COSTS
									</h3>
									<h2 className="heading-large-semibold colour-error">
										{formatter.format(Math.round(highLevelProfits.totalCosts)).replace(".00", "")}
									</h2>
								</div>
								<div className="financials-panel-footer-body-right-container">
									<div className="sticky-roi-coc-element">
										<h3 className="label-semibold-caps colour-primary margin-x-x-small">
											MONTHLY PROFIT
										</h3>
										<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 5 || blurred === true || highLevelProfits.lowerCoCR > 5 ? "colour-green" : highLevelProfits.cashOnCash < 0 || highLevelProfits.lowerCoCR < 0 ? "colour-error" : "colour-medium")}>
											{
												blurred === true ?
												"$99"
												:
												formatter.format(highLevelProfits.monthlyProfit.toFixed(0)).replace(".00", "")
											}
										</h2>
									</div>
									<div className="sticky-roi-coc-element">
										<h3 className="label-semibold-caps colour-primary margin-x-x-small">
											CASH ON CASH
										</h3>
										{
											blurred === true || typeof(highLevelProfits.cashOnCash) === "number" ?
												<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 5 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium")}>
													{
														blurred === true ?
														"4.85%"
														:
														`${highLevelProfits.cashOnCash.toFixed(2)}%`
													}
												</h2>
											:
											<div className="sticky-roi-element-row">
												{
													highLevelProfits.upperCoCR !== "" ?
													<h2 className={"heading-large-semibold " + (highLevelProfits.upperCoCR > 5 || blurred === true ? "colour-green" : highLevelProfits.upperCoCR < 0 ? "colour-error" : "colour-medium")}>
														{highLevelProfits.upperCoCR.toFixed(0)}%
													</h2>
													:
													null
												}
												{
													highLevelProfits.upperCoCR !== "" ?
													<span className="heading-large-semibold block-text colour-primary margin-sides">
														-
													</span>
													:
													null
												}
												<h2 className={"heading-large-semibold " + (highLevelProfits.lowerCoCR > 5 || blurred === true ? "colour-green" : highLevelProfits.lowerCoCR < 0 ? "colour-error" : "colour-medium")}>
													{
														highLevelProfits.lowerCoCR !== "" && highLevelProfits.upperCoCR === "" ?
														"<"
														:
														null
													}
													{highLevelProfits.lowerCoCR.toFixed(0)}%
												</h2>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						:
						null
					}
				</div>
			</div>
			<ReportFinancialsBug
				userDetails={userDetails}
				bugModal={bugModal}
				setBugModal={setBugModal}
				blurred={blurred}
			/>
		</div>
    )
}

export default FinancialsPanel;