import React, { useState, Fragment } from 'react';
import '../styles/RentTab.css';
import { MFHRentOneUnitType, SFHRent, MFHHouseHackOneUnitType, SFHRentHouseHack, MFHHouseHackMultiUnitType, MFHRentMultiUnitType, DefaultTooltip } from '../components';
import { FinancialsInput } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';

function RentTab(props) {
    const rent = props.rent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const defaultRent = props.defaultRent;
    const strategyPanel = props.strategyPanel;
    const property = props.property;
    const unitsRent = props.unitsRent;
    const onChangeUnitRent = props.onChangeUnitRent;
    const houseHackUnitsRent = props.houseHackUnitsRent;
    const houseHackRent = props.houseHackRent;
    const unitLiving = props.unitLiving;
    const setUnitLiving = props.setUnitLiving;
    const defaultHouseHackRent = props.defaultHouseHackRent;
    const onChangeUnitLiving = props.onChangeUnitLiving;
    const houseHackBedroomsRent = props.houseHackBedroomsRent;
    const onChangeBedroomRent = props.onChangeBedroomRent;
    const onChangeBedroomLiving = props.onChangeBedroomLiving;
    const resetRentEstimates = props.resetRentEstimates;
    const multipleUniqueUnits = props.multipleUniqueUnits;
    const multiUnitRent = props.multiUnitRent;
    const multiUnitRentTotal = props.multiUnitRentTotal;
    const defaultMultiUnitRent = props.defaultMultiUnitRent;
    const houseHackMultiUnitsRent = props.houseHackMultiUnitsRent;
    const multiUnitTypeUnitLiving = props.multiUnitTypeUnitLiving;
    const multiUnitHouseHackRentTotal = props.multiUnitHouseHackRentTotal;
    const defaultMultiUnitHouseHackRentTotal = props.defaultMultiUnitHouseHackRentTotal;
    const multiUnitRentRatio = props.multiUnitRentRatio;
    const onChangeMultiUnitTotalRent = props.onChangeMultiUnitTotalRent;
    const onChangeMultiUnitLiving = props.onChangeMultiUnitLiving;
    const resetMFHMultiUnitRentEstimates = props.resetMFHMultiUnitRentEstimates;
    const onChangeMultiUnitRent = props.onChangeMultiUnitRent;
    const onChangeSFHHouseHackTotal = props.onChangeSFHHouseHackTotal;
    const onChangeValueAddInput = props.onChangeValueAddInput;
    const valueAddOptions = props.valueAddOptions;
    const onChangeValueAddOptions = props.onChangeValueAddOptions;
    const viewRentalComps = props.viewRentalComps;
    const [valueAddTooltip, setValueAddTooltip] = useState("");
    const [tooltipTitle, setTooltipTitle] = useState("");
    const [tooltipDescription, setTooltipDescription] = useState("");

    const toggleTooltip = (item, val) => {
        if ( val === true ) {
            setValueAddTooltip(item.label);
            if ( item.label === "Add a bedroom" ) {
                setTooltipTitle("Add bedroom");
                setTooltipDescription("Based on the square footage to bedroom ratio, it may be possible to add a bedroom. Select this if you plan to add a bedroom.");
            }
            else if ( item.label === "ADU" ) {
                setTooltipTitle("Accessory dwelling unit (ADU)");
                setTooltipDescription("This property is zoned for ADU. Select this if there is an existing ADU or if you plan to build an ADU.");
            }
        }
        else {
            setValueAddTooltip("");
            setTooltipTitle("");
            setTooltipDescription("");
        }
    };

    return (
        <div className="rent-tab-outer-container">
            {
                strategyPanel === 3 ?
                <div className="margin-large relative-container">
                    <div className="rent-tab-fix-flip-exclamation-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.61547 4.9648C7.65031 4.92995 7.69262 4.91253 7.7424 4.91253H8.36213C8.41689 4.91253 8.4592 4.92995 8.48907 4.9648C8.52391 4.99964 8.54133 5.04195 8.54133 5.09173V8.616C8.54133 8.66578 8.52391 8.70809 8.48907 8.74293C8.4592 8.77778 8.41689 8.7952 8.36213 8.7952H7.7424C7.69262 8.7952 7.65031 8.77778 7.61547 8.74293C7.58062 8.70809 7.5632 8.66578 7.5632 8.616V5.09173C7.5632 5.04195 7.58062 4.99964 7.61547 4.9648Z" fill="#CC564B"/>
                            <path d="M7.57813 10.3571C7.61298 10.3273 7.65778 10.3123 7.71253 10.3123H8.392C8.44676 10.3123 8.49156 10.3273 8.5264 10.3571C8.56124 10.392 8.57867 10.4368 8.57867 10.4915V11.0291C8.57867 11.0789 8.56124 11.1212 8.5264 11.1561C8.49156 11.1909 8.44676 11.2083 8.392 11.2083H7.71253C7.65778 11.2083 7.61298 11.1909 7.57813 11.1561C7.54827 11.1212 7.53333 11.0789 7.53333 11.0291V10.4915C7.53333 10.4368 7.54827 10.392 7.57813 10.3571Z" fill="#CC564B"/>
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1ZM14.0667 8C14.0667 4.64947 11.3505 1.93333 8 1.93333C4.64947 1.93333 1.93333 4.64947 1.93333 8C1.93333 11.3505 4.64947 14.0667 8 14.0667C11.3505 14.0667 14.0667 11.3505 14.0667 8Z" 
                                fill="#CC564B"
                            />
                        </svg>
                    </div>
                    <span className="body-semibold colour-error block-text margin-x-small">
                        Select a different strategy to estimate rent.
                    </span>
                    <span className="body-regular colour-primary">
                        You selected Fix & Flip, which assumes you will not rent this property. 
                        Select another strategy above to use this feature.
                    </span>
                </div>
                :
                null
            }
            <div className={"rent-tab-inner-container " + (strategyPanel === 3 ? "rent-tab-fix-flip" : "")}>
                {
                    ((property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" || property.propertyTypeDimension === "Condo") && strategyPanel !== 1) || (strategyPanel === 3) ?
                    // SFH rent for NON house hack
                    <SFHRent
                        rent={rent}
                        blurred={blurred}
                        onChangeRent={onChangeRent}
                        defaultRent={defaultRent}
                        strategyPanel={strategyPanel}
                        resetRentEstimates={resetRentEstimates}
                        property={property}
                    />
                    :
                    ((property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" || property.propertyTypeDimension === "Condo") && strategyPanel === 1) ?
                    // SFH rent for House Hack
                    <SFHRentHouseHack
                        rent={houseHackRent}
                        blurred={blurred}
                        onChangeRent={onChangeSFHHouseHackTotal}
                        defaultRent={defaultHouseHackRent}
                        strategyPanel={strategyPanel}
                        houseHackBedroomsRent={houseHackBedroomsRent}
                        bedroomLiving={unitLiving}
                        onChangeBedroomLiving={onChangeBedroomLiving}
                        onChangeBedroomRent={onChangeBedroomRent}
                        resetRentEstimates={resetRentEstimates}
                        property={property}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === false && property.units !== undefined && property.units !== null && property.units > 1) 
                    && 
                    (strategyPanel === 0 || strategyPanel === 2) ?
                    // MFH rent for one unit type
                    <MFHRentOneUnitType
                        property={property}
                        blurred={blurred}
                        rent={rent}
                        defaultRent={defaultRent}
                        onChangeRent={onChangeRent}
                        units={unitsRent}
                        onChangeUnitRent={onChangeUnitRent}
                        resetRentEstimates={resetRentEstimates}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === false && property.units !== undefined && property.units !== null && property.units > 1 && strategyPanel === 1) ?
                    // MFH rent for one unit type on a House Hack
                    <MFHHouseHackOneUnitType
                        property={property}
                        blurred={blurred}
                        rent={houseHackRent}
                        defaultRent={defaultHouseHackRent}
                        onChangeRent={onChangeRent}
                        units={houseHackUnitsRent}
                        onChangeUnitRent={onChangeUnitRent}
                        unitLiving={unitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeUnitLiving}
                        resetRentEstimates={resetRentEstimates}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === true && property.units !== undefined && property.units !== null && property.units > 1 && strategyPanel !== 1) ?
                    <MFHRentMultiUnitType
                        property={property}
                        blurred={blurred}
                        rent={multiUnitRentTotal}
                        defaultRent={defaultMultiUnitRent}
                        onChangeRent={onChangeMultiUnitTotalRent}
                        units={multiUnitRent}
                        onChangeUnitRent={onChangeMultiUnitRent}
                        unitLiving={unitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeUnitLiving}
                        resetRentEstimates={resetMFHMultiUnitRentEstimates}
                        uniqueUnits={property.uniqueUnitPropertyDetails}
                        multiUnitRentRatio={multiUnitRentRatio}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === true && property.units !== undefined && property.units !== null && property.units > 1 && strategyPanel === 1) ?
                    <MFHHouseHackMultiUnitType
                        property={property}
                        blurred={blurred}
                        rent={multiUnitHouseHackRentTotal}
                        defaultRent={defaultMultiUnitHouseHackRentTotal}
                        onChangeRent={onChangeMultiUnitTotalRent}
                        units={houseHackMultiUnitsRent}
                        onChangeUnitRent={onChangeMultiUnitRent}
                        unitLiving={multiUnitTypeUnitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeMultiUnitLiving}
                        resetRentEstimates={resetMFHMultiUnitRentEstimates}
                        uniqueUnits={property.uniqueUnitPropertyDetails}
                        multiUnitRentRatio={multiUnitRentRatio}
                    />
                    :
                    null
                }
                {
                    (property.rentComps === undefined && property.unitPropertyComps === undefined) ||
                    (property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length === 0) ||
                    (property.rentComps !== undefined && property.rentComps !== null && property.rentComps.length === 0)
                    ?
                    null
                    :
                    <div className="rent-tab-rent-comps-container button-row margin-medium">
                        <span 
                            className="body-regular-underline colour-link block-text subtext-margin-right"
                            onClick={() => viewRentalComps()}
                        >
                            Go to rent comps
                        </span>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                        >
                            <path 
                                d="M8.71167 15.1521L15.1157 8.74807C15.5086 8.35509 15.5086 7.71794 15.1157 7.32496C14.7227 6.93198 14.0855 6.93198 13.6926 7.32496L9.00641 12.0111L9.00641 1.56C9.00641 1.00424 8.55588 0.553711 8.00012 0.553711C7.44436 0.553711 6.99383 1.00424 6.99383 1.56L6.99383 12.0111L2.30769 7.32496C1.91471 6.93198 1.27756 6.93198 0.884579 7.32496C0.491599 7.71794 0.491599 8.35509 0.884579 8.74807L7.28857 15.1521C7.68155 15.545 8.31869 15.545 8.71167 15.1521Z" 
                                fill="#2F4858"
                            />
                        </svg>
                    </div>
                }
                {
                    valueAddOptions.length > 0 && strategyPanel !== 3 ?
                    <div className="rent-tab-value-add-opportunity-container">
                        <span className="body-semibold colour-primary block-text margin-medium">
                            Value-add opportunity
                        </span>
                        <div className="value-add-opportunity-inner-row-container">
                            {
                                valueAddOptions.map((item, index) =>
                                    <div 
                                        className="value-add-opportunity-element-container"
                                        key={index}
                                    >
                                        <div className="value-add-opportunity-inner-element-container">
                                            <div 
                                                className="value-add-checkbox-container"
                                                onClick={() => strategyPanel === 3 ? null : onChangeValueAddOptions(index)}
                                            >
                                                {
                                                    item.active === true ?
                                                    <div className="value-add-checkbox">
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <img
                                                src={item.icon}
                                                className="value-add-opportunity-icon"
                                                alt={item.label}
                                            />
                                            <span className="body-regular colour-primary block-text subtext-margin-right-large">
                                                {item.label}
                                            </span>
                                            <div 
                                                className="value-add-opportunity-tooltip-container relative-container"
                                                onMouseEnter={() => toggleTooltip(item, true)}
                                                onMouseLeave={() => toggleTooltip(item, false)}
                                            >
                                                <svg 
                                                    className="value-add-opportunity-tooltip-icon"
                                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                >
                                                    <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                                    <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                                                </svg>
                                                {
                                                    item.label === valueAddTooltip ?
                                                    <DefaultTooltip
                                                        title={tooltipTitle}
                                                        description={tooltipDescription}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        {
                                            item.active === true ?
                                            <div className="value-add-opportunity-extra-input-container margin-top-small">
                                                <FinancialsInput
                                                    value={item.value}
                                                    type="number"
                                                    label={
                                                        Number(item.original) === Number(item.value) ?
                                                        item.inputLabel
                                                        :
                                                        <Fragment>
                                                            {item.inputLabel}
                                                            <img
                                                                src={ChangeDot}
                                                                className="financials-panel-change-dot"
                                                                alt="Change"
                                                            />
                                                        </Fragment>
                                                    }
                                                    id={`${item.inputLabel}-input-${index}`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    onChange={(text) => onChangeValueAddInput(text.target.value, item)}
                                                    disabled={false}
                                                />
                                                {
                                                    Number(item.original) === Number(item.value) ?
                                                    null
                                                    :
                                                    <div className="rent-tab-reset-container">
                                                        <span 
                                                            className="label-semibold colour-link"
                                                            onClick={() => onChangeValueAddInput(Number(item.original), item)}
                                                        >
                                                            Reset
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default RentTab;