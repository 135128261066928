import React, { useState, useEffect } from 'react';
import '../styles/HowDidYouHear.css';
import { Header, Footer, Loading } from '../components';
import { ActionButton, citiesForPurchase } from '../styles/GlobalStyles';
import { setData, checkUserId, getDocument, recordEvent } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

function HowDidYouHear() {

    const location = useLocation();
    const state = location.state;
    const [disabled, setDisabled] = useState(true);
    const [loadingState, setLoadingState] = useState(false);
    const navigate = useNavigate();

    const [options, setOptions] = useState([
        {
            label: "Real Estate Rookie Podcast",
            value: false,
            gaVal: "real_estate_rookie_podcast"
        },
        {
            label: "Analytics Ariel (Tech in Real Estate)",
            value: false,
            gaVal: "analytics_ariel"
        },
        {
            label: "YouTube Ad",
            value: false,
            gaVal: "youtube_ad"
        },
        {
            label: "Facebook Group",
            value: false,
            gaVal: "facebook_group"
        },
        {
            label: "Bigger Pockets",
            value: false,
            gaVal: "bigger_pockets"
        },
        {
            label: "Google search",
            value: false,
            gaVal: "google_search"
        },
        {
            label: "TikTok",
            value: false,
            gaVal: "tiktok"
        },
        {
            label: "Instagram",
            value: false,
            gaVal: "instagram"
        },
        {
            label: "Word-of-mouth",
            value: false,
            gaVal: "word_of_mouth"
        },
        {
            label: "Other",
            value: false,
            gaVal: "other"
        }
    ]);

    useEffect(() => {
        document.title = "How did you hear about us?";
    });

    const toggleCheckbox = (index) => {
        setDisabled(false);
        const newOptions = options.slice();
        newOptions[index].value = !options[index].value;
        setOptions(newOptions);
    };

    const cta = async() => {
        setDisabled(true);
        setLoadingState(true);
        const amendedOptions = [];
        const tagManagerArgs = {
            dataLayer: {
                event: "user_acquisition"
            }
        };

        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if ( element.value === true ) {
                amendedOptions.push(element);
            }
            tagManagerArgs.dataLayer[element.gaVal] = element.value;
        };
        TagManager.dataLayer(tagManagerArgs);
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const getData = await getDocument("Users", user.userId);
            if ( getData.status === 200 ) {
                const userData = getData.data.data;
                userData.acquisitionChannels = amendedOptions;
                recordEvent("How did you hear about us?", {
                    "Acquisition Channels": amendedOptions
                });
                const writeData = await setData("Users", user.userId, userData);
                if ( writeData.status === 200 ) {
                    const chosenCity = state === null ? null : state.chosenCity !== undefined ? state.chosenCity : null;
                    if ( state !== null && chosenCity !== undefined && chosenCity !== null ) {
                        const cityId = chosenCity.cityId;
                        if ( cityId !== undefined ) {
                            navigate(`/properties-list/${cityId}`, {
                                state: state
                            });
                        }
                        else if ( chosenCity.city !== undefined && chosenCity.state !== undefined ) {
                            const findCityIndex = citiesForPurchase.findIndex(e => e.cityUnchanged === chosenCity.city && e.state === chosenCity.state);
                            if ( findCityIndex !== -1 ) {
                                const coreCityId = citiesForPurchase[findCityIndex].city_id;
                                navigate(`/properties-list/${coreCityId}`, {
                                    state: state
                                });
                            }
                            else {
                                navigate("/pick-city", {
                                    state: state
                                });
                            }
                        }
                        else {
                            navigate("/pick-city", {
                                state: state
                            });
                        }
                    }
                    else {
                        navigate("/pick-city", {
                            state: state
                        });
                    }
                    setLoadingState(false);
                }
                else {
                    navigate("/", {
                        state: state
                    });
                    setLoadingState(false);
                }
            }
            else {
                navigate("/", {
                    state: state
                });
                setLoadingState(false);
            }
        }
        else {
            navigate("/", {
                state: state
            });
            setLoadingState(false);
        }
        setDisabled(false);
    };

    return (
        <div className="how-did-you-hear-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="how-did-you-hear-inner-container">
                {
                    loadingState === true ?
                    <Loading />
                    :
                    null
                }
                <div className="how-did-you-hear-title-container margin-x-x-large">
                    <span className="label-regular colour-secondary margin-x-small body-text">
                        One last thing...
                    </span>
                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                        How did you hear about us?
                    </h1>
                    <h2 className="body-regular colour-primary">
                        Select all that apply.
                    </h2>
                </div>
                <div className="how-did-you-hear-body-container margin-x-large">
                    {
                        options.map((item, index) =>
                            <div     
                                className="how-did-you-hear-element-container margin-medium"
                                key={index}
                            >
                                <div 
                                    className="price-reduction-checkbox-container"
                                    onClick={() => toggleCheckbox(index)}
                                >
                                    {
                                        item.value === true ?
                                        <div className="price-reduction-checkbox">
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <span className="body-regular colour-primary">
                                    {item.label}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="how-did-you-hear-bottom-text margin-x-x-large">
                    <span className="body-regular colour-primary">
                        Thanks for answering, we know it’s annoying but this REALLY helps us out 🫶
                    </span>
                </div>
                <div className="how-did-you-hear-button-container">
                    <ActionButton
                        disabled={disabled}
                        onClick={() => cta()}
                    >
                        Confirm
                    </ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HowDidYouHear;