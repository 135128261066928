import React from 'react';
import '../styles/RehabCalculator.css';
import { FinancialsInput } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { TextBadge } from './';

function RehabCalculator(props) {
    const property = props.property;
    const costPerSqFoot = props.costPerSqFoot;
    const onChangeCostPerSqFoot = props.onChangeCostPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const onChangeTotalCost = props.onChangeTotalCost;
    const changeRehabCost = props.changeRehabCost;
    const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
    const defaultTotalCost = defaultCostPerSqFoot * property.livingArea;

    return (
        <div className="rehab-calculator-outer-container">
            <h3 className="label-regular colour-secondary margin-x-small">
                Estimate rehab cost
            </h3>
            <div className={"rehab-calculator-inputs-row " + (totalRehabCost === defaultTotalCost ? "margin-medium" : "")}>
                <div className="rehab-calculator-input-element subtext-margin-right">
                    <FinancialsInput
                        value={costPerSqFoot}
                        type="number"
                        label="Per squarefoot"
                        // label={
                        //     Number(subItem.value) !== Number(subItem.rentcast_rent) ?
                        //     <Fragment>
                        //         Layout {layoutLabels[i]}
                        //         <img
                        //             src={ChangeDot}
                        //             className="financials-panel-change-dot"
                        //             alt="Change"
                        //         />
                        //     </Fragment>
                        //     :
                        //     `Layout ${layoutLabels[i]}`
                        // }
                        id={`cost-per-sq-foot-input`}
                        placeholder={`30`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                        onChange={(text) => onChangeCostPerSqFoot(text.target.value)}
                    />
                     <span className="body-regular colour-secondary block-text subtext-margin-left">
                        x{property.livingArea}
                    </span>
                </div>
                <div className="rehab-calculator-input-element">
                    <span className="body-regular colour-secondary block-text subtext-margin-right">
                        =
                    </span>
                    <FinancialsInput
                        value={totalRehabCost}
                        type="number"
                        label="Total"
                        // label={
                        //     Number(subItem.value) !== Number(subItem.rentcast_rent) ?
                        //     <Fragment>
                        //         Layout {layoutLabels[i]}
                        //         <img
                        //             src={ChangeDot}
                        //             className="financials-panel-change-dot"
                        //             alt="Change"
                        //         />
                        //     </Fragment>
                        //     :
                        //     `Layout ${layoutLabels[i]}`
                        // }
                        id={`total-cost-input`}
                        placeholder={`40,000`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                        onChange={(text) => onChangeTotalCost(text.target.value)}
                    />
                </div>
            </div>
            {
                totalRehabCost === defaultTotalCost ?
                null
                :
                <div className="rehab-calculator-rehab-container margin-medium">
                    <span 
                        className="label-semibold colour-link"
                        onClick={() => onChangeTotalCost(defaultTotalCost)}
                    >
                        Reset
                    </span>
                </div>
            }
            <div className="rehab-calculator-slider-container margin-medium">
                <Stack 
                    spacing={2} 
                    direction="row" 
                    sx={{ mb: 1, px: 1 }} 
                    alignItems="center"
                >
                    <Slider
                        aria-label="Discount"
                        value={Number(costPerSqFoot)}
                        onChange={changeRehabCost}
                        min={0}
                        max={100}
                        disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                        sx={{
                            color: '#CFBCAD',
                            height: '5px',
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                width: 24,
                                height: 24,
                                backgroundColor: '#fff',
                                '&:before': {
                                    boxShadow: '0px 0px 10px 0px #99959180',
                                },
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                },
                            }
                        }}
                    />
                </Stack>
            </div>
            <div className="rehab-calculator-analysis-container">
                <span className="body-regular colour-secondary block-text subtext-margin-right">
                    This estimate is
                </span>
                <TextBadge
                    bgColour={costPerSqFoot < 30 ? "bg-colour-green" : costPerSqFoot < 50 ? "bg-colour-yellow" : "bg-colour-red"}
                    text={costPerSqFoot < 30 ? "Low" : costPerSqFoot < 50 ? "Average" : "High"}
                />
                <span className="body-regular colour-secondary block-text subtext-margin-left">
                    for the city.
                </span>
            </div>
        </div>
    )
}

export default RehabCalculator;