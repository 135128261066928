import React, { useState, useRef } from 'react';
import '../styles/ReportFinancialsBug.css';
import { ReportFinancialsBugModal } from './';
import { checkUserId, setData, makeId, sendEmail } from '../functions';

function ReportFinancialsBug(props) {

	const userDetails = props.userDetails;
	const bugModal = props.bugModal;
	const setBugModal = props.setBugModal;
	const blurred = props.blurred;

	const recipientEmail = ["liam.maher@coffeeclozers.com", "maddy@coffeeclozers.com", "ariel.herrera@coffeeclozers.com"];
	const senderEmail = "liam.maher@coffeeclozers.com";
	const dynamicTemplate = "d-26c8a5dfcece437993a21ac4b7ceecab";
	const [disabled, setDisabled] = useState(false);
	const modalRef = useRef(null);

	const openModal = () => {
		// document.body.style.overflow = "hidden";
		setBugModal(true);

		setTimeout(() => {
			const id = 'reportFinancialsModal';
			const yOffset = -300;
			const element = document.getElementById(id);
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
		}, 200);
	};

	const complete = async(bugText) => {
		setDisabled(true);
		const colRef = "Financials Bug";
		const docRef = await makeId(15);
		const user = await checkUserId();
		const propertyUrl = window.location.href;

		const data = {
			name:  `${userDetails.firstName} ${userDetails.lastName}`,
			email: user.email,
			userId: user.userId,
			url: propertyUrl,
			bug: bugText,
			date: new Date(),
		};

		const writeData = await setData(colRef, docRef, data);
		if ( writeData.status === 200 ) {
			const msg = {
				to: recipientEmail,
				from: senderEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					name:  `${userDetails.firstName} ${userDetails.lastName}`,
					email: user.email,
					userId: user.userId,
					url: propertyUrl,
					bug: bugText
				}
			};
			await sendEmail(msg);
		}

		setBugModal(false);
		setDisabled(false);
		// document.body.removeAttribute("style");
		alert("Bug reported - thank you for letting us know 😊");
	}

	return (
		<div className="financials-panel-bug-report-container">
			{
				bugModal === true ?
				<ReportFinancialsBugModal
					setBugModal={setBugModal}
					complete={complete}
					disabled={disabled}
					modalRef={modalRef}
				/>
				:
				null
			}
			<div className="report-financials-bug-inner-container">
				<span className="body-regular colour-secondary">
					See something inaccurate?{' '}
					<span 
						className="colour-link underline"
						onClick={() => blurred === true ? null : openModal()}
					>
						Report an issue
					</span>
				</span>
			</div>
		</div>
	)
}

export default ReportFinancialsBug;