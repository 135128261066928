import React, { Fragment } from 'react';
import { FinancialsInput, IOSSwitch } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { revenueTooltipIndexes } from '../functions';
import { Info, Beak, ChangeDot } from '../assets';

function IncomeNew(props) {

    const recurringProfits = props.recurringProfits;
    const toggleTooltip = props.toggleTooltip;
    const revenueTooltipShow = props.revenueTooltipShow;
    const revenueTooltipIndex = props.revenueTooltipIndex;
    const property = props.property;
    const onChangeRevenue = props.onChangeRevenue;
    const customDiscount = props.customDiscount;
    const changeSlider = props.changeSlider;
    const customPrice = props.customPrice;
    const onChangeLowball = props.onChangeLowball;
    const toggleAddBedroom = props.toggleAddBedroom;
    const addBedroom = props.addBedroom;
    const maxPercentage = props.maxPercentage;
    const resetRecurringProfits = props.resetRecurringProfits;
    const resetLowballOffer = props.resetLowballOffer;
    const blurred = props.blurred;
    const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const onChangeMFHRents = props.onChangeMFHRents;
    const recurringUnits = props.recurringUnits;

    const layoutLabels = ["A", "B", "C", "D"];

    return (
        <div className="income-new-outer-container">
            <h3 className="label-regular colour-secondary margin-x-small">
                Rent
            </h3>
            <div className="margin-large small-desktop-grid">
                {
                    recurringProfits.map((item, index) =>
                        <div 
                            className="new-income-input-container margin-medium relative-container"
                            key={index}
                        >
                            <div className={multipleUniqueUnits === true && index === 0 ? "new-income-inner-input-container" : "new-income-inner-input-container"}>
                                {
                                    multipleUniqueUnits === true && index === 0 ?
                                    <div className="financials-row-inputs">
                                        {
                                            recurringUnits.map((subItem, i) =>
                                                <div 
                                                    key={i} 
                                                    className="financials-mfh-rent-element"
                                                >
                                                    <FinancialsInput
                                                        value={blurred === true ? "999" : subItem.value}
                                                        type="number"
                                                        label={
                                                            Number(subItem.value) !== Number(subItem.rentcast_rent) ?
                                                            <Fragment>
                                                                Layout {layoutLabels[i]}
                                                                <img
                                                                    src={ChangeDot}
                                                                    className="financials-panel-change-dot"
                                                                    alt="Change"
                                                                />
                                                            </Fragment>
                                                            :
                                                            `Layout ${layoutLabels[i]}`
                                                        }
                                                        id={`${item.label}-input-${i}`}
                                                        placeholder={`${subItem.value}`}
                                                        onWheel={(e) => e.target.blur()}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled={blurred}
                                                        onChange={(text) => onChangeMFHRents(text.target.value, i)}
                                                    />
                                                    <span className="body-regular colour-secondary block-text subtext-margin-left">
                                                        x{subItem.frequency}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <div className="financials-input-long-width">
                                        <FinancialsInput
                                            value={blurred === true ? "999" : item.value}
                                            type="number"
                                            label={
                                                Number(item.value) !== Number(item.original) ?
                                                <Fragment>
                                                    {item.label}
                                                    <img
                                                        src={ChangeDot}
                                                        className="financials-panel-change-dot"
                                                        alt="Change"
                                                    />
                                                </Fragment>
                                                :
                                                item.label
                                            }
                                            id={`${item.label}-input-${index}`}
                                            onWheel={(e) => e.target.blur()}
                                            placeholder={index === 1 ? "0" : ""}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            disabled={blurred}
                                            onChange={(text) => onChangeRevenue(text.target.value, index)}
                                        />
                                        <img
                                            src={Info}
                                            className="financials-panel-info-tooltip"
                                            alt="Info"
                                            onMouseEnter={() => toggleTooltip(true, index, "revenue")}
                                            onMouseLeave={() => toggleTooltip(false, index, "revenue")}
                                        />
                                        {
                                            revenueTooltipShow === true && revenueTooltipIndex === index ?
                                            <div className="financials-panel-tooltip-container">
                                                <img
                                                    src={Beak}
                                                    className="financials-panel-beak"
                                                    alt="Beak"
                                                />
                                                <h5 className="property-details-tooltip-title">
                                                    {revenueTooltipIndexes[index].title}
                                                </h5>
                                                <span className="property-details-tooltip-text">
                                                    {revenueTooltipIndexes[index].description}
                                                </span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            </div>
                            {
                                blurred === false ?
                                <div 
                                    className={"new-financials-costs-recurring-costs-reset-container " + (Number(item.value) !== Number(item.original) ? "" : "view-none")}
                                    onClick={() => resetRecurringProfits(index, item.original)}
                                >
                                    <span className="label-semibold colour-link">
                                        Reset
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                    )
                }
            </div>
            <h3 className="label-regular colour-secondary margin-x-small">
                Purchase
            </h3>
            <div className="income-purchase-price-container margin-large">
                <div 
                    className="new-income-input-container relative-container"
                >
                    <div className="financials-input-long-width">
                        <FinancialsInput
                            value={customPrice}
                            type="number"
                            label={
                                property.price !== customPrice ?
                                <Fragment>
                                    Asking price
                                    <img
                                        src={ChangeDot}
                                        className="financials-panel-change-dot"
                                        alt="Change"
                                    />
                                </Fragment>
                                :
                                "Asking price"
                            }
                            id={`lowball-input`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(text) => onChangeLowball(text.target.value)}
                        />
                    </div>
                     <div 
                        className={"new-financials-costs-recurring-costs-reset-container " + (property.price !== customPrice ? "" : "view-none")}
                        onClick={() => resetLowballOffer(property.price)}
                    >
                        <span className="label-semibold colour-link">
                            Reset
                        </span>
                    </div>
                </div>
                <div className="new-financials-asking-price-small-row">
                    <div className="margin-medium">
                        {
                            customDiscount <= 0 ?
                            <span className="label-regular colour-secondary">
                                {customDiscount * -1}% below asking price
                            </span>
                            :
                            <span className="label-regular colour-secondary">
                                {customDiscount}% above asking price
                            </span>
                        }
                    </div>
                    <div 
                        className={"new-financials-costs-recurring-costs-reset-container " + (property.price !== customPrice ? "" : "view-none")}
                        onClick={() => resetLowballOffer(property.price)}
                    >
                        <span className="label-semibold colour-link">
                            Reset
                        </span>
                    </div>
                </div>
                <div className="new-income-slider-container">
                    <Stack 
                        spacing={2} 
                        direction="row" 
                        sx={{ mb: 1, px: 1 }} 
                        alignItems="center"
                    >
                        <Slider
                            aria-label="Discount"
                            value={customDiscount}
                            onChange={changeSlider}
                            min={maxPercentage}
                            max={25}
                            disabled={blurred}
                            sx={{
                                color: '#CFBCAD',
                                height: '5px',
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 24,
                                    height: 24,
                                    backgroundColor: '#fff',
                                    '&:before': {
                                        boxShadow: '0px 0px 10px 0px #99959180',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                    },
                                }
                            }}
                        />
                    </Stack>
                </div>
            </div>
            {
                property.addBedOpportunity === true ?
                <div className="new-income-other-container">
                    <h3 className="label-regular colour-secondary margin-x-small">
                        Other
                    </h3>
                    <div className="new-income-add-bedroom-row margin-x-small">
                        <span className="new-income-add-bedroom-text body-semibold colour-primary">
                            Add a bedroom
                        </span>
                        <FormGroup>
                            <FormControlLabel
                                control={<IOSSwitch checked={addBedroom} onChange={(e) => toggleAddBedroom(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                            />
                        </FormGroup>
                    </div>
                    <span className="body-regular colour-secondary">
                        Based on the square footage to bedroom ratio, 
                        it may be possible to add a bedroom.
                    </span>
                </div>
                :
                null
            }
        </div>
    )
}

export default IncomeNew;