import React from 'react';
import '../styles/BuyBoxModal.css';
import { ActionButton } from '../styles/GlobalStyles';

function BuyBoxModal(props) {
    const dontAsk = props.dontAsk;
    const setDontAsk = props.setDontAsk;
    // const setOpenBuyBoxModal = props.setOpenBuyBoxModal;
    const skip = props.skip;
    const complete = props.complete;

    // const closeModal = () => {
    //     setOpenBuyBoxModal(false);
    // };

    return (
        <div className="buy-box-modal-outer-container">
            <div className="buy-box-modal-inner-container relative-container">
                {/* <div 
                    className="buy-box-modal-close-container"
                    onClick={() => closeModal()}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="22" 
                        height="24" 
                        viewBox="0 0 22 24" 
                        fill="none"
                        className="buy-box-modal-close-icon"
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25732 0.419021C1.78932 -0.100741 0.988587 -0.142706 0.468826 0.32529C-0.0509363 0.793286 -0.092901 1.59402 0.375095 2.11378L9.29582 12.0213L0.41338 21.8862C-0.0546153 22.406 -0.0126517 23.2067 0.50711 23.6747C1.02687 24.1427 1.82761 24.1007 2.2956 23.581L10.9999 13.9138L19.7042 23.5809C20.1722 24.1007 20.9729 24.1427 21.4927 23.6747C22.0125 23.2067 22.0544 22.4059 21.5864 21.8862L12.704 12.0213L21.6247 2.11382C22.0927 1.59406 22.0507 0.793322 21.531 0.325326C21.0112 -0.142669 20.2105 -0.100704 19.7425 0.419057L10.9999 10.1287L2.25732 0.419021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div> */}
                <div className="buy-box-modal-title-container margin-x-large">
                    <h2 className="heading-large-semibold colour-primary margin-medium">
                        Want to set up buy box?
                    </h2>
                    <span className="body-regular colour-secondary">
                        Optionally set up a buy box for this city.
                    </span>
                </div>
                <div className="margin-x-large">
                    <span className="body-regular colour-primary">
                        Setting up a buy box will automatically filter listings and enable notifications 
                        every time a new property that falls within your buy box is listed on the market.
                    </span>
                </div>
                <div className="buy-box-modal-dont-show-container margin-x-large">
                    <div 
                        className="checkbox-container"
                        onClick={() => setDontAsk(!dontAsk)}
                    >
                        {
                            dontAsk === true ?
                            <div className="checkbox-icon">
                            </div>
                            :
                            null
                        }
                    </div>
                    <span className="body-regular colour-primary">
                        Don’t show this again.
                    </span>
                </div>
                <div className="buy-box-modal-bottom-row">
                    <div 
                        className="buy-box-modal-skip-container text-button"
                        onClick={() => skip()}
                    >
                        <span className="body-regular colour-link">
                            Skip for now
                        </span>
                    </div>
                    <div className="buy-box-modal-button-container">
                        <ActionButton
                            onClick={() => complete()}
                        >
                            Set up buy box
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BuyBoxModal;