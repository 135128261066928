import React, { Fragment } from 'react';
import { FinancialsInput } from '../styles/GlobalStyles'; //colour, CustomRadio, CustomSelect
import InputAdornment from '@mui/material/InputAdornment';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import Box from '@mui/material/Box';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { ChangeDot } from '../assets';

function InitialCosts(props) {
    
    const downPayment = props.downPayment;
    const onChangeDownPayment = props.onChangeDownPayment;
    const interestRate = props.interestRate;
    const onChangeInterest = props.onChangeInterest;
    const closingPercentage = props.closingPercentage;
    const onChangeClosing = props.onChangeClosing;
    // const renovationRadio = props.renovationRadio;
    // const changeRenovationRadio = props.changeRenovationRadio;
    const formatter = props.formatter;
    const closingCost = props.closingCost;
    const blurred = props.blurred;
    const customPrice = props.customPrice;
    const property = props.property;

    // const renovationsOptions = [
    //     {
    //         text: "None",
    //         value: 0
    //     },
    //     {
    //         text: "Light ($1k - $10k)",
    //         value: 1
    //     },
    //     {
    //         text: "Medium ($10k - $30k)",
    //         value: 2
    //     },
    //     {
    //         text: "Heavy ($30k+)",
    //         value: 3
    //     }
    // ];

    // const styles = {
	// 	menuItem: {
	// 		backgroundColor: colour.grayScaleWhite,
	// 		fontFamily: 'Rubik'
	// 	}
	// };

    return (
        <div className="initial-costs-outer-container">
            <h3 className="label-regular colour-secondary margin-x-small">
                Up-front costs
            </h3>
            <div className="initial-costs-inputs-container">
                <div className="initial-costs-row margin-medium">
                    <div className="new-income-input-container relative-container">
                        <FinancialsInput
                            value={downPayment}
                            type="number"
                            label="Down payment"
                            id={`down-payment-input`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                style: {
                                    maxWidth: '100px'
                                }
                            }}
                            disabled={blurred}
                            onChange={(text) => onChangeDownPayment(text.target.value)}
                        />
                    </div>
                    <span className="initial-costs-calculated-value body-semibold colour-error">
                        {formatter.format((customPrice * downPayment / 100) * -1).replace(/(\.|,)00$/g, '')}
                    </span>
                </div>
                <div className="new-income-input-container new-interest-rate-container margin-medium relative-container">
                    <FinancialsInput
                        value={interestRate}
                        type="number"
                        label={
                            property.mortgage30us !== Number(interestRate) ?
                            <Fragment>
                                Interest rate
                                <img
                                    src={ChangeDot}
                                    className="financials-panel-change-dot"
                                    alt="Change"
                                />
                            </Fragment>
                            :
                            "Interest rate"
                        }
                        id={`interest-rate-input`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            style: {
                                maxWidth: '100px'
                            }
                        }}
                        disabled={blurred}
                        onChange={(text) => onChangeInterest(text.target.value)}
                    />
                    {
                        blurred === false ?
                        <div 
                            className={"new-financials-costs-recurring-costs-reset-container " + (property.mortgage30us !== Number(interestRate) ? "" : "view-none")}
                            onClick={() => onChangeInterest(property.mortgage30us)}
                        >
                            <span className="label-semibold colour-link">
                                Reset
                            </span>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="initial-costs-row margin-x-x-small">
                    <div className="new-income-input-container relative-container">
                        <FinancialsInput
                            value={closingPercentage}
                            type="number"
                            label="Closing costs"
                            id={`closing-cost-input`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                style: {
                                    maxWidth: '100px'
                                }
                            }}
                            disabled={blurred}
                            onChange={(text) => onChangeClosing(text.target.value)}
                        />
                    </div>
                    <span className="initial-costs-calculated-value body-semibold colour-error">
                        {formatter.format(closingCost * -1).replace(/(\.|,)00$/g, '')}
                    </span>
                </div>
                <span className="label-regular colour-secondary">
                    % of purchase price
                </span>
            </div>
          
            {/* <h3 className="label-regular colour-secondary margin-x-small">
                Renovations &amp; CapEx
            </h3>
            <div className="initial-costs-renovation-radios">
                <FormControl>
                    <RadioGroup
                        aria-labelledby="payment-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={renovationRadio}
                        onChange={(val) => blurred === true ? null : changeRenovationRadio(val.target.value)}
                    >
                        <FormControlLabel
                            value={0} 
                            control={<CustomRadio disabled={blurred} />} 
                            className="new-financials-costs-renovation-costs-radio-label"
                            label={<span className="body-regular colour-primary">None</span>}
                        />
                        <FormControlLabel 
                            value={1} 
                            control={<CustomRadio disabled={blurred} />} 
                            className="new-financials-costs-renovation-costs-radio-label"
                            label={<span className="body-regular colour-primary">Light ($1k - $10k) <span className="new-financials-costs-renovation-costs-label-description">Standard</span></span>}
                        />
                        <FormControlLabel 
                            value={2} 
                            control={<CustomRadio disabled={blurred} />} 
                            className="new-financials-costs-renovation-costs-radio-label"
                            label={<span className="body-regular colour-primary">Medium ($10k - $30k)</span>}
                        />
                        <FormControlLabel 
                            value={3} 
                            control={<CustomRadio disabled={blurred} />} 
                            className="new-financials-costs-renovation-costs-radio-label"
                            label={<span className="body-regular colour-primary">Heavy ($30k+)</span>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="initial-costs-renovation-select">
                <Box>
                    <FormControl fullWidth>
                        <Select
                            id="renovation-select"
                            value={renovationRadio}
                            label=""
                            onChange={(event) => changeRenovationRadio(event.target.value)}
                            input={<CustomSelect />}
                            disabled={blurred}
                        >
                            {
                                renovationsOptions.map((item, index) =>
                                    <MenuItem 
                                        key={index}
                                        value={item.value}
                                        style={styles.menuItem}
                                        disabled={false}
                                    >
                                        {item.text}
                                    </MenuItem>	
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </div> */}
        </div>
    )
}

export default InitialCosts;