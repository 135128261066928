import React from 'react';
import '../styles/CustomSelector.css';

function CustomSelector(props) {
    const array = props.array;
    const selectOption = props.selectOption;
    const property = props.property;
    return (
        <div className="custom-selector-outer-container bg-colour-white">
            {
                array.map((item, index) =>
                    <div 
                        className="custom-selector-element"
                        key={index}
                        onClick={() => selectOption(index, property)}
                    >
                        <span className="body-regular colour-primary">
                            {item.label}
                        </span>
                    </div>
                )
            }
        </div>
    )
};

export default CustomSelector;