import React from 'react';
import '../styles/PriceTab.css';

function PriceTab(props) {
    const text = props.text;
    const bgColour = props.bgColour;
    const textColour = props.textColour;
    return (
        <div className={"price-tab-outer-container " + (bgColour)}>
            <div className="price-tab-inner-container">
                <span className={"label-semibold " + (textColour)}>
                    {text}
                </span>
            </div>
        </div>
    )
};

export default PriceTab;