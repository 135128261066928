import React, { useState } from 'react';
import '../styles/EditProfileModal.css';
import { Exit } from '../assets';
import { ActionButton, FormInput, WhiteActionButton } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, setMergeData } from '../functions';
import { UploadImage } from '../components';

function EditProfileModal(props) {
    const partnerData = props.partnerData;
    const closeModal = props.closeModal;
    const setPartnerData = props.setPartnerData;
    const [firstName, setFirstName] = useState(partnerData.firstName);
    const [lastName, setLastName] = useState(partnerData.lastName);
    const [companyName, setCompanyName] = useState(partnerData.companyName);
    const [companyLogo, setCompanyLogo] = useState(partnerData.companyLogo);
    const [email, setEmail] = useState(partnerData.email);
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
    const [loading, setLoading] = useState(false);

    const saveCompanyLogo = async(uploadedFile) => {
        setCompanyLogo(uploadedFile.url);
    };

    const changePhoneNumber = (value) => {
        // return nothing if no value
		// if (!value) return value; 

		// only allows 0-9 inputs

		if ( value.length < phoneNumber.length ) {
            setPhoneNumber(value);
        }
        else {
			if (!value) setPhoneNumber(value); 

			const currentValue = value.replace(/[^\d]/g, '');
			const cvLength = currentValue.length; 
		
			if (!phoneNumber || value.length > phoneNumber.length) {
				if (cvLength < 4) setPhoneNumber(currentValue);
			
				if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
			
				setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
			}
		}
    };

    const checkDisabled = () => {
        if ( firstName === "" || lastName === "" || email === "" || phoneNumber === "" || loading === true ) {
            return true;
        }
        else {
            return false;
        }
    };

    const complete = async() => {
        setLoading(true);
        const unformattedPhoneNumber = phoneNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const colRef = "Partners";
            const docRef = user.userId;
            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: unformattedPhoneNumber,
                companyName: companyName,
                profileImage: partnerData.profileImage,
                companyLogo: companyLogo,
                listings: partnerData.listings
            };
            await setMergeData(colRef, docRef, data);
            setPartnerData(data);
            closeModal();
        }
        setLoading(false);
    };
    
    return (
        <div className="edit-profile-modal-outer-container bg-colour-light">
            <div className="edit-profile-modal-inner-container">
                <div className="edit-profile-modal-exit-container">
                    <img
                        src={Exit}
                        className="edit-profile-modal-exit"
                        alt="Close Modal"
                        onClick={() => closeModal()}
                    />
                </div>
                <div className="edit-profile-modal-title-container margin-x-large">
                    <h2 className="heading-large-semibold colour-primary margin-medium">
                        Edit profile details
                    </h2>
                    <span className="body-regular colour-secondary">
                        Change your public profile details.
                    </span>
                </div>
                <div className="edit-profile-modal-section-container edit-profile-modal-section-border">
                    <h3 className="body-semibold colour-primary margin-medium">
                        Personal details
                    </h3>
                    <div className="edit-profile-modal-section-row margin-x-large">
                        <div className="edit-profile-modal-input-element">
                            <FormInput
                                value={firstName}
                                type="text"
                                label="First name*"
                                id="first-name-input"
                                placeholder="First Name"
                                onChange={(text) => setFirstName(text.target.value)}
                            />
                        </div>
                        <div className="edit-profile-modal-input-element">
                            <FormInput
                                value={lastName}
                                type="text"
                                label="Last name*"
                                id="last-name-input"
                                placeholder="Last Name"
                                onChange={(text) => setLastName(text.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="edit-profile-modal-section-container edit-profile-modal-section-border">
                    <h3 className="body-semibold colour-primary margin-medium margin-top-x-x-large">
                        Company details
                    </h3>
                    <div className="edit-profile-modal-input-element margin-x-large">
                        <FormInput
                            value={companyName}
                            type="text"
                            label="Company"
                            id="company-name-input"
                            placeholder="Company"
                            onChange={(text) => setCompanyName(text.target.value)}
                        />
                    </div>
                    <h3 className="body-semibold colour-primary margin-medium">
                        Company image
                    </h3>
                    <div className="margin-x-large">
                        <UploadImage
                            saveFile={saveCompanyLogo}
                            uploadedImage={companyLogo}
                            route="Company Logo"
                            subText={`${companyLogo === "" ? "Add" : "Change"} company image or logo`}
                            showIcon={true}
                        />
                    </div>
                </div>
                <div className="edit-profile-modal-section-container edit-profile-modal-section-border">
                    <h3 className="body-semibold colour-primary margin-medium margin-top-x-x-large">
                        Contact info
                    </h3>
                    <div className="edit-profile-modal-section-row margin-x-large">
                        <div className="edit-profile-modal-input-element">
                            <FormInput
                                value={email}
                                type="text"
                                label="Email*"
                                id="email-input"
                                placeholder="email@email.com"
                                onChange={(text) => setEmail(text.target.value)}
                            />
                        </div>
                        <div className="edit-profile-modal-input-element">
                            <FormInput
                                value={phoneNumber}
                                type="text"
                                label="Phone*"
                                id="phone-number-input"
                                placeholder="(555) 123-4567"
                                onChange={(text) => changePhoneNumber(text.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="edit-profile-modal-buttons-container">
                    <div className="edit-profile-modal-cancel-button-container subtext-margin-right-x-large">
                        <WhiteActionButton
                            disabled={loading}
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="edit-profile-modal-save-button-container">
                        <ActionButton
                            disabled={checkDisabled()}
                            onClick={() => complete()}
                        >
                            Save
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfileModal;