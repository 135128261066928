import React, { useEffect, useState } from 'react';
import '../styles/PullRecords.css';
import { Header, Footer, Loading } from '../components';
import { counties } from '../counties';
import { Exit, Search } from '../assets';
import { CustomSelect, FilterCityInput, colour, bedroomOptions, bathroomOptions, squareFootageRange, budgetOptions, yearsOwnedOptions, formatterCompact, ActionButton, numberFormatter, CustomRadio, formatterLong } from '../styles/GlobalStyles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { checkOnAuthStateChanged, formatCityNames, queryOffMarketData, recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';

function PullRecords() {

    const [county, setCounty] = useState("");
    const [countyOptions, setCountyOptions] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState(null);
    // const [selectOpen, setSelectOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [propertyType, setPropertyType] = useState("SFR");
    const [bedrooms, setBedrooms] = useState(1);
    const [bathrooms, setBathrooms] = useState(1);
    const [squareFootage, setSquareFootage] = useState([0, 999999]);
    const [budgetRange, setBudgetRange] = useState([0, 1000000000]);
    const [yearsOwned, setYearsOwned] = useState([0, 100]);
    const [loading, setLoading] = useState(false);
    const [propertyCount, setPropertyCount] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    // const [filtersExpanded, setFiltersExpanded] = useState(true);

    const offMarketFilterLabels = [
        {
            label: "🫥 Absentee owner",
            value: "absentee-owner"
        },
        {
            label: "🪧 Auction",
            value: "auction"
        },
        {
            label: "🧾 Free & Clear",
            value: "free-clear"
        },
        {
            label: "🐷🏦 High Equity",
            value: "high-equity"
        },
        {
            label: "🫴 Inherited",
            value: "inherited"
        },
        {
            label: "📉 Pre-Foreclosure",
            value: "pre-foreclosure"
        },
        {
            label: "🕊️ Recent death",
            value: "recent-death"
        },
        {
            label: "🗂️🤝 Tax Lien",
            value: "tax-lien"
        },
        {
            label: "🏚️ Vacant",
            value: "vacant"
        }
    ];

    const propertyTypes = [
        {
            label: "Single family residence",
            value: "SFR"
        },
        {
            label: "Multi family residence",
            value: "MFR"
        },
        {
            label: "Land",
            value: "LAND"
        },
        {
            label: "Condo",
            value: "CONDO"
        },
        {
            label: "Mobile home",
            value: "MOBILE"
        }
    ];

    const pricingPlans = [
        // {
        //     title: "Starter",
        //     price: "$0.10 per record",
        //     description: "Property information + Zillow information",
        //     value: 0.1
        // },
        // {
        //     title: "Growth",
        //     price: "$0.15 per record",
        //     description: "Property information + Zillow information + Skip tracing",
        //     value: 0.15
        // },
        // {
        //     title: "Premium",
        //     price: "$0.25 per record",
        //     description: "Property information + Zillow information + Skip tracing + Comps (rental + sales comps only for Single Family Homes)",
        //     value: 0.25
        // }

        {
            title: "Starter",
            price: "$0.10 per record",
            description: "Property & Owner information + Zillow estimate values",
            value: 0.1
        },
        {
            title: "Premium",
            price: "$0.20 per record",
            description: "Property & Owner information + Zillow estimates + Comps (rental + sales comps only for Single Family Homes)",
            value: 0.2
        }
    ];

    useEffect(() => {
        document.title = "Off-Market Records";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                setUserId(docRef);
            }
        }

        fetchUserData();
        
    }, []);

    const changeCounty = (val) => {
        setCounty(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };
        setCountyOptions(newArray);
    };

    const resetCounty = () => {
        setCounty("");
    };

    const selectCounty = (item) => {
        const newObject = {
            county: item.county,
            state: item.state
        };
        setSelectedCounty(newObject);
        setCounty("");
    };

    const queryRecords = async() => {
        setLoading(true);
        // setFiltersExpanded(false);
        window.scrollTo(0, 0);
        const reApiParams = {
             // does not change
            ids_only: false,
            obfuscate: false,
            summary: false,
            
            // backend page handling and results
            count: true, // changes to false after payment to get records
            size: 250,
            resultIndex: 0,
            
            // user filters
            // location
            state: selectedCounty.state,
            county: selectedCounty.county,
            property_type: propertyType,
            baths_min: Number(bedrooms),
            baths_max: 20,
            beds_min: Number(bathrooms),
            beds_max: 20,
            assessed_value_min: Number(budgetRange[0]),
            assessed_value_max: Number(budgetRange[1]),
            years_owned_min: Number(yearsOwned[0]),
            years_owned_max: Number(yearsOwned[1]),
            building_size_min: Number(squareFootage[0]),
            building_size_max: Number(squareFootage[1]),
            
            // our filters that don't change
            mls_active: false,
        };

        // lists
        if ( selectedFilters.includes("absentee-owner") ) {
            reApiParams.absentee_owner = true;
        }

        if ( selectedFilters.includes("auction") ) {
            reApiParams.auction = true;
        }

        if ( selectedFilters.includes("free-clear") ) {
            reApiParams.free_clear = true;
        }

        if ( selectedFilters.includes("high-equity") ) {
            reApiParams.high_equity = true;
        }

        if ( selectedFilters.includes("inherited") ) {
            reApiParams.inherited = true;
        }

        if ( selectedFilters.includes("pre-foreclosure") ) {
            reApiParams.pre_foreclosure = true;
        }

        if ( selectedFilters.includes("recent-death") ) {
            reApiParams.death = true;
        }

        if ( selectedFilters.includes("tax-lien") ) {
            reApiParams.tax_lien = true;
        }

        if ( selectedFilters.includes("vacant") ) {
            reApiParams.vacant = true;
        }

        const params = {
            reAPIParams: reApiParams,
            reAPIEndpoint: "PropertySearch",
            skipTrace: false,
            comps: false,
            compCount: 10,
            total: 0,
            plan: 0,
            totalRecords: 0,
            county: formatCityNames(selectedCounty)
        };
        
        const queryData = await queryOffMarketData(params);
        if ( queryData.status === 200 ) {
            const data = queryData.data;
            if ( data.statusMessage === "Not Found" ) {
                alert("No properties found 😔 try broadening your search");
            }
            else if ( data.resultCount > 10000 ) {
                alert(`This returned ${numberFormatter.format(data.resultCount)} records, which is too many to display. Please refine your search to under 10,000 records!`);
            }
            else {
                setPropertyCount(data.resultCount);
            }
        }
        else {
            alert("No properties found 😔 try broadening your search");
        }
        setLoading(false);
    };

    const changeFilters = (vals) => {
        const newArray = [...selectedFilters];
		const index = newArray.indexOf(vals.value);
		if ( index === -1 ) {
			newArray.push(vals.value);
		}
		else {
			newArray.splice(index, 1);
		}
		setSelectedFilters(newArray);
    };

    const filterSelected = (val) => {
        const index = selectedFilters.indexOf(val);
		if ( index === -1 ) {
			return false;
		}
		else {
			return true;
		}
    };

    // const filterLabel = (val) => {
    //     const getObject = offMarketFilterLabels.findIndex(e => e.value === val);
    //     const object = offMarketFilterLabels[getObject];
    //     return object.label;
    // };

    const changeBudgetRange = (val) => {
        setBudgetRange(val);
    };

    const changeYearsOwned = (val) => {
        setYearsOwned(val);
    };

    const editFilters = () => {
        recordEvent("Edit Filters", {});
        setPropertyCount(null);
    };

    const continueToCheckout = () => {
        const reApiParams = {
            // does not change
           ids_only: false,
           obfuscate: false,
           summary: false,
           
           // backend page handling and results
           count: false, // changes to false after payment to get records
           size: 250,
           resultIndex: 0, // changes after payment to get each page of records
           
           // user filters
           // location
           state: selectedCounty.state,
           county: selectedCounty.county,
           property_type: propertyType,
           baths_min: Number(bedrooms),
           baths_max: 20,
           beds_min: Number(bathrooms),
           beds_max: 20,
           assessed_value_min: Number(budgetRange[0]),
           assessed_value_max: Number(budgetRange[1]),
           years_owned_min: Number(yearsOwned[0]),
           years_owned_max: Number(yearsOwned[1]),
           building_size_min: Number(squareFootage[0]),
           building_size_max: Number(squareFootage[1]),
           
           // our filters that don't change
           mls_active: false
       };

       // lists
       if ( selectedFilters.includes("absentee-owner") ) {
            reApiParams.absentee_owner = true;
        }

        if ( selectedFilters.includes("auction") ) {
            reApiParams.auction = true;
        }

        if ( selectedFilters.includes("free-clear") ) {
            reApiParams.free_clear = true;
        }

        if ( selectedFilters.includes("high-equity") ) {
            reApiParams.high_equity = true;
        }

        if ( selectedFilters.includes("inherited") ) {
            reApiParams.inherited = true;
        }

        if ( selectedFilters.includes("pre-foreclosure") ) {
            reApiParams.pre_foreclosure = true;
        }

        if ( selectedFilters.includes("recent-death") ) {
            reApiParams.death = true;
        }

        if ( selectedFilters.includes("tax-lien") ) {
            reApiParams.tax_lien = true;
        }

        if ( selectedFilters.includes("vacant") ) {
            reApiParams.vacant = true;
        }

       const params = {
           reAPIParams: reApiParams,
           reAPIEndpoint: "PropertySearch",
           skipTrace: false,//selectedPlan > 0 ? true : false,
           comps: selectedPlan === 1 ? true : false,//selectedPlan === 2 ? true : false,
           compCount: 10,
           total: pricingPlans[selectedPlan].value * propertyCount,
           plan: pricingPlans[selectedPlan],
           totalRecords: propertyCount,
           county: formatCityNames(selectedCounty)
       };

        if ( userId !== null ) {
            navigate("/pull-records-pay", {
                state: {
                    records: params
                }
            });
        }
        else {
            navigate("/buy-sign-up", {
                state: {
                    records: params,
                    route: "/pull-records-pay"
                }
            });
        }
    };

    const openSummary = () => {
        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Real-Estate-Off-Market-Leads-663ff053849e413aa32407679143f927", "_blank");
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
        accordionSummary: {
            marginLeft: '0px',
            maxWidth: '100px'
        }
    };

    return (
        <div className="pull-records-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="pull-records-inner-container relative-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="pull-records-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                        Off-Market Records
                    </h1>
                    <h2 className="body-regular colour-secondary text-align-center">
                        Choose your filters to get off market records.
                        <br/>
                        Pricing will be calculated by record after filters are applied
                    </h2>
                </div>
                <div className="pull-records-body-container">
                    {/* <Accordion 
                        expanded={filtersExpanded} 
                        onChange={() => setFiltersExpanded(!filtersExpanded)}
                        sx={{ boxShadow: 'none' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`pull-records-bh-content`}
                            id={`pull-records-bh-header`}
                            sx={styles.accordionSummary}
                        >
                            <Typography
                                sx={styles.typography}
                            >
                                <span className="body-regular colour-primary">
                                    Filters
                                </span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            
                        </AccordionDetails>
                    </Accordion> */}
                    {
                        propertyCount === null ?
                        <div className="pull-records-filters-outer-container">
                            <div className="pull-records-filter-container">
                                <div className="pull-records-filter-element">
                                    <div className="pull-records-filter-title">
                                        <h3 className="body-semibold colour-primary margin-x-small">
                                            Choose your county
                                        </h3>
                                    </div>
                                    {
                                        selectedCounty !== null ?
                                        <div className="pull-records-selected-county-container margin-medium">
                                            <span className="body-regular colour-primary block-text subtext-margin-right-large">
                                                {formatCityNames(selectedCounty)}
                                            </span>
                                            <span 
                                                className="body-regular colour-link"
                                                onClick={() => setSelectedCounty(null)}
                                            >
                                                Edit
                                            </span>
                                        </div>
                                        :
                                        <div className="pull-records-inner-input-container relative-container margin-x-large">
                                            <FilterCityInput
                                                value={county}
                                                type="text"
                                                id="county-input"
                                                placeholder="Search for any county in the U.S..."
                                                onChange={(text) => changeCounty(text.target.value)}
                                                disabled={false}
                                            />
                                            <img
                                                src={county === "" ? Search : Exit}
                                                className={county === "" ? "pull-records-search-icon" : "pull-records-exit-icon"}
                                                alt="Search"
                                                onClick={() => county === "" ? null : resetCounty()}
                                            />
                                            {
                                                countyOptions.length > 0 && county !== "" ?
                                                    <div className="city-filtering-outer-container">
                                                        {
                                                            countyOptions.map((item, index) =>
                                                                <div 
                                                                    className="city-filtering-element"
                                                                    key={index}
                                                                    onClick={() => selectCounty(item)}
                                                                >
                                                                    <span className="body-regular colour-primary">
                                                                        {formatCityNames(item)}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="pull-records-filter-container margin-x-large">
                                <div className="margin-medium">
                                    <div className="pull-records-filter-title">
                                        <h3 className="body-semibold colour-primary margin-x-small">
                                            Choose your filters
                                        </h3>
                                    </div>
                                    <div className="pull-records-grid-container">
                                        {
                                            offMarketFilterLabels.map((item, index) =>
                                                <div 
                                                    className="sign-up-component-how-did-you-hear-row cursor-pointer"
                                                    key={index}
                                                    onClick={() => changeFilters(item)}
                                                >

                                                    <div className="checkbox-container">
                                                        {
                                                            filterSelected(item.value) === true ?
                                                            <div className="checkbox-icon">
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <span className="body-regular colour-primary">
                                                        {item.label}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {/* <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="off-market-filters-select-label"
                                                id="off-market-filters-select"
                                                value={selectedFilters.length === 0 ? ["Selected filters (0)"] : selectedFilters}
                                                label=""
                                                // renderValue={(selected) => selected.join(", ")}
                                                renderValue={(selected) => selectedFilters.length === 0 ? "Selected filters (0)" : `Selected filters (${selected.length})`}
                                                input={<CustomSelect />}
                                                multiple
                                                open={selectOpen}
                                                onOpen={() => setSelectOpen(true)}
                                                onClose={() => setSelectOpen(false)}
                                                style={{ color: selectedFilters.length === 0 ? colour.textTertiary : colour.textPrimary }}
                                            > 
                                                {
                                                    offMarketFilterLabels.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            onClick={() => changeFilters(item)}
                                                        >
                                                            <div className="sign-up-component-how-did-you-hear-row">
                                                                <div className="checkbox-container">
                                                                    {
                                                                        filterSelected(item.value) === true ?
                                                                        <div className="checkbox-icon">
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <span className="body-regular colour-primary">
                                                                    {item.label}
                                                                </span>
                                                            </div>
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box> */}
                                </div>
                                {/* <div className="pull-records-filters-row">
                                    {
                                        selectedFilters.map((item, index) =>
                                            <div
                                                className="pull-records-filter-type-element bg-colour-medium"
                                                key={index}
                                            >
                                                <span className="body-regular colour-primary">
                                                    {filterLabel(item)}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div> */}
                            </div>
                            <div className="pull-records-filter-container margin-x-large">
                                <div className="pull-records-filter-title">
                                    <h3 className="body-semibold colour-primary margin-x-small">
                                        Property type
                                    </h3>
                                </div>
                                <Box>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="off-market-property-type-select-label"
                                            id="off-market-property-type-select"
                                            value={propertyType}
                                            label=""
                                            input={<CustomSelect />}
                                            onChange={(e) => setPropertyType(e.target.value)}
                                        > 
                                            {
                                                propertyTypes.map((item, index) =>
                                                    <MenuItem 
                                                        key={index}
                                                        value={item.value}
                                                        style={styles.menuItem}
                                                    >
                                                    <span className="body-regular colour-primary">
                                                            {item.label}
                                                    </span>
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="pull-records-filter-row-container">
                                <div className="pull-records-filter-container margin-x-large subtext-margin-right-large">
                                    <div className="pull-records-filter-title">
                                        <h3 className="body-semibold colour-primary margin-x-small">
                                            Beds
                                        </h3>
                                    </div>
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="off-market-property-type-select-label"
                                                id="off-market-property-type-select"
                                                value={bedrooms}
                                                label=""
                                                input={<CustomSelect />}
                                                onChange={(e) => setBedrooms(e.target.value)}
                                            > 
                                                {
                                                    bedroomOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                        >
                                                        <span className="body-regular colour-primary">
                                                            {item.label}
                                                        </span>
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="pull-records-filter-container margin-x-large">
                                    <div className="pull-records-filter-title">
                                        <h3 className="body-semibold colour-primary margin-x-small">
                                            Baths
                                        </h3>
                                    </div>
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="off-market-property-type-select-label"
                                                id="off-market-property-type-select"
                                                value={bathrooms}
                                                label=""
                                                input={<CustomSelect />}
                                                onChange={(e) => setBathrooms(e.target.value)}
                                            > 
                                                {
                                                    bathroomOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                        >
                                                        <span className="body-regular colour-primary">
                                                            {item.label}
                                                        </span>
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            <div className="pull-records-filter-title margin-x-small">
                                <span className="body-semibold colour-primary">
                                    Square footage
                                </span>
                            </div>
                            <div className="pull-records-filter-row-container margin-x-large">
                                <div className="pull-records-filter-container subtext-margin-right-large">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="min-sq-footage-label"
                                                id="min-sq-footage-select"
                                                value={squareFootage[0]}
                                                label=""
                                                onChange={(event) => setSquareFootage([event.target.value, squareFootage[1]])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    squareFootageRange.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={squareFootage[1] <= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="pull-records-filter-container">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="max-sq-footage-label"
                                                id="max-sq-footage-select"
                                                value={squareFootage[1]}
                                                label=""
                                                onChange={(event) => setSquareFootage([squareFootage[0], event.target.value])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    squareFootageRange.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={squareFootage[0] >= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            <div className="pull-records-filter-title margin-x-small">
                                <span className="body-semibold colour-primary">
                                    Assessed value
                                </span>
                            </div>
                            <div className="pull-records-filter-row-container margin-x-large">
                                <div className="pull-records-filter-container subtext-margin-right-large">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="min-select-label"
                                                id="min-budget-select"
                                                value={budgetRange[0]}
                                                label=""
                                                onChange={(event) => changeBudgetRange([event.target.value, budgetRange[1]])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    budgetOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={budgetRange[1] <= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 1000000000 ? item.label : formatterCompact.format(item.value)}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="pull-records-filter-container">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="min-select-label"
                                                id="min-budget-select"
                                                value={budgetRange[1]}
                                                label=""
                                                onChange={(event) => changeBudgetRange([budgetRange[0], event.target.value])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    budgetOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={budgetRange[0] >= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 1000000000 ? item.label : formatterCompact.format(item.value)}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            <div className="pull-records-filter-title margin-x-small">
                                <span className="body-semibold colour-primary">
                                    Years of ownership
                                </span>
                            </div>
                            <div className="pull-records-filter-row-container margin-x-large">
                                <div className="pull-records-filter-container subtext-margin-right-large">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="min-select-label"
                                                id="min-years-owned-select"
                                                value={yearsOwned[0]}
                                                label=""
                                                onChange={(event) => changeYearsOwned([event.target.value, yearsOwned[1]])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    yearsOwnedOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={yearsOwned[1] <= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 100 ? item.label : item.value}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="pull-records-filter-container">
                                    <Box style={styles.fullWidth}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="max-select-label"
                                                id="max-years-owned-select"
                                                value={yearsOwned[1]}
                                                label=""
                                                onChange={(event) => changeYearsOwned([yearsOwned[0], event.target.value])}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    yearsOwnedOptions.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={item.value}
                                                            style={styles.menuItem}
                                                            disabled={yearsOwned[0] >= item.value ? true : false}
                                                        >
                                                            {item.value === 0 || item.value === 100 ? item.label : item.value}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="pull-records-pricing-options-container margin-x-large">
                            <div className="pull-record-result-count-finished margin-x-large">
                                <div className="pull-record-result-count-inner-container">
                                    <span className="heading-small-semibold colour-primary block-text text-align-left">
                                        {numberFormatter.format(propertyCount)} record{propertyCount === 1 ? "" : "s"} found
                                    </span>
                                </div>
                            </div>
                            <FormControl>
								<RadioGroup
									aria-labelledby="payment-radio-buttons-group-label"
									name="radio-buttons-group"
									value={selectedPlan}
									onChange={(val) => setSelectedPlan(Number(val.target.value))}
								>
                                    {
                                        pricingPlans.map((item, index) =>
                                            index === 1 && propertyType !== "SFR" ?
                                            null
                                            :
                                            <FormControlLabel
                                                value={index} 
                                                control={<CustomRadio />} 
                                                className="pull-records-payment-radio-element"
                                                key={index}
                                                label={ <div 
                                                    className="pull-records-pricing-element margin-medium"
                                                >
                                                    <span className="body-semibold colour-primary block-text margin-x-small">
                                                        {item.title} ({item.price})
                                                    </span>
                                                    <span className="body-regular colour-primary">
                                                        {item.description}
                                                    </span>
                                                </div>}
                                            />
                                        )
                                    }
									
                                </RadioGroup>
                            </FormControl>
                            <div 
                                className="unassigned-counties-badge-container pull-records-skip-trace-badge margin-x-large"
                                onClick={() => openSummary()}
                            >
                                <span className="body-semibold colour-primary">
                                    Skip tracing coming soon
                                </span>
                            </div>
                            <div 
                                className=""
                                onClick={() => openSummary()}
                            >
                                <span className="body-regular colour-regular underline cursor-pointer">
                                    Here is a summary of what is included
                                </span>
                            </div>
                        </div>
                    }
                    {
                        loading === true ?
                        <div className="pull-record-result-count margin-x-small">
                            <span className="body-regular colour-primary block-text text-align-center">
                                Searching..
                            </span>
                        </div>
                        :
                        propertyCount === null ?
                        <div className="pull-record-result-count margin-x-small">
                            <span className="body-regular colour-primary block-text text-align-center">
                                Search for some records
                            </span>
                        </div>
                        :
                        null
                    }
                    {
                        propertyCount === null ?
                        <div className="pull-records-button-container">
                            <ActionButton
                                disabled={selectedCounty === null ? true : loading}
                                onClick={() => queryRecords()}
                            >
                                Get Records
                            </ActionButton>
                        </div>
                        :
                        <div className="pull-records-selected-button-row">
                            <div 
                                className="pull-records-edit-container text-button"
                                onClick={() => editFilters()}
                            >
                                <span className="body-regular text-link">
                                    {"<"} Edit filters
                                </span>
                            </div>
                            <div className="pull-records-checkout-button-container">
                                {
                                    selectedPlan === null || (selectedPlan !== null && pricingPlans[selectedPlan].value * propertyCount >= 0.5) ?
                                    <ActionButton
                                        disabled={selectedPlan === null ? true : false}
                                        onClick={() => continueToCheckout()}
                                    >
                                        Continue to checkout {selectedPlan === null ? null : `${formatterLong.format(pricingPlans[selectedPlan].value * propertyCount).replace(".00", "")}`}
                                    </ActionButton>
                                    :
                                    <span className="body-regular colour-error">
                                        Minimum order is $0.50
                                    </span>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PullRecords;