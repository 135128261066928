import { useEffect } from 'react';
import { getWhereDoc } from '../functions';
import { useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged } from '../functions';

function PartnerEffect(props) {
    const setPartnerData = props.setPartnerData;
    const partnerId = props.partnerId;
    const setPartnerView = props.setPartnerView;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPartner = async() => {
            const colRef = "Partners";
            const field = "data.id";
            const operator = "==";
            const queryDocument = await getWhereDoc(colRef, field, operator, partnerId);
            if ( queryDocument.status === 200 ) {
                const partnerData = queryDocument.data[0];
                partnerData.phoneNumber = await formatPhoneNumber(partnerData.phoneNumber);
                setPartnerData(partnerData);

                const user = await checkOnAuthStateChanged();
                if ( user.status === 200 ) {
                    const userId = user.userId;
                    if ( partnerData.userId === userId ) {
                        setPartnerView(true);
                    }
                }
            }
            else {
                navigate("/");
            }
        };

        const formatPhoneNumber = (value) => {
            if (!value) return value; 

            const currentValue = value.replace(/[^\d]/g, '');
            const cvLength = currentValue.length; 
        
            if (cvLength < 4) return currentValue;
        
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }

        fetchPartner();
    }, [navigate, partnerId, setPartnerData, setPartnerView]);
};

export default PartnerEffect;