import React from 'react';
import '../styles/UpgradeMetro.css';
import { WhiteActionButton } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function UpgradeMetro(props) {
    const setMetroUpgradeModal = props.setMetroUpgradeModal;
    const upgradeMetro = props.upgradeMetro;

    const upgrade = () => {
        setMetroUpgradeModal(true);
        recordEvent("Upgrade Metro Modal - Property Listings", {
            msaTitle: upgradeMetro.msaTitle
        });
    };

    return (
        <div className="upgrade-metro-container">
            <WhiteActionButton
                onClick={() => upgrade()}
            >
                🚀 Upgrade to County
            </WhiteActionButton>
        </div>
    )
};

export default UpgradeMetro;