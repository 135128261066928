import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

function MarketDataChart(props) {
    const label = props.label;
    const labels = props.selectedCityYears;
    const selectedCityData = props.selectedCityData;
    const fieldName = props.fieldName;
    const shortLabel = props.shortLabel;
    const cityData = selectedCityData.map(e => e[fieldName]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: label,
          }
        },
        // scales: {
        //     yAxes: {
        //         ticks: {
        //             beginAtZero: false,
        //             callback: function(value, index, values) {
        //                 return index === 0 ? formatterCompact.format(value) : value;
        //             }
        //         }
        //     }
        // }
    };
      
    const data = {
        labels,
        datasets: [
            {
                label: shortLabel,
                data: cityData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    return (
        <div className="market-data-chart-element">
            <Line
                data={data}
                options={options}
            />
        </div>
    );
};

export default MarketDataChart;