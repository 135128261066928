import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/CustomSwiper.css';
import { useNavigate } from 'react-router-dom';
// import HideButton from '../assets/hide-button.svg';
// import { hideProperty } from '../functions';

function CustomSwiper(props) {
	const images = props.images;
	const title = props.title;
	const Heart = props.Heart;
	const HeartActive = props.HeartActive;
	const favourites = props.favourites;
	const toggleFavourite = props.toggleFavourite;
	const favouriteDisable = props.favouriteDisable;
	const item = props.item;
	const setFavourites = props.setFavourites;
	const setFavouriteDisable = props.setFavouriteDisable;
	// const abstract = props.abstract;
	const viewProperty = props.viewProperty;
	const cityId = props.cityId;
	const setCardClick = props.setCardClick;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const propertySearch = props.propertySearch;
	const partner = props.partner;
	const imageLimit = props.imageLimit;
	const arrowClicked = props.arrowClicked;
	const swiperRef = props.swiperRef;
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	}
	const [elementHover, setElementHover] = useState(false);
	const zpidIndex = favourites.findIndex(e => e.zpid === item.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;

	const navigate = useNavigate();

	const showArrows = () => {
		if ( images.length > 1 ) {
			setElementHover(true)
		}
	};

	const hideArrows = () => {
		setElementHover(false)
	};

	return (
		images !== undefined ?
			<div 
				className={"custom-swiper-module-container " + (elementHover === true ? "custom-swiper-module-container-hover" : "")}
				onMouseEnter={() => showArrows()}
				onMouseLeave={() => hideArrows()}
			>
				{
					Heart !== undefined && propertySearch === false && partner === false && toggleFavourite !== null ?
					<div 
						onClick={() => temporaryId === true ? setSignUpModal(true) : setFavourites === null ? null : toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, setCardClick)}
						className="favourite-icon-container"
					>
						<img
							src={
								inFavourites === false ?
								Heart
								:
								HeartActive
							}
							className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
							alt="Add To Favourites"
						/>
					</div>
					:
					null
				}
				<Swiper 
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination, Navigation]} 
					className="custom-swiper-outer-container"
					loop={true}
					// slidesPerView={5}
					navigation={{
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next',
					}}
					ref={swiperRef}
				>
					<div className="swiper-button-prev" onClick={(e) => arrowClicked === null ? null : arrowClicked(e)}>
						<svg 
							xmlns="http://www.w3.org/2000/svg" 
							width="9" 
							height="8" 
							viewBox="0 0 9 8" 
							fill="none"
							className="custom-swiper-icon"
						>
							<path 
								d="M2.7592 0.243947C3.0723 -0.0813155 3.58721 -0.0813155 3.90032 0.243947L7.51595 3.99999L3.90032 7.75603C3.58721 8.08129 3.0723 8.08129 2.7592 7.75603C2.45527 7.44029 2.45527 6.93514 2.7592 6.6194L5.2807 3.99999L2.7592 1.38057C2.45527 1.06483 2.45527 0.559682 2.7592 0.243947Z"
								fill="#292621"
							/>
						</svg>
					</div>
      				<div className="swiper-button-next" onClick={(e) => arrowClicked === null ? null : arrowClicked(e)}>
					  	<svg 
							xmlns="http://www.w3.org/2000/svg" 
							width="9" 
							height="8" 
							viewBox="0 0 9 8" 
							fill="none"
							className="custom-swiper-icon"
						>
							<path 
								d="M2.7592 0.243947C3.0723 -0.0813155 3.58721 -0.0813155 3.90032 0.243947L7.51595 3.99999L3.90032 7.75603C3.58721 8.08129 3.0723 8.08129 2.7592 7.75603C2.45527 7.44029 2.45527 6.93514 2.7592 6.6194L5.2807 3.99999L2.7592 1.38057C2.45527 1.06483 2.45527 0.559682 2.7592 0.243947Z"
								fill="#292621"
							/>
						</svg>
					</div>
					{
						images.map((imageItem, index) =>
							imageItem.includes("maps.googleapis.com") === true ?
							null
							:
							(index + 1) <= imageLimit &&
							<SwiperSlide
								key={index}
							>
								<div 
									className="custom-swiper-image-container"
									onClick={(e) => viewProperty === null ? null : viewProperty(item.zpid, e, false)}
								>
									<img
										src={imageItem}
										className="custom-swiper-image"
										alt={imageItem.unlocked === true ? title : "random image"}
									/>
								</div>
							</SwiperSlide>
						)
					}
				</Swiper>
			</div>
		:
		null
	)
}

export default CustomSwiper;