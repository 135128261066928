import React, { useState } from 'react';
import '../styles/AddComparableModal.css';
import { Exit } from '../assets';
import { ActionButton, FinancialsInput, WhiteActionButton } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PlaceInput } from './';
import dayjs from 'dayjs';
import { calcCrow, containsNumbers, geocode } from '../functions';

function AddComparableModal(props) {
    const selectedPanel = props.selectedPanel;
    const setAddComparableModal = props.setAddComparableModal;
    const property = props.property;
    const setProperty = props.setProperty;
    const comps = props.comps;
    const setComps = props.setComps;
    const setSelectedPanel = props.setSelectedPanel;
    const [address, setAddress] = useState("");
    const [addressObject, setAddressObject] = useState(null);
    const [localLocation, setLocalLocation] = useState("");
    const [price, setPrice] = useState("");
    const [status, setStatus] = useState("");
    const [sqFootage, setSqFootage] = useState("");
    const [bedrooms, setBedrooms] = useState("");
    const [bathrooms, setBathrooms] = useState("");
    const [distance, setDistance] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [rent, setRent] = useState("");
    const [yearBuilt, setYearBuilt] = useState("");
    const [date, setDate] = useState(dayjs('2023-04-17'));
    const [zillowURL, setZillowURL] = useState("");
    const today = dayjs();
    const minDate = dayjs('2000-01-01');

    const closeModal = () => {
        setAddComparableModal(false);
    };

    const checkDisabled = () => {
        if ( 
            address === "" ||
            (status === "" && selectedPanel === 1) ||
            sqFootage === "" ||
            bedrooms === "" ||
            bathrooms === "" ||
            distance === "" ||
            propertyType === "" ||
            yearBuilt === "" ||
            date === "" ||
            (selectedPanel === 0 && rent === "") ||
            (selectedPanel === 1 && price === "")
        ) {
            return true;
        }
        else if ( 
            Number(bedrooms) === 0 || isNaN(Number(bedrooms)) ||
            Number(bathrooms) === 0 || isNaN(Number(bathrooms)) ||
            Number(distance) === 0 || isNaN(Number(distance)) ||
            Number(yearBuilt) === 0 || isNaN(Number(yearBuilt)) ||
            Number(sqFootage) === 0 || isNaN(Number(sqFootage))
        ) {
            return true;
        }
        else {
            return false;
        }
    };

    const changeLocation = (val) => {
        setLocalLocation(val);
    };

    const selectedLocation = async(place) => {
        if ( place !== null && place.label !== undefined ) {
            const getGeocode = await geocode(place.label);
            const addressElements = place.value.terms;
            const firstElement = addressElements.length !== 0 ? addressElements[0].value : "";
            const firstWord = firstElement.replace(/ .*/,'');
            const checkNumber = await containsNumbers(firstWord);
            if ( checkNumber === true ) {
                setAddress(place.label);

                if ( addressElements.length >=4 ) {
                    const streetAddress = `${addressElements[0].value} ${addressElements[1].value}`;
                    const city = `${addressElements[2].value}`;
                    const state = `${addressElements[3].value}`;
                    const newObj = {
                        streetAddress: streetAddress,
                        city: city,
                        state: state,
                        zipcode: ""
                    }

                    if ( getGeocode.status === 200 ) {
                        newObj.latitude = getGeocode.data[0];
                        newObj.longitude = getGeocode.data[1];
                        
                        if ( property.latitude === undefined ) {
                            const subjectPropertyAddress = `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`;
                            const propertyGeocode = await geocode(subjectPropertyAddress);
                            if ( propertyGeocode.status === 200 ) {
                                property.latitude = propertyGeocode.data[0];
                                property.longitude = propertyGeocode.data[1];

                                const propertyClone = structuredClone(property);
                                setProperty(propertyClone);
                            }
                        }
                        
                        const kmDistance = await calcCrow(property, newObj);
						const mileDistance = (kmDistance / 1.609).toFixed(2);
                        setDistance(mileDistance);
                    }
                    setAddressObject(newObj);
                }
            }
        }
    };

    const complete = async() => {
        const compsClone = comps === null ? [] : [...comps];
        const newComp = {
            activityDate: dayjs(date).format("YYYY-MM-DD"),
            address: addressObject,
            bathrooms: Number(bathrooms),
            bedrooms: Number(bedrooms),
            distance: Number(distance),
            fullAddress: address,
            imgSrc: "",
            latitude: addressObject.latitude !== undefined ? addressObject.latitude : 0,
            listingStatus: status,
            livingArea: Number(sqFootage),
            longitude: addressObject.longitude !== undefined ? addressObject.longitude : 0,
            lotAreaUnit: "sqft",
            lotAreaValue: 0,
            prediction_label: 0,
            price: selectedPanel === 0 ? Number(rent) : Number(price),
            pricePerSquareFoot: Number(price) / Number(sqFootage),
            propertyType: propertyType,
            rentZestimate: 0,
            similarity_rank: 1,
            similarity_score: 0,
            squareFootage: Number(sqFootage),
            subjProp: false,
            userInputted: true,
            yearBuilt: Number(yearBuilt),
            zestimate: 0,
            zillowURL: zillowURL,
            zpid: ""
        };
        compsClone.unshift(newComp);
        setComps(compsClone);
        
        if ( selectedPanel === 0 ) {
            const propertyClone = {...property, rentComps: compsClone};
            setProperty(propertyClone);
        }
        setAddComparableModal(false);
        const correctPanel = selectedPanel === 0 ? 0 : 1;
        const oppositePanel = selectedPanel === 0 ? 1 : 0;
        setSelectedPanel(oppositePanel);
        setSelectedPanel(correctPanel);
    };

    return (
        <div className="add-comparable-modal-outer-container bg-colour-light">
            <div className="add-comparable-modal-inner-container">
                <div 
                    className="add-comparable-modal-exit-container"
                    onClick={() => closeModal()}
                >
                    <img
                        src={Exit}
                        className="add-comparable-modal-exit-icon cursor-pointer"
                        alt="Exit"
                    />
                </div>
                <div className="add-comparable-modal-title-container margin-large">
                    <h3 className="heading-small-semibold colour-primary">
                        Add {selectedPanel === 0 ? "rental" : "sales"} comparable
                    </h3>
                </div>
                <div className="add-comparable-modal-body-container">
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Address
                        </h4>
                        <PlaceInput
                            placeholder="Enter address"
                            localLocation={localLocation}
                            changeLocation={changeLocation}
                            selectedLocation={selectedLocation}
                            types={['address']}
                            disabled={false}
                        />
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Distance to subject property
                        </h4>
                        <FinancialsInput
                            value={distance}
                            type="text"
                            id={`comps-distance-input`}
                            placeholder="0.25"
                            inputProps={{
                                // maxLength: 4
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mi</InputAdornment>,
                            }}
                            onChange={(text) => setDistance(text.target.value)}
                        />
                    </div>
                    {
                        selectedPanel === 0 ?
                        <div className="add-comparable-modal-element-container margin-medium">
                            <h4 className="body-semibold colour-primary margin-x-small">
                                Rent
                            </h4>
                            <FinancialsInput
                                value={rent}
                                type="number"
                                id={`comp-rent-input`}
                                placeholder="2,500"
                                inputProps={{
                                    // maxLength: 4
                                }}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(text) => setRent(text.target.value)}
                            />
                        </div>
                        :
                        <div className="add-comparable-modal-element-container margin-medium">
                            <h4 className="body-semibold colour-primary margin-x-small">
                                Price
                            </h4>
                            <FinancialsInput
                                value={price}
                                type="number"
                                id={`comp-price-input`}
                                placeholder="250,000"
                                inputProps={{
                                    // maxLength: 4
                                }}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(text) => setPrice(text.target.value)}
                            />
                        </div>
                    }
                    {
                        selectedPanel === 1 ?
                        <div className="add-comparable-modal-element-container margin-medium">
                            <h4 className="body-semibold colour-primary margin-x-small">
                                Status
                            </h4>
                            <FinancialsInput
                                value={status}
                                type="text"
                                id={`comp-status-input`}
                                placeholder="For sale | Pending | Sold"
                                inputProps={{
                                    // maxLength: 4
                                }}
                                onChange={(text) => setStatus(text.target.value)}
                            />
                        </div>
                        :
                        null
                    }
                    <div className="add-comparable-modal-element-container add-comparable-modal-date-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            {
                                selectedPanel === 0 ?
                                "Listed for rent date"
                                :
                                "Listed or sold date"
                            }
                        </h4>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                        >
                            <DatePicker 
                                value={date} 
                                onChange={(val) => setDate(val)}
                                minDate={minDate}
                                maxDate={today}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Square Footage
                        </h4>
                        <FinancialsInput
                            value={sqFootage}
                            type="text"
                            id={`comps-sq-footage-input`}
                            placeholder="2,500"
                            inputProps={{
                                // maxLength: 4
                            }}
                            onChange={(text) => setSqFootage(text.target.value)}
                        />
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Beds
                        </h4>
                        <FinancialsInput
                            value={bedrooms}
                            type="text"
                            id={`comps-beds-input`}
                            placeholder="2"
                            inputProps={{
                                // maxLength: 4
                            }}
                            onChange={(text) => setBedrooms(text.target.value)}
                        />
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Baths
                        </h4>
                        <FinancialsInput
                            value={bathrooms}
                            type="text"
                            id={`comps-baths-input`}
                            placeholder="2"
                            inputProps={{
                                // maxLength: 4
                            }}
                            onChange={(text) => setBathrooms(text.target.value)}
                        />
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Year built
                        </h4>
                        <FinancialsInput
                            value={yearBuilt}
                            type="text"
                            id={`comps-year-built-input`}
                            placeholder="1980"
                            inputProps={{
                                maxLength: 4
                            }}
                            onChange={(text) => setYearBuilt(text.target.value)}
                        />
                    </div>
                    <div className="add-comparable-modal-element-container margin-medium">
                        <h4 className="body-semibold colour-primary margin-x-small">
                            Property type
                        </h4>
                        <FinancialsInput
                            value={propertyType}
                            type="text"
                            id={`comps-property-type-input`}
                            placeholder="Single Family | Multi Family"
                            inputProps={{
                                // maxLength: 4
                            }}
                            onChange={(text) => setPropertyType(text.target.value)}
                        />
                    </div>
                    {
                        selectedPanel === 0 ?
                        null
                        :
                        <div className="add-comparable-modal-element-container margin-medium">
                            <h4 className="body-semibold colour-primary margin-x-small">
                                Zillow/Redfin/Realtor URL
                            </h4>
                            <FinancialsInput
                                value={zillowURL}
                                type="text"
                                id={`comps-zillow-url-input`}
                                placeholder="https://zillow.com/123-main-street"
                                inputProps={{
                                    // maxLength: 4
                                }}
                                onChange={(text) => setZillowURL(text.target.value)}
                            />
                        </div>
                    }
                </div>
                <div className="add-comparable-modal-buttons-row">
                    <div className="add-comparable-modal-cancel-button">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="add-comparable-modal-submit-button">
                        <ActionButton
                            onClick={() => complete()}
                            disabled={checkDisabled()}
                        >
                            Submit
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AddComparableModal;