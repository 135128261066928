import React, { useEffect, useState, useRef } from 'react';
import '../styles/Pay.css';
import { Header, Footer, LoadingStatic, CreditCard, SetupForm, DeleteCardModal, Loading, DiscountCode, CountyPricingCard, CountyMobilePricingOverlay } from '../components';
import { ChevronLeft } from '../assets';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, getSetupIntent, getStripeCustomer, recordEvent, setData, createStripeSetupIntent, deleteStripePaymentMethod, writeSubscriptionData, payAutoBilling, submitPayment, updateStripeCustomer, checkStripeError } from '../functions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TagManager from 'react-gtm-module';

const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
const stripePromise = loadStripe(mode === "test" ? JSON.parse(process.env.REACT_APP_STRIPE).test.publishable : JSON.parse(process.env.REACT_APP_STRIPE).live.publishable);

function Pay() {
    const location = useLocation();
    const [state, setState] = useState(location.state);
    const subscriptionId = state === null ? null : state.subscriptionId;
    const plan = state === null ? null : state.plan;
    const pricing = state === null ? null : state.pricing;
    const selectedPlan = state === null ? null : state.selectedPlan;
    const allCities = state === null ? null : state.allCities;
    const [mobileAccordion, setMobileAccordion] = useState(false);
    const [userData, setUserData] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const stateSearchParams = searchParams.get('stateId');
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [discountCodes, setDiscountCodes] = useState([]);
    const [discountCode, setDiscountCode] = useState("");
    const [discountCodeDisabled, setDiscountCodeDisabled] = useState(true);
    const [discountError, setDiscountError] = useState("");
    const [discountSuccess, setDiscountSuccess] = useState("");
    const [discountApplied, setDiscountApplied] = useState(null);
    const [promoDisabled, setPromoDisabled] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState("");
    const autoBilling = state === null ? false : plan.months === 1 ? true : false;
    const creditCardButton = useRef(null);
    const navigate = useNavigate();

    const options = {
        clientSecret: clientSecret,
        fonts: [
            {
                cssSrc: "https://fonts.googleapis.com/css?family=Rubik"
            }
        ],
        appearance: {
            theme: "stripe",
            variables: {
                fontFamily: "Rubik",
                colorBackground: colour.grayScaleWhite,
                colorText: colour.textPrimary,
                colorDanger: colour.redRed01,
                borderRadius: '3px',
                fontSizeBase: '14px',
                colorPrimary: colour.brownBrown01
            }
        }
    };

    useEffect(() => {
        document.title = "Checkout | Coffee Clozers";
        const fetchUserData = async () => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const colRef = "Users";
                const docRef = user.userId;
                setUserId(docRef);
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const userDetails = queryData.data.data;

                    if ( userDetails.creditCard === undefined ) {
                        setupIntent(userDetails, docRef);
                    }
                    getDiscountCodes();
                }

                const subColRef = "Subscriptions";
                const subDocRef = user.userId;
                const subQueryData = await getDocument(subColRef, subDocRef);
                if ( subQueryData.status === 200 ) {
                    setSubscriptions(subQueryData.data.data);
                    setUserData(queryData.data.data);
                }
                else {
                    setUserData(queryData.data.data);
                }
                setPromoDisabled(false);
            }
            else {
                navigate("/pick-city");
            }
        };

        const setupIntent = async(userDetails, userId) => {
            const getSetupIntentParams = searchParams.get('setup_intent');
            if ( clientSecret !== "" ) {
                return;
            }
            else if ( getSetupIntentParams !== null && userDetails.creditCard === undefined ) {
                const card = await getSetupIntent(getSetupIntentParams, mode);
                if ( card !== "" ) {
                    const amendedCard = {
                        brand: card.brand,
                        last4: card.last4,
                        exp_month: card.exp_month,
                        exp_year: card.exp_year,
                        mode: card.mode,
                        paymentMethod: card.paymentMethod
                    };
                    const colRef = "Users";
                    const newUserDetails = structuredClone(userDetails);
                    newUserDetails.creditCard = amendedCard;
                    await setData(colRef, userId, newUserDetails);
                    setUserData(newUserDetails);
                }
            }
            else {
                let customerId = userDetails.customerId;
                if ( customerId === undefined ) {
                    const customer = {
                        email: userDetails.email,
                        name: `${userDetails.firstName} ${userDetails.lastName}`,
                        phone: userDetails.phoneNumber !== undefined ? userDetails.phoneNumber : "",
                        mode: mode
                    };
                    const getCustomer = await getStripeCustomer(customer);
                    customerId = getCustomer.customerId;
                    const colRef = "Users";
                    const docRef = userId;
                    const data = userDetails;
                    data.customerId = customerId;
                    setUserData(data);
                    await setData(colRef, docRef, data);
                }
                const params = {
                    customerId: customerId,
                    mode: mode
                };
                const getIntent = await createStripeSetupIntent(params);
                if ( getIntent.status === 200 ) {
                    const id = getIntent.setupIntent.client_secret;
                    setClientSecret(id);
                }
            }
        };

        const getDiscountCodes = async() => {
            const colRef = "Discount Codes";
            const docRef = "Codes";
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;
                setDiscountCodes(data);
            }
        };

        const querySavedState = async() => {
            const getStateColRef = "Page State";
            const queryState = await getDocument(getStateColRef, stateSearchParams);
            if ( queryState.status === 200 ) {
                const stateData = queryState.data.data;
                setState(stateData);
                setSearchParams({});
                fetchUserData();
            }
            else if ( state === null ) {
                navigate("/pick-city");
            }
        };

        if ( state === null && stateSearchParams !== null ) {
            querySavedState();
        }
        else if ( state === null || state.plan === undefined ) {
            navigate("/pick-city", {
                state: state
            });
        }
        else {
            fetchUserData();
        }

        fetchUserData();

    }, [navigate, state, clientSecret, searchParams, setSearchParams, stateSearchParams]);

    const showDeleteModal = () => {
        recordEvent("Show Delete Card Modal");
        setDeleteModal(true);
    };

    const goBack = () => {
        navigate("/pricing");
    };

    const toggleAccordion = () => {
        setMobileAccordion(!mobileAccordion);
    };

    const changeDiscountCode = (text) => {
        setDiscountCode(text);

        if ( text === "" ) {
            setDiscountCodeDisabled(true);
        }
        else {
            setDiscountCodeDisabled(false);
        }
    };

    const removeDiscountCode = () => {
        setDiscountCode("");
        setDiscountError("");
        setDiscountSuccess("");
        setDiscountApplied(null);
    };

    const submitDiscountCode = () => {
        setDiscountCodeDisabled(true);
        const codeIndex = discountCodes.findIndex(e => e.label === discountCode);

        if ( codeIndex !== -1 ) {
            const relevantProducts = mode === "test" ? discountCodes[codeIndex].testProducts : discountCodes[codeIndex].products;
            const productIndex = relevantProducts.indexOf(subscriptionId);

            for (let index = 0; index < subscriptions.length; index++) {
                const element = subscriptions[index];
                if ( element.discountApplied !== undefined && element.discountApplied !== null ) {
                    const pastAppliedDiscountLabel = element.discountApplied.label;
                    if ( pastAppliedDiscountLabel === discountCode ) {
                        setDiscountError("You have already used this discount code in the past");
                        setDiscountSuccess("");
                        setDiscountCodeDisabled(false);
                        return;
                    }
                }
            }

            const chosenDiscountCode = discountCodes[codeIndex];
            const todaySeconds = new Date().getTime() / 1000;
            if ( chosenDiscountCode.expiration.seconds < todaySeconds ) {
                setDiscountError(`This discount code has expired`);
                setDiscountSuccess("");
                setDiscountCodeDisabled(false);
                return;
            }

            if ( productIndex !== -1 ) {
                setDiscountError("");
                setDiscountSuccess("Discount code applied successfully!");
                setDiscountApplied(discountCodes[codeIndex]);
                setDiscountCode("");
            }
            else {
                setDiscountError(discountCodes[codeIndex].planError);
                setDiscountSuccess("");
                setDiscountCodeDisabled(false);
            }
        }
        else {
            setDiscountError("This discount code is invalid. Please try again.");
            setDiscountSuccess("");
            setDiscountCodeDisabled(false);
        }
    };

    const deleteCard = async(item) => {
        setDisabled(true);
        setDeleteModal(false);
        let clone = JSON.parse(JSON.stringify(userData))
        delete clone.creditCard;
        setClientSecret("");
        setUserData(clone);
        const params = {
            mode: item.mode,
            paymentMethod: item.paymentMethod
        };
        const deletion = await deleteStripePaymentMethod(params);
        if ( deletion.status === 200 ) {
            const colRef = "Users";
            const data = clone;
            await setData(colRef, userId, data);
            setUserData(clone);

            const setupIntentParams = {
				customerId: data.customerId,
				mode: item.mode
			};
			const getIntent = await createStripeSetupIntent(setupIntentParams);
			if ( getIntent.status === 200 ) {
				const id = getIntent.setupIntent.client_secret;
				setClientSecret(id);
				setDisabled(false);
			}
        }
        setDisabled(false);
	};

    const saveCreditCard = () => {
        if ( creditCardButton.current !== null) {
            creditCardButton.current.click();
        }
    };

    const complete = async(newUserDetails) => {
        // 1. Check if auto-billing is present
        // 2. If it IS present, we need to create individual subscriptions for each city as separate items
        // 3. If it is NOT present, we need to create a single payment for the total amount
        // 4. We then need to write the subscription data to the database
        // 5. We then need to let Ariel know via API so she can commence data processing
        if ( newUserDetails === undefined ) {
            newUserDetails = userData;
        }

        setLoading(true);
        const originalPrice = plan.months * plan.originalPrice;
        const totalDiscount = discountApplied === null ? null : plan.discount + discountApplied.discount;
        const discountedTotal = totalDiscount === null ? null : originalPrice - ((totalDiscount / 100) * originalPrice);
        const roundedPrice = Number(plan.price.toFixed(2));
        plan.price = roundedPrice;
        const metadata = {
            months: plan.months,
            price: plan.total,
            subscriptionId: subscriptionId,
            allCities: allCities,
            discountApplied: discountApplied,
            discountedTotal: discountedTotal,
            plan: plan,
            counties: plan.counties,
            creative: plan.creative
        };
        const sendPayment = autoBilling === true ? await payAutoBilling(newUserDetails, mode, metadata) : await submitPayment(newUserDetails, metadata);
        if ( sendPayment.status === 200 ) {
            const writeData = await writeSubscriptionData(sendPayment, autoBilling, metadata, newUserDetails, discountApplied);
            if ( writeData.status === 200 ) {
                sendPayment.email = writeData.email;
                sendPayment.allCities = allCities;
                setPaymentError("");

                const tagManagerArgs = {
                    dataLayer: {
                        event: "purchase",
                        live: mode === "live" ? true : false,
                        credits_only: false,
                        value: plan.total,
                        currency: "USD"
                    }
                };
                TagManager.dataLayer(tagManagerArgs);

                const newState = {
                    counties: metadata.counties,
                    email: sendPayment.email
                }
                navigate("/success", {
                    state: newState
                });
                recordEvent("Payment Success", {
                    mode: mode,
                    metadata: metadata,
                    price: plan.total
                });
            }
            setLoading(false);
        }
        else if ( sendPayment.status === 404 ) {
            const newCustomerData = {
                paymentMethod: newUserDetails.creditCard.paymentMethod,
                customerId: newUserDetails.customerId,
                mode: mode
            }
            const setDefaultPaymentMethod = await updateStripeCustomer(newCustomerData);
            if ( setDefaultPaymentMethod.status === 200 ) {
                complete(userData);
            }
            else {
                setPaymentError("There was an error processing your subscription. Please delete your credit card and re-add it.");
                setLoading(false);
            }
        }
        else {
            const error = autoBilling === true ? sendPayment.subscription : sendPayment.paymentMethod;
            const checkedError = await checkStripeError(error);
            setPaymentError(checkedError);
            setLoading(false);
        }
    };

    return (
        <div className="pay-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                <div className="pay-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    {
                        userData !== null && userData.creditCard !== undefined && deleteModal === true ?
                        <DeleteCardModal
                            creditCard={userData.creditCard}
                            deleteFunction={deleteCard}
                            setDeleteModal={setDeleteModal}
                        />
                        :
                        null
                    }
                    <div className="pay-row-container">
                        <div className="pay-left-container margin-x-small">
                            <div className="city-selection-mobile-back-container desktop-none">
                                <div 
                                    className="button-row text-button"
                                    onClick={() => goBack()}
                                >
                                    <img
                                        src={ChevronLeft}
                                        className="city-selection-back-icon"
                                        alt="Back icon"
                                    />
                                    <span className="body-regular colour-link">
                                        Back
                                    </span>
                                </div>
                            </div>
                            <div className="city-selection-mobile-progress-bar margin-x-large desktop-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                                    <rect 
                                        width="100%" 
                                        height="4" 
                                        rx="2" 
                                        fill="#F2E1D0"
                                    />
                                    <rect
                                        width={`100%`}
                                        height="4" 
                                        rx="2" 
                                        fill={colour.blueBlue03}
                                    />
                                </svg>
                            </div>
                            <h1 className="heading-small-semibold colour-primary margin-large">
                                Payment method
                            </h1>
                            {
                                userData !== null && userData.creditCard !== undefined ?
                                <span className="body-regular colour-secondary block-text margin-large">
                                    Found card on file
                                </span>
                                :
                                null
                            }
                            {
                                userData === null ?
                                <LoadingStatic />
                                :
                                userData.creditCard !== undefined ?
                                <CreditCard
                                    creditCard={userData.creditCard}
                                    deleteCard={showDeleteModal}
                                    checkout={true}
                                />
                                :
                                clientSecret !== "" ?
                                    <Elements
                                        stripe={stripePromise} 
                                        options={options}
                                    >
                                        <SetupForm
                                            clientSecret={clientSecret}
                                            domain="pay"
                                            bundle={false}
                                            userData={userData}
                                            setUserData={setUserData}
                                            setPaymentMethodModal={null}
                                            autoBilling={selectedPlan === 0 ? true : false}
                                            setAutoBilling={null}
                                            product={plan}
                                            city={null}
                                            loading={loading}
                                            setLoading={setLoading}
                                            setPaymentError={setPaymentError}
                                            payFunc={complete}
                                            creditCardButton={creditCardButton}
                                            setDisabled={setDisabled}
                                            pageState={state}
                                        />
                                    </Elements>
                                :
                                <LoadingStatic />
                            }
                            {
                                paymentError !== "" ?
                                <div className="pay-payment-error-container margin-top-small">
                                    <span className="body-regular colour-error">
                                        {paymentError}
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                discountCodes.length > 0 && promoDisabled === false ?
                                <div className="pay-divider-line">
                                </div>
                                :
                                null
                            }
                            {
                                discountCodes.length > 0 && promoDisabled === false ?
                                <DiscountCode
                                    discountCode={discountCode}
                                    changeDiscountCode={changeDiscountCode}
                                    disabled={discountCodeDisabled}
                                    submitDiscountCode={submitDiscountCode}
                                    discountError={discountError}
                                    discountSuccess={discountSuccess}
                                    discountApplied={discountApplied}
                                    removeDiscountCode={removeDiscountCode}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="pay-right-container">
                            <CountyPricingCard
                                deleteCity={null}
                                checkout={true}
                                total={plan.total}
                                pricing={pricing}
                                selectedPlan={selectedPlan}
                                allCities={allCities}
                                discountApplied={discountApplied}
                                title={plan.title}
                                plan={plan}
                            />
                        </div>
                    </div>
                    <div className="new-checkout-buttons-row margin-top-x-x-large mobile-none">
                        <div 
                            className="button-row text-button"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeft}
                                className="city-selection-back-icon"
                                alt="Back icon"
                            />
                            <span className="body-regular colour-link">
                                Back
                            </span>
                        </div>
                        <div className="city-selection-next-button">
                            <ActionButton
                                disabled={userData === null || loading === true || disabled === true ? true : false}
                                onClick={() => userData !== null && userData.creditCard !== undefined ? complete() : saveCreditCard()}
                            >
                                Submit payment
                            </ActionButton>
                        </div>
                    </div>
                    {
                        deleteModal === true ?
                        null
                        :
                        <CountyMobilePricingOverlay
                            toggleAccordion={toggleAccordion}
                            mobileAccordion={mobileAccordion}
                            deleteCity={null}
                            complete={complete}
                            checkout={true}
                            total={plan.total}
                            pricing={pricing}
                            selectedPlan={selectedPlan}
                            plan={plan}
                            cta="Submit payment"
                            payment={true}
                            disabled={userData === null || loading === true || disabled === true ? true : false}
                            userData={userData}
                            saveCreditCard={saveCreditCard}
                            allCities={allCities}
                            discountApplied={discountApplied}
                            title={plan.title}
                        />
                    }
                </div>
            }
            <Footer />
        </div>
    );
}

export default Pay;