import React, { useEffect } from 'react';
import '../styles/PaymentSuccess.css';
import { Header, Footer } from '../components';
import { useNavigate, useLocation } from 'react-router-dom';

function PullRecordsSuccess() {
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	useEffect(() => {
		document.title = "Success";
		if ( state === null ) {
			navigate('/my-cities');
		}
	}, [state, navigate]);

	return (
		<div className="payment-success-outer-container payment-records-success-outer-container bg-colour-light">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="payment-success-inner-container">
				<div className="payment-success-icon-container margin-x-large">
					<svg 
						xmlns="http://www.w3.org/2000/svg" 
						width="81" 
						height="80" 
						viewBox="0 0 81 80" 
						fill="none"
					>
						<circle 
							cx="40.5" 
							cy="40" 
							r="40" 
							fill="#68AB8E"
						/>
						<path 
							fillRule="evenodd" 
							clipRule="evenodd" 
							d="M63.936 23.8398C65.9421 25.6987 66.03 28.7987 64.1323 30.7638L34.9657 60.9679C34.0378 61.9287 32.7528 62.4812 31.4028 62.4995C30.0527 62.5179 28.7525 62.0006 27.7978 61.0654L16.9645 50.4532C15.0118 48.5404 15.0118 45.4392 16.9645 43.5264C18.9171 41.6136 22.0829 41.6136 24.0355 43.5264L31.2338 50.5777L56.8676 24.0321C58.7653 22.0669 61.9299 21.9808 63.936 23.8398Z" 
							fill="#FFFEFC"
						/>
					</svg>
				</div>
				<h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
					Payment Success 🎉
				</h1>
				<span className="body-regular colour-primary margin-x-large text-align-center">
					We'll send an email to <span className="body-semibold underline">{state.email}</span> when your records are ready!
				</span>
			</div>
			<Footer />
		</div>
	)
}

export default PullRecordsSuccess;