import React from 'react';
import '../styles/TextLoading.css';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';

function TextLoading(props) {
    const stage = props.stage;
    const stages = props.stages;
    const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div className="text-loading-container">
            <div className="text-loading-animation-container">
                {View}
            </div>
            <div className="text-loading-inner-container">
                {
                    stages.map((item, index) =>
                        <div 
                            className="text-loading-stage-element margin-x-small"
                            key={index}
                        >
                            <span className={"colour-primary " + (index <= stage ? "body-semibold" : "body-regular")}>
                                {item}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default TextLoading;