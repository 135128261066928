import { useEffect, useState } from 'react';
import { checkOnAuthStateChanged, chooseCity, checkPropertySearchCredits, recordEvent, checkUserDocumentExists, getDocument, renewFreeTrial } from '../functions/index';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import { FullStory } from '@fullstory/browser';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';

function HeaderPartialEffect(props) {

    const setDisabled = props.setDisabled;
    const setUserData = props.setUserData;
    const setPartner = props.setPartner;
    const setSubscriptions = props.setSubscriptions;
    const setFreeTrial = props.setFreeTrial;
    const setLoading = props.setLoading;
    const state = props.state;
    const filteredResults = props.filteredResults;
    const userData = props.userData;
    const city = props.city;
    const mapSearchAddressAutocomplete = props.mapSearchAddressAutocomplete;
    const selectedFilteredResult = props.selectedFilteredResult;
    const setSelectedFilteredResult = props.setSelectedFilteredResult;
    const nonDisclosureStates = props.nonDisclosureStates;
    const path = props.path;
    const setUpgradeModal = props.setUpgradeModal;
    const freeTrial = props.freeTrial;
    const subscriptions = props.subscriptions;
    const setUpgradeCity = props.setUpgradeCity;
	const collection = props.collection;
	const setUserId = props.setUserId;
    const posthog = usePostHog();
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
		async function fetchUserData() {
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
				const userId = getUserId.userId;
				setUserId(userId);
				mixpanel.identify(userId);

				const emailVerified = getUserId.emailVerified;
				if ( emailVerified === false && path !== "/profile" && path !== "/verify-email" && path !== "/email-verification" && path !== "/describe-you" && path !== "/welcome" && path !== "/demo-confirmation" ) {
					navigate("/verify-email");
				}
				
				const docRef = userId;
				const query = await getDocument(collection, docRef);
				if ( (query.status === 200 && collection === "Users") || (collection === "Subscriptions") ) {
					const queriedData = [
						{
							status: 200,
							data: collection === "Users" ? query.data.data : userData
						},
						{
							status: query.status,
							data: collection === "Subscriptions" && query.status === 200 ? query.data.data : subscriptions
						}
					]
					saveUserData(queriedData, userId);
				}
				else if ( collection === "Users" ) {
					await checkUserDocumentExists(getUserId, navigate)
					setDisabled(false);
				}
			}
			else {
				setDisabled(false);
			}
		}

		const saveUserData = async(query, userId) => {
			setDisabled(false);
			const data = query[0].data;
			setUserData(data);

			if ( data.email !== undefined ) {
				const name = `${data.firstName} ${data.lastName}`;
				const email = data.email.toLowerCase();
				mixpanel.register({
					'Email': email,
					'Name': name
				});

				mixpanel.people.set({
					'$name': name,
					'$email': email,
					'$distinct_id': userId
				});

				FullStory.identify(userId, {
					displayName: name,
					email: email,
					uid: userId
				});

				posthog?.identify(userId, {
					email: email,
					name: name
				});

				const tagManagerArgs = {
					dataLayer: {
						userId: userId,
						email: email,
						name: name
					}
				};
				TagManager.dataLayer(tagManagerArgs);
			}

			if ( data.partner !== undefined ) {
				setPartner(true);
			}

			// if ( data.phoneNumberVerified !== true ) {
			// 	if ( path === "/profile" ) {
							
			// 	}
			// 	else if ( state === null ) {
			// 		const newState = {
			// 			finalRoute: "/pick-city"
			// 		};
			// 		navigate("/phone-verification", {
			// 			state: newState
			// 		});
			// 	}
			// 	else {
			// 		state.onboarding = true;
			// 		navigate("/phone-verification", {
			// 			state: state
			// 		});
			// 	}
			// }

			// 3 different user types

			// 1. Paid users who need grandfathering
			// 2. Free Trial users who need grandfathering
			// 3. New users who DON'T need grandfathering (further split into paid vs free)

			const userFreeTrial = data.freeTrial;
			const checkSubscriptions = query[1].status === 200 ? query[1].data : [];
			renewFreeTrial(userFreeTrial, checkSubscriptions, userId, data, setUserData);
			if ( data.repeatUser === true && path !== "/profile" ) {
				// User who has created 2 accounts and gotten found out
				if ( path !== "/repeat-user" ) {
					navigate("/repeat-user", {
						state: state
					});
					recordEvent("Sent to Repeat User", {});
				}
			}
			else if ( query[1].status === 200 ) {
				// User who has subscriptions on new system
				setSubscriptions(query[1].data);
				
				const subscriptionData = query[1].data;
				const activeSubscription = subscriptionData.findIndex(e => e.endDate.seconds > Date.now() / 1000);
				if ( activeSubscription === -1 ) {
					setFreeTrial(userFreeTrial);
				}
			}
			else {
				setFreeTrial(userFreeTrial);
			}
		};

		if ( (firstRender === false && ((userData !== "" && userData !== null && collection === "Subscriptions" ) || ( subscriptions !== null && subscriptions !== undefined && collection === "Users" ))) ) {
			setFirstRender(true);
			fetchUserData();
		}
		else {
			setDisabled(false);
		}

		const keyDownHandler = event => {
			if ( (filteredResults.length !== 0 || mapSearchAddressAutocomplete.length !== 0) && city !== "" ) {
				if (event.key === "ArrowDown") {
					event.preventDefault();
					const newVal = selectedFilteredResult === null || selectedFilteredResult === 6 ? 0 : selectedFilteredResult + 1;
					setSelectedFilteredResult(newVal);
				}
				else if ( event.key === "ArrowUp" ) {
					event.preventDefault();
					const newVal = selectedFilteredResult === null || selectedFilteredResult === 0 ? null : selectedFilteredResult - 1;
					setSelectedFilteredResult(newVal);
				}
				else if ( event.key === "Enter" && selectedFilteredResult !== null ) {
					event.preventDefault();
					if ( mapSearchAddressAutocomplete.length !== 0 ) {
						enterSelectGoogleMapProperty(mapSearchAddressAutocomplete[selectedFilteredResult]);
					}
					else {
						enterCityClick(filteredResults[selectedFilteredResult]);
					}
				}
			}
		};

		const enterSelectGoogleMapProperty = async(item) => {
			setLoading(true);
			if ( userData === "" || userData === null ) {
				const encodedAddress = encodeURIComponent(item.description);

				window.open(`/quick-report/${encodedAddress}`, "_self");
			}
			else if ( item.zpid !== undefined ) {
				window.open(`/properties/${item.cityId}/${item.zpid}`, "_blank");
			}
			else {
				const queryPropertySearch = await checkPropertySearchCredits(item, null, null);
				if ( queryPropertySearch === "no credits" ) {
					const encodedAddress = encodeURIComponent(item.description);
					
					window.open(`/quick-report/${encodedAddress}`, "_self");
				}
			}
			setLoading(false);
		};
	
		const enterCityClick = async(item) => {
			setLoading(true);
			if ( userData !== "" && userData !== null ) {
				const checkCity = await chooseCity(freeTrial, subscriptions, item);
				if ( checkCity.status === 200 && checkCity.cityId !== undefined) {
					setUpgradeCity(null);
					window.open(`/properties-list/${checkCity.cityId}`, "_blank");
				}
				else {
					setUpgradeCity(item);
					setUpgradeModal(true);
				}
			}
			else {
				const newState = {
					chosenCity: {
						city: item.city,
						state: item.state
					},
					onboarding: true
				};
				navigate("/sign-up", {
					state: newState
				});
			}
			setLoading(false);
		};
	  
		document.addEventListener('keydown', keyDownHandler);
	
		// 👇️ clean up event listener
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [
		city,
		filteredResults,
		freeTrial,
		mapSearchAddressAutocomplete,
		navigate,
		path,
		posthog,
		selectedFilteredResult,
		state,
		subscriptions,
		userData,
		firstRender,
        nonDisclosureStates,
        setDisabled,
        setFreeTrial,
        setLoading,
        setPartner,
        setSelectedFilteredResult,
        setSubscriptions,
        setUpgradeCity,
        setUpgradeModal,
        setUserData,
		collection,
		setUserId
	]);
};

export default HeaderPartialEffect;