import React, { useEffect, useState } from 'react';
import { Header, Footer } from '../components';
import '../styles/EmailVerification.css';
import { ActionButton } from "../styles/GlobalStyles";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkOnAuthStateChanged } from '../functions';

function EmailVerification() {

	const url = "https://firebasestorage.googleapis.com/v0/b/refi-787d3.appspot.com/o/always-be-closing.gif?alt=media&token=bc909010-d52a-461a-b98f-f4617766eb9b";
	const navigate = useNavigate();

	const [redirect, setRedirect] = useState(false);
	const [searchParams] = useSearchParams({});
	const [getUserCalled, setGetUserCalled] = useState(false);
	const mode = searchParams.getAll('mode');
	const oobCode = searchParams.getAll('oobCode');
	const apiKey = searchParams.getAll('apiKey');

	let continueURL
	if ( mode.length !== 0 && mode[0] === "resetPassword" ) {
		continueURL = window.location.hostname === "localhost" ? `http://localhost:3000/login?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&` : `https://app.coffeeclozers.com/login?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&`;
	}
	else if ( mode.length !== 0 && mode[0] === "recoverEmail" ) {
		continueURL = window.location.hostname === "localhost" ? `http://localhost:3000/login?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&` : `https://app.coffeeclozers.com/login?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&`;
	}
	else {
		continueURL = window.location.hostname === "localhost" ? `http://localhost:3000/email-verification?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&` : `https://app.coffeeclozers.com/email-verification?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&`;	
	}
	const fullUrl = `https://refi-787d3.firebaseapp.com/__/auth/action?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en&continueUrl=${continueURL}`;

	useEffect(() => {

		async function getUser() {
			const user = await checkOnAuthStateChanged();
			
			if ( user.status === 200 ) {
				const checkEmail = user.emailVerified;
				setGetUserCalled(true);
				if ( mode.length === 0 || oobCode.length === 0 ) {
					setRedirect(true);
				}
				else if ( redirect === false && checkEmail !== true ) {
					window.open(fullUrl, "_self");
					setRedirect(true);
				}
				else if ( checkEmail === true ) {
					setRedirect(true);
				}
			}
			else if ( mode.length !== 0 && mode[0] === "resetPassword" ) {
				window.open(fullUrl, "_self");
				setRedirect(true);
			}
			else {
				navigate('/login');
			}
		}

		getUser();
	}, [fullUrl, redirect, mode.length, oobCode.length, navigate, mode]);

	const cta = () => {
		window.open("/my-cities", "_self");
	}

	return (
		<div className="email-verification-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			{
				redirect === false ?
				<div className="email-verification-inner-container">
					<h1 className="email-verification-title-text">
						redirecting you...
					</h1>
					<h2 className="email-verification-subtitle-text">
						If the redirect doesn't work, click 
						{
							getUserCalled === true ?
							<a className="email-verification-subtitle-text-anchor" href={fullUrl}> here</a>
							:
							" here"
						}
						{' '}to redirect
					</h2>
				</div>
				:
				<div className="email-verification-inner-container">
					<div className="error-page-image-container">
						<img
							src={url}
							className="error-page-gif"
							alt="Always Be Clozing"
						/>
					</div>
					<div className="email-verification-title-container">
						<h1 className="email-verification-title-text">
							Email Verified
						</h1>
						<h2 className="email-verification-subtitle-text">
							Now get back to clozing.
						</h2>
					</div>
					<div className="email-verification-button-container">
						<ActionButton
							onClick={() => cta()}
						>
							Let's go
						</ActionButton>
					</div>
				</div>
			}
			<Footer />
		</div>
	)
}

export default EmailVerification