import React, { useEffect, useState } from 'react';
import '../styles/Favourites.css';
import { Header, Footer, FavouritesTable, FavouritesGrid, SideNavigation } from '../components';
import { saveNewFavourites, checkOnAuthStateChanged, downloadExcel, removeMultiFavourites, getSubDocument, calculateCashOnCash, recordEvent, getUserDocuments, getAPIGatewayDetail, cloudFunctionV2 } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, List, Dash, Compare, Download, Sort, BlackTick, HousesIcon } from '../assets';
import { ActionButton, CustomSelect, favouritesCategories, colour, SortSelect, formatterCompact, defaultUserData } from '../styles/GlobalStyles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { isMobile } from 'react-device-detect';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';

function Favourites() {

    const [properties, setProperties] = useState([]);
    const [downPayment, setDownPayment] = useState(20);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [listView, setListView] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [selectOpen, setSelectOpen] = useState(false);
    const [favouritesDisable, setFavouriteDisable] = useState(false);
    const [sorting, setSorting] = useState(0);
    const [freeTrial, setFreeTrial] = useState(true);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);
    const [spreadsheetProperties, setSpreadsheetProperties] = useState([]);
    const [neighbourhoodData, setNeighbourhoodData] = useState([]);
    const location = useLocation();
    const state = location.state;
    const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);
    const sortOptions = [
        {
            label: "State (A - Z)"
        },
        {
            label: "City (A - Z)"
        },
        {
            label: "Cash on cash"
        },
        {
            label: "Price"
        }
    ];
    const columns = [
        {
            label: "",
            style: "",
            value: [],
            cellValue: ""
        },
        {
            label: "Address",
            style: "u",
            value: ["address", "streetAddress"],
            cellValue: "address"
        },
        {
            label: "Market",
            style: "m",
            value: ["offMarket"],
            cellValue: "market"
        },
        {
            label: "City",
            style: "t",
            value: ["address", "city"],
            cellValue: "city"
        },
        {
            label: "ZIP code",
            style: "t",
            value: ["address", "zipcode"],
            cellValue: "zip-code"
        },
        {
            label: "State",
            style: "t",
            value: ["address", "state"],
            cellValue: "state"
        },
        {
            label: "Cash on cash",
            style: "p",
            value: ["financials", "cashOnCash"],
            cellValue: "cash-on-cash"
        },
        {
            label: "Neighborhood grade",
            style: "b",
            value: [],
            cellValue: "neighbourhood-data"
        },
        {
            label: "Hotspots",
            style: "b",
            value: ["zpid"],
            cellValue: "hotspots"
        },
        {
            label: "Monthly profit",
            style: "d",
            value: ["financials", "monthlyProfit"],
            cellValue: "profit"
        },
        {
            label: "Initial costs",
            style: "d",
            value: ["financials", "initialCosts"],
            cellValue: "initial-costs"
        },
        {
            label: "Price",
            style: "d",
            value: ["price"],
            cellValue: "price"
        },
        {
            label: "Price per sqft",
            style: "d",
            value: ["resoFacts", "pricePerSqFoot"],
            cellValue: "price-per-sqft"
        },
        {
            label: "Down Payment",
            style: "dp",
            value: [],
            cellValue: "down-payment"
        },
        {
            label: "Interest Rate",
            style: "p",
            value: ["financials", "interestRate"],
            cellValue: "interest-rate"
        },
        {
            label: "Closing Costs",
            style: "d",
            value: ["financials", "closingCost"],
            cellValue: "closing-costs"
        },
        {
            label: "Rent",
            style: "d",
            value: ["financials", "rent"],
            cellValue: "rent"
        },
        {
            label: "Mortgage Payment",
            style: "d",
            value: ["financials", "mortgagePayment"],
            cellValue: "mortgage-payment"
        },
        {
            label: "HOA",
            style: "d",
            value: ["financials", "hoaFees"],
            cellValue: "hoa"
        },
        {
            label: "Insurance",
            style: "d",
            value: ["financials", "insurance"],
            cellValue: "insurance"
        },
        {
            label: "Property Taxes",
            style: "d",
            value: ["financials", "propertyTaxes"],
            cellValue: "property-taxes"
        },
        {
            label: "Vacancy",
            style: "d",
            value: ["financials", "vacancyRateAllocation"],
            cellValue: "vacancy"
        },
        {
            label: "Management fee",
            style: "d",
            value: ["financials", "managementFee"],
            cellValue: "management-fee"
        },
        {
            label: "Maintenance",
            style: "d",
            value: ["financials", "maintenanceFee"],
            cellValue: "maintenance"
        },
        {
            label: "Property type",
            style: "t",
            value: ["propertyTypeDimension"],
            cellValue: "property-type"
        },
        {
            label: "Beds",
            style: "t",
            value: ["bedrooms"],
            cellValue: "beds"
        },
        {
            label: "Baths",
            style: "t",
            value: ["bathrooms"],
            cellValue: "baths"
        },
        // {
        //     label: "Renovations & CapEx",
        //     style: "t",
        //     value: ["financials", "capex"],
        //     cellValue: "renovations-capex"
        // },
        {
            label: "Rehab",
            style: "d",
            value: ["financials", "rehab"],
            cellValue: "rehab"
        },
        {
            label: "Year built",
            style: "t",
            value: ["yearBuilt"],
            cellValue: "year-built"
        },
        {
            label: "SQFT",
            style: "t",
            value: ["livingArea"],
            cellValue: "sq-footage"
        }
    ];
    const [selectedCategories, setSelectedCategories] = useState([
        "empty",
        "market",
        "city",
        "state",
        "cash-on-cash",
        "profit",
        "initial-costs",
        "price",
        "price-per-sqft",
        "beds",
        "baths",
        "property-type",
        "year-built",
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        const renovationsOptions = [
            {
                text: "None",
                value: 0,
                lower: 0,
                upper: 0
            },
            {
                text: "Light ($1k - $10k)",
                value: 1,
                lower: 1000,
                upper: 10000
            },
            {
                text: "Medium ($10k - $30k)",
                value: 2,
                lower: 10000,
                upper: 30000
            },
            {
                text: "Heavy ($30k+)",
                value: 3,
                lower: 30000,
                upper: 0
            }
        ];

        document.title = "Favorites";
        const getData = async() => {
            const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const collections = [
					"Users",
                    "Favourites",
                    "Financial Preferences",
                    "Subscriptions"
				];

                const docRef = getUserId.userId;
                const query = await getUserDocuments(collections, docRef);
                if ( query[0].status === 200 ) {
                    const data = query[0].data;
                    setUserDetails(data);

                    let checkFavourites = [];
                    if ( query[1].status === 200 ) {
                        checkFavourites = query[1].data;
                    }
                    else {
                        checkFavourites = data.favourites
                        saveNewFavourites(checkFavourites, docRef);
                    }

                    let userSettings;
                    if ( query[2].status === 200 ) {
                        userSettings = query[2].data;
                    }
                    else {
                        userSettings = defaultUserData.settings;
                    }

                    let down = userSettings.initialCosts[0].value === "" ? 20 : Number(userSettings.initialCosts[0].value);
                    setDownPayment(down);
                    
                    const getProperties = [];
                    for (let index = 0; index < checkFavourites.length; index++) {
                        const zpid = checkFavourites[index].zpid;
                        const cityId = checkFavourites[index].cityId;
                        const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                        const getGatewayQueryString = await getAPIGatewayDetail(cityId, zpid, userSettings);
                        const getGatewayParams = {
                            type: "gateway",
                            resourceId: "detail",
                            queryString: getGatewayQueryString
                        };
                        const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
                        if ( getGateway.status === 200 ) {
                            const propertyObject = getGateway.body.prop;
                            if ( propertyObject === null ) {
                                continue;
                            }
                            propertyObject.datePostedTS = propertyObject.datePosted !== undefined && propertyObject.datePostedTS !== null ? new Date(propertyObject.datePosted) : 0;
                            const closingPercentage = 3;
                            const closingCost = (propertyObject.price * Number(closingPercentage)) / 100;
                            const downPaymentPercentage = downPayment / 100;
                            const downPaymentCost = (propertyObject.price * downPaymentPercentage);
                            const initialCosts = downPaymentCost + closingCost;
                            propertyObject.financials.initialCosts = initialCosts;
                            propertyObject.financials.closingCost = closingCost;
                            propertyObject.financials.renovation = renovationsOptions[0].text;
                            propertyObject.financials.capex = renovationsOptions[0].text;
                            propertyObject.financials.totalRehabCost = 0;
                            propertyObject.financials.interestRate = propertyObject.mortgage30us;
                            propertyObject.offMarket = propertyObject.stackedList !== undefined ? true : false;
                            if ( propertyObject.offMarket === true ) {
                                const motivatedSellerArray = [
                                    propertyObject.motivatedSeller.absentee_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.auction_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.death_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.free_clear_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.high_equity_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.inherited_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.lien_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
                                    propertyObject.motivatedSeller.owner_occupied_flag === 0 ? true : false,						
                                    propertyObject.motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.spousal_death_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.tax_lien_flag === 0 ? false : true,
                                    propertyObject.motivatedSeller.vacant_flag === 0 ? false : true
                                ];
                                
                                const emptyArray = [];
                                const totalTrueArray = [];
                                let motivatedSellerFourthIndex = 99;
                                for (let i = 0; i < motivatedSellerArray.length; i++) {
                                    const subElement = motivatedSellerArray[i];
                                    if ( subElement === true && emptyArray.length < 4 ) {
                                        emptyArray.push(subElement);
                                    }
                            
                                    if ( subElement === true ) {
                                        totalTrueArray.push(true);
                                    }
                            
                                    if ( emptyArray.length === 4 && motivatedSellerFourthIndex === 99 ) {
                                        motivatedSellerFourthIndex = i;
                                    }
                                }
                                propertyObject.motivatedSellerCount = totalTrueArray.length;
                                propertyObject.motivatedSellerFourthIndex = motivatedSellerFourthIndex;
                            }
                            const checkSavedDetails = await checkSavedProperty(docRef, propertyObject, down);

                            getProperties.push(checkSavedDetails);
                        }
                    }
                    getNeighbourhoodData(checkFavourites);
                    setProperties(getProperties);
                    setLoading(false);

                    const getPropertiesClone = getProperties.slice(0);
                    const spreadsheetArray = [];
                    for (let index = 0; index < getPropertiesClone.length; index++) {
                        const element = getPropertiesClone[index];
                        spreadsheetArray.push(element);
                    }
                    setSpreadsheetProperties(spreadsheetArray);

                    if ( query[3].status === 200 ) {
                        const subscriptions = query[3].data;
                        const today = new Date();
                        const todaySeconds = today.getTime() / 1000;
                        const subscriptionIndex = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
                        if ( subscriptionIndex !== -1) {
                            setFreeTrial(false);
                        }
                    }
                }
            }
            else {
                setLoading(false);
                navigate("/sign-up");
            }
        };

        async function checkSavedProperty(userId, property, down) {
            const colRef = "Users";
			const docRef = userId;
			const subColRef = "Properties";
			const subDocRef = `${property.zpid}`;
			const queryProperty = await getSubDocument(colRef, docRef, subColRef, subDocRef);
            if ( queryProperty.status === 200 ) {
                const savedProperty = queryProperty.data.data;
                const closingCosts = ((savedProperty.closingCosts / 100) * savedProperty.price);
                const downPaymentCost = ((savedProperty.downPayment / 100) * savedProperty.price);
                const initialCosts = downPaymentCost + closingCosts;
                const newCosts = [];
                const newProfits = [];
                for (let index = 0; index < savedProperty.financials.length; index++) {
                    const element = savedProperty.financials[index];
                    if ( element.loss === true ) {
                        newCosts.push(element);
                    }
                    else {
                        newProfits.push(element);
                    }
                }

                const profitObject = await updateCashFlow(newCosts, newProfits, savedProperty.totalRenovationCost, initialCosts);
                const amendedObject = {
                    rent: Number(savedProperty.financials[0].value),
                    otherIncome: Number(savedProperty.financials[1].value),
                    mortgagePayment: Number(savedProperty.financials[2].value),
                    hoaFees: Number(savedProperty.financials[3].value),
                    insurance: Number(savedProperty.financials[4].value),
                    propertyTaxes: Number(savedProperty.financials[5].value),
                    vacancyRateAllocation: Number(savedProperty.financials[6].value),
                    managementFee: Number(savedProperty.financials[7].value),
                    maintenanceFee: Number(savedProperty.financials[8].value),
                    otherCosts: Number(savedProperty.financials[9].value),
                    price: savedProperty.price,
                    closingCost: closingCosts,
                    capex: renovationsOptions[savedProperty.totalRenovationCost].text,
                    initialCosts: savedProperty.totalRehabCost === undefined ? initialCosts : initialCosts + savedProperty.totalRehabCost,
                    monthlyProfit: profitObject.monthlyProfit,
                    cashOnCash: typeof(profitObject.cashOnCash) === "number" ? profitObject.cashOnCash : profitObject.upperCoCR !== "" ? `${profitObject.upperCoCR.toFixed(0)}% - ${profitObject.lowerCoCR.toFixed(0)}%` : `<${profitObject.lowerCoCR.toFixed(0)}%`,
                    lowerCoCR: profitObject.lowerCoCR,
                    interestRate: Number(savedProperty.interestRate),
                    downPayment: savedProperty.downPayment,
                    renovation: savedProperty.totalRenovationCost === 0 ? 0 : savedProperty.totalRenovationCost === 1 ? 10000 : 30000,
                    totalRehabCost: savedProperty.totalRehabCost === undefined ? 0 : savedProperty.totalRehabCost
                }
                property.price = savedProperty.price;
                property.mortgage30us = savedProperty.interestRate;
                property.financials = amendedObject;
                // property.resoFacts.pricePerSqFoot = property.livingArea !== null ? `$${(savedProperty.price / property.livingArea).toFixed(0)}/sqft` : "N/A";
                property.purchaseCosts.closingCostRate = savedProperty.closingCosts / 100;
                return property;
            }
            else {
                property.financials.lowerCoCR = property.financials.cashOnCash;
                property.financials.downPayment = down;
                property.financials.renovation = 0;
                return property;
            }
        };

        async function updateCashFlow(newCosts, newProfits, newRadio, customInitialCosts){
            let monthlyProfit = 0;
            for (let index = 0; index < newCosts.length; index++) {
                monthlyProfit = monthlyProfit - Number(newCosts[index].value);
            }
    
            for (let index = 0; index < newProfits.length; index++) {
                monthlyProfit = monthlyProfit + Number(newProfits[index].value);
            }
    
            const lowerBound = newRadio === 0 ? 0 : newRadio === 3 ? customInitialCosts + renovationsOptions[newRadio].lower : newRadio !== null ? customInitialCosts + renovationsOptions[newRadio].lower : 0;
            const upperBound = newRadio === 0 ? 0 : newRadio === 3 ? 0 :  newRadio !== null ? customInitialCosts + renovationsOptions[newRadio].upper : 0;
            
            let newCashOnCash;
            let finalCosts;
            let lowerCoCR = "";
            let upperCoCR = "";
            if ( newRadio === 0 )  {
                const newInitialCosts = customInitialCosts;
                finalCosts = newInitialCosts;
                newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);
            }
            else if ( newRadio === 1 ) {
                lowerCoCR = await calculateCashOnCash(monthlyProfit, lowerBound);
                upperCoCR = await calculateCashOnCash(monthlyProfit, upperBound);
                finalCosts = `${formatterCompact.format(lowerBound)} - ${formatterCompact.format(upperBound)}`;
                newCashOnCash = `${upperCoCR.toFixed(0)}% - ${lowerCoCR.toFixed(0)}%`;
            }
            else if ( newRadio === 2 ) {
                lowerCoCR = await calculateCashOnCash(monthlyProfit, lowerBound);
                upperCoCR = await calculateCashOnCash(monthlyProfit, upperBound);
                finalCosts = `${formatterCompact.format(lowerBound)} - ${formatterCompact.format(upperBound)}`;
                newCashOnCash = `${upperCoCR.toFixed(0)}% - ${lowerCoCR.toFixed(0)}%`;
            }
            else if ( newRadio === 3 ) {
                lowerCoCR = await calculateCashOnCash(monthlyProfit, lowerBound);
                finalCosts = `${formatterCompact.format(lowerBound)}+`;
                newCashOnCash = `<${lowerCoCR.toFixed(0)}%`;
            }
    
            const profitObject = {
                monthlyProfit: monthlyProfit,
                cashOnCash: newCashOnCash,
                totalCosts: finalCosts,
                lowerCoCR: lowerCoCR,
                upperCoCR: upperCoCR,
                cocrRange: typeof(newCashOnCash) === "string" ? true : false,
                lowerCost: lowerBound,
                upperCost: upperBound
            };
            return profitObject;
        }

        async function getNeighbourhoodData(checkFavourites) {
            const allNeighbourhoodData = [];
            for (let index = 0; index < checkFavourites.length; index++) {
                const element = checkFavourites[index];
                const cityId = element.cityId;
                if ( cityId === null ) {
                    continue;
                }
                const neighbourhoodPath = cityId.includes("CTY") ? `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json` : `output/map_neighborhood/${cityId}.json`
                
                const params = {
                    s3Data: {
                        key: neighbourhoodPath,
                        bucketName: "residentialpropertydata"
                    },
                    type: "s3"
                }
                const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
                const queryData = await cloudFunctionV2(awsURL, params);
                if ( queryData.status === 200 ) {
                    const body = queryData.body;
                    for (let ii = 0; ii < body.length; ii++) {
                        allNeighbourhoodData.push(body[ii]);
                    }
                }
            }
            setNeighbourhoodData(allNeighbourhoodData);
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		  };
	  
		window.addEventListener('resize', handleWindowResize);

        getData();
    }, [navigate, downPayment]);

    const toggleView = () => {
        setListView(!listView);
    }

    const toggleCheckbox = (zpid) => {
        const cloneArray = selectedProperties.slice(0);
        const index = selectedProperties.indexOf(zpid);

        if ( index === -1 ) { // && cloneArray.length < 2
            cloneArray.push(zpid);
        }
        else if ( index !== -1 ) {
            cloneArray.splice(index, 1);
        }
        setSelectedProperties(cloneArray);
    };

    const propertySelected = (zpid) => {
        const index = selectedProperties.indexOf(zpid);
        if ( index === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const changeCategories = (val, event) => {
        const index = selectedCategories.indexOf(val);
        const clonedArray = selectedCategories.slice(0);
        if ( index === -1 ) {
            clonedArray.push(val);
        }
        else {
            clonedArray.splice(index, 1);
        }
        setSelectedCategories(clonedArray);
    }

    const favouritesCategoriesSelected = (val) => {
        const index = selectedCategories.indexOf(val);
        if ( index === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const removeFavourites = async() => {
        setLoading(true);
        const newArray = [];
        const amendedProperties = [];
        for (let index = 0; index < properties.length; index++) {
            const element = properties[index];
            const zpidIndex = selectedProperties.indexOf(element.zpid);
            if ( zpidIndex === -1 ) {
                amendedProperties.push(element);
                const newObject = {
                    zpid: element.zpid,
					cityId: element.cityId,
					city: element.address.city,
					state: element.address.state
                }
                newArray.push(newObject);
            }
        }

        await removeMultiFavourites(newArray, setProperties, setFavouriteDisable, navigate, amendedProperties);
        setSelectedProperties([]);
        setLoading(false);
    };

    const compareProperties = () => {
        const comparePropertyArray = [];
        for (let index = 0; index < selectedProperties.length; index++) {
            const element = selectedProperties[index];
            const propertyIndex = properties.findIndex(e => e.zpid === element);
            const newObject = {
                cityId: properties[propertyIndex].cityId,
                zpid: element
            }
            comparePropertyArray.push(newObject);
        }

        const path = window.location.pathname;

        recordEvent("Navigation", {
            oldRoute: path,
            newRoute: "/compare-properties"
        });
        recordEvent("Compare Properties", {
            properties: comparePropertyArray
        });
        navigate(`/compare-properties?properties=${JSON.stringify(comparePropertyArray)}`);
    };

    const downloadActiveProperties = () => {
        const newArray = [];
        for (let index = 0; index < selectedProperties.length; index++) {
            const element = selectedProperties[index];
            const propertyIndex = spreadsheetProperties.findIndex(e => e.zpid === element);
            newArray.push(spreadsheetProperties[propertyIndex]);
        }
        downloadExcel(newArray, "", "", downPayment, false, 0);
    };

    const changeSorting = (val) => {
        setSorting(val);
        const propertiesClone = properties.slice(0);
        if ( val === 0 ) {
            propertiesClone.sort((a, b) => a.address.state.toLowerCase().localeCompare(b.address.state.toLowerCase()))
        }
        else if ( val === 1 ) {
            propertiesClone.sort((a, b) => a.address.city.toLowerCase().localeCompare(b.address.city.toLowerCase()))
        }
        else if ( val === 2 ) {
            propertiesClone.sort((a,b) => (a.financials.lowerCoCR < b.financials.lowerCoCR) ? 1 : ((b.financials.lowerCoCR < a.financials.lowerCoCR) ? -1 : 0));
        }
        else if ( val === 3 ) {
            propertiesClone.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
        }
        setProperties(propertiesClone);
    }

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
		selectedMenuItem: {
			backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
		},
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center'
		}
	};

    return (
        <div className="favourites-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={userDetails}
                queries={[false, true]}
                mobileNav={true}
            />
            <div className="favourites-outer-row-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userDetails}
                />
                <div className="favourites-inner-container">
                    <div className="favourites-title-container margin-x-small">
                        <div className="button-row">
                            <img
                                src={HousesIcon}
                                className="favourites-title-icon"
                                alt="Favourites icon"
                            />
                            <div className="favourites-title-text-container">
                                <h1 className="heading-large-semibold colour-primary margin-x-small">
                                    Favorites
                                </h1>
                                <span className="body-regular colour-secondary">
                                    Your saved properties
                                </span>
                            </div>
                        </div>
                        {
                            isMobile === false ?
                            <div 
                                className={"favourites-download-container text-button " + (freeTrial === true ? "favourites-free-trial" : "")}
                                onClick={() => freeTrial === true ? null : downloadExcel(spreadsheetProperties, "", "", downPayment, false, 0)}
                            >
                                <img
                                    src={Download}
                                    className="favourites-download-csv-icon"
                                    alt="Download"
                                />
                                <span className="body-regular colour-link">
                                    Download CSV
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="favourites-divider-container margin-tops">
                    </div>
                    <div className="favourites-icons-container">
                        {
                            isMobile === false && innerWidth > 767 ?
                            <div className="favourites-icons-left">
                                <div className="favourites-list-grid-toggle">
                                    <img
                                        src={listView === true ? Grid : List}
                                        className=""
                                        alt={listView === true ? "Grid" : "List"}
                                        onClick={() => toggleView()}
                                    />
                                </div>
                                <div className={"favourites-select-container " + (listView === false ? "view-none" : "")}>
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="categories-select-label"
                                                id="categories"
                                                value={selectedCategories}
                                                multiple
                                                label=""
                                                placeholder="Show/hide columns"
                                                input={<CustomSelect />}
                                                renderValue={(selected) => selected.length === 1 ? "Show/hide columns" : `Columns (${selected.length - 1})`}
                                                open={selectOpen}
                                                onOpen={() => setSelectOpen(true)}
                                                onClose={() => setSelectOpen(false)}
                                            >
                                                {
                                                    favouritesCategories.map((item, index) =>
                                                        index === 0 ?
                                                        null
                                                        :
                                                        <div 
                                                            className="favourites-categories-select-element"
                                                            key={index}
                                                        >
                                                            <h3 className="label-semibold-caps colour-secondary">
                                                                {item.type}
                                                            </h3>
                                                            <div className="favourites-categories-select-subcategory">
                                                                {
                                                                    item.options.map((option, i) =>
                                                                        <MenuItem 
                                                                            key={`${i}-sub`}
                                                                            value={option.value}
                                                                            onClick={() => changeCategories(option.value)}
                                                                        >
                                                                            <div className="favourites-categories-options-row">
                                                                                <div className="favourites-categories-checkbox-container">
                                                                                    {
                                                                                        favouritesCategoriesSelected(option.value) === true ?
                                                                                        <div className="favourites-categories-checkbox">
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </div>
                                                                                <span className="body-regular colour-primary">
                                                                                    {option.label}
                                                                                </span>
                                                                            </div>
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="favourites-icons-right">
                            <div className="favourites-filter-button">

                            </div>
                            <div className="favourites-sort-container">
                                <Box>
                                    <FormControl sx={styles.formControl} fullWidth>
                                        <img
                                            src={Sort}
                                            className="pick-city-sort-icon"
                                            alt="Sort"
                                        />
                                        <Select
                                            labelId="sort-select-label"
                                            id="sort-select"
                                            value={sorting}
                                            label=""
                                            placeholder="Interest Points"
                                            onChange={(event) => changeSorting(event.target.value)}
                                            input={<SortSelect />}
                                        >
                                            {
                                                sortOptions.map((item, index) =>
                                                    <MenuItem 
                                                        key={index}
                                                        value={index}
                                                        style={index === sorting ? styles.selectedMenuItem : styles.menuItem}
                                                    >
                                                        {
                                                            index === sorting ?
                                                            <img
                                                                src={BlackTick}
                                                                className="pick-city-sorting-tick"
                                                                alt="Tick"
                                                            />
                                                            :
                                                            <div className="pick-city-sorting-tick">

                                                            </div>
                                                        }
                                                        {item.label}
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>

                            </div>
                            <div className="favourites-search-container">

                            </div>
                        </div>
                    </div>
                    {
                        selectedProperties.length === 0 ?
                        null
                        :
                        <div className="favourites-selected-properties-overlay">
                            <div className="favourites-selected-properties-left-container">
                                <div className="favourites-selected-count-container">
                                    <span className="body-regular colour-primary">
                                        {selectedProperties.length} selected
                                    </span>
                                </div>
                                <div className="favourites-selected-total-count-container">
                                    <span className="body-regular colour-tertiary">
                                        Select 2 to compare
                                    </span>
                                </div>
                            </div>
                            <div className="favourites-selected-properties-right-container">
                                <div 
                                    className="favourites-selected-remove-container"
                                    onClick={() => favouritesDisable === false ? removeFavourites() : null}
                                >
                                    <img
                                        src={Dash}
                                        className="favourites-selected-dash-icon"
                                        alt="Dash"
                                    />
                                    <span className="body-regular colour-error">
                                        Remove
                                    </span>
                                </div>
                                <div 
                                    className={"favourites-selected-download-container " + (freeTrial === true ? "favourites-free-trial" : "")}
                                    onClick={() => freeTrial === true ? null : downloadActiveProperties()}
                                >
                                    <img
                                        src={Download}
                                        className="favourites-selected-download-icon"
                                        alt="Download CSV"
                                    />
                                    <span className="body-regular colour-link">
                                        Download CSV
                                    </span>
                                </div>
                                <div className="">
                                    <ActionButton
                                        onClick={() => compareProperties()}
                                        disabled={selectedProperties.length !== 2 ? true : false}
                                    >
                                        <img
                                            src={Compare}
                                            className="favourites-selected-compare-icon"
                                            alt="Compare"
                                        />
                                        Compare
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="favourites-body-container bg-colour-light">
                        {
                            listView === true && isMobile === false && innerWidth > 767 ?
                            <FavouritesTable
                                properties={properties}
                                downPayment={downPayment}
                                selectedProperties={selectedProperties}
                                setSelectedProperties={setSelectedProperties}
                                toggleCheckbox={toggleCheckbox}
                                propertySelected={propertySelected}
                                columns={columns}
                                selectedCategories={selectedCategories}
                                neighbourhoodData={neighbourhoodData}
                            />
                            :
                            <FavouritesGrid
                                properties={properties}
                                selectedProperties={selectedProperties}
                                setSelectedProperties={setSelectedProperties}
                                userDetails={userDetails}
                                toggleCheckbox={toggleCheckbox}
                                propertySelected={propertySelected}
                                innerWidth={innerWidth}
                                setProperties={setProperties}
                            />
                        }
                        {
                            loading === true ?
                            <div className="favourites-loading-container">
                                {View}
                            </div>
                            :
                            loading === false && properties.length === 0 ?
                            <div className="favourites-no-entries-container">
                                <h3 className="heading-small-semibold colour-primary text-align-center">
                                    No properties added to favorites
                                </h3>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Favourites;