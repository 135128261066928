import React, { useState } from 'react';
import { FixerUpper, BedroomBlack, ADU, HandShake } from '../assets';
import '../styles/MultipleBadges.css';

function MultipleBadges(props) {
	const property = props.property;
	const strategy = props.strategy;
	const topPickVal = strategy === 0 ? property.topPick
		:
		property.topPickOptions !== undefined && strategy === 1 ?
		property.topPickOptions.traditional
		:
		property.topPickOptions !== undefined && strategy === 2 ?
		property.topPickOptions.houseHacking
		:
		property.topPickOptions !== undefined && strategy === 3 ?
		property.topPickOptions.brrrr
		:
		property.topPickOptions !== undefined && strategy === 4 ?
		property.topPickOptions.fixAndFlip
		:
		property.topPickOptions !== undefined && strategy === 5 ?
		property.topPickOptions.hiddenGem
		:
		false;
	
	const [hoverIndex, setHoverIndex] = useState(null);
	const [hoverOutIndex, setHoverOutIndex] = useState(null);

	const badges = [
		{
			label: "Top Pick",
			visible: topPickVal,
			emoji: "🔥",
			icon: ""
		},
		{
			label: "Fixer Upper",
			visible: property.fixerUpper,
			emoji: "",
			icon: FixerUpper
		},
		{
			label: "Cash Only",
			visible: property.cashOnly,
			emoji: "💵",
			icon: ""
		},
		{
			label: "Add Bedroom",
			visible: property.addBedOpportunity,
			emoji: "",
			icon: BedroomBlack
		},
		{
			label: "ADU Eligible",
			visible: property.zonedForAdu,
			emoji: "",
			icon: ADU
		},
		{
			label: "Seller Financing",
			visible: property.ownerFinancingFlag,
			emoji: "",
			icon: HandShake
		}
	];

	const hoverIn = (index) => {
		setHoverIndex(index)
		setHoverOutIndex(null);
	};

	const hoverOut = (index) => {
		setHoverIndex(null);
		setHoverOutIndex(index);


		setTimeout(() => {
			setHoverOutIndex(null);
		}, 250);
	};

	return (
		<div className="multiple-badges-outer-container">
			{
				badges.map((item, index) =>
					item.visible === true ?
					<div 
						className={"multiple-badges-element " + (index === hoverIndex ? "multiple-badges-element-hover" : "multiple-badges-element-hover-out") + (hoverIndex !== index && hoverOutIndex !== index ? " multiple-badges-text-none" : "")}
						key={index}
						onMouseOver={() => hoverIn(index)}
						onMouseOut={() => hoverOut(index)}
					>
						{
							item.emoji === "" ?
							<img
								src={item.icon}
								className="multiple-badges-icon"
								alt={item.label}
							/>
							:
							<span className="multiple-badges-emoji">
								{item.emoji}
							</span>
						}
						<span className="multiple-badges-text">
							{item.label}
						</span>
					</div>
					:
					null
				)
			}
		</div>
	)
}

export default MultipleBadges;