import React from 'react';
import '../styles/RepeatUser.css';
import { ActionButton } from '../styles/GlobalStyles';
import { Footer, Header } from '../components';
import { recordEvent } from '../functions';

function RepeatUser() {

    const openEmail = () => {
        recordEvent("Repeat User - Contact Us Clicked", {});
        window.location.href = "mailto:info@coffeeclozers.com";
    };

    return (
        <div className="repeat-user-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="repeat-user-inner-container">
                <h1 className="heading-large-semibold margin-large">
                    Have you already had your free trial? 🤔
                </h1>
                <div className="repeat-user-body-container">
                    <p className="body-regular colour-primary">
                        We may be mistaken but we think you've already
                        used your free trial on another account.
                        <br/>
                        <br/>
                        Please contact us if you feel this is a mistake.
                    </p>
                </div>
                <img
                    src="https://media.tenor.com/eh5z0wkCNzgAAAAd/naughty-naughty-naughty.gif"
                    className="borat-image"
                    alt="Multiple Free Trials Used"
                />
                <div className="repeat-user-button-container">
                    <ActionButton
                        onClick={() => openEmail()}
                    >
                        Contact us
                    </ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RepeatUser;