import React from 'react';

function PropertyNeighbourhoodBadge(props) {
    const zipType = props.zipType;
    const badgeText = props.badgeText;

    return (
        <div className={"neighbourhood-badge-outer-container " + (zipType)}>
            <span className={"body-regular " + (zipType === "map-zone-d" ? "colour-white" : "colour-primary")}>
                {badgeText}
            </span>
        </div>
    )
}

export default PropertyNeighbourhoodBadge;