import React from 'react';
import '../styles/BuyBoxSelector.css';
import { Arrow } from '../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkWhichAddCityPath, getBuyBoxFilterParams } from "../functions";

function BuyBoxSelector(props) {
    const buyBoxes = props.buyBoxes;
    const searchParams = props.searchParams;
    const cityId = props.cityId;
    const freeTrial = props.freeTrial;
    const subscriptions = props.subscriptions;
    const selectedBuyBox = searchParams.get("buyBox") !== null ? JSON.parse(searchParams.get("buyBox")) : null;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const addCity = async() => {
        const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
        const chosenPath = checkPath.path;
        const newState = checkPath.state;

        navigate(chosenPath, {
            state: newState === null ? state : newState
        });
    };

    const chooseCity = (item) => {
        if ( item.metroArea === true ) {
            if ( item.msaCode.includes("CTY") ) {
                window.open(`/county-properties-list/${item.msaCode}`, "_blank");
            }
            else {
                window.open(`/metro-properties-list/${item.msaCode}/${item.msaCityIds[0]}`, "_blank");
            }
        }
        else {
            window.open(`/properties-list/${item.cityId}`, "_blank");
        }
    };

    const chooseBuyBox = async(item) => {
        const filters = await getBuyBoxFilterParams(item);
        if ( item.metroArea === true ) {
            const cityIndex = buyBoxes.findIndex(e => e.msaCode === item.msaCode);
            if ( cityIndex !== -1 ) {
                const cityObject = buyBoxes[cityIndex];
                const subscriptionIndex = subscriptions.findIndex(e => e.msaCode === cityObject.msaCode && e.endDate.seconds > new Date().getTime() / 1000);
                if ( subscriptionIndex !== -1 ) {
                    if ( item.msaCode.includes("CTY") ) {
                        window.open(`/county-properties-list/${item.msaCode}`, "_blank");
                    }
                    else {
                        const relevantSubscription = subscriptions[subscriptionIndex];
                        const msaIds = relevantSubscription.msaCityIds;
                        window.open(`/metro-properties-list/${item.msaCode}/${msaIds[0]}?${filters}`, "_blank");
                    }
                }
            }
        }
        else {
            window.open(`/properties-list/${item.cityId}?${filters}`, "_blank");
        }
    };

    return (
        <div className="buy-box-selector-outer-container bg-colour-white">
            <div className="buy-box-selector-inner-container">
                <div className="buy-box-selector-title-container">
                    <span className="label-semibold-caps colour-secondary">
                        My cities and buy boxes
                    </span>
                </div>
                {
                    buyBoxes.map((item, index) =>
                        <div 
                            className="buy-box-selector-city-container"
                            key={index}
                        >
                            <div 
                                className={"buy-box-selector-element-container " + (cityId === item.cityId && selectedBuyBox === null ? "buy-box-selector-selected-element-container" : "")}
                                onClick={() => chooseCity(item)}
                            >
                                <span className="body-semibold colour-primary">
                                    {
                                        item.metroArea === true ?
                                        item.msaTitle
                                        :
                                        `${item.city}, ${item.state}`
                                    }
                                </span>
                            </div>
                            {
                                item.buyBoxes.map((buyBox, ii) =>
                                <div 
                                    className={"buy-box-selector-element-container " + (cityId === item.cityId && selectedBuyBox === buyBox.id ? "buy-box-selector-selected-element-container" : "")}
                                    key={ii}
                                    onClick={() => chooseBuyBox(buyBox)}
                                >
                                    <span className="body-regular colour-primary">
                                        {buyBox.title}
                                    </span>
                                </div>
                                )
                            }
                            <div className="buy-box-divider-line margin-tops">
                                <div className="buy-box-divider-inner-line">
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="buy-box-selector-add-city-container">
                    <div 
                        className="button-row text-button"
                        onClick={() => addCity()}
                    >
                        <span className="body-regular colour-link block-text subtext-margin-right">
                            Add a market
                        </span>
                        <img
                            src={Arrow}
                            className="buy-box-selector-arrow"
                            alt="Arrow"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BuyBoxSelector;