import React from 'react';
import '../styles/DefaultTooltip.css';
import { ChangeDot } from '../assets';
import { recordEvent } from '../functions';

function StrategyTooltip(props) {
    const title = props.title;
    const description = props.description;
    const changeIcon = props.changeIcon;
    const link = props.link;
    const index = props.index;
    const strategyTooltipClass = `strategy-tooltip-container-${index}`;

    const openLink = () => {
        window.open(link, "_blank");
        recordEvent("STRATEGY_TOOLTIP_LINK_CLICKED", { "title": title });
    };

    return (
        <div className={"default-tooltip-container " + (strategyTooltipClass)}>
            <h5 className="body-semibold margin-x-small colour-primary">
                {
                    changeIcon === true ?
                    <img
                        src={ChangeDot}
                        className="chip-change-icon"
                        alt="Changes Made"
                    />
                    :
                    null
                }
                {title}
            </h5>
            <span className="tooltip-regular colour-primary">
                {description}
            </span>
            {
                link !== null ?
                <div className="default-tooltip-link-container margin-top-small">
                    <div 
                        className="button-row text-button"
                        onClick={() => openLink()}
                    >
                        <span className="body-regular-underline colour-link block-text subtext-margin-right">
                            Learn more
                        </span>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                        >
                            <path 
                                d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                fill="#2F4858"
                            />
                        </svg>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}

export default StrategyTooltip;