import React from 'react';
import { formatterLong } from '../styles/GlobalStyles';

function ARVBadge(props) {
    const arvPrice = props.arvPrice;
    return (
        <div className="arv-badge-container bg-colour-white">
            <div className="arv-badge-inner-container">
                <span className="label-semibold colour-primary block-text subtext-margin-right">
                    ARV
                </span>
                <span className="label-regular colour-primary">
                    {formatterLong.format(arvPrice).replace(".00", "")}
                </span>
            </div>
        </div>
    )
};

export default ARVBadge;