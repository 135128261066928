import React, { useState } from 'react';
import '../styles/Location.css';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { LocationMap } from './';
import { CustomSelect, colour } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function Location(props) {

	const property = props.property;
	const cityState = `${property.city} ${property.state}`;
	const neighbourhoodData = props.neighbourhoodData;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const hasUndefinedNeighborhoodGrade = neighbourhoodData.some(e => e.neighborhoodGrade === undefined);
	const setShowUpgradeModal = props.setShowUpgradeModal;
	const [neighbourhoodOption, setNeighbourhoodOption] = useState(hasUndefinedNeighborhoodGrade === true ? 1 : 0);

	const lowerCaseCity = cityState.toLowerCase();
	const formattedCity = lowerCaseCity.replace(" ", "-");
	const checkFullStop = formattedCity.replace(".", "");
	const crimeGradeURL = `https://crimegrade.org/safest-places-in-${checkFullStop}/`;

	const neighbourhoodDataOptions = [
		{
			label: "Neighborhood grade",
			location: ""
		},
		{
			label: "Median household income",
			location: "economics",
			subLocation: "medianHouseholdIncome"
		},
		{
			label: "Education (graduate degree)",
			location: "economics",
			subLocation: "ratioEducationGraduateDegree",
		},
		{
			label: "Employed",
			location: "economics",
			subLocation: "ratioEmployed"
		},
		{
			label: "Owner occupied",
			location: "economics",
			subLocation: "ratioOwnerOccupied"
		},
		{
			label: "Vacant",
			location: "economics",
			subLocation: "ratioVacant"
		}
	];

	const openCrimeMap = () => {
		window.open(crimeGradeURL, "_blank");
		recordEvent("Open Crime Map", {
			city: cityState
		});
	};

	const openUpgradeModal = () => {
		if ( setShowUpgradeModal !== null ) {
			setShowUpgradeModal(true);
		}
		recordEvent("Open Upgrade Modal Text", {
			city: cityState
		});
	};

	const onChangeMapOption = (val) => {
		if ( val === 0 && hasUndefinedNeighborhoodGrade === true ) {
			if ( setShowUpgradeModal !== null ) {
				setShowUpgradeModal(true);
			}
			recordEvent("Open Upgrade Modal Selector", {
				city: cityState
			});
		}
		else {
			setNeighbourhoodOption(val);
			recordEvent("Change Property Neighbourhood Map", {
				city: cityState,
				neighbourhoodData: neighbourhoodDataOptions[val].label
			});
		}
	};

	const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
		menuItemZIPs: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
			padding: '8px 36px'
		}
	};

	return (
		<div className="location-outer-container">
			<div className="location-inner-container">
				<div className="location-top-bar-container margin-medium">
					<div className="location-title-container">
						<h2 className="heading-small-semibold colour-primary">
							Location
						</h2>
					</div>
					<div className="location-top-selector-container">
						{
							hasUndefinedNeighborhoodGrade === true ?
							<div className="location-upgrade-prompt-container">
								<div 
									className="text-button button-row"
									onClick={() => openUpgradeModal()}
								>
									<span className="body-regular colour-link block-text subtext-margin-right">
										🚀 Browse on-market listings in this city
									</span>
									<svg 
										xmlns="http://www.w3.org/2000/svg" 
										width="16" 
										height="16" 
										viewBox="0 0 16 16" 
										fill="none"
									>
										<path 
											d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
											fill="#2F4858"
										/>
									</svg>
								</div>
							</div>
							:
							null
						}
						<Box>
							<FormControl fullWidth>
								<Select
									labelId="neighbourhood-option-select-label"
                                    id={`neighbourhood-option-select`}
									value={neighbourhoodOption}
									label=""
									onChange={(event) => onChangeMapOption(event.target.value)}
									input={<CustomSelect />}
								>
									{
                                        neighbourhoodDataOptions.map((item, index) =>
                                            <MenuItem
                                                key={index}
                                                value={index}
                                                style={styles.menuItem}
                                                disabled={false}
                                            >
                                                {item.label}
												{
													hasUndefinedNeighborhoodGrade === true && index === 0 ?
													<div className="location-locked-neighbourhood-grade-container">
														<svg 
															xmlns="http://www.w3.org/2000/svg" 
															width="16" 
															height="16" 
															viewBox="0 0 16 16" 
															fill="none"
														>
															<path 
																fillRule="evenodd" 
																clipRule="evenodd" 
																d="M3.14387 5.70336C3.19648 2.11915 5.44553 4.65982e-07 7.99966 0C10.5538 -5.26632e-07 12.8028 2.11915 12.8554 5.70336H13.7891C14.0023 5.70336 14.1751 5.87624 14.1751 6.08949V14.6164C14.1751 15.3805 13.5559 16 12.7921 16H3.20726C2.44343 16 1.82422 15.3805 1.82422 14.6164V6.08949C1.82422 5.87624 1.99704 5.70336 2.21018 5.70336H3.14387ZM5.20257 5.70336C5.25051 3.05864 6.77638 2.05933 7.99966 2.05933C9.22293 2.05933 10.7488 3.05864 10.7967 5.70336H5.20257ZM8.80645 10.9138C9.15545 10.6626 9.3827 10.2528 9.3827 9.78984C9.3827 9.02569 8.76348 8.40623 7.99966 8.40623C7.23583 8.40623 6.61662 9.02569 6.61662 9.78984C6.61662 10.2396 6.83112 10.6392 7.16334 10.8919L6.90914 12.9764C6.89042 13.1298 7.01006 13.265 7.16453 13.265H8.80262C8.95709 13.265 9.07674 13.1298 9.05801 12.9764L8.80645 10.9138Z" 
																fill="#2F4858"
															/>
														</svg>
													</div>
													:
													null
												}
                                            </MenuItem>	
                                        )
                                    }
								</Select>
							</FormControl>
						</Box>
					</div>
				</div>
				<LocationMap
					property={property}
					controls={false}
					text={true}
					neighbourhoodBounds={neighbourhoodBounds}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodOption={neighbourhoodOption}
					neighbourhoodDataOptions={neighbourhoodDataOptions}
				/>
				<div className="location-crime-map-container">
					<span 
						className="body-regular-underline colour-link"
						onClick={() => openCrimeMap()}
					>
						View crime map
					</span>
				</div>
			</div>
		</div>
	)
}

export default Location;