import React from 'react';
import '../styles/FSBOOnly.css';
import { PrimaryButton } from '../buttons';
import { useNavigate } from 'react-router-dom';

function FSBOOnly() {

    const navigate = useNavigate();
    
    const complete = () => {
        navigate("/pricing");
    };

    return (
        <div className="fsbo-only-outer-container">
            <div className="fsbo-only-inner-container">
                <h2 className="heading-semibold colour-primary margin-x-small">
                    This market is FSBO only
                </h2>
                <h3 className="body-regular colour-secondary margin-medium">
                    Unfortunately we only have For-Sale-By-Owner data for this market at the moment. You can upgrade
                    to the Creative Finance product to access nationwide FSBO data, or you can unlock this specific market
                    for agent listings by picking up another Traditional subscription.
                    <br />
                    <br />
                    New users are adding more counties to our site every day, so there's also a chance that we'll
                    have agent listings for this market pretty soon 😊
                </h3>
                <div className="fsbo-only-button-container">
                    <PrimaryButton
                        text="Unlock market"
                        cta={complete}
                        disabled={false}
                        size="medium"
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
};

export default FSBOOnly;