import React, { useRef, useState } from 'react';
import '../styles/NewProperty.css';
import { Header, Footer, StickyROI, ImageGrid, FinancialsPanel, ContactAgent, TopPickElement, TopPickFire, ShareButtons, PropertyAbstract, PropertyDetails, Schools, MobileBottomBar, OwnerDetails, PurchaseDetails, OffMarketImageContainer, Comparables, OldLocation } from '../components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toggleFavourite, downloadExcel } from '../functions';
import { formatterCompact, formatterLong } from '../styles/GlobalStyles';
import NeighbourhoodScores from "../components/NeighbourhoodScores";
import GentrificationScores from "../components/GentrificationScores";
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';
import { PropertyEffect } from '../queries';
import { PropertyPDF } from '../pdfs';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function OldProperty() {

	const params = useParams();
	const cityId = decodeURIComponent(params.cityId);
	const id = decodeURIComponent(params.id);
	const [property, setProperty] = useState([]);
	const [favourites, setFavourites] = useState([]);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [editedProperty, setEditedProperty] = useState("");
	const [userDetails, setUserDetails] = useState(null);
	const [downPayment, setDownPayment] = useState("");
	const [interestRate, setInterestRate] = useState("");
	const [closingPercentage, setClosingPercentage] = useState("");
	const [topPickPopUp, setTopPickPopUp] = useState(true);
	const [highLevelProfits, setHighLevelProfits] = useState("");
	const [addBedroom, setAddBedroom] = useState(false);
	const [queryMade, setQueryMade] = useState(false);
	const [bugModal, setBugModal] = useState(false);
	const [zipData, setZipData] = useState([]);
	const [bounds, setBounds] = useState([]);
	const [zipNames, setZipNames] = useState([]);
	const [freeTrial, setFreeTrial] = useState(true);
	const [neighbourhoodTooltipIndex, setNeighbourhoodTooltipIndex] = useState(0);
	const [neighbourhoodTooltipShow, setNeighbourhoodTooltipShow] = useState(false);
	const [tooltipType, setTooltipType] = useState("");
	const [mobileBar, setMobileBar] = useState(false);
	const [gentrificationData, setGentrificationData] = useState([]);
	const [gentrificationBounds, setGentrificationBounds] = useState([]);
	const [blurred, setBlurred] = useState(false);
	const [loading, setLoading] = useState(true);
	const [usePropertySearch, setUsePropertySearch] = useState(false);
	const [recurringCosts, setRecurringCosts] = useState([]);
	const [renovationRadio, setRenovationRadio] = useState(0);
	const [spreadsheetProperty, setSpreadsheetProperty] = useState(null);
	const [costPerSqFoot, setCostPerSqFoot] = useState(0);
	const [totalRehabCost, setTotalRehabCost] = useState(0);
	const [defaultCostPerSqFoot, setDefaultCostPerSqFoot] = useState(0);
	const [cityState, setCityState] = useState("");
	const [userSettings, setUserSettings] = useState(null);
	const [subscriptions, setSubscriptions] = useState([]);
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;
	const [selectedCompsPanel, setSelectedCompsPanel] = useState(0);
	const compsRef = useRef(null);

	const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

	const toggleTopPick = () => {
		setTopPickPopUp(!topPickPopUp);
	};

	const toggleTooltip = (open, index, type) => {
		setNeighbourhoodTooltipIndex(index);
		setNeighbourhoodTooltipShow(open);
		setTooltipType(type);
	};

	const toggleMobileBottomBar = () => {
		if ( mobileBar === true ) {
			document.body.removeAttribute("style");
		}
		else {
			document.body.style = "overflow: hidden";
			setTopPickPopUp(false);
		}
		setMobileBar(!mobileBar);
	};

	const downloadPDF = async() => {
		if ( property.length !== 0 ) {
			const doc = <PropertyPDF property={property} propertySearch={false} partner={false} highLevelProfits={highLevelProfits} />;
			const asPdf = pdf([]); // {} is important, throws without an argument
			asPdf.updateContainer(doc);
			const blob = await asPdf.toBlob();
			saveAs(blob, `${property.address.streetAddress}, ${property.address.city}, ${property.address.zipcode}.pdf`);
		}
	};

	return (
		<div className={"new-property-outer-container " + (bugModal === true ? "new-property-outer-container-relative" : mobileBar === true ? "new-property-mobile-bar-active" : "") + (blurred === true ? " new-property-blurred" : "")}>
			<Header
				subscriptions={subscriptions}
                users={userDetails}
                queries={[false, false]}
				mobileNav={true}
			/>
			<PropertyEffect
				cityId={cityId}
				setProperty={setProperty}
				setMobileBar={setMobileBar}
				setFreeTrial={setFreeTrial}
				setUserDetails={setUserDetails}
				setFavourites={setFavourites}
				navigate={navigate}
				setBlurred={setBlurred}
				id={id}
				setUsePropertySearch={setUsePropertySearch}
				setAddBedroom={setAddBedroom}
				editedProperty={editedProperty}
				queryMade={queryMade}
				setQueryMade={setQueryMade}
				setLoading={setLoading}
				setEditedProperty={setEditedProperty}
				setDownPayment={setDownPayment}
				setInterestRate={setInterestRate}
				setClosingPercentage={setClosingPercentage}
				setTotalRehabCost={setTotalRehabCost}
				setCostPerSqFoot={setCostPerSqFoot}
				setDefaultCostPerSqFoot={setDefaultCostPerSqFoot}
				setHighLevelProfits={setHighLevelProfits}
				setRecurringCosts={setRecurringCosts}
				setSpreadsheetProperty={setSpreadsheetProperty}
				setZipData={setZipData}
				setGentrificationData={setGentrificationData}
				setGentrificationBounds={setGentrificationBounds}
				setZipNames={setZipNames}
				setBounds={setBounds}
				propertySearch={false}
				partner={false}
				state={state}
				setCityState={setCityState}
				setUserSettings={setUserSettings}
				setSubscriptions={setSubscriptions}
				setUserId={null}
				testMode={false}
			/>
			{
				bugModal === true ?
				<div className="new-property-outer-container-bug-modal"></div>
				:
				null
			}
			<div className="property-relative-container">
				{
					property.length === 0 || highLevelProfits === "" || userDetails === null ?
					null
					:
					<StickyROI
						formatter={formatterLong}
						highLevelProfits={highLevelProfits}
						toggleFavourite={toggleFavourite}
						favourites={favourites}
						setFavourites={setFavourites}
						setFavouriteDisable={setFavouriteDisable}
						navigate={navigate}
						favouriteDisable={favouriteDisable}
						freeTrial={freeTrial}
						downloadCSV={downloadExcel}
						property={property}
						cityId={cityId}
						downPayment={downPayment}
						blurred={blurred}
						spreadsheetProperty={spreadsheetProperty}
						interestRate={interestRate}
						propertySearch={false}
						partner={false}
						partnerId={null}
						partnerView={false}
						downloadPDF={downloadPDF}
						strategyPanel={0}
						oldFormat={true}
					/>
				}
			</div>
			{
				loading === true ?
				<div className="new-property-loading-container">
					{View}
				</div>
				:
				usePropertySearch === true ?
				<div className="new-property-loading-container">
					<h2 className="new-property-not-found-text">
						Hmmm we couldn't find this property
					</h2>
					<span className="body-regular colour-primary">
						Please search for its address <span onClick={() => navigate("/quick-report")} className="underline colour-link">right here</span>
					</span>
				</div>
				:
				queryMade === true && property.length === 0 ?
				<div className="new-property-body-container">
					<h2 className="new-property-not-found-text">
						No property found {":("}
					</h2>
				</div>
				:
				property.length !== 0 && userDetails !== null ?
				<div className={"new-property-body-container " + (mobileBar === true ? "relative-container" : "") + (property.offMarket === true ? " new-property-off-market" : "")}>
					{
						property.topPick === true && blurred === false && property.offMarket === false ?
						<TopPickElement
							property={property}
							toggleElement={toggleTopPick}
							topPickPopUp={topPickPopUp}
						/>
						:
						null
					}
					{
						property.topPick === true && blurred === false && property.offMarket === false ?
						<TopPickFire
							toggleElement={toggleTopPick}
						/>
						:
						null
					}
					{
						property.offMarket === false ?
						<ImageGrid 
							property={property}
							images={property.images !== undefined ? property.images : [property.imgSrc]}
							title={property.address.streetAddress}
							preview={false}
							blurred={blurred}
						/>
						:
						null
					}
					<div className="new-property-inner-body-container">
						<div className="new-property-inner-body-left-container">
							{
								property.offMarket === true ?
								<OffMarketImageContainer
									property={property}
								/>
								:
								null
							}
							<PropertyAbstract
								property={property}
								formatter={formatterLong}
								propertySearch={false}
								queryARV={null}
								arvLoading={false}
								arvErrorMessage={null}
								partner={false}
								strategy={null}
								runComps={null}
							/>
							<PropertyDetails
								property={property}
								formatter={formatterLong}
								propertySearch={false}
							/>
							{
								property.offMarket === true ?
								<OwnerDetails
									property={property}
									blurred={blurred}
									freeTrial={freeTrial}
								/>
								:
								null
							}
							{
								property.offMarket === true ?
								<PurchaseDetails
									property={property}
								/>
								:
								null
							}
							{
								zipData.length !== 0 ?
								<NeighbourhoodScores
									property={property}
									zipData={zipData}
									formatter={formatterLong}
									toggleTooltip={toggleTooltip}
									neighbourhoodTooltipShow={neighbourhoodTooltipShow}
									neighbourhoodTooltipIndex={neighbourhoodTooltipIndex}
									tooltipType={tooltipType}
								/>
								:
								null
							}
							{
								gentrificationData.length !== 0 ?
								<GentrificationScores
									property={property}
									gentrificationData={gentrificationData}
									toggleTooltip={toggleTooltip}
									formatter={formatterLong}
									neighbourhoodTooltipShow={neighbourhoodTooltipShow}
									neighbourhoodTooltipIndex={neighbourhoodTooltipIndex}
									tooltipType={tooltipType}
								/>
								:
								null
							}
							<Schools
								property={property}
							/>
						</div>
						{
							editedProperty !== "" ?
							<FinancialsPanel
								property={property}
								userDetails={userDetails}
								editedProperty={editedProperty}
								formatter={formatterLong}
								downPayment={downPayment}
								interestRate={interestRate}
								closingPercentage={closingPercentage}
								setHighLevelProfits={setHighLevelProfits}
								setDownPayment={setDownPayment}
								setInterestRate={setInterestRate}
								setClosingPercentage={setClosingPercentage}
								setEditedProperty={setEditedProperty}
								formatterCompact={formatterCompact}
								addBedroom={addBedroom}
								setAddBedroom={setAddBedroom}
								bugModal={bugModal}
								setBugModal={setBugModal}
								highLevelProfits={highLevelProfits}
								mobileBar={mobileBar}
								blurred={blurred}
								recurringCosts={recurringCosts}
								setRecurringCosts={setRecurringCosts}
								renovationRadio={renovationRadio}
								setRenovationRadio={setRenovationRadio}
								spreadsheetProperty={spreadsheetProperty}
								setSpreadsheetProperty={setSpreadsheetProperty}
								setProperty={setProperty}
								costPerSqFoot={costPerSqFoot}
								setCostPerSqFoot={setCostPerSqFoot}
								totalRehabCost={totalRehabCost}
								setTotalRehabCost={setTotalRehabCost}
								defaultCostPerSqFoot={defaultCostPerSqFoot}
								partnerData={null}
								userSettings={userSettings}
							/>
							:
							null
						}
					</div>
					<OldLocation
						property={property}
						zipData={zipData}
						bounds={bounds}
						zipNames={zipNames}
						cityId={cityId}
						gentrificationData={gentrificationData}
						gentrificationBounds={gentrificationBounds}
						cityState={cityState}
					/>
					{
						property.rentComps !== null || property.unitPropertyComps !== undefined ?
						<Comparables
							property={property}
							blurred={blurred}
							propertySearch={false}
							selectedPanel={selectedCompsPanel}
							setSelectedPanel={setSelectedCompsPanel}
							compsRef={compsRef}
							savedData={null}
							setSavedData={null}
						/>
						:
						null
					}
					<div className="new-property-bottom-body-container">
						{
							property.listedBy === null || property.attributionInfo === null ?
							null
							:
							<ContactAgent
								property={property}
								partnerData={null}
								state={state}
							/>
						}
						<ShareButtons
							property={property}
							favourites={favourites}
							toggleFavourite={toggleFavourite}
							favouriteDisable={favouriteDisable}
							setFavourites={setFavourites}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							userDetails={userDetails}
							freeTrial={freeTrial}
							spreadsheetProperty={spreadsheetProperty}
							downPayment={downPayment}
							propertySearch={false}
							partner={false}
							downloadPDF={downloadPDF}
							strategyPanel={0}
						/>
					</div>
					{
						property.length === 0 || highLevelProfits === "" || userDetails === null ?
						null
						:
						<MobileBottomBar
							highLevelProfits={highLevelProfits}
							formatter={formatterLong}
							toggleMobileBottomBar={toggleMobileBottomBar}
							mobileBar={mobileBar}
							blurred={blurred}
							strategy={0}
							favourites={favourites}
							setFavourites={setFavourites}
							favouriteDisable={favouriteDisable}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							property={property}
							newVersion={false}
						/>
					}
				</div>
				:
				null
			}
			{
				property.length !== 0 ?
				<Footer />
				:
				null
			}
		</div>
	)
}

export default OldProperty;