import React, { Fragment, useState } from 'react';
import '../styles/RehabTab.css';
import { FinancialsInputNoLabel, FinancialsInput, formatterLong } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { DefaultTooltip, MulticolourSlider, SliderText } from '../components';
import { ChangeDot } from '../assets';

function RehabTab(props) {
    const property = props.property;
    const strategyPanel = props.strategyPanel;
    const costPerSqFoot = props.costPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const defaultTotalCost = props.defaultTotalCost;
    const onChangeCostPerSqFoot = props.onChangeCostPerSqFoot;
    const onChangeTotalCost = props.onChangeTotalCost;
    const changeRehabCost = props.changeRehabCost;
    const arv = props.arv;
    const onChangeARV = props.onChangeARV;
    const onChangeARVSlider = props.onChangeARVSlider;
    const customArv = props.customArv;
    const maxPercentage = props.maxPercentage;
    const defaultArv = props.defaultArv;
    const viewArvComps = props.viewArvComps;
    const [arvTooltip, setArvTooltip] = useState(false);

    return (
        <div className="rehab-tab-outer-container">
            {
                strategyPanel < 2 ?
                <div className="rehab-tab-inner-container">
                    <div className="rehab-tab-title-container margin-x-small">
                        <h3 className="body-semibold colour-primary">
                            Estimate minor repairs
                        </h3>
                    </div>
                    <div className={"strategies-panel-financials-input " + (totalRehabCost === defaultTotalCost ? "margin-large" : "")}>
                        <FinancialsInputNoLabel
                            value={totalRehabCost}
                            type="number"
                            label={
                                Number(totalRehabCost) !== defaultTotalCost ?
                                <Fragment>
                                    <img
                                        src={ChangeDot}
                                        className="financials-panel-change-dot"
                                        alt="Change"
                                    />
                                </Fragment>
                                :
                                null
                            }
                            id={`total-rehab-input`}
                            placeholder={`${totalRehabCost}`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={false}
                            onChange={(text) => onChangeTotalCost(text.target.value)}
                        />
                    </div>
                    {
                        totalRehabCost === defaultTotalCost ?
                        null
                        :
                        <div className="rehab-tab-rehab-container margin-large">
                            <span 
                                className="label-semibold colour-link"
                                onClick={() => onChangeTotalCost(defaultTotalCost)}
                            >
                                Reset
                            </span>
                        </div>
                    }
                    <div className="rehab-tab-total-slider-container">
                        <Stack 
                            spacing={2} 
                            direction="row" 
                            sx={{ mb: 1, px: 1 }} 
                            alignItems="center"
                        >
                            <Slider
                                aria-label="Minor repairs"
                                value={Number(costPerSqFoot)}
                                onChange={changeRehabCost}
                                min={0}
                                max={25}
                                sx={{
                                    color: '#CFBCAD',
                                    height: '5px',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: 24,
                                        height: 24,
                                        backgroundColor: '#fff',
                                        '&:before': {
                                            boxShadow: '0px 0px 10px 0px #99959180',
                                        },
                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                            boxShadow: 'none',
                                        },
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                </div>
                :
                <div className="rehab-tab-inner-container">
                    <div className="rehab-tab-title-container margin-x-small">
                        <h3 className="body-semibold colour-primary">
                            Estimate rehab cost
                        </h3>
                    </div>
                    <div className={"rehab-tab-inputs-row " + (totalRehabCost === defaultTotalCost ? "margin-medium" : "")}>
                        <div className="rehab-tab-input-element subtext-margin-right">
                            <FinancialsInput
                                value={costPerSqFoot}
                                type="number"
                                label={
                                    Number(totalRehabCost) !== defaultTotalCost ?
                                    <Fragment>
                                        Per squarefoot
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    `Per squarefoot`
                                }
                                id={`cost-per-sq-foot-input`}
                                placeholder={`30`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                                onChange={(text) => onChangeCostPerSqFoot(text.target.value)}
                            />
                            <span className="body-regular colour-secondary block-text subtext-margin-left">
                                x{property.livingArea}
                            </span>
                        </div>
                        <div className="rehab-tab-input-element">
                            <span className="body-regular colour-secondary block-text subtext-margin-right">
                                =
                            </span>
                            <FinancialsInput
                                value={totalRehabCost}
                                type="number"
                                label={
                                    Number(totalRehabCost) !== defaultTotalCost ?
                                    <Fragment>
                                        Total
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    `Total`
                                }
                                id={`total-cost-input`}
                                placeholder={`40,000`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                                onChange={(text) => onChangeTotalCost(text.target.value)}
                            />
                        </div>
                    </div>
                    {
                        totalRehabCost === defaultTotalCost ?
                        null
                        :
                        <div className="rehab-tab-rehab-container margin-medium">
                            <span 
                                className="label-semibold colour-link"
                                onClick={() => onChangeTotalCost(defaultTotalCost)}
                            >
                                Reset
                            </span>
                        </div>
                    }
                    <div className="rehab-tab-slider-container margin-x-large">
                        <Stack 
                            spacing={2} 
                            direction="row" 
                            sx={{ mb: 1, px: 1 }} 
                            alignItems="center"
                        >
                            <Slider
                                aria-label="Total rehab cost"
                                value={Number(costPerSqFoot)}
                                onChange={changeRehabCost}
                                min={0}
                                max={100}
                                disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                                sx={{
                                    color: '#CFBCAD',
                                    height: '5px',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: 24,
                                        height: 24,
                                        backgroundColor: '#fff',
                                        '&:before': {
                                            boxShadow: '0px 0px 10px 0px #99959180',
                                        },
                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                            boxShadow: 'none',
                                        },
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                    <div className="rehab-tab-arv-container">
                        <div className="rehab-tab-arv-title-row margin-x-small">
                            <h3 className="body-semibold colour-primary subtext-margin-right">
                                After Repair Value (ARV)
                            </h3>
                            <div 
                                className="rehab-tab-arv-tooltip-container cursor-pointer relative-container"
                                onMouseEnter={() => setArvTooltip(true)}
                                onMouseLeave={() => setArvTooltip(false)}
                            >
                                <svg 
                                    className="value-add-opportunity-tooltip-icon"
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                >
                                    <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                    <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                                </svg>
                                {
                                    arvTooltip === true ?
                                    <DefaultTooltip
                                        title="After Repair Value (ARV)"
                                        description={`This is the estimated selling price of the property after repairs are completed, indicating your potential profit.${"\n"}${"\n"}It is currently only available on Single Family Homes that are marked with the "Fixer Upper" tag (MFH coming soon)!`}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="strategies-panel-financials-input margin-medium">
                            <div className="rehab-tab-input-row">
                                <div className="rehab-tab-arv-input-container">
                                    <FinancialsInputNoLabel
                                        value={arv}
                                        type="number"
                                        label={
                                            Number(arv) !== Number(defaultArv) ?
                                            <Fragment>
                                                <img
                                                    src={ChangeDot}
                                                    className="financials-panel-change-dot"
                                                    alt="Change"
                                                />
                                            </Fragment>
                                            :
                                            null
                                        }
                                        id={`total-arv-input`}
                                        placeholder={`${arv}`}
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        disabled={false}
                                        onChange={(text) => onChangeARV(text.target.value)}
                                    />
                                </div>
                                {
                                    arv === defaultArv ?
                                    null
                                    :
                                    <span 
                                        className="label-semibold colour-link"
                                        onClick={() => onChangeARV(defaultArv)}
                                    >
                                        Reset
                                    </span>
                                }
                            </div>
                            <div className="rehab-tab-arv-input-extras">
                                {
                                    property.arvCompsSimilarProp !== undefined &&
                                    property.arvCompsSimilarProp !== null &&
                                    property.arvCompsSimilarProp.length > 0 ?
                                    <div className="button-row">
                                        <span 
                                            className="label-regular underline colour-link block-text subtext-margin-right"
                                            onClick={() => viewArvComps()}
                                        >
                                            Go to ARV comps
                                        </span>
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            viewBox="0 0 16 16" 
                                            fill="none"
                                        >
                                            <path 
                                                d="M8.71167 15.1511L15.1157 8.7471C15.5086 8.35412 15.5086 7.71697 15.1157 7.32399C14.7227 6.93101 14.0855 6.93101 13.6926 7.32399L9.00641 12.0101L9.00641 1.55902C9.00641 1.00327 8.55588 0.552734 8.00012 0.552734C7.44436 0.552734 6.99383 1.00327 6.99383 1.55902L6.99383 12.0101L2.30769 7.32399C1.91471 6.93101 1.27756 6.93101 0.884579 7.32399C0.491599 7.71697 0.491599 8.35412 0.884579 8.7471L7.28857 15.1511C7.68155 15.5441 8.31869 15.5441 8.71167 15.1511Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="rehab-tab-slider-container relative-container margin-x-large">
                            <MulticolourSlider
                                maxPercentage={maxPercentage}
                                changeSlider={onChangeARVSlider}
                                value={customArv}
                                reverse={true}
                            />
                            {
                                property.price !== defaultArv ?
                                <SliderText
                                    text="Estimated ARV"
                                    value={formatterLong.format(defaultArv).replace(".00", "")}
                                    position="slider-text-container-right"
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default RehabTab;