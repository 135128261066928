import React from 'react';
import '../styles/DripScoreBadge.css';
import { Bean, EmptyBean, HalfBean } from '../assets';
import { dripScoreText } from '../styles/GlobalStyles';
import { BadgeExplanation } from './';

function DripScoreBadge({ score }) {

	const round = (x) => {
        return Math.ceil(x / 10) * 10;
    };

	const scoreTimes100 = Math.round(score * 100);
	const roundedScore = round(scoreTimes100) / 20;

	return (
		<div className="drip-score-badge-outer-container">
			<BadgeExplanation
				title="Drip Score"
				description={dripScoreText}
			/>
			<span className="drip-score-badge-text">
				Drip Score
			</span>
			<div className="drip-score-badge-row">
				{
					roundedScore > 0.75 ?
					<img
						src={Bean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					roundedScore > 0.25 ?
					<img
						src={HalfBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					<img
						src={EmptyBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
				}
				{
					roundedScore > 1.75 ?
					<img
						src={Bean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					roundedScore > 1.25 ?
					<img
						src={HalfBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					<img
						src={EmptyBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
				}
				{
					roundedScore > 2.75 ?
					<img
						src={Bean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					roundedScore > 2.25 ?
					<img
						src={HalfBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					<img
						src={EmptyBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
				}
				{
					roundedScore > 3.75 ?
					<img
						src={Bean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					roundedScore > 3.25 ?
					<img
						src={HalfBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					<img
						src={EmptyBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
				}
				{
					roundedScore >= 4.75 ?
					<img
						src={Bean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					roundedScore >= 4.25 ?
					<img
						src={HalfBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
					:
					<img
						src={EmptyBean}
						className="drip-score-bean drip-score-bean-margin"
						alt="Bean"
					/>
				}
			</div>
			<span className="drip-score-badge-text">
				{roundedScore}
			</span>
		</div>
	)
}

export default DripScoreBadge;