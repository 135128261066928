import React, { useState, useEffect } from 'react';
import { ChevronRight } from '../assets';

function PropertySearchPagination(props) {
    const page = props.page;
    const searchHistory = props.searchHistory;
    const setPage = props.setPage;
    const numberOfPages = Math.ceil(searchHistory.length / 9);
    const [pages, setPages] = useState([]);
    const [pageRange, setPageRange] = useState([0, 9]);

    useEffect(() => {
		if ( pages.length === 0 ) {
			const emptyArray = [];
			for (let index = 0; index < numberOfPages; index++) {

				const lowerBound = index * 9
				const upperBound = lowerBound + 9;
				const pageProperties = searchHistory.slice(lowerBound, upperBound);

				const newObject = {
					properties: pageProperties
				}
				emptyArray.push(newObject);
				setPages(emptyArray);
			}

			if ( page > 7 ) {
				const minPage = page - 6;
				const maxPage = page + 1;
				setPageRange([minPage, maxPage]);
			}
			else {
				setPageRange([0, 8]);
			}
		}
	}, [numberOfPages, pages, page, searchHistory]);

    const prevPage = () => {
        changePage(page - 1);
    };

    const nextPage = () => {
        changePage(page + 1);
    };

    const changePage = (index) => {
        setPage(index);
        if ( index > 7 ) {
            const minPage = index - 6;
            const maxPage = index + 1;
            setPageRange([minPage, maxPage]);
        }
		else {
			setPageRange([0, 8]);
		}
    };

    return (
        <div className="pagination-outer-container">
			<div className="pagination-inner-container">
				{
					page !== 1 && pages.length !== 1 ?
					<img
						src={ChevronRight}
						className="pagination-chevron-left"
						alt="Previous Page"
						onClick={() => prevPage()}
					/>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div onClick={() => changePage(1)} className={"pagination-element " + (page === 1 ? "pagination-element-selected" : "" )}>
						<span>
							1
						</span>
					</div>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div className="pagination-element pagination-element-dots">
						<span>
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.map((item, index) =>
						index <= pageRange[1] && index >= pageRange[0] ? 
						<div onClick={() => changePage(index + 1)} className={"pagination-element " + (index + 1 === page ? "pagination-element-selected" : "" )} key={index}>
							<span>
								{index + 1}
							</span>
						</div>
						:
						null
					)
				}
				{
					pages.length > (pageRange[1] + 2) ?
					<div className="pagination-element pagination-element-dots">
						<span>
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.length > (pageRange[1] + 1) ?
						<div onClick={() => changePage(pages.length)} className={"pagination-element " + (pages.length === page ? "pagination-element-selected" : "" )}>
							<span>
								{pages.length}
							</span>
						</div>
					:
					null
				}
				{
					page !== pages.length && pages.length !== 1 ?
					<img
						src={ChevronRight}
						className="pagination-chevron-right"
						alt="Next Page"
						onClick={() => nextPage()}
					/>
					:
					null
				}
			</div>
		</div>
    )
}

export default PropertySearchPagination;