import React from 'react';
import '../styles/CityMobilePricingOverlay.css';
import { Cappuccino } from '../assets/images';
import { formatterLong, ActionButton } from '../styles/GlobalStyles';

function RecordsMobilePricingOverlay(props) {
    const toggleAccordion = props.toggleAccordion;
    const mobileAccordion = props.mobileAccordion;
    const complete = props.complete;
    const checkout = props.checkout;
    const total = props.total;
    const selectedPlan = props.selectedPlan;
    const cta = props.cta;
    const loading = props.loading;
    const payment = props.payment;
    const disabled = props.disabled;
    const userData = props.userData;
    const saveCreditCard = props.saveCreditCard;
    const plan = props.plan;

    return (
        <div className="city-mobile-pricing-overlay-container desktop-none bg-colour-white">
            <div className="city-mobile-pricing-overlay-inner-container">
                <div 
                    className="city-mobile-pricing-overlay-header-container"
                    onClick={() => toggleAccordion()}
                >
                    <img
                        src={Cappuccino}
                        className="city-selection-mobile-coffee"
                        alt="Cappuccino"
                    />
                    <div className="city-mobile-pricing-overlay-header-text-container">
                        <h3 className="label-semibold-caps colour-white block-text margin-x-x-x-small">
                            Upgrade Summary
                        </h3>
                        <span className="body-regular colour-secondary">
                            Coffee Clozers Premuim
                        </span>
                    </div>
                    <div className={"city-mobile-pricing-overlay-chevron-container cursor-pointer " + (mobileAccordion === false ? "city-selection-mobile-reverse" : "")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.6364 2.34983C23.1516 1.88316 22.3656 1.88316 21.8808 2.34983L12 11.8613L2.11917 2.34983C1.63438 1.88316 0.848381 1.88316 0.363592 2.34983C-0.121197 2.8165 -0.121197 3.57311 0.363592 4.03978L12 15.2412L23.6364 4.03978C24.1212 3.57312 24.1212 2.8165 23.6364 2.34983Z" fill="#2F4858"/>
                        </svg>
                    </div>
                </div>
                <div className={"city-selection-mobile-cities-container " + (mobileAccordion === false ? "city-selection-hide-mobile-accordion" : "city-selection-show-mobile-accordion")}>
                    <div className="pricing-card-bottom-inner-container margin-medium">
                        <div className="pricing-card-bottom-left-container">
                            <h3 className="body-semibold colour-primary margin-x-x-small">
                                {plan.title}
                            </h3>
                        </div>
                        <div className="pricing-card-bottom-right-container">
                            <div>
                                <span className="body-semibold colour-primary">
                                    {formatterLong.format(total).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    mobileAccordion === true ?
                    <div className="city-selection-divider-container margin-tops">
                    </div>
                    :
                    null
                }
                <div className={"city-selection-mobile-totals-container margin-medium " + (mobileAccordion === false ? "city-selection-mobile-totals-padding" : "")}>
                    <div className="pricing-card-total-label-container">
                        <span className="body-semibold colour-primary">
                            Total
                        </span>
                    </div>
                    <div className={"pricing-card-total-text-container " + (checkout === true ? "margin-x-small" : "")}>
                        <div className="pricing-card-total-inner-container">
                            <div className="button-row">
                                <span className="body-semibold colour-primary">
                                    {formatterLong.format(total).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="city-selection-mobile-continue-button-container ">
                    <ActionButton
                        disabled={(loading === true) || (checkout === true && selectedPlan === null) || (payment === true && disabled === true) ? true : false}
                        onClick={() => payment === true && userData !== null && userData.creditCard === undefined ? saveCreditCard() : complete()}
                    >
                        {cta}
                    </ActionButton>
                </div>
            </div>
        </div>
    )
};

export default RecordsMobilePricingOverlay;