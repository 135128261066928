import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { CoffeeIcon, LogoIconInverted } from '../assets';

function CompMap(props) {

    const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});
    const property = props.property;
    const comps = props.comps;
    const centre = ({ lat: property.latitude, lng: property.longitude });
	const [renderOverlay, setRenderOverlay] = useState(false);

    useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000);
		}
	}, [renderOverlay]);

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: true,
		streetViewControl: false
	}

    return (
        <div className="arv-tool-maps-container">
            {
                !isLoaded ? "Loading"
                :
                <GoogleMap
                    zoom={11}
                    center={centre}
                    mapContainerClassName="location-map-container"
                    options={options}
                >
                    {
                        renderOverlay === true ?
                            <OverlayViewF
                                position={{ lat: property.latitude, lng: property.longitude }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div 
                                    className="location-map-overlay"
                                >
                                    <span>
                                        <img
                                            src={CoffeeIcon}
                                            className="interactive-map-coffee-icon"
                                            alt="Coffee"
                                        /> 
                                    </span>
                                </div>
                            </OverlayViewF>	
                        :
                        null
                    }
                    {
                        renderOverlay === true ?
                            comps.map((item, index) =>
                                <OverlayViewF
                                    position={{ lat: item.latitude, lng: item.longitude }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    key={index}
                                >
                                    <div className="location-map-overlay-inverse">
                                        <span>
                                            <img
                                                src={LogoIconInverted}
                                                className="interactive-map-coffee-icon"
                                                alt="Coffee"
                                            /> 
                                        </span>
                                        <span className="label-regular colour-white">
                                            {item.fullAddress}
                                        </span>
                                    </div>
                                </OverlayViewF>	
                            )
                        :
                        null
                    }
                </GoogleMap>
            }
        </div>
    )
};

export default CompMap;