import React, { Fragment, useState } from 'react';
import '../styles/MonthlyCostsTab.css';
import { FinancialsInput } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';
import { DefaultTooltip } from '../components';
import { monthlyCostsTooltips } from '../functions';

function MonthlyCostsTab(props) {
    const blurred = props.blurred;
    const onChangeMonthlyCosts = props.onChangeMonthlyCosts;
    const onChangeMonthlyCostsPercentage = props.onChangeMonthlyCostsPercentage;
    const togglePercentage = props.togglePercentage;
    const strategyPanel = props.strategyPanel;
    const monthlyCosts = props.monthlyCosts;
    const resetMonthlyCost = props.resetMonthlyCost;
    const [tooltip, setTooltip] = useState(null);

    const toggleTooltip = (index, ii, val) => {
        if ( val === true ) {
            setTooltip([index, ii]);
        }
        else {
            setTooltip(null);
        }
    };

    return (
        <div className="monthly-costs-tab-outer-container">
            <div className="monthly-costs-tab-inner-container margin-medium">
                {
                    monthlyCosts.map((item, index) =>
                        <div 
                            className={"monthly-costs-tab-section-container " + (index !== 2 ? "margin-x-large" : "")}
                            key={index}
                        >
                            <h3 className="body-semibold colour-primary margin-medium">
                                {item.label}
                            </h3>
                            <div className="monthly-costs-grid-container">
                                {
                                    item.inputs.map((input, ii) =>
                                        <div 
                                            className="monthly-costs-grid-element"
                                            key={ii}
                                        >
                                            <div className={"monthly-costs-input-container relative-container " + (input.percentage === true ? "monthly-costs-input-container-right" : "")}>
                                                <FinancialsInput
                                                    value={
                                                            strategyPanel === 2 && index === 0 && ii === 3 && input.percentage === false ?
                                                            input.brrrrValue
                                                            :
                                                            strategyPanel === 2 && index === 0 && ii === 3 && input.percentage === true ?
                                                            input.brrrrPercentageVal
                                                            :
                                                            input.label === "Management fee" && strategyPanel === 1 ? 0 
                                                            : 
                                                            input.percentage === true ? input.percentageVal
                                                            :
                                                            input.value
                                                    }
                                                    type="number"
                                                    label={
                                                        input.label === "Management fee" && strategyPanel === 1 ?
                                                        input.label
                                                        :
                                                        index === 0 && ii === 0 ?
                                                        input.label
                                                        :
                                                        strategyPanel === 2 && index === 0 && ii === 3 && (Number(input.brrrrValue) !== Number(input.brrrrOriginal)) ?
                                                        <Fragment>
                                                            {input.label}
                                                            <img
                                                                src={ChangeDot}
                                                                className="financials-panel-change-dot"
                                                                alt="Change"
                                                            />
                                                        </Fragment>
                                                        :
                                                        strategyPanel === 2 && index === 0 && ii === 3 && (Number(input.brrrrValue) === Number(input.brrrrOriginal)) ?
                                                        input.label
                                                        :
                                                        Number(input.value) !== Number(input.original) ?
                                                        <Fragment>
                                                            {input.label}
                                                            <img
                                                                src={ChangeDot}
                                                                className="financials-panel-change-dot"
                                                                alt="Change"
                                                            />
                                                        </Fragment>
                                                        :
                                                        input.label
                                                    }
                                                    id={`${input.label}-input-${index}`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment className={input.percentage === true ? "financials-panel-dormant-option" : "financials-panel-active-option"} onClick={() => togglePercentage(false, index, ii)} position="start">$</InputAdornment>,
                                                        endAdornment: input.label === "Mortgage payment" || input.label === "HOA fees" ? null : <InputAdornment className={input.percentage === true ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => togglePercentage(true, index, ii)} position="end">%</InputAdornment>,
                                                    }}
                                                    onChange={(text) => input.percentage === true ? onChangeMonthlyCostsPercentage(text.target.value, index, ii) : onChangeMonthlyCosts(text.target.value, index, ii)}
                                                    disabled={(input.label === "Mortgage payment") || (input.label === "Management fee" && strategyPanel === 1) || (blurred === true) ? true : false}
                                                />
                                                <div 
                                                    className="monthly-costs-tooltip-container"
                                                    onMouseEnter={() => toggleTooltip(index, ii, true)}
                                                    onMouseLeave={() => toggleTooltip(index, ii, false)}
                                                >
                                                    {
                                                        tooltip === null || (tooltip !== null && index === tooltip[0] && ii === tooltip[1]) ?
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                                fill="#716E69"
                                                            />
                                                        </svg>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        tooltip !== null && index === tooltip[0] && ii === tooltip[1] ?
                                                        <DefaultTooltip
                                                            title={monthlyCostsTooltips[index].tooltips[ii].title}
                                                            description={monthlyCostsTooltips[index].tooltips[ii].description}
                                                        />
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            {
                                                index === 0 && ii === 0 ?
                                                null
                                                :
                                                <div 
                                                    className={"new-financials-costs-recurring-costs-reset-container " + ((strategyPanel === 2 && index === 0 && ii === 3 && (Number(input.brrrrValue) === Number(input.brrrrOriginal))) ? "view-none" : (strategyPanel === 2 && index === 0 && ii === 3 && (Number(input.brrrrValue) !== Number(input.brrrrOriginal))) || (Number(input.original) !== Number(input.value)) ? "" : "view-none")}
                                                    onClick={() => resetMonthlyCost(index, ii)}
                                                >
                                                    <span className="label-semibold colour-link">
                                                        Reset
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default MonthlyCostsTab;