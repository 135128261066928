import React from 'react';
import '../styles/FavouritesGrid.css';
import { NewPropertyCard, OffMarketCard } from '../components';

function FavouritesGrid(props) {
    const properties = props.properties;
    const setProperties = props.setProperties;
    const userDetails = props.userDetails;
    const toggleCheckbox = props.toggleCheckbox;
    const propertySelected = props.propertySelected;
    const innerWidth = props.innerWidth;

    const formatterLong = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		notation: 'standard'
	});

    return (
        <div className="favourites-grid-outer-container">
            {
                properties.map((item, index) =>
                    <div 
                        className={"favourites-grid-element " + (propertySelected(item.zpid) === true ? "property-grid-element-selected" : "")}
                        key={index}
                    >
                        <div 
                            className="favourites-grid-element-compare-container"
                            onClick={() => toggleCheckbox(item.zpid)}
                        >
                            <div className="favourites-table-checkbox-container">
                                {
                                    propertySelected(item.zpid) === true ?
                                    <div className="favourites-table-checkbox">
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <span className="body-regular colour-secondary">
                                Compare
                            </span>
                        </div>
                        {
                            item.offMarket === false ?
                            <NewPropertyCard
                                item={item}
                                index={index}
                                key={index}
                                userDetails={userDetails}
                                formatter={formatterLong}
                                abstract={true}
                                viewedProperties={null}
                                setViewedProperties={null}
                                cityId={item.cityId}
                                setHoverCard={null}
                                propertyRefs={[]}
                                onboarding={true}
                                onboardingStep={0}
                                setOnboardingStep={false}
                                setCardClick={null}
                                temporaryId={false}
                                overlay={true}
                                setOverlay={null}
                                propertySearch={false}
                                partnerView={false}
                                partnerEditProperty={null}
                                partnerSoldProperty={null}
                                partnerData={null}
                                changeStatus={null}
                                setShowExitScreen={null}
                                selectOption={null}
                                openStatus={null}
                                chooseStatus={null}
                                state={null}
                                favourites={properties}
                                setFavourites={setProperties}
                                arrowClicked={null}
                                imageLimit={10}
                            />
                            :
                            <OffMarketCard
                                item={item}
                                index={index}
                                key={index}
                                userDetails={userDetails}
                                formatter={formatterLong}
                                abstract={true}
                                viewedProperties={null}
                                setViewedProperties={null}
                                cityId={item.cityId}
                                setHoverCard={null}
                                propertyRefs={[]}
                                onboarding={true}
                                onboardingStep={0}
                                setOnboardingStep={false}
                                setCardClick={null}
                                temporaryId={false}
                                overlay={true}
                                setOverlay={null}
                                innerWidth={innerWidth}
                                favourites={properties}
                                setFavourites={setProperties}
                            />
                        }
                    </div>
                )
            }
        </div>
    )
}

export default FavouritesGrid;