import React, { Fragment } from 'react';
import { FinancialsInput } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { Info, Beak, ChangeDot } from '../assets';
import { costsTooltipIndexes } from '../functions';

function RecurringCosts(props) {
    const recurringCosts = props.recurringCosts;
    const onChangeRecurringCosts = props.onChangeRecurringCosts;
    const resetRecurringCosts = props.resetRecurringCosts;
    const toggleTooltip = props.toggleTooltip;
    const costsTooltipShow = props.costsTooltipShow;
    const costsTooltipIndex = props.costsTooltipIndex;
    const blurred = props.blurred;
    const togglePercentage = props.togglePercentage;
    const recurringProfits = props.recurringProfits;
    const property = props.property;

    const renderPercentage = (val, item) => {
        if ( val === "" ) {
            return val;
        }
        else if ( item.label === "Property taxes" || item.label === "Insurance" ) {
            const priceVal = property.price;
            const percentageVal = (Number(val) / priceVal) * 1200;
            // console.log("priceVal = ", priceVal);
            // console.log("percentageVal = ", percentageVal);
            let returnedValue = 0;

            if ( item.value === item.original ) {
                returnedValue = Math.round(percentageVal);
            }
            else if ( Number.isInteger(percentageVal) === true ) {
                returnedValue = percentageVal;
            }
            else {
                returnedValue = percentageVal.toFixed(1);
            }

            return returnedValue;
        }
        else {
            const rentVal = Number(recurringProfits[0].value);
            const percentageVal = (Number(val) / rentVal) * 100;

            return item.value === item.original ? Math.round(percentageVal) : Number.isInteger(percentageVal) === true ? percentageVal : percentageVal.toFixed(1);
        }
    };

    return (
        <div className="recurring-costs-outer-container">
            <h3 className="label-regular colour-secondary margin-x-small">
                Monthly costs
            </h3>
            <div className="recurring-costs-grid-container">
                {
                    recurringCosts.map((item, index) =>
                        <div 
                            className={"new-income-input-container margin-medium relative-container " + (item.percentage === true ? "financials-panel-percentage-container" : "")}
                            key={index}
                        >
                            <div className="relative-container">
                                <div className="financials-input-long-width">
                                    <FinancialsInput
                                        value={blurred === true ? "999" : item.percentage === true ? renderPercentage(item.value, item) : item.value}
                                        type="number"
                                        label={
                                            Number(item.value) !== Number(item.original) && index !== 0 ?
                                            <Fragment>
                                                {item.label}
                                                <img
                                                    src={ChangeDot}
                                                    className="financials-panel-change-dot"
                                                    alt="Change"
                                                />
                                            </Fragment>
                                            :
                                            item.label
                                        }
                                        id={`${item.label}-input-${index}`}
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            startAdornment: <InputAdornment className={item.percentage === true ? "financials-panel-dormant-option" : "financials-panel-active-option"} onClick={() => togglePercentage(index, false)} position="start">$</InputAdornment>,
                                            endAdornment: item.label === "Mortgage payment" ? null : <InputAdornment className={item.percentage === true ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => togglePercentage(index, true)} position="end">%</InputAdornment>,
                                        }}
                                        onChange={(text) => onChangeRecurringCosts(text.target.value, index, false)}
                                        disabled={item.label === "Mortgage payment" || blurred === true ? true : false}
                                    />
                                </div>
                                <img
                                    src={Info}
                                    className="financials-panel-info-tooltip"
                                    alt="Info"
                                    onMouseEnter={() => toggleTooltip(true, index, "costs")}
                                    onMouseLeave={() => toggleTooltip(false, index, "costs")}
                                />
                                {
                                    costsTooltipShow === true && costsTooltipIndex === index ?
                                    <div className="financials-panel-tooltip-container">
                                        <img
                                            src={Beak}
                                            className="financials-panel-beak"
                                            alt="Beak"
                                        />
                                        <h5 className="property-details-tooltip-title">
                                            {costsTooltipIndexes[index].title}
                                        </h5>
                                        <span className="property-details-tooltip-text">
                                            {costsTooltipIndexes[index].description}
                                        </span>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {
                                blurred === false ?
                                <div 
                                    className={"new-financials-costs-recurring-costs-reset-container " + (index === 0 ? "view-none" : Number(item.value) !== Number(item.original) ? "" : "view-none")}
                                    onClick={() => resetRecurringCosts(index, item.original, item.percentage === true ? true : false)}
                                >
                                    <span className="label-semibold colour-link">
                                        Reset
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default RecurringCosts;