import React, { useEffect, useState } from 'react';
import '../styles/EditListing.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, Footer, NonDisclosureState, Loading, DeleteModal, UploadMultipleImages, UploadedImage, PropertyStatus, ExitScreen, RichTextEditor, LoadingStatic } from '../components';
import { Bathrooms, Bedrooms, SQFootage, SingleFamily } from '../assets';
import { formatterLong, numberFormatter, FinancialsInput, IOSSwitch, WhiteActionButton, ActionButton, MultilineInput, nonDisclosureStates, propertyStatuses, CustomSelect, colour } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { checkOnAuthStateChanged, checkUserId, deleteImage, getDocument, makeId, recordEvent, setData } from '../functions';
import { ReactSortable } from 'react-sortablejs';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function EditListing() {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;
    const [property, setProperty] = useState(state === null ? null : state.property);
    const pastAmendments = state === null ? null : state.amendments;
    const [firstRender, setFirstRender] = useState(false);
    const [address, setAddress] = useState(pastAmendments !== null && pastAmendments.address !== null ? pastAmendments.address : `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`);
    const [propertyType, setPropertyType] = useState(pastAmendments !== null && pastAmendments.propertyTypeDimension !== null ? pastAmendments.propertyTypeDimension : property.propertyTypeDimension === null || property.propertyTypeDimension === undefined ? "" : property.propertyTypeDimension);
    const [beds, setBeds] = useState(pastAmendments !== null && pastAmendments.bedrooms !== null ? pastAmendments.bedrooms : property.bedrooms === null || property.bedrooms === undefined ? "0" : property.bedrooms);
    const [baths, setBaths] = useState(pastAmendments !== null && pastAmendments.bathrooms !== null ? pastAmendments.bathrooms : property.bathrooms === null || property.bathrooms === undefined ? "0" : property.bathrooms);
    const [sqFootage, setSqFootage] = useState(pastAmendments !== null && pastAmendments.livingArea !== null ? pastAmendments.livingArea : property.livingArea === null || property.livingArea === undefined ? "0" : property.livingArea);
    const [description, setDescription] = useState(pastAmendments !== null && pastAmendments.description !== null ? pastAmendments.description : property.description === null || property.description === undefined ? "" : property.description);
    const [price, setPrice] = useState(pastAmendments !== null && pastAmendments.price !== null ? pastAmendments.price : "");
    const [rent, setRent] = useState(pastAmendments !== null && pastAmendments.rent !== null ? pastAmendments.rent : property !== null && property.financials !== undefined && property.financials.rent !== undefined && property.financials.rent !== null ? property.financials.rent : "");
    const [arv, setArv] = useState(pastAmendments !== null && pastAmendments.arv !== null ? pastAmendments.arv : "");
    const [priceDefault, setPriceDefault] = useState(pastAmendments !== null && pastAmendments.price !== null ? false : true);
    const [rentDefault, setRentDefault] = useState(pastAmendments !== null && pastAmendments.rent !== null ? false : true);
    const [arvDefault, setArvDefault] = useState(pastAmendments !== null && pastAmendments.arv !== null ? false : property.arvComps === true ? true : false);
    const [customImages, setCustomImages] = useState(pastAmendments !== null && pastAmendments.customImages !== null && pastAmendments.customImages !== undefined ? pastAmendments.customImages : []);
    // const [title, setTitle] = useState(pastAmendments !== null && pastAmendments.title !== null && pastAmendments.title !== undefined ? pastAmendments.title : "");
    const [message, setMessage] = useState(pastAmendments !== null && pastAmendments.message !== null && pastAmendments.message !== undefined ? pastAmendments.message : "");
    const multipleUniqueUnits = property === null ? false : property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const [salesComps] = useState(pastAmendments !== null && pastAmendments.arvCompsSimilarProp !== null && pastAmendments.arvCompsSimilarProp !== undefined ? pastAmendments.arvCompsSimilarProp : pastAmendments === null && property.arvCompsSimilarProp !== undefined ? property.arvCompsSimilarProp : []);
    const [rentalComps] = useState(pastAmendments !== null && pastAmendments.rentalComps !== undefined && pastAmendments.rentalComps !== null ? pastAmendments.rentalComps : multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
        property.unitPropertyComps 
        :
        property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
        property.unitPropertyComps
        :
        property.rentComps !== undefined && property.rentComps !== null ?
        property.rentComps
        :
        []
    );
    const [downPayment, setDownPayment] = useState(pastAmendments !== null && pastAmendments.downPayment !== undefined && pastAmendments.downPayment !== null ? pastAmendments.downPayment : "");
    const [interestRate, setInterestRate] = useState(pastAmendments !== null && pastAmendments.interestRate !== undefined && pastAmendments.interestRate !== null ? pastAmendments.interestRate : "");
    const [closingCosts, setClosingCosts] = useState(pastAmendments !== null && pastAmendments.closingCosts !== undefined && pastAmendments.closingCosts !== null ? pastAmendments.closingCosts : "");
    const [rehabCost, setRehabCost] = useState(pastAmendments !== null && pastAmendments.totalRehabCost !== undefined && pastAmendments.totalRehabCost !== null && property.livingArea !== null && property.livingArea !== 0 ? pastAmendments.totalRehabCost / property.livingArea : "");
    const [strategy, setStrategy] = useState(pastAmendments !== null && pastAmendments.strategy !== undefined && pastAmendments.strategy !== null ? pastAmendments.strategy : "traditional");
    const [showSeller, setShowSeller] = useState(pastAmendments !== null && pastAmendments.sellerInfo !== undefined && pastAmendments.sellerInfo !== null ? pastAmendments.sellerInfo : true);
    const [showNeighbourhood, setShowNeighbourhood] = useState(pastAmendments !== null && pastAmendments.showNeighbourhood !== undefined && pastAmendments.showNeighbourhood !== null ? pastAmendments.showNeighbourhood : true);
    // const [notes, setNotes] = useState("");
    const [loading, setLoading] = useState(false);
    const [editArray, setEditArray] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [chooseStatus, setChooseStatus] = useState(false);
    const [partnerData, setPartnerData] = useState(null);
    const [richTextLoading, setRichTextLoading] = useState(true);
    // const [compsAccordion, setCompsAccordion] = useState(false);
    const [imageLoading, setImageLoading] = useState("");
    const statusIndex = propertyStatuses.findIndex(e => e.value === property.status);
    const statusElement = statusIndex !== -1 ? propertyStatuses[statusIndex] : null;

    useEffect(() => {
        document.title = "Edit Partner Listing";

        const saveFirstCustomImage = async(propertyImages) => {
            const newPropertyImages = [];
            for (let index = 0; index < propertyImages.length; index++) {
                const element = propertyImages[index];
                if ( element.includes("maps.googleapis.com") === true ) {

                }
                else {
                    const id = await makeId(10);
                    const newObject = {
                        title: "Featured Image",
                        url: element,
                        id: id
                    };
                    newPropertyImages.push(newObject);
                }
            };
            setCustomImages(newPropertyImages);
            
            const colRef = "Partners";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const partner = queryData.data.data;
                    setPartnerData(partner);
                    const listings = partner.listings;
                    const listingIndex = listings.findIndex(e => e.zpid === property.zpid);
                    if ( listingIndex !== -1 ) {
                        partner.listings[listingIndex].amendments.customImages = newPropertyImages;
                    }
                    await setData(colRef, docRef, partner);
                }
            }
        };

        const loadPartnerData = async() => {
            const colRef = "Partners";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const partner = queryData.data.data;
                    setPartnerData(partner);
                }
            }
        };

        if ( state === null ) {
            navigate("/partner/dashboard");
        }
        else if ( firstRender === false ) {
            setFirstRender(true);
            const propertyImages = property.images;
            if ( propertyImages !== undefined && propertyImages !== null && customImages.length === 0 ) {
                saveFirstCustomImage(propertyImages);
            }
            else {
                loadPartnerData();
            }

            const propertyClone = {...property, rentComps: rentalComps};
            setProperty(propertyClone);
        };
    }, [navigate, state, firstRender, property.images, property.zpid, customImages.length, property, rentalComps]);

    const strategies = [
        {
            label: "Turnkey (Buy-and-hold)",
            value: "traditional"
        },
        {
            label: "BRRRR",
            value: "brrrr"
        },
        {
            label: "Househacking",
            value: "houseHacking"
        },
        {
            label: "Fix & Flip",
            value: "fixAndFlip",
        }
    ];

    const onChangePrice = (text) => {
        setPrice(text);
        
        if ( text === "" ) {
            setPriceDefault(true);
        }
        else {
            setPriceDefault(false);
        }
    };

    const onChangeRent = (text) => {
        setRent(text);

        if ( text === "" ) {
            setRentDefault(true);
        }
        else {
            setRentDefault(false);
        }
    };

    const onChangeARV = (text) => {
        setArv(text);

        if ( text === "" ) {
            setArvDefault(true);
        }
        else {
            setArvDefault(false);
        }
    };

    const togglePriceDefault = (val) => {
        setPriceDefault(val);
    };

    const toggleRentDefault = (val) => {
        setRentDefault(val);
    };

    const toggleARVDefault = (val) => {
        setArvDefault(val);
    };

    const checkNonDisclosureState = () => {
        const address = property.address;
		const state = address.state;

		if ( nonDisclosureStates.indexOf(state) !== -1 ) {
			return true;
		}
		else {
			return false;
		}
    };

    const toggleEdit = (index) => {
        const tempArray = editArray.slice(0);
        const arrayIndex = tempArray.indexOf(index);
        if ( arrayIndex === -1 ) {
            tempArray.push(index);
        }
        else {
            tempArray.splice(arrayIndex, 1);
        }
        setEditArray(tempArray);
    };

    const toggleNeighbourhoodInfo = () => {
        setShowNeighbourhood(!showNeighbourhood);
    };

    const toggleSellerInfo = () => {
        setShowSeller(!showSeller);
    };

    const cancel = () => {
        navigate("/partner/dashboard");
    };

    const activeDeleteListing = () => {
        setShowDelete(true);
        recordEvent("Open Delete Listing Modal", {});
    };

    const deleteListing = async() => {
        setLoading(true);
        setShowDelete(false);
        const colRef = "Partners";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const partner = queryData.data.data;
                const listings = partner.listings;
                const listingIndex = listings.findIndex(e => Number(e.zpid) === Number(property.zpid));
                if ( listingIndex !== -1 ) {
                    partner.listings.splice(listingIndex, 1);
                }
                await setData(colRef, docRef, partner);
                navigate("/partner/dashboard");
            }
            else {
                navigate("/partner/setup");
            }
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
    };

    const hasNestedArray = (arr) => {
        return arr.some(Array.isArray);
    };

    const complete = async() => {
        setLoading(true);
        const checkedBeds = Number(beds);
        const checkedBaths = Number(baths);
        const checkedSqFootage = Number(sqFootage);
        const checkedARV = Number(arv);
        const checkedDownPayment = Number(downPayment);
        const checkedInterestRate = Number(interestRate);
        const checkedClosingCosts = Number(closingCosts);
        const checkedRehabCost = checkedSqFootage === 0 || isNaN(checkedSqFootage) === true ? 0 : Number(rehabCost) * checkedSqFootage;

        const colRef = "Partners";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const partner = queryData.data.data;
                const listings = partner.listings;
                const listingIndex = listings.findIndex(e => Number(e.zpid) === Number(property.zpid));
                const nestedRentalCompsArray = rentalComps === undefined || rentalComps === null ? false : await hasNestedArray(rentalComps);
                const checkedRentalComps = rentalComps === undefined || rentalComps === null ? null : nestedRentalCompsArray === false ? rentalComps : null;
                if ( listingIndex === -1 ) {
                    const amendments = {
                        address: address === `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}` ? null : address,
                        propertyTypeDimension: propertyType === property.propertyTypeDimension ? null : propertyType,
                        bedrooms: checkedBeds === property.bedrooms ? null : isNaN(checkedBeds) ? null : checkedBeds,
                        bathrooms: checkedBaths === property.bathrooms ? null : isNaN(checkedBaths) ? null : checkedBaths,
                        livingArea: checkedSqFootage === property.livingArea ? null : isNaN(checkedSqFootage) ? null : checkedSqFootage,
                        description: description,
                        price: priceDefault === true ? null : Number(price),
                        rent: rentDefault === true ? null : Number(rent),
                        arv: (property.arvComps === true && arv === property.arvCompsStats.arvPrice === true) || arvDefault === true ? null : checkedARV === 0 ? null : isNaN(checkedARV) ? null : checkedARV,
                        arvCompsStats: property.arvComps === true ? property.arvCompsStats : null,
                        arvCompsSimilarProp: property.arvComps === true && property.arvCompsSimilarProp !== undefined ? property.arvCompsSimilarProp : null,
                        rentalComps: rentalComps === undefined ? null : checkedRentalComps,
                        customImages: customImages.length === 0 ? null : customImages,
                        // title: title === "" ? null : title,
                        message: message === "" ? null : message,
                        downPayment: downPayment === "" ? null : checkedDownPayment,
                        interestRate: interestRate === "" ? null : checkedInterestRate,
                        closingCosts: closingCosts === "" ? null : checkedClosingCosts,
                        totalRehabCost: rehabCost === "" ? null : checkedRehabCost,
                        sellerInfo: showSeller,
                        showNeighbourhood: showNeighbourhood,
                        strategy: strategy,
                        notes: null//notes === "" ? null : notes
                    };
            
                    const propertyObject = {
                        zpid: property.zpid !== undefined && property.zpid !== null ? property.zpid : null,
                        address: `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`,
                        amendments: amendments,
                        status: property.status === undefined ? "active" : property.status
                    };

                    partner.listings.push(propertyObject);
                }
                else {
                    const currentListingAmendments = listings[listingIndex].amendments;
                    const amendments = {
                        address: address === `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}` ? null : address,
                        propertyTypeDimension: propertyType === property.propertyTypeDimension ? null : propertyType,
                        bedrooms: checkedBeds === property.bedrooms ? null : isNaN(checkedBeds) ? null : checkedBeds,
                        bathrooms: checkedBaths === property.bathrooms ? null : isNaN(checkedBaths) ? null : checkedBaths,
                        livingArea: checkedSqFootage === property.livingArea ? null : isNaN(checkedSqFootage) ? null : checkedSqFootage,
                        description: description,
                        price: priceDefault === true ? null : Number(price),
                        rent: rentDefault === true ? null : Number(rent),
                        arv: (property.arvComps === true && arv === property.arvCompsStats.arvPrice === true) || arvDefault === true ? null : checkedARV === 0 ? null : isNaN(checkedARV) ? null : checkedARV,
                        arvCompsStats: property.arvComps === true ? property.arvCompsStats : null,
                        arvCompsSimilarProp: property.arvComps === true && currentListingAmendments.arvCompsSimilarProp !== undefined ? salesComps : null,
                        customImages: customImages.length === 0 ? null : customImages,
                        rentalComps: rentalComps === undefined ? null : checkedRentalComps,
                        // title: title === "" ? null : title,
                        message: message === "" ? null : message,
                        downPayment: downPayment === "" ? null : checkedDownPayment,
                        interestRate: interestRate === "" ? null : checkedInterestRate,
                        closingCosts: closingCosts === "" ? null : checkedClosingCosts,
                        totalRehabCost: rehabCost === "" ? null : checkedRehabCost,
                        sellerInfo: showSeller,
                        showNeighbourhood: showNeighbourhood,
                        strategy: strategy,
                        notes: null//notes === "" ? null : notes
                    };
            
                    const propertyObject = {
                        zpid: property.zpid !== undefined && property.zpid !== null ? property.zpid : null,
                        address: `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`,
                        amendments: amendments,
                        status: property.status === undefined ? "active" : property.status
                    };

                    partner.listings[listingIndex] = propertyObject;
                }

                await setData(colRef, docRef, partner);
                navigate("/partner/dashboard");
            }
            else {
                navigate("/partner/setup");
            }
        }
        else {
            navigate("/sign-up");
        }

        setLoading(false);
    };
    
    const savePropertyImages = async(uploadedFiles) => {
        setDisabled(true);
        const imageClone = customImages.slice(0);
        const newArray = imageClone.concat(uploadedFiles);
        setCustomImages(newArray);
        deployNewCustomImages(newArray);
    };

    const deployNewCustomImages = async(newArray) => {
        const colRef = "Partners";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const partner = queryData.data.data;
                const listings = partner.listings;
                const listingIndex = listings.findIndex(e => e.zpid === property.zpid);
                if ( listingIndex !== -1 ) {
                    partner.listings[listingIndex].amendments.customImages = newArray;
                }
                await setData(colRef, docRef, partner);
            }
        }
        setDisabled(false);
    };

    const deleteImageFunc = async(index) => {
        if ( disabled === true ) {
            return;
        }
        else {
            setDisabled(true);
            const imageCode = customImages[index].id;
            const imageClone = customImages.slice(0);
            imageClone.splice(index, 1);
            setCustomImages(imageClone);
            deployNewCustomImages(imageClone);
            const user = await checkUserId();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const deleteRoute = `Users/${userId}/partner_listings/${property.zpid}/${imageCode}`;
                await deleteImage(deleteRoute);
            }
            setDisabled(false);
        }
    };

    const changeOrder = (vals) => {
        setCustomImages(vals);
    };

    const openStatus = () => {
        setChooseStatus(!chooseStatus);
    };

    const selectOption = (index, property) => {
        const propertyClone = structuredClone(property);
        propertyClone.status = propertyStatuses[index].value;
        setProperty(propertyClone);
        if ( propertyClone.status === "sold" ) {
            const listingIndex = partnerData.listings.findIndex(e => e.zpid === propertyClone.zpid);
            if ( listingIndex !== -1 ) {
                navigate("/partner/sold", {
                    state: {
                        property: propertyClone,
                        partnerData: partnerData,
                        amendments: partnerData.listings.length === 0 ? null : partnerData.listings[listingIndex].amendments
                    }
                });
            }
        }
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

    return (
        <div className="edit-listing-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                <div className="edit-listing-inner-container margin-x-x-large">
                    {
                        chooseStatus === true ?
                        <ExitScreen
                            clickScreen={() => setChooseStatus(false)}
                        />
                        :
                        null
                    }
                    <div className="edit-listings-body-container margin-x-large bg-colour-white">
                        {
                            loading === true ?
                            <div className="edit-listing-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        {
                            showDelete === true ?
                            <DeleteModal
                                deleteFunction={deleteListing}
                                setDeleteModal={setShowDelete}
                                description="Are you sure you want to delete this listing?"
                                deleteParam={null}
                            />
                            :
                            null
                        }
                        <div className="edit-listings-inner-body-container">
                            <div className="edit-listings-title-container edit-listings-section-border">
                                <h1 className="heading-large-semibold colour-primary margin-medium">
                                    {state.edit === false ? "Verify" : "Edit"} listing
                                </h1>
                                <h2 className="body-regular colour-secondary margin-x-large">
                                    Add or edit listing information.
                                </h2>
                            </div>
                            <div className="edit-listings-width-container edit-listings-status-container edit-listings-section-border">
                                <div className="edit-listings-row margin-medium">
                                    <h3 className="body-semibold colour-primary subtext-margin-right-x-x-x-large">
                                        Listing status
                                    </h3>
                                    <PropertyStatus
                                        text={statusElement !== null ? statusElement.label : ""}
                                        bgColour={statusElement !== null ? statusElement.bgColour : ""}
                                        openStatus={openStatus}
                                        chooseStatus={chooseStatus}
                                        selectOption={selectOption}
                                        property={property}
                                    />
                                </div>
                                {/* <div className="edit-listings-custom-title-container margin-x-large">
                                    <h4 className="body-semibold colour-primary margin-x-small">
                                        Title
                                    </h4>
                                    <TextInput
                                        value={title}
                                        type="text"
                                        id={`custom-title-input`}
                                        inputProps={{
                                        }}
                                        placeholder="Miami Fix and Flip Opportunity!!"
                                        onChange={(text) => setTitle(text.target.value)} 
                                    />
                                </div> */}
                                <div className="edit-listings-custom-title-container">
                                    <h4 className="body-semibold colour-primary margin-x-small">
                                        Message <span className="body-regular colour-secondary">(Optional)</span>
                                    </h4>
                                    <span className="body-regular colour-secondary margin-x-small block-text">
                                        Write a separate message from the property description.
                                    </span>
                                    {
                                        richTextLoading === true ?
                                        <LoadingStatic />
                                        :
                                        null
                                    }
                                    <RichTextEditor
                                        message={message}
                                        setMessage={setMessage}
                                        setLoading={setRichTextLoading}
                                    />
                                    {/* <MultilineInput
                                        value={message}
                                        type="text"
                                        id={`message-input`}
                                        placeholder="Great investment opportunity in Miami! The owner of this property is looking to sell quick. Call ASAP for more details."
                                        inputProps={{
                                            autoCapitalize: 'none'
                                        }}
                                        rows="4"
                                        onChange={(text) => setMessage(text.target.value)}
                                        multiline
                                    /> */}
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-property-container edit-listings-section-border">
                                <h3 className="heading-small-semibold text-link">
                                    Property
                                </h3>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Address
                                        </h4>
                                        {
                                            editArray.indexOf(0) !== -1 ?
                                            <FinancialsInput
                                                value={address}
                                                type="text"
                                                id={`address-input`}
                                                inputProps={{
                                                    // maxLength: 4
                                                }}
                                                onChange={(text) => setAddress(text.target.value)}
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {address}
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(0)}
                                        >
                                            {
                                                editArray.indexOf(0) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <div className="edit-listings-body-subtitle-row">
                                            <img
                                                src={SingleFamily}
                                                className="edit-listings-icon"
                                                alt="Property Type"
                                            />
                                            <h4 className="body-semibold colour-primary margin-x-small">
                                                Property type
                                            </h4>
                                        </div>
                                        {
                                            editArray.indexOf(1) !== -1 ?
                                            <FinancialsInput
                                                value={propertyType}
                                                type="text"
                                                id={`property-type-input`}
                                                inputProps={{
                                                    // maxLength: 4
                                                }}
                                                onChange={(text) => setPropertyType(text.target.value)}
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {propertyType}
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(1)}
                                        >
                                            {
                                                editArray.indexOf(1) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row edit-listings-body-short-input">
                                    <div className="edit-listings-body-left-container">
                                        <div className="edit-listings-body-subtitle-row">
                                            <img
                                                src={Bedrooms}
                                                className="edit-listings-icon"
                                                alt="Property Type"
                                            />
                                            <h4 className="body-semibold colour-primary margin-x-small">
                                                Beds
                                            </h4>
                                        </div>
                                        {
                                            editArray.indexOf(2) !== -1 ?
                                            <FinancialsInput
                                                value={beds}
                                                type="text"
                                                id={`bedrooms-input`}
                                                inputProps={{
                                                    maxLength: 2
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">bed</InputAdornment>,
                                                }}
                                                onChange={(text) => setBeds(text.target.value)}
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {beds} bed
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(2)}
                                        >
                                            {
                                                editArray.indexOf(2) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row edit-listings-body-short-input">
                                    <div className="edit-listings-body-left-container">
                                        <div className="edit-listings-body-subtitle-row">
                                            <img
                                                src={Bathrooms}
                                                className="edit-listings-icon"
                                                alt="Property Type"
                                            />
                                            <h4 className="body-semibold colour-primary margin-x-small">
                                                Baths
                                            </h4>
                                        </div>
                                        {
                                            editArray.indexOf(3) !== -1 ?
                                            <FinancialsInput
                                                value={baths}
                                                type="text"
                                                id={`bathrooms-input`}
                                                inputProps={{
                                                    maxLength: 2
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">bath</InputAdornment>,
                                                }}
                                                onChange={(text) => setBaths(text.target.value)}
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {baths} bath
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(3)}
                                        >
                                            {
                                                editArray.indexOf(3) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row edit-listings-body-short-input">
                                    <div className="edit-listings-body-left-container">
                                        <div className="edit-listings-body-subtitle-row">
                                            <img
                                                src={SQFootage}
                                                className="edit-listings-icon"
                                                alt="Property Type"
                                            />
                                            <h4 className="body-semibold colour-primary margin-x-small">
                                                Square footage
                                            </h4>
                                        </div>
                                        {
                                            editArray.indexOf(4) !== -1 ?
                                            <FinancialsInput
                                                value={sqFootage}
                                                type="text"
                                                id={`sq-footage-input`}
                                                inputProps={{
                                                    maxLength: 5
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">sqft</InputAdornment>,
                                                }}
                                                onChange={(text) => setSqFootage(text.target.value)}
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {numberFormatter.format(sqFootage)} sqft
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(4)}
                                        >
                                            {
                                                editArray.indexOf(4) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Description
                                        </h4>
                                        {
                                            editArray.indexOf(5) !== -1 ?
                                            <MultilineInput
                                                value={description}
                                                type="text"
                                                id={`description-input`}
                                                placeholder="Insert description here"
                                                inputProps={{
                                                    autoCapitalize: 'none'
                                                }}
                                                rows="8"
                                                onChange={(text) => setDescription(text.target.value)}
                                                multiline
                                            />
                                            :
                                            <span className="body-regular colour-primary">
                                                {description}
                                            </span>
                                        }
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => toggleEdit(5)}
                                        >
                                            {
                                                editArray.indexOf(5) !== -1 ?
                                                "Done"
                                                :
                                                "Edit"
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-section-border edit-listings-marketing-container">
                                <h3 className="heading-small-semibold text-link margin-x-large">
                                    Marketing
                                </h3>
                                <div className="edit-listings-property-images-container">
                                    <h4 className="body-semibold colour-primary margin-medium">
                                        Property images
                                    </h4>
                                    <div className="margin-medium">
                                        <UploadMultipleImages
                                            saveFile={savePropertyImages}
                                            route={`partner_listings/${property.zpid}`}
                                            subText="Select photos"
                                            setImageLoading={setImageLoading}
                                        />
                                        {
                                            imageLoading !== "" ?
                                            <span className="body-regular colour-primary block-text margin-top-small">
                                                {imageLoading}
                                            </span>
                                            :
                                            null
                                        }
                                    </div>
                                    <ReactSortable 
                                        list={customImages} 
                                        setList={changeOrder}
                                        className="edit-listings-upload-images-row"
                                    >
                                        {
                                            customImages.map((item, index) =>
                                                <UploadedImage
                                                    item={item}
                                                    index={index}
                                                    deleteImage={deleteImageFunc}
                                                    key={index}
                                                />
                                            )
                                        }
                                    </ReactSortable>
                                    {
                                        customImages.length > 1 ?
                                        <div className="margin-top-small">
                                            <span className="body-regular colour-primary">
                                                Drag and drop to re-order images
                                            </span>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-financials-container edit-listings-section-border">
                                <h3 className="heading-small-semibold text-link margin-medium">
                                    Recommended Strategy
                                </h3>
                                <div className="edit-listings-recommended-strategy-container">
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="user-experience-label"
                                                id="partner-strategy-select"
                                                value={strategy}
                                                label=""
                                                onChange={(event) => setStrategy(event.target.value)}
                                                input={<CustomSelect />}
                                            >
                                                {
                                                    strategies.map((subItem, subIndex) =>
                                                        <MenuItem 
                                                            key={subIndex}
                                                            value={subItem.value}
                                                            style={styles.menuItem}
                                                        >
                                                            {subItem.label}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-financials-container edit-listings-section-border">
                                <h3 className="heading-small-semibold text-link">
                                    Financials
                                </h3>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Asking price
                                        </h4>
                                        <FinancialsInput
                                            value={price}
                                            type="number"
                                            id={`asking-price-input`}
                                            placeholder={formatterLong.format(property.price).replace(".00", "")}
                                            onWheel={(e) => e.target.blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => onChangePrice(text.target.value)}
                                        />
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <div className="edit-listings-switch-container margin-medium">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch checked={priceDefault} onChange={(e) => togglePriceDefault(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                                />
                                            </FormGroup>
                                            <span className="body-regular colour-primary">
                                                Use default
                                            </span>     
                                        </div>
                                        <span className="body-regular colour-primary">
                                            Zestimate{'  '}
                                            <span className="body-semibold colour-primary">
                                                {formatterLong.format(property.price).replace(".00", "")}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Rent
                                        </h4>
                                        <FinancialsInput
                                            value={rent}
                                            type="number"
                                            id={`rent-input`}
                                            placeholder={formatterLong.format(rent).replace(".00", "")}
                                            onWheel={(e) => e.target.blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => onChangeRent(text.target.value)}
                                        />
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <div className="edit-listings-switch-container margin-medium">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch checked={rentDefault} onChange={(e) => toggleRentDefault(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                                />
                                            </FormGroup>
                                            <span className="body-regular colour-primary">
                                                Use default
                                            </span>     
                                        </div>
                                        <span className="body-regular colour-primary">
                                            Coffee Clozers rent estimate{'  '}
                                            <span className="body-semibold colour-primary">
                                                {formatterLong.format(rent)}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            ARV
                                        </h4>
                                        <FinancialsInput
                                            value={arv}
                                            type="number"
                                            id={`arv-input`}
                                            placeholder={property.arvComps !== true ? "" : formatterLong.format(property.arvCompsStats.arvPrice).replace(".00", "")}
                                            onWheel={(e) => e.target.blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => onChangeARV(text.target.value)}
                                        />
                                    </div>
                                    <div className="edit-listings-body-right-container">
                                        <div className="edit-listings-switch-container margin-medium">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch checked={arvDefault} onChange={(e) => toggleARVDefault(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                                />
                                            </FormGroup>
                                            <span className="body-regular colour-primary">
                                                Use default
                                            </span>     
                                        </div>
                                        {
                                            checkNonDisclosureState() === true ?
                                            <NonDisclosureState
                                                property={property}
                                            />
                                            :
                                            property.arvComps !== true ?
                                            <span className="body-regular colour-primary">
                                                No ARV estimate available
                                            </span>
                                            :
                                            <span className="body-regular colour-primary">
                                                Coffee Clozers ARV estimate{'  '}
                                                <span className="body-semibold colour-primary">
                                                    {formatterLong.format(property.arvCompsStats.arvPrice).replace(".00", "")}
                                                </span>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-financials-container edit-listings-section-border">
                                <h3 className="heading-small-semibold text-link margin-medium">
                                    Neighborhood & Seller Information
                                </h3>
                                <div className="edit-listings-neighbourhood-seller-row margin-medium">
                                    <span className="body-regular colour-primary">
                                        Show seller information
                                    </span>
                                    <div className="edit-listings-switch-container">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<IOSSwitch checked={showSeller} onChange={(e) => toggleSellerInfo(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                            />
                                        </FormGroup>
                                        <span className="body-regular colour-primary">
                                            {
                                                showSeller === true ?
                                                "Active"
                                                :
                                                "Inactive"
                                            }
                                        </span>   
                                    </div>
                                </div>
                                <div className="edit-listings-neighbourhood-seller-row">
                                    <span className="body-regular colour-primary">
                                        Show neighborhood information
                                    </span>
                                    <div className="edit-listings-switch-container">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<IOSSwitch checked={showNeighbourhood} onChange={(e) => toggleNeighbourhoodInfo(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                            />
                                        </FormGroup>
                                        <span className="body-regular colour-primary">
                                            {
                                                showNeighbourhood === true ?
                                                "Active"
                                                :
                                                "Inactive"
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-listings-width-container edit-listings-financials-container edit-listings-section-border">
                                <h3 className="heading-small-semibold text-link margin-medium">
                                    Default Terms
                                </h3>
                                <span className="body-regular colour-secondary">
                                    Set the default values that your buyers will see
                                    if they are <span className="body-semibold underling">NOT</span>
                                    {' '}logged in, or simply haven't saved any custom settings
                                </span>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Down Payment
                                        </h4>
                                        <FinancialsInput
                                            value={downPayment}
                                            type="text"
                                            id={`down-payment-input`}
                                            placeholder={"20"}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => setDownPayment(text.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Interest rate
                                        </h4>
                                        <FinancialsInput
                                            value={interestRate}
                                            type="text"
                                            id={`interest-rate-input`}
                                            placeholder={"7"}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                maxLength: 4
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => setInterestRate(text.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-listings-body-row">
                                    <div className="edit-listings-body-left-container">
                                        <h4 className="body-semibold colour-primary margin-x-small">
                                            Closing costs
                                        </h4>
                                        <FinancialsInput
                                            value={closingCosts}
                                            type="text"
                                            id={`closing-costs-input`}
                                            placeholder={"3"}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                maxLength: 4
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={false}
                                            onChange={(text) => setClosingCosts(text.target.value)}
                                        />
                                    </div>
                                </div>
                                {
                                    (property.livingArea !== undefined && property.livingArea !== null) || (sqFootage !== "" && Number(sqFootage) !== 0) ?
                                    <div className="edit-listings-body-row">
                                        <div className="edit-listings-body-left-container">
                                            <h4 className="body-semibold colour-primary margin-x-small">
                                                Rehab cost per sq. foot
                                            </h4>
                                            <FinancialsInput
                                                value={rehabCost}
                                                type="text"
                                                id={`rehab-costs-input`}
                                                placeholder={"30"}
                                                onWheel={(e) => e.target.blur()}
                                                inputProps={{
                                                    maxLength: 3
                                                }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    endAdornment: <InputAdornment position="end">/sqft</InputAdornment>,
                                                }}
                                                disabled={false}
                                                onChange={(text) => setRehabCost(text.target.value)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {/* <div className="edit-listings-width-container">
                                <CompsEditor
                                    compsAccordion={compsAccordion}
                                    setCompsAccordion={setCompsAccordion}
                                    salesComps={salesComps}
                                    setSalesComps={setSalesComps}
                                    rentalComps={rentalComps}
                                    setRentalComps={setRentalComps}
                                    property={property}
                                    setProperty={setProperty}
                                    rent={rent}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="edit-listing-buttons-container">
                        <div className="edit-listing-left-container">
                            {
                                state.edit === true ?
                                <span 
                                    className="body-regular cursor-pointer colour-error cursor-pointer"
                                    onClick={() => activeDeleteListing()}
                                >
                                    Delete listing
                                </span>
                                :
                                null
                            }
                        </div>
                        <div className="edit-listing-right-container">
                            <div className="edit-listing-cancel-button subtext-margin-right-x-large">
                                <WhiteActionButton
                                    disabled={disabled}
                                    onClick={() => cancel()}
                                >
                                    Cancel
                                </WhiteActionButton>
                            </div>
                            <div className="edit-listing-save-listing">
                                <ActionButton
                                    disabled={loading === true ? true : disabled}
                                    onClick={() => complete()}
                                >
                                    Save listing
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default EditListing;