import React from 'react';
import '../styles/NewSwitch.css';

function NewSwitch(props) {
    
    const firstOption = props.firstOption;
    const pricingSwitch = props.pricingSwitch;
    const textOptions = props.textOptions;
    const large = props.large;

    return (
        <div className="pricing-cta-long-switch-container">
            <div className="pricing-cta-long-switch-element-container">
                <div className="pricing-cta-long-switch-element-inner-container">
                    <div 
                        className={"pricing-cta-thumb-element " + (firstOption === true ? "pricing-cta-move-left" : "pricing-cta-move-right")}
                        onClick={() => pricingSwitch()}
                    >
                    </div>
                    <div className="pricing-cta-long-switch-left-container">
                        <span 
                            className={"pricing-cta-long-switch-text colour-primary " + (firstOption === true && large === false ? "label-semibold " : large === false ? "label-regular" : firstOption === true && large === true ? "body-semibold" : "body-regular")}
                            onClick={() => pricingSwitch()}
                        >
                            {textOptions[0]}
                        </span>
                    </div>
                    <div className="pricing-cta-long-switch-right-container">
                        <span 
                            className={"pricing-cta-long-switch-text colour-primary " + (firstOption === true && large === false ? "label-regular " : large === false ? "label-semibold" : firstOption === true && large === true ? "body-regular" : "body-semibold")}
                            onClick={() => pricingSwitch()}
                        >
                            {textOptions[1]}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewSwitch;