import React, { useState } from 'react';
import '../styles/SidePanel.css';
import { percentage, formatterLong, numberFormatter, neighbourhoodValues, neighbourhoodColours } from '../styles/GlobalStyles';
import { Exit } from '../assets';
import { SidePanelTooltip } from './';
import { recordEvent } from '../functions';

function MapSidePanel(props) {
    const chosenTract = props.chosenTract;
    const mapSidePanelOpen = props.mapSidePanelOpen;
    const setMapSidePanelOpen = props.setMapSidePanelOpen;
    const setSidePanelCloseClicked = props.setSidePanelCloseClicked;
    const [economicsTooltipOpen, setEconomicsTooltipOpen] = useState(null);
    const [marketTooltipOpen, setMarketTooltipOpen] = useState(null);
    const [metricsTooltipOpen, setMetricsTooltipOpen] = useState(null);
    const [propertyAttributesTooltipOpen, setPropertyAttributesTooltipOpen] = useState(null);
    const devMode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? true : false;

    const topLevelStats = chosenTract === null ? [] : [
        {
            label: "Population",
            value: numberFormatter.format(chosenTract.stats.economics.totalPopulation)
        },
        {
            label: "ZIP code",
            value: chosenTract.region.zipCode
        }
    ];

    const economicsVals = chosenTract === null ? [] : [
        {
            label: "Median household income",
            value: formatterLong.format(chosenTract.stats.economics.medianHouseholdIncome).replace(".00", ""),
            rawValue: chosenTract.stats.economics.medianHouseholdIncome,
            stateValue: chosenTract.stats.economics.medianHouseholdIncomeState,
            positive: chosenTract.stats.economics.medianHouseholdIncome >= chosenTract.stats.economics.medianHouseholdIncomeState ? true : false,
            percentage: false,
            reverse: false,
            difference: chosenTract.stats.economics.medianHouseholdIncome >= chosenTract.stats.economics.medianHouseholdIncomeState ? chosenTract.stats.economics.medianHouseholdIncome - chosenTract.stats.economics.medianHouseholdIncomeState : chosenTract.stats.economics.medianHouseholdIncomeState - chosenTract.stats.economics.medianHouseholdIncome,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Bachelors or higher",
            value: percentage.format(chosenTract.stats.economics.ratioEducationGraduateDegree).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioEducationGraduateDegree,
            stateValue: chosenTract.stats.economics.ratioEducationGraduateDegreeState,
            positive: chosenTract.stats.economics.ratioEducationGraduateDegree >= chosenTract.stats.economics.ratioEducationGraduateDegreeState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioEducationGraduateDegree >= chosenTract.stats.economics.ratioEducationGraduateDegreeState ? chosenTract.stats.economics.ratioEducationGraduateDegree - chosenTract.stats.economics.ratioEducationGraduateDegreeState : chosenTract.stats.economics.ratioEducationGraduateDegreeState - chosenTract.stats.economics.ratioEducationGraduateDegree,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Employed",
            value: percentage.format(chosenTract.stats.economics.ratioEmployed).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioEmployed,
            stateValue: chosenTract.stats.economics.ratioEmployedState,
            positive: chosenTract.stats.economics.ratioEmployed >= chosenTract.stats.economics.ratioEmployedState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioEmployed >= chosenTract.stats.economics.ratioEmployedState ? chosenTract.stats.economics.ratioEmployed - chosenTract.stats.economics.ratioEmployedState : chosenTract.stats.economics.ratioEmployedState - chosenTract.stats.economics.ratioEmployed,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Vacancy ratio",
            value: percentage.format(chosenTract.stats.economics.ratioVacant).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioVacant,
            stateValue: chosenTract.stats.economics.ratioVacantState,
            positive: chosenTract.stats.economics.ratioVacant <= chosenTract.stats.economics.ratioVacantState ? true : false,
            percentage: true,
            reverse: true,
            difference: chosenTract.stats.economics.ratioVacant <= chosenTract.stats.economics.ratioVacantState ? chosenTract.stats.economics.ratioVacantState - chosenTract.stats.economics.ratioVacant : chosenTract.stats.economics.ratioVacant - chosenTract.stats.economics.ratioVacantState,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Owner occupied",
            value: percentage.format(chosenTract.stats.economics.ratioOwnerOccupied).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioOwnerOccupied,
            stateValue: chosenTract.stats.economics.ratioOwnerOccupiedState,
            positive: chosenTract.stats.economics.ratioOwnerOccupied >= chosenTract.stats.economics.ratioOwnerOccupiedState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioOwnerOccupied >= chosenTract.stats.economics.ratioOwnerOccupiedState ? chosenTract.stats.economics.ratioOwnerOccupied - chosenTract.stats.economics.ratioOwnerOccupiedState : chosenTract.stats.economics.ratioOwnerOccupiedState - chosenTract.stats.economics.ratioOwnerOccupied,
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const marketVals = chosenTract === null ? [] : [
        {
            label: "Number of listings",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.numberOfListings !== null ? numberFormatter.format(chosenTract.stats.market.numberOfListings) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median list price",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianListPrice !== null ? formatterLong.format(chosenTract.stats.market.medianListPrice).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median rent",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianRent !== null ? formatterLong.format(chosenTract.stats.market.medianRent).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median days on market",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianDaysOnMarket !== null ? numberFormatter.format(chosenTract.stats.market.medianDaysOnMarket) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const metricsVals = chosenTract === null ? [] : [
        {
            label: "Median cash on cash",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianCashOnCash !== null ? percentage.format(chosenTract.stats.metrics.medianCashOnCash) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Price to rent ratio",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.priceToRentRatio !== null ? numberFormatter.format(chosenTract.stats.metrics.priceToRentRatio) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median price per sq. foot",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianPricePerSqft !== null ? formatterLong.format(chosenTract.stats.metrics.medianPricePerSqft).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median ARV spread",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianArvSpread !== null ? percentage.format(chosenTract.stats.metrics.medianArvSpread) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const propertyAttributes = chosenTract === null ? [] : [
        {
            label: "Median bedrooms",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianBedrooms !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianBedrooms) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median bathrooms",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianBathrooms !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianBathrooms) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median sq. footage",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianSqft !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianSqft) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median construction year",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianYear !== null ? chosenTract.stats.propAttr.medianYear : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const calcPercentage = (item) => {
        if ( item.percentage === false ) {
            const percentageChange = Math.round(((item.rawValue - item.stateValue) / item.stateValue) * 100) / 100;
            return percentage.format(Math.abs(percentageChange));
        }
        else if ( item.positive === true ) {
            const percentageChange = Math.abs((Math.round((item.rawValue - item.stateValue) * 100) / 100));
            return percentage.format(percentageChange);
        }
        else {
            const percentageChange = (Math.round((item.stateValue - item.rawValue) * 100) / 100);
            return percentage.format(percentageChange);
        }
    };

    const closeSidePanel = () => {
        setSidePanelCloseClicked(true);
        setMapSidePanelOpen(false);
    };

    const moreInfo = () => {
        recordEvent("Open maps notion doc", {});
        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Maps-1541acce14b642ee8791b94c6c1cfccf", "_blank");
    };

    const openLink = (index) => {
        const links = [
            {
                link: "https://www.census.gov/data/developers/data-sets/acs-5year.html",
                title: "Economics"
            },
            {
                link: "https://www.census.gov/library/visualizations/2022/comm/median-household-income.html",
                title: "Median household income"
            },
            {
                link: "https://www.census.gov/topics/education/educational-attainment.html",
                title: "Education"
            },
            {
                link: "https://www.census.gov/topics/employment/labor-force.html",
                title: "Employment"
            },
            {
                link: "https://www.census.gov/housing/hvs/index.html",
                title: "Vacancy ratio"
            },
            {
                link: "https://www.census.gov/housing/hvs/index.html",
                title: "Owner occupied"
            }
        ];
        recordEvent("Open side panel source", {
            link: links[index].link,
            title: links[index].title
        });
        window.open(links[index].link, "_blank");
    };

    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    return (
        <div className={"side-panel-outer-container " + (mapSidePanelOpen === true ? "side-panel-animate-in" : mapSidePanelOpen === false ? "side-panel-animate-out" : "side-panel-hidden")}>
            <div className="side-panel-inner-container">
                <div 
                    className="side-panel-close-container"
                    onClick={() => closeSidePanel()}
                >
                    <img
                        src={Exit}
                        className="side-panel-close-icon"
                        alt="Close Modal"
                    />
                </div>
                {
                    chosenTract === null ?
                    <span className="body-regular colour-primary">
                        Click a neighborhood on the map to view insights
                    </span>
                    :
                    <div className="side-panel-body-container">
                        <div className="side-panel-title-container margin-large">
                            <h2 className="heading-small-semibold colour-primary margin-x-x-small">
                                Neighborhood Score
                            </h2>
                            <span className="body-regular colour-secondary">
                                The Neighborhood Score is based on a combination of micro and macro economic factors, 
                                amenities, and other market data. <span onClick={() => moreInfo()} className="underline colour-link">More info</span>
                            </span>
                        </div>
                        {
                            devMode === true ?
                            <span className="body-regular colour-primary block-text margin-medium">
                                Tract ID: {chosenTract.region.tractId}
                            </span>
                            :
                            null
                        }
                        {
                            chosenTract.region.emergingMarket === true ?
                            <span className="body-semibold colour-primary block-text margin-medium">
                                🌱 Emerging area
                            </span>
                            :
                            null
                        }
                        {
                            chosenTract.region.recentNewBuildGrowth === true ?
                            <span className="body-semibold colour-primary block-text margin-medium">
                                🏗️ Recent new build growth
                            </span>
                            :
                            null
                        }
                        <div 
                            className="side-panel-neighbourhood-grade-badge margin-medium"
                            style={
                                chosenTract.neighborhoodGrade === 8 ? styles.first :
                                chosenTract.neighborhoodGrade === 7 ? styles.second :
                                chosenTract.neighborhoodGrade === 6 ? styles.third :
                                chosenTract.neighborhoodGrade === 5 ? styles.fourth :
                                chosenTract.neighborhoodGrade === 4 ? styles.fifth :
                                chosenTract.neighborhoodGrade === 3 ? styles.sixth :
                                chosenTract.neighborhoodGrade === 2 ? styles.seventh :
                                styles.eighth
                            }
                        >
                            <span className={"body-regular " + (chosenTract.neighborhoodGrade === 8 ? "colour-primary" : "colour-white")}>
                                {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].label}
                            </span>
                        </div>
                        {/* <span className="body-regular colour-primary block-text margin-medium">
                            {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].description}
                        </span> */}
                        <div className="side-panel-row-container margin-large">
                            {
                                topLevelStats.map((item, index) =>
                                    <div 
                                        className="side-panel-beige-element bg-colour-light"
                                        key={index}
                                    >
                                        <div className="side-panel-beige-inner-element">
                                            <span className="label-regular colour-primary block-text margin-x-x-small">
                                                {item.label}
                                            </span>
                                            <span className="body-semibold colour-primary">
                                                {item.value}
                                            </span>
                                        </div>
                                    </div>   
                                )
                            }
                        </div>
                        <div className="side-panel-divider-line margin-tops">
                        </div>
                        <div className="side-panel-economics-container">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Economics
                            </h3>
                            <span className="body-regular colour-secondary block-text margin-large">
                                How this neighborhood compares to the rest of the state.
                            </span>
                            {
                                economicsVals.map((item, index) =>
                                    <div 
                                        className="economics-vals-element-container margin-large"
                                        key={index}
                                    >
                                        <div className="side-panel-grid-element-title-row">
                                            <span className="label-regular colour-primary block-text margin-x-x-small">
                                                {item.label}
                                            </span>
                                            {
                                                item.tooltip === true ?
                                                <div 
                                                    className="side-panel-tooltip-icon-container relative-container"
                                                    onMouseEnter={() => setEconomicsTooltipOpen(index)}
                                                    onMouseLeave={() => setEconomicsTooltipOpen(null)}
                                                >
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="16" 
                                                        height="16" 
                                                        viewBox="0 0 16 16" 
                                                        fill="none"
                                                        className="side-panel-tooltip-icon"
                                                    >
                                                        <path 
                                                            d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                            fill="#716E69"
                                                        />
                                                        <path 
                                                            d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                            fill="#716E69"
                                                        />
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                            fill="#716E69"
                                                        />
                                                    </svg>
                                                    {
                                                        economicsTooltipOpen === index ?
                                                        <SidePanelTooltip
                                                            title={item.title}
                                                            description={item.description}
                                                            link={item.link}
                                                            index={index}
                                                        />
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <span className="body-semibold colour-primary block-text margin-x-small">
                                            {item.value}
                                        </span>
                                        <div className={"economics-vals-comparison-container " + (item.percentage === true && item.difference < 0.01 ? "bg-colour-medium" : item.positive === true ? "bg-colour-green-6" : "bg-colour-red-3")}>
                                            <div className="economics-vals-inner-comparison-container">
                                                {
                                                    item.percentage === true && item.difference < 0.01 ?
                                                    null
                                                    :
                                                    <div className={"economics-vals-comparison-icon-container subtext-margin-right " + (item.reverse === true ? "reverse" : "")}>
                                                        {
                                                            item.positive === false ?
                                                            <svg 
                                                                xmlns="http://www.w3.org/2000/svg" 
                                                                width="8" 
                                                                height="8" 
                                                                viewBox="0 0 8 8" 
                                                                fill="none"
                                                            >
                                                                <path 
                                                                    d="M4.36274 7.84168L7.80272 4.4017C8.01381 4.19061 8.01381 3.84835 7.80272 3.63726C7.59162 3.42617 7.24937 3.42617 7.03828 3.63726L4.52106 6.15448L4.52106 0.540541C4.52106 0.242008 4.27905 0 3.98052 0C3.68199 0 3.43998 0.242008 3.43998 0.540541L3.43998 6.15448L0.922761 3.63726C0.711666 3.42617 0.369415 3.42617 0.158321 3.63726C-0.0527736 3.84836 -0.0527735 4.19061 0.158321 4.4017L3.5983 7.84168C3.80939 8.05277 4.15165 8.05277 4.36274 7.84168Z" 
                                                                    fill="#CC564B"
                                                                />
                                                            </svg>
                                                            :
                                                            <svg 
                                                                xmlns="http://www.w3.org/2000/svg" 
                                                                width="8" 
                                                                height="8" 
                                                                viewBox="0 0 8 8" 
                                                                fill="none"
                                                            >
                                                                <path 
                                                                    d="M4.36274 0.15832L7.80272 3.5983C8.01381 3.80939 8.01381 4.15165 7.80272 4.36274C7.59162 4.57383 7.24937 4.57383 7.03828 4.36274L4.52106 1.84552L4.52106 7.45946C4.52106 7.75799 4.27905 8 3.98052 8C3.68199 8 3.43998 7.75799 3.43998 7.45946L3.43998 1.84552L0.922761 4.36274C0.711666 4.57383 0.369415 4.57383 0.158321 4.36274C-0.0527736 4.15164 -0.0527735 3.80939 0.158321 3.5983L3.5983 0.15832C3.80939 -0.0527735 4.15165 -0.0527735 4.36274 0.15832Z" 
                                                                    fill="#1C8557"
                                                                />
                                                            </svg>
                                                        }
                                                    </div>
                                                }
                                                <span className={"label-semibold " + (item.percentage === true && item.difference < 0.01 ? "colour-primary" : item.positive === true ? "colour-green-7" : "colour-error")}>
                                                    {
                                                        item.percentage === true && item.difference < 0.01 ?
                                                        `About the same as state average`
                                                        :
                                                        `${calcPercentage(item)} ${item.positive === true && item.reverse === false ? "above" : item.positive === false && item.reverse === false ? "below" : item.positive === true && item.reverse === true ? "below" : "above"} state average`
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="side-panel-divider-line margin-tops">
                        </div>
                        <div className="side-panel-market-container">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Market
                            </h3>
                            <span className="body-regular colour-secondary block-text margin-large">
                                Specific stats on this neighborhood.
                            </span>
                            <div className="side-panel-grid-container margin-large">
                                {
                                    marketVals.map((item, index) =>
                                        <div 
                                            className="side-panel-grid-element"
                                            key={index}
                                        >
                                            <div className="side-panel-grid-element-title-row">
                                                <span className="label-regular colour-primary block-text margin-x-x-small">
                                                    {item.label}
                                                </span>
                                                {
                                                    item.tooltip === true ?
                                                    <div 
                                                        className="side-panel-tooltip-icon-container relative-container"
                                                        onMouseEnter={() => setMarketTooltipOpen(index)}
                                                        onMouseLeave={() => setMarketTooltipOpen(null)}
                                                    >
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                            className="side-panel-tooltip-icon"
                                                        >
                                                            <path 
                                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                                fill="#716E69"
                                                            />
                                                        </svg>
                                                        {
                                                            marketTooltipOpen === index ?
                                                            <SidePanelTooltip
                                                                title={item.title}
                                                                description={item.description}
                                                                link={item.link}
                                                                index={index}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <span className="body-semibold colour-primary">
                                                {item.value}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="side-panel-divider-line margin-tops">
                        </div>
                        <div className="side-panel-metrics-container">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Metrics
                            </h3>
                            <span className="body-regular colour-secondary block-text margin-large">
                                Property performance metrics in this neighborhood.
                            </span>
                            <div className="side-panel-grid-container margin-large">
                                {
                                    metricsVals.map((item, index) =>
                                        <div 
                                            className="side-panel-grid-element"
                                            key={index}
                                        >
                                            <div className="side-panel-grid-element-title-row">
                                                <span className="label-regular colour-primary block-text margin-x-x-small">
                                                    {item.label}
                                                </span>
                                                {
                                                    item.tooltip === true ?
                                                    <div 
                                                        className="side-panel-tooltip-icon-container relative-container"
                                                        onMouseEnter={() => setMetricsTooltipOpen(index)}
                                                        onMouseLeave={() => setMetricsTooltipOpen(null)}
                                                    >
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                            className="side-panel-tooltip-icon"
                                                        >
                                                            <path 
                                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                                fill="#716E69"
                                                            />
                                                        </svg>
                                                        {
                                                            metricsTooltipOpen === index ?
                                                            <SidePanelTooltip
                                                                title={item.title}
                                                                description={item.description}
                                                                link={item.link}
                                                                index={index}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <span className="body-semibold colour-primary">
                                                {item.value}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="side-panel-divider-line margin-tops">
                        </div>
                        <div className="side-panel-property-attributes-container">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Property attributes
                            </h3>
                            <span className="body-regular colour-secondary block-text margin-large">
                                What the average property looks like in this neighborhood.
                            </span>
                            <div className="side-panel-grid-container margin-large">
                                {
                                    propertyAttributes.map((item, index) =>
                                        <div 
                                            className="side-panel-grid-element"
                                            key={index}
                                        >
                                            <div className="side-panel-grid-element-title-row">
                                                <span className="label-regular colour-primary block-text margin-x-x-small">
                                                    {item.label}
                                                </span>
                                                {
                                                    item.tooltip === true ?
                                                    <div 
                                                        className="side-panel-tooltip-icon-container relative-container"
                                                        onMouseEnter={() => setPropertyAttributesTooltipOpen(index)}
                                                        onMouseLeave={() => setPropertyAttributesTooltipOpen(null)}
                                                    >
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                            className="side-panel-tooltip-icon"
                                                        >
                                                            <path 
                                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                                fill="#716E69"
                                                            />
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                                fill="#716E69"
                                                            />
                                                        </svg>
                                                        {
                                                            propertyAttributesTooltipOpen === index ?
                                                            <SidePanelTooltip
                                                                title={item.title}
                                                                description={item.description}
                                                                link={item.link}
                                                                index={index}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <span className="body-semibold colour-primary">
                                                {item.value}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="side-panel-divider-line margin-tops">
                        </div>
                        <div className="side-panel-citations-container margin-large">
                            <span className="body-regular colour-secondary block-text">
                                <span className="body-semibold">
                                    Economics Data Sources
                                </span>
                                <br />
                                U.S. Census Bureau. <span onClick={() => openLink(0)} className="colour-link underline text-italic">American Community Survey (ACS).</span> 5 Year Estimates 2022.
                                <br/>
                                <span className="body-semibold">
                                    Links:
                                </span>
                                {' '}<span 
                                        className="underline colour-link"
                                        onClick={() => openLink(1)}
                                    >
                                        Median household income
                                    </span>,{' '}
                                <span 
                                    className="underline colour-link"
                                    onClick={() => openLink(2)}
                                >
                                    Education
                                </span>,{' '}
                                <span 
                                    className="underline colour-link"
                                    onClick={() => openLink(3)}
                                >
                                    Employment
                                </span>,{' '}
                                <span 
                                    className="underline colour-link"
                                    onClick={() => openLink(4)}
                                >
                                    Vacancy ratio
                                </span>,{' '}
                                <span 
                                    className="underline colour-link"
                                    onClick={() => openLink(5)}
                                >
                                    Owner occupied
                                </span>
                            </span>
                        </div>
                        <span className="label-regular colour-primary">
                            Map data updates weekly. Property data updates daily.
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default MapSidePanel;