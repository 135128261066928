import React from 'react';
import '../styles/BackButton.css';
import { ChevronLeftThick } from '../assets';

function BackButton(props) {
    const func = props.func;
    const outerClass = props.outerClass;
    return (
        <div 
            className={"back-button-outer-container text-button " + (outerClass)}
            onClick={() => func()}
        >
            <div className="back-button-inner-container">
                <img
                    src={ChevronLeftThick}
                    className="back-button-icon"
                    alt="Back"
                />
                <span className="label-regular colour-link">
                    Back
                </span>
            </div>
        </div>
    )
}

export default BackButton;