import React, { useEffect, useState } from 'react';
import '../styles/InteractiveMap.css';
import { GoogleMap, useLoadScript, OverlayView, Polygon, OverlayViewF } from '@react-google-maps/api';
import { mapColours, colour, neighbourhoodColours } from '../styles/GlobalStyles';
import { MapLegend } from './';

function MultiLocationMap(props) {
	const properties = props.properties;
	const controls = props.controls;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const neighbourhoodData = props.neighbourhoodData;
	const centreCoordinates = props.centreCoordinates;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});

    const [centre] = useState({ lat: centreCoordinates.lat, lng: centreCoordinates.lng });

	const [renderOverlay, setRenderOverlay] = useState(false);

	const options = {
		disableDefaultUI: controls,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false
	}

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}
	}, [renderOverlay]);

	
	const checkNeighbourhoodStyles = (index) => {
		const tractValue = neighbourhoodData[index].neighborhoodGrade;
		const getHex = tractValue === 8 ? styles.first.backgroundColor 
		:
		tractValue === 7 ? styles.second.backgroundColor
		:
		tractValue === 6 ? styles.third.backgroundColor
		:
		tractValue === 5 ? styles.fourth.backgroundColor
		:
		tractValue === 4 ? styles.fifth.backgroundColor
		:
		tractValue === 3 ? styles.sixth.backgroundColor
		:
		tractValue === 2 ? styles.seventh.backgroundColor
		:
		styles.eighth.backgroundColor;
		
		const newStyle = {
			fillColor: getHex,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		}
		
		return newStyle;
	};

	const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

	return (
		<div className="relative-container">
			{
				!isLoaded ? "Loading"
				:
                centreCoordinates.length === 0 ?
                null
                :
				<GoogleMap
					zoom={11}
					center={centre}
					mapContainerClassName="location-map-container"
					options={options}
				>
					{
						renderOverlay === true ?
						<MapLegend
							hoverMapLegend={null}
							setHoverMapLegend={null}
							neighbourhoodOption={null}
						/>
						:
						null
					}
					{
						renderOverlay === true ?
						neighbourhoodBounds.map((item, index) =>
							<Polygon
								editable={false}
								paths={item}
								options={checkNeighbourhoodStyles(index)}
								key={index}
							/>
						)
						:
						null
					}
					{
						renderOverlay === true ?
                            properties.map((property, index) =>
                                <OverlayViewF
                                    position={{ lat: property.latitude, lng: property.longitude }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    key={index}
                                >
                                    <div 
                                        className="multi-location-map-overlay"
                                    >
										<span className="label-semibold colour-primary">
											{property.address.streetAddress}
										</span>
                                    </div>
                                </OverlayViewF>	
                            )
						:
						null
					}
				</GoogleMap>
			}
		</div>
	)
}

export default MultiLocationMap;