import React, { useState } from 'react';
import '../styles/ReportFinancialsBugModal.css';
import { ActionButton, NewMultilineInput } from '../styles/GlobalStyles';
import { Exit } from "../assets";

function ReportFinancialsBugModal(props) {
	const setBugModal = props.setBugModal;
	const complete = props.complete;
	const disabled = props.disabled;
	const modalRef = props.modalRef;

	const [bugText, setBugText] = useState("");

	const closeModal = () => {
		// document.body.removeAttribute("style");
		setBugModal(false)
	}

	return (
		<div id="reportFinancialsModal" ref={modalRef} className="report-financials-bug-modal-outer-container">
			<div className="report-financials-bug-modal-exit-container">
				<img
					src={Exit}
					className="report-financials-bug-modal-exit-icon"
					alt="Close Modal"
					onClick={() => closeModal()}
				/>
			</div>
			<div className="report-financials-bug-modal-inner-container">
				<h2 className="report-financials-bug-modal-title">
					Report a bug
				</h2>
				<p className="report-financials-bug-modal-text">
					If you see some data the looks inaccurate 
					(e.g. the estimated rent), we really want to know!
				</p>
				<div className="report-financials-bug-modal-body-container">
					<h3 className="report-financials-bug-modal-body-title">
						What did we get wrong?
					</h3>
					<NewMultilineInput
						value={bugText}
						type="text"
						id="report-bug-input"
						placeholder="Leave feedback"
						rows="8"
						inputProps={{
							autoCapitalize: 'none'
						}}
						onChange={(text) => setBugText(text.target.value)}
						multiline
					/>
				</div>
				<div className="report-financials-bug-button-container">
					<ActionButton
						disabled={bugText === "" ? true : disabled}
						onClick={() => complete(bugText)}
					>
						Submit
					</ActionButton>
				</div>
			</div>
		</div>
	)
}

export default ReportFinancialsBugModal;