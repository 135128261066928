import React from 'react';
import '../styles/PricingCard.css';
import { Cappuccino } from '../assets/images';
import { formatterLong } from '../styles/GlobalStyles';

function RecordsPricingCard(props) {
    const total = props.total;
    const plan = props.plan;

    return (
        <div className="pricing-card-outer-container">
            <div className="pricing-card-top-inner-container">
                <div className="pricing-card-image-container">
                    <img
                        src={Cappuccino}
                        className="pricing-card-image"
                        alt="Cappuccino"
                    />
                </div>
                <span className="body-regular colour-quaternary margin-x-small block-text">
                    Coffee Clozers Premium
                </span>
                <h2 className="heading-large-semibold text-link">
                    Upgrade summary
                </h2>
            </div>
            <div className="pricing-card-bottom-container bg-colour-white">
                <div className="pricing-card-cities-list-container margin-medium">
                    <div className="pricing-card-bottom-inner-container margin-x-large">
                        <div className="pricing-card-bottom-left-container">
                            <h3 className="body-semibold colour-primary margin-x-x-small">
                                {plan.title}
                            </h3>
                            <h4 className="body-semibold colour-quaternary">
                                {plan.description}
                            </h4>
                        </div>
                        <div className="pricing-card-bottom-right-container">
                            <span className="body-semibold colour-primary">
                                {formatterLong.format(total).replace(".00", "")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="city-selection-divider-container margin-tops">
                </div>
                <div className="pricing-card-total-container">
                    <div className="pricing-card-total-label-container">
                        <span className="body-semibold colour-primary">
                            Total
                        </span>
                    </div>
                    <div className="pricing-card-total-text-container margin-x-small">
                        <div className="pricing-card-total-inner-container">
                            <div className="button-row">
                                <span className="body-semibold colour-primary">
                                    {formatterLong.format(total).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordsPricingCard;