import React, { useEffect, useState } from 'react';
import { Header, Footer, Chip, PlaceInput, LoadingStatic, PropertyAbstract, CompMap, ARVComps } from '../components';
import { checkOnAuthStateChanged, cloudFunctionV2, getARVGateway, queryARVErrorEmail } from '../functions';
import '../styles/ARVTool.css';
import { useNavigate } from 'react-router-dom';
import { FormInput, CustomSelect, citiesForPurchase, colour, ActionButton, formatterLong } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ZillowLogo } from '../assets';

function ARVTool() {
    const [cityId, setCityId] = useState("");
    const [selectedPanel, setSelectedPanel] = useState(0);
    const [zpid, setZpid] = useState("");
    const [address, setAddress] = useState("");
    const [searchLocation, setSearchLocation] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [property, setProperty] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [cityIndex, setCityIndex] = useState("placeholder");
    const navigate = useNavigate();

	useEffect(() => {
		document.title = "ARV Tool";

		async function checkLoggedIn() {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 && (user.userId === "QiT75rQsoMfiG96JFyfSKOfiHJu2" || user.userId === "HHTwL0UD3fQmNcIyL3gpvnyZdCM2" || user.userId === "3Y07zDu9wibxwnt3Oe6Nsv6qRtp1") ) {
                
			}
			else {
				navigate('/sign-up');
			}
		}

		checkLoggedIn();
	}, [navigate]);

    const searchOptions = [
        "City ID/ZPID",
        "Address"
    ];

    const changeCityId = (val) => {
        setCityIndex(val);
        setCityId(citiesForPurchase[val].city_id);

        if ( val !== "" ) {
            setAddress("");
        }
    };

    const changeZPID = (val) => {
        setZpid(val);
        if ( val !== "" ) {
            setAddress("");
        }
    };

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    const changeLocation = (val) => {
        setSearchLocation(val);
    };

    const selectedLocation = (item) => {
        if ( item !== null && item.label !== undefined ) {
            setAddress(item.label);
            complete(item.label);
        }
    };

    const complete = async(localAddress) => {
        setDisabled(true);
        const getARVParams = await getARVGateway(cityId, zpid, localAddress);
        const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
        const gatewayData = {
            type: "gateway",
            resourceId: "arv",
            queryString: getARVParams
        };

        const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
        if ( getGateway.status === 200 ) {
            const gatewayBody = getGateway.body;
            const subjectProperty = JSON.parse(gatewayBody.subjProp);
            subjectProperty[0].propertySearch = true;
            if ( subjectProperty[0].propertyType === "SINGLE_FAMILY" ) {
                subjectProperty[0].propertyTypeDimension = "Single Family";
            }
            else if ( subjectProperty[0].propertyType === "MULTI_FAMILY" ) {
                subjectProperty[0].propertyTypeDimension = "Multi Family";
            }
            else if ( subjectProperty[0].propertyType === "TOWNHOUSE" ) {
                subjectProperty[0].propertyTypeDimension = "Townhouse";
            }
            else if ( subjectProperty[0].propertyType === "CONDO" ) {
                subjectProperty[0].propertyTypeDimension = "Condo";
            }
            else if ( subjectProperty[0].propertyType === "Manufactured" ) {
                subjectProperty[0].propertyTypeDimension = "Manufactured";
            }
            else {
                subjectProperty[0].propertyTypeDimension = subjectProperty[0].propertyType; 
            }

            const propRes = {
                compStats: gatewayBody.compStats,
                comps: JSON.parse(gatewayBody.comps),
                subjProp: subjectProperty[0]
            };
            setProperty(propRes);
            setErrorMessage("");
        }
        else {
            if ( getGateway.status === 405 ) {
                // Internal server error
                await queryARVErrorEmail(getGateway.body, localAddress);
            }

            if ( getGateway.error !== undefined ) {
                setErrorMessage(getGateway.error);
            }
            else {
                setErrorMessage(getGateway.body.message);
            }
        }

        setDisabled(false);
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

	return (
		<div className="arv-tool-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="arv-tool-inner-container">
                <div className="property-search-title-container margin-x-large">
                    <div className="property-search-title-inner-container">
                        <h1 className="heading-large-semibold colour-primary margin-medium">
                            ARV Tool
                        </h1>
                        <span className="body-regular colour-primary block-text margin-medium">
                            Search for an address or City ID/ZPID combo
                        </span>
                        <div className="arv-tool-chips-row margin-medium">
                            {
                                searchOptions.map((item, index) =>
                                    <Chip
                                        label={item}
                                        func={changeSelectedPanel}
                                        index={index}
                                        selected={selectedPanel}
                                        key={index}
                                        changeIcon={false}
                                        recommended={false}
                                        hover={null}
                                        leave={null}
                                        disabled={false}
                                    />
                                )
                            }
                        </div>
                        {
                            disabled === true ?
                            <LoadingStatic />
                            :
                            selectedPanel === 0 ?
                            <div className="arv-tool-inputs-outer-container margin-medium">
                                <div className="arv-tool-input-container margin-x-small">
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="city-select-label"
                                                id="city-select"
                                                value={cityIndex}
                                                label=""
                                                onChange={(event) => changeCityId(event.target.value)}
                                                input={<CustomSelect />}
                                            >   
                                                <MenuItem 
                                                    value={"placeholder"}
                                                    style={styles.menuItem}
                                                    disabled
                                                >
                                                    Choose your city
                                                </MenuItem>	
                                                {
                                                    citiesForPurchase.map((item, index) =>
                                                        <MenuItem 
                                                            key={index}
                                                            value={index}
                                                            style={styles.menuItem}
                                                        >
                                                            {item.cityUnchanged}, {item.state}
                                                        </MenuItem>	
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>   
                                </div>
                                <div className="arv-tool-input-container">
                                    <FormInput
                                        value={zpid}
                                        type="text"
                                        label="ZPID"
                                        id="zpid-input"
                                        placeholder="ZPID"
                                        onChange={(text) => changeZPID(text.target.value)}
                                    />
                                </div>
                            </div>
                            :
                            <div className="arv-tool-inputs-outer-container margin-medium">
                                <PlaceInput
                                    placeholder="Enter address"
                                    localLocation={searchLocation}
                                    changeLocation={changeLocation}
                                    selectedLocation={selectedLocation}
                                    types={['address']}
                                    disabled={false}
                                />
                            </div>
                        }
                        {
                            errorMessage !== "" ?
                            <span className="body-regular colour-error margin-medium block-text">
                                {errorMessage}
                            </span>
                            :
                            null
                        }
                        {
                            selectedPanel === 0 ?
                                <div className="arv-tool-button-container">
                                    <ActionButton
                                        disabled={address === "" && zpid === "" ? true : disabled}
                                        onClick={() => complete("")}
                                    >
                                        Submit
                                    </ActionButton>
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
                {
                    property === null ?
                    null
                    :
                    disabled === true ?
                    <LoadingStatic />
                    :
                    <div className="arv-tool-property-container">
                        {
                            property.subjProp.imgSrc !== undefined && property.subjProp.imgSrc !== null ?
                            <div className="arv-tool-image-container">
                                <img
                                    src={property.subjProp.imgSrc}
                                    className="arv-tool-image-element"
                                    alt="Property"
                                />
                            </div>
                            :
                            null
                        }
                        <div className="arv-tool-zillow-button margin-top-medium">
                            <a 
                                rel="noreferrer" 
                                href={`https://www.zillow.com/homedetails/2242-E-81st-St-Cleveland-OH-44103/${property.subjProp.zpid}_zpid/`}
                                target="_blank" 
                                title="View on Zillow"
                                className={`zillow-button`}
                            >
                                <span className="body-regular colour-white">
                                    Visit on 
                                </span>
                                <img
                                    src={ZillowLogo}
                                    className="zillow-logo"
                                    alt="View on Zillow"
                                />
                            </a>
                        </div>
                        <PropertyAbstract
                            propertySearch={true}
                            formatter={formatterLong}
                            property={property.subjProp}
                            queryARV={null}
                            arvLoading={false}
                            arvErrorMessage={null}
                            partner={false}
                            strategy={null}
                            runComps={null}
                        />
                        <h3 className="heading-small-semibold colour-primary margin-medium margin-top-medium">
                            Comp Stats
                        </h3>
                        <div className="arv-tool-comp-stats-header margin-medium">
                            <div className="arv-tool-comp-stats-header-element margin-x-small">
                                <span className="body-semibold colour-primary">
                                    ARV Price - {formatterLong.format(property.compStats.arvPrice).replace(".00", "")}
                                </span>
                            </div>
                            <div className="arv-tool-comp-stats-header-element">
                                <span className="body-semibold colour-primary">
                                    ARV $/sqft - {formatterLong.format(property.compStats.arvPpsf).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                        <div className="arv-tool-comp-stats margin-medium">
                            <ul className="arv-tool-comp-stats-list">
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Min. $/sqft - {formatterLong.format(property.compStats.minPpsf).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Avg. $/sqft - {formatterLong.format(property.compStats.avgPpsf).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Med. $/sqft - {formatterLong.format(property.compStats.medianPpsf).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Max. $/sqft - {formatterLong.format(property.compStats.maxPpsf).replace(".00", "")}
                                    </span>
                                </li>
                            </ul>
                            <ul className="arv-tool-comp-stats-list">
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Min. Price - {formatterLong.format(property.compStats.minPrice).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Avg. Price - {formatterLong.format(property.compStats.avgPrice).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Med. Price - {formatterLong.format(property.compStats.medianPrice).replace(".00", "")}
                                    </span>
                                </li>
                                <li className="arv-tool-comp-stats-element margin-x-small">
                                    <span className="body-regular colour-primary">
                                        Max. Price - {formatterLong.format(property.compStats.maxPrice).replace(".00", "")}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <ARVComps
                            comps={property.comps}
                            blurred={false}
                            propertySearch={false}
                            partnerEdit={false}
                            deleteComp={null}
                            excludedComps={[]}
                        />
                        <CompMap
                            property={property.subjProp}
                            comps={property.comps}
                        />
                    </div>
                }
            </div>
            <Footer />
		</div>
	)
}

export default ARVTool;