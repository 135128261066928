import React from 'react';
import '../styles/SliderText.css';
import { formatterCompact } from '../styles/GlobalStyles';

function CustomSliderText(props) {
    const text = props.text;
    const shText = props.shText;
    const knownValue = props.knownPercentile;
    const desiredValue = props.desiredPercentile;
    const onePercentile = knownValue / 75;
    const desiredPercentile = desiredValue / onePercentile;

    const styles = {
        position: {
            left: `${desiredPercentile}%`,
            position: 'absolute',
            top: '38px'
        }
    };

    return (
        desiredPercentile > 67 || desiredPercentile < 0 ?
        null
        :
        <div style={styles.position} className="slider-text-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                <path d="M4 0L7.4641 4.5H0.535898L4 0Z" fill="#716E69"/>
            </svg>
            <span className="label-regular colour-secondary block-text margin-tops-small">
                {
                    desiredPercentile > 57 ?
                    shText
                    :
                    text
                }
            </span>
            <span className="label-semibold colour-primary">
                {formatterCompact.format(desiredValue)}
            </span>
        </div>
    )
};

export default CustomSliderText;