import React, { useState, useRef } from 'react';
import { CustomRadio, FinancialsInput, IOSSwitch, colour, CustomSelect, ActionButton } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Exit, Plus } from '../assets';
import { Loading } from './';
import { checkUserId, setData, defaultInitialCosts, defaultRecurringCosts } from '../functions';
// import { useIsInViewport } from '../hooks';

function FinancialsSettings(props) {
    // const monthlyCostRadio = props.monthlyCostRadio;
    // const changeMonthlyCostRadio = props.changeMonthlyCostRadio;
    const mortgageRadio = props.mortgageRadio;
    const changeMortgageRadio = props.changeMortgageRadio;
    const disabled = props.disabled;
    const setDisabled = props.setDisabled;
    const userDataSettings = props.userSettings;
    const [loanType, setLoanType] = useState(userDataSettings !== undefined ? userDataSettings.loanType : "30");
    const [customCities, setCustomCities] = useState(userDataSettings !== undefined ? userDataSettings.customCities : []);
    const [recurringCosts, setRecurringCosts] = useState(userDataSettings !== undefined ? userDataSettings.recurringCosts : defaultRecurringCosts);
    const [initialCosts, setInitialCosts] = useState(userDataSettings !== undefined ? userDataSettings.initialCosts : defaultInitialCosts);
    const [loading, setLoading] = useState(false);
    const [initialCostsEmptyError, setInitialCostsEmptyError] = useState(null);
    const [recurringCostsEmptyError, setRecurringCostsEmptyError] = useState(null);
    const [cityChanges, setCityChanges] = useState(false);
    const [rehabSwitch, setRehabSwitch] = useState(userDataSettings.rehabCosts !== undefined ? userDataSettings.rehabCosts.default : true);
    const [costPerSqFoot, setCostPerSqFoot] = useState(userDataSettings.rehabCosts !== undefined ? userDataSettings.rehabCosts.value : "");
    const [rehabCostError, setRehabCostError] = useState(false);
    // const buttonRef = useRef(null);
	// const buttonIsInViewport = useIsInViewport(buttonRef);
    const recurringCostError = useRef(null);
    const initialCostError = useRef(null);
    const rehabCostErrorRef = useRef(null);
    const selectedPanel = props.selectedPanel;
    const setSelectedPanel = props.setSelectedPanel;

    const loanRanges = [
        {
            label: "40 Year Fixed",
            value: "40"
        },
        {
            label: "30 Year Fixed",
            value: "30"
        },
        {
            label: "25 Year Fixed",
            value: "25"
        },
        {
            label: "20 Year Fixed",
            value: "20"
        },
        {
            label: "15 Year Fixed",
            value: "15"
        }
    ];

    const onChangeRecurringCosts = (value, index) => {
        const clone = recurringCosts.slice(0);
        const amendedValue = value.replaceAll(/\s/g,'');
        const numberValue = Number(amendedValue);
        if ( numberValue > 100 ) {
            return;
        }
        else {
            clone[index].value = amendedValue;
            clone[index].default = amendedValue === "" ? true : false;
            setRecurringCosts(clone);
            setDisabled(false);
        }
    };

    const onChangeRecurringCostsSwitch = (val, index) => {
        const clone = recurringCosts.slice(0);
        clone[index].default = val;
        clone[index].value = "";
        setRecurringCosts(clone);
        setDisabled(false);
    }

    const onChangeRehabCost = (val) => {
        const amendedValue = val.replaceAll(/\s/g,'');
        setCostPerSqFoot(amendedValue);

        if ( amendedValue === "" ) {
            setRehabSwitch(true);
        }
        else {
            setRehabSwitch(false);
        }
        setDisabled(false);
    };

    const onChangeRehabSwitch = (val) => {
        setRehabSwitch(val);
        setDisabled(false);

        if ( val === true ) {
            setCostPerSqFoot("");
        }
    };

    const onChangeInitialCostsSwitch = (val, index) => {
        const clone = initialCosts.slice(0);
        clone[index].default = val;
        clone[index].value = "";
        setInitialCosts(clone);
        setDisabled(false);
    }

    const onChangeInitialCosts = (value, index) => {
        const clone = initialCosts.slice(0);
        const amendedValue = value.replaceAll(/\s/g,'');
        const valueNumber = Number(amendedValue);
        if ( valueNumber > 100 ) {
            return;
        }
        else {
            clone[index].value = amendedValue;
            clone[index].default = amendedValue === "" ? true : false;
            setInitialCosts(clone);
            setDisabled(false);
        }
    };

    const onChangeLoanYears = (val) => {
        setLoanType(val);
        setDisabled(false);
    }

    const onChangeSetting = (val, index, settingIndex) => {
        const clone = customCities.slice(0);
        clone[index].settings[settingIndex].type = val;

        const labelIndex = clone[index].settings[settingIndex].options.findIndex(e => e.value === val);
        clone[index].settings[settingIndex].label = clone[index].settings[settingIndex].options[labelIndex].label;
        clone[index].settings[settingIndex].info = clone[index].settings[settingIndex].options[labelIndex].info;
        clone[index].settings[settingIndex].rentPercentage = clone[index].settings[settingIndex].options[labelIndex].rentPercentage;
        setCustomCities(clone);
        setCityChanges(true);
        setDisabled(false);
    };

    const removeOption = (index, settingIndex) => {
        const clone = customCities.slice(0);
        clone[index].settings.splice(settingIndex, 1);
        setCustomCities(clone);
        setCityChanges(true);
        setDisabled(false);
    }

    const onChangeCityValue = (value, index, settingIndex) => {
        const clone = customCities.slice(0);
        const amendedValue = value.replaceAll(/\s/g,'');
        if ( clone[index].settings[settingIndex].rentPercentage === true ) {
            const valueNumber = Number(amendedValue);
            if ( valueNumber > 100 ) {
                return;
            }
        }

        clone[index].settings[settingIndex].value = amendedValue;
        setCustomCities(clone);
        setCityChanges(true);
        setDisabled(false);
    }

    const addSetting = (index) => {
        setCityChanges(true);
        setDisabled(false);
        const clone = customCities.slice(0);
        const newSetting = {
            value: "",
            label: "",
            type: "",
            info: "",
            options: [
                {
                    value: "insurance",
                    label: "Insurance",
                    info: recurringCosts[0].info,
                    rentPercentage: false
                },
                {
                    value: "property-taxes",
                    label: "Property taxes",
                    info: recurringCosts[1].info,
                    rentPercentage: false
                },
                {
                    value: "vacancy-rate",
                    label: "Vacancy rate",
                    info: recurringCosts[2].info,
                    rentPercentage: true
                },
                {
                    value: "management-fee",
                    label: "Management fee",
                    info: recurringCosts[3].info,
                    rentPercentage: true
                },
                {
                    value: "maintenance-fee",
                    label: "Maintenance fee",
                    info: recurringCosts[4].info,
                    rentPercentage: true
                },
                {
                    value: "other",
                    label: "Other costs",
                    info: recurringCosts[5].info,
                    rentPercentage: true
                },
                {
                    value: "rehab",
                    label: "Rehab",
                    info: "",
                    rentPercentage: false
                }
            ]
        };
        clone[index].settings.push(newSetting);
        setCustomCities(clone);
    };

    const checkDisabled = (index, value) => {
        const cityId = customCities[index].city_id;
        const cityIndex = customCities.findIndex(e => e.city_id === cityId);
        const citySettings = customCities[cityIndex].settings;
        const citySettingIndex = citySettings.findIndex(e => e.type === value);
        if ( citySettingIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const checkButtonDisabled = () => {
        const emptyArray = [];

        for (let i = 0; i < customCities.length; i++) {
            const element = customCities[i].settings;
            for (let i2 = 0; i2 < element.length; i2++) {
                const subElement = element[i2];
                if ( subElement.value === "" ) {
                    emptyArray.push(true);
                }
            }
        }

        if ( emptyArray.length === 0 && cityChanges === true ) {
            return false;
        }
        else if ( cityChanges === true ) {
            return false;
        }
        else {
            return disabled;
        }
    };

    const saveChanges = async() => {
        setLoading(true);
        setDisabled(true);

        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const colRef = "Financial Preferences";

            const monthlyNoDefaultEmptyValIndex = recurringCosts.findIndex(e => e.default === false && e.value === "");
            const initialNoDefaultEmptyValIndex = initialCosts.findIndex(e => e.default === false && e.value === "");
            if ( monthlyNoDefaultEmptyValIndex === -1 && initialNoDefaultEmptyValIndex === -1 && ((rehabSwitch === true) || (rehabSwitch === false && costPerSqFoot !== "")) ) {
                if ( initialCosts[0].value === "" ) {
                    initialCosts[0].value = "20";
                }

                const rehabCosts = {
                    value: rehabSwitch === true ? "" : Number(costPerSqFoot),
                    default: rehabSwitch
                };

                const newSettings = {
                    recurringCosts: recurringCosts,
                    initialCosts: initialCosts,
                    loanType: loanType,
                    customCities: customCities,
                    mortgage: mortgageRadio,
                    rehabCosts: rehabCosts
                };
                setRecurringCostsEmptyError(null);
                setInitialCostsEmptyError(null);
                setRehabCostError(false);

                await setData(colRef, userId, newSettings);
                setLoading(false);
                setDisabled(true);
                setCityChanges(false);
            }
            else {
                if ( monthlyNoDefaultEmptyValIndex !== -1 ) {
                    setRecurringCostsEmptyError(monthlyNoDefaultEmptyValIndex);

                    setTimeout(() => {
                        const elementPosition = recurringCostError.current.getBoundingClientRect();
                        window.scrollTo({ top: elementPosition.top - 300 + window.scrollY, behavior: 'smooth' });
                    }, 500);
                }
                if ( initialNoDefaultEmptyValIndex !== -1 ) {
                    setInitialCostsEmptyError(initialNoDefaultEmptyValIndex);

                    setTimeout(() => {
                        const elementPosition = initialCostError.current.getBoundingClientRect();
                        window.scrollTo({ top: elementPosition.top - 300 + window.scrollY, behavior: 'smooth' });
                    }, 500);
                }

                if ( rehabSwitch === false && costPerSqFoot === "" ) {
                    setRehabCostError(true);

                    setTimeout(() => {
                        const elementPosition = rehabCostErrorRef.current.getBoundingClientRect();
                        window.scrollTo({ top: elementPosition.top - 300 + window.scrollY, behavior: 'smooth' });
                    }, 500);
                }

                setSelectedPanel(0);
                setLoading(false);
                return;
            }
        }
        else {
            setLoading(false);
            setDisabled(false);
        }
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
		menuItemZIPs: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
			padding: '8px 36px'
		}
	};

    return (
        <div className="financial-preferences-body-container margin-top-x-large">
            <div className="financial-preferences-sticky-save-container sticky-save-button-in-view">
                <ActionButton
                    disabled={
                        loading === true ? true 
                        : 
                        disabled === true ? true
                        :
                        checkButtonDisabled() === true ? true
                        :
                        false
                    }
                    onClick={() => saveChanges()}
                >
                    Save
                </ActionButton>
            </div>
            {
                selectedPanel === 0 ?
                <div className="financial-preferences-all-cities-container margin-x-x-large">
                    <div className="profile-account-information-container financial-preferences-subheading-negative-margin">
                        <div className="financial-preferences-loading-container">
                            {
                                loading === true ?
                                <Loading />
                                :
                                null
                            }
                        </div>
                        <div className="financial-settings-inner-width-container">
                            <div className="financial-preferences-subheading-container-row margin-medium">
                                <div className="profile-subheading-inner-title-container">
                                    <h2 className="heading-small-semibold colour-quaternary">
                                        Initial costs
                                    </h2>
                                </div>
                            </div>
                            <span className="body-semibold colour-primary block-text margin-x-small">
                                Payment type
                            </span>
                            <div className={"profile-account-settings-payment-type-container " + (mortgageRadio === true ? "profile-account-settings-payment-type-border" : "profile-account-settings-cash-payment")}>
                                <FormControl className="">
                                    <RadioGroup
                                        aria-labelledby="payment-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={mortgageRadio}
                                        className="new-filters-payment-radio-row"
                                        onChange={(val) => changeMortgageRadio(val.target.value)}
                                    >
                                        <FormControlLabel 
                                            value={true} 
                                            control={<CustomRadio />} 
                                            className="property-payment-radio-label"
                                            label={<span className="body-regular colour-primary">Mortgage</span>}
                                        />
                                        <FormControlLabel 
                                            value={false}
                                            control={<CustomRadio />} 
                                            className="property-payment-radio-label"
                                            label={<span className="body-regular colour-primary">Cash</span>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="financial-settings-input-settings-container">
                                {
                                    mortgageRadio === false ?
                                    null
                                    :
                                    initialCosts.map((item, index) =>
                                        <div 
                                            className={"profile-account-settings-financials-input relative-container " + (item.percentage === true ? "financials-panel-percentage-container" : "")}
                                            key={index}
                                        >
                                            <div className="relative-container">
                                                <div className="profile-account-settings-costs-input-row">
                                                    <span className="body-semibold colour-primary margin-medium block-text">
                                                        {item.label}
                                                    </span>
                                                    {
                                                        index === 1 ?
                                                        <div className="profile-account-switch-container margin-medium">
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={<IOSSwitch disabled={loading} checked={item.default} onChange={(e) => onChangeInitialCostsSwitch(e.target.checked, index)} sx={{ m: 1, marginRight: '0px' }} />}
                                                                />
                                                            </FormGroup>
                                                            <span className="body-regular colour-primary">
                                                                Use default
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="profile-account-settings-costs-input-row">
                                                    <div className="financials-input-long-width">
                                                        <FinancialsInput
                                                            value={item.value}
                                                            type="number"
                                                            // label={item.label}
                                                            id={`${item.label}-input`}
                                                            onWheel={(e) => e.target.blur()}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment className="financials-panel-active-option" position="end">%</InputAdornment>,
                                                            }}
                                                            placeholder={item.placeholder}
                                                            onChange={(text) => onChangeInitialCosts(text.target.value, index)}
                                                            disabled={false}
                                                        />
                                                        {
                                                            initialCostsEmptyError === index ?
                                                            <span 
                                                                className="body-regular colour-error block-text margin-top-small"
                                                                ref={initialCostError}
                                                            >
                                                                This field can't be empty if you're not using the default value
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className="profile-account-settings-default-cost-explanation">
                                                        <span className="label-regular colour-secondary">
                                                            {item.info}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    mortgageRadio === false ?
                                    null
                                    :
                                    <div className="profile-account-settings-financials-input relative-container">
                                        <div className="relative-container">
                                            <span className="body-semibold colour-primary margin-medium block-text">
                                                Loan-type
                                            </span>
                                        </div>
                                        <div className="financials-input-long-width">
                                            <Box>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="loan-type-select-label"
                                                        id="loan-type-select"
                                                        value={loanType}
                                                        label=""
                                                        onChange={(event) => onChangeLoanYears(event.target.value)}
                                                        input={<CustomSelect />}
                                                    >
                                                        {
                                                            loanRanges.map((item, index) =>
                                                                <MenuItem 
                                                                    key={index}
                                                                    value={item.value}
                                                                    style={styles.menuItem}
                                                                >
                                                                    {item.label}
                                                                </MenuItem>	
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="financial-preferences-thick-separator margin-tops">
                    </div>
                    <div className="profile-account-information-container">
                        <div className="financial-settings-inner-width-container">
                            <div className="financial-preferences-subheading-container-row margin-top-x-x-large">
                                <div className="profile-subheading-inner-title-container">
                                    <h2 className="heading-small-semibold colour-quaternary">
                                        Monthly costs
                                    </h2>
                                </div>
                            </div>
                            <div className="financial-settings-input-settings-container">
                                {
                                    recurringCosts.map((item, index) =>
                                        <div 
                                            className={"profile-account-settings-financials-input relative-container " + (item.percentage === true ? "financials-panel-percentage-container" : "")}
                                            key={index}
                                        >
                                            <div className="relative-container">
                                                <div className="profile-account-settings-costs-input-row">
                                                    <div className="financials-input-long-width mobile-margin-x-small">
                                                        <span className="body-semibold colour-primary block-text">
                                                            {item.label}
                                                        </span>
                                                    </div>
                                                    <div className="profile-account-switch-container margin-medium">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={<IOSSwitch disabled={loading} checked={item.default} onChange={(e) => onChangeRecurringCostsSwitch(e.target.checked, index)} sx={{ m: 1, marginRight: '0px' }} />}
                                                            />
                                                        </FormGroup>
                                                        <span className="body-regular colour-primary">
                                                            Use default
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="profile-account-settings-costs-input-row">
                                                    <div className="financials-input-long-width">
                                                        <FinancialsInput
                                                            value={item.value}
                                                            type="number"
                                                            // label={item.label}
                                                            id={`${item.label}-input`}
                                                            onWheel={(e) => e.target.blur()}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment className="financials-panel-active-option" position="end">%</InputAdornment>,
                                                            }}
                                                            placeholder={item.placeholder}
                                                            onChange={(text) => onChangeRecurringCosts(text.target.value, index)}
                                                            disabled={false}
                                                        />
                                                        {
                                                            recurringCostsEmptyError === index ?
                                                            <span 
                                                                className="body-regular colour-error block-text margin-top-small"
                                                                ref={recurringCostError}
                                                            >
                                                                This field can't be empty if you're not using the default value
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                        <span className="body-regular colour-secondary block-text margin-top-small">
                                                            Relative to {item.rentPercentage === true ? <span className="underline">rent</span> : <span className="underline">property price</span>}
                                                        </span>
                                                    </div>
                                                    <div className="profile-account-settings-default-cost-explanation">
                                                        <span className="label-regular colour-secondary">
                                                            {item.info}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="financial-preferences-thick-separator margin-tops">
                    </div>
                    <div className="profile-account-information-container profile-account-rehab-costs-container">
                        <div className="financial-settings-inner-width-container">
                            <div className="financial-preferences-subheading-container-row margin-medium margin-top-x-x-large">
                                <div className="profile-subheading-inner-title-container">
                                    <h2 className="heading-small-semibold colour-quaternary">
                                        Rehab
                                    </h2>
                                </div>
                            </div>
                            <div className="financial-settings-input-settings-container">
                                <div className="profile-account-settings-financials-input relative-container">
                                    <div className="relative-container">
                                        <div className="profile-account-settings-costs-input-row">
                                            <div className="financials-input-long-width mobile-margin-x-small">
                                                <span className="body-semibold colour-primary block-text">
                                                    Rehab per sq. foot
                                                </span>
                                            </div>
                                            <div className="profile-account-switch-container margin-medium">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<IOSSwitch disabled={loading} checked={rehabSwitch} onChange={(e) => onChangeRehabSwitch(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                                                    />
                                                </FormGroup>
                                                <span className="body-regular colour-primary">
                                                    Use default
                                                </span>
                                            </div>
                                        </div>
                                        <div className="profile-account-settings-costs-input-row">
                                            <div className="financials-input-long-width">
                                                <FinancialsInput
                                                    value={costPerSqFoot}
                                                    type="number"
                                                    // label="Rehab"
                                                    id={`rehab-sq-foot-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                    placeholder="0"
                                                    onChange={(text) => onChangeRehabCost(text.target.value)}
                                                    disabled={false}
                                                />
                                                {
                                                    rehabCostError === true ?
                                                    <span 
                                                        className="body-regular colour-error block-text margin-top-small"
                                                        ref={rehabCostErrorRef}
                                                    >
                                                        This field can't be empty if you're not using the default value
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="profile-account-settings-default-cost-explanation">
                                                <span className="label-regular colour-secondary">
                                                    The default is $0 on turnkey and subjective 
                                                    based on city for Fixer Uppers.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="profile-account-information-container financial-settings-city-filtering-container">
                    <div className="financial-preferences-loading-container">
                        {
                            loading === true ?
                            <Loading />
                            :
                            null
                        }
                    </div>
                    <div className="financial-settings-inner-width-container">
                        <div className="financial-settings-city-box-container">
                            <div className="financial-settings-city-inner-box-container">
                                {
                                    customCities.map((item, index) =>
                                        index === selectedPanel - 1 &&
                                        <div 
                                            className="financial-settings-city-element-container"
                                            key={index}
                                        >
                                            {
                                                item.settings.map((setting, settingIndex) =>
                                                    <div
                                                        className="financial-preferences-city-element-setting-container margin-medium relative-container"
                                                        key={settingIndex}
                                                    >
                                                        <div className="financial-preferences-city-element-inner-padding">
                                                            <img
                                                                src={Exit}
                                                                className="profile-account-settings-city-exit"
                                                                alt="Close option"
                                                                onClick={() => removeOption(index, settingIndex)}
                                                            />
                                                            <span className="label-semibold colour-primary margin-x-x-small block-text">
                                                                Choose setting
                                                            </span>
                                                            <div className="financial-preferences-city-element-setting-container-row">
                                                                <div className="profile-account-settings-city-element-setting-left">
                                                                    <div className="financials-input-long-width margin-medium">
                                                                        <Box>
                                                                            <FormControl fullWidth>
                                                                                <Select
                                                                                    labelId="loan-type-select-label"
                                                                                    id="loan-type-select"
                                                                                    value={setting.type}
                                                                                    label=""
                                                                                    onChange={(event) => onChangeSetting(event.target.value, index, settingIndex)}
                                                                                    input={<CustomSelect />}
                                                                                >
                                                                                    {
                                                                                        setting.options.map((option, optionIndex) =>
                                                                                            <MenuItem 
                                                                                                key={optionIndex}
                                                                                                value={option.value}
                                                                                                style={styles.menuItem}
                                                                                                disabled={checkDisabled(index, option.value)}
                                                                                            >
                                                                                                {option.label}
                                                                                            </MenuItem>	
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                    {
                                                                        setting.type !== "" ?
                                                                        <div className="mobile-margin-medium">
                                                                            <span className="label-semibold colour-primary margin-x-x-small block-text">
                                                                                {setting.label === "Rehab" ? "Rehab cost per square foot" : setting.label}
                                                                            </span>
                                                                            <div className="">
                                                                                    {
                                                                                        setting.label === "Rehab" ?
                                                                                        <div className="financials-input-long-width profile-account-rehab-costs-container">
                                                                                            <FinancialsInput
                                                                                                value={setting.value}
                                                                                                type="number"
                                                                                                // label={item.label}
                                                                                                id={`${setting.label}-input`}
                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                InputProps={{
                                                                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                                                }}
                                                                                                placeholder={setting.placeholder}
                                                                                                onChange={(text) => onChangeCityValue(text.target.value, index, settingIndex)}
                                                                                                disabled={false}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="financials-input-long-width">
                                                                                            <FinancialsInput
                                                                                                value={setting.value}
                                                                                                type="number"
                                                                                                // label={item.label}
                                                                                                id={`${setting.label}-input`}
                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                InputProps={{
                                                                                                    endAdornment: <InputAdornment className="financials-panel-active-option" position="end">%</InputAdornment>,
                                                                                                }}
                                                                                                placeholder={setting.placeholder}
                                                                                                onChange={(text) => onChangeCityValue(text.target.value, index, settingIndex)}
                                                                                                disabled={false}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                            </div>
                                                                            {
                                                                                setting.label === "Rehab" ?
                                                                                null
                                                                                :
                                                                                <span className="body-regular colour-secondary block-text margin-top-small">
                                                                                    Relative to {setting.rentPercentage === true ? <span className="underline">rent</span> : <span className="underline">property price</span>}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div className="profile-account-settings-city-element-setting-right">
                                                                    {
                                                                        setting.type !== "" ?
                                                                        <span className="label-regular colour-secondary">
                                                                            {setting.info}
                                                                        </span>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                item.settings.length < 6 ?
                                                <div 
                                                    className="profile-account-settings-city-element-add-setting-container cursor-pointer"
                                                    onClick={() => addSetting(index)}
                                                >
                                                    <img
                                                        src={Plus}
                                                        className="profile-account-settings-plus-icon"
                                                        alt="Add option"
                                                        onClick={() => null}
                                                    />
                                                    <span className="body-regular colour-link">
                                                        Add setting
                                                    </span>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="profile-mobile-sticky-save-button">
                <div className="profile-mobile-sticky-inner-container">
                    <ActionButton
                        disabled={
                            loading === true ? true 
                            : 
                            disabled === true ? true
                            :
                            checkButtonDisabled() === true ? true
                            :
                            false
                        }
                        onClick={() => saveChanges()}
                    >
                        Save
                    </ActionButton>
                </div>
            </div>
        </div>
    )
}

export default FinancialsSettings;