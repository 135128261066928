import React from 'react';
import '../styles/MobileBottomBar.css';
import { ActionButton, formatterCompact, formatterLong, percentage } from '../styles/GlobalStyles';
import { toggleFavourite } from "../functions";

function MobileBottomBar(props) {

    const highLevelProfits = props.highLevelProfits;
    const toggleMobileBottomBar = props.toggleMobileBottomBar;
    const blurred = props.blurred;
    const strategy = props.strategy;
    const mobileBar = props.mobileBar;
    const property = props.property;
    const favourites = props.favourites;
    const setFavourites = props.setFavourites;
    const favouriteDisable = props.favouriteDisable;
    const setFavouriteDisable = props.setFavouriteDisable;
    const navigate = props.navigate;
    const cityId = props.cityId;
    const favouriteIndex = favourites.findIndex(e => e.zpid === property.zpid);
    const newVersion = props.newVersion;
    const cityObject = {
		cityId: cityId,
		city: property.address.city,
		state: property.address.state
	};

    return (
        mobileBar === false || newVersion === false ?
        <div
            className="mobile-bottom-bar-outer-container"
            onClick={() => toggleMobileBottomBar()}
        >
            <div className="mobile-bottom-bar-toggle-icon">
            </div>
            <div className="mobile-bottom-bar-inner-container">
                <div className="mobile-bottom-bar-element-container">
                    <span className="xs-label colour-secondary block-text margin-x-small">
                        Up-front costs
                    </span>
                    <h2 className="body-semibold colour-error">
                        {
                            strategy === 2 ?
                            formatterCompact.format(highLevelProfits.totalCosts).replace(".00", "")
                            :
                            formatterLong.format(highLevelProfits.totalCosts).replace(".00", "")
                        }
                    </h2>
                </div>
                {
                    strategy === 3 ?
                    <div className="mobile-bottom-bar-element-container">
                        <span className="xs-label colour-secondary block-text margin-x-small">
                            Est. Profit
                        </span>
                        <h2 className={"body-semibold " + (highLevelProfits.arvProfit > 0 || blurred === true ? "colour-green" : "colour-error")}>
                            {
                                blurred === true ?
                                "4.85%"
                                :
                                `${formatterLong.format(highLevelProfits.arvProfit).replace(".00", "")}`
                            }
                        </h2>
                    </div>
                    :
                    <div className="mobile-bottom-bar-element-container">
                        <span className="xs-label colour-secondary block-text margin-x-small">
                            Monthly Profit
                        </span>
                        <h2 className={"body-semibold " + ((highLevelProfits.cashOnCash > 0.05 && strategy !== 2) || (highLevelProfits.monthlyProfit > 0 && strategy === 2) || blurred === true ? "colour-green" : (highLevelProfits.cashOnCash < 0 || highLevelProfits.monthlyProfit < 0) ? "colour-error" : "colour-medium")}>
                            {
                                blurred === true ? 
                                "$99"
                                :
                                formatterLong.format(Math.round(highLevelProfits.monthlyProfit)).replace(".00", "")
                            }
                        </h2>
                    </div>
                }
                {
                    strategy === 0 || strategy === 2 ?
                    <div className="mobile-bottom-bar-element-container">
                        <span className="xs-label colour-secondary block-text margin-x-small">
                            Cash on Cash
                        </span>
                        {
                            strategy === 2 && highLevelProfits.cashInDeal <= 0 ?
                            <h2 className="body-semibold colour-green">
                                ∞
                            </h2>
                            :
                            <h2 className={"body-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium")}>
                                {
                                    blurred === true ?
                                    "4.85%"
                                    :
                                    newVersion === false ?
                                    `${percentage.format(highLevelProfits.cashOnCash / 100)}`
                                    :
                                    `${percentage.format(highLevelProfits.cashOnCash)}`
                                }
                            </h2>
                        }
                    </div>
                    :
                    strategy === 1 ?
                    <div className="mobile-bottom-bar-element-container">
                        <span className="xs-label colour-secondary block-text margin-x-small">
                            Expenses covered
                        </span>
                        <h2 className={"body-semibold " + (highLevelProfits.expensesCovered > 1 || blurred === true ? "colour-green" : "colour-error")}>
                            {
                                blurred === true ?
                                "4.85%"
                                :
                                `${percentage.format(highLevelProfits.expensesCovered)}`
                            }
                        </h2>
                    </div>
                    :
                    strategy === 3 ?
                    <div className="mobile-bottom-bar-element-container">
                        <span className="xs-label colour-secondary block-text margin-x-small">
                            ARV spread
                        </span>
                        <h2 className={"body-semibold " + (highLevelProfits.arvSpread > 0 || blurred === true ? "colour-green" : "colour-error")}>
                            {
                                blurred === true ?
                                "4.85%"
                                :
                                `${percentage.format(highLevelProfits.arvSpread)}`
                            }
                        </h2>
                    </div>
                    :
                    null
                }
            </div>
        </div>
        :
        <div className="mobile-bottom-bar-outer-container-new">
            <div className="mobile-bottom-bar-inner-container-column">
                <span className="body-regular colour-secondary block-text text-align-center margin-x-small">
                    To edit values, save to favorites for 
                    later viewing on desktop.
                </span>
                <div className="mobile-bottom-bar-action-button-width">
                    <ActionButton
                        disabled={favouriteDisable}
                        onClick={() => favouriteDisable === true ? null : toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
                    >
                        {
                            favouriteIndex !== -1 ?
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="24" 
                                height="24" 
                                viewBox="0 0 24 24" 
                                fill="none"
                                className="subtext-margin-right block-text mobile-bottom-bar-favourite-icon"
                            >
                                <path 
                                    d="M6.72201 18.5913C6.1125 18.0088 5.27986 17.1797 4.42822 16.2241C3.59948 15.2942 2.62629 14.1043 1.82476 12.8053C1.14866 11.7095 0 9.61551 0 7.08635C0 3.58693 2.84431 0.750091 6.35294 0.750091C8.81148 0.750091 10.9438 2.14297 12 4.18068C13.0562 2.14297 15.1885 0.750091 17.6471 0.750091C21.1557 0.750091 24 3.58693 24 7.08635C24 9.61551 22.8513 11.7095 22.1752 12.8053C21.3737 14.1043 20.4005 15.2942 19.5718 16.2241C18.7201 17.1797 17.8875 18.0088 17.278 18.5913C15.2149 20.5632 15.8326 19.9365 13.3188 22.1051C12.5617 22.7583 11.4383 22.7583 10.6812 22.1051C8.16735 19.9365 8.78524 20.5634 6.72201 18.5913Z" 
                                    fill="#FFFEFC"
                                />
                            </svg>
                            :
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="17" 
                                height="17" 
                                viewBox="0 0 17 17" 
                                fill="none"
                                className="subtext-margin-right block-text mobile-bottom-bar-favourite-icon"
                            >
                                <path 
                                    fillRule="evenodd" 
                                    clipRule="evenodd" 
                                    d="M13.9567 11.0308C14.3893 10.5232 14.8737 9.90138 15.2921 9.21897C15.7018 8.55088 16.5 7.12258 16.5 5.33824C16.5 2.6819 14.3499 0.5 11.6623 0.5C10.4498 0.5 9.3457 0.945689 8.5 1.67708C7.6543 0.945689 6.55023 0.5 5.33766 0.5C2.65012 0.5 0.5 2.6819 0.5 5.33824C0.5 7.12258 1.2982 8.55088 1.70785 9.21897C2.21066 10.039 2.80875 10.7715 3.29762 11.3234C3.80363 11.8947 4.29523 12.387 4.65266 12.7308C5.48298 13.5294 6.36032 14.2672 7.22746 15.0243C7.95406 15.6586 9.04594 15.6586 9.77254 15.0243C10.641 14.2661 11.5158 13.5306 12.3473 12.7308C12.911 12.1886 13.4321 11.6102 13.9567 11.0308ZM14.1981 8.56079C14.5768 7.94335 15.22 6.76341 15.22 5.33824C15.22 3.36635 13.6272 1.76785 11.6623 1.76785C10.7775 1.76785 9.96813 2.09205 9.3457 2.62851C8.99961 2.92678 8.71133 3.29067 8.5 3.70096C8.28867 3.29067 8.00039 2.92678 7.6543 2.62851C7.03188 2.09205 6.22246 1.76785 5.33766 1.76785C3.37281 1.76785 1.78 3.36635 1.78 5.33824C1.78 6.76341 2.42324 7.94335 2.80187 8.56079C3.2507 9.2928 3.7957 9.96332 4.2598 10.4873C4.73672 11.0258 5.20301 11.4929 5.54434 11.8212C6.77823 13.0079 6.29964 12.5243 8.07379 14.0731C8.31672 14.2852 8.68328 14.2852 8.92621 14.0731C9.77931 13.3284 10.6389 12.6067 11.4557 11.8212C12.3054 11.0039 13.3595 9.92855 14.1981 8.56079Z" 
                                    fill="#FFFEFC"
                                />
                            </svg>
                        }
                        Add to favorites
                    </ActionButton>
                </div>
            </div>
        </div>
    )
}

export default MobileBottomBar;