import React, { Fragment, useState } from 'react';
import '../styles/PurchaseTab.css';
import { FinancialsInput, FinancialsInputNoLabel, formatterCompact } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomSliderText, MulticolourSlider, PriceTab, SliderText, ValuesAccordion } from '../components';
import { ChangeDot } from '../assets';

function PurchaseTab(props) {
    const onChangePrice = props.onChangePrice;
    const customPrice = props.customPrice;
    const relativeValue = "overpriced"
    const downPayment = props.downPayment;
    const onChangeDownPayment = props.onChangeDownPayment;
    const interestRate = props.interestRate;
    const onChangeInterest = props.onChangeInterest;
    const closingCost = props.closingCost;
    const onChangeClosing = props.onChangeClosing;
    const closingPercentage = props.closingPercentage;
    const strategyPanel = props.strategyPanel;
    const maxPercentage = props.maxPercentage;
    const changeSlider = props.changeSlider;
    const customDiscount = props.customDiscount;
    const property = props.property;
    const defaultDownPayment = props.defaultDownPayment;
    const defaultInterestRate = props.defaultInterestRate;
    const onChangeARVFinancing = props.onChangeARVFinancing;
    const arvFinancingPercentage = props.arvFinancingPercentage;
    const arvFinancing = props.arvFinancing;
    const totalRehabCost = props.totalRehabCost;
    const breakevenPoint = arvFinancing - (property.price * 0.03) - totalRehabCost;
    const priceTabBg = customDiscount >= 0 ? "bg-colour-red-2" : customDiscount >= -35 ? "bg-colour-orange-3" : customDiscount >= -70 ? "bg-colour-yellow-4" : "bg-colour-green-2";
    const priceTabText = customDiscount >= 0 ? "High" : customDiscount >= -35 ? "Moderate" : customDiscount >= -70 ? "Low" : "Very low";
    const priceTabTextColour = customDiscount >= 0 ? "colour-white" : customDiscount >= -35 ? "colour-white" : customDiscount >= -70 ? "colour-primary" : "colour-white";
    const [valuesAccordion, setValuesAccordion] = useState(false);

    const handleValuesAccordion = () => {
        setValuesAccordion(!valuesAccordion);
    };

    return (
        <div className="purchase-tab-outer-container">
            <div className="purchase-tab-inner-container">
                <div className="purchase-tab-price-container margin-medium">
                    <div className="purchase-tab-price-title-container margin-x-small">
                        <h3 className="body-semibold colour-primary">
                            Offer price
                        </h3>
                        <PriceTab
                            bgColour={priceTabBg}
                            text={priceTabText}
                            textColour={priceTabTextColour}
                        />
                    </div>
                    <div className="button-row margin-x-x-small">
                        <div className="strategies-panel-financials-input relative-container subtext-margin-right">
                            <FinancialsInputNoLabel
                                value={customPrice}
                                type="number"
                                label={
                                    property.price !== customPrice ?
                                    <Fragment>
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    null
                                }
                                id={`custom-price-input`}
                                placeholder={`${customPrice}`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={false}
                                onChange={(text) => onChangePrice(text.target.value)}
                            />
                            {/* <div className="purchase-input-info-icon">
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 16 16" 
                                    fill="none"
                                >
                                    <path 
                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                        fill="#716E69"
                                    />
                                    <path 
                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                        fill="#716E69"
                                    />
                                    <path 
                                        fillRule="evenodd" 
                                        clipRule="evenodd" 
                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                        fill="#716E69"
                                    />
                                </svg>
                            </div> */}
                        </div>
                        {
                            property.price !== customPrice ?
                            <span 
                                className="label-semibold colour-link block-text"
                                onClick={() => onChangePrice(property.price)}
                            >
                                Reset
                            </span>
                            :
                            null
                        }
                    </div>
                    <div className="purchase-tab-price-message-container">
                        <span className="label-regular colour-secondary">
                            The asking price indicates this property is {relativeValue}. Adjust offer price using the slider.
                        </span>
                    </div>
                </div>
                <div className="purchase-tab-values-accordion margin-large">
                    <ValuesAccordion
                        property={property}
                        rentAccordion={valuesAccordion}
                        handleRentAccordion={handleValuesAccordion}
                    />
                </div>
                <div className="purchase-tab-slider-container relative-container margin-medium">
                    <MulticolourSlider
                        maxPercentage={maxPercentage}
                        changeSlider={changeSlider}
                        value={customDiscount}
                        reverse={false}
                    />
                    <SliderText
                        text="Asking price"
                        value={formatterCompact.format(property.price).replace(".00", "")}
                        position="slider-text-container-right"
                    />
                    {
                        strategyPanel === 2 ?
                        <CustomSliderText
                            text="Breakeven point"
                            shText="BE"
                            knownPercentile={property.price}
                            desiredPercentile={breakevenPoint}
                        />
                        :
                        null
                    }
                </div>
                <div className="purchase-tab-initial-costs-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Up-front costs
                    </h3>
                    <div className="purchase-tab-initial-costs-row">
                        <div className="purchase-tab-financials-input relative-container">
                            <FinancialsInput
                                value={strategyPanel === 3 ? 100 : downPayment}
                                type="number"
                                label={
                                    Number(downPayment) !== Number(defaultDownPayment) ?
                                    <Fragment>
                                        Down payment
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    "Down payment"
                                }
                                id={`down-payment-input`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    style: {
                                        maxWidth: '100px'
                                    }
                                }}
                                disabled={strategyPanel === 3 ? true : false}
                                onChange={(text) => onChangeDownPayment(text.target.value)}
                            />
                            <div className="purchase-tab-down-payment-bottom-row">
                                <span className="label-semibold colour-error block-text margin-top-small">
                                    {
                                        strategyPanel === 3 ?
                                        formatterCompact.format(customPrice * -1).replace(/(\.|,)00$/g, '')
                                        :
                                        formatterCompact.format((customPrice * downPayment / 100) * -1).replace(/(\.|,)00$/g, '')
                                    }
                                </span>
                                {
                                    Number(defaultDownPayment) !== Number(downPayment) ?
                                    <span 
                                        className="label-semibold colour-link block-text margin-top-small"
                                        onClick={() => onChangeDownPayment(defaultDownPayment)}
                                    >
                                        Reset
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        {
                            strategyPanel < 2 ?
                            <div className="purchase-tab-financials-input relative-container">
                                <FinancialsInput
                                    value={interestRate}
                                    type="number"
                                    label={
                                        Number(interestRate) !== Number(defaultInterestRate) ?
                                        <Fragment>
                                            Interest rate
                                            <img
                                                src={ChangeDot}
                                                className="financials-panel-change-dot"
                                                alt="Change"
                                            />
                                        </Fragment>
                                        :
                                        "Interest rate"
                                    }
                                    id={`interest-rate-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        style: {
                                            maxWidth: '100px'
                                        }
                                    }}
                                    onChange={(text) => onChangeInterest(text.target.value)}
                                />
                                {
                                    Number(defaultInterestRate) !== Number(interestRate) ?
                                    <span 
                                        className="label-semibold colour-link block-text margin-top-small"
                                        onClick={() => onChangeInterest(defaultInterestRate)}
                                    >
                                        Reset
                                    </span>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                        <div className="purchase-tab-financials-input relative-container">
                            <FinancialsInput
                                value={closingPercentage}
                                type="number"
                                label="Closing costs"
                                id={`closing-costs-input`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    style: {
                                        maxWidth: '100px'
                                    }
                                }}
                                onChange={(text) => onChangeClosing(text.target.value)}
                            />
                            <span className="label-semibold colour-error block-text margin-top-small">
                                {formatterCompact.format(closingCost * -1).replace(/(\.|,)00$/g, '')}
                            </span>
                        </div>
                    </div>
                </div>
                {
                    strategyPanel === 2 ?
                    <div className="purchase-tab-refinance-costs-container margin-top-x-x-large">
                        <h3 className="body-semibold colour-primary margin-x-small">
                            Refinance
                        </h3>
                        <div className="purchase-tab-refinance-row">
                            <div className="purchase-tab-financials-input relative-container">
                                <FinancialsInput
                                    value={arvFinancingPercentage}
                                    type="number"
                                    label="ARV financing"
                                    id={`arv-financing-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        style: {
                                            maxWidth: '100px'
                                        }
                                    }}
                                    onChange={(text) => onChangeARVFinancing(text.target.value)}
                                />
                                <div className="margin-top-small">
                                    <span className="label-semibold colour-green">
                                        +{formatterCompact.format(arvFinancing).replace(".00", "")}
                                    </span>
                                </div>
                            </div>
                            <div className="purchase-tab-financials-input relative-container">
                                <FinancialsInput
                                    value={interestRate}
                                    type="number"
                                    label={
                                        Number(interestRate) !== Number(defaultInterestRate) ?
                                        <Fragment>
                                            Interest rate
                                            <img
                                                src={ChangeDot}
                                                className="financials-panel-change-dot"
                                                alt="Change"
                                            />
                                        </Fragment>
                                        :
                                        "Interest rate"
                                    }
                                    id={`interest-rate-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        style: {
                                            maxWidth: '100px'
                                        }
                                    }}
                                    onChange={(text) => onChangeInterest(text.target.value)}
                                />
                                {
                                    Number(interestRate) !== Number(defaultInterestRate) ?
                                    <span 
                                        className="label-semibold colour-link block-text margin-top-small"
                                        onClick={() => onChangeInterest(defaultInterestRate)}
                                    >
                                        Reset
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default PurchaseTab;