import React from 'react';
import '../styles/SliderText.css';

function SliderText(props) {
    const text = props.text;
    const value = props.value;
    const position = props.position;
    return (
        <div className={"slider-text-container " + (position)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                <path d="M4 0L7.4641 4.5H0.535898L4 0Z" fill="#716E69"/>
            </svg>
            <span className="label-regular colour-secondary block-text margin-tops-small">
                {text}
            </span>
            <span className="label-semibold colour-primary">
                {value}
            </span>
        </div>
    )
};

export default SliderText;