import React from 'react';
import '../styles/MiscellaneousFilters.css';
import { priceReductionList, CustomSelect, colour, CustomRadio } from '../styles/GlobalStyles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useLocation } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import { EditNeighbourhoodGrade } from './';

function MiscellaneousFilters(props) {
    const priceReduction = props.priceReduction;
    const setPriceReduction = props.setPriceReduction;
    const priceReductionIndex = props.priceReductionIndex;
    const setPriceReductionIndex = props.setPriceReductionIndex;
    const fsbo = props.fsbo;
    const changeFSBOFilter = props.changeFSBOFilter;
    const filtersModal = props.filtersModal;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const changePriceReduction = (val) => {
        setPriceReductionIndex(val);
    };

    const listingTypeRadios = [
        {
            label: "Listed by agent",
            value: 0
        },
        {
            label: "For sale by owner",
            value: 1
        }
    ];

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
        marginTop: {
            marginTop: '8px'
        }
	};

    const goToPreferences = () => {
        navigate("/preferences", {
            state: state
        });
    };

    const changeNeighbourhoodGrade = (val, type) => {
        if ( type === 0 ) {
            setNeighbourhoodGradeMin(val);    
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

    return (
        <div className="miscellaneous-filters-outer-container">
            <div className="miscellaneous-filters-inner-container">
                {
                    filtersModal === true ?
                    <div className="miscellaneous-filters-neighbourhood-grade-container">
                        <EditNeighbourhoodGrade
                            neighbourhoodGradeMin={neighbourhoodGradeMin}
                            neighbourhoodGradeMax={neighbourhoodGradeMax}
                            changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                            filtersModal={true}
                        />
                    </div>
                    :
                    null
                }
                <div className="miscellaneous-filters-price-reduction-container margin-x-large">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Price reduction
                    </h3>
                    <div className="button-row">
                        <div 
                            className="price-reduction-checkbox-container"
                            onClick={() => setPriceReduction(!priceReduction)}
                        >
                            {
                                priceReduction === true ?
                                <div className="price-reduction-checkbox">
                                </div>
                                :
                                null
                            }
                        </div>
                        <span className="body-semibold colour-primary">
                            Price reduction
                        </span>
                    </div>
                    {
                        priceReduction === true ?
                        <Box style={styles.marginTop}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="price-reduction-label"
                                    id="price-reduction-select"
                                    value={priceReductionIndex}
                                    label=""
                                    onChange={(event) => changePriceReduction(event.target.value)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        priceReductionList.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={index}
                                                style={styles.menuItem}
                                                disabled={false}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        :
                        null
                    }
                </div>
                <div className="margin-x-large">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Financing options
                    </h3>
                    <span className="body-regular colour-primary">
                        Configure financial settings (down payment, interest rate, and loan-type) 
                        from your <span className="body-regular-underline colour-link" onClick={() => goToPreferences()}>financial preferences.</span>
                    </span>
                </div>
                {
                    filtersModal === true ?
                    <div className="choose-value-add-opportunity-column">
                        <div className="choose-value-add-opportunity-title-container margin-x-small">
                            <span className="body-semibold colour-primary">
                                Listing type
                            </span>
                        </div>
                        <div className="">
                            <FormControl className="new-filters-modal-radio-outer-container">
                                <RadioGroup
                                    aria-labelledby="fixer-upper-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={fsbo}
                                    className="choose-value-add-opportunity-property-condition-radios"
                                    onChange={(val) => changeFSBOFilter(val.target.value)}
                                >
                                    {
                                        listingTypeRadios.map((item, index) =>
                                            <FormControlLabel 
                                                value={item.value} 
                                                control={<CustomRadio />}
                                                className=""
                                                label={<span className="body-regular colour-primary">{item.label}</span>}
                                                key={index}
                                            />
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default MiscellaneousFilters;