import React, { useState } from 'react';
import '../styles/Location.css';
import { LocationGentrificationMap, Chip, LocationZIPMap } from './';
import { recordEvent } from '../functions';

function OldLocation(props) {

	const property = props.property;
	const cityState = props.cityState;
	const gentrificationData = props.gentrificationData;
	const gentrificationBounds = props.gentrificationBounds;
	const zipData = props.zipData;
	const bounds = props.bounds;
	const zipNames = props.zipNames;
	const [gentrificationMap, setGentrificationMap] = useState(true);

	const lowerCaseCity = cityState.toLowerCase();
	const formattedCity = lowerCaseCity.replace(" ", "-");
	const checkFullStop = formattedCity.replace(".", "");
	const crimeGradeURL = `https://crimegrade.org/safest-places-in-${checkFullStop}/`;
	const mapTypes = ["Gentrification Heatmap", "ZIP Codes"];

	const openCrimeMap = () => {
		window.open(crimeGradeURL, "_blank");
		recordEvent("Open Crime Map", {
			city: cityState
		});
	};

	const changeMapType = (val) => {
		setGentrificationMap(val === 1 ? false : true);
	};

	return (
		<div className="location-outer-container">
			<div className="location-inner-container">
				<h2 className="heading-small-semibold colour-primary margin-medium">
                   Location
                </h2>
				<div className="location-chips-row margin-medium">
					{
						mapTypes.map((item, index) =>
							<div 
								className="map-types-element-container"
								key={`coming-soon-${index}`}
							>
								<Chip
									label={item}
									func={changeMapType}
									index={index}
									selected={gentrificationMap === true ? 0 : 1}
									recommended={false}
									hover={null}
                                    leave={null}
									disabled={false}
								/>
							</div>
						)
					}
				</div>
				{
					gentrificationMap === false ?
					<LocationZIPMap
						property={property}
						controls={false}
						text={true}
						zipData={zipData}
						bounds={bounds}
						zipNames={zipNames}
					/>
					:
					<LocationGentrificationMap
						property={property}
						controls={false}
						text={true}
						gentrificationData={gentrificationData}
						gentrificationBounds={gentrificationBounds}
					/>
				}
				<div className="location-crime-map-container">
					<span 
						className="body-regular-underline colour-link"
						onClick={() => openCrimeMap()}
					>
						View crime map
					</span>
				</div>
			</div>
		</div>
	)
}

export default OldLocation;