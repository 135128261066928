import React from 'react';
import '../styles/PropertyAbstract.css';
import { PropertyBadgesRow, PriceChange, UnitLayout, PendingTag, SaleStatusBadge } from './';
import { SingleFamily, SQFootage, Bedrooms, Bathrooms, Units, MultiFamilyBlack } from '../assets';

function PropertyAbstract(props) {

	const property = props.property;
	const formatter = props.formatter;
	const propertySearch = props.propertySearch;
	const partner = props.partner;
	const strategy = props.strategy;

	const icons = [
		{
			icon: property.propertyTypeDimension === "Single Family" ? SingleFamily : property.propertyTypeDimension === "Multi Family" ? MultiFamilyBlack : "",
			text: property.propertyTypeDimension === "Single Family" ? "Single Family" : property.propertyTypeDimension === "Multi Family" ? "Multi-family" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Manufactured" ? "Manufactured Home" : "",
		},
		{
			icon: property.propertyTypeDimension !== "Multi Family" ? null : Units,
			text: property.propertyTypeDimension !== "Multi Family" ? null : `${property.units === null || property.unitsGuess === true ? "N/A" : property.units} Unit`,
		},
		{
			icon: Bedrooms,
			text: `${property.bedrooms} bed`,
		},
		{
			icon: Bathrooms,
			text: `${property.bathrooms} bath`,
		},
		{
			icon: SQFootage,
			text: `${property.livingArea !== null ? property.livingArea.toLocaleString() : "0"} sqft`,
		}
	];

	return (
		<div className={"property-abstract-outer-container " + (propertySearch === true && property.offMarket === true ? "property-abstract-search-container" : "" )}>
			<PropertyBadgesRow
				property={property}
				strategy={strategy}
				partner={partner}
			/>
			<div className="property-abstract-inner-container">
				<div className="property-abstract-price-container margin-medium">
					{
						property.onMarket === true ?
						<h1 className="heading-large-semibold colour-primary">
							{formatter.format(property.price).replace(".00", "")}
						</h1>
						:
						null
					}
					{
						property.priceChange === true && property.onMarket === true ?
							<PriceChange
								priceChangeHistory={property.priceChangeHistory}
								priceChangeTotalAmount={property.priceChangeTotalAmount}
								propertyPage={true}
								priceDrop={property.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeLatestDate={property.priceChangeLatestDate}
							/>
						:
						null
					}
					{
						property.homeStatus === "PENDING" ?
						<PendingTag />
						:
						null
					}
				</div>
				{
					property.auction === true || property.backOnMarket === true ?
					<div className="property-abstract-sale-status-badges-row margin-top-x-large">
						{
							property.auction === true ?
							<SaleStatusBadge
								text="AUCTION"
								marginRight={property.backOnMarket === true ? true : false}
							/>
							:
							null
						}
						{
							property.backOnMarket === true ?
							<SaleStatusBadge
								text="BACK ON MARKET"
								marginRight={false}
							/>
							:
							null
						}
					</div>
					:
					null
				}
				<div className="property-abstract-address-container">
					{
						property.address === undefined ?
						<h2 className="heading-small-semibold colour-primary">
							{property.fullAddress}
						</h2>
						:
						<h2 className="heading-small-semibold colour-primary">
							{property.address.streetAddress}, {property.address.city}, {property.address.state} {property.address.zipcode}
						</h2>
					}
				</div>
				<div className={"property-abstract-icons-row margin-x-small " + (property.propertyTypeDimension !== "Multi Family" ? "margin-large" : "")}>
					{
						icons.map((item, index) =>
							item.icon !== null ?
							<div 
								className="property-abstract-icons-element margin-medium"
								key={index}
							>
								{
									item.icon === "" ?
									null
									:
									<img
										src={item.icon}
										className="property-abstract-icon"
										alt={item.text}
									/>
								}
								<span className="body-regular colour-primary">
									{item.text}									
								</span>
							</div>
							:
							null
						)
					}
				</div>
				{
					property.propertyTypeDimension === "Multi Family" ?
					<div className="property-abstract-units-layout-row">
						{
							property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ?
							null
							:
							property.uniqueUnitPropertyDetails.map((item, index) =>
								<UnitLayout
									item={item}
									property={property}
									index={index}
									key={index}
								/>
							)
						}
					</div>
					:
					null
				}
				{
					property.propertyTypeDimension === "Multi Family" && property.unitsGuess === true ?
					<div className="">
						<span className="body-regular colour-error">
							We were unable to detect how many units this MFH has since the property description
							was unclear 😔
						</span>
					</div>
					:
					null
				}
				{
					property.onMarket !== false && partner === false ?
					<div className="property-abstract-days-on-market-container">
						<h3 className="body-semibold colour-primary">
							{property.dateDifference} days on market
						</h3>
					</div>
					:
					null
				}
			</div>
		</div>
	)
}

export default PropertyAbstract;