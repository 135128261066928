import React from 'react';
import '../styles/PlanCard.css';
import { formatterLong } from "../styles/GlobalStyles";
import { PopularBadge, PricingCardBadge } from './';

function PlanCard(props) {
	const subscription = props.subscription;
	const selected = props.selected;
	const item = props.item;
	const last = props.last;
	const index = props.index;
	const choosePlan = props.choosePlan;

	return (
		<div 
			className={"plan-card-outer-container bg-colour-light relative-container " + (last === false ? "margin-large " : "") + (selected === true ? "plan-card-outer-container-selected" : "")}
			onClick={() => choosePlan(index)}
		>
			{
				index === 2 ?
				<PopularBadge />
				:
				null
			}
			<div className="plan-card-inner-container">
				<div className="plan-card-left-container">
					<div className="plan-card-radio-container subtext-margin-right-x-x-large">
						{
							selected === false ?
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<g filter="url(#filter0_d_1268_8749)">
									<circle cx="16" cy="16" r="8" fill="#FFFEFC"/>
									<circle cx="16" cy="16" r="7.5" stroke="#BCCAD4"/>
								</g>
								<defs>
									<filter id="filter0_d_1268_8749" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
									<feFlood floodOpacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
									<feOffset/>
									<feGaussianBlur stdDeviation="4"/>
									<feComposite in2="hardAlpha" operator="out"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.576471 0 0 0 0.4 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1268_8749"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1268_8749" result="shape"/>
									</filter>
								</defs>
							</svg>
							:
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<g filter="url(#filter0_d_1268_9143)">
									<circle cx="16" cy="16" r="8" fill="#FFFEFC"/>
									<circle cx="16" cy="16" r="7.5" stroke="#BCCAD4"/>
								</g>
								<circle cx="16" cy="16" r="5" fill="#292621"/>
								<defs>
									<filter id="filter0_d_1268_9143" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
									<feFlood floodOpacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
									<feOffset/>
									<feGaussianBlur stdDeviation="4"/>
									<feComposite in2="hardAlpha" operator="out"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.592157 0 0 0 0 0.576471 0 0 0 0.4 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1268_9143"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1268_9143" result="shape"/>
									</filter>
								</defs>
							</svg>
						}
					</div>
					<div className="plan-card-text-container">
						<div className="button-row margin-x-small">
							<h2 className="body-semibold colour-primary subtext-margin-right-large">
								{item.label}
							</h2>
							<PricingCardBadge
								discount={item.discount === 0 ? "Full price" : `${item.discount}% off`}
								green={index > 1 ? true : false}
								subscription={subscription}
							/>
						</div>
						<span className="body-regular colour-secondary block-text margin-x-small">
							{item.description}
						</span>
						<span className="body-regular colour-primary">
							{formatterLong.format(item.total).replace(".00", "")}
							{
								subscription === true ?
								" subscription, billed monthly"
								:
								", billed once"
							}
						</span>
						{
							item.guarantee !== "" ?
							<span className="label-semibold-caps text-link block-text margin-top-small">
								{item.guarantee}
							</span>
							:
							null
						}
					</div>
				</div>
				<div className="plan-card-right-container">
					{
						index === 0 ?
						null
						:
						<span className="body-regular colour-secondary strike-through block-text margin-x-small">
							{formatterLong.format(item.originalPrice).replace(".00", "")}
						</span>
					}
					<span className="heading-large-semibold colour-primary block-text">
						{formatterLong.format(item.price).replace(".00", "")}
					</span>
					<span className="body-regular colour-secondary">
						/month
					</span>
				</div>
			</div>
		</div>
	)
}

export default PlanCard;