import React, { useState, useEffect } from 'react';
import '../styles/MyCities.css';
import { Header, Footer, ExitScreen, SideNavigation, Loading, DeleteModal, PremiumModal, Modal, UnassignedCounties, MyMarkets, BuyBoxesGrid } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRight, Cog } from '../assets';
import { changeBuyBoxFrequency, checkOnAuthStateChanged, checkWhichAddCityPath, deleteBuyBoxFrequency, getUserDocuments, recordEvent, checkBuyBoxUpdates, changeBuyBoxStrategy, unassignedCountyWrite, addRecentMarket, removeRecentMarket } from '../functions';
import { PrimaryButton } from '../buttons';

function MyCities() {
    const location = useLocation();
    let state = location.state;
    const navigate = useNavigate();

    const [cities, setCities] = useState([]);
    const [buyBoxMore, setBuyBoxMore] = useState(null);
    const [buyBoxFrequency, setBuyBoxFrequency] = useState(null);
    const [openStrategy, setOpenStrategy] = useState(null);
    const [userData, setUserData] = useState(null);
    const [buyBoxes, setBuyBoxes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteParam, setDeleteParam] = useState(null);
    const [freeTrial, setFreeTrial] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [firstRender, setFirstRender] = useState(false);
    const [premiumModalOpen, setPremiumModalOpen] = useState(false);
    const [loadingBuyBoxes, setLoadingBuyBoxes] = useState([]);
    const [unassignedCountyModal, setUnassignedCountyModal] = useState(false);
    const [anyUnassignedCounty, setAnyUnassignedCounty] = useState(false);
    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const [userId, setUserId] = useState(null);
    const [marketSettings, setMarketSettings] = useState(null);

    useEffect(() => {
        document.title = "My Markets";
        const fetchUserData = async() => {
            setFirstRender(true);
            const collections = [
                "Users",
                "Subscriptions",
                "Buy Boxes",
                "Recently Viewed"
            ];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const queryUserData = await getUserDocuments(collections, user.userId);
                let checkFreeTrial = false;
				if ( queryUserData[0].status === 200 ) {
                    const data = queryUserData[0].data;
                    if ( data.freeTrial[0].endDate.seconds > new Date().getTime() / 1000 ) {
                        checkFreeTrial = true;
                    }
                    setUserData(data);
                }

                if ( queryUserData[1].status === 200 ) {
                    setFreeTrial(checkFreeTrial);
                    setSubscriptions(queryUserData[1].data);

                    for (let index = 0; index < queryUserData[1].data.length; index++) {
                        const element = queryUserData[1].data[index];
                        if ( element.metroArea === true && element.msaCode === "" ) {
                            if ( queryUserData[0].status === 200 ) {
                                const data = queryUserData[0].data;
                                if ( data.unassignedCounties !== undefined && data.unassignedCounties[0] === true ) {
                                    setUnassignedCountyModal(true);
                                }
                            }
                            setAnyUnassignedCounty(true);
                        }
                    }
                }

                if ( queryUserData[2].status === 200 ) {
                    const queriedBuyBoxes = queryUserData[2].data;
                    const loadedBuyBoxes = [];
                    for (let index = 0; index < queriedBuyBoxes.length; index++) {
                        const element = queriedBuyBoxes[index];
                        loadedBuyBoxes.push(element.loaded);
                    }
                    
                    const buyBoxCities = queryUserData[2].data
                    const citiesClone = [
                        {
                            label: "All cities",
                            value: "all-cities"
                        }
                    ];

                    for (let index = 0; index < buyBoxCities.length; index++) {
                        const element = buyBoxCities[index];
                        const cityValue = element.metroArea === true ? element.msaTitle.toLowerCase().replace(/\s/g, '-').replace(',', '') : `${element.city}, ${element.state}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                        const newObject = {
                            label: element.metroArea === true ? element.msaTitle : `${element.city}, ${element.state}`,
                            value: cityValue
                        }
                        citiesClone.push(newObject);
                    };
                    setCities(citiesClone);
                    setBuyBoxes(queriedBuyBoxes);
                    setLoading(false);

                    // Check how many new properties have been uploaded to the buy box
                    const checkSubscriptions = queryUserData[1].status === 200 ? queryUserData[1].data : [];
                    const buyBoxUpdates = await checkBuyBoxUpdates(queryUserData[2].data, user.userId, checkSubscriptions, checkFreeTrial, setLoadingBuyBoxes);
                    if ( buyBoxUpdates.changes === true ) {
                        setBuyBoxes(buyBoxUpdates.data);
                    }
                }
                else {
                    const citiesClone = [
                        {
                            label: "All cities",
                            value: "all-cities"
                        }
                    ];
                    setCities(citiesClone);
                    setLoading(false);
                }

                if ( queryUserData[3].status === 200 ) {
                    setRecentlyViewed(queryUserData[3].data);
                }
                else if ( queryUserData[1].status === 200 ) {
                    const subscriptionData = queryUserData[1].data;
                    const newRecentlyViewed = [];
                    for (let index = 0; index < subscriptionData.length; index++) {
                        const element = subscriptionData[index];
                        if ( element.metroArea === false || element.msaCode === "" ) {
                            continue;
                        }
                        const newElement = {
                            msaCode: element.msaCode,
                            msaTitle: element.msaTitle,
                            icon: ""
                        };
                        newRecentlyViewed.push(newElement);
                    }
                    setRecentlyViewed(newRecentlyViewed);
                }
            }
            else {
                setLoading(false);
                const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
                const chosenPath = checkPath.path;
                const newState = checkPath.state;
        
                navigate(chosenPath, {
                    state: newState === null ? state : newState
                });
            }
        };

        if ( firstRender === false ) {
            fetchUserData();
        }
    }, [cities, navigate, state, firstRender, freeTrial, subscriptions]);

    const triggerExit = () => {
        setBuyBoxFrequency(null);
        setBuyBoxMore(null);
        setOpenStrategy(null);
        setMarketSettings(null);
    };

    const onChangeBuyBoxFrequency = async(frequency, id, item) => {
        setOpenStrategy(null);
        setLoading(true);
        const buyBoxChange = await changeBuyBoxFrequency(item, frequency, id);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else if ( buyBoxChange.status === 400 ) {
            const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
            const chosenPath = checkPath.path;
            const newState = checkPath.state;
    
            navigate(chosenPath, {
                state: newState === null ? state : newState
            });
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const onChangeBuyBoxStrategy = async(val, buyBoxItem) => {
        setLoading(true);
        const buyBoxChange = await changeBuyBoxStrategy(buyBoxItem, val);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else if ( buyBoxChange.status === 400 ) {
            navigate("/pick-city");
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const manageSubscriptions = () => {
        navigate("/profile?tab=1", {
            state: state
        });
    };

    const editBuyBox = (item) => {
        if ( state === null ) {
            state = {
                userData: userData,
                buyBox: item,
            }
        }
        else {
            state.userData = userData;
            state.buyBox = item;
        }

        if ( item.metroArea === true ) {
            const msaCode = item.msaCode;
            const cityIndex = buyBoxes.findIndex(e => e.msaCode === msaCode);
            if ( cityIndex !== -1 ) {
                state.buyBoxCity = buyBoxes[cityIndex];
            }
        }
        else {
            delete state.buyBoxCity;
        }

        recordEvent("Edit Buy Box", {
            item: item
        });
        navigate("/edit-buy-box", {
            state: state
        });
    };

    const deleteBuyBox = async(item) => {
        setDeleteModal(false);
        setLoading(true);
        const deletion = await deleteBuyBoxFrequency(item);
        if ( deletion.status === 200 ) {
            setBuyBoxes(deletion.data);
        }
        setLoading(false);
        setBuyBoxMore(null);

        const eventData = {};
        if ( item.metroArea === true ) {
            eventData.msaTitle = item.msaTitle;
            eventData.msaCode = item.msaCode;
        }
        else {
            eventData.city = item.city;
            eventData.state = item.state;
        }
        recordEvent("Delete Buy Box", eventData);
        setDeleteParam(null);
    };

    const closeUnassignedCountyModal = async() => {
		setUnassignedCountyModal(false);

        const currentUnassignedCounties = userData.unassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			await unassignedCountyWrite(userId, currentUnassignedCounties);
		}
	};

	const goToUnassignedCounty = async() => {
		let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        const currentUnassignedCounties = userData.unassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			await unassignedCountyWrite(userId, currentUnassignedCounties);
		}

		navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
	};

    const goToChooseCounties = () => {
        let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
    };

    const viewMarket = async(item) => {
        const prefix = item.msaCode.includes("CTY") ? "county-properties-list" : "properties-list";
        window.open(`/${prefix}/${item.msaCode}`, "_blank");

        if ( userId !== null ) {
            await addRecentMarket(item, userId, setRecentlyViewed);
        }
    };

    const removeViewedMarket = async(item) => {
        if ( userId !== null ) {
            await removeRecentMarket(item, userId, setRecentlyViewed);
        }
    };

    const viewSamples = () => {
        navigate("/pick-city");
    };

    return (
        <div className="my-cities-outer-container relative-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            {
                premiumModalOpen === true || deleteModal === true || unassignedCountyModal === true ? 
                <div className="my-cities-modal-background">
                </div>
                :
                null
            }
            {
                (deleteModal === false) && (buyBoxFrequency !== null || buyBoxMore !== null || openStrategy !== null || marketSettings !== null) ?
                <ExitScreen
                    clickScreen={() => triggerExit()}
                />
                :
                null
            }
            {
                deleteModal === true ?
                <DeleteModal
                    deleteFunction={deleteBuyBox}
                    setDeleteModal={setDeleteModal}
                    description="Are you sure you want to delete this buy box?"
                    deleteParam={deleteParam}
                />
                :
                null
            }
            {
                premiumModalOpen === true ?
                <PremiumModal
                    setPremiumModalOpen={setPremiumModalOpen}
                    state={null}
                />
                :
                null
            }
            <div className="my-cities-inner-container relative-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="my-cities-body-container">
                    <div className="my-cities-inner-body-container">
                    {
                            unassignedCountyModal === true ?
                            <Modal
                                title="You have counties that are unassigned"
                                description="You have counties that you bought which need to be assigned to your account!"
                                closeButtonText="Close"
                                saveButtonText="Choose counties"
                                closeModal={closeUnassignedCountyModal}
                                saveModal={goToUnassignedCounty}
                            />
                            :
                            null
                        }
                        {
                            loading === true ?
                            <div className="my-cities-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="my-cities-sub-body-container margin-x-large">
                            <MyMarkets
                                anyUnassignedCounty={anyUnassignedCounty}
                                goToChooseCounties={goToChooseCounties}
                                recentlyViewed={recentlyViewed}
                                viewMarket={viewMarket}
                                marketSettings={marketSettings}
                                setMarketSettings={setMarketSettings}
                                removeViewedMarket={removeViewedMarket}
                                loadingData={loading}
                                viewSamples={viewSamples}
                            />
                        </div>
                        <BuyBoxesGrid
                            buyBoxes={buyBoxes}
                            buyBoxMore={buyBoxMore}
                            setBuyBoxMore={setBuyBoxMore}
                            buyBoxFrequency={buyBoxFrequency}
                            setBuyBoxFrequency={setBuyBoxFrequency}
                            onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                            openStrategy={openStrategy}
                            setOpenStrategy={setOpenStrategy}
                            deleteBuyBox={deleteBuyBox}
                            editBuyBox={editBuyBox}
                            setDeleteParam={setDeleteParam}
                            subscriptions={subscriptions}
                            userId={userId}
                            onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                            loadingBuyBoxes={loadingBuyBoxes}
                            setRecentlyViewed={setRecentlyViewed}
                        />
                    </div>
                    <div className="my-cities-mobile-body-container relative-container">
                        {
                            loading === true ?
                            <div className="my-cities-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="margin-large">
                            <h1 className="heading-large-semibold colour-primary margin-x-small">
                                My markets
                            </h1>
                            <h2 className="body-regular colour-secondary">
                                The home for your cities and buy boxes.
                            </h2>
                            {
                                anyUnassignedCounty === true ?
                                <UnassignedCounties
                                    cta={goToChooseCounties}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="my-cities-light-divider-line">
                        </div>
                        <div className="my-cities-mobile-options-container">
                            <div className="my-cities-mobile-option-element">
                                <div 
                                    className="button-row my-cities-mobile-option-padding"
                                    onClick={() => manageSubscriptions()}
                                >
                                    <img
                                        src={Cog}
                                        className="my-cities-mobile-option-cog"
                                        alt="Manage subscriptions"
                                    />
                                    <span className="body-regular colour-link block-text subtext-margin-right">
                                        Manage subscriptions
                                    </span>
                                    <img
                                        src={ChevronRight}
                                        className="my-cities-mobile-option-arrow"
                                        alt="Manage subscriptions"
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            recentlyViewed.length === 0 && loading === false ?
                            <div className="my-markets-empty-container">
                                <div className="my-markets-view-samples-container">
                                    <PrimaryButton
                                        text="View sample markets"
                                        cta={viewSamples}
                                        disabled={false}
                                        size="medium"
                                        leftIcon={null}
                                        leftIconClass=""
                                        rightIcon={null}
                                        alt=""
                                    />
                                </div>
                            </div>
                            :
                            recentlyViewed.map((item, index) =>
                                <div 
                                    className="my-cities-mobile-option-element"
                                    key={index}
                                    onClick={() => viewMarket(item)}
                                >
                                    <div className="button-row my-cities-mobile-option-padding">
                                        <span className="body-regular colour-link block-text subtext-margin-right">
                                            {item.msaTitle}
                                        </span>
                                        <img
                                            src={ChevronRight}
                                            className="my-cities-mobile-option-arrow"
                                            alt={item.msaTitle}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default MyCities;