import React, { useEffect, useState } from 'react';
import '../styles/CustomPlan.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, getDocument, makeId, recordEvent, sendEmail, setData } from '../functions';
import { useNavigate } from 'react-router-dom';
import { EspressoMartini } from '../assets/images';
import { ActionButton, FormInput, MultilineNoShadow } from '../styles/GlobalStyles';

function CustomPlan() {
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState("");
    const [customPlanText, setCustomPlanText] = useState("");
    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(null);
    const contactEmail = "liam.maher@coffeeclozers.com";
	const dynamicTemplate = "d-fd830c5d4d8d4f78b73364e253ef6c25";
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Coffee Clozers | Custom Plan";

        const fetchUserData = async () => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const colRef = "Users";
                const docRef = user.userId;
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    setEmail(queryData.data.data.email);
                    setUserData(queryData.data.data);
                    setLoading(false);
                }
                else {
                    navigate("/");
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        fetchUserData();
    }, [navigate]);

    const complete = async() => {
        setLoading(true);
        const saveObject = {
            userId: userId,
            email: email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            customPlanText: customPlanText,
            date: new Date()
        };

        const colRef = "Custom Plans";
        const docRef = await makeId(15);
        const saveData = await setData(colRef, docRef, saveObject);
        if ( saveData.status === 200 ) {
            recordEvent("Custom Plan Submitted", {
                userId: userId,
                customPlanText: customPlanText
            });

            const msg = {
				to: [contactEmail, "ariel.herrera@coffeeclozers.com", "maddy@coffeeclozers.com"],
				from: contactEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					userId: userId,
					email: email,
                    customPlanText: customPlanText,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    creative: false
				}
			};
			await sendEmail(msg);
            setError(null);
            setLoading(false);
            setDone(true);
        }
        else {
            console.log("saveData = ", saveData);
            setError("There was an error submitting your request. Please try again.");
        }


        setLoading(false);
    };

    return (
        <div className="custom-plan-outer-container">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
            />
            <div className="custom-plan-inner-container">
                <img
                    src={EspressoMartini}
                    className="custom-plan-image block-text margin-medium"
                    alt="Espresso Martini"
                />
                <div className="custom-plan-title-container">
                    <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                        {
                            done === true ?
                            "🎉 Request sent"
                            :
                            "Custom plan"
                        }
                    </h1>
                    {
                        done === true ?
                        null
                        :
                        <span className="body-regular colour-secondary block-text margin-x-large text-align-center">
                            Tell us about your needs and we will get back to you with a quote on a custom plan.
                        </span>
                    }
                </div>
                {
                    done === true ?
                    <div className="custom-plan-body-container">
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Thanks for your custom plan inquiry! We’ll get back to you via email as soon as possible. Keep an eye out on your inbox 🥳
                        </span>
                        <div className="custom-plan-finish-container margin-top-medium">
                            <ActionButton
                                onClick={() => navigate("/my-cities")}
                            >
                                Back to dashboard
                            </ActionButton>
                        </div>
                    </div>
                    :
                    <div className="custom-plan-body-container">
                        <div className="custom-plan-input-container margin-large">
                            <FormInput
                                value={email}
                                type="email"
                                label="Your email"
                                id="email-input"
                                placeholder="youremail@email.com"
                                onChange={(text) => setEmail(text.target.value)}
                            />
                        </div>
                        <h3 className="body-semibold colour-primary margin-x-small">
                            What areas would you like?
                        </h3>
                        <div className="custom-plan-input-container margin-large">
                            <MultilineNoShadow
                                value={customPlanText}
                                type="text"
                                id="custom-plan-input"
                                placeholder="Tell us a bit more about your request. Include the specific cities, counties, or any details we need to know."
                                rows="8"
                                inputProps={{
                                    autoCapitalize: 'none'
                                }}
                                onChange={(text) => setCustomPlanText(text.target.value)}
                                multiline
                            />
                        </div>
                        <div className="custom-plan-button-container">
                            <ActionButton
                                onClick={() => complete()}
                                disabled={email === "" || customPlanText === "" || loading === true ? true : false}
                            >
                                Submit request
                            </ActionButton>
                            {
                                error !== null ?
                                <span className="body-regular colour-error block-text margin-top-small">
                                    {error}
                                </span>
                                :
                                null
                            }
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
};

export default CustomPlan;