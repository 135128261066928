import React, { useState } from 'react';
import '../styles/PropertyListTopBar.css';
import { PinBlue } from '../assets';
import { Download, Filters } from '../assets';
import { BadgeExplanation, BuyBoxSelector, ExitScreen, FilterDropdown } from './';
import { recordEvent, resetFilterType } from '../functions';
import { colour, CustomSelect, neighbourhoodDataOptions } from '../styles/GlobalStyles';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

function PropertyListTopBar(props) {
    const cityStateName = props.cityStateName;
    const setFiltersModalOpen = props.setFiltersModalOpen;
    const freeTrial = props.freeTrial;
    const downloadCSV = props.downloadCSV;
    const downloadCSVFreeTrial = props.downloadCSVFreeTrial;
    const setCardIndex = props.setCardIndex;
    const cityId = props.cityId;
    const temporaryId = props.temporaryId;
    const setSignUpModal = props.setSignUpModal;
    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const setChangeMade = props.setChangeMade;
    const setPage = props.setPage;
    const setAddressSearchedProperty = props.setAddressSearchedProperty;
    const setGoogleMapProperty = props.setGoogleMapProperty;
    const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
	const onlyADU = props.onlyADU;
	const setOnlyADU = props.setOnlyADU;
	const onlyExtraBedroom = props.onlyExtraBedroom;
	const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
	const onlySellerFinancing = props.onlySellerFinancing;
	const setOnlySellerFinancing = props.setOnlySellerFinancing;
	const priceReduction = props.priceReduction;
	const setPriceReduction = props.setPriceReduction;
	const priceReductionIndex = props.priceReductionIndex;
	const setPriceReductionIndex = props.setPriceReductionIndex;
	const hideFixerUppers = props.hideFixerUppers;
	const setHideFixerUppers = props.setHideFixerUppers;
	const onlyFixerUppers = props.onlyFixerUppers;
	const setOnlyFixerUppers = props.setOnlyFixerUppers;
    const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
    const subscriptions = props.subscriptions;
    const buyBoxes = props.buyBoxes;
    const neighbourhoodOption = props.neighbourhoodOption;
    const fixerUpperOption = props.fixerUpperOption;
    const setFixerUpperOption = props.setFixerUpperOption;
    const fsbo = props.fsbo;
    const setFsbo = props.setFsbo;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;
    const hideHeatmap = props.hideHeatmap;
    const onChangeNeighbourhoodOption = props.onChangeNeighbourhoodOption;

    const [openFilters, setOpenFilters] = useState(null);
    const [buyBoxSelectorContainer, setBuyBoxSelectorContainer] = useState(false);

    const filterTypes = [
        {
            label: "Property type"
        },
        {
            label: "Budget"
        },
        {
            label: "Property specifics"
        },
        {
            label: "Value-add"
        },
        {
            label: "More filters"
        }
    ];

    const cityButtonClick = () => {
        setBuyBoxSelectorContainer(true);
    };

    const openFiltersModal = () => {
		setFiltersModalOpen(true)
        recordEvent("Open Filters", {});
	};

    const clickOutside = (e) => {
        setCardIndex(null)
        setAddressSearchedProperty(null);
        setGoogleMapProperty(null);
    }

    const openFilterDropdown = (index, e) => {
        const target = e.target;

        if ( (target.tagName === "path" || target.tagName === "svg") ) {
            if ( openFilters === index ) {
                setOpenFilters(null);
            }
            else {
                setOpenFilters(index);
            }
            return;
        }
        else if ( target.className.includes("filter-dropdown-chip") && openFilters === index) {
            setOpenFilters(null);
            return;
        }

        if ( index !== openFilters ) {
            setOpenFilters(index);
        }
    };

    const resetAllFilters = async() => {
		searchParams.delete("propertyType");
		searchParams.delete("budgetRange");
		searchParams.delete("propertySpecifics");
		searchParams.delete("valueAdd");
		searchParams.delete("more");
		await resetFilterType(
			"all",
			setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
            setOnlyADU,
            setOnlyExtraBedroom,
            setHideFixerUppers,
            setOnlyFixerUppers,
            setPriceReduction,
            setPriceReductionIndex,
            setOnlySellerFinancing,
            setFixerUpperOption,
            setFsbo,
            setNeighbourhoodGradeMin,
            setNeighbourhoodGradeMax
		);
		setSearchParams(searchParams);
        setChangeMade("filters");
        recordEvent("Reset Filters", {});
	};
    
    const closeEverything = () => {
        setOpenFilters(null);
        setBuyBoxSelectorContainer(false);
    };

    const styles = {
        menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
        selectedMenuItem: {
			backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
		},
        hiddenMenuItem: {
            backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hiddenMenuItemSelected: {
            backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center'
		}
	};

    return (
        <div className="property-list-top-bar-outer-container">
            {
                openFilters !== null || buyBoxSelectorContainer === true ?
                <ExitScreen
                    clickScreen={() => closeEverything()}
                />
                :
                null
            }
            <div 
                className="property-list-top-bar-inner-container"
                onClick={(e) => clickOutside(e)}
            >
                <div className="property-list-top-bar-left-container relative-container">
                    <div 
                        className="property-list-top-bar-city-button-container text-button"
                        onClick={() => cityButtonClick()}
                    >
                        <img
                            src={PinBlue}
                            className="pin-icon"
                            alt="Pin"
                        />
                        <span className={(buyBoxSelectorContainer === true ? "body-semibold" : "body-regular") + " colour-link"}>
                            {cityStateName}
                        </span>
                    </div>
                    {
                        buyBoxSelectorContainer === true ?
                        <BuyBoxSelector
                            buyBoxes={buyBoxes}
                            searchParams={searchParams}
                            cityId={cityId}
                            freeTrial={freeTrial}
                            subscriptions={subscriptions}
                        />
                        :
                        null
                    }
                    <div className="property-list-top-bar-csv-download-container icon-button">
                        <img
                            src={Download}
                            className="top-bar-download-icon"
                            alt="Download as CSV"
                            onClick={() => freeTrial === true ? downloadCSVFreeTrial() : downloadCSV()}
                        />
                        <BadgeExplanation
                            title="Download as CSV"
                            description="CSV download is only available on a premium plan"
                        />
                    </div>
                    <div 
                        className="property-list-top-bar-filters-button"
                        onClick={() => temporaryId === true ? setSignUpModal(true) : openFiltersModal()}
                    >
                        <img
                            src={Filters}
                            className="top-bar-filters-icon"
                            alt="Filters"
                        />
                        <span className="body-regular text-link">
                            Filters
                        </span>
                    </div>
                    <div className="property-list-top-bar-filter-chips-row">
                        {
                            filterTypes.map((item, index) =>
                                <FilterDropdown
                                    item={item}
                                    key={index}
                                    openFilters={openFilters}
                                    setOpenFilters={setOpenFilters}
                                    openFilterDropdown={openFilterDropdown}
                                    index={index}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    propertyTypes={propertyTypes}
                                    setPropertyTypes={setPropertyTypes}
                                    units={units}
                                    setUnits={setUnits}
                                    budgetRange={budgetRange}
                                    setBudgetRange={setBudgetRange}
                                    bedrooms={bedrooms}
                                    setBedrooms={setBedrooms}
                                    bathrooms={bathrooms}
                                    setBathrooms={setBathrooms}
                                    squareFootage={squareFootage}
                                    setSquareFootage={setSquareFootage}
                                    constructionYears={constructionYears}
                                    setConstructionYears={setConstructionYears}
                                    onlyADU={onlyADU}
                                    setOnlyADU={setOnlyADU}
                                    onlyExtraBedroom={onlyExtraBedroom}
                                    setOnlyExtraBedroom={setOnlyExtraBedroom}
                                    priceReduction={priceReduction}
                                    setPriceReduction={setPriceReduction}
                                    priceReductionIndex={priceReductionIndex}
                                    setPriceReductionIndex={setPriceReductionIndex}
                                    onlySellerFinancing={onlySellerFinancing}
                                    setOnlySellerFinancing={setOnlySellerFinancing}
                                    hideFixerUppers={hideFixerUppers}
                                    setHideFixerUppers={setHideFixerUppers}
                                    onlyFixerUppers={onlyFixerUppers}
                                    setOnlyFixerUppers={setOnlyFixerUppers}
                                    setChangeMade={setChangeMade}
                                    setPage={setPage}
                                    setPropertiesOnPageChange={setPropertiesOnPageChange}
                                    fixerUpperOption={fixerUpperOption}
                                    setFixerUpperOption={setFixerUpperOption}
                                    fsbo={fsbo}
                                    setFsbo={setFsbo}
                                    neighbourhoodGradeMin={neighbourhoodGradeMin}
                                    neighbourhoodGradeMax={neighbourhoodGradeMax}
                                    setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                    setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                                />
                            )
                        }
                        {
                            searchParams.get("propertyType") !== null ||
                            searchParams.get("budgetRange") !== null ||
                            searchParams.get("propertySpecifics") !== null ||
                            searchParams.get("valueAdd") !== null ||
                            searchParams.get("more") !== null ?
                            <span 
                                className="body-regular-underline colour-link block-text subtext-margin-left-large"
                                onClick={() => resetAllFilters()}
                            >
                                Clear filters
                            </span>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="property-list-top-bar-right-container">
                    <div className="map-types-row">
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="neighbourhood-option-select-label"
                                    id={`neighbourhood-option-select`}
                                    value={hideHeatmap === true ? "hidden" : neighbourhoodOption}
                                    label=""
                                    onChange={(event) => onChangeNeighbourhoodOption(event.target.value)}
                                    input={<CustomSelect />}
                                    className={hideHeatmap === true ? "neighbourhood-option-selector" : ""}
                                >
                                    <MenuItem
                                        value={"hidden"}
                                        style={hideHeatmap === true ? styles.hiddenMenuItemSelected : styles.hiddenMenuItem}
                                        disabled={false}
                                    >
                                        Hide Heatmap
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            viewBox="0 0 16 16" 
                                            fill="none"
                                        >
                                            <path 
                                                d="M14.0878 2.98574C14.2701 2.81671 14.2701 2.54264 14.0878 2.3736C13.9055 2.20457 13.6099 2.20457 13.4276 2.3736L1.95111 13.0144C1.7688 13.1835 1.7688 13.4575 1.95111 13.6266C2.13343 13.7956 2.42901 13.7956 2.61132 13.6266L14.0878 2.98574Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                d="M8 3.36502C9.06405 3.36502 10.1176 3.6722 11.0951 4.12809L10.3462 4.82251C9.57269 4.50103 8.77831 4.30285 8 4.30285C6.47146 4.30285 4.88093 5.06722 3.50834 6.02876C2.51975 6.72131 1.70743 7.47124 1.19707 7.98206C1.70743 8.49288 2.51975 9.24281 3.50835 9.93536C3.76059 10.1121 4.0202 10.2821 4.28544 10.442L3.57174 11.1037C1.84521 10.0158 0.520206 8.65178 0.0723986 8.165C-0.0241321 8.06007 -0.0241332 7.90405 0.0723974 7.79912C0.860885 6.94201 4.369 3.36502 8 3.36502Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                d="M7.9611 5.67354C8.38493 5.67354 8.78364 5.77329 9.1314 5.94885L5.80714 9.03108C5.61779 8.70864 5.51021 8.33896 5.51021 7.94599C5.51021 6.69095 6.60751 5.67354 7.9611 5.67354Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                d="M4.95671 11.86L5.70766 11.1638C6.46415 11.4722 7.23974 11.6613 8 11.6613C9.52853 11.6613 11.1191 10.8969 12.4917 9.93536C13.4802 9.24281 14.2926 8.49288 14.8029 7.98206C14.2926 7.47124 13.4802 6.72131 12.4917 6.02876C12.2543 5.86251 12.0105 5.70216 11.7616 5.55063L12.4745 4.88966C14.1784 5.9726 15.4838 7.31669 15.9276 7.79912C16.0241 7.90405 16.0241 8.06007 15.9276 8.165C15.1391 9.02211 11.631 12.5991 8 12.5991C6.95474 12.5991 5.91966 12.3027 4.95671 11.86Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                d="M6.94646 10.0152L10.1928 7.00524C10.3336 7.2919 10.412 7.61045 10.412 7.94599C10.412 9.20103 9.31468 10.2184 7.9611 10.2184C7.59921 10.2184 7.25564 10.1457 6.94646 10.0152Z" 
                                                fill="#292621"
                                            />
                                        </svg>
                                    </MenuItem>	
                                    {
                                        neighbourhoodDataOptions.map((item, index) =>
                                            <MenuItem
                                                key={index}
                                                value={index}
                                                style={index === neighbourhoodOption && hideHeatmap === false ? styles.selectedMenuItem : styles.menuItem}
                                                disabled={false}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyListTopBar;