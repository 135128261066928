import React, { useEffect, useRef, useState } from 'react';
import '../styles/PickCity.css';
import { Header, Footer, Chip, ExploreMarkets, PickCityBottom, BuyBoxModal, OpenModalBg, QuickReport } from '../components';
import { useNavigate } from 'react-router-dom';
import { citiesForPurchase, SortSelect, colour, FilterCityInput, defaultBuyBox } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, recordEvent, cloudFunctionV2, getUserDocuments, setTransaction, makeId, setMergeData } from '../functions';
import { DarkSearch, Search, Sort, BlackTick, Exit } from '../assets';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';

function PickCity() {

	const [userData, setUserData] = useState(null);
	const [subscriptions, setSubscriptions] = useState([]);
	const [buyBoxes, setBuyBoxes] = useState([]);
	const [savedUserId, setSavedUserId] = useState("");
	const [filterKeywords, setFilterKeywords] = useState("");
	const [filteredResults, setFilteredResults] = useState([]);
	const [searchActive, setSearchActive] = useState(false);
	const [sorting, setSorting] = useState(0);
	const [sortedResults, setSortedResults] = useState([]);
	const [cashOnCashRankings, setCashOnCashRankings] = useState([]);
	const [appreciationRankings, setAppreciationRankings] = useState([]);
	const [valueAddRankings, setValueAddRankings] = useState([]);
	const [landlordRankings, setLandlordRankings] = useState([]);
	const [hotnessRankings, setHotnessRankings] = useState([]);
	const [listingPriceRankings, setListingPriceRankings] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const [openBuyBoxModal, setOpenBuyBoxModal] = useState(false);
	const [dontAsk, setDontAsk] = useState(false);
	const [newState, setNewState] = useState(null);
	const checkedRankings = selectedCategory === 0 ? cashOnCashRankings : selectedCategory === 1 ? valueAddRankings : selectedCategory === 2 ? landlordRankings : selectedCategory === 3 ? appreciationRankings : selectedCategory === 4 ? hotnessRankings : listingPriceRankings;
	const citiesRef = useRef(null);
	const navigate = useNavigate();

	const sortOptions = [
		{
			label: "Sort"
		},
		{
			label: "City name (A to Z)"
		},
		{
			label: "State (A to Z)"
		}
	];
	const categories = [
		{
			label: "All",
			value: "All",
			description: "The 50 hottest investment markets in the U.S. right now."
		},
		{
			label: "🤑 Best for cash flow",
			value: "Cash flow",
			description: "The cities with the highest average cash flow."
		},
		{
			label: "🏘️ Value-add-opportunity",
			value: "Value-add",
			description: "The cities with the best fix & flip opportunities"
		},
		{
			label: "👨‍👩‍👧‍👦 Landlord-friendly",
			value: "Landlord-friendly",
			description: "The cities with the most landlord-friendly laws."
		},
		{
			label: "📈 Best for appreciation",
			value: "Appreciation",
			description: "The cities with the highest average appreciation."
		},
		{
			label: "🔥 Hottest market",
			value: "Hottest",
			description: "The cities that are currently most popular."
		},
		{
			label: "💰 Most affordable",
			value: "Affordable",
			description: "The cities with the most affordable properties."
		}
	];

	useEffect(() => {
		document.title = "Pick City";
		async function fetchUserData() {

			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const colRefs = ["Users", "Subscriptions", "Buy Boxes"];
				const userId = user.userId;

				const queryUser = await getUserDocuments(colRefs, userId);
				if ( queryUser[0].status === 200 ) {
					const userDetails = queryUser[0].data;
					setUserData(userDetails);
				}
				setSavedUserId(userId);

				if ( queryUser[1].status === 200 ) {
					const today = new Date();
					const todaySeconds = today.getTime() / 1000;

					const oldSubs = queryUser[1].data;
					const newSubs = [];
					for (let index = 0; index < oldSubs.length; index++) {
						const element = oldSubs[index];
						if ( element.endDate.seconds > todaySeconds ) {
							newSubs.push(element);
						}
					};
					setSubscriptions(newSubs);
				}

				if ( queryUser[2].status === 200 ) {
					setBuyBoxes(queryUser[2].data);
				}
			}
			else {
				setSavedUserId(null);
			}
			getCityData();
		}

		async function getCityData() {
			const s3URL = process.env.REACT_APP_AWS_QUERY_URL;
			const params = {
                type: "s3",
                s3Data: {
                    key: `output/city_market_data_rank/all_cities_v3.json`,
				    bucketName: "residentialpropertydata"
                }
			};
			const query = await cloudFunctionV2(s3URL, params);
            if ( query.status === 200 ) {
                const body = query.body;
                setCashOnCashRankings(body.cash_flow.slice(0, 12));
                setAppreciationRankings(body.appreciation.slice(0, 12));
                setValueAddRankings(body.value_add.slice(0, 12));
                setLandlordRankings(body.landlord_score.slice(0, 12));
                setHotnessRankings(body.hottest_market.slice(0, 12));
                setListingPriceRankings(body.most_affordable.slice(0, 12));
            }
		}

		fetchUserData();
	}, []);

	const selectCity = async(item) => {
		const checkedCity = item.cityUnchanged === undefined ? item.city : item.cityUnchanged;
		recordEvent("Pick City", {
			"City": checkedCity,
			"State": item.state,
			"CityId": item.city_id
		});
		const newState = {
			chosenCity: {
				city: checkedCity,
				state: item.state,
				cityId: item.city_id
			},
			onboarding: userData === null ? true : false
		};
		setNewState(newState);
		if ( userData === null ) {
			navigate("/sign-up", {
				state: newState
			});
		}
		else {
			// Check if they have a subscription or buy box for this city
			const buyBoxIndex = buyBoxes.findIndex(e => e.cityId === item.city_id);
			const today = new Date();
			const todaySeconds = today.getTime() / 1000;
			const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
			const freeTrial = userData.freeTrial[0].endDate.seconds > todaySeconds ? true : false;

			if ( ( activeSubscription !== -1 || freeTrial === true ) && buyBoxIndex !== -1 ) {
				// If the user HAS an active subscription for the city or has a free trial and they ALREADY have a buy box for the city,
				// just navigate to it
				window.open(`/properties-list/${newState.chosenCity.cityId}`, "_blank");
				await saveCityToDashboard(newState.chosenCity);
			}
			else if ( ( activeSubscription !== -1 || freeTrial === true ) && userData.buyBoxAsk === false ) {
				// If the user HAS an active subscription for the city or has a free trial and they have asked to not be asked
				// about Buy Boxes again, just navigate to it
				window.open(`/properties-list/${newState.chosenCity.cityId}`, "_blank");
				await saveCityToDashboard(newState.chosenCity);
			}
			else if ( ( activeSubscription !== -1 || freeTrial === true ) && buyBoxIndex === -1 ) {
				// If the user HAS an active subscription for the city or has a free trial and does NOT have a buy box for the city,
				// ask them if they would like one
				setOpenBuyBoxModal(true);
			}
			else {
				// If the user does NOT have an active subscription for the city or a free trial
				navigate("/pricing");
			}
		}
	};

	const skip = async() => {
		setOpenBuyBoxModal(false);
		if ( dontAsk === true && savedUserId !== "" && savedUserId !== null ) {
			const colRef = "Users";
			const root = "buyBoxAsk";
			const val = false;
			const subval = null;
			await setTransaction(colRef, savedUserId, root, val, subval);

			const userDataClone = structuredClone(userData);
			userDataClone.buyBoxAsk = false;
			setUserData(userDataClone);
		}

		await saveCityToDashboard(newState.chosenCity);
		window.open(`/properties-list/${newState.chosenCity.cityId}`, "_blank");
    };
    
    const complete = async() => {
		if ( dontAsk === true && savedUserId !== "" && savedUserId !== null ) {
			const colRef = "Users";
			const root = "buyBoxAsk";
			const val = false;
			const subval = null;
			await setTransaction(colRef, savedUserId, root, val, subval);

			const userDataClone = structuredClone(userData);
			userDataClone.buyBoxAsk = false;
			setUserData(userDataClone);
		}

		navigate("/buy-box-setup", {
			state: newState
		});
    };

	const saveCityToDashboard = async(item) => {
		const cityIndex = buyBoxes.findIndex(e => e.cityId === item.cityId);
		if ( cityIndex === -1 ) {
			const newBuyBox = {
				city: item.city,
				cityId: item.cityId,
				state: item.state,
				metroArea: false,
				buyBoxes: [],
				loaded: true

			};

			if ( buyBoxes.length === 0 ) {
				const id = await makeId(10);
				defaultBuyBox.city = item.city;
				defaultBuyBox.cityId = item.cityId
				defaultBuyBox.state = item.state;
				defaultBuyBox.title = `My ${item.city}, ${item.state} Turnkey buy box`;
				defaultBuyBox.id = id;
				newBuyBox.buyBoxes.push(defaultBuyBox);
			}

			const buyBoxesClone = [...buyBoxes];
			buyBoxesClone.unshift(newBuyBox);
			setBuyBoxes(buyBoxesClone);
			await setMergeData("Buy Boxes", savedUserId, buyBoxesClone);
		}
	};

	const changeFilter = (val) => {
		if ( val === "" ) {
			setFilteredResults([]);
			setFilterKeywords(val);
		}
		else {
			const lowercaseVal = val.toLowerCase();
			const citiesForPurchaseClone = [];
			for (let index = 0; index < citiesForPurchase.length; index++) {
				const city = citiesForPurchase[index].city;
				const state = citiesForPurchase[index].stateFull;
				const newObject = {
					city: city.toLowerCase(),
					stateFull: state.toLowerCase(),
					state: citiesForPurchase[index].state,
					cityUnchanged: city,
					icon: citiesForPurchase[index].icon,
					fullCityFullState: `${city.toLowerCase()}, ${state.toLowerCase()}`,
					fullCityAbbreviatedState: `${city.toLowerCase()}, ${citiesForPurchase[index].state.toLowerCase()}`,
					city_id: citiesForPurchase[index].city_id
				}
				citiesForPurchaseClone.push(newObject);
			}

			const results = citiesForPurchaseClone.filter(e => e.city.includes(lowercaseVal));
			const stateResults = citiesForPurchaseClone.filter(e => e.stateFull.includes(lowercaseVal));
			const fullStateResults = citiesForPurchaseClone.filter(e => e.fullCityFullState.includes(lowercaseVal));
			const abbreviatedStateResults = citiesForPurchaseClone.filter(e => e.fullCityAbbreviatedState.includes(lowercaseVal));

			const combinedArray = results.concat(stateResults, fullStateResults, abbreviatedStateResults);
			
			let finalFilteredArray = [];
			for (let index = 0; index < combinedArray.length; index++) {
				const element = combinedArray[index];
				const cityId = element.city_id;
				const cityIndex = finalFilteredArray.findIndex(e => e.city_id === cityId);
				if ( cityIndex === -1 ) {
					finalFilteredArray.push(element);
				}
			}

			finalFilteredArray = combinedArray.filter((value, index, self) =>
				index === self.findIndex((e) => (
					e.city_id === value.city_id
				))
			);

			setFilteredResults(finalFilteredArray);
			setFilterKeywords(val);
		}
	}

	const onChangeCategory = (index) => {
		setSelectedCategory(index);
		recordEvent("Pick City - Change Category", {
			"Category": categories[index].value
		});
	};

	const changeSorting = (val) => {
		const arrayClone = citiesForPurchase.slice(0);
		if ( val === 1 ) {
			arrayClone.sort((a, b) => a.city.localeCompare(b.city));
			setSortedResults(arrayClone);
		}
		else if ( val === 2 ) {
			setSortedResults([]);
		}
		else if ( val === 3 ) {

		}
		setSorting(val);
	};

	const upgradeCity = () => {
		recordEvent("Pick City - Upgrade City", {});
		navigate("/pricing");
	};

	const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

	const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
		selectedMenuItem: {
			backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
		},
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center'
		}
	};

	return (
		<div className="pick-city-outer-container relative-container">
			<Header
				subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
				mobileNav={true}
			/>
			{
				openBuyBoxModal === true ?
				<OpenModalBg />
				:
				null
			}
			{
				savedUserId === "" ?
				null
				:
				<QuickReport
					userData={userData}
					subscriptions={subscriptions}
				/>
			}
			<div className={"pick-city-inner-container " + (filterKeywords !== "" ? "pick-city-search-active" : "")}>
				{
					openBuyBoxModal === true ?
					<BuyBoxModal
						dontAsk={dontAsk}
						setDontAsk={setDontAsk}
						setOpenBuyBoxModal={setOpenBuyBoxModal}
						skip={skip}
						complete={complete}
					/>
					:
					null
				}
				<div className="pick-city-outer-body-container">
					<div className="pick-city-section-title-container margin-top-x-x-large">
						<h2 className="heading-large-semibold colour-primary text-align-center">
							Browse markets
						</h2>
					</div>
					<div className="pick-city-categories">
						{
							filterKeywords !== "" ?
							null
							:
							categories.map((item, index) =>
								<Chip
									label={item.label}
									func={onChangeCategory}
									index={index}
									selected={selectedCategory}
									key={index}
									recommended={false}
									hover={null}
									leave={null}
									disabled={false}
								/>
							)
						}
					</div>
					{
						checkedRankings.length === 0 ?
						<div className="pick-city-loading-container">
							{View}
						</div>
						:
						null
					}
					<div className="pick-city-all-property-title-container margin-large">
						<h2 className="heading-small-semibold colour-primary margin-x-small text-align-center">
							{
								selectedCategory === 0 ?
								"All cities"
								:
								categories[selectedCategory].label
							}
						</h2>
						<span className="body-regular colour-secondary block-text text-align-center">
							{categories[selectedCategory].description}
						</span>
					</div>
					{
						selectedCategory === 0 ?
						<div className={"pick-city-right-row margin-large " + (searchActive === true ? "pick-city-input-padding" : "")}>
							<Box>
								<FormControl sx={styles.formControl} fullWidth>
									<img
										src={Sort}
										className="pick-city-sort-icon"
										alt=""
									/>
									<Select
										labelId="sort-select-label"
										id="sort-select"
										value={sorting}
										label=""
										placeholder="Interest Points"
										onChange={(event) => changeSorting(event.target.value)}
										input={<SortSelect />}
									>
										{
											sortOptions.map((item, index) =>
												<MenuItem 
													key={index}
													value={index}
													style={index === sorting ? styles.selectedMenuItem : styles.menuItem}
													disabled={index === 0 ? true : false}
												>
													{
														index === sorting ?
														<img
															src={BlackTick}
															className="pick-city-sorting-tick"
															alt="Tick"
														/>
														:
														<div className="pick-city-sorting-tick">

														</div>
													}
													{item.label}
												</MenuItem>	
											)
										}
									</Select>
								</FormControl>
							</Box>
							{
								searchActive === false ?
								<div className="pick-city-unselected-search-container">
									<img
										src={DarkSearch}
										className="pick-city-unselected-search-icon"
										alt="Search"
										onClick={() => setSearchActive(true)}
									/>
								</div>
								:
								null
							}
							<div className={"pick-city-filter-input-container " + (searchActive === true ? "search-active-input-container" : "search-inactive-input-container")}>
								<FilterCityInput
									value={filterKeywords}
									type="text"
									id="filter-input"
									placeholder="Filter cities"
									onChange={(text) => changeFilter(text.target.value)}
								/>
								<img
									src={filterKeywords === "" ? Search : Exit}
									className={filterKeywords === "" ? "pick-city-search-icon" : "pick-city-exit-icon"}
									alt="Search"
									onClick={() => filterKeywords === "" ? setSearchActive(false) : changeFilter("")}
								/>
							</div>
						</div>
						:
						<ExploreMarkets
							ref={citiesRef}
							selectCity={selectCity}
							cashOnCashRankings={cashOnCashRankings}
							appreciationRankings={appreciationRankings}
							valueAddRankings={valueAddRankings}
							landlordRankings={landlordRankings}
							hotnessRankings={hotnessRankings}
							listingPriceRankings={listingPriceRankings}
							selectedCategory={selectedCategory - 1}
							savedUserId={savedUserId}
						/>
					}
					{
						filteredResults.length === 0 && filterKeywords !== "" ?
						<div className="pick-city-no-results-container">
							<span className="body-regular colour-primary text-align-center block-text margin-medium">
								<span className="body-semibold text-capitalise">{filterKeywords}</span> is not included in our 50 sample markets.
							</span>
							<div 
								className="text-button"
								onClick={() => upgradeCity()}
							>
								<span className="body-regular colour-link">
									Upgrade here
								</span>
							</div>
						</div>
						:
						null
					}
					<div className="pick-city-body-container">
						{
							filteredResults.length === 0 && filterKeywords !== "" ?
							null
							:
							selectedCategory !== 0 ?
							null
							:
							filteredResults.length !== 0 ?
							filteredResults.map((item, index) =>
								<div 
									key={index}
									className="pick-city-body-element"
									onClick={() => savedUserId === "" ? null : selectCity(item)}
								>
									<img
										src={item.icon}
										className={"pick-city-icon " + (item.city === "Philadelphia" ? "pick-city-double-icon" : "")}
										alt={item.cityUnchanged}
									/>
									<span className="body-semibold colour-link">
										{item.cityUnchanged}, {item.state}
									</span>
								</div>
							)
							:
							sortedResults.length !== 0 ?
								sortedResults.map((item, index) =>
									<div 
										key={index}
										className="pick-city-body-element"
										onClick={() => savedUserId === "" ? null : selectCity(item)}
									>
										<img
											src={item.icon}
											className={"pick-city-icon " + (item.city === "Philadelphia" ? "pick-city-double-icon" : "")}
											alt={item.cityUnchanged}
										/>
										<span className="body-semibold colour-link">
											{item.cityUnchanged}, {item.state}
										</span>
									</div>
								)
							:
							citiesForPurchase.map((item, index) =>
								<div 
									key={index}
									className="pick-city-body-element"
									onClick={() => savedUserId === "" ? null : selectCity(item)}
								>
									<img
										src={item.icon}
										className={"pick-city-icon " + (item.city === "Philadelphia" ? "pick-city-double-icon" : "")}
										alt={item.cityUnchanged}
									/>
									<span className="body-semibold colour-link">
										{item.cityUnchanged}, {item.state}
									</span>
								</div>
							)
						}
					</div>
				</div>
			</div>
			<PickCityBottom
				navigate={navigate}
				userData={userData}
			/>
			<Footer />
		</div>
	)
}

export default PickCity;