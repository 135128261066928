import React, { useEffect, useState } from 'react';
import '../styles/Pagination.css';
import ChevronRight from '../assets/chevron-right.svg';
import { sortByPage, recordEvent, saveSearchParams } from '../functions';

function Pagination(props) {

	const page = props.page; 
	const queryLength = props.queryLength;
	const propertiesOnPage = props.propertiesOnPage;
	const propertiesOnPageChange = props.propertiesOnPageChange; 
	const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
	const cityId = props.cityId;
	const setProperties = props.setProperties;
	const setPage = props.setPage;
	const cardsRef = props.cardsRef;
	const searchParams = props.searchParams;
	const setSearchParams = props.setSearchParams;
	const allProperties = props.allProperties;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const setLoading = props.setLoading;
	// const offMarket = props.offMarket;
	const setChangeMade = props.setChangeMade;
	const setMapProperties = props.setMapProperties;
	// const everyProperty = props.everyProperty;

	const length = queryLength;
	const numberOfPages = length !== 0 ? Math.ceil(length / (propertiesOnPage === undefined || propertiesOnPage === 0 ? 20 : propertiesOnPage)) : 0;
	const [pages, setPages] = useState([]);
	const [pageRange, setPageRange] = useState([0, 19]);

	useEffect(() => {
		if ( pages.length === 0 || propertiesOnPageChange === true ) {
			setPropertiesOnPageChange(false);
			const emptyArray = [];
			for (let index = 0; index < numberOfPages; index++) {

				const lowerBound = index * 20
				const upperBound = lowerBound + 20;
				const pageProperties = allProperties.slice(lowerBound, upperBound);

				const newObject = {
					properties: pageProperties
				}
				emptyArray.push(newObject);
				setPages(emptyArray);
			}

			if ( page > 7 ) {
				const minPage = page - 6;
				const maxPage = page + 1;
				setPageRange([minPage, maxPage]);
			}
			else {
				setPageRange([0, 8]);
			}
		}
	}, [numberOfPages, pages, page, length, propertiesOnPageChange, setPropertiesOnPageChange, cityId, allProperties, setLoading]);

	const nextPage = () => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			setLoading(true);
			changePage(page + 1);

			recordEvent("Page Change", {
				oldPage: page,
				newPage: page + 1
			});
		}
	};

	const prevPage = () => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			setLoading(true);
			changePage(page - 1);

			recordEvent("Page Change", {
				oldPage: page,
				newPage: page - 1
			});
		}
	};

	const changePage = async(item) => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			setChangeMade("page");
			setLoading(true);
			setPage(item);

			setTimeout(() => {
				if ( cardsRef.current !== null ) {
					cardsRef.current.scroll({
						top: 0,
						behavior: 'smooth'
					});
				}
			}, 250);

			const pageParam = item;
			const searchQueryParam = searchParams.get('searchQueryState');
			const label = "page";
			const value = item;
			await saveSearchParams(label, value, searchParams, setSearchParams);

			const newPropertyOrder = await sortByPage(allProperties, pageParam);
			setProperties(newPropertyOrder.page);
			if ( searchQueryParam === null ) {
				setMapProperties(newPropertyOrder.map);
			}
			setLoading(false);

			if ( item > 7 ) {
				const minPage = item - 6;
				const maxPage = item + 1;
				setPageRange([minPage, maxPage]);
			}
			else {
				setPageRange([0, 8]);
			}

			recordEvent("Page Change", {
				oldPage: page,
				newPage: item
			});
		}
	};

	return (
		<div className="pagination-outer-container">
			<div className="pagination-inner-container">
				{
					page !== 1 && pages.length !== 1 ?
					<img
						src={ChevronRight}
						className="pagination-chevron-left"
						alt="Previous Page"
						onClick={() => prevPage()}
					/>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div onClick={() => changePage(1)} className={"pagination-element " + (page === 1 ? "pagination-element-selected" : "" )}>
						<span>
							1
						</span>
					</div>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div className="pagination-element pagination-element-dots">
						<span>
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.map((item, index) =>
						index <= pageRange[1] && index >= pageRange[0] ? 
						<div onClick={() => changePage(index + 1)} className={"pagination-element " + (index + 1 === page ? "pagination-element-selected" : "" )} key={index}>
							<span>
								{index + 1}
							</span>
						</div>
						:
						null
					)
				}
				{
					pages.length > (pageRange[1] + 2) ?
					<div className="pagination-element pagination-element-dots">
						<span>
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.length > (pageRange[1] + 1) ?
						<div onClick={() => changePage(pages.length)} className={"pagination-element " + (pages.length === page ? "pagination-element-selected" : "" )}>
							<span>
								{pages.length}
							</span>
						</div>
					:
					null
				}
				{
					page !== pages.length && pages.length !== 1 ?
					<img
						src={ChevronRight}
						className="pagination-chevron-right"
						alt="Next Page"
						onClick={() => nextPage()}
					/>
					:
					null
				}
			</div>
		</div>
	)
}

export default Pagination;