import React, { useState, useEffect, useRef } from 'react';
import '../styles/Dashboard.css';
import { Header, Footer, LoadingStatic, Chip, EditProfileModal, NewPropertyCard, PartnerBanner, ExitScreen, SideNavigation } from '../components';
import { checkOnAuthStateChanged, getDocument, checkUserId, uploadImage, setData, getAPIGatewaySavedProperties, changePartnerPropertyStatus, recordEvent, cloudFunctionV2 } from '../functions';
import { ActionButton, defaultUserData, formatterLong, propertyStatuses } from '../styles/GlobalStyles';
// import { Grid, List } from '../assets';
import { useLocation, useNavigate } from 'react-router-dom';

function Dashboard() {
    const textInput = useRef(null);
    const [partnerData, setPartnerData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [option, setOption] = useState(0);
    const [listings, setListings] = useState([]);
    // const [listView, setListView] = useState(false);
    const [profilePicError, setProfilePicError] = useState("");
    const [profilePicLoading, setProfilePicLoading] = useState("");
    const [editProfile, setEditProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showExitScreen, setShowExitScreen] = useState(false);
    const [categoryCount, setCategoryCount] = useState([listings.length, 0, 0, 0, 0]);
    const [chooseStatus, setChooseStatus] = useState(false);
    const [chooseStatusZPID, setChooseStatusZPID] = useState(null);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    const chipOptions = [
        `All (${listings.length})`,
        `Active (${categoryCount[1]})`,
        `Under contract (${categoryCount[2]})`,
        `Sold (${categoryCount[3]})`,
        `Archive (${categoryCount[4]})`
    ];

    const chipOptionsValues = [
        "all",
        "active",
        "under-contract",
        "sold",
        "archive"
    ];

    useEffect(() => {
        document.title = "Partner Dashboard";
        const fetchData = async() => {
            setLoading(true);
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const colRef = "Partners";
                const docRef = user.userId;
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    getUser(docRef);
                    setPartnerData(queryData.data.data);
                    formatPhoneNumber(queryData.data.data.phoneNumber);

                    const pastListings = queryData.data.data.listings;
                    const zpidArray = [];
                    for (let index = 0; index < pastListings.length; index++) {
                        const element = pastListings[index];
                        if ( element.zpid !== undefined ) {
                            zpidArray.push(element.zpid);
                        }
                    }
                    const zpids = zpidArray.toString();
                    
                    if ( zpidArray.length !== 0 ) {
                        const getGatewayQueryString = await getAPIGatewaySavedProperties(zpids, defaultUserData.settings);
                        const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                        const gatewayData = {
                            type: "gateway",
                            resourceId: "saved",
                            queryString: getGatewayQueryString
                        };
                        const queryData = await cloudFunctionV2(gatewayURL, gatewayData);
                        if ( queryData.status === 200 ) {
                            const pastSearches = queryData.body;
                            const newCategoryCount = [zpidArray.length, 0, 0, 0, 0];
                            const newSearches = [];

                            for (let index = 0; index < pastSearches.length; index++) {
                                const element = pastSearches[index];

                                if ( element.address === null ) {
                                    continue;
                                }
            
                                if ( element.propertyId !== undefined ) {
                                    pastSearches[index].zpid = element.propertyId;
                                }
                                
                                if ( element.images !== null && element.images.length > 0 ) {
                                    pastSearches[index].imgSrc = element.images[0];
                                }

                                element.status = pastListings[index].status;
                                element.amendments = pastListings[index].amendments;
                                if ( element.status === "active" ) {
                                    newCategoryCount[1]++;
                                }
                                else if ( element.status === "under-contract" ) {
                                    newCategoryCount[2]++;
                                }
                                else if ( element.status === "sold" ) {
                                    newCategoryCount[3]++;
                                }
                                else if ( element.status === "archive" ) {
                                    newCategoryCount[4]++;
                                }

                                if ( element.status === "sold" && pastListings[index].soldPrice !== undefined ) {
                                    element.price = pastListings[index].soldPrice;
                                }

                                if ( element.amendments.arvCompsStats !== null ) {
                                    element.arvComps = true;
                                    element.arvCompsStats = element.amendments.arvCompsStats;
                                }

                                if ( element.amendments.bedrooms !== null ) {
                                    element.bedrooms = element.amendments.bedrooms;
                                }

                                if ( element.amendments.bathrooms !== null ) {
                                    element.bathrooms = element.amendments.bathrooms;
                                }

                                if ( element.amendments.livingArea !== null ) {
                                    element.livingArea = element.amendments.livingArea;
                                }

                                if ( element.amendments.price !== null ) {
                                    element.price = element.amendments.price;
                                }

                                if ( element.amendments.customImages !== undefined && element.amendments.customImages !== null && element.amendments.customImages.length !== 0 ) {
                                    const customImages = element.amendments.customImages;
                                    const newImages = [];
                                    for (let index = 0; index < customImages.length; index++) {
                                        const element = customImages[index].url;
                                        newImages.push(element);
                                    }
                                    element.images = newImages;
                                    if ( newImages.length !== 0 ) {
                                        element.imgSrc = newImages[0];
                                    }
                                }
            
                                if ( element.zpid !== undefined ) {
                                    const zpidIndex = pastListings.findIndex(e => e.zpid === element.propertyId);
                                    if ( zpidIndex !== -1 ) {
                                        element.encodedAddress = pastListings[zpidIndex].encodedAddress;
                                    }
                                    else {
                                        pastSearches.splice(index, 1);
                                    }   
                                };

                                newSearches.push(element);
                            }
                            setCategoryCount(newCategoryCount);
                            pastSearches.reverse();
                            setListings(newSearches);
                            setLoading(false);
                        }
                    }
                    else {
                        setLoading(false);
                    }
                }
                else {
                    navigate("/partner/setup");
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        const getUser = async(userId) => {
            const colRef = "Users";
            const docRef = userId;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                setUserData(queryData.data.data);
            }
        };

        const formatPhoneNumber = (value) => {
            if (!value) setPhoneNumber(value); 

            const currentValue = value.replace(/[^\d]/g, '');
            const cvLength = currentValue.length; 
        
            if (!phoneNumber || value.length > phoneNumber.length) {
                if (cvLength < 4) setPhoneNumber(currentValue);
            
                if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
            
                setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
            }
        }

        fetchData();
    }, [phoneNumber, navigate]);

    const onChangeFile = async(event) => {
        event.stopPropagation();
        event.preventDefault();
        if ( event.target.files.length === 0 ) return;
        const file = URL.createObjectURL(event.target.files[0]);
		const type = event.target.files[0].type;
		const user = await checkUserId();
		const userId = user.userId;

		if ( userId === "" ) {
			navigate("/sign-up");
		}
		else if ( type.includes("image") ) {
            setProfilePicError("");
			const savePath = `Users/${userId}/Profile Image`;
			const uploading = await uploadImage(file, savePath, setProfilePicLoading);
			const uploadedFile = {
				title: event.target.files[0].name,
				url: uploading
			};
			saveFile(uploadedFile, userId);
        }
        else {
            setProfilePicError("Please select an image!");
        }
	}

    const saveFile = async(uploadedFile, userId) => {
        const colRef = "Partners";
        const docRef = userId;
        const queryData = await getDocument(colRef, docRef);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            data.profileImage = uploadedFile.url;
            await setData(colRef, docRef, data);
            setPartnerData(data);
        }

        const userColRef = "Users";
        const userDocRef = userId;
        const userQueryData = await getDocument(userColRef, userDocRef);
        if ( userQueryData.status === 200 ) {
            const data = userQueryData.data.data;
            data.profileImage = uploadedFile;
            await setData(userColRef, userDocRef, data);
        }
    };

    // const toggleView = () => {
    //     setListView(!listView);
    // };

    const addListing = () => {
        navigate("/partner/add-listing");
    };

    const changeOption = (index) => {
        setOption(index);
    };

    const openEditProfile = () => {
        setEditProfile(true);
        recordEvent("Partner Edit Profile Opened", {});
    };

    const editProperty = (zpid) => {
        const listingIndex = listings.findIndex(e => e.zpid === zpid);
        if ( listingIndex !== -1 ) {
            navigate("/partner/edit-listing", {
                state: {
                    property: listings[listingIndex],
                    partnerData: partnerData,
                    userData: userData,
                    edit: true,
                    amendments: listings[listingIndex].amendments
                }
            });
        }
        else {
            return;
        }
    };

    const soldProperty = (index) => {
        recordEvent("Mark As Sold", {});
        navigate("/partner/sold", {
            state: {
                property: listings[index],
                partnerData: partnerData,
                amendments: partnerData.listings.length === 0 ? null : partnerData.listings[index].amendments
            }
        });
    };

    const closeModal = () => {
        setEditProfile(false);
        recordEvent("Partner Edit Profile Closed", {});
    };

    const changeStatus = (newListings) => {

        const newCategoryCount = [0, 0, 0, 0, 0];
        for (let index = 0; index < newListings.length; index++) {
            const element = newListings[index];
            newCategoryCount[0]++;

            if ( element.status === "active" ) {
                newCategoryCount[1]++;
            }
            else if ( element.status === "under-contract" ) {
                newCategoryCount[2]++;
            }
            else if ( element.status === "sold" ) {
                newCategoryCount[3]++;
            }
            else if ( element.status === "archive" ) {
                newCategoryCount[4]++;
            }
        };
        setCategoryCount(newCategoryCount);
    };

    const clickExitScreen = () => {
        setShowExitScreen(false);
        setChooseStatus(false);
    };

    const openStatus = (zpid) => {
        setChooseStatus(!chooseStatus);

		if ( !chooseStatus === true ) {
			setShowExitScreen(true);
            setChooseStatusZPID(zpid);
		}
		else {
			setShowExitScreen(false);
            setChooseStatusZPID(null);
		}
    };

    const selectOption = (index, property) => {
        const propertyClone = structuredClone(property);
        propertyClone.status = propertyStatuses[index].value;
        const listingsClone = listings.slice(0);
        for (let index = 0; index < listingsClone.length; index++) {
            const element = listingsClone[index];
            if ( element.zpid === propertyClone.zpid ) {
                listingsClone[index] = propertyClone;
            }
        }
        setListings(listingsClone);
        changePartnerPropertyOption(propertyClone, listingsClone);
    };

    const changePartnerPropertyOption = async(val, newListings) => {
        recordEvent("Change Property Status", {
            zpid: val.zpid,
            status: val.status
        });
		
        const index = newListings.findIndex(e => e.zpid === val.zpid);
		if ( val.status === "sold" ) {
            recordEvent("Mark As Sold");
			navigate("/partner/sold", {
				state: {
					property: val,
					partnerData: partnerData,
					amendments: newListings.length === 0 ? null : newListings[index].amendments
				}
			});
		}
		else {
			await changePartnerPropertyStatus(val, partnerData, navigate);
			changeStatus(newListings);
		}
	};

    return (
        <div className="dashboard-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
            />
            <div className="dashboard-outer-row">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="dashboard-inner-container dashboard-inner-container-height">
                    {
                        partnerData === null ?
                            <LoadingStatic />
                        :
                        <div className="dashboard-inner-body-container">
                            {
                                showExitScreen === true ?
                                <ExitScreen
                                    clickScreen={clickExitScreen}
                                />
                                :
                                null
                            }
                            {
                                editProfile === true ?
                                <EditProfileModal
                                    partnerData={partnerData}
                                    phoneNumber={phoneNumber}
                                    closeModal={closeModal}
                                    setPartnerData={setPartnerData}
                                />
                                :
                                null
                            }
                            <PartnerBanner
                                partnerData={partnerData}
                                phoneNumber={phoneNumber}
                                profilePicLoading={profilePicLoading}
                                profilePicError={profilePicError}
                                textInput={textInput}
                                openEditProfile={openEditProfile}
                                onChangeFile={onChangeFile}
                                editMode={true}
                                property={null}
                                state={state}
                            />
                            <div className="dashboard-body-container">
                                <div className="dashboard-body-top-container margin-large">
                                    <h3 className="heading-small-semibold colour-primary">
                                        Listings
                                    </h3>
                                    <div className="dashboard-body-button-container">
                                        <ActionButton
                                            onClick={() => addListing()}
                                        >
                                            Add listing
                                        </ActionButton>
                                    </div>
                                </div>
                                <div className="dashboard-listing-options-container margin-large">
                                    <div className="dashboard-listings-left-container">
                                        {/* <div className="dashboard-list-grid-toggle">
                                            <img
                                                src={listView === true ? Grid : List}
                                                className="dashboard-list-grid-toggle-image"
                                                alt={listView === true ? "Grid" : "List"}
                                                onClick={() => toggleView()}
                                            />
                                        </div> */}
                                        {
                                            chipOptions.map((item, index) =>
                                                <Chip
                                                    label={item}
                                                    func={changeOption}
                                                    index={index}
                                                    selected={option}
                                                    key={index}
                                                    recommended={false}
                                                    hover={null}
                                                    leave={null}
                                                    disabled={false}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="dashboard-listings-right-container">

                                    </div>
                                </div>
                                {
                                    loading === true ?
                                    <LoadingStatic />
                                    :
                                    listings.length === 0 || (categoryCount[1] === 0 && option === 1) ?
                                    <div className="dashboard-no-listings-container">
                                        <h4 className="heading-small-semibold colour-primary margin-medium">
                                            No listings yet 🤨
                                        </h4>
                                        <h5 className="body-regular colour-secondary margin-x-small">
                                            Woah, are we closing deals or what??
                                        </h5>
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => addListing()}
                                        >
                                            Add a listing now
                                        </span>
                                    </div>
                                    :
                                    <div className="dashboard-listings-grid">
                                        {
                                            listings.map((item, index) =>
                                                option === 0 || (option !== 0 && item.status === chipOptionsValues[option]) ?
                                                <NewPropertyCard
                                                    item={item}
                                                    index={index}
                                                    key={index}
                                                    userDetails={null}
                                                    formatter={formatterLong}
                                                    abstract={true}
                                                    viewedProperties={[]}
                                                    setViewedProperties={null}
                                                    cityId={""}
                                                    setHoverCard={null}
                                                    propertyRefs={null}
                                                    onboarding={null}
                                                    onboardingStep={null}
                                                    setOnboardingStep={null}
                                                    setCardClick={null}
                                                    temporaryId={null}
                                                    setSignUpModal={null}
                                                    overlay={null}
                                                    setOverlay={null}
                                                    propertySearch={false}
                                                    ipUser={false}
                                                    noUserId={""}
                                                    partnerView={true}
                                                    partnerEditProperty={editProperty}
                                                    partnerSoldProperty={soldProperty}
                                                    partnerData={partnerData}
                                                    changeStatus={changeStatus}
                                                    setShowExitScreen={setShowExitScreen}
                                                    selectOption={selectOption}
                                                    openStatus={openStatus}
                                                    chooseStatus={chooseStatus === true && chooseStatusZPID === item.zpid ? chooseStatus : false}
                                                    state={null}
                                                    favourites={[]}
                                                    setFavourites={null}
                                                    arrowClicked={null}
                                                    imageLimit={10}
                                                />
                                                :
                                                null
                                            )
                                        }
                                    </div>
                                }
                            </div> 
                    </div>
                }
                </div>
            </div>
            <Footer
                state={state}
            />
        </div>
    )
}

export default Dashboard;