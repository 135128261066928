import React, { useEffect, useState } from 'react';
import '../styles/InteractiveMap.css';
import { GoogleMap, useLoadScript, OverlayView, Polygon, OverlayViewF } from '@react-google-maps/api';
import CoffeeIcon from '../assets/coffee-icon.svg';
import { useSelector } from 'react-redux';
import { colour, mapColours } from '../styles/GlobalStyles';
import { OldMapLegend } from './';

function LocationGentrificationMap(props) {
    const property = props.property;
    const controls = props.controls;
    const text = props.text;
    const gentrificationData = props.gentrificationData;
    const bounds = props.gentrificationBounds;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});

	const profit = useSelector(state => state.profit);

	const centre = ({ lat: property.latitude, lng: property.longitude });
	const [renderOverlay, setRenderOverlay] = useState(false);

	const options = {
		disableDefaultUI: controls,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false
	}

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}
	}, [renderOverlay]);

	const checkGentrificationStyles = (index) => {
		const stylVal = gentrificationData[index].gent_label === 1 ? styles.little : gentrificationData[index].gent_label === 2 ? styles.early : gentrificationData[index].gent_label === 3 ? styles.inProgress : styles.gentrified;
		return stylVal;
	}

	const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		little: {
			fillColor: mapColours.mapColorsMapGentrificationLittleNone,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		littleHover: {
			fillColor: mapColours.mapColorsMapGentrificationLittleNone,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
        early: {
			fillColor: mapColours.mapColorsMapGentrificationEarlySigns,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		earlyHover: {
			fillColor: mapColours.mapColorsMapGentrificationEarlySigns,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
        inProgress: {
			fillColor: mapColours.mapColorsMapGentrificationInProgress,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inProgressHover: {
			fillColor: mapColours.mapColorsMapGentrificationInProgress,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
        gentrified: {
			fillColor: mapColours.mapColorsMapGentrificationGentrified,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		gentrifiedHover: {
			fillColor: mapColours.mapColorsMapGentrificationGentrified,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		}
	};

	return (
		<div className="location-gentrification-map-container">
			<OldMapLegend
				selectedMapType={0}
				zipData={[]}
				searchParams={null}
				setSearchParams={null}
				cityId={""}
				setJustLoaded={null}
				setShowResetButton={null}
				gentrificationScores={gentrificationData}
				mapSidePanelOpen={false}
				splitMap={true}
				propertyPage={true}
				offMarket={false}
				setLoading={null}
				setPropertiesOnPageChange={null}
				strategy={null}
			/>
			{
				!isLoaded ? "Loading"
				:
				<GoogleMap
					zoom={11}
					center={centre}
					mapContainerClassName="location-map-container"
					options={options}
				>
					{
						renderOverlay === true ?
						bounds.map((item, index) =>
							<Polygon
								editable={false}
								paths={item}
								options={checkGentrificationStyles(index)}
								key={index}
							/>
						)
						:
						null
					}
					{
						renderOverlay === true ?
							<OverlayViewF
								position={{ lat: property.latitude, lng: property.longitude }}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							>
								<div 
									className="location-map-overlay"
								>
									<span>
										{
											text === true && profit !== "" ? 
											`${profit.cashOnCash.toFixed(2)}%`
											:
											null
										}
										<img
											src={CoffeeIcon}
											className="interactive-map-coffee-icon"
											alt="Coffee"
										/> 
									</span>
								</div>
							</OverlayViewF>	
						:
						null
					}
				</GoogleMap>
			}
		</div>
	)
}

export default LocationGentrificationMap;