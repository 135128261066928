import React from 'react';
import { ADUBadge, AddBedroomBadge, CashOnlyBadge, DripScoreBadge, FixerUpperBadge, SellerFinancingBadge, TopPickBadge } from './';
import '../styles/PropertyBadgesRow.css';

function PropertyBadgesRow(props) {
	const property = props.property;
	const strategy = props.strategy;
	const partner = props.partner;
	return (
		<div className="property-badges-row-outer-container">
			{
				property.onMarket !== false && property.dripScore !== undefined && property.dripScore !== null && partner !== true ?
				<div className="property-badges-row-element">
					<DripScoreBadge
						score={
							strategy === null || property.dripScoreOptions === undefined || property.dripScoreOptions === null ?
							property.dripScore
							:
							strategy === 0 ?
							property.dripScoreOptions.traditional
							:
							strategy === 1 ?
							property.dripScoreOptions.houseHacking
							:
							strategy === 2 ?
							property.dripScoreOptions.brrrr
							:
							strategy === 3 ?
							property.dripScoreOptions.fixAndFlip
							:
							property.dripScore
						}
					/>
				</div>
				:
				null
			}
			{
				(property.topPickOptions !== undefined && strategy === 0 && property.topPickOptions.traditional === true) ||
				(property.topPickOptions !== undefined && strategy === 1 && property.topPickOptions.houseHacking === true) ||
				(property.topPickOptions !== undefined && strategy === 2 && property.topPickOptions.brrrr === true) ||
				(property.topPickOptions !== undefined && strategy === 3 && property.topPickOptions.fixAndFlip === true)
				?
				<div className="property-badges-row-element">
					<TopPickBadge />
				</div>
				:
				(property.topPickOptions === undefined && property.topPick === true) || (strategy === null && property.topPick === true) ?
				<div className="property-badges-row-element">
					<TopPickBadge />
				</div>
				:
				null
			}
			{
				property.fixerUpper === true && partner !== true ?
				<div className="property-badges-row-element">
					<FixerUpperBadge />
				</div>
				:
				null
			}
			{
				property.cashOnly === true && partner !== true ?
				<div className="property-badges-row-element">
					<CashOnlyBadge />
				</div>
				:
				null
			}
			{
				property.addBedOpportunity === true ?
				<div className="property-badges-row-element">
					<AddBedroomBadge />
				</div>
				:
				null
			}
			{
				property.zonedForAdu === true ?
				<div className="property-badges-row-element">
					<ADUBadge />
				</div>
				:
				null
			}
			{
				property.ownerFinancingFlag === true ?
				<div className="property-badges-row-element">
					<SellerFinancingBadge />
				</div>
				:
				null
			}
		</div>
	)
}

export default PropertyBadgesRow;