import React from 'react';
import '../styles/DownloadSelector.css';
import { LockBlue } from '../assets';

function DownloadSelector(props) {
    const downloadCSV = props.downloadCSV;
    // const clickDownloadPDF = props.clickDownloadPDF;
    const buyBox = props.buyBox;
    const editBuyBox = props.editBuyBox;
    const deleteBuyBox = props.deleteBuyBox;
    const item = props.item;
    const setDeleteParam = props.setDeleteParam;
    const downloadable = props.downloadable;

    const clickDelete = () => {
        setDeleteParam(item);
        deleteBuyBox(item);
    };

    return (
        <div className="download-selector-outer-container bg-colour-white">
            <div 
                className={"download-selector-element-container mobile-none " + (downloadable === false ? "download-selector-disabled" : "")}
                onClick={() => downloadable === false ? null : downloadCSV()}
            >
                <span className="body-regular colour-primary">
                    Download CSV
                </span>
                {
                    downloadable === false ?
                    <img
                        src={LockBlue}
                        className="download-selector-lock"
                        alt="Lock"
                    />
                    :
                    null
                }
            </div>
            {/* {
                buyBox === false ?
                <div 
                    className="download-selector-element-container"
                    onClick={() => clickDownloadPDF()}
                >
                    <span className="body-regular colour-primary">
                        Download PDF
                    </span>
                </div>
                :
                null
            } */}
            {
                buyBox === true ?
                <div 
                    className="download-selector-element-container"
                    onClick={() => editBuyBox(item)}
                >
                    <span className="body-regular colour-primary">
                        Edit
                    </span>
                </div>
                :
                null
            }
            {
                buyBox === true ?
                <div 
                    className="download-selector-element-container"
                    onClick={() => clickDelete(item)}
                >
                    <span className="body-regular colour-error">
                        Delete
                    </span>
                </div>
                :
                null
            }
        </div>
    )
};

export default DownloadSelector;