import React from 'react';
import '../styles/Schools.css';
import { ProgressBar } from './';

function Schools({ property }) {
    return (
        <div className="schools-outer-container">
            <div className="schools-inner-container">
                <h2 className="heading-small-semibold colour-primary">
                    Schools
                </h2>
                <div className="schools-subheading-container">
                    <h3 className="body-regular text-primary">
                        {property.address.city} School District
                    </h3>
                    <div className="schools-body-container">
                        {
                            property.schools === undefined || property.schools === null || Object.keys(property.schools).length === 0 ?
                            null
                            :
                            property.schools.map((item, index) =>
                                <div 
                                    className="schools-element-container"
                                    key={index}
                                >
                                    <div className="schools-element-left-container">
                                        <h4 className="body-regular colour-primary">
                                            {item.name}
                                        </h4>
                                        <div className="schools-element-text-row">
                                            <span className="body-regular colour-secondary">
                                                Grades {item.grades}
                                            </span>
                                            {
                                                item.distance !== undefined && item.distance !== null ?
                                                <span className="body-regular colour-secondary dot-spacing">
                                                    •
                                                </span>
                                                :
                                                null
                                            }
                                            {
                                                item.distance !== undefined && item.distance !== null ?
                                                <span className="body-regular colour-secondary">
                                                    Distance: {item.distance} mi
                                                </span>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="schools-element-right-container">
                                        <ProgressBar
                                            item={item}
                                        />
                                        <span className="label-semibold colour-primary schools-score-text">
                                            {item.rating}/10
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schools;