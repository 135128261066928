import React from 'react';
import '../styles/AddCity.css';
import { Arrow } from '../assets';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkWhichAddCityPath, recordEvent } from '../functions';

function AddCity(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const freeTrial = props.freeTrial;
    const subscriptions = props.subscriptions;

    const addCity = async() => {
        const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
        const chosenPath = checkPath.path;
        const newState = checkPath.state;

        navigate(chosenPath, {
            state: newState === null ? state : newState
        });
        recordEvent("Add City", {});
    };

    return (
        <div 
            className="add-city-container text-button"
            onClick={() => addCity()}
        >
            <span className="body-regular text-link block-text subtext-margin-right">
                Add a market
            </span>
            <img
                src={Arrow}
                className="add-city-arrow"
                alt="Add a market"
            />
        </div>
    )
};

export default AddCity;