import React, { useState, useEffect } from 'react';
import '../styles/FreeCredits.css';
import { Header, Footer, Loading } from '../components';
import { ActionButton, NewInput } from '../styles/GlobalStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDocument, makeId, recordEvent, sendEmail, setData, checkOnAuthStateChanged } from '../functions';

function FreeCredits() {
    const [postURL, setPostURL] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        document.title = "Free Credits | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const colRef = "Users";
				const docRef = user.userId;
                setUserId(user.userId);
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    setLoading(false);
                    setUserData(queryData.data.data);
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        fetchUserData();
    }, [navigate]);

    const openBPLink = () => {
        recordEvent("Open BP Link");
        const link = "https://www.biggerpockets.com/topics/new";
        window.open(link, "_blank");
    };

    const viewPricing = () => {
        recordEvent("View Pricing");
        navigate("/pricing", {
            state: state
        });
    };

    const backToSearch = () => {
        recordEvent("Back to Search", {});
        navigate("/quick-report");
    };

    const complete = async() => {
        const contactEmail = "info@coffeeclozers.com";
        const dynamicTemplate = "d-e7f74888d87c438ca458177049f8175f";
        if ( postURL.includes("biggerpockets.com/forums") ) {
            setLoading(true);
            const saveObject = {
                userId: userId,
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                link: postURL,
                date: new Date()
            };

            const colRef = "Free Credit Posts";
            const docRef = await makeId(15);
            const saveData = await setData(colRef, docRef, saveObject);
            if ( saveData.status === 200 ) {
                recordEvent("Free Credit Post Submitted", {
                    userId: userId,
                    link: postURL
                });

                const msg = {
                    to: ["liam.maher@coffeeclozers.com", "ariel.herrera@coffeeclozers.com", "maddy@coffeeclozers.com"],
                    from: contactEmail,
                    templateId: dynamicTemplate,
                    dynamic_template_data: {
                        userId: userId,
                        email: userData.email,
                        link: postURL,
                        firstName: userData.firstName,
                        lastName: userData.lastName
                    }
                };
                await sendEmail(msg);
                setError("");
                setLoading(false);
                window.scrollTo(0, 0);
                setDone(true);
            }
        }
        else {
            setError("Hmm, it doesn't look like this links to a BiggerPockets post 🤔");
        }
    };

    return (
        <div className="free-credits-outer-container">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
            />
            {
                done === true ?
                <div className="free-credits-inner-container relative-container">
                    <div className="free-credits-title-container margin-large">
                        <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                            Thanks for posting about us!
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            We'll credit your account ASAP with your new credits and send you an email once it's done 😊
                        </span>
                    </div>
                    <div className="free-credits-done-button">
                        <ActionButton
                            onClick={() => backToSearch()}
                        >
                            Back to search
                        </ActionButton>
                    </div>
                </div>
                :
                <div className="free-credits-inner-container relative-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    <div className="free-credits-title-container margin-large">
                        <h1 className="heading-large-semibold colour-primary text-align-center">
                            Get FREE credits 👀
                        </h1>
                    </div>
                    <div className="free-credits-video-container margin-large">
                        <iframe 
                            width="100%" 
                            height="315" 
                            src="https://www.youtube.com/embed/xyuRDjMud7g?si=6awi82mORc0ssl4H"
                            title="Coffee Clozers Free Credits"
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                    <div className="">
                        <h2 className="heading-small-semibold text-link text-align-center">
                            🎉 5 Free credits 🎉
                        </h2>
                    </div>
                    <div className="free-credits-body-container margin-x-x-large">
                        <div className="free-credits-element-container">
                            <span className="label-semibold-caps text-link block-text margin-medium">
                                STEP 1
                            </span>
                            <p className="body-regular colour-primary">
                                Mention us in a new a post on BiggerPockets <span onClick={() => openBPLink()} className="body-regular-underline colour-link">by clicking here</span>.
                                Feel free to copy the example below, or come up with your own!
                            </p>
                        </div>
                        <div className="free-credits-separator margin-tops">
                        </div>
                        <div className="free-credits-post-container">
                            <div className="free-credits-post-element-container">
                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                    TITLE
                                </span>
                                <h3 className="body-semibold colour-primary">
                                    Software for Analyzing deals?
                                </h3>
                            </div>
                            <div className="free-credits-post-element-container">
                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                    POST
                                </span>
                                <p className="body-regular colour-primary">
                                    What software do you guys use for analyzing deals? I’ve been using Coffee Clozers 
                                    in the last week which seems to work pretty well but I’m not sure about what’s best. 
                                    Anyone have any strong opinions about this?
                                </p>
                            </div>
                        </div>
                        <div className="free-credits-separator margin-tops">
                        </div>
                        <div className={"free-credits-element-container " + (error === "" ? "margin-medium" : "")}>
                            <span className="label-semibold-caps text-link block-text margin-medium">
                                STEP 2
                            </span>
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Link to your post
                            </h3>
                            <div className="free-credits-input-container">
                                <NewInput
                                    value={postURL}
                                    type="text"
                                    label=""
                                    placeholder="Insert link to post"
                                    id={`free-credits-url`}
                                    onChange={(text) => setPostURL(text.target.value)}
                                />
                            </div>
                        </div>
                        {
                            error !== "" ?
                            <div className="free-credits-error-container margin-x-small">
                                <span className="body-regular colour-error">
                                    {error}
                                </span>
                            </div>
                            :
                            null
                        }
                        <div className="free-credits-button-container">
                            <ActionButton
                                disabled={postURL === "" || userData === null || loading === true ? true : false}
                                onClick={() => complete()}
                            >
                                Submit
                            </ActionButton>
                        </div>
                        <div className="free-credits-separator margin-tops">
                        </div>
                        <div className="free-credits-bottom-message">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                Need more credits?
                            </h3>
                            <span className="body-regular colour-secondary block-text text-align-center margin-x-small">
                                Upgrade to any paid plan to get 50 credits 
                                nationwide for on and off-market properties
                            </span>
                            <div 
                                className="text-button button-row"
                                onClick={() => viewPricing()}
                            >
                                <span className="body-regular colour-link block-text subtext-margin-right">
                                    View pricing
                                </span>
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 16 16" 
                                    fill="none"
                                >
                                    <path 
                                        d="M15.1521 7.28845L8.74807 0.884462C8.35509 0.491482 7.71794 0.491482 7.32496 0.884462C6.93198 1.27744 6.93198 1.91459 7.32496 2.30757L12.0111 6.99371L1.56 6.99371C1.00424 6.99371 0.553711 7.44424 0.553711 8C0.553711 8.55576 1.00424 9.00629 1.56 9.00629L12.0111 9.00629L7.32496 13.6924C6.93198 14.0854 6.93198 14.7226 7.32496 15.1155C7.71794 15.5085 8.35509 15.5085 8.74807 15.1155L15.1521 8.71156C15.545 8.31858 15.545 7.68143 15.1521 7.28845Z" 
                                        fill="#2F4858"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer/>
        </div>
    )
};

export default FreeCredits;