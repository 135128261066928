import React, { useState } from 'react';
import '../styles/ComparePropertyFinancials.css';
import { Accordion, AccordionDetails, AccordionSummary, colour } from '../styles/GlobalStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ComparePropertyFinancials(props) {
    const [expanded, setExpanded] = useState(true);
    const propertyFinancials = props.propertyFinancials;
    const positiveArray = props.positiveArray;

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const checkColour = (index, object, parentIndex) => {
        if ( index === 0 ) {
            return "colour-error"
        }
        else {
            if ( positiveArray[parentIndex] === true ) {
                return "colour-green"
            }
            else {
                return "colour-error"
            }
        }
    };

    const styles = {
		accordionSummary: {
			width: '100px',
			boxShadow: 'none', 
			padding: 0,
			margin: '0 auto'
		},
		typography: {
			flexShrink: 0, 
			fontFamily: "Rubik",
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '28px',
			letterSpacing: '0em',
			textAlign: 'left',
			color: colour.blueBlue03
		}
	};

    return (
        <div className="compare-property-financials-outer-container">
            <div className={"compare-property-financials-inner-container " + (expanded === false ? "compare-property-financials-inner-container-bottom-padding" : "")}>
                <Accordion 
                    expanded={expanded} 
                    onChange={handleChange}
                    sx={{ boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-2-bh-content`}
                        id={`panel-2-bh-header`}
                        sx={styles.accordionSummary}
                    >
                        <h2 className="heading-large-semibold colour-link">
                            Financials
                        </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="compare-property-financials-body-container">
                            {
                                propertyFinancials.map((item, index) =>
                                    <div
                                        className="compare-property-financials-category"
                                        key={index}
                                    >
                                        {
                                            item.label !== "" ?
                                            <div className="compare-property-financials-category-title-container">
                                                <h3 className="heading-small-semibold text-link">
                                                    {item.label}
                                                </h3>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="compare-property-financials-row-container">
                                            {
                                                item.properties.map((property, i) =>
                                                    <div 
                                                        className="compare-property-financials-subcategory-element"
                                                        key={i}
                                                    >
                                                        <div className="compare-property-financials-subcategory-label-container">
                                                            <h4 className="body-regular colour-secondary">
                                                                {property.label}
                                                            </h4>
                                                        </div>
                                                        <div className="compare-property-financials-right-container">
                                                            {
                                                                property.value.map((subItem, i2) =>
                                                                    <div 
                                                                        className="compare-property-financials-right-element"
                                                                        key={i2}
                                                                    >
                                                                        {
                                                                            typeof(subItem.value) === "object" ?
                                                                            <div className="compare-property-financials-abstract-row">
                                                                                {
                                                                                    subItem.value.map((bItem, i3) =>
                                                                                        <div 
                                                                                            className="compare-property-financials-abstract-element"
                                                                                            key={i3}
                                                                                        >
                                                                                            <h5 className="label-semibold-caps colour-primary">
                                                                                                {subItem.label[i3]}
                                                                                            </h5>
                                                                                            <span className={"heading-large-semibold " + (checkColour(i3, subItem.value, i2))}>
                                                                                                {bItem}
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <span className="body-regular colour-primary">
                                                                                {subItem.value}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default ComparePropertyFinancials;