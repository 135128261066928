import React from 'react';
import '../styles/ToastMessage.css';

function ToastMessage(props) {
    const text = props.text;
    const close = props.close;
    const active = props.active;
    return (
        <div className={"toast-message-outer-container " + (active === true ? "toast-message-slide-in" : active === false ? "toast-message-slide-out" : "toast-message-inactive")}>
            <div className="toast-message-inner-container">
                <div className="toast-message-icon-container subtext-margin-right-large">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 16 16" 
                        fill="none"
                    >
                        <path 
                            d="M7.61547 4.9648C7.65031 4.92995 7.69262 4.91253 7.7424 4.91253H8.36213C8.41689 4.91253 8.4592 4.92995 8.48907 4.9648C8.52391 4.99964 8.54133 5.04195 8.54133 5.09173V8.616C8.54133 8.66578 8.52391 8.70809 8.48907 8.74293C8.4592 8.77778 8.41689 8.7952 8.36213 8.7952H7.7424C7.69262 8.7952 7.65031 8.77778 7.61547 8.74293C7.58062 8.70809 7.5632 8.66578 7.5632 8.616V5.09173C7.5632 5.04195 7.58062 4.99964 7.61547 4.9648Z" 
                            fill="#FFFEFC"
                        />
                        <path 
                            d="M7.57813 10.3571C7.61298 10.3273 7.65778 10.3123 7.71253 10.3123H8.392C8.44676 10.3123 8.49156 10.3273 8.5264 10.3571C8.56124 10.392 8.57867 10.4368 8.57867 10.4915V11.0291C8.57867 11.0789 8.56124 11.1212 8.5264 11.1561C8.49156 11.1909 8.44676 11.2083 8.392 11.2083H7.71253C7.65778 11.2083 7.61298 11.1909 7.57813 11.1561C7.54827 11.1212 7.53333 11.0789 7.53333 11.0291V10.4915C7.53333 10.4368 7.54827 10.392 7.57813 10.3571Z" 
                            fill="#FFFEFC"
                        />
                        <path
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1ZM14.0667 8C14.0667 4.64947 11.3505 1.93333 8 1.93333C4.64947 1.93333 1.93333 4.64947 1.93333 8C1.93333 11.3505 4.64947 14.0667 8 14.0667C11.3505 14.0667 14.0667 11.3505 14.0667 8Z" 
                            fill="#FFFEFC"
                        />
                    </svg>
                </div>
                <div className="toast-message-text-container subtext-margin-right-large">
                    <span className="body-regular colour-white">
                        {text}
                    </span>
                </div>
                <div className="toast-message-close-container">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="22" 
                        height="24" 
                        viewBox="0 0 22 24" 
                        fill="none"
                        className="toast-message-close-icon"
                        onClick={() => close()}
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25829 0.419021C1.7903 -0.100741 0.989564 -0.142706 0.469802 0.32529C-0.0499597 0.793286 -0.0919245 1.59402 0.376071 2.11378L9.2968 12.0213L0.414356 21.8862C-0.0536387 22.406 -0.0116752 23.2067 0.508087 23.6747C1.02785 24.1427 1.82858 24.1007 2.29658 23.581L11.0009 13.9138L19.7052 23.5809C20.1732 24.1007 20.9739 24.1427 21.4937 23.6747C22.0134 23.2067 22.0554 22.4059 21.5874 21.8862L12.705 12.0213L21.6257 2.11382C22.0937 1.59406 22.0517 0.793322 21.532 0.325326C21.0122 -0.142669 20.2115 -0.100704 19.7435 0.419057L11.0009 10.1287L2.25829 0.419021Z" 
                            fill="#FFFEFC"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
};

export default ToastMessage;