import React, { useState } from 'react';
import '../styles/MobileFinancials.css';
import { IncomeNew, InitialCosts, RecurringCosts, Chip, RentComparison, RehabCalculator } from './';
import { ChevronUp } from '../assets';

function MobileFinancials(props) {
    const mobileBar = props.mobileBar;
    const recurringProfits = props.recurringProfits;
    const recurringCosts = props.recurringCosts;
    const toggleTooltip = props.toggleTooltip;
    const revenueTooltipShow = props.revenueTooltipShow;
    const revenueTooltipIndex = props.revenueTooltipIndex;
    const property = props.property;
    const onChangeRevenue = props.onChangeRevenue;
    const changeSlider = props.changeSlider;
    const customDiscount = props.customDiscount;
    const customPrice = props.customPrice;
    const onChangeLowball = props.onChangeLowball;
    const toggleAddBedroom = props.toggleAddBedroom;
    const addBedroom = props.addBedroom;
    const maxPercentage = props.maxPercentage;
    const resetRecurringCosts = props.resetRecurringCosts;
    const resetLowballOffer = props.resetLowballOffer;
    const downPayment = props.downPayment;
    const onChangeDownPayment = props.onChangeDownPayment;
    const interestRate = props.interestRate;
    const onChangeInterest = props.onChangeInterest;
    const closingPercentage = props.closingPercentage;
    const onChangeClosing = props.onChangeClosing;
    const renovationRadio = props.renovationRadio;
    const changeRenovationRadio = props.changeRenovationRadio;
    const formatter = props.formatter;
    const closingCost = props.closingCost;
    const onChangeRecurringCosts = props.onChangeRecurringCosts;
    const costsTooltipShow = props.costsTooltipShow;
    const costsTooltipIndex = props.costsTooltipIndex;
    const resetRecurringProfits = props.resetRecurringProfits;
    const blurred = props.blurred;
    const rentAccordion = props.rentAccordion;
    const rentalEstimates = props.rentalEstimates;
    const toggleRentAccordion = props.toggleRentAccordion;
    const togglePercentage = props.togglePercentage;
    const onChangeMFHRents = props.onChangeMFHRents;
    const recurringUnits = props.recurringUnits;
    const profitChanges = props.profitChanges;
    const monthlyCostsChanges = props.monthlyCostsChanges;
    const costPerSqFoot = props.costPerSqFoot;
    const setCostPerSqFoot = props.setCostPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const setTotalRehabCost = props.setTotalRehabCost;
    const changeRehabCost = props.changeRehabCost;
    const onChangeCostPerSqFoot = props.onChangeCostPerSqFoot;
    const onChangeTotalCost = props.onChangeTotalCost;
    const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
    const partnerData = props.partnerData;

    const [selectedPanel, setSelectedPanel] = useState(0);
    const sidePanelElements = [
        {
            text: "Income"
        },
        {
            text: "Rehab"
        },
        {
            text: "Initial costs"
        },
        {
            text: "Monthly costs"
        }
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    }

    return (
        <div className={"mobile-financials-outer-container " + (mobileBar === true ? "mobile-financials-outer-container-show-overlay" : "mobile-financials-outer-container-hide-overlay")}>
            <div className="mobile-financials-inner-container">
                <h1 className="heading-small-semibold colour-primary">
                    Run the numbers
                </h1>

                <div className="mobile-financials-options-row">
                    {
                        sidePanelElements.map((item, index) =>
                            <Chip
                                label={item.text}
                                func={changeSelectedPanel}
                                index={index}
                                selected={selectedPanel}
                                key={index}
                                changeIcon={profitChanges === true && index === 0 ? true : costPerSqFoot !== defaultCostPerSqFoot && index === 1 ? true : customPrice !== property.price && index === 0 ? true : monthlyCostsChanges === true && index === 3 ? true : interestRate !== property.mortgage30us && index === 2 ? true : false}
                                recommended={false}
                                hover={null}
                                leave={null}
                                disabled={false}
                            />
                            // <li 
                            //     className={"mobile-financials-sidepanel-element " + (selectedPanel === index ? "body-semibold colour-primary" : "body-regular colour-secondary")}
                            //     key={index}
                            //     onClick={() => changeSelectedPanel(index)}
                            // >
                            //     {item.text}
                            // </li>
                        )
                    }
                    
                </div>
                {
                    selectedPanel === 0 ?
                    <div className="mobile-financials-income-container">
                        {
							recurringProfits.length !== 0 ?
                                <h3 className="body-regular colour-primary">
                                    Coffee Clozers rent estimate <span className="body-semibold">
                                        {
                                            blurred === true ?
                                            "$9,999"
                                            :
                                            formatter.format(Number(recurringProfits[0].original)).replace(".00", "")
                                        }
                                    </span>
                                </h3>
                            :
                            null
                        }
                        <div 
                            className="financials-panel-rent-estimate-compare-container margin-medium"
                            onClick={() => toggleRentAccordion()}
                        >
                            <span className="label-semibold colour-link underline">
                                {rentAccordion === true ? "Hide" : "Show"} comparison
                            </span>
                            <img
                                src={ChevronUp}
                                className={"financials-panel-rent-estimate-compare-chevron " + (rentAccordion === true ? "" : "reverse")}
                                alt="Comparison chevron"
                            />
                        </div>
                        <RentComparison
							rentAccordion={rentAccordion}
							rentalEstimates={rentalEstimates}
							formatter={formatter}
                            blurred={blurred}
                            partnerData={partnerData}
						/>
                        <IncomeNew
                            recurringProfits={recurringProfits}
                            toggleTooltip={toggleTooltip}
                            revenueTooltipShow={revenueTooltipShow}
                            revenueTooltipIndex={revenueTooltipIndex}
                            property={property}
                            onChangeRevenue={onChangeRevenue}
                            customDiscount={customDiscount}
                            changeSlider={changeSlider}
                            customPrice={customPrice}
                            onChangeLowball={onChangeLowball}
                            toggleAddBedroom={toggleAddBedroom}
                            addBedroom={addBedroom}
                            maxPercentage={maxPercentage}
                            resetRecurringProfits={resetRecurringProfits}
                            resetLowballOffer={resetLowballOffer}
                            blurred={blurred}
                            onChangeMFHRents={onChangeMFHRents}
                            recurringUnits={recurringUnits}
                        />
                    </div>
                    :
                    selectedPanel === 1 ?
                    <RehabCalculator
                        property={property}
                        costPerSqFoot={costPerSqFoot}
                        setCostPerSqFoot={setCostPerSqFoot}
                        totalRehabCost={totalRehabCost}
                        setTotalRehabCost={setTotalRehabCost}
                        changeRehabCost={changeRehabCost}
                        onChangeCostPerSqFoot={onChangeCostPerSqFoot}
                        onChangeTotalCost={onChangeTotalCost}
                        defaultCostPerSqFoot={defaultCostPerSqFoot}
                    />
                    :
                    selectedPanel === 2 ?
                    <InitialCosts
                        downPayment={downPayment}
                        onChangeDownPayment={onChangeDownPayment}
                        interestRate={interestRate}
                        onChangeInterest={onChangeInterest}
                        closingPercentage={closingPercentage}
                        onChangeClosing={onChangeClosing}
                        renovationRadio={renovationRadio}
                        changeRenovationRadio={changeRenovationRadio}
                        toggleTooltip={toggleTooltip}
                        property={property}
                        formatter={formatter}
                        closingCost={closingCost}
                        blurred={blurred}
                        customPrice={customPrice}
                    />
                    :
                    <RecurringCosts
                        recurringCosts={recurringCosts}
                        onChangeRecurringCosts={onChangeRecurringCosts}
                        resetRecurringCosts={resetRecurringCosts}
                        toggleTooltip={toggleTooltip}
                        costsTooltipShow={costsTooltipShow}
                        costsTooltipIndex={costsTooltipIndex}
                        blurred={blurred}
                        recurringProfits={recurringProfits}
                        togglePercentage={togglePercentage}
                        property={property}
                    />
                }
            </div>
        </div>
    )
}

export default MobileFinancials;