import React from 'react';

function TopPickFire({ toggleElement }) {
	return (
		<div 
			className="top-pick-element-fire-container"
			onClick={() => toggleElement()}
		>
			<span className="top-pick-element-fire-text">
				🔥
			</span>
		</div>
	)
}

export default TopPickFire;