import React, { useEffect, useState, useRef } from 'react';
import '../styles/BuyBoxSetup.css';
import { Footer, Header, ChoosePropertyType, ChooseBudget, ChoosePropertySpecifics, Loading, ChooseStrategy, EditNeighbourhoodGrade, ChooseCounty } from '../components';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftThick, Pin } from '../assets';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, makeId, recordEvent, setData, getCitySearch, timer, sendCityIDErrorEmail, cloudFunctionV2, savePreferences } from '../functions';

function BuyBoxSetup() {

    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [chosenMarket, setChosenMarket] = useState(null);
    const [propertyTypes, setPropertyTypes] = useState([
        {
            code: "MultiFamily",
            included: false,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: false,
            property: "Single-Family",
            abbreviation: "SFH"
        }
    ]);
    const [units, setUnits] = useState(1);
    const [budgetRange, setBudgetRange] = useState([0, 1000000000]);
    const [bedrooms, setBedrooms] = useState(1);
	const [bathrooms, setBathrooms] = useState(1);
	const [squareFootage, setSquareFootage] = useState([0, 999999]);
	const [constructionYears, setConstructionYears] = useState(["", ""]);
    const [strategy, setStrategy] = useState("");
    const [mobileIntro, setMobileIntro] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [error, setError] = useState("");
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(1);
    const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(8);
    const [geographyType, setGeographyType] = useState(0);
    const [customCities, setCustomCities] = useState([]);
    const [customZipCodes, setCustomZipCodes] = useState([]);
    const [allZipCodes, setAllZipCodes] = useState([]);
    const [zipOptions, setZipOptions] = useState([]);
    const buyBoxSetupRef = useRef();

    const steps = [
        {
            title: "Choose a market",
            description: "Select the county that you'd like to apply this buy-box to"
        },
        {
            title: "Property type",
            description: "Which property types are you interested in?"
        },
        {
            title: "Budget",
            description: "Indicate a budget range."
        },
        {
            title: "Property specifics",
            description: "Let’s get specific! Filter by these property details."
        },
        {
            title: "Strategy",
            description: "Optionally choose a strategy to get search results tailored to you."
        },
        {
            title: "Location requirements",
            description: "Limit properties based on their neighborhood score."
        }
    ];

    const strategies = [
        {
            title: "Turnkey (Buy-and-hold)",
            description: "This is a long-term investment strategy of purchasing properties to hold and rent them for steady cash flow and potential appreciation.",
            text: "Turnkey",
            value: "turnkey"
        },
        {
            title: "BRRRR (Buy, Rehab, Rent, Refinance, Repeat)",
            description: "BRRRR is a real estate strategy involving buying undervalued properties, renovating for added value, renting out for income, refinancing to recover capital, and repeating the cycle.",
            text: "BRRRR",
            value: "brrrr"
        },
        {
            title: "Househacking",
            description: "Househacking means buying an investment property with the intention of living in one of the units or rooms (for at least one year) and renting out the others to cover expenses and generate income. This strategy allows the investor to make a much smaller down payment. We calculate this with a 5% down payment.",
            text: "Househacking",
            value: "househacking"
        },
        {
            title: "Fix & Flip",
            description: "Fix & flip is the short-term strategy of buying distressed properties, renovating them, and selling at a higher price to make a profit.",
            text: "Fix & Flip",
            value: "fix-flip"
        }
    ];

    useEffect(() => {
        document.title = "Buy Box Setup";
        const checkListings = async() => {
            const colRef = "Buy Boxes";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryDocument = await getDocument(colRef, userId);
                if ( queryDocument.status === 200 ) {

                }
            }
            else {
                navigate("/my-cities");
            }
        };

       if ( firstRender === false ) {
            checkListings();
            setFirstRender(true);
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);

    }, [navigate, firstRender]);

    const goBack = () => {
        if ( step === 1 ) {
            navigate("/my-cities");
            recordEvent("Back to 'Where To Invest' Page", {});
        }
        else if ( step === 1 ) {
            navigate("/my-cities");
            recordEvent("Back to 'My Cities' Page", {});
        }
        else {
            recordEvent(`Back to Step ${step}`, {});
            setStep(step - 1);
        }
    };

    const changeNeighbourhoodGrade = (val, index) => {
        if ( index === 0 ) {
            setNeighbourhoodGradeMin(val);
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

    const next = async() => {
        if ( step === 5 ) {
            complete();
        }
        else {
            if ( step === 3 ) {
                setConstructionYears([1800, 2024]);
            }
            setStep(step + 1);
        }

        if ( buyBoxSetupRef.current !== null ) {
            buyBoxSetupRef.current.scrollIntoView();
        }
    };

    const completeIntro = () => {
        setMobileIntro(true);
    };

    const checkDisabled = () => {
        if ( disabled === true ) {
            return true;
        }
        else if ( step === 1 )  {
            if ( chosenMarket === null ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 2 ) {
            if ( propertyTypes[0].included === false && propertyTypes[1].included === false ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 3 ) {
            return false;
        }
        else if ( step === 4 ) {
            return false;
        }
        else if ( step === 5 ) {
            if ( strategy === "" ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 6 ) {
            return false;
        }
    };

    const complete = async() => {
        setDisabled(true);
        setLoading(true);
        const colRef = "Buy Boxes";
        // Create a new buy box

        if ( chosenMarket.cityId === undefined && chosenMarket.metroArea !== true ) {
            const params = {
                city: chosenMarket.city,
                state: chosenMarket.state,
                returnCityId: "True"
            }

            const getCityParams = await getCitySearch(params, false);
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
            const gatewayData = {
                type: "gateway",
                resourceId: "citySearchLive",
                queryString: getCityParams
            };
            const queryCityIdData = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( queryCityIdData.status === 200 ) {
                const getCityIdBody = queryCityIdData.body;
                const getCityId = getCityIdBody.cityId;
                if ( getCityId === null || getCityId === undefined ) {
                    if ( chosenMarket.metroArea === true ) {
                        setError(`We're having an issue with ${chosenMarket.msaTitle}. Please contact us for more details.`);
                    }
                    else {
                        setError(`We're having an issue with ${chosenMarket.city}, ${chosenMarket.state}. Please contact us for more details.`);
                    }
                }
                else {
                    await timer(500);
                    complete();
                }
            }
            else {
                if ( chosenMarket.metroArea === true ) {
                    setError(`We're having an issue with ${chosenMarket.msaTitle}. Please contact us for more details.`);
                }
                else {
                    setError(`We're having an issue with ${chosenMarket.city}, ${chosenMarket.state}. Please contact us for more details.`);
                }

                if ( chosenMarket.metroArea !== true ) {
                    const cityIdErrorPage = "Buy Box Setup";
                    const apiResponse = `Query came back with bad status: ${JSON.stringify(queryCityIdData)}`;
                    await sendCityIDErrorEmail(chosenMarket.city, chosenMarket.state, cityIdErrorPage, apiResponse);
                }
            }
        }
        else {
            setError("");
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryDocument = await getDocument(colRef, userId);
                let pastBuyBoxes = [];
                if ( queryDocument.status === 200 ) {
                    pastBuyBoxes = queryDocument.data.data;
                }

                const id = await makeId(10);
                const cityIndex = chosenMarket.metroArea === true && chosenMarket.msaCode !== undefined ? pastBuyBoxes.findIndex(e => e.msaCode === chosenMarket.msaCode) : chosenMarket.cityId !== undefined ? pastBuyBoxes.findIndex(e => e.cityId === chosenMarket.cityId) : pastBuyBoxes.findIndex(e => e.city === chosenMarket.city && e.state === chosenMarket.state);
                const strategyIndex = strategies.findIndex(e => e.value === strategy);
                const chosenStrategy = strategy === "" || strategyIndex === -1 ? "" : ` ${strategies[strategyIndex].text}`;
                const newBuyBox = {
                    activeListings: 0,
                    bathrooms: bathrooms,
                    bedrooms: bedrooms,
                    budgetRange: budgetRange,
                    constructionYears: constructionYears,
                    frequency: "daily",
                    id: id,
                    lastUpdate: new Date(),
                    metroArea: chosenMarket.metroArea === true ? true : false,
                    neighbourhoodGradeMin: neighbourhoodGradeMin,
                    neighbourhoodGradeMax: neighbourhoodGradeMax,
                    newListings: 0,
                    noFixerUpper: false,
                    onlyADU: false,
                    onlyExtraBedroom: false,
                    propertyTypes: propertyTypes,
                    sent: [],
                    squareFootage: squareFootage,
                    strategy: strategy,
                    title: chosenMarket.metroArea === true ? `My ${chosenMarket.msaTitle}${chosenStrategy} buy box` : `My ${chosenMarket.city}, ${chosenMarket.state}${chosenStrategy} buy box`,
                    totalListings: 0,
                    units: units
                };

                if ( chosenMarket.metroArea === true ) {
                    newBuyBox.msaTitle = chosenMarket.msaTitle;
                    newBuyBox.msaCode = chosenMarket.msaCode;
                }
                else {
                    newBuyBox.city = chosenMarket.city;
                    newBuyBox.state = chosenMarket.state;
                    newBuyBox.cityId = chosenMarket.cityId;
                }

                if ( cityIndex === -1 ) {
                    const newCityBuyBox = {
                        metroArea: chosenMarket.metroArea === true ? true : false,
                        buyBoxes: [],
                        loaded: true
                    };

                    if ( chosenMarket.metroArea === true ) {
                        newCityBuyBox.msaTitle = chosenMarket.msaTitle;
                        newCityBuyBox.msaCode = chosenMarket.msaCode;
                        newCityBuyBox.metroCities = chosenMarket.metroCities;
                        newCityBuyBox.msaCityIds = chosenMarket.msaCityIds;
                    }
                    else {
                        newCityBuyBox.city = chosenMarket.city;
                        newCityBuyBox.state = chosenMarket.state;
                        newCityBuyBox.cityId = chosenMarket.cityId;
                    }

                    newCityBuyBox.buyBoxes.unshift(newBuyBox);
                    pastBuyBoxes.unshift(newCityBuyBox);
                }
                else {
                    pastBuyBoxes[cityIndex].buyBoxes.unshift(newBuyBox);
                }
                await setData(colRef, userId, pastBuyBoxes);
                const preferenceCities = [
                    {
                        metroArea: chosenMarket.metroArea === true ? true : false,
                        allCities: false
                    }
                ];

                if ( chosenMarket.metroArea === true ) {
                    preferenceCities[0].msaTitle = chosenMarket.msaTitle;
                    preferenceCities[0].msaCode = chosenMarket.msaCode;
                }
                else {
                    preferenceCities[0].city = chosenMarket.city;
                    preferenceCities[0].state = chosenMarket.state;
                    preferenceCities[0].cityId = chosenMarket.cityId;
                }

                await savePreferences(userId, preferenceCities);

                recordEvent("New Buy Box Saved", {
                    "City": chosenMarket.metroArea === true ? chosenMarket.msaTitle : `${chosenMarket.city}, ${chosenMarket.state}`,
                    "City ID": chosenMarket.metroArea === true ? chosenMarket.msaCode : chosenMarket.cityId,
                });

                navigate("/my-cities");
            }
            else {
                navigate("/sign-up");
            }
        }
        setLoading(false);
        setDisabled(false);
    };

    return (
        <div ref={buyBoxSetupRef} className="buy-box-setup-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                innerWidth < 1000 && mobileIntro === false ?
                <div className="buy-box-setup-inner-container relative-container">
                    <div className="buy-box-setup-mobile-title-container">
                        <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                            Buy Box Setup
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Let’s set up a buy box – this helps us show you the best 
                            properties and notify you when new ones within your buy box are listed.
                        </span>
                        <div className="buy-box-setup-mobile-intro-button-container margin-x-x-large">
                            <ActionButton
                                onClick={() => completeIntro()}
                            >
                                Start
                            </ActionButton>
                        </div>
                    </div>
                </div>
                :
                <div className="buy-box-setup-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    <div className="buy-box-setup-title-container">
                        <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                            Buy Box Setup
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Let’s set up a buy box – this helps us show you the best 
                            properties and notify you when new ones within your buy box are listed.
                        </span>
                        {
                            chosenMarket === null ?
                            <div className="">

                            </div>
                            :
                            <div className="buy-box-setup-row margin-large">
                                <img
                                    src={Pin}
                                    className="buy-box-setup-pin"
                                    alt="pin"
                                />
                                <span className="body-regular colour-secondary block-text text-align-center">
                                    {chosenMarket.msaTitle}
                                </span>
                            </div>
                        }
                    </div>
                    <div className="buy-box-setup-mobile-top-fixed-container margin-x-small">
                        <div 
                            className="buy-box-setup-back-button-container text-button cursor-pointer"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeftThick}
                                className="buy-box-setup-back-button"
                                alt="Go back"
                            />
                            <span className="body-regular text-link">
                                Back
                            </span>
                        </div>
                        <div className="buy-box-setup-mobile-steps-container">
                            <span className="label-regular colour-secondary">
                                Step {step} of 6
                            </span>
                        </div>
                    </div>
                    <div className="buy-box-setup-mobile-progress-bar-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                            <rect 
                                width="100%" 
                                height="4" 
                                rx="2" 
                                fill="#F2E1D0"
                            />
                            <rect
                                width={`${step * 25}%`} 
                                height="4" 
                                rx="2" 
                                fill={colour.blueBlue03}
                            />
                        </svg>
                    </div>
                    <div className="buy-box-setup-body-container margin-large">
                        <div className="buy-box-setup-body-inner-container">
                        <div className="buy-box-setup-body-mobile-top-container">
                                <div className="buy-box-setup-row margin-large">
                                    <img
                                        src={Pin}
                                        className="buy-box-setup-pin"
                                        alt="pin"
                                    />
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        {
                                            chosenMarket === null ?
                                            ""
                                            :
                                            chosenMarket.msaTitle
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="buy-box-setup-body-bottom-container">
                                <div className="buy-box-setup-step-subtitle-container margin-large">
                                    <h2 className="heading-small-semibold colour-primary block-text text-align-center margin-x-small">
                                        {steps[step - 1].title}
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        {steps[step - 1].description}
                                    </span>
                                </div>
                                {
                                    step === 1 ?
                                    <ChooseCounty
                                        chosenMarket={chosenMarket}
                                        setChosenMarket={setChosenMarket}
                                        setDisabled={setDisabled}
                                        geographyType={geographyType}
                                        setGeographyType={setGeographyType}
                                        customCities={customCities}
                                        setCustomCities={setCustomCities}
                                        customZipCodes={customZipCodes}
                                        setCustomZipCodes={setCustomZipCodes}
                                        allZipCodes={allZipCodes}
                                        setAllZipCodes={setAllZipCodes}
                                        zipOptions={zipOptions}
                                        setZipOptions={setZipOptions}
                                    />
                                    :
                                    step === 2 ?
                                    <ChoosePropertyType
                                        propertyTypes={propertyTypes}
                                        setPropertyTypes={setPropertyTypes}
                                        units={units}
                                        setUnits={setUnits}
                                    />
                                    :
                                    step === 3 ?
                                    <ChooseBudget
                                        budgetRange={budgetRange}
                                        setBudgetRange={setBudgetRange}
                                    />
                                    :
                                    step === 4 ?
                                    <ChoosePropertySpecifics
                                        bedrooms={bedrooms}
                                        setBedrooms={setBedrooms}
                                        bathrooms={bathrooms}
                                        setBathrooms={setBathrooms}
                                        squareFootage={squareFootage}
                                        setSquareFootage={setSquareFootage}
                                        constructionYears={constructionYears}
                                        setConstructionYears={setConstructionYears}
                                    />
                                    :
                                    step === 5 ?
                                    <ChooseStrategy
                                        strategies={strategies}
                                        strategy={strategy}
                                        setStrategy={setStrategy}
                                    />
                                    :
                                    step === 6 ?
                                    <EditNeighbourhoodGrade
                                        neighbourhoodGradeMin={neighbourhoodGradeMin}
                                        neighbourhoodGradeMax={neighbourhoodGradeMax}
                                        changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                                        filtersModal={false}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="buy-box-setup-buttons-row margin-x-x-large">
                        <div 
                            className="buy-box-setup-back-button-container text-button cursor-pointer"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeftThick}
                                className="buy-box-setup-back-button"
                                alt="Go back"
                            />
                            <span className="body-regular text-link">
                                Back
                            </span>
                        </div>
                        <div className="buy-box-setup-next-button">
                            {
                                error !== "" ?
                                <div className="margin-x-small">
                                    <span className="body-regular colour-error">
                                        {error}
                                    </span>
                                </div>
                                :
                                null
                            }
                            <ActionButton
                                disabled={checkDisabled()}
                                onClick={() => next()}
                            >
                                Next
                            </ActionButton>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default BuyBoxSetup;