import React, { useEffect, useState } from 'react';
import '../styles/HowDidYouHear.css';
import { Header, Footer, Loading } from '../components';
import { ActionButton, citiesForPurchase } from '../styles/GlobalStyles';
import { setData, checkUserId, getDocument, recordEvent } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomSelect, colour } from '../styles/GlobalStyles';
import TagManager from 'react-gtm-module';

function DescribeYou() {

    const location = useLocation();
    const state = location.state;
    const [disabled, setDisabled] = useState(true);
    const [loadingState, setLoadingState] = useState(false);
    const [userType, setUserType] = useState([
        {
            label: "Real Estate Investor",
            value: false,
            gaVal: "real_estate_investor"
        },
        {
            label: "Realtor",
            value: false,
            gaVal: "realtor"
        },
        {
            label: "Wholesaler",
            value: false,
            gaVal: "wholesaler"
        },
        {
            label: "Contractor",
            value: false,
            gaVal: "contractor"
        },
        {
            label: "Other",
            value: false,
            gaVal: "other"
        }
    ]);
    const [experience, setExperience] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Describe yourself..";
    });

    const toggleCheckbox = (index) => {
        setDisabled(false);
        const newOptions = userType.slice();
        newOptions[index].value = !userType[index].value;
        setUserType(newOptions);
    };

    const cta = async() => {
        setDisabled(true);
        setLoadingState(true);

        const user = await checkUserId();
        if ( user.status === 200 ) {
            const getData = await getDocument("Users", user.userId);
            if ( getData.status === 200 ) {
                const userData = getData.data.data;
                
                const amendedOptions = [];
                const tagManagerArgs = {
                    dataLayer: {
                        event: "describe_you"
                    }
                };

                for (let index = 0; index < userType.length; index++) {
                    const element = userType[index];
                    if ( element.value === true ) {
                        amendedOptions.push(element);
                    }
                    tagManagerArgs.dataLayer[element.gaVal] = element.value;
                };

                for (let index = 0; index < experienceOptions.length; index++) {
                    const element = experienceOptions[index];
                    if ( element.gaVal !== null ) {
                        tagManagerArgs.dataLayer[element.gaVal] = element.value === experience ? true : false;
                    }
                };
                TagManager.dataLayer(tagManagerArgs);
                userData.describeYou = {
                    type: amendedOptions,
                    experience: experience
                };
                recordEvent("Describe You", {
                    "User Type": amendedOptions,
                    "Experience": experience
                });
                const writeData = await setData("Users", user.userId, userData);
                if ( writeData.status === 200 ) {
                    if ( user.emailChangeable === false ) {
                        navigate("/how-did-you-hear", {
                            state: state
                        });
                    }
                    else {
                        const chosenCity = state === null ? null : state.chosenCity !== undefined ? state.chosenCity : null;
                        if ( state !== null && chosenCity !== undefined && chosenCity !== null ) {
                            const cityId = chosenCity.cityId;
                            if ( cityId !== undefined ) {
                                navigate(`/properties-list/${cityId}`, {
                                    state: state
                                });
                            }
                            else if ( chosenCity.city !== undefined && chosenCity.state !== undefined ) {
                                const findCityIndex = citiesForPurchase.findIndex(e => e.cityUnchanged === chosenCity.city && e.state === chosenCity.state);
                                if ( findCityIndex !== -1 ) {
                                    const coreCityId = citiesForPurchase[findCityIndex].city_id;
                                    navigate(`/properties-list/${coreCityId}`, {
                                        state: state
                                    });
                                }
                                else {
                                    navigate("/pick-city", {
                                        state: state
                                    });
                                }
                            }
                            else {
                                navigate("/pick-city", {
                                    state: state
                                });
                            }
                        }
                        else {
                            navigate("/pick-city", {
                                state: state
                            });
                        }
                        setLoadingState(false);
                    }
                }
                else {
                    navigate("/", {
                        state: state
                    });
                    setLoadingState(false);
                }
            }
            else {
                navigate("/", {
                    state: state
                });
                setLoadingState(false);
            }
        }
        else {
            navigate("/", {
                state: state
            });
            setLoadingState(false);
        }
        setDisabled(false);
    };

    const experienceOptions = [
        {
            label: "Choose experience Level",
            value: false,
            gaVal: null
        },
        {
            label: "Newbie",
            value: "newbie",
            gaVal: "newbie"
        },
        {
            label: "1-3 deals",
            value: "1-3",
            gaVal: "one_to_three"
        },
        {
            label: "4-10 deals",
            value: "4-10",
            gaVal: "four_to_ten"
        },
        {
            label: "10+ deals",
            value: "10+",
            gaVal: "ten_plus"
        }
    ];

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

    return (
        <div className="how-did-you-hear-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="how-did-you-hear-inner-container">
                {
                    loadingState === true ?
                    <Loading />
                    :
                    null
                }
                <div className="how-did-you-hear-title-container margin-x-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                        What best describes you?
                    </h1>
                    <h2 className="body-regular colour-primary">
                        Select all that apply.
                    </h2>
                </div>
                <div className="how-did-you-hear-body-container margin-x-large">
                    {
                        userType.map((item, index) =>
                            <div 
                                className="describe-you-element-container"
                                key={index}
                            >
                                <div     
                                    className="how-did-you-hear-element-container margin-medium"
                                    key={index}
                                >
                                    <div 
                                        className="checkbox-container"
                                        onClick={() => toggleCheckbox(index)}
                                    >
                                        {
                                            item.value === true ?
                                            <div className="checkbox-icon">
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="body-regular colour-primary">
                                        {item.label}
                                    </span>
                                </div>
                                {
                                    index === 0 && item.value === true ?
                                    <div className="describe-you-investor-selector margin-medium">
                                        <Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="user-experience-label"
                                                    id="user-experience-select"
                                                    value={experience}
                                                    label=""
                                                    onChange={(event) => setExperience(event.target.value)}
                                                    input={<CustomSelect />}
                                                >
                                                    {
                                                        experienceOptions.map((subItem, subIndex) =>
                                                            <MenuItem 
                                                                key={subIndex}
                                                                value={subItem.value}
                                                                style={styles.menuItem}
                                                                disabled={subIndex === 0 ? true : false}
                                                            >
                                                                {subItem.label}
                                                            </MenuItem>	
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                </div>
                <div className="how-did-you-hear-button-container">
                    <ActionButton
                        disabled={experience === false && userType[0].value === true ? true : disabled}
                        onClick={() => cta()}
                    >
                        Confirm
                    </ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DescribeYou;