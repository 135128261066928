import React, { useEffect, useState } from 'react';
import '../styles/MapLegend.css';
import { saveSearchParams, sortByPage, sortValues } from '../functions';
import { checkZIPPercentile } from '../styles/GlobalStyles';

function OldMapLegend(props) {

    const splitMap = props.splitMap;
    const selectedMapType = splitMap === true ? props.selectedMapType : 1;
    const zipData = props.zipData;
    const [zipCategories, setZipCategories] = useState([]);
    const [gentrificationCategories, setGentrificationCategories] = useState([]);
    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const cityId = props.cityId;
    const setJustLoaded = props.setJustLoaded;
    const setShowResetButton = props.setShowResetButton;
    const gentrificationScores = props.gentrificationScores;
    const mapSidePanelOpen = props.mapSidePanelOpen;
    const propertyPage = props.propertyPage;
    const temporaryId = props.temporaryId;
    const selectedZIPLegend = props.selectedZIPLegend;
	const setSelectedZIPLegend = props.setSelectedZIPLegend;
	const selectedGentrificationLegend = props.selectedGentrificationLegend;
	const setSelectedGentrificationLegend = props.setSelectedGentrificationLegend;
    const innerWidth = props.innerWidth;
    // const offMarket = props.offMarket;
    const setLoading = props.setLoading;
    const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
    const setChangeMade = props.setChangeMade;
    const allProperties = props.allProperties;
    const setProperties = props.setProperties;
    const setMapProperties = props.setMapProperties;
    const sorting = props.sorting;
    // const page = props.page;
    const setSelectedTracts = props.setSelectedTracts;
    const setPage = props.setPage;

    const hoverZIPLegend = props.hoverZIPLegend;
	const setHoverZIPLegend = props.setHoverZIPLegend;
	const hoverGentrificationLegend = props.hoverGentrificationLegend;
	const setHoverGentrificationLegend = props.setHoverGentrificationLegend;
    const strategy = props.strategy;

    useEffect(() => {
        if ( zipCategories.length === 0 && propertyPage === false ) {
            const categories = [
                {
                    label: "A",
                    zips: []
                },
                {
                    label: "B",
                    zips: []
                },
                {
                    label: "B/C",
                    zips: []
                },
                {
                    label: "C",
                    zips: []
                },
                {
                    label: "D",
                    zips: []
                }
            ];
            for (let index = 0; index < zipData.length; index++) {
                const element = zipData[index];
                const percentile = checkZIPPercentile(zipData, index);
                const zipCode = element.zipcode;
                if ( percentile > 0.8 ) {
                    categories[0].zips.push(zipCode);
                }
                else if ( percentile > 0.6 ) {
                    categories[1].zips.push(zipCode);
                }
                else if ( percentile > 0.4 ) {
                    categories[2].zips.push(zipCode);
                }
                else if ( percentile > 0.2 ) {
                    categories[3].zips.push(zipCode);
                }
                else {
                    categories[4].zips.push(zipCode);
                }
            }
            setZipCategories(categories);
        }

        if ( gentrificationCategories.length === 0 ) {
            const categories = [
                {
                    label: "Little to none",
                    tracts: []
                },
                {
                    label: "Early signs",
                    tracts: []
                },
                {
                    label: "In progress",
                    tracts: []
                },
                {
                    label: "Gentrified",
                    tracts: []
                }
            ];
            for (let index = 0; index < gentrificationScores.length; index++) {
                const element = gentrificationScores[index];
                const gentLabel = element.gent_label;
                const tract = element.tract;
                if ( gentLabel === 1 && categories[0].tracts.indexOf(tract) === -1 ) {
                    categories[0].tracts.push(tract);
                }
                else if ( gentLabel === 2 && categories[1].tracts.indexOf(tract) === -1 ) {
                    categories[1].tracts.push(tract);
                }
                else if ( gentLabel === 3 && categories[2].tracts.indexOf(tract) === -1 ) {
                    categories[2].tracts.push(tract);
                }
                else if ( gentLabel === 4 && categories[3].tracts.indexOf(tract) === -1 ) {
                    categories[3].tracts.push(tract);
                }
            }
            setGentrificationCategories(categories);
        }

    }, [zipCategories.length, zipData, gentrificationCategories.length, gentrificationScores, propertyPage]);
    
    const neighbourhoodOptions = [
        {
            label: "A",
            colour: "map-zone-a",
            labelStyle: "body-semibold "
        },
        {
            label: "B",
            colour: "map-zone-b",
            labelStyle: "body-semibold "
        },
        {
            label: "B/C",
            colour: "map-zone-bc",
            labelStyle: "body-semibold "
        },
        {
            label: "C",
            colour: "map-zone-c",
            labelStyle: "body-semibold "
        },
        {
            label: "D",
            colour: "map-zone-d",
            labelStyle: "body-semibold "
        }
    ];

    const gentrificationOptions = [
        {
            label: "Little to none",
            colour: "gentrification-little-none",
            labelStyle: "body-semibold ",
            gent_label: 1
        },
        {
            label: "Early signs",
            colour: "gentrification-early-signs",
            labelStyle: "body-semibold ",
            gent_label: 2
        },
        {
            label: "In progress",
            colour: "gentrification-progress",
            labelStyle: "body-semibold ",
            gent_label: 3
        },
        {
            label: "Gentrified",
            colour: "gentrified",
            labelStyle: "body-semibold ",
            gent_label: 4
        }
    ];

    const hotspotOptions = [
        {
            label: "Hipster",
            colour: "map-zone-hipster",
            labelStyle: "body-semibold "
        },
        {
            label: "College students",
            colour: "map-zone-college",
            labelStyle: "body-semibold "
        },
        {
            label: "Affordable housing",
            colour: "map-zone-affordable-housing",
            labelStyle: "body-semibold "
        }
    ];

    const options = selectedMapType === 1 ? neighbourhoodOptions : selectedMapType === 0 ? gentrificationOptions : hotspotOptions;

    const zipCodeLegendClicked = async(index) => {
        if ( temporaryId === false ) {
            setChangeMade("zip legend");
            setLoading(true);
            setSelectedZIPLegend(index);
            setSelectedGentrificationLegend("");
            const category = zipCategories[index].zips;

            const newProperties = [];
            for (let index = 0; index < allProperties.length; index++) {
                const element = allProperties[index];
                const zipCode = element.address.zipcode;
                if ( category.indexOf(zipCode) !== -1 ) {
                    newProperties.push(element);
                }
            };
            const sortedArray = await sortValues(newProperties, sorting, strategy);
            const byPageArray = await sortByPage(sortedArray, 1);
            setProperties(byPageArray.page);
            setMapProperties(newProperties);


            const zipJSONObject = {
                "selections": category,
                "cityId": cityId,
                "tracts": []
            };

            const labels = [
                "searchQueryState",
                "page"
            ];
            const values = [
                JSON.stringify(zipJSONObject),
                1
            ];
            await saveSearchParams(labels, values, searchParams, setSearchParams);

            setJustLoaded(false);
            setShowResetButton(true);
            setLoading(false);
            setPage(1);
            setPropertiesOnPageChange(true);
        }
    };

    const gentrificationLegendClick = async(index) => {
        if ( temporaryId === false ) {
            setChangeMade("gentrification legend");
            setLoading(true);
            setSelectedZIPLegend("");
            setSelectedGentrificationLegend(index);
            const category = gentrificationCategories[index].tracts;
            setSelectedTracts(category);

            const newProperties = [];
            for (let index = 0; index < allProperties.length; index++) {
                const element = allProperties[index];
                const tract = element.censusTract;
                if ( category.indexOf(tract) !== -1 ) {
                    newProperties.push(element);
                }
            };
            const sortedArray = await sortValues(newProperties, sorting, strategy);
            const byPageArray = await sortByPage(sortedArray, 1);
            setProperties(byPageArray.page);
            setMapProperties(newProperties);

            const zipJSONObject = {
                "selections": [],
                "cityId": cityId,
                "tracts": category
            };

            const labels = [
                "searchQueryState",
                "page"
            ];
            const values = [
                JSON.stringify(zipJSONObject),
                1
            ];
            await saveSearchParams(labels, values, searchParams, setSearchParams);

            setJustLoaded(false);
            setShowResetButton(true);
            setLoading(false);
            setPage(1);
            setPropertiesOnPageChange(true);
        }
    };

    const hoverMapLegend = (hover, index) => {
        if ( hover === true ) {
            if ( selectedMapType === 1 ) {
                setHoverZIPLegend(index);
            }
            else {
                setHoverGentrificationLegend(index);
            }
        }
        else {
            setHoverZIPLegend("");
            setHoverGentrificationLegend("");
        }
    };

    return (
        <div className={"map-legend-outer-container " + (mapSidePanelOpen === true && propertyPage === false ? "map-legend-move-in" : mapSidePanelOpen === false && propertyPage === false ? "map-legend-default-position" : "")}>
            <div className="map-legend-inner-container">
                {
                    options.map((item, index) => 
                        <div 
                            className={selectedMapType === 1 ? "map-legend-element " + (item.colour) + (selectedZIPLegend === index ? " map-legend-selected-element" : selectedZIPLegend !== "" && propertyPage === false ? " map-legend-deselected" : "") + (hoverZIPLegend === index ? " map-legend-hover-element" : "") : "map-legend-element-gentrification " + (item.colour) + (selectedGentrificationLegend === index ? " map-legend-selected-element" : selectedGentrificationLegend !== "" && propertyPage === false ? " map-legend-deselected" : "") + (hoverGentrificationLegend === index ? " map-legend-hover-element" : "")}
                            key={index}
                            onMouseOver={() => 
                                innerWidth > 999 ?
                                hoverMapLegend(true, index)
                                :
                                null
                            }
                            onMouseLeave={() => 
                                innerWidth > 999 ?
                                hoverMapLegend(false, index)
                                :
                                null
                            }
                            onClick={() => 
                                propertyPage === true ?
                                null
                                :
                                selectedMapType === 1 && zipCategories.length !== 0 ? 
                                zipCodeLegendClicked(index) 
                                : 
                                selectedMapType === 0 ?
                                gentrificationLegendClick(index)
                                :
                                null
                            }
                        >
                            <span className={item.labelStyle + (index !== 4 ? "colour-primary" : "colour-white")}>
                                {item.label}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default OldMapLegend;