import React from 'react';
import { percentageCompact } from '../styles/GlobalStyles';

function ARVSpreadBadge(props) {
    const arvPrice = props.arvPrice;
    const item = props.item;
    const purchasePrice = item.price;
    const arvSpread = (arvPrice - purchasePrice) / purchasePrice;

    return (
        <div className={"new-property-card-arv-badge-container " + (arvSpread > 0 ? "new-property-card-cash-on-cash-positive" : "new-property-card-cash-on-cash-negative")}>
            <div className="new-property-card-arv-badge-inner-container">
                <span className="label-semibold colour-primary block-text subtext-margin-right">
                    ARV Spread
                </span>
                <span className="label-regular colour-primary">
                    {percentageCompact.format(arvSpread)}
                </span>
            </div>
        </div>
    )
}

export default ARVSpreadBadge;