import React, { forwardRef, useState } from 'react';
import '../styles/MobilePropertyCardOverlay.css';
import Heart from '../assets/heart.svg';
import { toggleFavourite, markPropertyViewed, recordEvent } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import { NewPropertyCharacteristics } from './';
import ThickExit from '../assets/thick-exit.svg';
import TopPickBadge from './TopPickBadge';
import { HeartActiveBlue } from '../assets';

const MobilePropertyCardOverlay = forwardRef((props, ref) => {
	const item = props.item;
	const userDetails = props.userDetails;
	const formatter = props.formatter;
	const abstract = props.abstract;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const setMobileAbtract = props.setMobileAbtract;
	const cityId = props.cityId;
	const propertyViewed = viewedProperties.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;
	const [favourites, setFavourites] = useState(userDetails === null ? [] : props.userDetails.favourites !== undefined ? props.userDetails.favourites : []);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const navigate = useNavigate();
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	}

	const viewProperty = async(zpid) => {
		window.open(`/properties/${cityId}/${zpid}`, '_blank');

		const newObject = {
			zpid: zpid,
			date: new Date()
		}
		const newArray = viewedProperties.slice(0);
		newArray.push(newObject);
		setViewedProperties(newArray);
		await markPropertyViewed(zpid);

		const path = window.location.pathname;
	
		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: `/properties/${cityId}/${zpid}`
		});

		recordEvent("Property Click", {
			zpid: zpid,
			cityId: cityId
		});
	};

	return (
		<div className={"mobile-property-card-overlay-outer-container " + (abstract === false && propertyViewed === true ? "mobile-property-card-overlay-viewed-property" : "")}>
			<div className="mobile-property-card-overlay-inner-container">
				<div 
					style={{ backgroundImage: item.imgSrc.includes("maps.googleapis.com") === true ? "" : `url(${item.imgSrc})` }} className="mobile-property-card-overlay-image-container"
				>
					{
						item.topPick === true ?
						<TopPickBadge/>
						:
						null
					}
					<div 
						className="mobile-property-card-overlay-inner-image-container"
						onClick={() => viewProperty(item.zpid)}
					>
					</div>
					<div 
						className="mobile-property-card-overlay-exit-outer-container"
						onClick={() => setMobileAbtract("")}
					>
						<div className="mobile-property-card-overlay-exit-container">
							<img
								src={ThickExit}
								className="mobile-property-card-overlay-exit-icon"
								alt="Close"
							/>
						</div>
					</div>
				</div>
				<div className="mobile-property-card-overlay-right-container">
					<div 
						className="favourite-icon-container"
						onClick={() => toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
					>
						<img
							src={
									favourites.findIndex(e => e.zpid === item.zpid) === -1 ?
									Heart
									:
									HeartActiveBlue
								}
							className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
							alt="Add To Favourites"
						/>
					</div>
					<div 
						onClick={() => viewProperty(item.zpid)}
						className="mobile-property-card-overlay-info-container"
					>
						<div className="mobile-property-card-overlay-price-container">
							<h3 className="mobile-property-card-overlay-price-text">
								{formatter.format(item.price).replace(".00", "")}
							</h3>
						</div>
						<NewPropertyCharacteristics
							item={item}
						/>
					</div>
					<div 
						className="mobile-property-card-overlay-badges-container"
						onClick={() => viewProperty(item.zpid)}
					>
						<div className={"mobile-property-card-overlay-cash-on-cash-badge-container " + (item.financials.cashOnCash > 0 ? "mobile-property-card-overlay-cash-on-cash-positive" : "mobile-property-card-overlay-cash-on-cash-negative")}>
							<span className="mobile-property-card-overlay-cash-on-cash-badge-label">
								Cash on Cash{' '}
								<span className="mobile-property-card-overlay-cash-on-cash-badge-value">
									{item.financials.cashOnCash.toFixed(2)}%
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
});

export default MobilePropertyCardOverlay;