import React, { useState } from 'react';
import '../styles/ComparePropertyCard.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/CustomSwiper.css';
import { OffMarketHouse, RedirectArrow } from '../assets';
import { formatterLong } from '../styles/GlobalStyles';
import { OffMarketBadgesTooltip, OffMarketBadge } from './';
import { recordEvent } from '../functions';

function ComparePropertyCard(props) {
    const property = props.property;
    const viewProperty = props.viewProperty;
	const innerWidth = props.innerWidth;
	const googleLink = `https://www.google.com/maps/place/${property.address.streetAddress.replace(" ", "+")},+${property.address.city},+${property.address.state}+${property.address.zipcode}`;
	// const category = stackedListCategory.findIndex(e => e.id === property.stackedList);
    const breakPointNumber = innerWidth > 2250 ? 3 : innerWidth > 1600 ? 4 : innerWidth > 1500 ? 3 : innerWidth > 1000 ? 2 : innerWidth > 900 ? 3 : 0;
	const [distressTooltip, setDistressTooltip] = useState(false);

	const openGoogle = () => {
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
	}

    const openProperty = (e) => {
        recordEvent("Open Compare Property", {});
        viewProperty(property, e, false);
    };

	const motivatedSeller = property.offMarket === false ? [] : [
        {
            flag: property.motivatedSeller.absentee_flag === 0 ? false : true,
            icon: "🫥",
            label: "Absentee owner"
        },
        {
            flag: property.motivatedSeller.auction_flag === 0 ? false : true,
            icon: "🪧",
            label: "Auction"
        },
        {
            flag: property.motivatedSeller.death_flag === 0 ? false : true,
            icon: "🕊️",
            label: "Recent death"
        },
        {
            flag: property.motivatedSeller.free_clear_flag === 0 ? false : true,
            icon: "🧾",
            label: "Free & Clear"
        },
        {
            flag: property.motivatedSeller.high_equity_flag === 0 ? false : true,
            icon: "🐷🏦",
            label: "High Equity"
        },
        {
            flag: property.motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
            icon: "😶‍🌫️",
            label: "In-State Absentee owner"
        },
        {
            flag: property.motivatedSeller.inherited_flag === 0 ? false : true,
            icon: "🫴",
            label: "Inherited"
        },
        {
            flag: property.motivatedSeller.lien_flag === 0 ? false : true,
            icon: "🤝",
            label: "Lien"
        },
        {
            flag: property.motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
            icon: "💰",
            label: "Multiple Mortgages"
        },
        {
            flag: property.motivatedSeller.owner_occupied_flag === 0 ? true : false,
            icon: "🧓🏠",
            label: "Owner Occupied"
        },
        {
            flag: property.motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
            icon: "📉",
            label: "Pre-Foreclosure"
        },
        {
            flag: property.motivatedSeller.spousal_death_flag === 0 ? false : true,
            icon: "💍🕊️",
            label: "Spousal Death"
        },
        {
            flag: property.motivatedSeller.tax_lien_flag === 0 ? false : true,
            icon: "🗂️🤝",
            label: "Tax Lien"
        },
        {
            flag: property.motivatedSeller.vacant_flag === 0 ? false : true,
            icon: "🏚️",
            label: "Vacant"
        }
    ];

    return (
        <div className="compare-property-card-outer-container">
            <div className="compare-property-card-inner-container">
				{
					property.offMarket === true ?
					<div 
						className="off-market-card-image-container"
						onClick={(e) => viewProperty(property, e, false)}
					>
						<img
							src={OffMarketHouse}
							className="off-market-card-icon"
							alt="Off Market"
						/>
						<div 
							className="off-market-card-text-row"
							onClick={() => openGoogle()}
						>
							<span className="label-regular-caps colour-secondary">
								OFF-MARKET
							</span>
							<img
								src={RedirectArrow}
								className="off-market-card-redirect-arrow"
								alt="Redirect"
							/>
						</div>
						<div className={"off-market-badges-container " + (innerWidth < 900 ? "off-market-badges-mobile-container" : innerWidth < 1200 ? "off-market-badges-small-desktop-container" : innerWidth < 1600 ? "off-market-badges-medium-desktop-container" : "off-market-badges-large-desktop-container")}>
							{
								motivatedSeller.map((subItem, i) =>
									subItem.flag === true && i <= property.motivatedSellerFourthIndex ?
										<OffMarketBadge
											emoji={subItem.icon}
											text={subItem.label}
											key={i}
										/>
									:
									null
								)
							}
							{
								property.motivatedSellerCount > 4 ?
								<div 
									className="off-market-extra-badges-container"
									onMouseOver={() => setDistressTooltip(true)}
									onMouseLeave={() => setDistressTooltip(false)}
								>
									<span className="body-semibold text-link underline block-text">
										+{property.motivatedSellerCount - breakPointNumber}
									</span>
									{
										distressTooltip === true ?
										<OffMarketBadgesTooltip
											motivatedSeller={motivatedSeller}
										/>
										:
										null
									}
								</div>
								:
								null
							}
						</div>
					</div>
					:
					<Swiper 
						pagination={{
							dynamicBullets: true,
						}}
						modules={[Pagination, Navigation]} 
						className="custom-swiper-outer-container"
						loop={true}
						navigation={true}
					>
						{
							property.images.map((imageItem, index) =>
								imageItem.includes("maps.googleapis.com") === true ?
								null
								:
								<SwiperSlide
									key={index}
								>
									<div 
										className="custom-swiper-image-container"
										onClick={() => viewProperty(property, property.cityId)}
									>
										<img
											src={imageItem}
											className="custom-swiper-image"
											alt={imageItem.unlocked === true ? property.address.streetAddress : "random image"}
										/>
									</div>
								</SwiperSlide>
							)
						}
					</Swiper>	
				}
                <div className="compare-property-card-text-container">
                    <h3 className="heading-large-semibold text-link margin-x-small">
                        {formatterLong.format(property.price).replace(".00", "")}
                    </h3>
                    <h4 
                        className="heading-small-semibold colour-primary underline-hover cursor-pointer margin-x-small"
                        onClick={(e) => openProperty(e)}
                    >
                        {property.address.streetAddress}
                    </h4>
                    <h5 className="body-regular colour-primary">
                        {property.address.city}, {property.address.state} {property.address.zipcode}
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default ComparePropertyCard;