import React, { useEffect, useState } from 'react';
import '../styles/MarkSold.css';
import { Header, Footer, Loading } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleFamily, MultiFamilyBlack, Bedrooms, Bathrooms, SQFootage, TickWhite } from '../assets';
import { ActionButton, FormInput, WhiteActionButton } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { checkOnAuthStateChanged, getDocument, setData } from '../functions';

function MarkSold() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const property = state.property;
    const amendments = state.amendments;
    const [price, setPrice] = useState(amendments.price !== null ? amendments.price : property.price);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if ( state === null ) {
            navigate("/partner/dashboard");
        }
    }, [navigate, state]);

    const icons = [
		{
			icon: property.propertyTypeDimension === "Single Family" ? SingleFamily : property.propertyTypeDimension === "Multi Family" ? MultiFamilyBlack : "",
			text: amendments.propertyTypeDimension !== null ? amendments.propertyTypeDimension : property.propertyTypeDimension === "Single Family" ? "Single Family" : property.propertyTypeDimension === "Multi Family" ? "Multi-family" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Manufactured" ? "Manufactured Home" : "",
		},
		{
			icon: Bedrooms,
			text: `${amendments.bedrooms !== null ? amendments.bedrooms : property.bedrooms} bed`,
		},
		{
			icon: Bathrooms,
			text: `${amendments.bathrooms !== null ? amendments.bathrooms : property.bathrooms} bath`,
		},
		{
			icon: SQFootage,
			text: `${amendments.livingArea !== null ? amendments.livingArea : property.livingArea !== null && property.livingArea !== undefined ? property.livingArea.toLocaleString() : "0"} sqft`,
		}
	];

    const cancel = () => {
        navigate(-1);
    };

    const complete = async() => {
        setLoading(true);
        const colRef = "Partners";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;
                const listings = data.listings;
                const index = listings.findIndex(e => e.zpid === property.zpid);
                if ( index !== -1 ) {
                    data.listings[index].status = "sold";
                    data.listings[index].soldPrice = Number(price);
                    await setData(colRef, docRef, data);
                    navigate("/partner/dashboard");
                }
            }
            else {
                navigate("/partner/setup");
            }
        }
        else {
            navigate("/sign-up");
        }

        setLoading(false);
    };

    return (
        <div className="mark-sold-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="mark-sold-inner-container">
                <div className="mark-sold-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                        ✨ Congrats on clozing the deal! ✨
                    </h1>
                    <h2 className="body-regular colour-secondary text-align-center">
                        You’ve earned yourself a cup of joe – here ya go ☕
                    </h2>
                </div>
                <div className="mark-sold-body-container margin-x-large">
                    <div className="mark-sold-inner-body-container">
                        <h3 className="heading-large-semibold colour-primary margin-x-large">
                            Confirm sale
                        </h3>
                        <div className="mark-sold-bottom-border margin-x-large">
                            <h4 className="body-semibold colour-primary margin-x-small">
                                {property.address.streetAddress}, {property.address.city}, {property.address.state} {property.address.zipcode}
                            </h4>
                            <div className="mark-sold-characteristics-row margin-x-large">
                                {
                                    icons.map((item, index) => 
                                        <div 
                                            className="mark-sold-characteristics-element"
                                            key={index}
                                        >
                                            <img 
                                                src={item.icon}
                                                className="mark-sold-characteristics-icon"
                                                alt={item.text}
                                            />
                                            <span className="body-regular colour-primary">
                                                {item.text}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="mark-sold-bottom-container">
                            <span className="body-semibold colour-primary block-text margin-x-small">
                                What price did this deal close for?   
                            </span>
                            <FormInput
                                value={price}
                                type="number"
                                label="Final sale price"
                                id="price-input"
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                placeholder="500,000"
                                onChange={(text) => setPrice(text.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {
                    loading === true ?
                    <div className="mark-sold-loading-container">
                        <Loading />
                    </div>
                    :
                    null
                }
                <div className="mark-sold-buttons-row">
                    <div className="mark-sold-cancel-button">
                        <WhiteActionButton
                            disabled={loading}
                            onClick={() => cancel()}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="mark-sold-complete-button">
                        <ActionButton
                            disabled={loading}
                            onClick={() => complete()}
                        >
                            Mark as sold
                            <img
                                src={TickWhite}
                                className="mark-sold-complete-icon"
                                alt="Complete"
                            />
                        </ActionButton>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MarkSold;