import React, { useState } from 'react';
import '../styles/StickyROI.css';
import Download from '../assets/download.svg';
// import Share from '../assets/share.svg';
import Heart from '../assets/heart.svg';
import HeartActiveBlue from '../assets/heart-active-blue.svg';
import { CopyChainBlue, LockWhite, TickBlue } from '../assets';
import { ActionButton, formatterLong, percentage } from '../styles/GlobalStyles';
import BadgeExplanation from "./BadgeExplanation";
import { DownloadSelector, PendingTag, ExitScreen, DefaultTooltip } from './';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { recordEvent } from "../functions";

function StickyROI(props) {

	const highLevelProfits = props.highLevelProfits;
	const toggleFavourite = props.toggleFavourite;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites
	const setFavouriteDisable = props.setFavouriteDisable;
	const navigate = props.navigate;
	const favouriteDisable = props.favouriteDisable;
	const freeTrial = props.freeTrial;
	const downloadCSV = props.downloadCSV;
	const property = props.property;
	const cityId = props.cityId;
	const downPayment = props.downPayment;
	const blurred = props.blurred;
	const spreadsheetProperty = props.spreadsheetProperty;
	const propertySearch = props.propertySearch;
	const partner = props.partner;
	const partnerId = props.partnerId;
	const downloadPDF = props.downloadPDF;
	const partnerView = props.partnerView;
	const strategyPanel = props.strategyPanel;
	const currentURL = window.location.href.includes("utm_source") || partnerId === null ? `${window.location.href}` : `${window.location.href}?utm_source=partner&utm_medium=listing&utm_campaign=${partnerId}`;
	const oldFormat = props.oldFormat;
	const cityObject = {
		cityId: cityId,
		city: property.address.city,
		state: property.address.state
	};

	const zpidIndex = favourites.findIndex(e => e.zpid === property.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const [downloadSelect, setDownloadSelect] = useState(false);
	const [linkCopied, setLinkCopied] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const openDownloadSelector = () => {
		setDownloadSelect(true);
	};

	const closeDownloadSelector = () => {
		setDownloadSelect(false);
	};

	const clickDownloadCSV = () => {
		downloadCSV([spreadsheetProperty], `${property.address.streetAddress}`, cityId, downPayment, propertySearch, strategyPanel);
		setDownloadSelect(false);
	};

	const clickDownloadPDF = () => {
		recordEvent("Download PDF", {
			city: cityId,
			cityTitle: "",
			all: false,
			zpid: property.zpid
		});

		downloadPDF();
		setDownloadSelect(false);
	};

	const copyToClipboard = () => {
		recordEvent("Copy to Clipboard", {});
		setLinkCopied(true);

		setTimeout(() => {
			setLinkCopied(false);
		}, 10000);
	};

	return (
		<div className={"sticky-roi-outer-container " + (property.homeStatus === "PENDING" ? "sticky-roi-pending-status" : "")}>
			{
				downloadSelect === true ?
				<ExitScreen
					clickScreen={() => closeDownloadSelector()}
				/>
				:
				null
			}
			<div className="sticky-roi-inner-container">
				<div className="sticky-roi-coc-container">
					<div className="sticky-roi-coc-element">
						<h3 className="label-semibold-caps colour-primary margin-x-x-small">
							UP-FRONT COSTS
						</h3>
						<h2 className="heading-large-semibold colour-error">
							{formatterLong.format(Math.round(highLevelProfits.totalCosts)).replace(".00", "")}
						</h2>
					</div>
					{
						strategyPanel === 2 ?
						<div className="sticky-roi-coc-element">
							<h3 className="label-semibold-caps colour-primary margin-x-x-small">
								{highLevelProfits.cashInDeal > 0 ? "CASH IN DEAL" : "SURPLUS"}
							</h3>
							<h2 className={"heading-large-semibold " + (highLevelProfits.cashInDeal > 0 ? "colour-error" : "colour-green")}>
								{formatterLong.format(Math.abs(Math.round(highLevelProfits.cashInDeal))).replace(".00", "")}
							</h2>
						</div>
						:
						null
					}
					{
						strategyPanel !== 3 ?
						<div className="sticky-roi-coc-element">
							<h3 className="label-semibold-caps colour-primary margin-x-x-small">
								MONTHLY PROFIT
							</h3>
							<h2 className={"heading-large-semibold " + ((highLevelProfits.cashOnCash > 0.05 && strategyPanel !== 2) || (highLevelProfits.monthlyProfit > 0 && strategyPanel === 2) || blurred === true ? "colour-green" : (highLevelProfits.cashOnCash < 0 || highLevelProfits.monthlyProfit < 0) ? "colour-error" : "colour-medium")}>
								{
									blurred === true ?
									"$44"
									:
									formatterLong.format(highLevelProfits.monthlyProfit).replace(".00", "")
								}
							</h2>
						</div>
						:
						null
					}
					{
                        strategyPanel !== 1 && strategyPanel !== 3 ?
                        <div className="sticky-roi-coc-element">
                            <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                                CASH ON CASH
                            </h3>
							{
                                strategyPanel === 2 && highLevelProfits.cashInDeal <= 0 ?
                                <div className="infinite-cocr-container relative-container">
                                    <h2 className="heading-large-semibold colour-green">
                                        ∞
                                    </h2>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="16" 
                                        height="16" 
                                        viewBox="0 0 16 16" 
                                        fill="none"
                                        className="infinity-cocr-icon"
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                    >
                                        <path 
                                            d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                            fill="#716E69"
                                        />
                                        <path 
                                            d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                            fill="#716E69"
                                        />
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                            fill="#716E69"
                                        />
                                    </svg>
                                    {
                                        showTooltip === true ?
                                        <DefaultTooltip
                                            title="Infinite CoCR"
                                            description={`The cash on cash return is a measure of how much money you have put INTO the deal vs how much profit you're taking OUT of the deal. Since your ARV refinance is causing you to have no more money in the deal, your cash on cash return is technically infinite because we're dividing by zero. ${`\n\n`}This is like the bit in in Oppenheimer when they thought they might set fire to the universe.`}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                :
                                <h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium")}>
									{
										blurred === true ?
										"4.85%"
										:
										oldFormat === true ?
										`${percentage.format(highLevelProfits.cashOnCash / 100)}`
										:
										`${percentage.format(highLevelProfits.cashOnCash)}`
									}
								</h2>
							}
                        </div>
                        :
                        strategyPanel === 1 ?
                        <div className="sticky-roi-coc-element">
                            <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                                EXPENSES COVERED
                            </h3>
                            <h2 className={"heading-large-semibold " + (highLevelProfits.expensesCovered > 1 || blurred === true ? "colour-green" : "colour-error")}>
                                {
                                    blurred === true ?
                                    "4.85%"
                                    :
                                    `${percentage.format(highLevelProfits.expensesCovered)}`
                                }
                            </h2>
                        </div>
                        :
                        null
                    }
					{
						strategyPanel === 3 ?
                        <div className="sticky-roi-coc-element">
                            <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                                EST. PROFIT
                            </h3>
                            <h2 className={"heading-large-semibold " + (highLevelProfits.arvProfit > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                {
                                    blurred === true ?
                                    "4.85%"
                                    :
                                    `${formatterLong.format(highLevelProfits.arvProfit).replace(".00", "")}`
                                }
                            </h2>
                        </div>
						:
						null
					}
					{
						strategyPanel > 1 ?
                        <div className="sticky-roi-coc-element">
                            <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                                ARV SPREAD
                            </h3>
                            <h2 className={"heading-large-semibold " + (highLevelProfits.arvSpread > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                {
                                    blurred === true ?
                                    "4.85%"
                                    :
                                    `${percentage.format(highLevelProfits.arvSpread)}`
                                }
                            </h2>
                        </div>
						:
						null
					}
					{
						property.homeStatus === "PENDING" ?
						<PendingTag />
						:
						null
					}
				</div>
				<div className="new-property-interactive-buttons-container">
					{
						partnerView === false && partner === true ?
						<CopyToClipboard 
							text={currentURL}
							onCopy={() => copyToClipboard()}
						>
							{
								linkCopied === false ?
								<div className="partner-view-bar-copy-icon-container text-button partner-view-bar-text-spacing">
									<img
										src={CopyChainBlue}
										className="partner-view-bar-copy-icon subtext-margin-right"
										alt="Copy Link"
									/>
									<span className="body-regular text-link">
										Copy link
									</span>
								</div>
								:
								<div className="partner-view-bar-copy-icon-container text-button partner-view-bar-text-spacing">
									<img
										src={TickBlue}
										className="partner-view-bar-copy-icon subtext-margin-right"
										alt="Copy Link"
									/>
									<span className="body-regular text-link">
										Link copied
									</span>
								</div>
							}
						</CopyToClipboard>
						:
						null
					}
					{
						blurred === true ?
						null
						:
						<div className={"new-property-interactive-button-element relative-container new-property-interactive-button-element-download " + (freeTrial === true && partner === false ? "new-property-download-container-free-trial" : " icon-button")}>
							<img
								src={Download}
								className="new-property-download"
								alt="Download as CSV"
								onClick={() => freeTrial === true && partner === false ? null : openDownloadSelector()}
							/>
							<BadgeExplanation
								title="Download as CSV"
								description="CSV download is only available on a premium plan"
							/>
							{
								downloadSelect === true ?
								<DownloadSelector
									downloadCSV={clickDownloadCSV}
									clickDownloadPDF={clickDownloadPDF}
									buyBox={false}
									item={null}
									setDeleteParam={null}
									downloadable={true}
								/>
								:
								null
							}
						</div>
					}
					{
						blurred === true ?
						<div className="new-property-get-access-button">
							<ActionButton
								onClick={() => navigate("/pick-city")}
							>
								<img
									src={LockWhite}
									className="new-property-lock"
									alt="Get Access"
								/>
								Get access
							</ActionButton>
						</div>
						:
						(propertySearch === true && (property.zpid === undefined || property.zpid === null) ) || partner === true ?
						null
						:
						<div className="new-property-interactive-button-element">
							<div 
								className="new-property-favourite-icon-container icon-button"
								onClick={() => favouriteDisable === true ? null : toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
							>
								<img
									src={inFavourites === true ? HeartActiveBlue : Heart}
									className="new-property-favourite"
									alt="Favourite"
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default StickyROI;