import React from 'react';
import '../styles/PurchaseDetails.css';
import { formatterLong } from '../styles/GlobalStyles';

function PurchaseDetails(props) {
    const property = props.property;
    const bodyRow = [
        {
            label: "Purchase method",
            value: property.cashBuyer === true ? "Cash" : "Financed"
        },
        {
            label: "Est. equity",
            value: formatterLong.format(property.estimatedEquity).replace(".00", "")
        },
        {
            label: "Est. mortgage balance",
            value: formatterLong.format(Number(property.estimatedMortgageBalance)).replace(".00", "")
        }
    ];

    return (
        <div className="owner-details-outer-container">
            <div className="owner-details-inner-container">
                <h2 className="heading-small-semibold colour-primary margin-large">
					Purchase Details
				</h2>
                <div className="owner-details-body-row">
                    {
                        bodyRow.map((item, index) =>
                            <div 
                                className="owner-details-body-row-element"
                                key={index}
                            >
                                <span className="label-regular colour-primary margin-x-x-small block-text">
                                    {item.label}
                                </span>
                                <h3 className="body-semibold colour-primary">
                                    {item.value}
                                </h3>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default PurchaseDetails;