import React from 'react';
import '../styles/FreeTrialExpired.css';
import { EmptyCup } from '../assets';
import { ActionButton } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';

function FreeTrialExpired(props) {
    const cityNoAccess = props.cityNoAccess;

    const navigate = useNavigate();

    const cta = () => {
        navigate('/pricing');
    };

    return (
        <div className="free-trial-expired-outer-container">
            <div className="free-trial-expired-inner-container">
                <img
                    src={EmptyCup}
                    className="free-trial-expired-image"
                    alt="Empty Cup"
                />
                <div className="free-trial-expired-text-container">
                    <h2 className="heading-large-semibold colour-primary text-align-center margin-x-x-large">
                        {
                            cityNoAccess === false ?
                            "☹️ Free trial expired"
                            :
                            "You don't have access to this market"
                        }
                    </h2>
                    <span className="body-regular colour-primary block-text margin-x-x-large text-align-center">
                        {
                            cityNoAccess === false ?
                            "It’s good to see you again! Since you’ve been gone, your Coffee Clozers free trial expired."
                            :
                            "I'm afraid you've selected a market which you don't have access to."
                        }
                    </span>
                    <h4 className="body-semibold colour-primary text-align-center margin-medium">
                        {
                            cityNoAccess === false ?
                            "Ready to keep browsing?"
                            :
                            "Want to get access?"
                        }
                    </h4>
                    <div className="free-trial-expired-button-container">
                        <ActionButton
                            onClick={() => cta()}
                        >
                            View plans
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreeTrialExpired;