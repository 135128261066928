import React, { useEffect, useState } from 'react';
import '../styles/SignUp.css';
import SignUpComponent from '../authentication/SignUpComponent';
import { Header, Footer } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged } from '../functions';

function SignUp() {
	const location = useLocation();
	const state = location.state;

	const [initialCheck, setInitialCheck] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLoggedIn = async () => {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				navigate("/my-cities", {
					state: state
				});
			}
		};

		if ( initialCheck === false ) {
			checkLoggedIn();
			setInitialCheck(true);
		}
	}, [initialCheck, navigate, state]);

	return (
		<div className="sign-up-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="sign-up-inner-container">
				<h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
					Let's get you signed up
				</h1>
				<h2 className="body-regular colour-secondary text-align-center">
					No credit card required 😊
				</h2>
				<SignUpComponent
					route="/welcome"
					loginRoute="/login"
					state={state}
				/>
			</div>
			<Footer />
		</div>
	)
}

export default SignUp;