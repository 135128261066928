import React, { useState, useEffect } from 'react';
import '../styles/NewCheckout.css';
import { Header, Footer, NewSwitch, PlanCard, CityPricingCard, CityMobilePricingOverlay } from '../components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { cityPricing } from '../functions';
import { ChevronLeft } from '../assets';
import { ActionButton, colour } from '../styles/GlobalStyles';

function NewCheckout() {
    const location = useLocation();
    const [searchParams] = useSearchParams({});
    const getState = searchParams.get("state");
    const state = location.state !== null ? location.state : getState === null ? null : JSON.parse(getState);
    const [cities, setCities] = useState([]);
    const [chosenCity] = useState(state === null ? null : state.allCities === false ? state.city : null);
    const [allCities] = useState(state === null ? null : state.allCities === true ? true : false);
    const navigate = useNavigate();
    const [firstPricingOption, setFirstPricingOption] = useState(false);
    const pricingOptions = ["Subscription", "Pay once"];
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [pricing, setPricing] = useState(cityPricing);
    const [total, setTotal] = useState(0);
    const [change, setChange] = useState(true);
    const [mobileAccordion, setMobileAccordion] = useState(true);

    useEffect(() => {
        document.title = "Checkout | Coffee Clozers";
        if ( state === null ) {
            navigate("/pricing");
        }
        else if ( change === false ) {
            return;
        }
        else {
            const pricingClone = [...pricing];
            for (let index = 0; index < pricingClone.length; index++) {
                const basePrice = state.price / 100;
                pricingClone[index].originalPrice = basePrice;
                pricingClone[index].price = basePrice * (1 - (pricingClone[index].discount / 100));
                pricingClone[index].total = (pricingClone[index].price * pricingClone[index].months);
            }
            setPricing(pricingClone);

            if ( selectedPlan !== null ) {
                const totalCalc = pricingClone[selectedPlan].total;
                setTotal(totalCalc);
            }
        }
        setChange(false);
    }, [change, navigate, state, pricing, selectedPlan, allCities]);

    const descriptions = [
        "Monthly subscription, renews automatically every month...🤢",
        "If you aren’t satisfied, get a full refund within 14 days."
    ];

    const changePricingOption = () => {
        setFirstPricingOption(!firstPricingOption);
    };

    const choosePlan = (index) => {
        setSelectedPlan(index);
        setChange(true);
    };

    const goBack = () => {
        navigate(allCities === true ? "/pick-city" : "/pricing", {
            state: state
        });
    };

    const deleteCity = (index) => {
        const citiesClone = [...cities];
        citiesClone.splice(index, 1);
        setCities(citiesClone);
        setChange(true);
    };

    const toggleAccordion = () => {
        setMobileAccordion(!mobileAccordion);
    };

    const complete = () => {
        state.pricing = pricing;
        state.selectedPlan = selectedPlan;
        state.plan = pricing[selectedPlan];
        state.chosenCity = chosenCity;
        navigate("/pay", {
            state: state
        })
    };

    return (
        <div className="new-checkout-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                <div className="new-checkout-inner-container">
                    <div className="new-checkout-row-container">
                        <div className="new-checkout-left-container">
                            <div className="city-selection-mobile-back-container desktop-none">
                                <div 
                                    className="button-row text-button"
                                    onClick={() => goBack()}
                                >
                                    <img
                                        src={ChevronLeft}
                                        className="city-selection-back-icon"
                                        alt="Back icon"
                                    />
                                    <span className="body-regular colour-link">
                                        Back
                                    </span>
                                </div>
                                <div className="city-selection-mobile-step-container">
                                    <span className="label-regular colour-secondary">
                                        Step 2 of 3
                                    </span>
                                </div>
                            </div>
                            <div className="city-selection-mobile-progress-bar margin-x-large desktop-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                                    <rect 
                                        width="100%" 
                                        height="4" 
                                        rx="2" 
                                        fill="#F2E1D0"
                                    />
                                    <rect
                                        width={`66%`}
                                        height="4" 
                                        rx="2" 
                                        fill={colour.blueBlue03}
                                    />
                                </svg>
                            </div>
                            <span className="label-regular colour-secondary block-text margin-x-small mobile-none">
                                Step 2 of 3
                            </span>
                            <h1 className="heading-small-semibold colour-primary margin-large">
                                Choose billing frequency
                            </h1>
                            {
                                state.billingOptions !== false ?
                                <div className="new-checkout-switch-container margin-large">
                                    <NewSwitch
                                        firstOption={firstPricingOption}
                                        pricingSwitch={changePricingOption}
                                        textOptions={pricingOptions}
                                        large={false}
                                    />
                                </div>
                                :
                                null
                            }
                            <div className="new-checkout-description-container mobile-none margin-large">
                                {
                                    firstPricingOption === false ?
                                    <span className="body-regular colour-secondary block-text margin-x-small">
                                        Pay once to use it for your chosen duration. 
                                        Why? Because we’re just as sick of subscriptions 
                                        as you are 🫶
                                    </span>
                                    :
                                    null
                                }
                                <span className="body-regular colour-primary">
                                    {descriptions[firstPricingOption ? 0 : 1]}
                                </span>
                            </div>
                            <div className="new-checkout-pricing-cards-container">
                                {
                                    firstPricingOption === true ?
                                    <PlanCard
                                        subscription={true}
                                        selected={selectedPlan === 0 ? true : false}
                                        item={pricing[0]}
                                        last={true}
                                        index={0}
                                        choosePlan={choosePlan}
                                        allCities={allCities}
                                    />
                                    :
                                    pricing.map((item, index) => 
                                        index === 0 ?
                                        null
                                        :
                                        <PlanCard
                                            subscription={false}
                                            selected={selectedPlan === index ? true : false}
                                            item={item}
                                            key={index}
                                            index={index}
                                            last={index === 3 ? true : false}
                                            choosePlan={choosePlan}
                                            allCities={allCities}
                                        />
                                    )
                                }
                            </div>
                            {
                                firstPricingOption === true ?
                                <div className="new-checkout-mobile-description-container desktop-none">
                                    <span className="body-regular colour-secondary block-text">
                                        {descriptions[0]}
                                    </span>
                                </div>
                                :
                                <div className="new-checkout-mobile-description-container desktop-none">
                                    <span className="body-regular colour-secondary block-text margin-large">
                                        Pay once to use it for your chosen duration. 
                                        Why? Because we’re just as sick of subscriptions as you are 🫶
                                    </span>
                                    <span className="body-regular text-link">
                                        If you aren’t satisfied, get a full refund within 14 days.
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="new-checkout-right-container">
                            <CityPricingCard
                                cities={cities}
                                deleteCity={deleteCity}
                                checkout={true}
                                total={total}
                                pricing={pricing}
                                selectedPlan={selectedPlan}
                                allCities={allCities}
                                chosenCity={chosenCity}
                                discountApplied={null}
                            />
                        </div>
                    </div>
                    <div className="new-checkout-buttons-row margin-top-x-x-large mobile-none">
                        <div 
                            className="button-row text-button"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeft}
                                className="city-selection-back-icon"
                                alt="Back icon"
                            />
                            <span className="body-regular colour-link">
                                Back
                            </span>
                        </div>
                        <div className="city-selection-next-button">
                            <ActionButton
                                disabled={selectedPlan === null ? true : false}
                                onClick={() => complete()}
                            >
                                Continue to payment
                            </ActionButton>
                        </div>
                    </div>
                    <CityMobilePricingOverlay
                        toggleAccordion={toggleAccordion}
                        mobileAccordion={mobileAccordion}
                        chosenCity={chosenCity}
                        deleteCity={deleteCity}
                        complete={complete}
                        checkout={true}
                        total={total}
                        pricing={pricing}
                        selectedPlan={selectedPlan}
                        cta="Continue to payment"
                        loading={false}
                        allCities={allCities}
                        discountApplied={null}
                    />
                </div>
            }
            <Footer />
        </div>
    )
};

export default NewCheckout;