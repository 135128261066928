import React from 'react';
import '../styles/DefaultTooltip.css';
import { Beak, GreenArrow, RedArrow } from '../assets';

function IDTooltip(props) {
    return (
        <div className="default-tooltip-container">
            <img
                src={Beak}
                className="default-tooltip-beak"
                alt="Beak"
            />
            <h5 className="body-semibold margin-x-small colour-primary">
                Year over year days on market
            </h5>
            <span className="tooltip-regular colour-primary">
                Days on market tells us how long it takes a property to sell – 
                so, this number tells you if properties in this ZIP code are selling
                faster each year on average.
            </span>
            <div className="default-tooltip-row">
                <img
                    src={GreenArrow}
                    className=""
                    alt="Down Arrow"
                />
                <div className="default-tooltip-row-inner-column">
                    <h5 className="body-semibold margin-x-small colour-primary">
                        Decreasing
                    </h5>
                    <span className="tooltip-regular colour-primary">
                        If this number goes down, it means properties spent 
                        less time on the market (hot market!)
                    </span>
                </div>
            </div>
            <div className="default-tooltip-row">
                <img
                    src={RedArrow}
                    className=""
                    alt="Up Arrow"
                />
                <div className="default-tooltip-row-inner-column">
                    <h5 className="body-semibold margin-x-small colour-primary">
                        Increasing
                    </h5>
                    <span className="tooltip-regular colour-primary">
                        If this number goes up, those properties are sitting on the 
                        market for longer (cold market!)
                    </span>
                </div>
            </div>
        </div>
    )
}

export default IDTooltip;