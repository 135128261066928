import React, { useState, useEffect } from 'react';
import '../styles/AddListing.css';
import { Header, Footer, PlaceInput, Loading } from '../components';
import { checkOnAuthStateChanged, containsNumbers, getDocument, getAPIGatewayDetailAddress, cloudFunctionV2 } from '../functions';
import { useNavigate } from 'react-router-dom';
import { defaultUserData } from '../styles/GlobalStyles';

function AddListing() {
    const [error, setError] = useState("");
    const [searchLocation, setSearchLocation] = useState("");
    const [partnerData, setPartnerData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stage, setStage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPartnerData = async() => {
            const colRef = "Partners";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                const queryDocument = await getDocument(colRef, docRef);
                if ( queryDocument.status === 200 ) {
                    setPartnerData(queryDocument.data.data);
                    fetchUserData(docRef);
                }
                else {
                    navigate("/partner/setup");
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        const fetchUserData = async(userId) => {
            const colRef = "Users";
            const docRef = userId;
            const queryDocument = await getDocument(colRef, docRef);
            if ( queryDocument.status === 200 ) {
                setUserData(queryDocument.data.data);
                setLoading(false);
            }
        };

        fetchPartnerData();
    }, [navigate])

    const changeLocation = (text) => {
        setSearchLocation(text);
    };

    const selectedLocation = async(place) => {
        setStage("Searching for property...");
        if ( place !== null ) {
            const addressElements = place.value.terms;
            const firstElement = addressElements.length !== 0 ? addressElements[0].value : "";
            const address = place.label;
            const firstWord = firstElement.replace(/ .*/,'');
            const checkNumber = await containsNumbers(firstWord);
            if ( checkNumber === true ) {
                setLoading(true);
                setError("");

                const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                const getGatewayQueryString = await getAPIGatewayDetailAddress(defaultUserData.settings, address);
                const getGatewayParams = {
                    type: "gateway",
                    resourceId: "detail",
                    queryString: getGatewayQueryString
                };
                const getProperty = await cloudFunctionV2(gatewayURL, getGatewayParams);
                if ( getProperty.status === 200 ) {
                    const property = getProperty.body.prop;
                    property.status = "active";

                    setStage(null);
                    navigate("/partner/edit-listing", {
                        state: {
                            property: property,
                            partnerData: partnerData,
                            userData: userData,
                            edit: false,
                            amendments: null
                        }
                    });
                }
                else {
                    setError("We couldn't find that address. Please try again.");
                }
            }
            else {
                setError("It doesn't look like you entered an address.. 🤔");
            }
            setSearchLocation("");
            setLoading(false);
        }
    };

    return (
        <div className="add-listing-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
            />
            <div className="add-listing-inner-container">
                <h1 className="heading-large-semibold colour-primary margin-medium">
                    Add a listing to your profile
                </h1>
                <h2 className="body-regular colour-secondary margin-x-large">
                    Search for the address to get started.
                </h2>
                <div className="add-listing-body-container">
                    {
                        loading === true ?
                        <div className="add-listing-loading-container">
                            <Loading />
                        </div>
                        :
                        null
                    }
                    <PlaceInput
                        placeholder="Search any residential address"
                        localLocation={searchLocation}
                        changeLocation={changeLocation}
                        selectedLocation={selectedLocation}
                        types={['address']}
                        disabled={loading}
                    />
                    {
                        error === "" ?
                        null
                        :
                        <span className="body-regular colour-error block-text margin-top-small">
                            {error}
                        </span>
                    }
                    {
                        stage === null ?
                        null
                        :
                        <div className="add-listing-stage-container">
                            <span className="heading-small-semibold colour-primary">
                                {stage}
                            </span>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default AddListing;