import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function RichTextEditor(props) {
    const message = props.message;
    const setMessage = props.setMessage;
    const setLoading = props.setLoading;

    const onChange = (val) => {
        setMessage(val);
    };

    return (
        <Editor
            apiKey={JSON.parse(process.env.REACT_APP_TINY_MCE).key}
            init={{
                    plugins: 'anchor autolink charmap codesample emoticons link lists searchreplace visualblocks wordcount',
                    toolbar: 'undo redo | blocks fontsize | bold italic underline strikethrough | link | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat'
            }}
            selector="textarea"
            id="partner-message"
            value={message}
            onEditorChange={(newContent) => onChange(newContent)}
            onLoadContent={() => setLoading(false)}
        />
    )
};

export default RichTextEditor;