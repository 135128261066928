import React, { useState, useEffect } from 'react';
import '../styles/FinancialPreferences.css';
import { SideNavigation, Header, FinancialsSettings, Loading, Chip, AddCity, DeleteModal } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, getDocument, checkPayingCustomerLoggedIn, setData, getUserDocuments } from '../functions';
import { Abacus, ChevronRight, Cog, ChevronLeft } from '../assets';
import { defaultUserData } from '../styles/GlobalStyles';

function FinancialPreferences() {
    const [userData, setUserData] = useState(null);
    const [monthlyCostDisabled, setMonthlyCostDisabled] = useState(true);
    const [initialCostDisabled, setInitialCostDisabled] = useState(true);
    const [financialSettingsDisabled, setFinancialSettingsDisabled] = useState(true);
    const [monthlyCostRadio, setMonthlyCostRadio] = useState(false);
	const [mortgageRadio, setMortgageRadio] = useState(true);
    const [activeSubscription, setActiveSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cities, setCities] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState(0);
    const [userSettings, setUserSettings] = useState(defaultUserData.settings);
    const [mobileSelectedPanel, setMobileSelectedPanel] = useState(null);
    const [deleteCityModal, setDeleteCityModal] = useState(false);
    const [deleteParam, setDeleteParam] = useState(null);
    const [freeTrial, setFreeTrial] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Financial Preferences";
        const fetchUserData = async() => {
            const collections = ["Users", "Subscriptions", "Financial Preferences"];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                const queryData = await getUserDocuments(collections, docRef);
                if ( queryData[0].status === 200 ) {
                    const data = queryData[0].data;
                    setUserData(data);
                    const checkUser = await checkPayingCustomerLoggedIn(data);
                    if ( checkUser === true ) {
                        setActiveSubscription(true);
                    }
                    else {
                        setActiveSubscription(false);
                    }

                    if ( data.freeTrial !== undefined && data.freeTrial.length > 0 && data.freeTrial[0].endDate.seconds > new Date().getTime() / 1000 ) {
                        setFreeTrial(true);
                    }
                }

                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);
                }

                if ( queryData[2].status === 200 ) {
                    const financialPreferencesData = queryData[2].data;
                    setUserSettings(financialPreferencesData);

                    if ( financialPreferencesData.mortgage !== undefined ) {
						setMortgageRadio(financialPreferencesData.mortgage);
					}

                    const citiesClone = [
                        {
                            label: "All cities",
                            value: "all-cities"
                        }
                    ];
                    for (let index = 0; index < financialPreferencesData.customCities.length; index++) {
                        const element = financialPreferencesData.customCities[index];

                        if ( element.metroArea !== true ) {
                            const cityValue = `${element.city}, ${element.state}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                            const newObject = {
                                label: `${element.city}, ${element.state}`,
                                value: cityValue
                            }
                            citiesClone.push(newObject);
                        }
                        else {
                            const cityValue = `${element.msaTitle}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                            const newObject = {
                                label: `${element.msaTitle}`,
                                value: cityValue
                            }
                            citiesClone.push(newObject);
                        }
                    };
                    setCities(citiesClone);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }
            else {
                navigate("/sign-up", {
                    state: state
                });
            }
        };
        
        fetchUserData();
    }, [navigate, state]);

    const changeMonthlyCostRadio = () => {
		setMonthlyCostRadio(!monthlyCostRadio);
	};

	const changeMortgageRadio = () => {
		setMortgageRadio(!mortgageRadio);
		setInitialCostDisabled(false);
		setFinancialSettingsDisabled(false);
	};

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    const selectMobilePanel = (index) => {
        setMobileSelectedPanel(index);
    };

    const mobileBack = () => {
        setMobileSelectedPanel(null);
    };

    const manageSubscriptions = () => {
        navigate("/profile??tab=1", {
            state: state
        });
    };

    const openRemoveCityModal = (item) => {
        setDeleteCityModal(true);
        setDeleteParam(item);
    };

    const deleteCity = async() => {
        setLoading(true);
        const colRef = "Financial Preferences";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const queryData = await getDocument(colRef, userId);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;
                const newCustomCities = data.customCities;
                const newCitiesClone = [
                    {
                        label: "All cities",
                        value: "all-cities"
                    }
                ];

                const changedCustomCities = [];
                for (let index = 0; index < newCustomCities.length; index++) {
                    const element = newCustomCities[index];

                    if ( element.metroArea !== true ) {
                        const cityValue = `${element.city}, ${element.state}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                        if ( deleteParam.value !== cityValue ) {
                            const newObject = {
                                label: `${element.city}, ${element.state}`,
                                value: cityValue
                            }
                            newCitiesClone.push(newObject);
                            changedCustomCities.push(element);
                        }
                    }
                    else {
                        const cityValue = `${element.msaTitle}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                        if ( deleteParam.value !== cityValue ) {
                            const newObject = {
                                label: element.msaTitle,
                                value: cityValue
                            }
                            newCitiesClone.push(newObject);
                            changedCustomCities.push(element);
                        }
                    }
                }

                setSelectedPanel(0);
                setUserSettings(data);
                setCities(newCitiesClone);
                data.customCities = changedCustomCities;
                setDeleteCityModal(false);
                await setData(colRef, userId, data);
                setDeleteParam(null);
            }
        }
        setLoading(false);
    };

    return (
        <div className="financial-preferences-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            <div className="financial-preferences-row-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="financial-preferences-inner-container">
                    <div className="financial-preferences-inner-width-container">
                        {
                            loading === true ?
                            <div className="financial-preferences-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        {
                            deleteCityModal === true ?
                            <DeleteModal
                                deleteFunction={deleteCity}
                                setDeleteModal={setDeleteCityModal}
                                description={`Are you sure you want to remove ${deleteParam.label} from your financial preferences?`}
                                deleteParam={null}
                            />
                            :
                            null
                        }
                        <div className="financial-preferences-title-container">
                            <div className="financial-preferences-title-row">
                                <img
                                    src={Abacus}
                                    className="financial-preferences-icon"
                                    alt="Financial Preferences"
                                />
                                <div className="financials-preferences-title-text-container">
                                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                                        Financial preferences
                                    </h1>
                                    <span className="body-regular colour-secondary">
                                        Manage how finances are calculated. Settings will automatically apply across all properties.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="financial-preferences-separator margin-tops">
                        </div>
                        <div className="financial-preferences-city-row margin-top-x-large margin-large">
                            {
                                cities.map((item, index) =>
                                    <Chip
                                        label={item.label}
                                        func={changeSelectedPanel}
                                        index={index}
                                        selected={selectedPanel}
                                        key={index}
                                        changeIcon={null}
                                        recommended={false}
                                        hover={null}
                                        leave={null}
                                        disabled={false}
                                    />
                                )
                            }
                            <AddCity
                                freeTrial={freeTrial}
                                subscriptions={subscriptions}
                            />
                        </div>
                        {
                            cities.length !== 0 ?
                            <div className="financial-preferences-selected-city-container margin-x-large">
                                <div className="button-row margin-x-small">
                                    <h2 className="heading-small-semibold colour-primary subtext-margin-right-large">
                                        {cities[selectedPanel].label}
                                    </h2>
                                    {
                                        selectedPanel !== 0 ?
                                        <div 
                                            className="text-button"
                                            onClick={() => openRemoveCityModal(cities[selectedPanel])}
                                        >
                                            <span className="body-regular colour-error">
                                                Remove city
                                            </span>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <span className="body-regular colour-secondary">
                                    {
                                        selectedPanel === 0 ?
                                        "General settings will apply to all properties, across all cities – unless a custom setting exists on that city."
                                        :
                                        "Configure settings for just this city."
                                    }
                                </span>
                            </div>
                            :
                            null
                        }
                        <div className="financial-preferences-thick-separator margin-tops">
                        </div>
                        <div className="financial-preferences-middle-width">
                            {
                                userData !== null && loading === false ?
                                <FinancialsSettings
                                    monthlyCostRadio={monthlyCostRadio}
                                    setMonthlyCostRadio={setMonthlyCostRadio}
                                    changeMonthlyCostRadio={changeMonthlyCostRadio}
                                    mortgageRadio={mortgageRadio}
                                    setMortgageRadio={setMortgageRadio}
                                    changeMortgageRadio={changeMortgageRadio}
                                    monthlyCostDisabled={monthlyCostDisabled}
                                    setMonthlyCostDisabled={setMonthlyCostDisabled}
                                    initialCostDisabled={initialCostDisabled}
                                    setInitialCostDisabled={setInitialCostDisabled}
                                    userData={userData}
                                    activeSubscription={activeSubscription}
                                    disabled={financialSettingsDisabled}
                                    setDisabled={setFinancialSettingsDisabled}
                                    selectedPanel={selectedPanel}
                                    setSelectedPanel={setSelectedPanel}
                                    userSettings={userSettings}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        mobileSelectedPanel === null ?
                        <div className="financial-preferences-mobile-inner-container relative-container">
                            {
                                loading === true ?
                                <div className="financial-preferences-loading-container">
                                    <Loading />
                                </div>
                                :
                                null
                            }
                            <div className="financial-preferences-mobile-title-container margin-large">
                                <h1 className="heading-large-semibold colour-primary margin-x-small">
                                    Financial preferences
                                </h1>
                                <h2 className="body-regular colour-secondary">
                                    Manage how finances are calculated. 
                                    Settings will automatically apply across all properties.
                                </h2>
                            </div>
                            <div className="my-cities-light-divider-line">
                            </div>
                            <div className="my-cities-mobile-options-container">
                                <div 
                                    className="my-cities-mobile-option-element"
                                    onClick={() => manageSubscriptions()}
                                >
                                    <div className="button-row my-cities-mobile-option-padding">
                                        <img
                                            src={Cog}
                                            className="my-cities-mobile-option-cog"
                                            alt="Manage subscriptions"
                                        />
                                        <span className="body-regular colour-link block-text subtext-margin-right">
                                            Manage subscriptions
                                        </span>
                                        <img
                                            src={ChevronRight}
                                            className="my-cities-mobile-option-arrow"
                                            alt="Manage subscriptions"
                                        />
                                    </div>
                                </div>
                                {
                                    cities.map((item, index) =>
                                        <div 
                                            className="my-cities-mobile-option-element"
                                            key={index}
                                            onClick={() => selectMobilePanel(index)}
                                        >
                                            <div className="button-row my-cities-mobile-option-padding">
                                                <span className="body-regular colour-link block-text subtext-margin-right">
                                                    {item.label}
                                                </span>
                                                <img
                                                    src={ChevronRight}
                                                    className="my-cities-mobile-option-arrow"
                                                    alt={item.label}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        :
                        <div className="financial-preferences-mobile-inner-container relative-container">
                            {
                                loading === true ?
                                <div className="financial-preferences-loading-container">
                                    <Loading />
                                </div>
                                :
                                null
                            }
                            <div 
                                className="my-cities-mobile-back-container button-row text-button margin-large"
                                onClick={() => mobileBack()}
                            >
                                <img
                                    src={ChevronLeft}
                                    className="my-cities-back-chevron-left"
                                    alt="Go back"
                                />
                                <span className="body-regular colour-link">
                                    Back
                                </span>
                            </div>
                            <div className="financial-preferences-mobile-body-container">
                                <div className="margin-large">
                                    <h2 className="heading-small-semibold colour-primary margin-x-small">
                                        {cities[mobileSelectedPanel].label}
                                    </h2>
                                    <span className="body-regular colour-secondary">
                                        {
                                            mobileSelectedPanel === 0 ?
                                            "General settings will apply to all properties, across all cities – unless a custom setting exists on that city."
                                            :
                                            "Configure settings for just this city."
                                        }
                                    </span>
                                </div>
                                <div className="financial-preferences-thick-separator margin-tops">
                                </div>
                                <FinancialsSettings
                                    monthlyCostRadio={monthlyCostRadio}
                                    setMonthlyCostRadio={setMonthlyCostRadio}
                                    changeMonthlyCostRadio={changeMonthlyCostRadio}
                                    mortgageRadio={mortgageRadio}
                                    setMortgageRadio={setMortgageRadio}
                                    changeMortgageRadio={changeMortgageRadio}
                                    monthlyCostDisabled={monthlyCostDisabled}
                                    setMonthlyCostDisabled={setMonthlyCostDisabled}
                                    initialCostDisabled={initialCostDisabled}
                                    setInitialCostDisabled={setInitialCostDisabled}
                                    userData={userData}
                                    activeSubscription={activeSubscription}
                                    disabled={financialSettingsDisabled}
                                    setDisabled={setFinancialSettingsDisabled}
                                    selectedPanel={mobileSelectedPanel}
                                    setSelectedPanel={setMobileSelectedPanel}
                                    userSettings={userSettings}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default FinancialPreferences;