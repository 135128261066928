import React, { useState } from 'react';
import '../styles/MobileStrategiesPanel.css';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CustomSelect, colour, formatterCompact, formatterLong, percentage } from '../styles/GlobalStyles';
import { RentAccordion, ValuesAccordion } from './';

function MobileStrategiesPanel(props) {
    const property = props.property;
    const mobileBar = props.mobileBar;
    const strategyPanel = props.strategyPanel;
    const changeStrategyPanel = props.changeStrategyPanel;
    const strategies = props.strategies;
    const rent = props.rent;
    const arv = props.arv;
    const downPayment = props.downPayment;
    const downPaymentCost = props.downPaymentCost;
    const closingCostPercentage = props.closingCostPercentage;
    const closingCost = props.closingCost;
    const interestRate = props.interestRate;
    const highLevelProfits = props.highLevelProfits;
    const blurred = props.blurred;
    const toggleMobileBottomBar = props.toggleMobileBottomBar;
    const [rentAccordion, setRentAccordion] = useState(false);
    const [valuesAccordion, setValuesAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    const handleValuesAccordion = () => {
        setValuesAccordion(!valuesAccordion);
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
        outerContainer: {
            width: '100%'
        }
    };

    return (
        <div className={"mobile-strategies-panel-outer-container " + (mobileBar === true ? "mobile-financials-outer-container-show-overlay" : "mobile-financials-outer-container-hide-overlay")}>
            <div 
                className="mobile-bottom-bar-toggle-icon"
                onClick={() => toggleMobileBottomBar()}
            >
            </div>
            <div className="mobile-strategies-panel-inner-container margin-x-x-large">
                <div className="mobile-strategies-panel-max-width-container">
                    <div className="mobile-strategies-panel-selector-container margin-x-small">
                        <h2 className="label-regular-caps colour-secondary margin-x-x-x-small">
                            STRATEGY
                        </h2>
                        <Box sx={styles.outerContainer}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="strategies-select-label"
                                    id="strategies-select"
                                    value={strategyPanel}
                                    label=""
                                    onChange={(event) => changeStrategyPanel(event.target.value)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        strategies.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={index}
                                                style={styles.menuItem}
                                            >
                                                {item.text}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="mobile-strategies-panel-separator margin-tops">
                    </div>
                    <div className="mobile-strategies-panel-financials-container">
                        <h2 className="label-regular-caps colour-secondary margin-medium">
                            ESTIMATES
                        </h2>
                        <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                            <div className="mobile-strategies-panel-financials-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    RENT
                                </span>
                                <span className="heading-small-semibold colour-primary">
                                    {formatterLong.format(rent).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                        <div className="mobile-strategies-panel-mobile-accordion margin-medium">
                            <RentAccordion
                                property={property}
                                rentAccordion={rentAccordion}
                                handleRentAccordion={handleRentAccordion}
                            />
                        </div>
                        <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                            <div className="mobile-strategies-panel-financials-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    VALUE
                                </span>
                                <span className="heading-small-semibold colour-primary">
                                    {formatterLong.format(property.propertyEstimateDefault).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                        <div className={"mobile-strategies-panel-mobile-accordion " + (arv === property.price ? "margin-x-x-large" : "margin-medium")}>
                            <ValuesAccordion
                                property={property}
                                rentAccordion={valuesAccordion}
                                handleRentAccordion={handleValuesAccordion}
                            />
                        </div>
                        {
                            arv === property.price ?
                            null
                            :
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-x-x-large">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        ARV
                                    </span>
                                    <span className="heading-small-semibold colour-primary">
                                        {formatterLong.format(arv).replace(".00", "")}
                                    </span>
                                </div>
                            </div>
                        }
                        <h2 className="body-semibold colour-primary margin-medium">
                            Up-front costs
                        </h2>
                        <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                            <div className="mobile-strategies-panel-financials-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    DOWN PAYMENT
                                </span>
                                <div className="button-row">
                                    <span className="heading-small-semibold colour-primary block-text subtext-margin-right-large">
                                        {downPayment}%
                                    </span>
                                    <span className="heading-small-semibold colour-error">
                                        ({formatterCompact.format(downPaymentCost).replace(".00", "")})
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"mobile-strategies-panel-financials-element bg-colour-light " + (downPayment === 100 ? "margin-x-x-large" : "margin-medium")}>
                            <div className="mobile-strategies-panel-financials-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    CLOSING COSTS
                                </span>
                                <div className="button-row">
                                    <span className="heading-small-semibold colour-primary block-text subtext-margin-right-large">
                                        {closingCostPercentage}%
                                    </span>
                                    <span className="heading-small-semibold colour-error">
                                        ({formatterCompact.format(closingCost).replace(".00", "")})
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            downPayment === 100 ?
                            null
                            :
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-x-x-large">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        INTEREST RATE
                                    </span>
                                    <span className="heading-small-semibold colour-primary block-text">
                                        {interestRate}%
                                    </span>
                                </div>
                            </div>
                        }
                        <h2 className="body-semibold colour-primary margin-medium">
                            Summary
                        </h2>
                        <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                            <div className="mobile-strategies-panel-financials-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    UP-FRONT COSTS
                                </span>
                                <span className="heading-small-semibold colour-error block-text">
                                    {formatterLong.format(highLevelProfits.totalCosts).replace(".00", "")}
                                </span>
                            </div>
                        </div>
                        {
                            strategyPanel === 2 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        {highLevelProfits.cashInDeal > 0 ? "CASH IN DEAL" : "SURPLUS"}
                                    </span>
                                    <span className={"heading-small-semibold block-text " + (highLevelProfits.cashInDeal > 0 ? "colour-error" : "colour-green")}>
                                        {formatterLong.format(Math.abs(highLevelProfits.cashInDeal)).replace(".00", "")}
                                    </span>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            strategyPanel === 3 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        EST. PROFIT
                                    </span>
                                    <span className={"heading-small-semibold block-text " + (highLevelProfits.arvProfit > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                        {
                                            blurred === true ?
                                            "4.85%"
                                            :
                                            `${formatterLong.format(highLevelProfits.arvProfit).replace(".00", "")}`
                                        }
                                    </span>
                                </div>
                            </div>
                            :
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        MONTHLY PROFIT
                                    </span>
                                    <span className={"heading-small-semibold block-text " + ((highLevelProfits.cashOnCash > 0.05 && strategyPanel !== 2) || (highLevelProfits.monthlyProfit > 0 && strategyPanel === 2) || blurred === true ? "colour-green" : (highLevelProfits.cashOnCash < 0 || highLevelProfits.monthlyProfit < 0) ? "colour-error" : "colour-medium")}>
                                        {
                                            blurred === true ? 
                                            "$99"
                                            :
                                            formatterLong.format(highLevelProfits.monthlyProfit).replace(".00", "")
                                        }
                                    </span>
                                </div>
                            </div>
                        }
                        {
                            strategyPanel === 0 || strategyPanel === 2 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        CASH ON CASH
                                    </span>
                                    {
                                        strategyPanel === 2 && highLevelProfits.cashInDeal <= 0 ?
                                        <div className="mobile-strategies-panel-column">
                                            <span className="heading-small-semibold block-text colour-green margin-x-small">
                                                ∞
                                            </span>
                                            <span className="label-regular colour-primary">
                                                Cash on cash is infinite because you have no money left in the deal.
                                                <br/>
                                                <br/>
                                                don't get excited..you're not rich
                                            </span>
                                        </div>
                                        :
                                        <span className={"heading-small-semibold block-text " + (highLevelProfits.cashOnCash > 0.05 || blurred === true || highLevelProfits.lowerCoCR > 5 ? "colour-green" : highLevelProfits.cashOnCash < 0 || highLevelProfits.lowerCoCR < 0 ? "colour-error" : "colour-medium")}>
                                            {
                                                blurred === true ?
                                                "4.85%"
                                                :
                                                `${percentage.format(highLevelProfits.cashOnCash)}`
                                            }
                                        </span>
                                    }
                                </div>
                            </div>
                            :
                            strategyPanel === 1 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        EXPENSES COVERED
                                    </span>
                                    <span className={"heading-small-semibold block-text " + (highLevelProfits.expensesCovered > 1 || blurred === true ? "colour-green" : "colour-error")}>
                                        {
                                            blurred === true ?
                                            "4.85%"
                                            :
                                            `${percentage.format(highLevelProfits.expensesCovered)}`
                                        }
                                    </span>
                                </div>
                            </div>
                            :
                            strategyPanel === 3 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        ARV SPREAD
                                    </span>
                                    <span className={"heading-small-semibold block-text " + (highLevelProfits.arvSpread > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                        {
                                            blurred === true ?
                                            "4.85%"
                                            :
                                            `${percentage.format(highLevelProfits.arvSpread)}`
                                        }
                                    </span>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            strategyPanel === 2 ?
                            <div className="mobile-strategies-panel-financials-element bg-colour-light margin-medium">
                                <div className="mobile-strategies-panel-financials-inner-element">
                                    <span className="label-regular-caps colour-secondary">
                                        ARV SPREAD
                                    </span>
                                    <span className={"heading-small-semibold block-text " + (highLevelProfits.arvSpread > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                        {
                                            blurred === true ?
                                            "4.85%"
                                            :
                                            `${percentage.format(highLevelProfits.arvSpread)}`
                                        }
                                    </span>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MobileStrategiesPanel;