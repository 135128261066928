import React, { useEffect, useState } from 'react';
import '../styles/NeighbourhoodWatch.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMiddle, cloudFunctionV2 } from '../functions';
import { GoogleMap, useLoadScript, Polygon } from '@react-google-maps/api';
import { mapColours, colour, neighbourhoodColours } from '../styles/GlobalStyles';
import { Header, Footer, MapLegend, SidePanel, SidePanelTrigger } from '../components';
import mapStyles from '../styles/mapStyles.json';

function NeighbourhoodWatch() {
    const params = useParams();
	const cityId = params.id;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const getClickedTract = searchParams.get('tract');
    const [clickedTract, setClickedTract] = useState(getClickedTract);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [mapSidePanelOpen, setMapSidePanelOpen] = useState(getClickedTract !== null ? true : null);
    const [neighbourhoodData, setNeighbourhoodData] = useState([]);
    const [tracts, setTracts] = useState([]);
    const [chosenTract, setChosenTract] = useState(null);
    const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
    const neighbourhoodOption = useState(0);

    const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});
	const [renderOverlay, setRenderOverlay] = useState(false);
	const [centre, setCentre] = useState({lat: 0, lng: 0});

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: true,
		disableDoubleClickZoom: true,
		styles: mapStyles
	};

    useEffect(() => {
        document.title = "Neighborhood Grade Map";
        if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}

        const getNeighbourhoodData = async() => {
            const neighbourhoodPath = cityId.includes("CTY") ? `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json` : `output/map_neighborhood/${cityId}.json`
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);


                if ( getClickedTract !== null ) {
                    for (let index = 0; index < body.length; index++) {
                        const element = body[index];
                        if ( element.region.tractId === getClickedTract ) {
                            setChosenTract(element);
                            findCentre(newBounds[index]);
                        }
                    }
                }
                else {
                    findCentre(newBounds[0]);
                }
			}
		}

        async function findCentre(markers) {
			const latMiddle = getMiddle('lat', markers);
			const lngMiddle = getMiddle('lng', markers);
			const centreObject = {
				lat: latMiddle,
				lng: lngMiddle
			};
			setCentre(centreObject);
		}

        if ( params.id !== undefined ) {
            getNeighbourhoodData();
        }
        else {
            navigate("/404");
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		  };
	  
		window.addEventListener('resize', handleWindowResize);

    }, [cityId, navigate, params.id, renderOverlay, getClickedTract]);

    const checkNeighbourhoodStyles = (index) => {
		const tractValue = neighbourhoodData[index].neighborhoodGrade;
		const getHex = tractValue === 8 ? styles.first.backgroundColor 
		:
		tractValue === 7 ? styles.second.backgroundColor
		:
		tractValue === 6 ? styles.third.backgroundColor
		:
		tractValue === 5 ? styles.fourth.backgroundColor
		:
		tractValue === 4 ? styles.fifth.backgroundColor
		:
		tractValue === 3 ? styles.sixth.backgroundColor
		:
		tractValue === 2 ? styles.seventh.backgroundColor
		:
		styles.eighth.backgroundColor;
		
		if ( clickedTract === neighbourhoodData[index].region.tractId ) {
            const newStyle = {
                fillColor: getHex,
                fillOpacity: 1,
                strokeColor: colour.blueBlue02,
                strokeWeight: 3,
                zIndex: 2
            }
            
            return newStyle;
        }
        else {
            const newStyle = {
                fillColor: getHex,
                fillOpacity: 1,
                strokeColor: '#FFFFFF',
                strokeWeight: 1,
                zIndex: 1
            }
            
            return newStyle;
        }
	};

    const setSidePanelCloseClicked = () => {
    };

    const sidePanelToggle = () => {
        setMapSidePanelOpen(!mapSidePanelOpen);
    }

    const clickTract = (val) => {
        setClickedTract(val);
        setMapSidePanelOpen(true);

        for (let index = 0; index < neighbourhoodData.length; index++) {
            const element = neighbourhoodData[index];
            if ( element.region.tractId === val ) {
                setChosenTract(element);
            }
        }
	};

    const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    return (
        <div className="neighbourhood-watch-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="neighbourhood-watch-inner-container">
                <div className="neighbourhood-watch-title-container">
                    <h1 className="heading-large-semibold colour-primary">
                        Neighborhood Grades
                    </h1>
                </div>
                <div className="neighbourhood-watch-row-container">
                    <div className="neighbourhood-watch-map-container">
                        <MapLegend
                            hoverMapLegend={null}
							setHoverMapLegend={null}
							neighbourhoodOption={null}
                        />
                        {
                            !isLoaded ? null
                            :
                            <GoogleMap
                                zoom={getClickedTract === null ? 11 : 13}
                                center={centre}
                                mapContainerClassName="neighbourhood-map-element"
                                options={options}
                            >
                                {
                                    renderOverlay === true && neighbourhoodData.length !== 0 && innerWidth < 768 ?
                                    <SidePanelTrigger
                                        sidePanelToggle={sidePanelToggle}
                                        lastClickedZIPCode={""}
                                        mapSidePanelOpen={mapSidePanelOpen}
                                        lastClickedTract={""}
                                        userDetails={null}
                                    />
                                    :
                                    null
                                }
                                {
                                   renderOverlay === true ?
                                   neighbourhoodBounds.map((item, index) =>
                                       <Polygon
                                           editable={false}
                                           paths={item}
                                           options={checkNeighbourhoodStyles(index)}
                                           key={index}
                                           onClick={() => clickTract(tracts[index])}
                                       />
                                   )
                                   :
                                   null
                                }
                            </GoogleMap>
                        }
                        {
                            neighbourhoodData.length !== 0 ?
                            <SidePanel
                                chosenTract={chosenTract}
                                neighbourhoodData={neighbourhoodData}
                                neighbourhoodOption={neighbourhoodOption}
                                clickedTract={clickedTract}
                                mapSidePanelOpen={mapSidePanelOpen}
                                setMapSidePanelOpen={setMapSidePanelOpen}
                                setSidePanelCloseClicked={setSidePanelCloseClicked}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NeighbourhoodWatch;