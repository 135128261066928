import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function UpgradeCityRedirect() {

    const navigate = useNavigate();

    useEffect(() => {

        navigate("/pricing");
    }, [navigate]);

    return (
        <div className="">
        </div>
    )
};

export default UpgradeCityRedirect;