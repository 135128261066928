import React from 'react';
import '../styles/PickCityBottom.css';
import { MarketClouds } from '../assets/images';
import { ActionButton } from "../styles/GlobalStyles";
import { recordEvent } from '../functions';

function PickCityBottom(props) {
    const navigate = props.navigate;
    const userData = props.userData;

    const scrollToTop = () => {
        recordEvent("Scroll to top", {});
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const pickAnyCity = () => {
        recordEvent("Pick any city", {});
        if ( userData === null ) {
            navigate("/sign-up");
        }
        else {
            navigate("/pricing");
        }
    };

    return (
        <div className="pick-city-bottom-outer-container bg-colour-white">
            <div className="pick-city-bottom-inner-container">
                <div className="pick-city-bottom-image-container">
                    <img
                        src={MarketClouds}
                        className="pick-city-bottom-image"
                        alt="Where to invest"
                    />
                </div>
                <div className="pick-city-bottom-text-container">
                    <h2 className="display-xl-semibold colour-primary margin-medium">
                        Don’t see your target market?
                    </h2>
                    <span className="body-regular colour-secondary block-text margin-medium">
                        Already know where you want to invest but can't find your target market? 
                        Upgrade now to get full access to any U.S. city.*
                        <br/>
                        <br/>
                        However, we recommend exploring our <span className="underline colour-link" onClick={() => scrollToTop()}>50 sample markets</span> for a Coffee Clozers preview before committing.
                    </span>
                    <div className="pick-city-bottom-button-container margin-medium">
                        <ActionButton
                            onClick={() => pickAnyCity()}
                        >
                            Pick any market
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none"
                                className="pick-city-bottom-button-icon"
                            >
                                <path 
                                    d="M15.1518 7.28845L8.74783 0.884462C8.35485 0.491482 7.7177 0.491482 7.32472 0.884462C6.93174 1.27744 6.93174 1.91459 7.32472 2.30757L12.0109 6.99371L1.55976 6.99371C1.004 6.99371 0.553467 7.44424 0.553467 8C0.553467 8.55576 1.004 9.00629 1.55976 9.00629L12.0109 9.00629L7.32472 13.6924C6.93174 14.0854 6.93174 14.7226 7.32472 15.1155C7.7177 15.5085 8.35485 15.5085 8.74783 15.1155L15.1518 8.71156C15.5448 8.31858 15.5448 7.68143 15.1518 7.28845Z" 
                                    fill="#FFFEFC"
                                />
                            </svg>
                        </ActionButton>
                    </div>
                    <span className="label-regular colour-secondary block-text mobile-text-align-center">
                        *Upon upgrade, it usually takes about 30 minutes to upload the data for that city.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default PickCityBottom;