import React from 'react';
import { ADU, Bedrooms, FixerUpper } from '../assets';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomRadio, IOSSwitch } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

function ChooseValueAddOpportunity(props) {
    const onlyADU = props.onlyADU;
    const setOnlyADU = props.setOnlyADU;
    const onlyExtraBedroom = props.onlyExtraBedroom;
    const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
    const noFixerUpper = props.noFixerUpper;
    const setNoFixerUpper = props.setNoFixerUpper;
    const filtersModal = props.filtersModal;
    const fixerUpperError = props.fixerUpperError;
    const changeFixerUpperRadio = props.changeFixerUpperRadio;
    const fixerUpperOption = props.fixerUpperOption;

    const fixerUpperOptions = [
        {
            label: "No – I want turnkey only.",
            value: true
        },
        {
            label: "Yes – I don’t mind seeing properties that may require rehab.",
            value: false
        }
    ];

    const fixerUpperRadios = [
        {
            label: "Fixer uppers only",
            value: "fu"
        },
        {
            label: "Turnkey only",
            value: "tk"
        },
        {
            label: "Show properties in all conditions",
            value: "both"
        }
    ];

    const changeNoFixerUpperRadio = (value) => {
        if ( value === "true" ) {
            setNoFixerUpper(true);
        }
        else if ( value === "false" ) {
            setNoFixerUpper(false);
        }
        else {
            setNoFixerUpper(value);
        }
    };

    return (
        <div className="choose-value-add-opportunity-outer-container">
            <div className="choose-value-add-opportunity-title-container margin-x-small">
                <img
                    src={ADU}
                    className="choose-value-add-opportunity-icon"
                    alt="ADU Only"
                />
                <span className="body-semibold colour-primary">
                    Accessory Dwelling Unit (ADU)
                </span>
            </div>
            <div className="choose-value-add-opportunity-element-container margin-large">
                <FormGroup>
                    <FormControlLabel
                        control={<IOSSwitch checked={onlyADU} onChange={(e) => setOnlyADU(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                    />
                </FormGroup>
                <span className="body-regular colour-primary">
                    Show <span className="body-semibold">ONLY</span> properties with ADUs or ADU zoning
                </span>
            </div>
            <div className="choose-value-add-opportunity-title-container margin-x-small">
                <img
                    src={Bedrooms}
                    className="choose-value-add-opportunity-icon"
                    alt="Add Bedroom Only"
                />
                <span className="body-semibold colour-primary">
                    Add a bedroom
                </span>
            </div>
            <div className="choose-value-add-opportunity-element-container margin-large">
                <FormGroup>
                    <FormControlLabel
                        control={<IOSSwitch checked={onlyExtraBedroom} onChange={(e) => setOnlyExtraBedroom(e.target.checked)} sx={{ m: 1, marginRight: '0px' }} />}
                    />
                </FormGroup>
                <span className="body-regular colour-primary">
                    Show <span className="body-semibold">ONLY</span> properties where you could add a bedroom
                </span>
            </div>
            <div className="choose-value-add-opportunity-title-container margin-x-small">
                <img
                    src={FixerUpper}
                    className="choose-value-add-opportunity-icon"
                    alt="Fixer Uppers"
                />
                <span className="body-semibold colour-primary">
                    Fixer Uppers
                </span>
            </div>
            {
                filtersModal === false ?
                <div className="choose-value-add-opportunity-column">
                    <span className="body-regular colour-secondary block-text margin-x-small">
                        Are you interested in fixer uppers?
                    </span>
                    <div className="choose-value-add-opportunity-column">
                        <FormControl className="">
                            <RadioGroup
                                aria-labelledby="fixer-upper-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={noFixerUpper}
                                className="profile-buy-box-radio-column"
                                onChange={(val) => changeNoFixerUpperRadio(val.target.value)}
                            >
                                {
                                    fixerUpperOptions.map((item, index) =>
                                        <FormControlLabel 
                                            value={item.value} 
                                            control={<CustomRadio />} 
                                            className=""
                                            label={<span className="body-regular colour-primary">{item.label}</span>}
                                            key={index}
                                        />
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        fixerUpperError === true ?
                        <div className="margin-top-small">
                            <span className="body-regular colour-error">
                                Please select whether you want to see fixer uppers
                            </span>
                        </div>
                        :
                        null
                    }
                </div>
                :
                <div className="">
                    <FormControl className="new-filters-modal-radio-outer-container">
                        <RadioGroup
                            aria-labelledby="fixer-upper-buttons-group-label"
                            name="radio-buttons-group"
                            value={fixerUpperOption}
                            className="choose-value-add-opportunity-property-condition-radios"
                            onChange={(val) => changeFixerUpperRadio(val.target.value)}
                        >
                            {
                                fixerUpperRadios.map((item, index) =>
                                    <FormControlLabel 
                                        value={item.value} 
                                        control={<CustomRadio />}
                                        className=""
                                        label={<span className="body-regular colour-primary">{item.label}</span>}
                                        key={index}
                                    />
                                )
                            }
                        </RadioGroup>
                    </FormControl>
				</div>
            }
        </div>
    );
};

export default ChooseValueAddOpportunity;