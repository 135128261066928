import React from 'react';
import '../styles/PopularBadge.css';

function PopularBadge() {
    return (
        <div className="popular-badge-outer-container bg-colour-black">
            <div className="popular-badge-inner-container">
                <span className="body-regular colour-white block-text text-align-center">
                    ✨ Most popular ✨
                </span>
            </div>
        </div>
    )
};

export default PopularBadge;