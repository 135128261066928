import React, { useState, useEffect } from 'react';
import '../styles/Dashboard.css';
import { Header, Footer, LoadingStatic, Chip, NewPropertyCard, PartnerBanner } from '../components';
import { cloudFunctionV2, getAPIGatewaySavedProperties, getWhereDoc } from '../functions';
import { defaultUserData, formatterLong } from '../styles/GlobalStyles';
// import { Grid, List } from '../assets';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

function PartnerProfile() {

    const params = useParams();
    const id = params.partnerId;
    const [partnerData, setPartnerData] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [option, setOption] = useState(0);
    const [listings, setListings] = useState([]);
    // const [listView, setListView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryCount, setCategoryCount] = useState([listings.length, 0, 0]);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state === null ? {partnerId: id} : location.state;

    const chipOptions = [
        `All (${categoryCount[1] + categoryCount[2]})`,
        `Active (${categoryCount[1]})`,
        `Under contract (${categoryCount[2]})`
    ];

    useEffect(() => {
        document.title = "Partner Profile";
        const fetchData = async() => {
            setLoading(true);
            
            const colRef = "Partners";
            const field = "data.id";
            const operator = "==";
            const queryDocument = await getWhereDoc(colRef, field, operator, id);
            if ( queryDocument.status === 200 ) {
                const data = queryDocument.data[0];
                setPartnerData(data);
                formatPhoneNumber(data.phoneNumber);

                const pastListings = data.listings;
                const zpidArray = [];
                for (let index = 0; index < pastListings.length; index++) {
                    const element = pastListings[index];
                    if ( element.zpid !== undefined ) {
                        zpidArray.push(element.zpid);
                    }
                }
                const zpids = zpidArray.toString();
                
                if ( zpidArray.length !== 0 ) {
                    const getGatewayQueryString = await getAPIGatewaySavedProperties(zpids, defaultUserData.settings);
                    const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                    const gatewayData = {
                        type: "gateway",
                        resourceId: "saved",
                        queryString: getGatewayQueryString
                    };
                    const queryData = await cloudFunctionV2(gatewayURL, gatewayData);
                    if ( queryData.status === 200 ) {
                        const pastSearches = queryData.body;
                        const newCategoryCount = [zpidArray.length, 0, 0];
                        for (let index = 0; index < pastSearches.length; index++) {
                            const element = pastSearches[index];
        
                            if ( element.propertyId !== undefined ) {
                                pastSearches[index].zpid = element.propertyId;
                            }
                            
                            if ( element.images !== null && element.images.length > 0 ) {
                                pastSearches[index].imgSrc = element.images[0];
                            }

                            element.status = pastListings[index].status;
                            element.amendments = pastListings[index].amendments;
                            if ( element.status === "active" ) {
                                newCategoryCount[1]++;
                            }
                            else if ( element.status === "under-contract" ) {
                                newCategoryCount[2]++;
                            }

                            if ( element.amendments.arvCompsStats !== null ) {
                                element.arvComps = true;
                                element.arvCompsStats = element.amendments.arvCompsStats;
                            }

                            if ( element.amendments.bedrooms !== null ) {
                                element.bedrooms = element.amendments.bedrooms;
                            }

                            if ( element.amendments.bathrooms !== null ) {
                                element.bathrooms = element.amendments.bathrooms;
                            }

                            if ( element.amendments.livingArea !== null ) {
                                element.livingArea = element.amendments.livingArea;
                            }

                            if ( element.amendments.price !== null ) {
                                element.price = element.amendments.price;
                            }
        
                            if ( element.zpid !== undefined ) {
                                const zpidIndex = pastListings.findIndex(e => e.zpid === element.propertyId);
                                if ( zpidIndex !== -1 ) {
                                    element.encodedAddress = pastListings[zpidIndex].encodedAddress;
                                }
                                else {
                                    pastSearches.splice(index, 1);
                                }   
                            }
                        }
                        setCategoryCount(newCategoryCount);
                        pastSearches.reverse();
                        setListings(pastSearches);
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                }
            }
            else {
                navigate("/404");
            }
        };

        const formatPhoneNumber = (value) => {
            if (!value) setPhoneNumber(value); 

            const currentValue = value.replace(/[^\d]/g, '');
            const cvLength = currentValue.length; 
        
            if (!phoneNumber || value.length > phoneNumber.length) {
                if (cvLength < 4) setPhoneNumber(currentValue);
            
                if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
            
                setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
            }
        }

        fetchData();
    }, [phoneNumber, navigate, id]);

    // const toggleView = () => {
    //     setListView(!listView);
    // };

    const changeOption = (index) => {
        setOption(index);
    };

    return (
        <div className="dashboard-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                partnerData === null ?
                <div className="dashboard-inner-container">
                    <LoadingStatic />
                </div>
                :
                <div className="dashboard-inner-container partner-view-profile-container">
                    <PartnerBanner
                        partnerData={partnerData}
                        phoneNumber={phoneNumber}
                        profilePicLoading={""}
                        profilePicError={null}
                        textInput={null}
                        openEditProfile={null}
                        onChangeFile={null}
                        editMode={false}
                        property={null}
                        state={state}
                    />
                    <div className="dashboard-body-container">
                        <div className="dashboard-body-top-container margin-large">
                            <h3 className="heading-small-semibold colour-primary">
                                Listings
                            </h3>
                        </div>
                        <div className="dashboard-listing-options-container margin-large">
                            <div className="dashboard-listings-left-container">
                                {/* <div className="dashboard-list-grid-toggle">
                                    <img
                                        src={listView === true ? Grid : List}
                                        className="dashboard-list-grid-toggle-image"
                                        alt={listView === true ? "Grid" : "List"}
                                        onClick={() => toggleView()}
                                    />
                                </div> */}
                                {
                                    chipOptions.map((item, index) =>
                                        <Chip
                                            label={item}
                                            func={changeOption}
                                            index={index}
                                            selected={option}
                                            key={index}
                                            recommended={false}
                                            hover={null}
                                            leave={null}
                                            disabled={false}
                                        />
                                    )
                                }
                            </div>
                            <div className="dashboard-listings-right-container">

                            </div>
                        </div>
                        {
                            loading === true ?
                            <LoadingStatic />
                            :
                            listings.length === 0 || (categoryCount[1] === 0 && option === 1) ?
                            <div className="dashboard-no-listings-container">
                                <h4 className="heading-small-semibold colour-primary margin-medium">
                                    No listings yet 🤨
                                </h4>
                            </div>
                            :
                            <div className="dashboard-listings-grid">
                                {
                                    listings.map((item, index) =>
                                        item.status === "sold" || item.status === "archive" ?
                                        null
                                        :
                                        option === 0 || (option === 1 && item.status === "active") || (option === 2 && item.status === "under-contract") ?
                                        <NewPropertyCard
                                            item={item}
                                            index={index}
                                            key={index}
                                            userDetails={null}
                                            formatter={formatterLong}
                                            abstract={true}
                                            viewedProperties={[]}
                                            setViewedProperties={null}
                                            cityId={""}
                                            setHoverCard={null}
                                            propertyRefs={null}
                                            onboarding={null}
                                            onboardingStep={null}
                                            setOnboardingStep={null}
                                            setCardClick={null}
                                            temporaryId={null}
                                            setSignUpModal={null}
                                            overlay={null}
                                            setOverlay={null}
                                            propertySearch={false}
                                            ipUser={false}
                                            noUserId={""}
                                            partnerView={true}
                                            partnerEditProperty={null}
                                            partnerSoldProperty={null}
                                            partnerData={partnerData}
                                            changeStatus={null}
                                            setShowExitScreen={null}
                                            selectOption={null}
                                            openStatus={null}
                                            chooseStatus={null}
                                            state={state}
                                            favourites={[]}
                                            setFavourites={null}
                                            arrowClicked={null}
                                            imageLimit={10}
                                        />
                                        :
                                        null
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>   
            }
            <Footer />
        </div>
    )
}

export default PartnerProfile;