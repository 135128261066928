import React from 'react';
import '../styles/PartnerSplashScreen.css';
import { PartnerBanner } from './';
import { ActionButton } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function PartnerSplashScreen(props) {
    const partnerData = props.partnerData;
    const phoneNumber = props.phoneNumber;
    const state = props.state;
    const setShowSplashScreen = props.setShowSplashScreen;

    const complete = () => {
        setShowSplashScreen(false);
        recordEvent("Partner Splash Screen Closed", {

        });
    };
    
    return (
        <div className="partner-splash-screen-outer-container bg-colour-light">
            <div className="partner-splash-screen-inner-container">
                <div className="partner-splash-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-medium">
                        Welcome to Coffee Clozers!
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        One of our partners has shared a listing with you. 
                        Since it’s your first time here, it may take a moment to load 😊
                    </span>
                </div>
                <div className="partner-splash-body-container margin-x-large">
                    <span className="label-regular-caps colour-secondary block-text text-align-center margin-medium">
                        partner listing shared by
                    </span>
                    <PartnerBanner
                        partnerData={partnerData}
                        phoneNumber={phoneNumber}
                        profilePicLoading={""}
                        profilePicError={null}
                        textInput={null}
                        openEditProfile={null}
                        onChangeFile={null}
                        editMode={false}
                        property={null}
                        state={state}
                    />
                </div>
                <div className="partner-splash-screen-button-container">
                    <ActionButton
                        onClick={() => complete()}
                    >
                        Continue to view listing
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="17" 
                            height="16" 
                            viewBox="0 0 17 16" 
                            fill="none"
                            className="subtext-margin-left-large block-text"
                        >
                            <path 
                                d="M15.4763 7.28844L9.07229 0.884459C8.67931 0.491479 8.04216 0.491479 7.64918 0.884459C7.2562 1.27744 7.2562 1.91459 7.64918 2.30757L12.3353 6.99371L1.88422 6.99371C1.32846 6.99371 0.87793 7.44424 0.87793 8C0.87793 8.55576 1.32846 9.00629 1.88422 9.00629L12.3353 9.00629L7.64918 13.6924C7.2562 14.0854 7.2562 14.7226 7.64918 15.1155C8.04216 15.5085 8.67931 15.5085 9.07229 15.1155L15.4763 8.71155C15.8693 8.31857 15.8693 7.68143 15.4763 7.28844Z" 
                                fill="#FFFEFC"
                            />
                        </svg>
                    </ActionButton>
                </div>
            </div>
        </div>
    );
};

export default PartnerSplashScreen;