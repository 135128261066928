import React from 'react';
import '../styles/SubscriptionTable.css';
import { PinBlue } from '../assets';
import { formatterLong } from '../styles/GlobalStyles';
import moment from 'moment';

function SubscriptionTable(props) {
    const subscriptions = props.subscriptions;
    const checkMonths = props.checkMonths;
    // const expiredSubscriptions = props.expiredSubscriptions;
    const freeTrialObject = props.freeTrialObject;
    const cancelSubscription = props.cancelSubscription;
    const getHelp = props.getHelp;
    const upgradeSubscription = props.upgradeSubscription;
    const columns = [
        {
            label: "City"
        },
        {
            label: "Plan"
        },
        {
            label: "Cost"
        },
        {
            label: "Expires"
        },
        {
            label: "Renews"
        }
    ];

    return (
        <table className="subscription-table-outer-container">
            <thead className="">
                <tr className="subscription-table-row">
                    {
                        columns.map((item, index) =>
                            <th 
                                className={"subscription-table-label-cell text-align-left " + (index === 0 ? "no-padding-left" : "")}
                                key={index}
                            >
                                <span className="body-regular colour-tertiary">
                                    {item.label}
                                </span>
                            </th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    subscriptions.length === 0 ?
                    freeTrialObject.map((item, index) =>
                        <tr
                            key={index}
                            className="subscription-values-row"
                        >
                            <td className="subscription-table-cell no-padding-left"> 
                                <img
                                    src={PinBlue}
                                    className="subscription-table-pin-icon"
                                    alt="Pin"
                                />
                                <span className="body-regular text-link">
                                    All active markets
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-semibold colour-primary">
                                    7 day free trial
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-regular colour-secondary">
                                    $0
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-regular colour-primary">
                                    {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-regular colour-primary">
                                    You will <span className="body-semibold">NOT</span> be billed
                                    when your trial is up.
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                <div 
                                    className="text-button"
                                    onClick={() => upgradeSubscription(item)}
                                >
                                    <span className="body-regular colour-link block-text text-align-center">
                                        Upgrade
                                    </span>
                                </div>
                            </td>
                        </tr>
                    )
                    :
                    subscriptions.map((item, index) =>
                        <tr
                            key={index}
                            className="subscription-values-row"
                        >
                            <td className="subscription-table-cell no-padding-left"> 
                                <img
                                    src={PinBlue}
                                    className="subscription-table-pin-icon"
                                    alt="Pin"
                                />
                                {
                                    item.credits === true ?
                                    <span className="body-regular text-link">
                                        {item.creditCount} Quick Report credits
                                    </span>
                                    :
                                    <span className="body-regular text-link">
                                        All active markets
                                    </span>
                                }
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-semibold colour-primary">
                                    {
                                        item.autoBilling === true ?
                                        "Auto-renew"
                                        :
                                        `${checkMonths(item)} ${checkMonths(item) === 1 ? "month" : "months"} access`
                                    }
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                {
                                    item.price === "" ?
                                    <span className="body-regular colour-secondary">
                                        --
                                    </span>
                                    :
                                    item.autoBilling === true ?
                                    <span className="body-regular colour-secondary">
                                        {formatterLong.format(item.price).replace(".00", "")}<span className="label-regular">/month</span>
                                    </span>
                                    :
                                    <span className="body-regular colour-secondary">
                                        {formatterLong.format(item.price).replace(".00", "")}
                                    </span>
                                }
                            </td>
                            <td className="subscription-table-cell"> 
                                <span className="body-regular colour-primary">
                                    {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                </span>
                            </td>
                            <td className="subscription-table-cell"> 
                                {
                                    item.autoBilling === true ?
                                    <span className="body-regular colour-primary">
                                        {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                    </span>
                                    :
                                    <span className="body-regular colour-primary">
                                        Does <span className="body-semibold">NOT</span> renew automatically
                                    </span>
                                }
                            </td>
                            <td className="subscription-table-cell"> 
                                {
                                    item.autoBilling === true ?
                                    <div 
                                        className="text-button"
                                        onClick={() => cancelSubscription(item)}
                                    >
                                        <span className="body-regular colour-error block-text text-align-center">
                                            Cancel subscription
                                        </span>
                                    </div>
                                    :
                                    <div 
                                        className="text-button"
                                        onClick={() => getHelp()}
                                    >
                                        <span className="body-regular colour-link block-text text-align-center">
                                            Get help
                                        </span>
                                    </div>
                                }
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
};

export default SubscriptionTable;