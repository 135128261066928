import React, { useEffect, useState } from 'react';
import '../styles/InteractiveMap.css';
import { GoogleMap, useLoadScript, OverlayView, Polygon, OverlayViewF } from '@react-google-maps/api';
import CoffeeIcon from '../assets/coffee-icon.svg';
import { useSelector } from 'react-redux';
import { checkZIPPercentile, colour, mapColours } from '../styles/GlobalStyles';
import { OldMapLegend } from './';

function LocationZIPMap(props) {
	const property = props.property;
	const controls = props.controls;
	const text = props.text
	const zipData = props.zipData;
	const bounds = props.bounds
	const zipNames = props.zipNames;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});

	const profit = useSelector(state => state.profit);

	const centre = ({ lat: property.latitude, lng: property.longitude });
	const [renderOverlay, setRenderOverlay] = useState(false);

	const options = {
		disableDefaultUI: controls,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false
	}

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}
	}, [renderOverlay]);

	const checkZIPRanking = (index) => {
		const zipCode = zipNames[index]
		const zipCodeIndex = zipData.findIndex(e => e.zipcode === zipCode);
		const percentile = checkZIPPercentile(zipData, zipCodeIndex);
		
		if ( percentile > 0.8 ) {
			return styles.deepGreen;
		}
		else if ( percentile > 0.6  ) {
			return styles.green;
		}
		else if ( percentile > 0.4 ) {
			return styles.amber;
		}
		else if ( percentile > 0.2 ) {
			return styles.blue;
		}
		else if ( percentile <= 0.2 ) {
			return styles.deepBlue;
		}
		else {
			return styles.default;
		}
	};

	const styles = {
		deepBlue: {
			fillColor: mapColours.mapColorsMapZoneD,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		deepBlueSelected: {
			fillColor: mapColours.mapColorsMapZoneD,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		blue: {
			fillColor: mapColours.mapColorsMapZoneC,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		blueSelected: {
			fillColor: mapColours.mapColorsMapZoneC,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		amber: {
			fillColor: mapColours.mapColorsMapZoneBC,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		amberSelected: {
			fillColor: mapColours.mapColorsMapZoneBC,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		green: {
			fillColor: mapColours.mapColorsMapZoneB,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		greenSelected: {
			fillColor: mapColours.mapColorsMapZoneB,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		deepGreen: {
			fillColor: mapColours.mapColorsMapZoneA,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		deepGreenSelected: {
			fillColor: mapColours.mapColorsMapZoneA,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		default: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		defaultSelected: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		}
	};

	return (
		<div className="location-gentrification-map-container">
			<OldMapLegend
				selectedMapType={1}
				zipData={zipData}
				searchParams={null}
				setSearchParams={null}
				cityId={""}
				setJustLoaded={null}
				setShowResetButton={null}
				gentrificationScores={[]}
				mapSidePanelOpen={false}
				splitMap={true}
				propertyPage={true}
				offMarket={false}
				setLoading={null}
				setPropertiesOnPageChange={null}
				strategy={null}
			/>
			{
				!isLoaded ? "Loading"
				:
				<GoogleMap
					zoom={11}
					center={centre}
					mapContainerClassName="location-map-container"
					options={options}
				>
					{
						renderOverlay === true ?
						bounds.map((item, index) =>
							<Polygon
								editable={false}
								paths={item}
								options={checkZIPRanking(index)}
								key={index}
							/>
						)
						:
						null
					}
					{
						renderOverlay === true ?
							<OverlayViewF
								position={{ lat: property.latitude, lng: property.longitude }}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							>
								<div 
									className="location-map-overlay"
								>
									<span>
										{
											text === true && profit !== "" ? 
											`${profit.cashOnCash.toFixed(2)}%`
											:
											null
										}
										<img
											src={CoffeeIcon}
											className="interactive-map-coffee-icon"
											alt="Coffee"
										/> 
									</span>
								</div>
							</OverlayViewF>	
						:
						null
					}
				</GoogleMap>
			}
		</div>
	)
}

export default LocationZIPMap;