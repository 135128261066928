import React from 'react';
import { DefaultTooltip, PropertyNeighbourhoodBadge } from './';
import { Info } from '../assets';
import { gentrificationTooltipIndexes } from '../functions';
import { gentrificationtTitles } from '../styles/GlobalStyles';

function GentrificationScores(props) {

    const property = props.property;
    const gentrificationData = props.gentrificationData;
    const gentrificationIndex = gentrificationData.findIndex(e => e.tract === property.censusTract);
    const formatter = props.formatter;
    const toggleTooltip = props.toggleTooltip;
    const neighbourhoodTooltipShow = props.neighbourhoodTooltipShow;
    const neighbourhoodTooltipIndex = props.neighbourhoodTooltipIndex;
    const tooltipType = props.tooltipType;
    const titleIndex = gentrificationIndex !== -1 ? gentrificationData[gentrificationIndex].gent_label - 1 : "";

    const percentage = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    });

    const areaStatsLeft = [
		{
			label: "Moved in the last 5 years",
			value: gentrificationIndex !== -1 ? percentage.format(gentrificationData[gentrificationIndex].ratio_moved_in_last_5_yr) : ""
		},
        {
			label: "Bachelor's degree or higher",
			value: gentrificationIndex !== -1 ? percentage.format(gentrificationData[gentrificationIndex].ratio_education_graduate_degree) : ""
		}
	];

    const areaStatsRight = [
		{
			label: "Employed",
			value: gentrificationIndex !== -1 ? percentage.format(gentrificationData[gentrificationIndex].ratio_employed) : ""
		},
		{
			label: "Median household income",
			value: gentrificationIndex !== -1 ? formatter.format(gentrificationData[gentrificationIndex].median_income).replace(".00", "") : ""
		}
	];

    return (
        titleIndex !== "" ?
        <div className="neighbourhood-scores-outer-container">
            <div className="neighbourhood-scores-inner-container">
                <h2 className="heading-small-semibold colour-primary margin-x-small">
                    Gentrification
                </h2>
                <PropertyNeighbourhoodBadge
                    item={property}
                    zipType={gentrificationtTitles[titleIndex].value}
                    badgeText={gentrificationtTitles[titleIndex].title}
                    type={"neighbourhood-rating"}
                />
                <div className="neighbourhood-scores-body-container">
                    <h4 className="body-semibold colour-primary">
                        Residential snapshot
                    </h4>
                    <div className="neighbourhood-scores-grid-container">
                        <div className="neighbourhood-scores-left-grid-container">
                            {
                                areaStatsLeft.map((item, index) =>
                                    <div 
                                        className="neighbourhood-scores-element-container"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small inline-block-text relative-container">
                                            {item.label}
                                            <img
                                                src={Info}
                                                className="neighbourhood-scores-tooltip-icon"
                                                alt="Info"
                                                onMouseEnter={() => toggleTooltip(true, index, "gentrification")}
                                                onMouseLeave={() => toggleTooltip(false, index, "gentrification")}
                                            />
                                            {
                                                neighbourhoodTooltipShow === true && neighbourhoodTooltipIndex === index && tooltipType === "gentrification" ?
                                                <DefaultTooltip
                                                    title={gentrificationTooltipIndexes[index].title}
                                                    description={gentrificationTooltipIndexes[index].description}
                                                />
                                                :
                                                null
                                            }
                                        </span>
                                        <h5 className="body-semibold colour-primary">
                                            {item.value}
                                        </h5>
                                    </div>
                                )
                            }
                        </div>
                        <div className="neighbourhood-scores-right-grid-container">
                            {
                                areaStatsRight.map((item, index) =>
                                    <div 
                                        className="neighbourhood-scores-element-container"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small inline-block-text relative-container">
                                            {item.label}
                                            <img
                                                src={Info}
                                                className="neighbourhood-scores-tooltip-icon"
                                                alt="Info"
                                                onMouseEnter={() => toggleTooltip(true, index + 2, "gentrification")}
                                                onMouseLeave={() => toggleTooltip(false, index + 2, "gentrification")}
                                            />
                                            {
                                                neighbourhoodTooltipShow === true && neighbourhoodTooltipIndex === (index + 2) && tooltipType === "gentrification" ?
                                                <DefaultTooltip
                                                    title={gentrificationTooltipIndexes[index + 2].title}
                                                    description={gentrificationTooltipIndexes[index + 2].description}
                                                />
                                                :
                                                null
                                            }
                                        </span>
                                        <div className="neighbourhood-scores-element-inner-row">
                                            <h5 className="body-semibold colour-primary">
                                                {item.value}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        null
    )
}

export default GentrificationScores;