import React from 'react';
import '../styles/PickCityBottom.css';
import { LandingImage } from '../assets/images';
import { ActionButton } from "../styles/GlobalStyles";
import { recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';

function MarketAnalysisBottom() {

    const navigate = useNavigate();

    const pickAnyCity = () => {
        recordEvent("Market Analysis Bottom CTA", {});
        navigate("/pick-city");
    };

    return (
        <div className="pick-city-bottom-outer-container bg-colour-white">
            <div className="pick-city-bottom-inner-container">
                <div className="pick-city-bottom-image-container">
                    <img
                        src={LandingImage}
                        className="pick-city-bottom-image"
                        alt="Where to invest"
                    />
                </div>
                <div className="pick-city-bottom-text-container">
                    <h2 className="display-xl-semibold colour-primary margin-medium">
                        Discover cash-flowing properties
                    </h2>
                    <span className="body-regular colour-secondary block-text margin-medium">
                        Found your market and ready to find your next investment property? Click below to start browsing properties
                    </span>
                    <div className="pick-city-bottom-button-container margin-medium">
                        <ActionButton
                            onClick={() => pickAnyCity()}
                        >
                            Start exploring
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MarketAnalysisBottom;