import React from 'react';
import '../styles/CityMobilePricingOverlay.css';
import { Cappuccino } from '../assets/images';
import { formatterLong, ActionButton } from '../styles/GlobalStyles';
import moment from 'moment';

function CountyMobilePricingOverlay(props) {
    const toggleAccordion = props.toggleAccordion;
    const mobileAccordion = props.mobileAccordion;
    const complete = props.complete;
    const checkout = props.checkout;
    const total = props.total;
    const selectedPlan = props.selectedPlan;
    const startDate = new Date();
	const endDate = moment(startDate).add(1, "M").format("D MMMM YYYY");
    const cta = props.cta;
    const loading = props.loading;
    const payment = props.payment;
    const disabled = props.disabled;
    const userData = props.userData;
    const saveCreditCard = props.saveCreditCard;
    const discountApplied = props.discountApplied;
    const plan = props.plan;

    const calculateDiscountedAmount = () => {
        const originalPrice = plan.originalPrice * plan.months;
        const discountAmount = originalPrice * (discountApplied.discount / 100);
        return discountAmount;
    };

    const calculateDiscountedTotal = () => {
        const originalPrice = plan.originalPrice * plan.months;
        const discount = discountApplied.discount + plan.discount;
        const discountAmount = originalPrice * (discount / 100);
        const finalPrice = originalPrice - discountAmount;
        return finalPrice;
    };

    return (
        <div className="city-mobile-pricing-overlay-container desktop-none bg-colour-white">
            <div className="city-mobile-pricing-overlay-inner-container">
                <div 
                    className="city-mobile-pricing-overlay-header-container"
                    onClick={() => toggleAccordion()}
                >
                    <img
                        src={Cappuccino}
                        className="city-selection-mobile-coffee"
                        alt="Cappuccino"
                    />
                    <div className="city-mobile-pricing-overlay-header-text-container">
                        <h3 className="label-semibold-caps colour-white block-text margin-x-x-x-small">
                            Upgrade Summary
                        </h3>
                        <span className="body-regular colour-secondary">
                            Coffee Clozers Premuim
                        </span>
                    </div>
                    <div className={"city-mobile-pricing-overlay-chevron-container cursor-pointer " + (mobileAccordion === false ? "city-selection-mobile-reverse" : "")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.6364 2.34983C23.1516 1.88316 22.3656 1.88316 21.8808 2.34983L12 11.8613L2.11917 2.34983C1.63438 1.88316 0.848381 1.88316 0.363592 2.34983C-0.121197 2.8165 -0.121197 3.57311 0.363592 4.03978L12 15.2412L23.6364 4.03978C24.1212 3.57312 24.1212 2.8165 23.6364 2.34983Z" fill="#2F4858"/>
                        </svg>
                    </div>
                </div>
                <div className={"city-selection-mobile-cities-container " + (mobileAccordion === false ? "city-selection-hide-mobile-accordion" : "city-selection-show-mobile-accordion")}>
                    <div className="pricing-card-bottom-inner-container margin-medium">
                        <div className="pricing-card-bottom-left-container">
                            <h3 className="body-semibold colour-primary margin-x-x-small">
                                {plan.title}
                            </h3>
                            <h4 className="body-semibold colour-quaternary">
                                {plan.label}
                            </h4>
                        </div>
                        <div className="pricing-card-bottom-right-container">
                            {
                                plan.months > 1 ?
                                <div className="button-row">
                                    <span className="body-regular colour-secondary block-text subtext-margin-right strike-through">
                                        {formatterLong.format(plan.originalPrice).replace(".00", "")}
                                    </span>
                                    <span className="body-semibold colour-primary">
                                        {formatterLong.format(plan.price).replace(".00", "")}
                                    </span>
                                </div>
                                :
                                <div>
                                    <span className="body-semibold colour-primary">
                                        {formatterLong.format(plan.price).replace(".00", "")}
                                    </span>
                                    <span className="label-regular colour-secondary">
                                        /month
                                    </span>
                                </div>
                            }
                            {
                                checkout === true && plan.months > 1 ?
                                <span className="label-regular colour-secondary">
                                    x{plan.months} months
                                </span>
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        discountApplied !== null ?
                        <div className="pricing-card-bottom-inner-container margin-x-large">
                            <div className="pricing-card-bottom-left-container">
                                <h3 className="body-semibold colour-primary margin-x-small">
                                    Promo code
                                </h3>
                            </div>
                            <div className="pricing-card-bottom-right-container">
                                <span className="body-regular colour-secondary">
                                    -{formatterLong.format(calculateDiscountedAmount()).replace(".00", "")}
                                </span>
                                <span className="label-semibold colour-primary">
                                    Additional {discountApplied.discount}% off
                                </span>
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        checkout === true && plan.months > 1 ?
                        <div className="pricing-card-supplementary-value margin-top-small margin-large">
                            <div className="button-row margin-x-small">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4995 1.90933C16.1415 2.51658 16.1696 3.52926 15.5623 4.1712L6.22901 14.0379C5.93211 14.3517 5.52089 14.5322 5.08888 14.5382C4.65687 14.5442 4.2408 14.3752 3.93529 14.0697L0.468629 10.6031C-0.15621 9.97821 -0.15621 8.96515 0.468629 8.34031C1.09347 7.71547 2.10653 7.71547 2.73137 8.34031L5.0348 10.6437L13.2376 1.97216C13.8449 1.33022 14.8576 1.30209 15.4995 1.90933Z" fill="#68AB8E"/>
                                </svg>
                                <span className="body-regular colour-primary block-text subtext-margin-left-large">
                                    No automatic renewal
                                </span>
                            </div>
                            <div className="button-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4995 1.90933C16.1415 2.51658 16.1696 3.52926 15.5623 4.1712L6.22901 14.0379C5.93211 14.3517 5.52089 14.5322 5.08888 14.5382C4.65687 14.5442 4.2408 14.3752 3.93529 14.0697L0.468629 10.6031C-0.15621 9.97821 -0.15621 8.96515 0.468629 8.34031C1.09347 7.71547 2.10653 7.71547 2.73137 8.34031L5.0348 10.6437L13.2376 1.97216C13.8449 1.33022 14.8576 1.30209 15.4995 1.90933Z" fill="#68AB8E"/>
                                </svg>
                                <span className="body-regular colour-primary block-text subtext-margin-left-large">
                                    Money-back guarantee within 14 days!
                                </span>
                            </div>
                        </div>
                        :
                        checkout === true && plan.months === 1 ?
                        <div className="pricing-card-renewal-container margin-x-small">
                            <div className="pricing-card-total-container margin-x-small">
                                <div className="pricing-card-total-label-container">
                                    <span className="body-semibold colour-primary">
                                        Renews
                                    </span>
                                </div>
                                <div className="pricing-card-total-text-container">
                                    <span className="body-regular colour-primary">
                                        {endDate}
                                    </span>
                                </div>
                            </div>
                            <span className="body-regular colour-primary">
                                Will renew monthly until you cancel.
                            </span>
                        </div>
                        :
                        null
                    }
                </div>
                {
                    mobileAccordion === true ?
                    <div className="city-selection-divider-container margin-tops">
                    </div>
                    :
                    null
                }
                <div className={"city-selection-mobile-totals-container margin-medium " + (mobileAccordion === false ? "city-selection-mobile-totals-padding" : "")}>
                    <div className="pricing-card-total-label-container">
                        <span className="body-semibold colour-primary">
                            Total
                        </span>
                    </div>
                    <div className={"pricing-card-total-text-container " + (checkout === true ? "margin-x-small" : "")}>
                        {
                            checkout === false ?
                            <span className="body-regular colour-sceondary text-italic block-text text-align-right">
                                Total will be calculated based on <br/>
                                which plan you choose (next step)
                            </span>
                            :
                            checkout === true && total === 0 ?
                            <span className="body-regular colour-secondary text-italic">
                                Select a plan to calculate total
                            </span>
                            :
                            <div className="pricing-card-total-inner-container">
                                <div className="button-row">
                                    {
                                        plan.months > 1 ?
                                        <span className="body-regular colour-secondary block-text subtext-margin-right strike-through">
                                            {formatterLong.format(plan.originalPrice * plan.months).replace(".00", "")}
                                        </span>
                                        :
                                        null
                                    }
                                    {
                                        discountApplied !== null ?
                                        <span className="body-semibold colour-primary">
                                            {formatterLong.format(calculateDiscountedTotal()).replace(".00", "")}
                                        </span>
                                        :
                                        <span className="body-semibold colour-primary">
                                            {formatterLong.format(total).replace(".00", "")}
                                        </span>
                                    }
                                </div>
                                {
                                    selectedPlan === null ?
                                    null
                                    :
                                    plan.months > 1 ?
                                    <span className="label-semibold-caps colour-primary block-text margin-top-x-small">
                                        {plan.months} MONTHS ACCESS
                                    </span>
                                    :
                                    <span className="label-regular colour-secondary">
                                        Today
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="city-selection-mobile-continue-button-container ">
                    <ActionButton
                        disabled={(loading === true) || (checkout === true && selectedPlan === null) || (payment === true && disabled === true) ? true : false}
                        onClick={() => payment === true && userData !== null && userData.creditCard === undefined ? saveCreditCard() : complete()}
                    >
                        {cta}
                    </ActionButton>
                </div>
            </div>
        </div>
    )
};

export default CountyMobilePricingOverlay;