import React from 'react';
import { percentageCompact } from '../styles/GlobalStyles';

function ExpensesCoveredBadge(props) {
    const item = props.item;
    const expensesCovered = item.houseHackingFeatures.ratioOfMortgageCovered;
    const bgColour = expensesCovered >= 1 ? "bg-colour-green" : expensesCovered >= 0.7 ? "bg-colour-yellow" : "bg-colour-red";

    return (
        <div className={"expenses-covered-badge-outer-container " + (bgColour)}>
            <div className="expenses-covered-badge-inner-container">
                <span className="label-semibold colour-primary block-text subtext-margin-right">
                    Exp. covered
                </span>
                <span className="label-regular colour-primary">
                    {percentageCompact.format(expensesCovered)}
                </span>
            </div>
        </div>
    )
};

export default ExpensesCoveredBadge;