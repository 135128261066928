import React, { useState } from 'react';
import '../styles/FinancialPreferencesBanner.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkUserId, recordEvent } from '../functions';

function FinancialPreferences() {

    const [showBanner, setShowBanner] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
	let state = location.state;
    
    const goToSettings = async() => {
        recordEvent("Clicked on financial preferences banner", {});
        const user = await checkUserId();
        if ( user.status === 200 ) {
            navigate("/preferences", {
                state: state
            });
        }
        else {
            navigate("/sign-up", {
                state: state
            });
        }
    };

    const dismiss = () => {
        recordEvent("Dismiss financial preferences banner", {});
        setShowBanner(false);
    };

    return (
        showBanner === true ?
        <div className="financial-preferences-banner-outer-container bg-colour-white margin-medium">
            <div className="financial-preferences-banner-inner-container">
                <span className="body-regular colour-primary block-text subtext-margin-right-large">
                    💡 Boost accuracy now! <span onClick={() => goToSettings()} className="body-semibold colour-link">Click here</span> to tailor your financial preferences.
                </span>
                <span 
                    className="label-semibold-caps colour-link"
                    onClick={() => dismiss()}
                >
                    DISMISS
                </span>
            </div>
        </div>
        :
        null
    )
};

export default FinancialPreferences;