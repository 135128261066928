import React from 'react';
import '../styles/BestValue.css';

function BestValue(props) {
    const text = props.text;
    return (
        <div className="best-value-container">
            <span className="body-regular colour-white block-text text-align-center">
                {text}
            </span>
        </div>
    )
}

export default BestValue;