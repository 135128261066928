import React, { useState } from 'react';
import '../styles/PartnerViewBar.css';
import { ChevronLeftThickWhite, CopyChainWhite, TickWhite } from '../assets';
import { useNavigate } from 'react-router-dom';
import { ExitScreen, PropertyStatus } from './';
import { propertyStatuses } from '../styles/GlobalStyles';
import { changePartnerPropertyStatus, recordEvent } from '../functions';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function PartnerViewBar(props) {
    const partnerData = props.partnerData;
    const property = props.property;
    const userDetails = props.userDetails;
    const setProperty = props.setProperty;
    const statusElement = propertyStatuses.findIndex(e => e.value === property.status) === -1 ? null : propertyStatuses[propertyStatuses.findIndex(e => e.value === property.status)];
    const [linkCopied, setLinkCopied] = useState(false);
    const [chooseStatus, setChooseStatus] = useState(false);
    const host = window.location.host;
    const listingURL = partnerData === null ? "" : `${host}/partner/${partnerData.id}/${property.zpid}?utm_source=partner&utm_medium=listing&utm_campaign=${partnerData.id}`;

    const navigate = useNavigate();

    const goBack = () => {
        recordEvent("Back To Dashboard", {});
        navigate('/partner/dashboard');
    };

    const editListing = () => {
        recordEvent("Edit Partner Listing", {});
        const index = partnerData.listings.findIndex(e => e.zpid === property.zpid);
        if ( index !== -1 ) {
            navigate("/partner/edit-listing", {
                state: {
                    property: property,
                    partnerData: partnerData,
                    userData: userDetails,
                    edit: true,
                    amendments: partnerData.listings.length === 0 ? null : partnerData.listings[index].amendments
                }
            })
        }
        else {
            navigate("/partner/dashboard");
        }
    };

    const changePartnerPropertyOption = async(val) => {
        if ( val.status === "sold" ) {
            markSold();
        }
        else {
            await changePartnerPropertyStatus(val, partnerData, navigate);
        }
    };

    const markSold = () => {
        recordEvent("Mark As Sold", {});
        const index = partnerData.listings.findIndex(e => e.zpid === property.zpid);
        navigate("/partner/sold", {
            state: {
                property: property,
                partnerData: partnerData,
                amendments: partnerData.listings.length === 0 ? null : partnerData.listings[index].amendments
            }
        });
    };

    const copyToClipboard = () => {
        recordEvent("Copy to Clipboard", {});
		setLinkCopied(true);

		setTimeout(() => {
			setLinkCopied(false);
		}, 10000);
	};

    const openStatus = () => {
        setChooseStatus(!chooseStatus);
    };

    const selectOption = (index, property) => {
        const propertyClone = structuredClone(property);
        propertyClone.status = propertyStatuses[index].value;
        setProperty(propertyClone);
        changePartnerPropertyOption(propertyClone);
    };

    return (
        <div className="partner-view-outer-container">
            <div className="partner-view-inner-container">
                <div 
                    className="partner-view-left-container cursor-pointer"
                    onClick={() => goBack()}
                >
                    <img
                        src={ChevronLeftThickWhite}
                        className="partner-view-back-button"
                        alt="Back"
                    />
                    <span className="body-regular colour-white">
                        Back to profile
                    </span>
                </div>
                <div className="partner-view-right-container">
                    <div className="partner-view-bar-text-spacing">
                        <span 
                            className="body-regular text-button-inverse colour-white cursor-pointer"
                            onClick={() => editListing()}
                        >
                            Edit listing
                        </span>
                    </div>
                    <CopyToClipboard 
                        text={listingURL}
                        onCopy={() => copyToClipboard()}
                    >
                        {
                            linkCopied === false ?
                            <div className="partner-view-bar-copy-icon-container text-button-inverse partner-view-bar-text-spacing">
                                <img
                                    src={CopyChainWhite}
                                    className="partner-view-bar-copy-icon subtext-margin-right"
                                    alt="Copy Link"
                                />
                                <span className="body-regular colour-white">
                                    Copy link
                                </span>
                            </div>
                            :
                            <div className="partner-view-bar-copy-icon-container text-button-inverse partner-view-bar-text-spacing">
                                <img
                                    src={TickWhite}
                                    className="partner-view-bar-copy-icon subtext-margin-right"
                                    alt="Copy Link"
                                />
                                <span className="body-regular colour-white">
                                    Link copied
                                </span>
                            </div>
                        }
                    </CopyToClipboard>
                    <div className="partner-view-status-container">
                        <PropertyStatus
                            text={statusElement !== null ? statusElement.label : ""}
                            bgColour={statusElement !== null ? statusElement.bgColour : ""}
                            openStatus={openStatus}
                            chooseStatus={chooseStatus}
                            selectOption={selectOption}
                            property={property}
                        />
                    </div>
                    {
                        chooseStatus === true ?
                        <ExitScreen
                            clickScreen={() => setChooseStatus(false)}
                        />
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
};

export default PartnerViewBar;