import React from 'react';
import moment from 'moment';

function TrialExpirationMobile(props) {
    const freeTrial = props.freeTrial;
    const endDate = freeTrial === null || freeTrial.length === 0 ? 0 : moment.unix(freeTrial[0].endDate.seconds);
    const today = new Date();
    const todaySeconds = moment.unix(today.getTime() / 1000);
    const freeTrialDays = freeTrial === null || freeTrial.length === 0 ? "" : endDate.diff(todaySeconds, 'days');
    const freeTrialEnding = freeTrialDays === 0 ? "today" : freeTrialDays + " days";

    return (
        freeTrial !== false && freeTrial !== null ?
        <div className="header-free-trial-container">
            <div className="header-tutorial-video-container">
            </div>
            {
                freeTrialDays < 0 ?
                <span className="body-regular colour-secondary header-free-trial-margin-text">
                    Trial expired
                </span>
                :
                <span className="body-regular colour-secondary header-free-trial-margin-text">
                    ⏳
                </span>
            }
            {
                freeTrialDays >= 0 ?
                    <span className="body-semibold text-link">
                    {freeTrialEnding}
                </span>
                :
                null
            }
        </div>
        :
        null
    )
}

export default TrialExpirationMobile;