import React from 'react';
import '../styles/TextBadge.css';

function TextBadge(props) {
    const bgColour = props.bgColour;
    const text = props.text;
    return (
        <div className={"text-badge-container " + (bgColour)}>
            <span className="body-regular colour-primary">
                {text}
            </span>
        </div>
    )
}

export default TextBadge;