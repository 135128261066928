import React, { useState, forwardRef } from 'react';
import '../styles/OffMarketCard.css';
import { OffMarketHouse, RedirectArrow, FaveUnselected, FaveSelected } from '../assets';
import { toggleFavourite, markPropertyViewed, recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';
import { percentage, stackedListCategory } from '../styles/GlobalStyles';
import { NewPropertyCharacteristics, OffMarketBadge, OffMarketBadgesTooltip } from './';

const OffMarketCard = forwardRef((props, ref) => {
    const item = props.item;
	const index = props.index;
	const userDetails = props.userDetails;
	const formatter = props.formatter;
	const abstract = props.abstract;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const cityId = props.cityId;
	const setHoverCard = props.setHoverCard;
	const propertyRefs = props.propertyRefs;
	const onboarding = props.onboarding;
	const onboardingStep = props.onboardingStep;
	const setCardClick = props.setCardClick;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const overlay = props.overlay;
	const setOverlay = props.setOverlay;
    const innerWidth = props.innerWidth;
	const propertyViewed = viewedProperties === null ? false : viewedProperties.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;
	const [favourites, setFavourites] = useState(userDetails === null ? [] : props.userDetails.favourites !== undefined ? props.userDetails.favourites : []);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
    const [distressTooltip, setDistressTooltip] = useState(false);
    const navigate = useNavigate();
    const googleLink = `https://www.google.com/maps/place/${item.address.streetAddress.replace(" ", "+")},+${item.address.city},+${item.address.state}+${item.address.zipcode}`;
    const zpidIndex = favourites.findIndex(e => e.zpid === item.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
    const category = stackedListCategory.findIndex(e => e.id === item.stackedList);
    const breakPointNumber = innerWidth > 2250 ? 3 : innerWidth > 1600 ? 4 : innerWidth > 1500 ? 3 : innerWidth > 1000 ? 2 : innerWidth > 900 ? 3 : 2;
    const formattedAddress = `${item.address.streetAddress.replaceAll(" ", "-")},${item.address.city},${item.address.state}-${item.address.zipcode}`;
    const cityObject = {
        cityId: cityId,
        city: item.address.city,
        state: item.address.state
    };

    const motivatedSeller = [
        {
            flag: item.motivatedSeller.absentee_flag === 0 ? false : true,
            icon: "🫥",
            label: "Absentee owner"
        },
        {
            flag: item.motivatedSeller.auction_flag === 0 ? false : true,
            icon: "🪧",
            label: "Auction"
        },
        {
            flag: item.motivatedSeller.death_flag === 0 ? false : true,
            icon: "🕊️",
            label: "Recent death"
        },
        {
            flag: item.motivatedSeller.free_clear_flag === 0 ? false : true,
            icon: "🧾",
            label: "Free & Clear"
        },
        {
            flag: item.motivatedSeller.high_equity_flag === 0 ? false : true,
            icon: "🐷🏦",
            label: "High Equity"
        },
        {
            flag: item.motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
            icon: "😶‍🌫️",
            label: "In-State Absentee owner"
        },
        {
            flag: item.motivatedSeller.inherited_flag === 0 ? false : true,
            icon: "🫴",
            label: "Inherited"
        },
        {
            flag: item.motivatedSeller.lien_flag === 0 ? false : true,
            icon: "🤝",
            label: "Lien"
        },
        {
            flag: item.motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
            icon: "💰",
            label: "Multiple Mortgages"
        },
        {
            flag: item.motivatedSeller.owner_occupied_flag === 0 ? true : false,
            icon: "🧓🏠",
            label: "Owner Occupied"
        },
        {
            flag: item.motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
            icon: "📉",
            label: "Pre-Foreclosure"
        },
        {
            flag: item.motivatedSeller.spousal_death_flag === 0 ? false : true,
            icon: "💍🕊️",
            label: "Spousal Death"
        },
        {
            flag: item.motivatedSeller.tax_lien_flag === 0 ? false : true,
            icon: "🗂️🤝",
            label: "Tax Lien"
        },
        {
            flag: item.motivatedSeller.vacant_flag === 0 ? false : true,
            icon: "🏚️",
            label: "Vacant"
        }
    ];

    const propertyValues = [
        {
            label: "EST. VALUE",
            value: formatter.format(item.price).replace(".00", "")
        },
        {
            label: "EST. EQUITY",
            value: percentage.format((item.equityPercent / 100))
        },
        {
            label: "CATEGORY",
            value: category !== -1 ? stackedListCategory[category].label : ""
        }
    ];


    const addHover = () => {
		setHoverCard(item.zpid);
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 3;
		}
	};

	const removeHover = () => {
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 1;
		}
		setHoverCard("");
	};

    const openGoogle = () => {
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

    const viewProperty = async(zpid, e, bgImage) => {
        const target = e.target;
		const targetClass = target.classList.value;
		if ( overlay === false ) {
			setOverlay(true);
		}
        else if ( target.parentNode.classList.value.includes("off-market-card-text-row") || targetClass.includes("favourite-icon") || target.parentNode.classList.value.includes("off-market-extra-badges-container") ) {
            return;
        }
		else if ( bgImage === true ) {
			if ( targetClass !== "favourite-icon " ) {
				if ( onboarding === true ) {
					if ( setCardClick !== null ) {
						setCardClick(true);
					}
					window.open(`/properties/${formattedAddress}/${zpid}`, '_blank');
		
					if ( temporaryId === false ) {
						const path = window.location.pathname;
						const newObject = {
							zpid: zpid,
							date: new Date()
						}
	
						if ( viewedProperties !== null ) {
							const newArray = viewedProperties.slice(0);
							newArray.push(newObject);
							setViewedProperties(newArray);
							await markPropertyViewed(zpid);
						}

                        recordEvent("Navigation", {
                            oldRoute: path,
                            newRoute: `/properties/${formattedAddress}/${zpid}`
                        })
                        recordEvent("Property Click - Off Market", {
							zpid: zpid,
							address: formattedAddress
						});
					}

					setTimeout(() => {
						if ( setCardClick !== null ) {
							setCardClick(false);
						}
					}, 500)
				}
			}
		}
		else {
			if ( onboarding === true ) {
				if ( setCardClick !== null ) {
					setCardClick(true);
				}
				window.open(`/properties/${formattedAddress}/${zpid}`, '_blank');
	
				if ( temporaryId === false ) {
					const newObject = {
						zpid: zpid,
						date: new Date()
					}
	
					if ( viewedProperties !== null ) {
						const newArray = viewedProperties.slice(0);
						newArray.push(newObject);
						setViewedProperties(newArray);
						await markPropertyViewed(zpid);
					}
		
					const path = window.location.pathname;

                    recordEvent("Navigation", {
                        oldRoute: path,
                        newRoute: `/properties/${formattedAddress}/${zpid}`
                    })
                    recordEvent("Property Click", {
                        zpid: zpid,
                        address: formattedAddress
                    });
				}
	
				setTimeout(() => {
					if ( setCardClick !== null ) {
						setCardClick(false);
					}
				}, 500)
			}
		}
	};

    return (
        <div 
            className={"new-property-card-outer-container " + (abstract === false && propertyViewed === true && onboarding === true ? "new-property-card-viewed-property" : "") + (distressTooltip === true ? " distress-tooltip-outer-container-position" : "")}
            onMouseOver={() => abstract === false ? addHover() : null}
            onMouseOut={() => abstract === false ? removeHover() : null}
        >
            <div className="new-property-card-inner-container">
                <div 
                    className="off-market-card-image-container"
                    onClick={(e) => viewProperty(item.zpid, e, false)}
                >
                    <div 
                        className="favourite-icon-container"
                        onClick={() => temporaryId === true ? setSignUpModal(true) : toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, setCardClick)}
                    >
                        <img
                            src={
                                    inFavourites === false ?
                                    FaveUnselected
                                    :
                                    FaveSelected
                                }
                            className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
                            alt="Add To Favourites"
                        />
                    </div>
                    <img
                        src={OffMarketHouse}
                        className="off-market-card-icon"
                        alt="Off Market"
                    />
                    <div 
                        className="off-market-card-text-row"
                        onClick={() => openGoogle()}
                    >
                        <span className="label-regular-caps colour-secondary">
                            OFF-MARKET
                        </span>
                        <img
                            src={RedirectArrow}
                            className="off-market-card-redirect-arrow"
                            alt="Redirect"
                        />
                    </div>
                    <div className={"off-market-badges-container " + (innerWidth < 900 ? "off-market-badges-mobile-container" : innerWidth < 1200 ? "off-market-badges-small-desktop-container" : innerWidth < 1600 ? "off-market-badges-medium-desktop-container" : "off-market-badges-large-desktop-container")}>
                        {
                            motivatedSeller.map((subItem, i) =>
                                subItem.flag === true && i <= item.motivatedSellerFourthIndex ?
                                    <OffMarketBadge
                                        emoji={subItem.icon}
                                        text={subItem.label}
                                        key={i}
                                    />
                                :
                                null
                            )
                        }
                        {
                            item.motivatedSellerCount > 4 ?
                            <div 
                                className="off-market-extra-badges-container"
                                onMouseOver={() => setDistressTooltip(true)}
                                onMouseLeave={() => setDistressTooltip(false)}
                            >
                                <span className="body-semibold text-link underline block-text">
                                    +{item.motivatedSellerCount - breakPointNumber}
                                </span>
                                {
                                    distressTooltip === true ?
                                    <OffMarketBadgesTooltip
                                        motivatedSeller={motivatedSeller}
                                    />
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div 
                    className="off-market-card-body-container"
                    onClick={(e) => viewProperty(item.zpid, e, false)}
                >
                    <div className="off-market-card-value-row">
                        {
                            propertyValues.map((subItem, i) =>
                                <div 
                                    className="off-market-card-value-element"
                                    key={i}
                                >
                                    <span className="label-regular-caps colour-secondary">
                                        {subItem.label}
                                    </span>
                                    <span className="heading-small-semibold text-link">
                                        {subItem.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    <NewPropertyCharacteristics
                        item={item}
                    />
                    <div 
                        className={"new-property-card-badges-container " + (onboarding === false && index === 0 && onboardingStep === 0 ? "new-property-card-badges-onboarding" : "")}
                        onClick={(e) => viewProperty(item.zpid, e, false)}
                    >
                        <div className={"new-property-card-cash-on-cash-badge-container " + ((typeof(item.financials.cashOnCash) === "number" && item.financials.cashOnCash > 0) || (typeof(item.financials.cashOnCash) === "string" && item.financials.lowerCoCR > 0) ? "new-property-card-cash-on-cash-positive" : "new-property-card-cash-on-cash-negative")}>
                            <span className="new-property-card-cash-on-cash-badge-label">
                                Cash on Cash{' '}
                                <span className="new-property-card-cash-on-cash-badge-value">
                                    {
                                        typeof(item.financials.cashOnCash) === "number" ?
                                        `${item.financials.cashOnCash.toFixed(0)} %`
                                        :
                                        item.financials.cashOnCash
                                    }
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default OffMarketCard;