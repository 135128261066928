import React, { useState } from 'react';
import '../styles/ComparePropertyNeighbourhood.css';
import { Accordion, AccordionDetails, AccordionSummary, colour } from '../styles/GlobalStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProgressBar, NeighbourhoodBadge } from '../components';

function ComparePropertyNeighbourhood(props) {
    const properties = props.properties;
    const propertyNeighbourhoods = props.propertyNeighbourhoods;
    const neighbourhoodData = props.neighbourhoodData;
    const [expanded, setExpanded] = useState(true);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const styles = {
		accordionSummary: {
			width: '100px',
			boxShadow: 'none', 
			padding: 0,
			margin: '0 auto'
		},
		typography: {
			flexShrink: 0, 
			fontFamily: "Rubik",
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '28px',
			letterSpacing: '0em',
			textAlign: 'left',
			color: colour.blueBlue03
		}
	};

    return (
        <div className="compare-property-neighbourhood-outer-container">
            <div className={"compare-property-neighbourhood-inner-container " + (expanded === false ? "compare-property-neighbourhood-inner-container-bottom-padding" : "")}>
                <Accordion 
                    expanded={expanded} 
                    onChange={handleChange}
                    sx={{ boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-3-bh-content`}
                        id={`panel-3-bh-header`}
                        sx={styles.accordionSummary}
                    >
                        <h2 className="heading-large-semibold colour-link">
                            Neighborhood
                        </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="compare-property-neighbourhood-body-container">
                            {
                                propertyNeighbourhoods.map((item, index) =>
                                    <div 
                                        className="compare-property-neighbourhood-category"
                                        key={index}
                                    >
                                        {
                                            item.label !== "" ?
                                            <div className="compare-property-neighbourhood-category-title-container">
                                                <h3 className="heading-small-semibold text-link">
                                                    {item.label}
                                                </h3>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="compare-property-neighbourhood-row-container">
                                            {
                                                item.properties.map((property, i) =>
                                                    <div 
                                                        className="compare-property-neighbourhood-subcategory-element"
                                                        key={i}
                                                    >
                                                        <div className="compare-property-neighbourhood-subcategory-label-container">
                                                            <h4 className="body-regular colour-secondary">
                                                                {property.label}
                                                            </h4>
                                                        </div>
                                                        <div className="compare-property-neighbourhood-right-container">
                                                            {
                                                                property.value.map((subItem, i2) =>
                                                                    i < 2 && index === 0 ?
                                                                    <div 
                                                                        className="compare-property-neighbourhood-right-element"
                                                                        key={i2}
                                                                    >
                                                                        <div className="compare-property-neighbourhood-property-badge">
                                                                            <NeighbourhoodBadge
                                                                                item={properties[i2]}
                                                                                neighbourhoodData={neighbourhoodData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    i === 0 ?
                                                                    <div 
                                                                        className="compare-property-neighbourhood-right-element"
                                                                        key={i2}
                                                                    >
                                                                        <span className="body-regular colour-primary">
                                                                            {subItem.value}
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    i === 1 ?
                                                                    <div 
                                                                        className="compare-property-neighbourhood-schools-column-container"
                                                                        key={i2}
                                                                    >
                                                                        {
                                                                            subItem.value.map((school, i3) =>
                                                                                <div 
                                                                                    className="schools-element-container"
                                                                                    key={i3}
                                                                                >
                                                                                    <div className="schools-element-left-container">
                                                                                        <h4 className="body-regular colour-primary">
                                                                                            {school.name}
                                                                                        </h4>
                                                                                        <div className="schools-element-text-row">
                                                                                            <span className="body-regular colour-secondary">
                                                                                                Grades {school.grades}
                                                                                            </span>
                                                                                            <span className="body-regular colour-secondary dot-spacing">
                                                                                                •
                                                                                            </span>
                                                                                            <span className="body-regular colour-secondary">
                                                                                                Distance: {school.distance} mi
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="schools-element-right-container">
                                                                                        <ProgressBar
                                                                                            item={school}
                                                                                        />
                                                                                        <span className="label-semibold colour-primary schools-score-text">
                                                                                            {school.rating}/10
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default ComparePropertyNeighbourhood;