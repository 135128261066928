import React, { useEffect, useState } from 'react';
import '../styles/MarketExplore.css';
import { Header, Footer, MarketDataChart, DefaultTooltip, Tabs, MarketAnalysisBottom, LoadingStatic, NewSwitch, Loading } from '../components';
import { cloudFunctionV2, recordEvent, timer } from '../functions';
import { useNavigate } from 'react-router-dom';
import { ActionButton, CustomSelect, colour, formatterLong, numberFormatter, percentage } from '../styles/GlobalStyles';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import { Download, Exit, Hamburger, Info } from '../assets';
import { CityEmoji } from '../assets/city-emojis';
import * as XLSX from 'xlsx';

function MarketExplore() {

    const navigate = useNavigate();
    const [loadingStage, setLoadingStage] = useState(0);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [mobileSlider, setMobileSlider] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [top10, setTop10] = useState([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [appreciation, setAppreciation] = useState(true);
    const [appreciationWeighting, setAppreciationWeighting] = useState(1);
    const [hotness, setHotness] = useState(false);
    const [hotnessWeighting, setHotnessWeighting] = useState(0.2);
    const [populationGrowth, setPopulationGrowth] = useState(false);
    const [populationGrowthWeighting, setPopulationGrowthWeighting] = useState(0.2);
    const [priceRentRatio, setPriceRentRatio] = useState(false);
    const [priceToRentRatioWeighting, setPriceToRentRatioWeighting] = useState(0.2);
    const [householdIncomeRatio, setHouseholdIncomeRatio] = useState(false);
    const [householdIncomeWeighting, setHouseholdIncomeWeighting] = useState(0.2);
    const [selectedTop10, setSelectedTop10] = useState(0);
    const [geography, setGeography] = useState("all");
    const [population, setPopulation] = useState("all");
    const [medianListingPrice, setMedianListingPrice] = useState("all");
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedCityData, setSelectedCityData] = useState([]);
    const [selectedCityYears, setSelectedCityYears] = useState([]);
    const [lastUpdated, setLastUpdated] = useState("");
    const [showTooltip, setShowTooltip] = useState(null);
    const [viewSources, setViewSources] = useState(false);
    const [metroView, setMetroView] = useState(true);
    const [geographyChanged, setGeographyChanged] = useState(false);
    const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

    const marketOptions = ["Metros", "Counties"];

    const toggleMobileSlider = () => {
        setMobileSlider(!mobileSlider);
    };
    
    const loadingStages = [
        "Getting Economic data from the U.S. Census Bureau",
        "Loading Vacancy & rent data from the Apartment List",
        "Loading Market data from the Realtor"
    ];

    const tabs = [
        {
            label: "Analysis",
            value: false
        },
        {
            label: "Sources",
            value: true
        }
    ];

    const defaultFilters = [
        {
            label: "median_listing_price_yy",
            active: appreciation,
            weighting: appreciationWeighting,
            highToLow: true
        },
        {
            label: "hotness_score",
            active: hotness,
            weighting: hotnessWeighting,
            highToLow: true
        },
        {
            label: "population_growth",
            active: populationGrowth,
            weighting: populationGrowthWeighting,
            highToLow: true
        },
        {
            label: "price_to_rent_ratio",
            active: priceRentRatio,
            weighting: priceToRentRatioWeighting,
            highToLow: false
        },
        {
            label: "household_income_to_rent_ratio",
            active: householdIncomeRatio,
            weighting: householdIncomeWeighting,
            highToLow: true
        }
    ];

    const charts = [
        {
            label: "Median list price",
            field: "median_listing_price"
        },
        {
            label: "Median days on market",
            field: "median_days_on_market"
        },
        {
            label: "Active listing count",
            field: "active_listing_count"
        },
        {
            label: "New listing count",
            field: "new_listing_count"
        },
        {
            label: "Price reduced count",
            field: "price_reduced_count"
        },
        {
            label: "Median listing price per sqft",
            field: "median_listing_price_per_square_foot"
        },
        {
            label: "Supply score",
            field: "supply_score"
        },
        {
            label: "Demand score",
            field: "demand_score"
        }
    ];

    const metricsSources = [
        {
            title: "Appreciation",
            description: "The increase in the value of a property over time. This creates equity for the owner and potentially a profit upon sale."
        },
        {
            title: "Market Hotness Score (realtor.com)",
            description: "An index used by realtors to gauge the competitiveness of a housing market. It considers factors like demand, inventory, and days on market, often with higher scores indicating a seller's market."
        },
        {
            title: "Vacancy Index",
            description: "The percentage of rental units in a specific area that are currently unoccupied. A low vacancy rate indicates high demand and potentially higher rents, while a high rate suggests more availability and potentially lower rent."
        },
        {
            title: "Price-to-Rent Ratio",
            description: "The comparison of a property's purchase price to the potential rental income it could generate. A high ratio indicates a potentially better investment for appreciation, while a low ratio suggests potentially better rental income compared to purchase cost."
        },
        {
            title: "Household-Income-to-Rent Ratio",
            description: "The percentage of a household's gross monthly income dedicated to covering rent expenses. It's a key metric for assessing affordability and financial health in relation to housing costs. Generally, a lower ratio indicates a more manageable housing burden."
        }
    ];

    const geographySources = [
        {
            title: "Metropolitan Statistical Area (MSA)",
            description: "This is a geographical designation defined by the US Office of Management and Budget. It represents an urbanized area with at least 50,000 people and its surrounding communities that are economically and socially integrated. These areas are used for statistical purposes and inform studies like population trends and economic analysis."
        },
        {
            title: "Geographic Region",
            description: "The U.S. Census Bureau groups the 50 states and the District of Columbia, for statistical purposes, into four geographic regions and nine divisions based on geographic proximity."
        }
    ];

    const dataSources = [
        {
            title: "U.S. Census Bureau",
            firstText: "American Community Survey (ACS) 5-year;",
            sourceLink: "https://www.census.gov/programs-surveys/acs/data/data-via-api.html",
            secondText: "Updated yearly (2022 latest)"
        },
        {
            title: "ApartmentList",
            firstText: "Vacancy Index & Rent Research;",
            sourceLink: "https://www.apartmentlist.com/research/apartment-list-vacancy-index",
            secondText: "Updated monthly"
        },
        {
            title: "Realtor",
            firstText: "Residential real estate data;",
            sourceLink: "https://www.realtor.com/research/data/",
            secondText: "Updated monthly"
        }
    ];

    useEffect(() => {
        document.title = "Market Analysis";
        const fetchUser = async() => {
            setFirstLoad(true);
            queryMarketData();
            runTimer();
        };

        const runTimer = async() => {
            await timer(2500);
            setLoadingStage(1);
            await timer(2500);
            setLoadingStage(2);
        };

        const queryMarketData = async() => {
            setLoading(true);
            
            const s3URL = process.env.REACT_APP_AWS_QUERY_URL;
            const filePath = metroView === true ? "msa_market_data" : "county_market_data";
			const params = {
                type: "s3csv",
                s3Data: {
                    key: `output/app_market_explore/${filePath}.csv`,
				    bucketName: "residentialpropertydata"
                }
			};
			const queryData = await cloudFunctionV2(s3URL, params);
            if ( queryData.status === 200 ) {
                setErrorMessage("");
                const body = queryData.body;
                setRawData(body);
                for (let index = 0; index < body.length; index++) {
                    const element = body[index];
                    const reportingDate = element.reporting_date;
                    const timestamp = moment(reportingDate).valueOf();
                    body[index].reporting_date_ts = timestamp;
                }
                const fieldName = "reporting_date_ts";
                const maxValue = body.reduce((max, obj) => Math.max(max, obj[fieldName]), body[0][fieldName]);
                const maxDate = moment(maxValue).format("YYYY-MM-DD");
                const maxDateFormatted = moment(maxValue).format("MMMM YYYY");
                setLastUpdated(maxDateFormatted);

                const maxDateArray = [];
                for (let index = 0; index < body.length; index++) {
                    const element = body[index];
                    if ( element.reporting_date === maxDate ) {
                        maxDateArray.push(element);
                    }
                }
                setYearData(maxDateArray);

                if ( appreciation === true ) {
                    const filterFieldName = "median_listing_price_yy";
                    const highToLow = true;
                    await filterEffect(maxDateArray, filterFieldName, highToLow, body);
                }
                else if ( hotness === true ) {
                    const filterFieldName = "hotness_score";
                    const highToLow = false;
                    await filterEffect(maxDateArray, filterFieldName, highToLow, body);
                }
                else if ( populationGrowth === true ) {
                    const filterFieldName = "population_growth";
                    const highToLow = true;
                    await filterEffect(maxDateArray, filterFieldName, highToLow, body);
                }
                else if ( priceRentRatio === true ) {
                    const filterFieldName = "price_to_rent_ratio";
                    const highToLow = false;
                    await filterEffect(maxDateArray, filterFieldName, highToLow, body);
                }
                else if ( householdIncomeRatio === true ) {
                    const filterFieldName = "household_income_to_rent_ratio";
                    const highToLow = true;
                    await filterEffect(maxDateArray, filterFieldName, highToLow, body);
                }
            }
            else if ( queryData.status === 405 ) {
                setErrorMessage(`${queryData.error}.`);
            }
            setLoading(false);
            setLoadingStage(0);
            setFirstLoadCompleted(true);
        };

        const filterEffect = (effectData, fieldName, highToLow, body) => {
            const checkedData = effectData.filter(item => item[fieldName] !== "" && item[fieldName] !== null && item[fieldName] !== undefined && item[fieldName] !== "N/A" && item[fieldName] !== "NA");
            if ( highToLow === true ) {
                // highest to lowest
                checkedData.sort((a,b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0));
            }
            else {
                // lowest to highest
                checkedData.sort((a,b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0));
            }
            const top10Array = checkedData.slice(0, 10);
            formatSelectedCityDataEffect(top10Array[selectedTop10].metro, body)
            setTop10(top10Array);
        };

        const formatSelectedCityDataEffect = (metro, data) => {
            const cityData = [];
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if ( element.metro === metro && element.reporting_date_ts > 1577836800000 ) {
                    cityData.unshift(element);
                }
            };
            setSelectedCityData(cityData);
    
            const cityYears = [];
            for (let index = 0; index < cityData.length; index++) {
                const element = cityData[index];
                const year = moment(element.reporting_date_ts).format("MMM YYYY");
                const yearIndex = cityYears.indexOf(year);
                if ( yearIndex === -1 ) {
                    cityYears.push(year);
                }
            };
            setSelectedCityYears(cityYears)
        };

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);

        if ( firstLoad === false ) {
            fetchUser();
        }
        else if ( geographyChanged === true ) {
            queryMarketData();
            setGeographyChanged(false);
        }
    }, [
        navigate,
        appreciation,
        hotness,
        populationGrowth,
        priceRentRatio,
        householdIncomeRatio,
        selectedTop10,
        firstLoad,
        metroView,
        geographyChanged
    ]);

    const longTermGrowth = [
        {
            label: "Appreciation 📈"
        },
        {
            label: "Hotness 🔥"
        },
        {
            label: "Population Growth 👥"
        }
    ];

    const columns = [
        {
            label: "Metro area"
        },
        {
            label: "Region"
        },
        {
            label: "Median listing price"
        },
        {
            label: "Median listing price YoY"
        },
        {
            label: "Median sq. feet"
        },
        {
            label: "Vacancy rate"
        },
        {
            label: "Rent"
        },
        {
            label: "Median household income"
        },
        {
            label: "Total population"
        },
        {
            label: "Price to rent ratio"
        },
        {
            label: "Household income to rent ratio"
        },
        {
            label: "Hotness score"
        }
    ];

    const tooltipDescriptions = [
        "Appreciation is an indicator of an emerging market, in which listing price increases YoY. (Source: Realtor)",
        "The demand score is an index representing a metro’s unique listing page viewers per property ranking compared to other metros. (Source: Realtor)",
        "Total population is an indicator of an emerging market, in which listing total residents increases. (Source: Census)",
        "Price-to-rent ratio is an indicator on likelihood return on investment. (Source: Realtor, Apartmentlist)",
        "Household-Income-to-rent ratio is an indicator on affordability. (Source: Census, Apartmentlist)",
        "Four defined U.S. geographic regions. (Source: CDC Gov)",
        "Total population category. (Source: Census)",
        "Median listing price. (Source: Realtor)"
    ];
    
    const weightingOptions = [
        0,
        0.1,
        0.2,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7,
        0.8,
        0.9,
        1
    ];
    
    const geographies = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Midwest",
            value: "midwest"
        },
        {
            label: "North East",
            value: "northeast"
        },
        {
            label: "West",
            value: "west"
        },
        {
            label: "South",
            value: "south"
        }
    ];

    const populations = [
        {
            label: "All",
            value: "all",
            min: 0,
            max: 1000000000
        },
        {
            label: "500k - 1M",
            value: "between 500K-1mil",
            min: 500000,
            max: 1000000
        },
        {
            label: "Under 500k",
            value: "under 500K",
            min: 0,
            max: 500000
        },
        {
            label: "Over 1M",
            value: "over 1mil",
            min: 1000000,
            max: 1000000000
        }
    ];

    const medianListingPrices = [
        {
            label: "All",
            value: "all",
            min: 0,
            max: 1000000000
        },
        {
            label: "Under $250k",
            value: "under 250K",
            min: 0,
            max: 250000
        },
        {
            label: "Between $250k - $500k",
            value: "between 250K-500K",
            min: 250000,
            max: 500000
        },
        {
            label: "Between $500k - $750k",
            value: "between 500K-750K",
            min: 500000,
            max: 750000
        },
        {
            label: "Between $750k - $1M",
            value: "between 750k-1mil",
            min: 750000,
            max: 1000000
        },
        {
            label: "Over $1M",
            value: "over 1mil",
            min: 1000000,
            max: 1000000000
        }
    ];

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
	};

    const selectTab = (val) => {
        setViewSources(val);
    };

    const changeLongTermGrowth = (index) => {
        if ( index === 0 ) {
            setAppreciation(!appreciation);
            if ( !appreciation === true ) {
                const filters = [
                    {
                        label: "median_listing_price_yy",
                        active: true,
                        weighting: appreciationWeighting,
                        highToLow: true
                    },
                    {
                        label: "hotness_score",
                        active: hotness,
                        weighting: hotnessWeighting,
                        highToLow: true
                    },
                    {
                        label: "population_growth",
                        active: populationGrowth,
                        weighting: populationGrowthWeighting,
                        highToLow: true
                    },
                    {
                        label: "price_to_rent_ratio",
                        active: priceRentRatio,
                        weighting: priceToRentRatioWeighting,
                        highToLow: false
                    },
                    {
                        label: "household_income_to_rent_ratio",
                        active: householdIncomeRatio,
                        weighting: householdIncomeWeighting,
                        highToLow: true
                    }
                ];
                filterData(geography, population, medianListingPrice, yearData, filters);
            }
            else {
                const clone = [...defaultFilters];
                clone[0].active = false;
                filterData(geography, population, medianListingPrice, yearData, clone);
            }
        }
        else if ( index === 1 ) {
            setHotness(!hotness);
            if ( !hotness === true ) {
                const filters = [
                    {
                        label: "median_listing_price_yy",
                        active: appreciation,
                        weighting: appreciationWeighting,
                        highToLow: true
                    },
                    {
                        label: "hotness_score",
                        active: true,
                        weighting: hotnessWeighting,
                        highToLow: true
                    },
                    {
                        label: "population_growth",
                        active: populationGrowth,
                        weighting: populationGrowthWeighting,
                        highToLow: true
                    },
                    {
                        label: "price_to_rent_ratio",
                        active: priceRentRatio,
                        weighting: priceToRentRatioWeighting,
                        highToLow: false
                    },
                    {
                        label: "household_income_to_rent_ratio",
                        active: householdIncomeRatio,
                        weighting: householdIncomeWeighting,
                        highToLow: true
                    }
                ];
                filterData(geography, population, medianListingPrice, yearData, filters);
            }
            else {
                const clone = [...defaultFilters];
                clone[1].active = false;
                filterData(geography, population, medianListingPrice, yearData, clone);
            }
        }
        else if ( index === 2 ) {
            setPopulationGrowth(!populationGrowth);
            if ( !populationGrowth === true ) {
                const filters = [
                    {
                        label: "median_listing_price_yy",
                        active: appreciation,
                        weighting: appreciationWeighting,
                        highToLow: true
                    },
                    {
                        label: "hotness_score",
                        active: hotness,
                        weighting: hotnessWeighting,
                        highToLow: true
                    },
                    {
                        label: "population_growth",
                        active: true,
                        weighting: populationGrowthWeighting,
                        highToLow: true
                    },
                    {
                        label: "price_to_rent_ratio",
                        active: priceRentRatio,
                        weighting: priceToRentRatioWeighting,
                        highToLow: false
                    },
                    {
                        label: "household_income_to_rent_ratio",
                        active: householdIncomeRatio,
                        weighting: householdIncomeWeighting,
                        highToLow: true
                    }
                ];
                filterData(geography, population, medianListingPrice, yearData, filters);
            }
            else {
                const clone = [...defaultFilters];
                clone[2].active = false;
                filterData(geography, population, medianListingPrice, yearData, clone);
            }
        }
    };

    const changeWeighting = (val, index) => {
        if ( index === 0 ) {
            setAppreciationWeighting(val);
        }
        else if ( index === 1 ) {
            setHotnessWeighting(val);
        }
        else if ( index === 2 ) {
            setPopulationGrowthWeighting(val);
        }
        else if ( index === 3 ) {
            setPriceToRentRatioWeighting(val);
        }
        else if ( index === 4 ) {
            setHouseholdIncomeWeighting(val);
        }

        const filters = [
            {
                label: "median_listing_price_yy",
                active: appreciation,
                weighting: appreciationWeighting,
                highToLow: true
            },
            {
                label: "hotness_score",
                active: hotness,
                weighting: hotnessWeighting,
                highToLow: true
            },
            {
                label: "population_growth",
                active: populationGrowth,
                weighting: populationGrowthWeighting,
                highToLow: true
            },
            {
                label: "price_to_rent_ratio",
                active: priceRentRatio,
                weighting: priceToRentRatioWeighting,
                highToLow: false
            },
            {
                label: "household_income_to_rent_ratio",
                active: householdIncomeRatio,
                weighting: householdIncomeWeighting,
                highToLow: true
            }
        ];
        filters[index].weighting = val;
        filterData(geography, population, medianListingPrice, yearData, filters);
    };

    const onChangeTop10 = (val) => {
        setSelectedTop10(val)
        formatSelectedCityData(top10[val].metro);
    };

    const changeGeography = (val) => {
        setGeography(val);
        const filters = [
            {
                label: "median_listing_price_yy",
                active: appreciation,
                weighting: appreciationWeighting,
                highToLow: true
            },
            {
                label: "hotness_score",
                active: hotness,
                weighting: hotnessWeighting,
                highToLow: true
            },
            {
                label: "population_growth",
                active: populationGrowth,
                weighting: populationGrowthWeighting,
                highToLow: true
            },
            {
                label: "price_to_rent_ratio",
                active: priceRentRatio,
                weighting: priceToRentRatioWeighting,
                highToLow: false
            },
            {
                label: "household_income_to_rent_ratio",
                active: householdIncomeRatio,
                weighting: householdIncomeWeighting,
                highToLow: true
            }
        ];
        filterData(val, population, medianListingPrice, yearData, filters);
    };

    const changePopulation = (val) => {
        setPopulation(val);
        const filters = [
            {
                label: "median_listing_price_yy",
                active: appreciation,
                weighting: appreciationWeighting,
                highToLow: true
            },
            {
                label: "hotness_score",
                active: hotness,
                weighting: hotnessWeighting,
                highToLow: true
            },
            {
                label: "population_growth",
                active: populationGrowth,
                weighting: populationGrowthWeighting,
                highToLow: true
            },
            {
                label: "price_to_rent_ratio",
                active: priceRentRatio,
                weighting: priceToRentRatioWeighting,
                highToLow: false
            },
            {
                label: "household_income_to_rent_ratio",
                active: householdIncomeRatio,
                weighting: householdIncomeWeighting,
                highToLow: true
            }
        ];
        filterData(geography, val, medianListingPrice, yearData, filters);
    };

    const changeMedianListingPrice = (val) => {
        setMedianListingPrice(val);
        const filters = [
            {
                label: "median_listing_price_yy",
                active: appreciation,
                weighting: appreciationWeighting,
                highToLow: true
            },
            {
                label: "hotness_score",
                active: hotness,
                weighting: hotnessWeighting,
                highToLow: true
            },
            {
                label: "population_growth",
                active: populationGrowth,
                weighting: populationGrowthWeighting,
                highToLow: true
            },
            {
                label: "price_to_rent_ratio",
                active: priceRentRatio,
                weighting: priceToRentRatioWeighting,
                highToLow: false
            },
            {
                label: "household_income_to_rent_ratio",
                active: householdIncomeRatio,
                weighting: householdIncomeWeighting,
                highToLow: true
            }
        ];
        filterData(geography, population, val, yearData, filters);
    };

    const changeProfitability = () => {
        setPriceRentRatio(!priceRentRatio);
        if ( !priceRentRatio === true ) {
            const filters = [
                {
                    label: "median_listing_price_yy",
                    active: appreciation,
                    weighting: appreciationWeighting,
                    highToLow: true
                },
                {
                    label: "hotness_score",
                    active: hotness,
                    weighting: hotnessWeighting,
                    highToLow: true
                },
                {
                    label: "population_growth",
                    active: populationGrowth,
                    weighting: populationGrowthWeighting,
                    highToLow: true
                },
                {
                    label: "price_to_rent_ratio",
                    active: true,
                    weighting: priceToRentRatioWeighting,
                    highToLow: false
                },
                {
                    label: "household_income_to_rent_ratio",
                    active: householdIncomeRatio,
                    weighting: householdIncomeWeighting,
                    highToLow: true
                }
            ];
            filterData(geography, population, medianListingPrice, yearData, filters);
        }
        else {
            const clone = [...defaultFilters];
            clone[3].active = false;
            filterData(geography, population, medianListingPrice, yearData, clone);
        }
    };

    const changeAffordability = () => {
        setHouseholdIncomeRatio(!householdIncomeRatio);
        if ( !householdIncomeRatio === true ) {
            const filters = [
                {
                    label: "median_listing_price_yy",
                    active: appreciation,
                    weighting: appreciationWeighting,
                    highToLow: true
                },
                {
                    label: "hotness_score",
                    active: hotness,
                    weighting: hotnessWeighting,
                    highToLow: true
                },
                {
                    label: "population_growth",
                    active: populationGrowth,
                    weighting: populationGrowthWeighting,
                    highToLow: true
                },
                {
                    label: "price_to_rent_ratio",
                    active: priceRentRatio,
                    weighting: priceToRentRatioWeighting,
                    highToLow: false
                },
                {
                    label: "household_income_to_rent_ratio",
                    active: true,
                    weighting: householdIncomeWeighting,
                    highToLow: true
                }
            ];
            filterData(geography, population, medianListingPrice, yearData, filters);
        }
        else {
            const clone = [...defaultFilters];
            clone[4].active = false;
            filterData(geography, population, medianListingPrice, yearData, clone);
        }
    };

    const filterData = (geog, pop, listPrice, preData, filters) => {
        const filteredData = [];
        for (let index = 0; index < preData.length; index++) {
            const element = preData[index];
            const geogIndex = geographies.findIndex(e => e.value === geog);
            const selectedGeog = geographies[geogIndex];

            const popIndex = populations.findIndex(e => e.value === pop);
            const selectedPop = populations[popIndex];

            const listPriceIndex = medianListingPrices.findIndex(e => e.value === listPrice);
            const selectedListPrice = medianListingPrices[listPriceIndex];

            if ( selectedGeog.value !== "all" && element.region !== selectedGeog.value ) {
                continue;
            }

            if ( selectedPop.value !== "all" && (element.population < selectedPop.min || element.population > selectedPop.max) ) {
                continue;
            }

            if ( selectedListPrice.value !== "all" && (element.median_listing_price < selectedListPrice.min || element.median_listing_price > selectedListPrice.max) ) {
                continue;
            }
            filteredData.push(element);
        };
        const activeFilters = filters.filter(e => e.active === true);
        weightData(filteredData, activeFilters);
    };

    const weightData = async(filteredData, activeFilters) => {
        // Multiply the weighting of each value by the value itself e.g. Appreciation score * Appreciation weighting
        // Add all the values together, and save that value to "Total Score"
        // Sort the cities by Total Score

        const weightingLabels = [
            {
                label: "median_listing_price_yy",
                weightingLabel: "appreciationScore"
            },
            {
                label: "hotness_score",
                weightingLabel: "hotnessScore"
            },
            {
                label: "population_growth",
                weightingLabel: "populationGrowthScore"
            },
            {
                label: "price_to_rent_ratio",
                weightingLabel: "priceRentRatioScore"
            },
            {
                label: "household_income_to_rent_ratio",
                weightingLabel: "householdIncomeRatioScore"
            }
        ];

        for (let index = 0; index < weightingLabels.length; index++) {
            const element = weightingLabels[index];
            const relevantObject = activeFilters.findIndex(e => e.label === element.label);
            if ( relevantObject !== -1 ) {
                weightingLabels[index].weighting = activeFilters[relevantObject].weighting;
            }
            else {
                weightingLabels[index].weighting = 0;
            }
        }

        const rankings = [];
        for (let index = 0; index < activeFilters.length; index++) {
            const filter = activeFilters[index];
            const filterLabel = filter.label;
            const dataForFiltering = [];
            for (let ii = 0; ii < filteredData.length; ii++) {
                dataForFiltering.push(filteredData[ii]);
            }
            const relevantWeightingLabelIndex = weightingLabels.findIndex(e => e.label === filterLabel);
            const relevantWeighting = weightingLabels[relevantWeightingLabelIndex];
            const sortedData = await sortData(dataForFiltering, filterLabel, filter.highToLow, relevantWeighting);
            const newObject = {
                label: filterLabel,
                data: sortedData,
                weightingLabel: relevantWeighting.weightingLabel
            }
            rankings.push(newObject);
        };

        const totalScoreArray = [];
        for (let index = 0; index < filteredData.length; index++) {
            const element = filteredData[index];
            const metro = element.metro;
            
            const scores = [];
            for (let ii = 0; ii < rankings.length; ii++) {
                const weightingLabel = rankings[ii].weightingLabel;
                const rankedData = rankings[ii].data;
                const rankedElementIndex = rankedData.findIndex(e => e.metro === metro);
                if ( rankedElementIndex !== -1 ) {
                    const score = rankedData[rankedElementIndex][weightingLabel];
                    scores.push(score);
                }
            };
            const totalScore = scores.reduce((a, b) => a + b, 0);
            element.totalScore = totalScore;
            totalScoreArray.push(element);
        }

        totalScoreArray.sort((a,b) => (a.totalScore > b.totalScore) ? 1 : ((b.totalScore > a.totalScore) ? -1 : 0));
        const getTop10 = totalScoreArray.slice(0, 10);

        setViewSources(false);
        if ( selectedTop10 <= (getTop10.length - 1) ) {
            formatSelectedCityData(getTop10[selectedTop10].metro);
            setTop10(getTop10);
        }
        else if ( getTop10.length === 0 ) {
            setSelectedTop10(0);
            setTop10([]);
        }
    };

    const sortData = (data, fieldName, highToLow, relevantWeighting) => {
        if ( highToLow === true ) {
            // highest to lowest
            data.sort((a,b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0));
        }
        else {
            // lowest to highest
            data.sort((a,b) => (a[fieldName] > b[fieldName]) ? 1 : ((b[fieldName] > a[fieldName]) ? -1 : 0));
        }

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const numerator = index + 1;
            const score = numerator * relevantWeighting.weighting;
            element[relevantWeighting.weightingLabel] = score;
        }
        
        return data;
    };

    const formatSelectedCityData = (metro) => {
        const cityData = [];
        for (let index = 0; index < rawData.length; index++) {
            const element = rawData[index];
            if ( element.metro === metro && element.reporting_date_ts > 1577836800000 ) {
                cityData.unshift(element);
            }
        };
        setSelectedCityData(cityData);

        const cityYears = [];
        for (let index = 0; index < cityData.length; index++) {
            const element = cityData[index];
            const year = moment(element.reporting_date_ts).format("MMM YYYY");
            const yearIndex = cityYears.indexOf(year);
            if ( yearIndex === -1 ) {
                cityYears.push(year);
            }
        };
        setSelectedCityYears(cityYears)
    };

    const dowloadCSV = () => {
        const sheetData = [];

        for (let index = 0; index < top10.length; index++) {
            const element = top10[index];
            const newObject = {
                "Metro": element.metro,
                "Region": element.region,
                "Median Listing Price": {
                    t: "n",
					v: element.median_listing_price,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
                },
                "YoY Appreciation": {
                    t: "n",
					v: element.median_listing_price_yy,
					z: "0.00%",
                },
                "Median Sq. Feet": element.median_square_feet,
                "Vacancy Rate": element.vacancy_rate,
                "Rent": {
                    t: "n",
					v: element.rent,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
                },
                "Median Household Income": {
                    t: "n",
					v: element.median_household_income,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
                },
                "Total Population": element.total_population,
                "Price to Rent Ratio": element.price_to_rent_ratio,
                "Household Income to Rent Ratio": element.household_income_to_rent_ratio,
                "Hotness Score": element.hotness_score
            };

            sheetData.push(newObject);
        }

        const sheetTitle = "Coffee Clozers Analysis.csv";
        const worksheet = XLSX.utils.json_to_sheet(sheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetTitle);
        XLSX.writeFile(workbook, sheetTitle);
    };

    const browseProperties = () => {
        recordEvent("Market Analysis Top CTA", {});
        navigate("/pick-city");
    };

    const changeMetroCounty = () => {
        if ( loading === false ) {
            setAppreciation(true);
            setAppreciationWeighting(1);
            setHotness(false);
            setHotnessWeighting(0.2);
            setPopulationGrowth(false);
            setPopulationGrowthWeighting(0.2);
            setPriceRentRatio(false);
            setPriceToRentRatioWeighting(0.2);
            setHouseholdIncomeRatio(false);
            setHouseholdIncomeWeighting(0.2);
            setSelectedTop10(0);
            setGeography("all");
            setPopulation("all");
            setMedianListingPrice("all");

            setMetroView(!metroView);
            setGeographyChanged(true);
        }
    };

    return (
        <div className={"market-data-outer-container bg-colour-light relative-container " + (mobileSlider === true ? "market-data-no-overflow" : "")}>
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                mobileSlider === true && innerWidth < 1000 ?
                <div className="dark-bg">
                </div>
                :
                null
            }
            <div className="">
                <div className="market-data-inner-container">
                    {
                        loading === true && firstLoadCompleted === false ?
                        <div className="market-data-loading-container">
                            <LoadingStatic />
                            {
                                loadingStages.map((item, index) =>
                                    <span 
                                        className={"colour-primary block-text margin-x-small " + (index <= loadingStage ? "heading-small-semibold" : "heading-small-regular")}
                                        key={index}
                                    >
                                        {index + 1}{")"} {item}
                                    </span>
                                )
                            }
                        </div>
                        :
                        null
                    }
                    {
                        errorMessage !== "" ?
                        <div className="market-data-error-container">
                            <h3 className="body-regular colour-error">
                                {errorMessage}
                            </h3>
                        </div>
                        :
                        null
                    }
                    {
                        loading === false || firstLoadCompleted === true ?
                        <div className="market-data-outer-body-container">
                            {
                                loading === true ?
                                <Loading />
                                :
                                null
                            }
                            <div className="market-data-title-container margin-x-large">
                                <div className="market-data-title-row-container">
                                    <div className="subtext-margin-right-x-large">
                                        <img
                                            src={CityEmoji}
                                            className="market-explore-cities-emoji"
                                            alt="My Cities"
                                        />
                                    </div>
                                    <div className="">
                                        <h1 className="heading-large-semibold margin-x-small">
                                            Market Analysis
                                        </h1>
                                        <h2 className="body-regular colour-secondary">
                                            <span className="body-semibold">Data as of:</span> {lastUpdated}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="market-data-tabs-outer-container">
                                <Tabs
                                    tabs={tabs}
                                    value={viewSources}
                                    selectTab={selectTab}
                                />
                                <div className="market-data-cta-container">
                                    <span className="body-regular colour-secondary block-text subtext-margin-right-large">
                                        Ready to find your next deal?
                                    </span>
                                    <div className="market-data-action-button-container">
                                        <ActionButton
                                            onClick={() => browseProperties()}
                                        >
                                            Browse properties now
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                viewBox="0 0 16 16" 
                                                fill="none"
                                                className="market-data-cta-arrow"
                                            >
                                                <path 
                                                    d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                                    fill="#FFFEFC"
                                                />
                                            </svg>
                                        </ActionButton>
                                    </div>
                                </div>
                            </div>
                            <div className="market-data-body-row">
                                {
                                    viewSources === true ?
                                    null
                                    :
                                    <div className="market-data-slider-toggle bg-colour-light desktop-none">
                                        <img
                                            src={Hamburger}
                                            className="market-data-slider-toggle-icon cursor-pointer"
                                            alt="Toggle slider"
                                            onClick={() => toggleMobileSlider()}
                                        />
                                    </div>
                                }
                                {
                                    viewSources === true ?
                                    null
                                    :
                                    <div className={"market-data-options-container margin-x-large bg-colour-white " + (innerWidth > 999 ? "" : mobileSlider === true ? "market-data-options-mobile-open" : "market-data-options-mobile-close")}>
                                        <div className="market-data-options-inner-container relative-container">
                                            <img
                                                src={Exit}
                                                className="market-data-slider-exit-icon cursor-pointer desktop-none"
                                                alt="Toggle slider"
                                                onClick={() => toggleMobileSlider()}
                                            />
                                            <h2 className="heading-small-semibold colour-primary margin-medium">
                                                Data
                                            </h2>
                                            <span className="label-regular-caps colour-secondary block-text margin-medium">
                                                METRICS
                                            </span>
                                            <h3 className="body-semibold colour-primary margin-medium">
                                                Long-term growth
                                            </h3>
                                            {
                                                longTermGrowth.map((item, index) =>
                                                    <div 
                                                        className={"market-data-option-container " + (index === 2 ? "margin-large" : "margin-x-small")}
                                                        key={index}
                                                    >
                                                        <div className="button-row margin-x-small">
                                                            <div 
                                                                className="market-data-checkbox-container"
                                                                onClick={() => loading === true ? null : changeLongTermGrowth(index)}
                                                            >
                                                                {
                                                                    (appreciation === true && index === 0) ||
                                                                    (hotness === true && index === 1) ||
                                                                    (populationGrowth === true && index === 2)
                                                                    ?
                                                                    <div className="market-data-checkbox">
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className="filters-modal-option-text-outer-container button-row relative-container">
                                                                <span className="body-regular colour-primary">
                                                                    {item.label}
                                                                </span>
                                                                <img
                                                                    src={Info}
                                                                    className="market-data-info-icon cursor-pointer"
                                                                    alt="Info"
                                                                    onMouseEnter={() => setShowTooltip(index)}
                                                                    onMouseLeave={() => setShowTooltip(null)}
                                                                />
                                                                {
                                                                    showTooltip === index ?
                                                                    <DefaultTooltip
                                                                        title={item.label}
                                                                        description={tooltipDescriptions[index]}
                                                                    />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            (appreciation === true && index === 0) ||
                                                            (hotness === true && index === 1) ||
                                                            (populationGrowth === true && index === 2)
                                                            ?
                                                            <div className="market-data-weighting-container margin-medium">
                                                                <span className="body-regular colour-secondary block-text margin-x-small">
                                                                    Weighting
                                                                </span>
                                                                <Box>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            labelId="market-data-select-label"
                                                                            id={`weighting-select-${index}`}
                                                                            value={index === 0 ? appreciationWeighting : index === 1 ? hotnessWeighting : populationGrowthWeighting}
                                                                            label=""
                                                                            onChange={(event) => changeWeighting(event.target.value, index)}
                                                                            input={<CustomSelect />}
                                                                            disabled={loading}
                                                                        >
                                                                            {
                                                                                weightingOptions.map((subItem, i) =>
                                                                                    <MenuItem
                                                                                        key={i}
                                                                                        value={subItem}
                                                                                        style={styles.menuItem}
                                                                                        disabled={false}
                                                                                    >
                                                                                        {subItem}
                                                                                    </MenuItem>	
                                                                                )
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div> 
                                                )
                                            }
                                            <h3 className="body-semibold colour-primary margin-medium">
                                                Profitability
                                            </h3>
                                            <div className="button-row margin-large">
                                                <div 
                                                    className="market-data-checkbox-container"
                                                    onClick={() => loading === true ? null : changeProfitability()}
                                                >
                                                    {
                                                        priceRentRatio === true ?
                                                        <div className="market-data-checkbox">
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="filters-modal-option-text-outer-container button-row relative-container">
                                                    <span className="body-regular colour-primary">
                                                        Price to rent ratio 💵
                                                    </span>
                                                    <img
                                                        src={Info}
                                                        className="market-data-info-icon cursor-pointer"
                                                        alt="Info"
                                                        onMouseEnter={() => setShowTooltip(3)}
                                                        onMouseLeave={() => setShowTooltip(null)}
                                                    />
                                                    {
                                                        showTooltip === 3 ?
                                                        <DefaultTooltip
                                                            title={"Price to rent ratio 💵"}
                                                            description={tooltipDescriptions[3]}
                                                        />
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            {
                                                priceRentRatio === true ?
                                                <div className="market-data-weighting-container margin-medium">
                                                    <span className="body-regular colour-secondary block-text margin-x-small">
                                                        Weighting
                                                    </span>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="market-data-select-label"
                                                                id="market-data-select"
                                                                value={priceToRentRatioWeighting}
                                                                label=""
                                                                onChange={(event) => changeWeighting(event.target.value, 3)}
                                                                input={<CustomSelect />}
                                                                disabled={loading}
                                                            >
                                                                {
                                                                    weightingOptions.map((subItem, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={subItem}
                                                                            style={styles.menuItem}
                                                                            disabled={false}
                                                                        >
                                                                            {subItem}
                                                                        </MenuItem>	
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                :
                                                null
                                            }
                                            <h3 className="body-semibold colour-primary margin-medium">
                                                Affordability
                                            </h3>
                                            <div className="button-row margin-x-small">
                                                <div 
                                                    className="market-data-checkbox-container"
                                                    onClick={() => loading === true ? null : changeAffordability()}
                                                >
                                                    {
                                                        householdIncomeRatio === true ?
                                                        <div className="market-data-checkbox">
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="filters-modal-option-text-outer-container button-row relative-container">
                                                    <span className="body-regular colour-primary">
                                                        Household income to rent ratio 🧑‍🧑‍🧒‍🧒
                                                    </span>
                                                    <img
                                                        src={Info}
                                                        className="market-data-info-icon cursor-pointer"
                                                        alt="Info"
                                                        onMouseEnter={() => setShowTooltip(4)}
                                                        onMouseLeave={() => setShowTooltip(null)}
                                                    />
                                                    {
                                                        showTooltip === 4 ?
                                                        <DefaultTooltip
                                                            title={"Household income to rent ratio 🧑‍🧑‍🧒‍🧒"}
                                                            description={tooltipDescriptions[4]}
                                                        />
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            {
                                                householdIncomeRatio === true ?
                                                <div className="market-data-weighting-container margin-medium">
                                                    <span className="body-regular colour-secondary block-text margin-x-small">
                                                        Weighting
                                                    </span>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="market-data-select-label"
                                                                id="market-data-select"
                                                                value={householdIncomeWeighting}
                                                                label=""
                                                                onChange={(event) => changeWeighting(event.target.value, 4)}
                                                                input={<CustomSelect />}
                                                                disabled={loading}
                                                            >
                                                                {
                                                                    weightingOptions.map((subItem, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={subItem}
                                                                            style={styles.menuItem}
                                                                            disabled={false}
                                                                        >
                                                                            {subItem}
                                                                        </MenuItem>	
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className="market-data-filters-outer-container margin-top-x-x-large">
                                                <h2 className="label-regular-caps colour-secondary block-text margin-medium">
                                                    Filters
                                                </h2>
                                                <div className="market-data-filter-container margin-medium">
                                                    <div className="market-data-filter-row margin-x-small relative-container">
                                                        <h3 className="body-semibold colour-primary">
                                                            Geographic region
                                                        </h3>
                                                        <img
                                                            src={Info}
                                                            className="market-data-info-icon cursor-pointer"
                                                            alt="Info"
                                                            onMouseEnter={() => setShowTooltip(5)}
                                                            onMouseLeave={() => setShowTooltip(null)}
                                                        />
                                                        {
                                                            showTooltip === 5 ?
                                                            <DefaultTooltip
                                                                title={"Geographic region"}
                                                                description={tooltipDescriptions[5]}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="market-data-select-label"
                                                                id="market-data-select"
                                                                value={geography}
                                                                label=""
                                                                onChange={(event) => changeGeography(event.target.value)}
                                                                input={<CustomSelect />}
                                                                disabled={loading}
                                                            >
                                                                {
                                                                    geographies.map((subItem, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={subItem.value}
                                                                            style={styles.menuItem}
                                                                            disabled={false}
                                                                        >
                                                                            {subItem.label}
                                                                        </MenuItem>	
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div className="market-data-filter-container margin-medium">
                                                    <div className="market-data-filter-row margin-x-small relative-container">
                                                        <h3 className="body-semibold colour-primary margin-x-small">
                                                            Population category
                                                        </h3>
                                                        <img
                                                            src={Info}
                                                            className="market-data-info-icon cursor-pointer"
                                                            alt="Info"
                                                            onMouseEnter={() => setShowTooltip(6)}
                                                            onMouseLeave={() => setShowTooltip(null)}
                                                        />
                                                        {
                                                            showTooltip === 6 ?
                                                            <DefaultTooltip
                                                                title={"Population category"}
                                                                description={tooltipDescriptions[6]}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="market-data-select-label"
                                                                id="market-data-select"
                                                                value={population}
                                                                label=""
                                                                onChange={(event) => changePopulation(event.target.value)}
                                                                input={<CustomSelect />}
                                                                disabled={loading}
                                                            >
                                                                {
                                                                    populations.map((subItem, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={subItem.value}
                                                                            style={styles.menuItem}
                                                                            disabled={false}
                                                                        >
                                                                            {subItem.label}
                                                                        </MenuItem>	
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div className="market-data-filter-container">
                                                    <div className="market-data-filter-row margin-x-small relative-container">
                                                        <h3 className="body-semibold colour-primary margin-x-small">
                                                            Median listing price category
                                                        </h3>
                                                        <img
                                                            src={Info}
                                                            className="market-data-info-icon cursor-pointer"
                                                            alt="Info"
                                                            onMouseEnter={() => setShowTooltip(7)}
                                                            onMouseLeave={() => setShowTooltip(null)}
                                                        />
                                                        {
                                                            showTooltip === 7 ?
                                                            <DefaultTooltip
                                                                title={"Median listing price category"}
                                                                description={tooltipDescriptions[7]}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="market-data-select-label"
                                                                id="median-listing-price-select"
                                                                value={medianListingPrice}
                                                                label=""
                                                                onChange={(event) => changeMedianListingPrice(event.target.value)}
                                                                input={<CustomSelect />}
                                                                disabled={loading}
                                                            >
                                                                {
                                                                    medianListingPrices.map((subItem, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={subItem.value}
                                                                            style={styles.menuItem}
                                                                            disabled={false}
                                                                        >
                                                                            {subItem.label}
                                                                        </MenuItem>	
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    viewSources === true ?
                                    <div className="market-data-body-sources-container">
                                        <div className="market-data-sources-container margin-large">
                                            <h2 className="heading-large-semibold colour-primary margin-large">
                                                Data sources
                                            </h2>
                                            <div className="">
                                                {
                                                    dataSources.map((item, index) =>
                                                    <div 
                                                        className="market-data-sources-element margin-medium"
                                                        key={index}
                                                    >
                                                        <h3 className="body-semibold colour-primary">
                                                            {item.title}
                                                        </h3>
                                                        <p className="body-regular colour-primary">
                                                            {item.firstText} <a className="body-semibold colour-link" rel="noreferrer" target="_blank" href={item.sourceLink}>(Source)</a> {item.secondText}
                                                        </p>
                                                    </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="market-data-divider-line margin-tops">
                                        </div>
                                        <div className="market-data-sources-definitions-container margin-large">
                                            <div className="margin-data-sources-category margin-x-large">
                                                <h2 className="heading-large-semibold colour-primary margin-large">
                                                    Definitions
                                                </h2>
                                                <h4 className="label-regular-caps colour-secondary margin-large">
                                                    METRICS
                                                </h4>
                                                {
                                                    metricsSources.map((item, index) =>
                                                    <div 
                                                        className="market-data-sources-element margin-large"
                                                        key={index}
                                                    >
                                                        <h3 className="body-semibold colour-primary">
                                                            {item.title}
                                                        </h3>
                                                        <p className="body-regular colour-primary">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                    )
                                                }
                                            </div>
                                            <div className="market-data-sources-category margin-large">
                                                <h3 className="label-regular-caps colour-secondary margin-large">
                                                    GEOGRAPHY
                                                </h3>
                                                {
                                                    geographySources.map((item, index) =>
                                                    <div 
                                                        className="market-data-sources-element margin-large"
                                                        key={index}
                                                    >
                                                        <h3 className="body-semibold colour-primary">
                                                            {item.title}
                                                        </h3>
                                                        <p className="body-regular colour-primary">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                    )
                                                }
                                                <img
                                                    src="https://firebasestorage.googleapis.com/v0/b/refi-787d3.appspot.com/o/US-regions.jpeg?alt=media&token=abeeea51-82cf-40e1-82ed-e76073b69cb5"
                                                    className="market-data-sources-geography-image"
                                                    alt="US Geography"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="market-data-body-container">
                                        <div className="market-data-table-title-container margin-medium">
                                            <h3 className="heading-large-semibold colour-primary">
                                                Rankings
                                            </h3>
                                            <div className="market-data-geography-switch-container">
                                                <NewSwitch
                                                    firstOption={metroView}
                                                    pricingSwitch={changeMetroCounty}
                                                    textOptions={marketOptions}
                                                    large={true}
                                                />
                                            </div>
                                            <div 
                                                className="market-data-download-container button-row text-button"
                                                onClick={() => dowloadCSV()}
                                            >
                                                <img
                                                    src={Download}
                                                    className="market-data-download-icon cursor-pointer"
                                                    alt="Download"
                                                />
                                                <span className="body-regular colour-link">
                                                    Download CSV
                                                </span>
                                            </div>
                                        </div>
                                        <div className="market-data-table-overflow-container margin-x-large">
                                            <table className="market-data-table-outer-container">
                                                <thead className="">
                                                    <tr className="market-data-table-row">
                                                        {
                                                            columns.map((item, index) =>
                                                                <th 
                                                                    className="market-data-table-label-cell text-align-left "
                                                                    key={index}
                                                                >
                                                                    <span className="body-semibold colour-primary">
                                                                        {item.label}
                                                                    </span>
                                                                </th>
                                                            )
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        top10.map((item, index) =>
                                                            <tr
                                                                key={index}
                                                                className="market-data-values-row"
                                                            >
                                                                <td className="market-data-table-cell market-data-table-address-value-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {item.metro}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary text-capitalise">
                                                                        {item.region}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {formatterLong.format(item.median_listing_price).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {percentage.format(item.median_listing_price_yy)}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {numberFormatter.format(item.median_square_feet)}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {
                                                                            item.vacancy_rate === "" ?
                                                                            "N/A"
                                                                            :
                                                                            percentage.format(item.vacancy_rate)
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {formatterLong.format(item.rent).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {formatterLong.format(item.median_household_income).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {numberFormatter.format(item.total_population).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {numberFormatter.format(item.price_to_rent_ratio).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell market-data-household-income-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {numberFormatter.format(item.household_income_to_rent_ratio).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                                <td className="market-data-table-cell"> 
                                                                    <span className="body-regular colour-primary">
                                                                        {percentage.format(item.hotness_score / 100).replace(".00", "")}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            top10.length > 0 && selectedTop10 <= (top10.length - 1) ?
                                            <div className="margin-medium margin-x-large">
                                                <h3 className="heading-large-semibold colour-primary margin-medium">
                                                    Performance
                                                </h3>
                                                <span className="body-regular colour-secondary">
                                                    Select a metro area to see a breakdown for each metro.
                                                </span>
                                            </div>
                                            :
                                            <div className="margin-medium block-text text-align-center margin-x-large">
                                                <span style={{ fontSize: '48px' }}>
                                                    🤔
                                                </span>
                                                <h3 className="heading-small-semibold text-align-center colour-primary margin-medium">
                                                    Looks like your filters are too restrictive there buddy
                                                </h3>
                                            </div>
                                        }
                                        {
                                            top10.length > 0 && selectedTop10 <= (top10.length - 1) ?
                                            <div className="market-data-city-selector-container margin-x-large">
                                                <Box>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="market-data-select-label"
                                                            id="top-10-select"
                                                            value={selectedTop10}
                                                            label=""
                                                            onChange={(event) => onChangeTop10(event.target.value)}
                                                            input={<CustomSelect />}
                                                        >
                                                            {
                                                                top10.map((item, index) =>
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={index}
                                                                        style={styles.menuItem}
                                                                        disabled={false}
                                                                    >
                                                                        {item.metro}
                                                                    </MenuItem>	
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            top10.length > 0 && selectedTop10 <= (top10.length - 1) ?
                                            <div className="market-data-top-results-row margin-x-large">
                                                <div className="market-data-top-results-card bg-colour-white">
                                                    <div className="market-data-top-results-inner-card">
                                                        <h3 className="label-regular-caps colour-secondary margin-x-small">
                                                            Median list price
                                                        </h3>
                                                        <span className="heading-small-semibold colour-primary block-text margin-x-small">
                                                            {formatterLong.format(top10[selectedTop10].median_listing_price).replace(".00", "")}
                                                        </span>
                                                        <span className={"body-semibold " + (top10[selectedTop10].median_listing_price_yy > 0 ? "colour-green" : "colour-error")}>
                                                            YoY {percentage.format(top10[selectedTop10].median_listing_price_yy)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="market-data-top-results-card bg-colour-white">
                                                    <div className="market-data-top-results-inner-card">
                                                        <h3 className="label-regular-caps colour-secondary margin-x-small">
                                                            Avg. rent
                                                        </h3>
                                                        <span className="heading-small-semibold colour-primary block-text margin-x-small">
                                                            {formatterLong.format(top10[selectedTop10].rent).replace(".00", "")}
                                                        </span>
                                                        <span className={"body-semibold " + (top10[selectedTop10].rent_growth_yy > 0 ? "colour-green" : "colour-error")}>
                                                            YoY {percentage.format(top10[selectedTop10].rent_growth_yy)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="market-data-top-results-card bg-colour-white">
                                                    <div className="market-data-top-results-inner-card">
                                                        <h3 className="label-regular-caps colour-secondary margin-x-small">
                                                            Median household income
                                                        </h3>
                                                        <span className="heading-small-semibold colour-primary block-text margin-x-small">
                                                            {formatterLong.format(top10[selectedTop10].median_household_income).replace(".00", "")}
                                                        </span>
                                                        <span className={"body-semibold " + (top10[selectedTop10].median_household_income_growth > 0 ? "colour-green" : "colour-error")}>
                                                            YoY {percentage.format(top10[selectedTop10].median_household_income_growth)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="market-data-top-results-card bg-colour-white">
                                                    <div className="market-data-top-results-inner-card">
                                                        <h3 className="label-regular-caps colour-secondary margin-x-small">
                                                            Total population
                                                        </h3>
                                                        <span className="heading-small-semibold colour-primary block-text margin-x-small">
                                                            {numberFormatter.format(top10[selectedTop10].total_population).replace(".00", "")}
                                                        </span>
                                                        <span className={"body-semibold " + (top10[selectedTop10].population_growth > 0 ? "colour-green" : "colour-error")}>
                                                            YoY {percentage.format(top10[selectedTop10].population_growth)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            top10.length > 0 && selectedTop10 <= (top10.length - 1) ?
                                            <div className="market-data-charts-container">
                                                <h3 className="heading-small-semibold colour-primary margin-medium">
                                                    Charts
                                                </h3>
                                                <div className="market-data-charts-grid">
                                                    {
                                                        charts.map((item, index) =>
                                                            <MarketDataChart
                                                                selectedCityData={selectedCityData}
                                                                label={`${item.label} (Realtor.com)`}
                                                                shortLabel={item.label}
                                                                selectedCityYears={selectedCityYears}
                                                                fieldName={item.field}
                                                                key={index}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <MarketAnalysisBottom />
            </div>
            <Footer />
        </div>
    )
};

export default MarketExplore;