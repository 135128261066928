import React, { useState, useEffect } from 'react';
import '../styles/Setup.css';
import { Header, Footer, UploadImage, BackButton, Loading } from '../components';
import { ActionButton, FormInput } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, setData, makeId } from '../functions';
import { ChevronRightWhite, TickWhite } from '../assets';
import { useNavigate } from 'react-router-dom';

function Setup() {

    const [step, setStep] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstQuery, setFirstQuery] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchUserData = async () => {
            setFirstQuery(true);
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const colRef = "Users";
                const docRef = user.userId;

                const queryDocument = await getDocument(colRef, docRef);
                if ( queryDocument.status === 200 ) {
                    const data = queryDocument.data.data;
                    setFirstName(data.firstName);
                    setLastName(data.lastName);
                    setEmail(data.email);
                    localChangePhoneNumber(data.phoneNumber);
                    if ( data.profileImage !== undefined ) {
                        setUploadedImage(data.profileImage.url);
                    }
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        const localChangePhoneNumber = (value) => {
            // return nothing if no value
            // if (!value) return value; 

            // only allows 0-9 inputs

            if ( value.length < phoneNumber.length ) {
                setPhoneNumber(value);
            }
            else {
                if (!value) setPhoneNumber(value); 

                const currentValue = value.replace(/[^\d]/g, '');
                const cvLength = currentValue.length; 
            
                if (!phoneNumber || value.length > phoneNumber.length) {
                    if (cvLength < 4) setPhoneNumber(currentValue);
                
                    if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
                
                    setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
                }
            }
        };

        if ( firstQuery === false ) {
            fetchUserData();
        }
    }, [navigate, phoneNumber, firstQuery]);

    const stepLabels = [
        "Add personal details that will display on your profile.",
        "List the name of the the company you’re associated with (if any) to be displayed on your profile.",
        "Where do you want clients to contact you? This will be visible on your profile."
    ];

    const goBack = () => {
        if ( step > 0 ) {
            setStep(step - 1);
        }
    };

    const checkDisabled = () => {
        if ( loading === true ) {
            return true;
        }
        else if ( step === 0 ) {
            return firstName === "" || lastName === "" ? true : false;
        }
        else if ( step === 1 ) {
            return false;
        }
        else if ( step === 2 ) {
            return email === "" || phoneNumber === "" ? true : false;
        }
    };

    const saveImageFile = async(uploadedFile, userId) => {
        const colRef = "Users";
        const docRef = userId;
        const queryData = await getDocument(colRef, docRef);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            data.profileImage = uploadedFile;
            await setData(colRef, docRef, data);
            setUploadedImage(uploadedFile.url);
        }
    };

    const saveCompanyLogo = async(uploadedFile, userId) => {
        const colRef = "Users";
        const docRef = userId;
        const queryData = await getDocument(colRef, docRef);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            data.partner = {
                companyName: companyName,
                logo: uploadedFile
            };
            await setData(colRef, docRef, data);
            setCompanyLogo(uploadedFile.url);
        }
    };

    const changePhoneNumber = (value) => {
		// return nothing if no value
		// if (!value) return value; 

		// only allows 0-9 inputs

		if ( value.length < phoneNumber.length ) {
            setPhoneNumber(value);
        }
        else {
			if (!value) setPhoneNumber(value); 

			const currentValue = value.replace(/[^\d]/g, '');
			const cvLength = currentValue.length; 
		
			if (!phoneNumber || value.length > phoneNumber.length) {
				if (cvLength < 4) setPhoneNumber(currentValue);
			
				if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
			
				setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
			}
		}
	}

    const nextStep = () => {
        setStep(step + 1);
    };

    const checkId = async() => {
        const id = await makeId(10);
        complete(id);
    };

    const complete = async(id) => {
        setLoading(true);
        const unformattedPhoneNumber = phoneNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const colRef = "Partners";
            const docRef = user.userId;

            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: unformattedPhoneNumber,
                companyName: companyName,
                profileImage: uploadedImage,
                companyLogo: companyLogo,
                listings: [],
                id: id,
                userId: user.userId
            };
            await setData(colRef, docRef, data);
            await saveUserData(docRef, id, companyName);
        }
        setLoading(false);
    };

    const saveUserData = async(userId, id, companyName) => {
        const colRef = "Users";
        const queryData = await getDocument(colRef, userId);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            data.partner = {
                id: id,
                companyName: companyName
            };
            await setData(colRef, userId, data);
            navigate("/partner/dashboard");
            return;
        }
        else {
            return;
        }
    };

    return (
        <div className="setup-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="setup-inner-container">
                {
                    loading === true ?
                    <div className="setup-loading-container">
                        <Loading />
                    </div>
                    :
                    null
                }
                <div className="setup-title-container margin-x-x-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-medium">
                        Welcome to the Partner Program 🎉
                    </h1>
                    <h2 className="body-regular colour-secondary">
                        We’re happy to have you as our newest partner! 
                        Let’s start by setting up your partner profile.
                    </h2>
                </div>
                <div className="setup-body-container">
                    <div className="setup-body-left-container">
                        <div className="setup-body-left-inner-container">
                            <h3 className="heading-large-semibold colour-primary margin-medium">
                                Profile setup
                            </h3>
                            <h4 className="body-regular colour-secondary block-text margin-medium">
                                Step {step + 1} of 3
                            </h4>
                            <span className="body-regular colour-primary">
                                {stepLabels[step]}
                            </span>
                        </div>
                    </div>
                    {
                        step === 0 ?
                        <div className="setup-body-right-container">
                            <h3 className="body-semibold colour-primary margin-medium">
                                Personal details
                            </h3>
                            <div className="setup-body-input-container margin-medium">
                                <FormInput
                                    value={firstName}
                                    type="text"
                                    label="First name*"
                                    id="first-name-input"
                                    placeholder="First Name"
                                    onChange={(text) => setFirstName(text.target.value)}
                                />
                            </div>
                            <div className="setup-body-input-container margin-x-large">
                                <FormInput
                                    value={lastName}
                                    type="text"
                                    label="Last name*"
                                    id="last-name-input"
                                    placeholder="Last Name"
                                    onChange={(text) => setLastName(text.target.value)}
                                />
                            </div>
                            <div className="setup-body-image-container margin-x-large">
                                <h3 className="body-semibold colour-primary margin-medium">
                                    Profile photo
                                </h3>
                                <UploadImage
                                    saveFile={saveImageFile}
                                    uploadedImage={uploadedImage}
                                    route="Profile Image"
                                    subText={`${uploadedImage === "" ? "Add a" : "Change"} photo`}
                                    showIcon={true}
                                />
                            </div>
                            <div className="setup-body-button-container">
                                <ActionButton
                                    disabled={checkDisabled()}
                                    onClick={() => nextStep()}
                                >
                                    Next
                                    <img
                                        src={ChevronRightWhite}
                                        className="setup-body-button-icon"
                                        alt="Next"
                                    />
                                </ActionButton>
                            </div>
                        </div>
                        :
                        step === 1 ?
                        <div className="setup-body-right-container">
                            <BackButton
                                func={goBack}
                                outerClass={"margin-x-large"}
                            />
                            <h3 className="body-semibold colour-primary margin-medium">
                                Company details <span className="body-regular colour-secondary">(Optional)</span>
                            </h3>
                            <div className="setup-body-input-container margin-x-large">
                                <FormInput
                                    value={companyName}
                                    type="text"
                                    label="Company"
                                    id="company-name-input"
                                    placeholder="Company"
                                    onChange={(text) => setCompanyName(text.target.value)}
                                />
                            </div>
                            <h3 className="body-semibold colour-primary margin-medium">
                                Company image <span className="body-regular colour-secondary">(Optional)</span>
                            </h3>
                            <UploadImage
                                saveFile={saveCompanyLogo}
                                uploadedImage={companyLogo}
                                route="Company Logo"
                                subText={`${companyLogo === "" ? "Add" : "Change"} company image or logo`}
                                showIcon={true}
                            />
                            <div className="setup-body-button-container">
                                <ActionButton
                                    disabled={checkDisabled()}
                                    onClick={() => nextStep()}
                                >
                                    Next
                                    <img
                                        src={ChevronRightWhite}
                                        className="setup-body-button-icon"
                                        alt="Next"
                                    />
                                </ActionButton>
                            </div>
                        </div>
                        :
                        step === 2 ?
                        <div className="setup-body-right-container">
                            <BackButton
                                func={goBack}
                                outerClass={"margin-x-large"}
                            />
                            <h3 className="body-semibold colour-primary margin-x-x-small">
                                Contact info
                            </h3>
                            <span className="body-regular colour-secondary margin-medium block-text">
                                This will be visible on your profile.
                            </span>
                            <div className="setup-body-input-container margin-medium">
                                <FormInput
                                    value={email}
                                    type="text"
                                    label="Email*"
                                    id="email-input"
                                    placeholder="email@email.com"
                                    onChange={(text) => setEmail(text.target.value)}
                                />
                            </div>
                            <div className="setup-body-input-container margin-x-large">
                                <FormInput
                                    value={phoneNumber}
                                    type="text"
                                    label="Phone*"
                                    id="phone-number-input"
                                    placeholder="(555) 123-4567"
                                    onChange={(text) => changePhoneNumber(text.target.value)}
                                />
                            </div>
                            <div className="setup-body-button-large-container">
                                <ActionButton
                                    disabled={checkDisabled()}
                                    onClick={() => checkId()}
                                >
                                    Complete profile
                                    <img
                                        src={TickWhite}
                                        className="setup-body-button-icon"
                                        alt="Next"
                                    />
                                </ActionButton>
                            </div>
                        </div>
                        :
                        null

                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Setup;