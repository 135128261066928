import React from 'react';
import '../styles/UploadedImage.css';

function UploadedImage(props) {
    const item = props.item;
    const index = props.index;
    const deleteImage = props.deleteImage;
    return (
        <div 
            className="uploaded-image-container cursor-grab relative-container"
        >
            <div 
                className="uploaded-image-delete-container cursor-pointer bg-colour-white"
                onClick={() => deleteImage(index)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.30879 5.68466C7.10255 5.4556 6.74966 5.43711 6.5206 5.64336C6.29154 5.8496 6.27305 6.20249 6.47929 6.43155L9.2482 9.50673L6.49141 12.5684C6.28516 12.7975 6.30366 13.1504 6.53272 13.3566C6.76178 13.5629 7.11466 13.5444 7.32091 13.3153L9.9992 10.3408L12.6775 13.3153C12.8837 13.5444 13.2366 13.5629 13.4657 13.3566C13.6947 13.1504 13.7132 12.7975 13.507 12.5684L10.7502 9.50673L13.5191 6.43155C13.7253 6.20249 13.7068 5.84961 13.4778 5.64336C13.2487 5.43711 12.8958 5.45561 12.6896 5.68467L9.9992 8.67266L7.30879 5.68466Z" fill="#2F4858"/>
                </svg>
            </div>
            <img
                src={item.url}
                className="uploaded-image-element"
                alt="Uploaded-Pic"
            />
        </div>
    )
}

export default UploadedImage;