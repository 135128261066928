import React, { useEffect } from 'react';
import { Header, Footer } from '../components';
import '../styles/About.css';

function About() {

	useEffect(() => {
		document.title = "Coffee Clozers | About";
	});

	return (
		<div className="about-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="about-inner-container">
				<h1>
					About
				</h1>
				<div className="about-text-container">
					<p>
						Ok we get it. You're experienced, you know what
						you're doing, you know how to invest in real
						estate, and you don't need a shitty website
						to help you do it. 
						<br></br>
						<br></br>
						Fine. We believe you.
						<br></br>
						<br></br>
						But here's the thing. No matter how good a deal
						you find - statistically speaking - there will almost
						always be a better one. Our job is to find you those
						best deals. Your job is to look into them, and choose
						the one that works for you.
						<br></br>
						<br></br>
						Let the machine do the machine's job, and let the
						human do the human's job.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default About;