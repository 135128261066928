import React, { useState } from 'react';
import '../styles/ComparePropertyDetails.css';
import { Accordion, AccordionDetails, AccordionSummary, colour } from '../styles/GlobalStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ComparePropertyDetails(props) {
    const [expanded, setExpanded] = useState(true);
    const propertyDetails = props.propertyDetails;

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const styles = {
		accordionSummary: {
			width: '100px',
			boxShadow: 'none', 
			padding: 0,
			margin: '0 auto'
		},
		typography: {
			flexShrink: 0, 
			fontFamily: "Rubik",
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '28px',
			letterSpacing: '0em',
			textAlign: 'left',
			color: colour.blueBlue03
		}
	};

    return (
        <div className="compare-property-details-outer-container">
            <div className="compare-property-details-inner-container">
                <Accordion 
                    expanded={expanded}
                    onChange={handleChange}
                    sx={{ boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-1-bh-content`}
                        id={`panel-1-bh-header`}
                        sx={styles.accordionSummary}
                    >
                        <h2 className="heading-large-semibold colour-link">
                            Property
                        </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="compare-property-details-container">
                            {
                                propertyDetails.map((item, index) =>
                                    <div 
                                        className="compare-property-details-element"
                                        key={index}
                                    >
                                        <div className="compare-property-grid-element">
                                            <h4 className="body-regular colour-secondary">
                                                {item.label}
                                            </h4>
                                        </div>
                                        <div className="compare-property-details-sub-element">
                                            {
                                                item.properties.map((subItem, i) =>
                                                    <div 
                                                        className="compare-property-sub-grid-element"
                                                        key={i}
                                                    >
                                                        {
                                                            subItem.icon !== "" ?
                                                            <img
                                                                src={subItem.icon}
                                                                className="compare-property-element-icon"
                                                                alt={item.label}
                                                            />
                                                            :
                                                            null
                                                        }
                                                        <span className="body-regular colour-primary">
                                                            {subItem.value}
                                                        </span>
                                                    </div>   
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default ComparePropertyDetails;